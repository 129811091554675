import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const BuilderDeleteModal = ({ data, deleteRecord, deleteSelectedData, cancelDelete }) => {
  return (
    <section className="delete-rtr-ad-content">
      <nav className="delete-rtr-ad-section">
        <p>Are you sure you want to delete this {data}?
        {data !=='answer' && (<><br /><br />Deleting {data} will reset all answer level branching.<br />You have to manually update all answer level branching links.</>)}
        </p>
      </nav>
      <nav className="btn-wrapper">
        <Button className="in-btn-sm in-btn-primary" onClick={() => deleteSelectedData(deleteRecord, data)}>
          Delete
        </Button>
        <Button color="link" className="in-btn-link" onClick={() => cancelDelete(false)}>
          Cancel
        </Button>
      </nav>
    </section>
  )
}

export default BuilderDeleteModal;