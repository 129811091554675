import React, { useState } from 'react';
import { Input, Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from '../../../Loader_Skeleton/SkeletonTable';
import GenerateItemList from './GenerateItemList';
import '../index.scss';

const TaxonomyAnswerList = (props) => {
  const {
    data,
    rtrType,
    questionObject,
    getTaxonomySelection,
    answerId,
    taxonomyAnswerListOption,
    filterAnserListData,
    clearTaxonomySelection
  } = props;

  const [isTaxonomyListFilterOpen, setTaxonomyListFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const ICONS = {
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    COPY: "/Icon_assets/svg/copy-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",
    ARROW_UP: "/Icon_assets/svg/toggle-up.svg",
    VALID_ICON: '/Icon_assets/svg/valid-tick-icon.svg',
    DRAG: "/Icon_assets/svg/Drag.svg",
    NEXT_QUES: "/Icon_assets/svg/next-question-arrow.svg",
  };

  const toggleTaxonomyListFilter = () => {
    setTaxonomyListFilterOpen(prev => !prev);
  }

  const searchAnsDropdown = (e) => {
    setSearchText(e.target.value)
    filterAnserListData(e.target.value);
  }


  const getDropdownLabel = (data) => {
    let label;
    const answerObject = data?.answer.filter((item)=>item.id === answerId);
    if(answerObject.length && answerObject[0]?.taxonomy_answer){
      label = answerObject[0].taxonomy_answer_label
    }else{
      label = 'Select Taxonomy'
    }
    return label;
  }

  const clearSelectedOption = (event) => {
    clearTaxonomySelection(event,'answerlist',data,answerId);
    setTaxonomyListFilterOpen(prev => !prev);
  }
  
  return (
    <>
      <Dropdown id='taxonomy-answer-list-dropdown' className={`taxonomy-answer-list d-flex next-q-dropdown ${(rtrType==="Invisibly" && questionObject[questionObject.length -1]?.ID === data?.ID) ? "disable-platform" : ""  }`} isOpen={isTaxonomyListFilterOpen} toggle={toggleTaxonomyListFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div style={{ display: 'flex' }}>
              <div className='dt-title'>{getDropdownLabel(data)}</div>
            </div>
            <div>
              <img style={{
                display: "inline-block",
                margin: '0 12px',
                transform: isTaxonomyListFilterOpen ? 'rotate(180deg)' : ''
              }}
                src={
                  ICONS.ARROW_DOWN
                }
                className="arrow-down"
                alt='next-q-filter'
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu id='taxonomy-answer-list-dropdown-menu'>
          <div className='search-wrapper'>
            <Input
              value={searchText}
              type="text"
              name="search"
              id="searchUser"
              placeholder="Search..."
              className="search-input"
              autocomplete="off"
              onChange={(event) => { searchAnsDropdown(event) }}
              disabled={false}
            />
            <img
              src="/Icon_assets/svg/search-organization-icon.svg"
              alt="Search"
              className="search-image"
            />
          </div>
          {taxonomyAnswerListOption?.length ? (<div className="clear-filter-link">
            <a href="#" onClick={(event) => {clearSelectedOption(event)}}>Clear</a></div>):''
          }
          <div className='next-q-list answer-list-dropdown' id="answerListDropDown">
            <InfiniteScroll
              dataLength={(taxonomyAnswerListOption?.length)?taxonomyAnswerListOption.length:0}
              next={() => { }}
              hasMore={false}
              loader={<SkeletonTable rows={10} />}
              scrollableTarget="answerListDropDown"
            >
              {
                taxonomyAnswerListOption.length ? (
                  <GenerateItemList taxonomyAnswerListData={taxonomyAnswerListOption} data={data} getTaxonomySelection={getTaxonomySelection} answerId={answerId}/>
                ):(
                  <nav className='no-answer-list-section'>No record found</nav>
                )
              }
            </InfiniteScroll>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default TaxonomyAnswerList;