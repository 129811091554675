export const csvToJSON = () => { }

export const JSONTocsv = () => { }

export const RTRBuilderPayloadToJSON = (allQuestions, allToasts = []) => {
    //question payload
    const newQuestions = allQuestions.map((question, index) => {
      let newObj = {}
      newObj[`group_${question.ID}.Q${question.ID}`] = question.question
      newObj[`group_${question.ID}.Q${question.ID}_shuffle`] = question.shuffle_type?.toLowerCase()
      newObj[`group_${question.ID}.Q${question.ID}_type`] = question.question_type === "SS" ? "Single choice" : "Multiple choice"

      // const button_component = Object.keys(question).find((item) => item.includes("button_component"))
      // if (button_component) {
      //   newObj[`group_${question.ID}.${button_component}`] = question[button_component]
      // }

      if (question.question_type === "MS") {
        newObj[`group_${question.ID}.button_component_1`] = "Next"
      }

      if (question?.next_question) {
        const isToast = question?.next_question.includes("Toast")
        if (isToast) {
          let linkedToastList = allToasts.filter((item)=>item.id === question?.next_question?.replace(" ", ""));
          if(linkedToastList.length && linkedToastList[0].button_url.includes('/SurveyResult')){
            newObj[`group_${question.ID}.selection_component_1`] =   "Toast 6";
          }else{
            newObj[`group_${question.ID}.selection_component_1`] =  question?.next_question?.replace(" ", "").replace("Toast", "Toast ");
          }
        }else{
          newObj[`group_${question.ID}.selection_component_1`] = 
          question?.next_question.includes('q') ?
           question?.next_question?.replace("q", "Message ") :
           question?.next_question?.replace("Question ", "Message ")
        }
      }
      
      if(question.hasOwnProperty('answer')){
        question.answer.forEach((ans, ansIndex) => {
          newObj[`group_${question.ID}.Q${question.ID}_ANS${ansIndex + 1}`] = ans[`answer${ansIndex + 1}`]
          if (ans[`answer${ansIndex + 1}_branch`] !== "") {
            const isToast = ans[`answer${ansIndex + 1}_branch`].includes("Toast")
            if (isToast) {
              let linkedToastList = allToasts.filter((item)=>item.id === ans[`answer${ansIndex + 1}_branch`].replace(" ", ""));
              if(linkedToastList.length && linkedToastList[0].button_url.includes('/SurveyResult')){
                newObj[`group_${question.ID}.Q${question.ID}_ANS${ansIndex + 1}_NEXT_Q`] = "Toast 6";
              }else{
                newObj[`group_${question.ID}.Q${question.ID}_ANS${ansIndex + 1}_NEXT_Q`] = ans[`answer${ansIndex + 1}_branch`]?.replace(" ", "").replace("Toast", "Toast ");
              } 
            }else{
              newObj[`group_${question.ID}.Q${question.ID}_ANS${ansIndex + 1}_NEXT_Q`] = ans[`answer${ansIndex + 1}_branch`]?.includes('q') ? 
              ans[`answer${ansIndex + 1}_branch`]?.replace("q", "Message ") :
              ans[`answer${ansIndex + 1}_branch`]?.replace("Question ", "Message ")
            }
          }
          if (ans[`answer${ansIndex + 1}_pixel`] !== "") {
            newObj[`group_${question.ID}.pixel_component_${ansIndex + 1}`] = ans[`answer${ansIndex + 1}_pixel`]
          }
        })
      }
      return newObj
    })

    //toasts payload
    let newToasts;
    let toastCounter = 26;
    if (allToasts.length) {
      newToasts = allToasts.map((toast, index) => {
        let newObj = {}
        let componentIndex = 1;
        let toastCounterNum = (toast.button_url.includes('/SurveyResult')) ? 31 : (toastCounter + index);
        newObj[`group_${toastCounterNum}.button_component_${componentIndex}`] = toast.button_copy
        newObj[`group_${toastCounterNum}.text_component_${componentIndex}`] = toast.title
        newObj[`group_${toastCounterNum}.text_component_${componentIndex + 1}`] = toast.subtitle
        newObj[`group_${toastCounterNum}.toast_button_link`] = toast.button_url
        if (toast?.button_pixel) {
          newObj[`group_${toastCounterNum}.pixel_component`] = toast?.button_pixel
        }
        return newObj
      })
    }
    
    //final flat object
    const result = allToasts.length ? Object.assign({}, ...newQuestions, ...newToasts) : Object.assign({}, ...newQuestions);
    return result
}

export const RTRBuilderResponseToJSON = (question_toasts) => {
  delete question_toasts["banner_group.image_component_1"];
  delete question_toasts["logo_details.image_component_1"];
  const mainObj = {}

  //creating groups based on question
  Object.keys(question_toasts).forEach((value, i) => {
    let a
    if(!value.includes('pixel_component')){
      a = value.replace(`${value.split(".")[0]}.`, '');
    }else{
      a = 'Q' + value.split("pixel_component_")[1] + '_ANS' + value.split("pixel_component_")[1] + '_pixel_component';
    }
    mainObj[value.split(".")[0]] = { ...mainObj[value.split(".")[0]], [a]: question_toasts[`${value}`] }
  })

  //extracting answers
  Object.keys(mainObj).forEach((key) => {
    let q = mainObj[key]
    Object.keys(q).forEach((val, i) => {
      if (val.split('_')[1] && val.split('_')[1].includes('ANS')) {
        q[val.split('_')[1]] = { ...q[val.split('_')[1]], [val]: q[val] }
        delete q[val]
      }
    })
  })

  //building answer objects
  Object.keys(mainObj).forEach((key) => {
    let q = mainObj[key]
    Object.keys(q).sort().forEach((val_2, i) => {
      if (val_2.includes('ANS')) {
        q['answer'] = { ...q['answer'], [val_2]: q[val_2] }
        delete q[val_2];
      }
    })
  })

  //sort the keys
  let keyList = Object.keys(mainObj).sort((a, b) => Number(a.split("_")[1] - b.split("_")[1]))

  //final array of object
  const questions = keyList.map((item, i) => {
    let questionsObj = {}
    //1-25 questions
    if (item.split("_")[1] <= 25) {
      const Questiontype = mainObj[item][`Q${i + 1}_type`] === "Single choice" ? "SS" : "MS"
      questionsObj["ID"] = i + 1
      questionsObj["question"] = mainObj[item][`Q${i + 1}`]
      questionsObj["shuffle_type"] = mainObj[item][`Q${i + 1}_shuffle`] && mainObj[item][`Q${i + 1}_shuffle`].charAt(0).toUpperCase() + mainObj[item][`Q${i + 1}_shuffle`].slice(1).toLowerCase() //shuffle_type: "none" 1st letter caps
      //finalObj["question_type"] = mainObj[item][`Q${i + 1}_type`] //SS/MM
      questionsObj["question_type"] = Questiontype //SS/MM

      if (Questiontype === "MS") {
        questionsObj["button_component_1"] = "Next"
      }

      // finalObj[`button_component_${i + 1}`] = mainObj[item][`button_component_${i + 1}`]
      // finalObj[`selection_component_${i + 1}`] = mainObj[item][`selection_component_${i + 1}`]
      //const button_component = Object.keys(mainObj[item]).find((item) => item.includes("button_component"))
      const selection_component = Object.keys(mainObj[item]).find((item) => item.includes("selection_component"))
      questionsObj["next_question"] = mainObj[item][selection_component] || ""
      // if (button_component) {
      //   questionsObj[button_component] = mainObj[item][button_component]
      // }
      //finalObj[selection_component] = mainObj[item][selection_component]

      if (mainObj[item].hasOwnProperty('answer')) {
        questionsObj["answer"] = Object.values(mainObj[item][`answer`]).map((ans, index) => {
          let ansObj = {}
          ansObj[`answer${index + 1}`] = ans[`Q${i + 1}_ANS${index + 1}`]
          ansObj[`answer${index + 1}_branch`] = ans[`Q${i + 1}_ANS${index + 1}_NEXT_Q`] || ""
          ansObj[`answer${index + 1}_pixel`] = ans[`Q${index + 1}_ANS${index + 1}_pixel_component`] || ""
          ansObj[`id`] = `answer${index + 1}`
          ansObj[`answer${index + 1}_toggle`] = ans[`Q${index + 1}_ANS${index + 1}_pixel_component`] ? true : false;

          return ansObj
        })
      }
    }
    return questionsObj
  })

  let toastCounter = 1;
  const toasts = keyList.map((item, i) => {
    const toastObj = {}
    //26-21 toasts
    if (item.split("_")[1] >= 26) {
      toastObj[`id`] = `Toast${toastCounter}`
      toastObj[`title`] = mainObj[item][`text_component_1`]
      toastObj[`subtitle`] = mainObj[item][`text_component_2`]
      toastObj[`button_copy`] = mainObj[item][`button_component_1`]
      toastObj[`button_url`] = mainObj[item][`toast_button_link`] || ""
      toastObj[`button_pixel`] = mainObj[item][`pixel_component`] || ""
      toastObj[`toggle_survey`] = mainObj[item][`toast_button_link`]?.includes('/SurveyResult') ? true : false;
      toastCounter = toastCounter + 1
    }
    return toastObj
  })

  let filteredQuestions = questions.filter(obj => !(obj && Object.keys(obj).length === 0));
  const filteredToasts = toasts.filter(obj => !(obj && Object.keys(obj).length === 0));
  filteredQuestions = filteredQuestions.map((item) => {
    const questionObj = {...item};
    questionObj.answer.forEach((itemObject)=>{
      if(itemObject.answer1_branch === 'Toast 6'){
        let toastObj = filteredToasts.filter((itemObject)=> itemObject.button_url.includes('/SurveyResult'));
        toastObj.length && (itemObject.answer1_branch = 'Toast ' + toastObj[0].id.split('Toast')[1]);
      }
    });
    if(item.next_question === 'Toast 6'){
      let toastObj = filteredToasts.filter((itemObject)=> itemObject.button_url.includes('/SurveyResult'));
      toastObj.length && (questionObj.next_question = 'Toast ' + toastObj[0].id.split('Toast')[1]);
    }
    return questionObj;
  });
  return { questions: filteredQuestions, toasts: filteredToasts };
}

export const compare = (firstArrayOfObject = [], secondArrayOfObject = []) => {
  if (Array.isArray(firstArrayOfObject) && Array.isArray(secondArrayOfObject)) { //check if both are arrays
    if (firstArrayOfObject.length !== secondArrayOfObject.length) { //if length is not same then both are not same
      return false  
    }
  
    //if length is same - go deep down
    const compareResult = firstArrayOfObject.every((item, i) => compareElement(firstArrayOfObject[i], secondArrayOfObject[i]))
    return compareResult
  }
}

const compareElement = (obj, source) => Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]);

export const RTRBuilderTaxononyPayload = (allQuestions) => {
  let taxonomy_mapping = {};
  const newQuestions = allQuestions.map((question, index) => {
    if(question?.taxonomy_question){
      taxonomy_mapping[`Q${question.ID}`] = question.taxonomy_question;
      taxonomy_mapping[`Q${question.ID}_taxonomy`] = question.taxonomy;
      taxonomy_mapping[`Q${question.ID}_taxonomy_question_label`] = question.taxonomy_question_label;
    }
    if(question.hasOwnProperty('answer')){
      question.answer.forEach((ans, ansIndex) => {
        if(ans?.taxonomy_answer){
          taxonomy_mapping[`Q${question.ID}_ANS${ansIndex + 1}`] = ans.taxonomy_answer;
          taxonomy_mapping[`Q${question.ID}_ANS${ansIndex + 1}_taxonomy`] = ans.taxonomy;
          taxonomy_mapping[`Q${question.ID}_ANS${ansIndex + 1}_taxonomy_answer_label`] = ans.taxonomy_answer_label;
        }
      })
    }
  })
  return taxonomy_mapping;
}

export const RTRBuilderTaxononyResponse = (question,taxonomyObject) => {
  question.forEach((dataObject,index)=>{
    if(Object.keys(taxonomyObject).indexOf(`Q${dataObject.ID}`)>-1){
      dataObject['taxonomy_question'] = taxonomyObject[`Q${dataObject.ID}`];
      dataObject['taxonomy'] = taxonomyObject[`Q${dataObject.ID}_taxonomy`];
      dataObject['taxonomy_question_label'] = taxonomyObject[`Q${dataObject.ID}_taxonomy_question_label`];
    }
    if(dataObject.hasOwnProperty('answer')){
      dataObject?.answer.forEach((ans, ansIndex) => {
        if(Object.keys(taxonomyObject).indexOf(`Q${dataObject.ID}_ANS${ansIndex+1}`)>-1){
          ans['taxonomy_answer'] = taxonomyObject[`Q${dataObject.ID}_ANS${ansIndex+1}`];
          ans['taxonomy'] = taxonomyObject[`Q${dataObject.ID}_ANS${ansIndex+1}_taxonomy`];
          ans['taxonomy_answer_label'] = taxonomyObject[`Q${dataObject.ID}_ANS${ansIndex+1}_taxonomy_answer_label`];
        }
      })
    }
  });
  return question;
}