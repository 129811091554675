import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  Table,
  UncontrolledTooltip, Button
} from "reactstrap";
import './index.scss';
import Loader from "../../Loader";

const ReportTable = ({ tableLoaded, jsonData }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [headerRows, setHeaderRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);



  const generateTableCol = () => {
    let headerRow = []
    let cols = jsonData[0];
    for (let key in cols) {
      headerRow.push({ key: key, class: key.toLowerCase() === ('Advertiser_VZ').toLowerCase() || key.toLowerCase() === ('Campaign_VZ').toLowerCase() || key.toLowerCase() === ('Ad_VZ').toLowerCase() || key.toLowerCase() === ('Line_VZ').toLowerCase() ? 'sticky-col resized-column' : '' });
    }

    setHeaderRows(headerRow);
  }

  const updateTableData = (data) => {
    let rowCells = [];
    for (let key in data) {
      rowCells.push({ value: data[key], class: key.toLowerCase() === ('Advertiser_VZ').toLowerCase() || key.toLowerCase() === ('Campaign_VZ').toLowerCase() || key.toLowerCase() === ('Ad_VZ').toLowerCase() || key.toLowerCase() === ('Line_VZ').toLowerCase() ? 'campaign-name' : '' })
    }
    return rowCells;
  }

  const generateTableData = () => {
    let data = [];

    for (let record of jsonData) {
      data.push(updateTableData(record));
    }

    setTableRows(data);
  }

  /* Resize column setup for table-main start*/
  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("table-main");
    if (!isLoading && table) {

      var tableHeight = table.offsetHeight;
      // For resizing column, add dummy div to header, add event when clicked
      if (headerRows.length > 0) {
        Array.prototype.forEach.call(
          table.querySelectorAll(".resized-column"),
          function (th) {
            //th.style.position = 'relative';

            var grip = document.createElement('div');
            grip.className = 'column-resizer';
            grip.innerHTML = "&nbsp;";
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.height = tableHeight + 'px';
            grip.style.position = 'absolute';
            grip.style.zIndex = "1";
            grip.style.cursor = 'col-resize';
            grip.addEventListener('mousedown', function (e) {
              thElm = th;
              startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
          });
      }
      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            thElm.style.width = startOffset + e.pageX + 'px';
            thElm.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  }, [headerRows, tableLoaded])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (jsonData) {
      generateTableCol();
      generateTableData();
      setIsLoading(false);
    }
  }, [jsonData]);

  return (
    <>
      <div className="report-table dashboard">
        <Card className="data-card card dashboard-table-data-container">
          {!tableLoaded ?
            <div
              style={{
                maxWidth: "100%",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "750px",
                borderRadius: "16px",
                whiteSpace: "nowrap"
              }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="report-header">Campaigns</h2>
              </div>
              <div>
                <Table className="dashboard-table mb-0" borderless={true} id="table-main">
                  <thead>
                    <tr>
                      {headerRows.map((data, index) => {
                        {
                          if (data.class !== '') {
                            return (<th className={`report-column-header ${data.class}`}>{data.key.replace('_VZ','')}
                              <div className="resizer-icon">
                                <span className="first"></span>
                                <span className="second"></span>
                              </div></th>)
                          }
                          else
                            return <th key={index} className={`report-column-header ${data.class}`}><div>{data.key}</div></th>;
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRows && tableRows.length > 0
                        ?
                        tableRows.map((data, index) => {
                          return (<tr className={"report-row td-line"} key={`tr-${index}`}>
                            { (data.map((col, i) => {
                              return (
                                <td className={col.class}
                                  id={`tooltip-${index}-${i}`}>
                                  {col.value}
                                  {col.class !== '' && <UncontrolledTooltip
                                    className="ad-tool-tip"
                                    placement="top"
                                    target={`tooltip-${index}-${i}`}>
                                    {col.value}
                                  </UncontrolledTooltip>}
                                </td>)

                            }))}
                          </tr>)
                        })
                        :
                        (
                          <div className="no-data-available-txt" 
                          style={{ margin: "auto", width: "90%", textAlign:"center" }}>
                         <div>   No data available</div>
                          </div>
                        )
                    }
                  </tbody>
                </Table>
              </div>
            </div>
            :
              <Loader />
          }
        </Card>
      </div>
    </>);

}

export default ReportTable;