import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import {
  Button, Card, CardBody, Label, Input, FormGroup, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, FormFeedback, CustomInput, Tooltip, UncontrolledTooltip
} from "reactstrap";
import Loader from '../../Loader';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import ADspinner from "../../Loader/ADspinner";
import { useLocation } from "react-router-dom";
import { notificationActionCreator } from "../../../actions";
import RBAC from "../../../components/RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";
import PlatformToggle from './PlatformToggle';

const Preview = (props) => {
  const dispatch = useDispatch();
  const {
    draftCalled,
    surveyName,
    isPDP,
    markSurveyComplete,
    copyLink,
    preview,
    activationData,
    adActivated,
    activateExternalAd,
    setActiveStep,
    isPreviewFetching,
    shadow,
    height,
    width,
    previewHtml,
    reloadIframe,
    msgbodyStyle,
    activating,
    role,
    handleBackClick
  } = props;

  const location = useLocation();
  const [isOfferValid, setIsOfferValid] = useState(false);
  const bothPlatformData = useSelector((state) => state.realtimeResearch.bothAdsData);
  const defaultLink = `http://${window.location.host}/brands/${localStorage.getItem('brand_id')}/messages/${localStorage.getItem('msg_id')}/preview-share?ad_id=${localStorage.getItem("ad_id")}`
  const [shareableLink, setShareableLink] = useState(defaultLink)
  window.closeSurvey = (exitSurvey) => {
    markSurveyComplete();
  }

  window.navigateToPDP = () => {
  }

  const activateAd = () => {
    if (!isOfferValid && isPDP) {
      dispatch(notificationActionCreator.setNotification({
        // color: "error",
        message: "Please edit survey and update the Offer details to activate"
      }));
    } else {
      activateExternalAd();
    }
  }

  useEffect(() => {
    if (bothPlatformData?.ads?.length > 1) {
      if (activationData.platformState === 2) {
        const programmaticAd = bothPlatformData?.ads?.find(ad => ad.platform_value === 2);
        const newLink = `${shareableLink}&ad1_id=${programmaticAd?.message}&msg1_id=${programmaticAd?.brand_offer?.[0]?.id}`
        setShareableLink(newLink)
      }
    }
  }, [bothPlatformData])

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      setIsOfferValid(location.state.detail.isOffer);
    }
  }, [location]);


  useEffect(() => {
    return () => {
      var iframe = document.getElementsByTagName('iframe')[0],
        iDoc = iframe?.contentWindow
          || iframe?.contentDocument;
      if (iDoc) {
        iDoc.removeEventListener('mouseup');
      }
    }
  }, [])

  return (
    <>
      <div className='right-container'>
        <div className="dashboard real-time">

          <section className="title-section dashboard-header-section">
            <>
              <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
                <Button className="btn-transparent" onClick={() => (+preview === 1) ? history.push(`${ROUTES.REALTIME_RESEARCH}`) : handleBackClick()}>
                  <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
                </Button>
                <h2 id={"survey-header"}
                  className="mb-0 pl-4 secondary-title" style={{ fontSize: "32px" }}>
                  {`Preview (${draftCalled ? surveyName : location?.state?.detail?.ad_name})`}</h2>
              </nav>
              {/* showing activate button for inreview and draft */}
              {
                (activationData.ad_state === 0 || activationData.ad_state === 1
                  || activationData.ad_state === 7) &&
                <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
                  <div className="text-right form-group" id="btn-activate">
                    <RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_WRITE} >
                      <Button className="in-btn-sm in-btn-info btn-export ml-3 btn-next"
                        style={{ minWidth: '144px', minHeight: '48px' }}
                        disabled={(!(Object.keys(adActivated).length === 0 && adActivated.constructor === Object))}
                        onClick={activateAd}>
                        {activating ?
                          <ADspinner size="large" /> :
                          `Activate`}
                      </Button>
                    </RBAC>
                  </div>
                  {/* <UncontrolledTooltip
                    className="ad-tool-tip"
                    placement="top"
                    target={`btn-activate`}>
                    Please edit survey and update the Offer details to activate
                    </UncontrolledTooltip> */}
                </nav>
              }
            </>
          </section>

          <section>


            <Row>
              <Col>
                {activationData.platformState === 2 && (
                  <PlatformToggle
                    data={{}}
                    selectPlatform={props.selectedPlatform}
                    handlePlatformChange={props.handlePlatformChange}
                  />
                )}
                <div id="reload-survey" style={{ marginBottom: "20px" }}>
                  <img onClick={reloadIframe}
                    src="/Icon_assets/svg/reload-icon.svg"
                    alt="share"
                    className="link-image"
                  />
                  <span className="reload-survey" onClick={reloadIframe}>Reload Survey</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {isPDP &&
                  <div style={{
                    background: "#9d5cff", color: "#fff",
                    fontSize: "18px", height: "15%", paddingTop: "40px"
                  }} className="survey-text">
                    <p>Survey</p>
                  </div>}
                <div className="iframe-div" style={msgbodyStyle}>
                  {
                    isPreviewFetching ?
                      <Loader />
                      :
                      <iframe frameBorder="0" title="previewIframe" name="frame"
                        // style={{ width: `${width}`, height: `${height}`, boxShadow: `${shadow}` }}
                        style={{
                          width: isPDP ? `100%` : `${width}`,
                          height: isPDP ? `100%` : `${height}`,
                          boxShadow: `${shadow}`,
                          minHeight: isPDP ? `620px` : `${height}`
                        }}
                        srcdoc={previewHtml} scrolling='no'>
                      </iframe>

                  }
                </div>
              </Col>
            </Row>

            <FormGroup row>
              <Col>
                <div id="link-div" style={{ paddingTop: "50px" }}>
                  <Label for="shareable-link" className="shareable-link">Sharelabel Link:</Label>
                  <Input
                    type="text"
                    name="link"
                    id="shareable-link"
                    className="share-input"
                    autoComplete="off"
                    value={shareableLink}
                    //value={`http://${window.location.host}/brands/${localStorage.getItem('brand_id')}/messages/${localStorage.getItem('msg_id')}/preview-share?ad_id=${localStorage.getItem("ad_id")}`}
                    readOnly={true} />
                  <img
                    src="/Icon_assets/svg/share-icon.svg"
                    alt="share"
                    className="link-image"
                    style={{ cursor: "pointer" }}
                    onClick={copyLink}
                  />
                </div>
              </Col>
            </FormGroup>
          </section>

        </div>
      </div>
    </>
  );
}

export default Preview;