import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import "./index.scss";
import {
  Button, Card, CardBody, Label, Input, FormGroup, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, FormFeedback, CustomInput, Tooltip, UncontrolledTooltip
} from "reactstrap";

const OfferSetup = (props) => {
  const imageLoading = useSelector((state) => state.offer.imageLoading);
  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
  };

  const titleLimit = 60;
  const offerNameLimit = 60;
  const subTitleLimit = 100;
  const buttonLabelLimit = 20;

  const {
    handleInputChange,
    validators,
    inputValues,
    handleOfferImageUpload,
    removeOfferSelection,
    mediaUrl,
    characterEntered,
    descriptionLimit
  } = props;

  const UNITS = {
    INVISIBLY: "invisibly",
    TEXT: "text"
  };

  const uploadLogo = (
    <Button className="export-icon" style={{ opacity: "1" }} onClick={() => console.log('export button')}>
      <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
      <span className="span-label">Upload Image/Video</span>
    </Button>);

  return (
    <div className="create-offer-form">
      <Card className={(inputValues.advertiser === "" ? `ad-disabled ` : ``) + `data-card`}
      >
        <CardBody>
          <section id="header-section">
            <nav className="left-nav-section" style={{ marginTop: "19px" }}>
              <h2 className="form-heading" style={{ paddingLeft: 0 }}>Invisibly Survey Details</h2>
            </nav>
          </section>
          <div className="ad-form">
            <Table className="ad-table">
              <Row>
                <Col md={12} sm={12} lg={4}>
                  <FormGroup >
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      required
                      className="form-input form-input-sm mt-2 required"
                      autoComplete="off"
                      value={inputValues.brand_offer?.title}
                      onChange={handleInputChange}
                      invalid={validators.title.errorMsg !== ""}
                    />
                    <Label className="floating-heading-label required">Survey Title</Label>
                    <span className="character-label">{`Maximum ${titleLimit} characters allowed`}</span>
                    <FormFeedback>{validators.title.errorMsg}</FormFeedback>
                  </FormGroup></Col>
                <Col md={12} sm={12} lg={4}>
                  <FormGroup >
                    <Input
                      type="text"
                      name="subtitle"
                      id="subtitle"
                      required
                      className="form-input form-input-sm mt-2 required"
                      autoComplete="off"
                      value={inputValues.brand_offer?.subtitle}
                      onChange={handleInputChange}
                      invalid={validators.subtitle.errorMsg !== ""}
                    />
                    <Label className="floating-heading-label">Survey Subtitle</Label>
                    <span className="character-label">{`Maximum ${subTitleLimit} characters allowed`}</span>
                    <FormFeedback>{validators.subtitle.errorMsg}</FormFeedback>
                  </FormGroup></Col>
              </Row>
              <Row>
                <Col md={12} sm={12} lg={4}>
                  <FormGroup style={{ marginTop: "16px" }}>
                    <Input
                      type="text"
                      name="button_text"
                      id="button_text"
                      required
                      className="form-input form-input-sm mt-2 required"
                      autoComplete="off"
                      value={inputValues.brand_offer?.button_text}
                      onChange={handleInputChange}
                      invalid={validators.button_text.errorMsg !== ""}
                    />
                    <Label className="floating-heading-label required">Button Label</Label>
                    <span className="character-label">{`Maximum ${buttonLabelLimit} characters allowed`}</span>
                    <FormFeedback>{validators.button_text.errorMsg}</FormFeedback>
                  </FormGroup></Col>
                <Col md={12} sm={12} lg={4}>
                  <FormGroup style={{ marginTop: "16px" }}>
                    <Input
                      type="text"
                      name="monetary"
                      id="monetary"
                      required
                      className="form-input form-input-sm mt-2 required"
                      autoComplete="off"
                      value={inputValues.brand_offer?.monetary}
                      onChange={handleInputChange}
                      invalid={validators.monetary.errorMsg !== ""}
                    />
                    <Label className="floating-heading-label required">Invisibly Points</Label>
                    <FormFeedback>{validators.monetary.errorMsg}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={24} sm={24} lg={8}>
                  <FormGroup>
                    <Label className="floating-heading-label required" style={{ top: "-25px" }}>Description</Label>
                    <span className="character-label" style={{ position: "absolute", top: "-20px", left: "100px" }}>{`(${characterEntered} of ${descriptionLimit})`}</span>
                    <Input type="textarea" className="form-area" onChange={(e) => handleInputChange(e)}
                      name="description" id="description" style={{}} value={inputValues.brand_offer?.description} />
                    <FormFeedback>{validators.description.errorMsg}</FormFeedback>
                  </FormGroup>
                </Col>

              </Row>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Card className={(inputValues.advertiser === "" ? `ad-disabled ` : ``) + `data-card`}>
        <CardBody>
          <section id="header-section">
            <nav className="left-nav-section" style={{ marginTop: "19px" }}>
              <h2 className="form-heading" style={{ paddingLeft: "0" }}>Invisibly Survey Media Upload</h2>
            </nav>
          </section>
          <div className="ad-form">
            <Table className="ad-table">
              <Row>
                <Col md={24} sm={24} lg={8}>
                  <FormGroup style={{ position: "relative" }} id="type" >
                    <div style={{ display: "flex" }}>
                      <div className={`floating-label required`} id="media-label-1" style={{ top: "2px", position: "inherit", fontSize: "16px" }}>Select Media</div>
                      <div>
                        <img src={"/Icon_assets/svg/info-icon.svg"}
                          style={{ padding: "6px" }} alt="Info icon" id="opentooltip" />
                        <UncontrolledTooltip
                          placement="top"
                          className="ad-tool-tip"
                          target="opentooltip"
                        ><div style={{ padding: "10px", marginLeft: "10px" }}>
                            <ul style={{ textAlign: "left", marginBottom: "0", fontSize: "12px" }}>
                              <li>Supported image files types: gif, jpg,and png</li>
                              <li>Recommended image file size: not more than 1MB</li>
                            </ul></div>
                        </UncontrolledTooltip>
                      </div>
                      {((inputValues["media_1_type"] &&
                        inputValues["media_1_type"].toLowerCase() === "image" && inputValues["media_1_size"] >= 2000000))
                        &&
                        <div>
                          <img src={"/Icon_assets/png/warning-icon.png"}
                            style={{ padding: "6px", height: "28px" }} alt="Info icon" id="warningTip" />
                          <UncontrolledTooltip
                            placement="top"
                            className="ad-tool-tip"
                            target="warningTip"
                          ><div style={{ fontSize: "12px" }}>Uploaded file is larger than the recommended file size which may slow down your offer rendering</div>
                          </UncontrolledTooltip>
                        </div>}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={24} sm={24} lg={8}>
                  {
                    ((imageLoading && mediaUrl === "media_1_url") ?
                      <Loader style={{ padding: "0px" }} />
                      :
                      (inputValues.brand_offer && inputValues.brand_offer["media_1_name"] ?
                        <>
                          <div className="selection-div">
                            <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                            <span className="span-label">{inputValues.brand_offer?.media_1_name}</span>
                            <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close"
                              name="media_1_name" onClick={(e) => removeOfferSelection(e)} />
                          </div>
                        </>

                        :
                        <div>
                          <Button className="export-icon offer-upload" style={{ opacity: "1",padding:"0" }} >
                            <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
                            <span className="span-label">Upload Image</span>
                          </Button>
                          <Input
                            type="file"
                            name="media_1_url"
                            id="logoImage"
                            //value={inputValues["media_1_url"]}
                            className="custom-file-upload-input"
                            onChange={(e) => handleOfferImageUpload(e)}
                          />
                        </div>))
                  }
                </Col>
              </Row>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )

}

export default OfferSetup;