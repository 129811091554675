import React from 'react';
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from '../../components/HeaderNavigation';
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";
import OfferList from "../../components/OfferList"
import "./index.scss";

import history from '../../history';
import CreateOffer from '../../components/OfferList/CreateOffer';
import RTROfferPreview from '../../components/OfferList/CreateOffer/RTROfferPreview';
import BrandOfferPreview from '../../components/OfferList/CreateOffer/BrandOfferPreivew';
import checkRBAC from '../../utils/checkRBAC'
import { PERMISSIONS } from "../../utils/permissionMatrix";

const Offers = () => {
  /**
    states required for RBAC 
     */
  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);


  function isAuthorized(requiredPermissions) {
    return checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization)
  }

  return (
    <Router basename="/" history={history}>
      <>
        <Notifications />
        <HeaderNavigation />
        <div className="conversion-container" style={{
          marginTop: (window.location.pathname === ROUTES.CREATE_OFFERS || window.location.pathname.includes('offers/edit-offer')) ? "0" : "40px",
          flexDirection: (window.location.pathname.includes('offers/preview') ||
            window.location.pathname.includes('offers/offer-preview'))
            ? "column" : "inherit"
        }}>
          <Switch>
            <Route exact path={ROUTES.OFFERS_LIST} component={OfferList} />
            {/* {isAuthorized(PERMISSIONS.OFFER_WRITE) && <> */}
              <Route exact path={ROUTES.CREATE_OFFERS} component={CreateOffer} />
              <Route exact path={ROUTES.EDIT_OFFERS} component={CreateOffer} />
            {/* </>} */}
            <Route exact path={ROUTES.OFFER_PREVIEW} component={RTROfferPreview} />
            <Route exact path={ROUTES.BRAND_OFFER_PREVIEW} component={BrandOfferPreview} />
          </Switch>
        </div>
      </>
    </Router>);
}

export default Offers;