import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const SaveChangesModal = ({ closeModal, saveUserRoleChanges }) => {
  return (
    <section className="remove-user-content">
      <nav className="remove-user-section">
        <p>Changes to the user will be applied immediately.</p>
      </nav>
      <nav className="btn-wrapper remove-user">
        <Button className="in-btn-sm in-btn-primary" onClick={saveUserRoleChanges}>
          Save
        </Button>
        <Button color="link" className="in-btn-link" onClick={closeModal}>
          Back to edit
        </Button>
      </nav>
    </section>
  )
}

export default SaveChangesModal;