import { takeLatest, call, select, put } from 'redux-saga/effects';
import { authActionCreator, notificationActionCreator, organizationActionCreator } from '../actions';
import { encrypt } from "../utils/crypto";
import ACTIONS from '../constants/actionTypes';
import axios from 'axios';

import history from '../history';
import ROUTES from '../utils/routeConstants';

function sendSignInRequest(API_URL_PARAM, userDetails) {
    const API_URL = API_URL_PARAM + "/v1/users/third-party-login";
    return axios({
        url: API_URL,
        method: "POST",
        data: userDetails,
        withCredentials: true,
    }).catch((err) => {
        console.log(err);
    });
}

function sendSignUpRequest(API_URL_PARAM, userDetails) {
    const API_URL = API_URL_PARAM + "/v1/users/third-party-signup";
    return axios({
        url: API_URL,
        method: "POST",
        data: userDetails,
        withCredentials: true,
    }).catch((err) => {
        console.log(err);
    });
}

function sendResetPasswordRequest(API_URL_PARAM, userDetails) {
    const API_URL = API_URL_PARAM + "/v1/users/reset-password";
    return axios({
        url: API_URL,
        method: "PUT",
        data: userDetails,
        withCredentials: true,
    }).catch((err) => {
        console.log(err);
    });
}

function* sendResetPasswordRequestSaga(action) {
    try {
        const API_URL = yield select((state) => state.config.API_URL);
        const userData = action.payload;
        const result = yield call(sendResetPasswordRequest, API_URL, userData);
        const { item, status } = result.data;

        if (status.status_type.toUpperCase() === 'SUCCESS') {
            yield put(authActionCreator.resetPasswordSuccess(status.status_type));
        } else {
            const errorMsgList = status && status.status_message ? status.status_message : null;
            let errorMsg = "";
            if (typeof errorMsgList === 'object') {
                errorMsg = Object.entries(errorMsgList).map(err => {
                    if (err instanceof Object)
                        return (`${err[0]}: ${JSON.stringify(err[1])}`);
                    else
                        return `${err[0]}: ${err[1]}`;
                });
                yield put(notificationActionCreator.setNotification({ message: errorMsg.join() }));
                yield put(authActionCreator.resetPasswordFailureClear());
            } else {
                errorMsg = status && status.status_message ? status.status_message : null;
                yield put(notificationActionCreator.setNotification({ message: errorMsg }));
                yield put(authActionCreator.resetPasswordFailureClear());
            }
        }
    } catch (error) {
        yield put(authActionCreator.signInFailure('Server Error'));
        yield put(notificationActionCreator.setNotification({ message: 'Server Error', }));
    }
}

function* sendSignInRequestSaga(action) {
    try {
        const API_URL = yield select((state) => state.config.API_URL);
        const userData = action.payload;
        const result = yield call(sendSignInRequest, API_URL, userData);
        const { item, status } = result.data;

        if (status.status_type.toUpperCase() === 'SUCCESS') {
            const organization_roles = item.organization_roles
            delete item['organization_roles']
            localStorage.setItem('user', JSON.stringify(item));
            
            if (item.organization_role === 0) {
                //If user is admin removed organizations as admin has full access
                item.organizations = [];
            }

            yield put(authActionCreator.signInSuccess(item));
            /*set RBAC to the loggedin user start*/
            yield put(authActionCreator.setRBAC(organization_roles));
            //If user have more than 1 orgs set 1st as selected org

            if (item?.organizations?.length) {
                yield put(organizationActionCreator.setdOrganization(item.organizations[0]))
                localStorage.setItem('selectedOrganization', JSON.stringify(item.organizations[0]));
                const encryptRBAC = encrypt(JSON.stringify(organization_roles), item.token);
                localStorage.setItem('$ifb_aes', encryptRBAC);
            }
            /*set RBAC to the loggedin user end*/
            yield put(authActionCreator.signInFailureClear());
            // delete item['organization_roles']
            // localStorage.setItem('user', JSON.stringify(item));
            if (item.organization_role === 0) {
              history.push(ROUTES.DASHBOARD);
            }else{
              if (item.organizations[0].is_media_enabled) {
                history.push(ROUTES.DASHBOARD);
              }else{
                history.push(ROUTES.REALTIME_RESEARCH);
              }
            }

        } else {
            const errorMsgList = status && status.status_message ? status.status_message : null;
            let errorMsg = "";
            if (typeof errorMsgList === 'object') {
                errorMsg = Object.entries(errorMsgList).map(err => {
                    if (err instanceof Object)
                        return (`${err[0]}: ${JSON.stringify(err[1])}`);
                    else
                        return `${err[0]}: ${err[1]}`;
                });
                yield put(authActionCreator.signInFailure(errorMsg.join()));
                yield put(notificationActionCreator.setNotification({ message: errorMsg.join() }));
            } else {
                errorMsg = status && status.status_message ? status.status_message : null;
                yield put(authActionCreator.signInFailure(errorMsg));
                yield put(notificationActionCreator.setNotification({ message: errorMsg }));
            }
        }
    } catch (error) {
        yield put(authActionCreator.signInFailure('Server Error'));
        yield put(notificationActionCreator.setNotification({ message: 'Server Error', }));
    }
}

function* sendSignUpRequestSaga(action) {
    try {
        const API_URL = yield select((state) => state.config.API_URL);

        const userData = action.payload;
        const result = yield call(sendSignUpRequest, API_URL, userData);
        const { item, status } = result.data;
        if (status.status_type.toUpperCase() === 'SUCCESS') {
            const organization_roles = item.organization_roles
            delete item['organization_roles']
            localStorage.setItem('user', JSON.stringify(item));

            if (item.organization_role === 0) {
                //If user is admin removed organizations as admin has full access
                item.organizations = [];
            }

            yield put(authActionCreator.signUpSuccess(item));
            /*set RBAC to the loggedin user start*/
            yield put(authActionCreator.setRBAC(organization_roles));

            //If user have more than 1 orgs set 1st as selected org
            if (item?.organizations?.length) {
                yield put(organizationActionCreator.setdOrganization(item.organizations[0]));
                localStorage.setItem('selectedOrganization', JSON.stringify(item.organizations[0]));
                const encryptedRBAC = encrypt(JSON.stringify(organization_roles), item.token);
                localStorage.setItem('$ifb_aes', encryptedRBAC);
            }
            /*set RBAC to the loggedin user end*/
            yield put(authActionCreator.signUpFailureClear());
            //delete item['organization_roles']
            //localStorage.setItem('user', JSON.stringify(item));
            //history.push(ROUTES.DASHBOARD);

            if (item.organization_role === 0) {
              history.push(ROUTES.DASHBOARD);
            }else{
              if (item.organizations[0].is_media_enabled) {
                history.push(ROUTES.DASHBOARD);
              }else{
                history.push(ROUTES.REALTIME_RESEARCH);
              }
            }

        } else {
            const errorMsgList = status && status.status_message ? status.status_message : null;
            let errorMsg = "";
            if (typeof errorMsgList === 'object') {

                errorMsg = Object.entries(errorMsgList).map(err => {
                    if (err instanceof Object)
                        return (`${err[0]}: ${JSON.stringify(err[1])}`);
                    else
                        return `${err[0]}: ${err[1]}`;
                });
                yield put(authActionCreator.signUpFailure(errorMsg.join()));
                yield put(notificationActionCreator.setNotification({ message: errorMsg.join() }));
            } else {
                errorMsg = status && status.status_message ? status.status_message : null;
                yield put(authActionCreator.signUpFailure(errorMsg));
                yield put(notificationActionCreator.setNotification({ message: errorMsg }));
            }
        }
    } catch (err) {
        yield put(authActionCreator.signUpFailure('Server Error'));
        yield put(notificationActionCreator.setNotification({ message: 'Server Error' }));
    }
}

function* sendLogOutRequestSaga(action) {
    try {
        localStorage.clear();
        yield put(authActionCreator.logOutSuccess());
        history.push(ROUTES.SIGNIN);
    } catch (err) {
        console.log('Server Error')
    }
}

function sendForgotPasswordRequest(API_URL_PARAM, email) {
    const API_URL = API_URL_PARAM + "/v1/users/reset-password-link";
    return axios({
        url: API_URL,
        method: "POST",
        data: email,
        withCredentials: true,
    }).catch((err) => {
        console.log(err);
    });
}

function* sendForgotPasswordRequestSaga(action) {
    try {
        const API_URL = yield select((state) => state.config.API_URL);
        const email = action.payload;
        const result = yield call(sendForgotPasswordRequest, API_URL, email);
        const { item, status } = result.data;
        if (status.status_type.toUpperCase() === 'SUCCESS') {
            yield put(authActionCreator.forgotPasswordSuccess(item));
        } else {
            const errorMsgList = status && status.status_message ? status.status_message : null;
            let errorMsg = "";
            if (typeof errorMsgList === 'object') {

                errorMsg = Object.entries(errorMsgList).map(err => {
                    if (err instanceof Object)
                        return (`${err[0]}: ${JSON.stringify(err[1])}`);
                    else
                        return `${err[0]}: ${err[1]}`;
                });
                yield put(authActionCreator.forgotPasswordFailure(errorMsg.join()));
                yield put(notificationActionCreator.setNotification({ message: errorMsg.join() }));
            } else {
                errorMsg = status && status.status_message ? status.status_message : null;
                yield put(authActionCreator.forgotPasswordFailure(errorMsg));
                yield put(notificationActionCreator.setNotification({ message: errorMsg }));
            }
        }
    } catch (err) {
        yield put(authActionCreator.forgotPasswordFailure('Server Error'));
    }
}

export default function* authSagas() {
    yield takeLatest(ACTIONS.SIGNIN_REQUEST, sendSignInRequestSaga);
    yield takeLatest(ACTIONS.SIGNUP_REQUEST, sendSignUpRequestSaga);
    yield takeLatest(ACTIONS.LOG_OUT_REQUEST, sendLogOutRequestSaga);
    yield takeLatest(ACTIONS.RESET_PASSWORD_REQUEST, sendResetPasswordRequestSaga);
    yield takeLatest(ACTIONS.FORGOTPASSWORD_REQUEST, sendForgotPasswordRequestSaga);
}