const STATES = [
    {
        id: 0,
        state_label: 'Paused',
        transaction_state: [1]
    },
    {
        id: 1,
        state_label: 'Active',
        transaction_state: [0]
    },
    {
      id: 2,
      state_label: 'Completed',
      transaction_state: []
    }
];

export default STATES;