import React, { useEffect, useState } from 'react';
import { CustomInput, DropdownItem } from "reactstrap";
import '../index.scss';

const QuestionMenuItem = (props) => {
  const {name, index, item, getTaxonomySelection,data} = props;
  const [isSelected, setIsSelected] = useState(false);
  
  const isCategorySelected = (data) => {
    if(data?.taxonomy_question === item.id){
      return true;
    }else{
      return false;
    }
  }

  useEffect(() => {
    setIsSelected(isCategorySelected(data))
  }, [])

  return (
    <section onClick={(event) => {getTaxonomySelection(event, data, name, item)}}>
      <DropdownItem toggle={true}>
        <CustomInput 
          type="radio"
          name={name}
          className={"custom-radio-input"}
          id={`radio-${item.id}"`}
          label={item.display_value}
          value={item.id}
          key={item.taxonomy + "_" + index}
          onChange={(event) => {
            setIsSelected(!isSelected)
            getTaxonomySelection(event, data, name, item)
          }}
          checked={isSelected}
        />
      </DropdownItem>
    </section>
  )
}

export default QuestionMenuItem;