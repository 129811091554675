import { takeLatest, call, select, put } from "redux-saga/effects";
import { organizationActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";
import history from "../history";
import ROUTES from "../utils/routeConstants";

function addOrganizationRequest(API_URL_PARAM, jwtToken, orgData) {
  const API_URL = API_URL_PARAM + "/v1/organizations";
  return axios({
    url: API_URL,
    method: "POST",
    data: orgData,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* addOrganizationRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      addOrganizationRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(organizationActionCreator.addOrganizationSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
      history.push(ROUTES.ORGANIZATIONS_LIST);
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.addOrganizationFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.addOrganizationFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.addOrganizationFailure("Server Error"));
  }
}

function getOrganizationListRequest(API_URL_PARAM, jwtToken, payload) {
  var param = {
    source: 1,
    limit: 200
  }

  if (payload?.search) {
    param.name = payload.search
  }

  if (payload?.limit) {
    param.limit = payload.limit;
    param.offset = payload.offset;
  }

  if (payload?.role_id) {
    param.role_id = payload.role_id;
  }

  const API_URL = API_URL_PARAM + "/v1/organizations";

  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      'Authorization': jwtToken
    },
    params: param
  }).catch((err) => {
    console.log(err);
  });
}

function* getOrganizationListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getOrganizationListRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(organizationActionCreator.getOrganizationListSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.getOrganizationListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.getOrganizationListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.getOrganizationListFailure('Server Error'));
  }
}

function* getOrganizationForRoleListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getOrganizationListRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(organizationActionCreator.getOrganizationForRoleListSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.getOrganizationForRoleListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.getOrganizationForRoleListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.getOrganizationListFailure('Server Error'));
  }
}

function getOrganizationRequestById(API_URL_PARAM, jwtToken, id) {
  //const API_URL =  searchKey ? API_URL_PARAM + "/v1/users?type=2&limit=200&search=" + searchKey :API_URL_PARAM + "/v1/users?type=2&limit=200";
  const API_URL = API_URL_PARAM + "/v1/organizations/" + id;
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      'Authorization': jwtToken
    }
  }).catch((err) => {
    console.log(err);
  });
}


function* getOrganizationRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;

    let id = action.payload;
    const result = yield call(getOrganizationRequestById, API_URL, jwtToken, id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(organizationActionCreator.getOrganizationByIdSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.getOrganizationByIdFailure(errorMsg.join()));
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.getOrganizationByIdFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.getOrganizationByIdFailure('Server Error'));
  }
}

function updateOrganizationRequest(API_URL_PARAM, jwtToken, orgData, id) {
  const API_URL = API_URL_PARAM + "/v1/organizations/" + id;
  return axios({
    url: API_URL,
    method: "PATCH",
    data: orgData,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* updateOrganizationRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(updateOrganizationRequest, API_URL, jwtToken, action.payload, action.id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(organizationActionCreator.updateOrganizationSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
      history.push(ROUTES.ORGANIZATIONS_LIST);
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.updateOrganizationFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.updateOrganizationFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.updateOrganizationFailure('Server Error'));
  }
}

function uploadOrganizationPicRequest(API_URL_PARAM, jwtToken, file) {
  const formData = new FormData();
  formData.append("file", file);
  const API_URL = API_URL_PARAM + "/v1/organizations/upload-third-party-org-logo";
  return axios({
    method: "POST",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
      "Content-Type": "multiplart/form-data",
    },
    data: formData,
  }).catch((err) => {
    console.log(err);
  });
}

function* uploadOrganizationPicRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const file = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(uploadOrganizationPicRequest, API_URL, jwtToken, file);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(organizationActionCreator.uploadOrganizationPicSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(organizationActionCreator.uploadOrganizationPicFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(organizationActionCreator.uploadOrganizationPicFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(organizationActionCreator.uploadOrganizationPicFailure("Server Error"));
  }
}

export default function* organizationSagas() {
  yield takeLatest(ACTIONS.ADD_ORGANIZATION_REQUEST, addOrganizationRequestSaga);
  yield takeLatest(ACTIONS.GET_ORGANIZATION_LIST_REQUEST, getOrganizationListRequestSaga);
  yield takeLatest(ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_REQUEST, getOrganizationForRoleListRequestSaga);
  yield takeLatest(ACTIONS.GET_ORGANIZATION_REQUEST, getOrganizationRequestSaga);
  yield takeLatest(ACTIONS.UPDATE_ORGANIZATION_REQUEST, updateOrganizationRequestSaga);
  yield takeLatest(ACTIONS.UPLOAD_ORGANIZATION_PIC_REQUEST, uploadOrganizationPicRequestSaga);
}