import { takeLatest, call, select, put } from "redux-saga/effects";
import { scheduleReportActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";
import history from "../history";
import ROUTES from "../utils/routeConstants";

function sheduleReportRequest(API_URL_PARAM, jwtToken, data, isAdmin, organization_id, method, id,) {
  let API_URL;
  let params = {};
  if (method === "GET") {
    params.limit = organization_id ? 20 : 10; //if org selected or Not a Super Admin set linit to 20 else default page limit is 10
  }
  if (data?.offset) {
    params.offset = data?.offset;
  }
  if (method === "POST") {
    API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/schedule` : isAdmin && "/v1/schedule"}`;
  } else {
    if (id)
      API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/schedule/${id}` : isAdmin && `/v1/schedule/${id}`}`;
    else
      API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/schedule` : isAdmin && `/v1/schedule`}`;
  }

  return axios({
    url: API_URL,
    method: method,
    data: data,
    params: params,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function deleteSheduleReportRequest(API_URL, jwtToken, isAdmin, organization_id, id) {

  let URL;
  if (organization_id)
    URL = `${API_URL}/v1/organizations/${organization_id}/schedule?ids=${id}`
  else
    URL = isAdmin && `${API_URL}/v1/schedule?ids=${id}`

  return axios({
    url: URL,
    method: 'DELETE',
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* SheduleReportListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;

    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;

    let jwtToken = userData && userData.token ? userData.token : null;
    let method = action.payload?.method;
    let id = action.payload?.id
    delete action.payload.method;
    delete action.payload.id;

    const result = yield call(
      sheduleReportRequest,
      API_URL,
      jwtToken,
      action.payload,
      isAdmin,
      organization_id,
      method,
      id
    );

    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(scheduleReportActionCreator.getScheduleReportListRequestSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(scheduleReportActionCreator.getScheduleReportListRequestFailure(errorMsg.join()));
        yield put(notificationActionCreator.setNotification({ message: errorMsg.join(), position: "sticky" })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(scheduleReportActionCreator.getScheduleReportListRequestFailure(errorMsg));
        yield put(notificationActionCreator.setNotification({ message: errorMsg, position: "sticky" }));
      }
    }
  } catch (error) {
    yield put(scheduleReportActionCreator.getScheduleReportListRequestFailure("Server Error"));
    yield put(notificationActionCreator.setNotification({ message: "Server Error", position: "sticky" }));
  }
}



function* SheduleReportRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;

    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;

    let jwtToken = userData && userData.token ? userData.token : null;
    let method = action.payload?.method;
    let id = action.payload?.id
    delete action.payload.method;
    delete action.payload.id;

    const result = yield call(
      sheduleReportRequest,
      API_URL,
      jwtToken,
      action.payload,
      isAdmin,
      organization_id,
      method,
      id
    );

    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      if (method === "POST" || method === "PUT") {
        //POST
        yield put(scheduleReportActionCreator.saveScheduleReportRequestSuccess(item));
        yield put(notificationActionCreator.setNotification({ color: "success", message: status.status_message }));
      } else if (method === "GET") {
        //GET
        yield put(scheduleReportActionCreator.getScheduleReportRequestSuccess(item));
      } else {
        //PATCH
        //yield put(scheduleReportActionCreator.saveScheduleReportRequestSuccess(item));
      }

      //yield put(notificationActionCreator.setNotification({color: "success",message: status.status_message, position: "sticky"}));
      //history.push(ROUTES.ORGANIZATIONS_LIST);
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            //return `${err[0]}: ${JSON.stringify(err[1])}`;
            return `${err[1]}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(scheduleReportActionCreator.saveScheduleReportRequestFailure(errorMsg.join()));
        yield put(notificationActionCreator.setNotification({ message: errorMsg.join(), position: "sticky" })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(scheduleReportActionCreator.saveScheduleReportRequestFailure(errorMsg));
        yield put(notificationActionCreator.setNotification({ message: errorMsg, position: "sticky" }));
      }
    }
  } catch (error) {
    yield put(scheduleReportActionCreator.saveScheduleReportRequestFailure("Server Error"));
  }
}

function* SheduleReportDeleteRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;
    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;
    let id = action.payload

    const result = yield call(
      deleteSheduleReportRequest,
      API_URL,
      jwtToken,
      isAdmin,
      organization_id,
      id
    );
    const status = result?.data?.status;
    if (status?.status_type?.toUpperCase() === "SUCCESS") {
      yield put(scheduleReportActionCreator.deleteScheduleReportRequestSuccess(status?.status_message));
      yield put(notificationActionCreator.setNotification({ color: "success", message: status?.status_message, position: "absolute" }));
    }
    else {
      yield put(scheduleReportActionCreator.deleteScheduleReportRequestFailure(status?.status_message));
      yield put(notificationActionCreator.setNotification({ message: status?.status_message, position: "absolute" }));
    }
  }
  catch (e) {
    yield put(scheduleReportActionCreator.deleteScheduleReportRequestFailure("Server Error"));
  }
}

export default function* sheduleReportSagas() {
  yield takeLatest(ACTIONS.SAVE_SHEDULE_REPORT_REQUEST, SheduleReportRequestSaga);
  yield takeLatest(ACTIONS.GET_SHEDULE_REPORT_REQUEST, SheduleReportRequestSaga);
  yield takeLatest(ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST, SheduleReportListRequestSaga);
  yield takeLatest(ACTIONS.DELETE_SHEDULE_REPORT_REQUEST, SheduleReportDeleteRequestSaga);
}