import React, { useEffect, useState } from 'react';
import { CustomInput, DropdownItem } from "reactstrap";
import '../index.scss';

const AnswerMenuItem = (props) => {
  const {name, index, item, getTaxonomySelection,data,answerId} = props;
  const [isSelected, setIsSelected] = useState(false);
  
  const isCategorySelected = (data) => {
    const setIsSelectedAnsData = data?.answer.filter((objectData)=> objectData.id === answerId && objectData.taxonomy_answer === item.id)
    if(setIsSelectedAnsData?.length){
      return true;
    }else{
      return false;
    }
  }

  useEffect(() => {
    setIsSelected(isCategorySelected(data))
  }, [])

  return (
    <section onClick={(event)=>getTaxonomySelection(event, data, "taxonomy_answer",item,answerId)}>
      <DropdownItem toggle={true}>
        <CustomInput 
          type="radio"
          name={name}
          className={"custom-radio-input"}
          id={`radio-${item.id}"`}
          label={item.display_value}
          value={item.id}
          key={item.taxonomy + "_" + index}
          onChange={(event) => {
            setIsSelected(!isSelected)
            getTaxonomySelection(event, data, "taxonomy_answer",item,answerId)
          }}
          checked={isSelected}
        />
      </DropdownItem>
    </section>
  )
}

export default AnswerMenuItem;