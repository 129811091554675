import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card, CardBody, Button, Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  CustomInput,
  Badge,
  Spinner,
  Tooltip,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import "./index.scss";

import Validator from "../../../utils/validators";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

import CLIENT_SIDE_MESSAGES from "../../../utils/clientSideMessages";
import ADspinner from "../../Loader/ADspinner";

import { advertiserActionCreator, notificationActionCreator, organizationActionCreator, realtimeResearchActionCreator } from "../../../actions";
import Loader from '../../Loader';

const EditOrganization = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  //validations
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  const [selectedRTRList, setSelectedRTRList] = useState([]);
  const [selectedMediaList, setSelectedMediaList] = useState([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [modalName, setModalName] = useState('');
  const [currentList, setCurrentList]= useState([]);

  //Tooltip setup
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  //Default Icons setup
  const icons = {
    CAMERA: "/Icon_assets/svg/camera-icon.svg",
    INFO: "/Icon_assets/svg/info-icon.svg",
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    CLOSE: "/Icon_assets/svg/close_icon_black.svg",
  }

  //Modals setup
  const [openMediaPopUp, setOpenMediaPopUp] = useState(false);
  const [openRTRPopUp, setOpenRTRPopUp] = useState(false);
  const [openClearFormPopUp, setOpenClearFormPopUp] = useState(false);

  const closeMediaBtn = (
    <button className="close" onClick={() => { setOpenMediaPopUp(false) }}>
      <img src={icons.CLOSE} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>
  );
 
  const clearcloseBtn = (
    <button className="close" onClick={() => { setOpenClearFormPopUp(false) }}>
      <img src={icons.CLOSE} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>
  );
  //Modal setup end


  /*switch setup*/
  const [includeMediaDashboard, setIncludeMediaDashboard] = useState(true);
  const toggleIncludeMediaDashboard = () => {
    if (includeRTR === false && includeMediaDashboard === true) {
      dispatch(notificationActionCreator.setNotification({
        message: CLIENT_SIDE_MESSAGES.DASHBOARD_RTR_INCLUDE
      }));
    } else {
      setIncludeMediaDashboard(prevState => !prevState);
    }
  };

  const [includeRTR, setincludeRTR] = useState(true);
  const toggleIncludeRTR = () => {
    if (includeMediaDashboard === false && includeRTR === true) {
      dispatch(notificationActionCreator.setNotification({
        message: CLIENT_SIDE_MESSAGES.DASHBOARD_RTR_INCLUDE
      }));
    } else {
      setincludeRTR(prevState => !prevState);
    }
  };
  /*switch setup end*/


  //Form initial setup
  const initialState = {
    name: '',
    source: 1,
    advertisers: [],
    logo: "",
  };

  const [inputValues, setInputValues] = useState(initialState);

  const [adList, setAdList] = useState([]);
  const [rtrList, setRTRList] = useState([]);
  const [noOfMedia, setNoOfMedia] = useState(0);
  const [noOfRTR, setNoOfRTR] = useState(0);
  const [ImgSizeLargeErr, showImgSizeLargeErr] = useState(false);
  const [serverMsg, setserverMsg] = useState(false);

  const backToOrgList = (e) => {
    e.preventDefault();
    history.push(ROUTES.ORGANIZATIONS_LIST)
  }

  const advertisersList = useSelector((state) => state.advertiser.advertiserData?.results);
  const realtimeResearchList = useSelector((state) => state.realtimeResearch.rtrList?.results);
  const isLoading = useSelector((state) => state.advertiser?.isLoading);
  const isFetched = useSelector((state) => state.advertiser.isFetched);
  const isRTRFetched = true //useSelector((state) => state.realtimeResearch.isFetched);

  const orgData = useSelector((state) => state.organization.organizationDataById);
  const isOrgUpdating = useSelector((state) => state.organization.isOrgUpdating);
  const isOrgUpdated = useSelector((state) => state.organization.isOrgUpdated);
  const errorMsg = useSelector((state) => state.organization.errorMsg);

  const organizationPic = useSelector((state) => state.organization.organizationPic);
  const isorganizationPicUploaded = !!organizationPic;
  const isUpLoadingPic = useSelector((state) => state.organization.isUpLoadingPic);
  const organizationPicErrors = useSelector((state) => state.organization.organizationPicErrors);

  //Update Icons if profile_pic uploaded
  const imgCameraprofile = isorganizationPicUploaded ? organizationPic : (inputValues.logo ? inputValues.logo : icons.CAMERA);
  const imgInfoDelete = isorganizationPicUploaded ? icons.DELETE : icons.INFO;


  /** 
   * On Component load get the Organization edit data and advertisers List data
  */
  useEffect(() => {
    setstate(validatorsObj);
    dispatch(organizationActionCreator.uploadOrganizationPicClear());
    dispatch(advertiserActionCreator.advertisersListRequest());
    dispatch(realtimeResearchActionCreator.rtrListRequest());
    dispatch(organizationActionCreator.getOrganizationRequest(id));
  }, []);

  /**
   * Executed when AdvertisersList and OrganizationData is availble on store
   * isOrgUpdating will check false here because on load OrganizationData has advertisers array of object
   * but when update advertisers its only an array
   */
  //
  useEffect(() => {
    loadInitialData();
  }, [advertisersList, realtimeResearchList, orgData]);

  const loadInitialData = () => {
    if (isFetched && isRTRFetched && orgData?.name && !isOrgUpdating) {

      if (advertisersList) {
        const selectedAds = orgData.advertisers;
        const newlist = [...advertisersList];
        const newAdList = newlist.map((d, i) => {
          const isSelected = selectedAds.findIndex(ad => ad.id === d.id);

          return {
            selected: isSelected !== -1 ? true : false,
            id: d.id,
            name: d.name
          }
        });
        setAdList(newAdList);
        let ad_list = JSON.parse(JSON.stringify(newAdList));
        let selected = ad_list.filter((data) => { return data.selected === true; });
        setNoOfMedia(selected.length);
        setSelectedMediaList(selected);
      }

      if (realtimeResearchList) {
        const selectedRTRs = orgData.brands;
        const newrealtimeResearchList = [...realtimeResearchList];
        const newRTRlist = newrealtimeResearchList.map((d, i) => {
          const isRTRSelected = selectedRTRs.findIndex(ad => ad.id === d.id);

          return {
            selected: isRTRSelected !== -1 ? true : false,
            id: d.id,
            name: d.name
          }
        });
        let data = sortArrayData(newRTRlist, 'name', true );
        setRTRList(data);
        let rtr_list = JSON.parse(JSON.stringify(newRTRlist));
        let selected = rtr_list.filter((data) => { return data.selected === true; });
        setNoOfRTR(selected.length);
        setSelectedRTRList(selected);
      }

      setIncludeMediaDashboard(orgData?.is_media_enabled);
      setincludeRTR(orgData?.is_rtr_enabled);

      const inputFields = { ...inputValues };
      inputFields['name'] = orgData.name;
      inputFields['logo'] = orgData.logo;
      setInputValues(inputFields);

      setIsValid(true);
    }
  }

  //when adList element Removed/Checked/Uncheck it executed to count NumberOfMedia Selected
  // useEffect(() => {
  //   if (id) {
  //     if (adList.length > 0) {
  //       let ad_list = JSON.parse(JSON.stringify(adList));
  //       let selected = ad_list.filter((data) => { return data.selected === true; });
  //       setNoOfMedia(selected.length);
  //       setSelectedMediaList(selected);
  //     }

  //     if (rtrList.length > 0) {
  //       let rtr_list = JSON.parse(JSON.stringify(rtrList));
  //       let selected = rtr_list.filter((data) => { return data.selected === true; });
  //       setNoOfRTR(selected.length);
  //       setSelectedRTRList(selected);
  //     }
  // }
  // }, [adList, rtrList])

  const sortArrayData = (orders, prop, asc) => {
    // var orders = [...new Set([...dashboardData])]
    orders.sort(function(a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
              return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
          }
      });
      return orders;
  }

const goBackToModal = () => {
    setOpenCancelModal(false);
    if(modalName === "rtr_modal") {
        setOpenRTRPopUp(true);
    } else {
        setOpenMediaPopUp(true);
    }
}

const cancelAdvertiserModal = () => {
    setOpenCancelModal(false);
    if (modalName === 'rtr_modal') {
        setOpenRTRPopUp(false);    
        setRTRList(currentList);
    } else {
        setAdList(currentList);
        setOpenMediaPopUp(false);
    }
}

const closeRTRBtn = (
  <button className="close" onClick={cancelAdvertiserModal }>
    <img src={icons.CLOSE} alt="Close" style={{ width: '16px', height: '16px' }} />
  </button>
);

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = validators.name.valid && noOfMedia > 0;
    setIsValid(checkValidation);
  }

  /***Handle checkbox change event start**/
  const handleMediaCheckboxChange = (e, id) => {
    let checked = e.target.checked;
    setAdList(
      adList.map((data) => {
        if (data.id === id) {
          data.selected = checked;
        }
        return data;
      })
    )
    const checkValidation = (validators.name.touched ? validators.name.valid : true) && adList.length > 0;
    setIsValid(checkValidation);
  }

  const handleRTRCheckboxChange = (e, id) => {
    let checked = e.target.checked;
    setRTRList(
      rtrList.map((data) => {
        if (data.id === id) {
          data.selected = checked;
        }
        return data;
      })
    )
    //const checkValidation = validators.name.valid && (adList.length > 0 || noOfRTR.count > 0);
    const checkValidation = (validators.name.touched ? validators.name.valid : true) && rtrList.length > 0;
    setIsValid(checkValidation);
  }
  /***Handle checkbox change event end**/

  /***Remove selected elements from list start**/
  const removeSelectedMedia = (e, id) => {
    e.preventDefault();
    let checked = false;
    setAdList(
      adList.map((data) => {
        if (data.id === id) {
          data.selected = checked;
        }
        return data;
      })
    );
    let ad_list = JSON.parse(JSON.stringify(adList));
    let selected = ad_list.filter((data) => { return data.selected === true; });
    setNoOfMedia(selected.length);
    setSelectedMediaList(selected);
  }

  const removeSelectedRTR = (e, id) => {
    e.preventDefault();
    let checked = false;
    setRTRList(
      rtrList.map((data) => {
        if (data.id === id) {
          data.selected = checked;
        }
        return data;
      })
    );
    let rtr_list = JSON.parse(JSON.stringify(rtrList));
    let selected = rtr_list.filter((data) => { return data.selected === true; });
    setNoOfRTR(selected.length);
    setSelectedRTRList(selected);
  }
  /***Remove selected elements from list end**/

  /***Verizon Advertisers/ClearDemand Brands popup save click handle start***/
  const handalADSave = (e) => {
    e.preventDefault();
    if (adList.length > 0) {
    let ad_list = JSON.parse(JSON.stringify(adList));
            const selected = ad_list.filter(item => item.selected === true);
            setNoOfMedia(selected.length);
            setSelectedMediaList(selected);
    }
    setOpenMediaPopUp(false);
  }

  const handalRTRSave = (e) => {
    e.preventDefault();
    if (rtrList.length > 0) {
      let rtr_list = JSON.parse(JSON.stringify(rtrList));
      let selected = rtr_list.filter(item => item.selected === true);
      setNoOfRTR(selected.length);
      setSelectedRTRList(selected);
  }
  setOpenRTRPopUp(false);
  }
  /***Verizon Advertisers/ClearDemand Brands popup save click handle start***/

  //Clear form
  const clearForm = (e) => {
    e.preventDefault();
    loadInitialData();
    setOpenClearFormPopUp(false);
  }

  //Form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const advertisers = adList.filter(res => res.selected).map(ele => ele.id);
    const brands = rtrList.filter(res => res.selected).map(ele => ele.id);
    const is_media_enabled = includeMediaDashboard ? 1 : 0;
    const is_rtr_enabled = includeRTR ? 1 : 0;
    const inputFields = { ...inputValues };

    let report_type = 0;

    if (is_media_enabled && advertisers.length > 0) {
      report_type = 0;
    }

    if ((is_rtr_enabled && brands.length > 0)) {
      report_type = 1;
    }

    if (((!is_rtr_enabled && brands.length > 0)
      && (is_media_enabled && advertisers.length > 0))
      || (is_rtr_enabled && brands.length > 0 && !is_media_enabled && advertisers.length > 0)
      || (is_rtr_enabled && brands.length > 0 && is_media_enabled && advertisers.length > 0)) {
      report_type = 2;
    }
    if (organizationPic) {
      inputFields["logo"] = organizationPic;
    } else {
      delete inputFields.logo;
    }
    setInputValues(inputFields);

    const formData = { ...inputFields, advertisers, brands, is_media_enabled, is_rtr_enabled, report_type };

    if (isValid) {
      dispatch(organizationActionCreator.updateOrganizationRequest(formData, id));
    }
  }

  useEffect(() => {
    if (!isOrgUpdated && errorMsg) {
      setTimeout(() => {
        dispatch(organizationActionCreator.updateOrganizationFailureClear());
      }, 3000);
    }
  }, [isOrgUpdated, errorMsg]);

  const handleUploadFile = (e) => {
    if (e.target.files.length !== 0) {
      if ((e.target.files[0].size / (1024 * 1024)).toFixed(2) <= 2.5) {
        dispatch(organizationActionCreator.uploadOrganizationPic(e.target.files[0]));
      } else {
        dispatch(notificationActionCreator.setNotification({
          message: CLIENT_SIDE_MESSAGES.IMAGE_MAX_SIZE
        }));
      }
    }
  };

  useEffect(() => {
    if (errorMsg) {
      setserverMsg(true);
      setTimeout(() => {
        setserverMsg(false);
        //dispatch(createUserFailureClear());
      }, 3000);
    }

    if (organizationPicErrors) {
      setserverMsg(true);
      setTimeout(() => {
        setserverMsg(false);
        dispatch(organizationActionCreator.uploadOrganizationPicFailureClear());
      }, 3000);
    }
  }, [errorMsg, isorganizationPicUploaded, organizationPicErrors]);

  const pageTitle = `Edit ${orgData?.name ? orgData.name : ''} Organization`;

  return (
    <>
      <section className="title-section-with-back pl-5 pb-3">
        <Button className="btn-transparent" onClick={backToOrgList}>
          <img src="/Icon_assets/svg/back_icon.svg" alt="go to back" className="go-back" />
        </Button>

        <h2 className="mb-0 pl-4 secondary-title" id="organization-name">{pageTitle}</h2>
        <UncontrolledTooltip
          className="ad-tool-tip"
          placement="top"
          target={"organization-name"}
        >{pageTitle}
        </UncontrolledTooltip>
      </section>

      { isLoading ?
        <Loader /> :
        (<Form
          className="form-container ad-form"
          onSubmit={handleFormSubmit}
        >
          <Card className="data-card mb-4">
            <CardBody className="pb-0">
              <Row>
                <Col md={12} sm={12} lg={6}>
                  <h4 className="fs-22 pb-4">Organization Setup</h4>
                  <FormGroup>
                    {/* <Label className="floating-label">Organization Name</Label> */}
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="form-input form-input-sm mt-2"
                      autoComplete="off"
                      value={inputValues.name}
                      title={inputValues.name}
                      onChange={handleInputChange}
                      invalid={validators.name.errorMsg !== ""}
                    //placeholder="Organization Name"
                    />
                    <Label className="floating-label">Organization Name</Label>
                    <FormFeedback>{validators.name.errorMsg}</FormFeedback>
                  </FormGroup>

                  <FormGroup className="custom-file-upload-wrapper">
                    <Label className="file-label">
                      Account Logo <span className="sub-text-mute">Optional</span>
                    </Label>
                    <div className="custom-file-upload-container">
                      <div className="upload-text">
                        {isorganizationPicUploaded ? "Reload" : (orgData.logo ? "Reload" : "Upload")}
                      </div>
                      <div className="images-container">
                        {isUpLoadingPic ? (
                          <Spinner
                            color="secondary"
                            size="sm"
                            className="mr-2"
                          />
                        ) : (
                          <img
                            src={imgCameraprofile}
                            alt="Camera Icon"
                            className="user-profile-picture mr-2 rounded"
                          />
                        )}
                        <img src={imgInfoDelete} alt="Info icon" id="opentooltip" />
                        <Tooltip
                          placement="top"
                          className="custom-file-upload-input-tooltip"
                          isOpen={tooltipOpen}
                          target="opentooltip"
                          toggle={toggle}
                        >
                          {isorganizationPicUploaded
                            ? "Delete"
                            : "If left blank, the Invisibly logo will be used"}
                        </Tooltip>
                      </div>
                    </div>
                    <Input
                      type="file"
                      name="file"
                      id="file"
                      className="custom-file-upload-input"
                      title=""
                      onChange={(e) => handleUploadFile(e)}
                      style={{ width: "12%", height: "50%", top: "20px" }}
                    />
                    <FormText className="sub-text">
                    jpeg or png file not less than 40X40px and not more than 192x40px
                    </FormText>
                  </FormGroup>

                </Col>
              </Row>

            </CardBody>
          </Card>

          <Card className={`data-card mb-4 ${!includeMediaDashboard ? 'ad-disabled' : ''}`}>
            <CardBody className="pb-0">
              <Row>
                <Col md={12} sm={12} lg={12} className="media-switch d-flex mb-4">
                  <h4 className="fs-22">Media Setup</h4>
                  <div className="media-switch-toggle d-flex">
                    <div className="toggle-title">{includeMediaDashboard ? 'Include' : 'Exclude'} Media Dashboard</div>
                    <CustomInput type="switch"
                      id="includeMediaDashboard"
                      name="includeMediaDashboard"
                      onChange={toggleIncludeMediaDashboard}
                      checked={includeMediaDashboard}
                    />
                  </div>
                </Col>
                <Col md={12} sm={12} lg={6}>
                  {/* <h4 className="fs-22 pb-4">Media Setup</h4> */}
                  <FormGroup>
                    {/* <Label className="floating-label">Verizon Advertisers</Label> */}
                    <Input
                      type="text"
                      name="noOfMedia"
                      id="noOfMedia"
                      className="form-input form-input-sm mt-2"
                      autoComplete="off"
                      //placeholder="Verizon Advertisers"
                      required
                      readOnly={true}
                      value={
                        noOfMedia == 0 ? "" :
                          `${noOfMedia} Item${noOfMedia > 1 ? 's' : ''} Selected`}
                    />
                    <span className={`textbox-option ${!includeMediaDashboard ? 'disabled-color' : ''}`} onClick={() => { 
                      setOpenMediaPopUp(true);
                        setModalName('media_modal');
                        setCurrentList(JSON.parse(JSON.stringify(adList)));
                        }}>
                      Choose
                    </span>
                    <Label className={`floating-label ${noOfMedia != 0 ? 'floating-label-active' : ''}`}>Verizon Advertisers</Label>
                    {/* <FormFeedback>{validators.name.errorMsg}</FormFeedback> */}
                  </FormGroup>
                  <div className="selected-advertisers">
                    {selectedMediaList && (
                      <>
                        {selectedMediaList.map((data) => {
                          {
                            if (data.selected) {
                              return (
                                <Badge key={data.id} color="dark ad-badge">
                                  { data.name}
                                  <img
                                    src="/Icon_assets/svg/close_icon.svg"
                                    alt="close icon"
                                    className="ml-2"
                                    onClick={(e) => { removeSelectedMedia(e, data.id) }}
                                  />
                                </Badge>
                              )
                            }
                          }

                        })}
                      </>
                    )}
                  </div>
                </Col>
              </Row>

            </CardBody>
          </Card>

          <Card className={`data-card mb-4 ${!includeRTR ? 'ad-disabled' : ''}`}>
            <CardBody className="pb-0">
              <Row>
                <Col md={12} sm={12} lg={12} className="media-switch d-flex mb-4">
                  <h4 className="fs-22">Advertiser Setup</h4>
                  <div className="media-switch-toggle d-flex">
                    <div className="toggle-title">{includeRTR ? 'Include' : 'Exclude'} Research & Offer Dashboard</div>
                    <CustomInput type="switch"
                      id="includeRTR"
                      name="includeRTR"
                      onChange={toggleIncludeRTR}
                      checked={includeRTR}
                    />
                  </div>
                </Col>

                <Col md={12} sm={12} lg={6}>

                  <FormGroup>
                    {/* <Label className="floating-label">Verizon Advertisers</Label> */}
                    <Input
                      type="text"
                      name="noOfRTR"
                      id="noOfRTR"
                      className="form-input form-input-sm mt-2"
                      autoComplete="off"
                      //placeholder="Verizon Advertisers"
                      required
                      readOnly={true}
                      value={
                        noOfRTR == 0 ? "" :
                          `${noOfRTR} advertiser${noOfRTR > 1 ? 's' : ''} selected`}
                    />
                    <span className={`textbox-option ${!includeRTR ? 'disabled-color' : ''}`} onClick={() => { 
                        setOpenRTRPopUp(true); 
                        setModalName('rtr_modal');
                        setCurrentList(JSON.parse(JSON.stringify(rtrList)));
                      }}>
                      Choose
                    </span>
                    <Label className={`floating-label ${noOfRTR != 0 ? 'floating-label-active' : ''}`}>Invisibly Advertisers</Label>
                    {/* <FormFeedback>{validators.name.errorMsg}</FormFeedback> */}
                  </FormGroup>
                  <div className="selected-advertisers">
                    {selectedRTRList && (
                      <>
                        {selectedRTRList.map((data) => {
                          {
                            if (data.selected) {
                              return (
                                <Badge key={data.id} color="dark ad-badge">
                                  { data.name}
                                  <img
                                    src="/Icon_assets/svg/close_icon.svg"
                                    alt="close icon"
                                    className="ml-2"
                                    onClick={(e) => { removeSelectedRTR(e, data.id) }}
                                  />
                                </Badge>
                              )
                            }
                          }

                        })}
                      </>
                    )}
                  </div>
                </Col>
              </Row>

            </CardBody>
          </Card>

          <FormGroup className="text-right">
            <Button
              className="in-btn-lg in-btn-secondary btn-transparent cancel-org-btn"
              onClick={() => setOpenClearFormPopUp(true)}
            >Cancel
            </Button>
            <Button
              className="in-btn-lg in-btn-primary ml-3 create-org-btn"
              type="submit"
              onClick={handleFormSubmit}
              disabled={!((((includeMediaDashboard && noOfMedia) && (includeRTR && noOfRTR)
                || ((includeMediaDashboard && noOfMedia) && (!includeRTR))
                || (includeRTR && noOfRTR) && (!includeMediaDashboard))
                && (validators.name.valid || isValid)))}
            >
              {isOrgUpdating ? <ADspinner /> : "Save"}
            </Button>
          </FormGroup>
        </Form>)}

      <Modal isOpen={openMediaPopUp} className="ad-pop-up-modal modal-dialog-scrollable organization-list-modal" centered>
        <ModalHeader>{"Verizon Advertisers"}</ModalHeader>
        <ModalBody>

          {isLoading ? (
              <Loader />
          ) :
            (
              <>
              <Form className="ad-form">
                <FormGroup >
                  {
                    adList.map((d, i) => {
                      return <CustomInput
                        key={i}
                        className="mt-3"
                        type="checkbox"
                        name={d.name}
                        value={d.id}
                        id={i} label={d.name}
                        checked={d.selected}
                        onChange={(e) => handleMediaCheckboxChange(e, d.id)}
                      />
                    })
                  }
                </FormGroup>
              </Form>
              <FormGroup className="footer-button mt-4">
                  <Button
                    className="in-btn-lg in-btn-primary mr-4 btn-submit"
                    type="submit"
                    disabled={false}
                    onClick={handalADSave}
                  >
                    Submit
                  </Button>
                  <Button color="link"
                  style= {{marginTop:"24px"}}
                    className="in-btn-link btn-modal-cancle"
                    onClick={() => {
                      setOpenMediaPopUp(false);
                      setOpenCancelModal(true);
                  }}
                  >
                    Cancel
                  </Button>
                </FormGroup>
                </>
            )
          }
        </ModalBody>
      </Modal>

      <Modal isOpen={openRTRPopUp} className="ad-pop-up-modal modal-dialog-scrollable organization-list-modal" centered>
        <ModalHeader>{"ClearDemand Advertisers"}</ModalHeader>
        <ModalBody >

          {isLoading ? (
              <Loader />
          ) :
            (
              <>
                <Form className="ad-form">
                <FormGroup >
                  {
                    rtrList.map((d, i) => {
                      return <CustomInput
                        key={i}
                        className="mt-3"
                        type="checkbox"
                        name={d.name}
                        value={d.id}
                        id={i}
                        label={d.name}
                        checked={d.selected}
                        onChange={(e) => handleRTRCheckboxChange(e, d.id)}
                      />
                    })
                  }
                </FormGroup>
              </Form>
                <FormGroup className="footer-button mt-4">
              <Button
                className="in-btn-lg in-btn-primary mr-4 btn-submit"
                type="submit"
                disabled={false}
                onClick={handalRTRSave}
              >
                Submit
              </Button>
              <Button color="link"
              style= {{marginTop:"24px"}}
                className="in-btn-link btn-modal-cancle"
                onClick={() =>{
                  setOpenRTRPopUp(false);
                  setOpenCancelModal(true);
                 }}
              >
                Cancel
              </Button>
            </FormGroup>
              </>
            )
          }
        </ModalBody>
      </Modal>

      <Modal isOpen={openClearFormPopUp} className="ad-pop-up-modal" centered>
        <ModalHeader close={clearcloseBtn}>{"Cancel your changes"}</ModalHeader>
        <ModalBody>
          <Form className="ad-form">

            <p className="mt-2 mb-2">Are you sure you want to cancel your changes?</p>

            <FormGroup className="footer-button mt-4">
              <Button
                className="in-btn-lg in-btn-primary mr-4"
                disabled={false}
                onClick={clearForm}
              >
                Cancel
                            </Button>
              <Button
                color="link"
                className="in-btn-link btn-modal-cancle"
                onClick={() => history.push(ROUTES.ORGANIZATIONS_LIST)}
              >
                Back to organizations.
                            </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    
      <Modal isOpen={openCancelModal} className="ad-pop-up-modal organization-list-modal" centered>
                <ModalHeader close={closeRTRBtn}>{"Cancel Setup"}</ModalHeader>
                <ModalBody>
                    <Form className="ad-form">

                        <p className="mt-2 mb-2" style={{marginBottom:"40px !important"}}>Cancelling will not save any of your new changes.</p>

                        <FormGroup className="footer-button mt-4" style={{boxShadow : "none"}}>
                            <Button
                                color="link"
                                style ={{marginTop : "24px"}}
                                className="in-btn-lg in-btn-primary mr-4 cancel-btn"
                                onClick={cancelAdvertiserModal}
                            >
                                Yes, I'm sure
                            </Button>
                            <Button
                                style={{marginTop: "24px"}}
                                className="in-btn-link btn-modal-cancle"
                                disabled={false}
                                onClick={goBackToModal}
                            >
                                No, go back
                            </Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
            </Modal>
    </>
  )
}

export default EditOrganization;