import { takeLatest, call, select, put } from "redux-saga/effects";
import { adActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendadListRequest(API_URL_PARAM, jwtToken, payload) {
    let API_URL = "";
    let encodeString = "";
    const params = {};

    params.ad_template_type = 5;
    params.source = 1;
    params.limit = 100;

    if (payload?.searchKey) {
      encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
      params.search = encodeString;
    }

    if (payload.user === 1) {
      params.organization_id = payload.organization_id;
    }

    if (payload?.organization) {
      params.organization_id = payload.organization;
    }

    API_URL = API_URL_PARAM + "/v1/dsp_ads";

    return axios({
      url: API_URL,
      method: "GET",
      params: params,
      headers: {
        Authorization: jwtToken,
      },
      withCredentials: true,
    }).catch((err) => {
      console.log(err);
    });
  }
  
  function* sendadListRequestSaga(action) {
    try {
      const API_URL = yield select((state) => state.config.API_URL);
      const userData = yield select((state) => state.auth.userData);
      const data = action.payload ? action.payload : "";
      let jwtToken = userData && userData.token ? userData.token : null;
      const result = yield call(
        sendadListRequest,
        API_URL,
        jwtToken,
        data
      );
      const { item, status } = result.data;
      if (status.status_type.toUpperCase() === "SUCCESS") {
        yield put(adActionCreator.getAdListSuccess(item));
      } else {
        const errorMsgList =
          status && status.status_message ? status.status_message : null;
        let errorMsg = "";
        if (typeof errorMsgList === "object") {
          errorMsg = Object.entries(errorMsgList).map((err) => {
            if (err instanceof Object)
              return `${err[0]}: ${JSON.stringify(err[1])}`;
            else return `${err[0]}: ${err[1]}`;
          });
          yield put(adActionCreator.getAdListFailure(errorMsg.join()));
        } else {
          errorMsg =
            status && status.status_message ? status.status_message : null;
          yield put(adActionCreator.getAdListFailure(errorMsg));
        }
      }
    } catch (error) {
      yield put(adActionCreator.getAdListFailure("Server Error"));
    }
  }
  
  export default function*adSagas() {
    yield takeLatest(ACTIONS.GET_AD_LIST_REQUEST, sendadListRequestSaga);
  }