import React, { useEffect, useState } from 'react'
import { Button, Card } from 'reactstrap'
import { useParams } from "react-router-dom";
import Chart from './Chart';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { realtimeResearchActionCreator } from '../../../actions';

const Steps = [
  {
    id: 1,
    img: 'take-surveys.png',
    label: 'Take surveys.'
  },
  {
    id: 2,
    img: 'earn-points.png',
    label: 'Earn points.'
  },
  {
    id: 3,
    img: 'unlock-payments.png',
    label: 'Unlock paywalls.'
  }
]

const FooterLinks = [
  {
    id: 1,
    label: 'Home',
    link: 'https://www.invisibly.com/'
  },
  {
    id: 2,
    label: 'Brands',
    link: 'https://www.invisibly.com/brands'
  },
  {
    id: 3,
    label: 'Publishers',
    link: 'https://www.invisibly.com/publishers'
  },
  {
    id: 4,
    label: 'Contact',
    link: 'https://www.invisibly.com/contact'
  },
  {
    id: 5,
    label: 'Careers',
    link: 'https://www.invisibly.com/careers'
  },
  {
    id: 6,
    label: 'Terms',
    link: 'https://www.invisibly.com/tos'
  },
  {
    id: 7,
    label: 'FAQ',
    link: 'https://www.invisibly.com/faq'
  },
  {
    id: 8,
    label: 'Privacy',
    link: 'https://www.invisibly.com/privacy-policy'
  },
]

const FooterSocialLinks = [
  {
    id: 1,
    img: <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.07927 7.98459H4.89138V16H1.57655V7.98459H0V5.16766H1.57655V3.34478C1.57655 2.04123 2.19576 0 4.92089 0L7.37631 0.0102726V2.74459H5.59474C5.30251 2.74459 4.8916 2.89059 4.8916 3.51242V5.17028H7.36888L7.07927 7.98459Z" fill="#191818" />
    </svg>,
    link: 'https://www.facebook.com/GetInvisibly'
  },
  {
    id: 2,
    img: <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 1.53934C15.4115 1.8003 14.7784 1.9768 14.1145 2.05563C14.7924 1.6495 15.3126 1.00689 15.5581 0.239909C14.9238 0.616175 14.221 0.889134 13.4736 1.03626C12.8748 0.398544 12.0217 0 11.077 0C9.26442 0 7.79436 1.47006 7.79436 3.2826C7.79436 3.53989 7.82349 3.79032 7.8798 4.03072C5.15169 3.89388 2.73276 2.5871 1.11362 0.600508C0.831115 1.08522 0.669053 1.6495 0.669053 2.25099C0.669053 3.38958 1.249 4.39475 2.12932 4.98326C1.59148 4.96637 1.08498 4.81875 0.642615 4.57223C0.64237 4.58619 0.642369 4.60014 0.642369 4.61385C0.642369 6.20435 1.77435 7.53095 3.27574 7.83231C3.00058 7.90771 2.71 7.94761 2.41109 7.94761C2.19909 7.94761 1.9937 7.92729 1.79345 7.8891C2.21108 9.19294 3.42312 10.142 4.85988 10.1687C3.73622 11.0493 2.321 11.5739 0.782643 11.5739C0.518253 11.5739 0.256311 11.5585 0 11.5279C1.45194 12.4596 3.17782 13.0028 5.03149 13.0028C11.0694 13.0028 14.3713 8.00098 14.3713 3.66278C14.3713 3.52055 14.3681 3.37881 14.3618 3.23829C15.0036 2.77561 15.5601 2.19762 16 1.53934Z" fill="#191818" />
    </svg>,
    link: 'https://twitter.com/getinvisibly'
  },
  {
    id: 3,
    img: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 9.8088V16H12.5699V10.2237C12.5699 8.77337 12.0747 7.7829 10.8328 7.7829C9.88502 7.7829 9.32199 8.44972 9.07335 9.0954C8.98305 9.32615 8.95978 9.64658 8.95978 9.97022V15.9997H5.52945C5.52945 15.9997 5.57549 6.21663 5.52945 5.20395H8.96003V6.73381C8.95312 6.74586 8.9434 6.75764 8.93726 6.76915H8.96003V6.73381C9.41587 5.9998 10.2288 4.95044 12.0514 4.95044C14.3081 4.95044 16 6.49342 16 9.8088ZM1.94104 0C0.767666 0 0 0.806023 0 1.86502C0 2.90152 0.745411 3.73083 1.89602 3.73083H1.91827C3.11466 3.73083 3.85854 2.90152 3.85854 1.86502C3.83577 0.806023 3.11466 0 1.94104 0ZM0.203875 16H3.63292V5.20395H0.203875V16Z" fill="#191818" />
    </svg>,
    link: 'https://www.linkedin.com/company/invisibly/'
  },
  {
    id: 4,
    img: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5198 0.746689C12.2689 0.758811 13.0228 0.773356 13.7598 0.93578C14.9646 1.20245 15.9319 1.82305 16.6034 2.8679C17.0083 3.50063 17.2216 4.20608 17.3089 4.9479C17.4253 5.95154 17.4156 6.96245 17.418 7.97336C17.4204 9.20972 17.4228 10.4461 17.4034 11.6824C17.3913 12.4194 17.3768 13.1588 17.1998 13.8837C16.7731 15.6291 15.6992 16.7515 13.961 17.2267C13.4083 17.3794 12.841 17.4376 12.2713 17.4424C10.3198 17.457 8.37071 17.4691 6.41919 17.4618C5.68465 17.4594 4.94768 17.4352 4.2301 17.2534C2.46283 16.8049 1.3501 15.6946 0.901615 13.9249C0.765857 13.3891 0.714948 12.8437 0.7101 12.2934C0.695554 10.4267 0.683433 8.56245 0.685857 6.69578C0.688282 5.96608 0.705251 5.23396 0.850706 4.51396C1.13434 3.11275 1.86162 2.03154 3.14162 1.36002C3.75737 1.0376 4.42162 0.85578 5.11252 0.821841C6.37071 0.763659 10.6422 0.732144 11.5198 0.746689ZM6.05071 2.27154C5.56586 2.28123 5.08343 2.31033 4.60828 2.41942C3.7598 2.61578 3.09313 3.06184 2.66646 3.83517C2.37071 4.37336 2.25919 4.96487 2.23495 5.56851C2.20343 6.41942 2.18162 7.27275 2.18162 8.12366C2.18404 9.71396 2.13313 11.3067 2.25434 12.8946C2.29313 13.3915 2.40222 13.8764 2.63737 14.3224C3.1101 15.2243 3.89071 15.6994 4.8701 15.8352C5.44465 15.9152 6.02646 15.9443 6.60586 15.954C7.86647 15.9734 9.12707 15.9685 10.3877 15.9661C11.275 15.9661 12.1646 15.9685 13.0471 15.8643C13.5489 15.8061 14.0289 15.6679 14.458 15.394C15.2386 14.897 15.6507 14.16 15.7719 13.2631C15.8422 12.7418 15.8762 12.2109 15.8834 11.6849C15.9028 10.3637 15.898 9.04245 15.9004 7.72123C15.9004 6.84851 15.9028 5.9782 15.801 5.11033C15.7501 4.66184 15.6313 4.23033 15.4156 3.83275C14.9865 3.04972 14.3077 2.60366 13.4495 2.41214C12.7877 2.26427 12.1113 2.26427 11.4374 2.24972C10.584 2.2376 6.98889 2.25214 6.05071 2.27154Z" fill="#191818" />
      <path d="M13.3425 9.11022C13.3522 11.4714 11.4176 13.3769 9.1049 13.406C6.7049 13.4375 4.73884 11.4811 4.74611 9.10779C4.75339 6.73203 6.66611 4.81688 9.02733 4.80719C11.4007 4.79506 13.3546 6.73931 13.3425 9.11022ZM11.8322 9.11749C11.8516 7.60719 10.6249 6.35628 9.11945 6.31749C7.5243 6.27628 6.28066 7.56355 6.25157 9.04719C6.22248 10.5987 7.46369 11.8642 8.97642 11.8981C10.557 11.9345 11.8443 10.6399 11.8322 9.11749Z" fill="#191818" />
      <path d="M13.5129 5.63886C12.9651 5.64128 12.5117 5.18795 12.5117 4.64007C12.5117 4.08977 12.9602 3.63158 13.5057 3.62916C14.0535 3.62674 14.519 4.08007 14.519 4.62068C14.5214 5.18795 14.0754 5.63886 13.5129 5.63886Z" fill="#191818" />
    </svg>
    ,
    link: 'https://www.instagram.com/getinvisibly/'
  },
]

function StepCards() {
  return <>
    {
      Steps.map(step => <>
        <div className={`sr-step-card`} id={`sr-step-card ${step.id}`}>
          <div className={`sr-step-img-container`}>
            <div className={`sr-step`}>
              <img src={`/Icon_assets/png/${step.img}`} alt={step.label} className={`img-${step.id}`} />
            </div>
          </div>
          <div className='sr-step-text'>{step.label}</div>
        </div>
      </>)
    }
  </>
}

function FooterNavLinks() {
  return <>
    {
      FooterLinks.map(FooterLink => <>
        <a className='sr-ft-links' target="_blank" rel="noreferrer" href={FooterLink.link}>
          <div >{FooterLink.label}</div>
        </a>
      </>)
    }
  </>
}

function FooterNavSocialLinks() {
  return <>
    {
      FooterSocialLinks.map(FooterSocialLink => <>
        <a className='sr-ft-social-links-icon' target="_blank" rel="noreferrer" href={FooterSocialLink.link}><div className='sr-ft-social-links'>
          <div className='sr-ft-social-links-icon'>
            {FooterSocialLink.img}
          </div>
        </div>
        </a>
      </>)
    }
  </>
}


function RealtimeSurveyResults() {

  const dispatch = useDispatch();
  const SURVEY_RESULTS_REDIRECT_LINK = useSelector((state) => state.config.SURVEY_RESULTS_REDIRECT_LINK);
  const SURVEY_RESULTS_REDIRECT_QR = useSelector((state) => state.config.SURVEY_RESULTS_REDIRECT_QR);
  const RTRSurveyData = useSelector(state => state.realtimeResearch.RTRSurveyData);
  const isRTRSurveyDataLoading = useSelector(state => state.realtimeResearch.isRTRSurveyDataLoading);
  const [chartData, setChartData] = useState([]);
  const params = useParams();

  useEffect(() => {
    dispatch(realtimeResearchActionCreator.getRTRByAdIdSRequest({ id: params?.ad_id }))
  }, [params])

  useEffect(() => {
    if (!Object.keys(RTRSurveyData).includes('failed')) {
      setChartData(RTRSurveyData)
      attachEvents();
    }
  }, [RTRSurveyData])

  function attachEvents() {
    const slider = document.querySelector('.chart-list');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }

  const goTo = (dir) => {
    let El = document.getElementById("chart-list");
    let card = document.getElementById('chart0')

    if (dir === 'next') {
      El.scrollTo({ left: El.scrollLeft + card.scrollWidth + (getIsSmallSizeDevice() ? 34 : 136), behavior: 'smooth' });
    }
    else {
      El.scrollTo({ left: El.scrollLeft - card.scrollWidth - (getIsSmallSizeDevice() ? 34 : 136), behavior: 'smooth' });
    }
  }

  const getIsSmallSizeDevice = () => {
    return window.screen.width < 768;
  }

  return (
    <div className='sr-container'>
      <div className='sr-top-container'>
        <div className='sr-navbar'>
          <div className='sr-logo' style={{ backgroundImage: 'url("/Icon_assets/svg/invisibly-logo.svg")' }}>
          </div>
          <a target="_blank" rel="noreferrer" href={SURVEY_RESULTS_REDIRECT_LINK}>
            <Button
              className="in-btn-sm in-btn-info btn btn-download-app"
            >Get Invisibly</Button></a>
        </div>
        <div className='page-title'>
          See how everyone<br /> else responded.
        </div>
        <>
          <div id='chart-list' className='chart-list'>
            {isRTRSurveyDataLoading ?
              <div id={'chart-1'}
                className='chart-container' style={{ marginLeft: '25%', marginRight: '25%' }}>
                <Card style={{ padding: '10px', borderRadius: '20px' }}>
                  <div className="spinner-container" style={{ height: '520px', padding: '0px', display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ alignSelf: 'center' }}>
                      <img src="/Icon_assets/svg/loader.gif" alt='loader' style={{ margin: "20px" }} />
                    </div>
                  </div>
                </Card>
              </div>
              : <>
                {!Object.keys(RTRSurveyData).includes('failed') ? <>
                  {
                    chartData.map((cd, index) => {
                      return <Card id={'chart' + index} className='chart-container' key={'chart' + index} style={{ marginLeft: index === 0 ? '157px' : '', marginRight: index === chartData.length - 1 ? '25%' : '', padding: '20px', borderRadius: '20px' }}>
                        <div className='title'>{cd.question}</div>
                        <Chart data={cd.options} />
                      </Card>
                    })
                  }
                </> :
                  <div id={'chart-1'}
                    className='chart-container' style={{ marginLeft: '25%', marginRight: '25%' }}>
                    <Card style={{ padding: '10px', borderRadius: '20px' }}>
                      <div className="spinner-container" style={{ height: '400px', padding: '0px', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ alignSelf: 'center' }}>
                          Oops! Some Issue while fetching data.
                        </div>
                      </div>
                    </Card>
                  </div>
                }
              </>}
          </div>
          {!Object.keys(RTRSurveyData).includes('failed') &&
            <div className='arrows'>
              <span onClick={() => goTo('prev')} className='prev'>&#8592;</span>
              <span onClick={() => goTo('next')} className='next'>&#8594;</span>
            </div>}
        </>
        <div className='sr-qr-container'>
          <div className='download-app'>
            <div className='d-flex' style={{ height: '223px', maxWidth: '410px' }}>
              <div className='scan-qr'>
                <div className='qr-wrap'>
                  <div className='qr'>
                    <img style={{ objectFit: 'contain' }} src={SURVEY_RESULTS_REDIRECT_QR} alt="QR" width="163" height="140" />
                  </div>
                </div>
                <div className='text'>Scan to Download</div>
              </div>
              <div className='download-icons'>
                <a target="_blank" rel="noreferrer" href={SURVEY_RESULTS_REDIRECT_LINK}>
                  <img className='dl-button' style={{ marginBottom: '15px' }} src="/Icon_assets/svg/apple-store-logo.svg" alt="QR" width="141" height="47" />
                </a>
                <a target="_blank" rel="noreferrer" href={SURVEY_RESULTS_REDIRECT_LINK}>
                  <img className='dl-button' src="/Icon_assets/svg/android-store-logo.svg" alt="QR" width="141" height="47" />
                </a>
              </div>
            </div>
            <div className='d-flex' style={{ maxWidth: '648px' }}>
              <div className='download-question'>
                <div className='title'>
                  Did you like taking this survey?
                </div>
                <div className='para'>
                  <b>Download the Invisibly app </b>to take more surveys, earn points, and <b>use your points to bypass paywalls</b> to read content you love.
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='sr-2-container'>
        <div className='sr-step-title-text'>How Invisibly Works</div>
        <div className='sr-steps-container'>
          <StepCards />
        </div>
        <div className='sr-dl-container'>
          <a target="_blank" rel="noreferrer" href={"https://www.invisibly.com/"}><Button
            className="in-btn-sm in-btn-info btn btn-download-app"
          >Learn more</Button></a>
        </div>

      </div>
      <div className='sr-footer-container'>
        <div className='sr-footer-sub-container'>
          <div className='sr-logo' style={{ backgroundImage: 'url("/Icon_assets/svg/invisibly-logo.svg")' }}>
          </div>
          <div className='sr-links-container'>
            <FooterNavLinks />
          </div>
        </div>
        <div className='sr-footer-sub-container' style={{ flexWrap: 'wrap-reverse' }}>
          <div className='sr-footer-rights-text'>
            © {new Date().getFullYear()} Invisibly. All Rights Reserved.
          </div>
          <div className='sr-social-links-container'>
            <FooterNavSocialLinks />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RealtimeSurveyResults