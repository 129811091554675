import ACTIONS from "../constants/actionTypes";

const initialState = {
  isUserListFetching: false,
  userListData: {},
  profilePic: "",
  profilePicErrors: undefined,
  isLoading: false,
  isUserAddeding: false,
  isUserAdded: false,
  deactivateUserRequestCompleted: false,
  disableToggle: false,
  activateUserRequestCompleted: false,
  updateUserData: {},
  isUserUpdated: false,
  isUserUpdating: false,
  userPassword: {},
  passwordSuccess: false,
  userRolesList: localStorage.getItem('roles') ? localStorage.getItem('roles') : undefined,
  isRolesLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_USER_LIST:
      return {
        ...state,
        userListData: {},
        isUserListFetching: false,
      };

    case ACTIONS.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isUserListFetching: true,
        userListData: action.payload,
        // payload for edit
        // {"email":"r@r.com",
        // "access":[{"role":"5fb66e57-48b9-4e9e-a153-9e16f8665558","org":["a33dd435-a6e1-4938-9b28-3bda0b2f514c","f5f0b2d5-2166-4a37-a46e-6230253b2bca"]},
        // {"role":"94aa74fe-9b9f-4464-a955-148669a22793","org":["a2fc3434-3aee-420d-ac1f-7128a36778e2","5efe0ec6-291f-4e08-8c63-d48cfe4ccf13"]}]
        // },
      };

    case ACTIONS.GET_USER_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isUserListFetching: true,
      };

    case ACTIONS.GET_USER_LIST_FAILURE_CLEAR:
      return {
        ...state,
        userListData: {},
        errorMsg: undefined,
      };

    case ACTIONS.UPLOAD_PROFILE_PIC_REQUEST:
      return {
        ...state,
        profilePic: "",
        isLoading: true,
        errorMsg: undefined,
      };

    case ACTIONS.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        profilePic: action.payload.profile_pic,
        isLoading: false,
      };

    case ACTIONS.UPLOAD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        profilePic: "",
        isLoading: false,
        profilePicErrors: action.payload,
      };

    case ACTIONS.UPLOAD_PROFILE_PIC_FAILURE_CLEAR:
      return {
        ...state,
        profilePicErrors: undefined,
      };

    case ACTIONS.UPLOAD_PROFILE_PIC_CLEAR:
      return {
        ...state,
        profilePic: "",
        errorMsg: undefined,
      };

    case ACTIONS.CREATE_USER_REQUEST:
      return {
        ...state,
        userData: action.payload,
        isUserAddeding: true,
        errorMsg: undefined,
      };

    case ACTIONS.CREATE_USER_SUCCESS:
      return {
        ...state,
        isUserAdded: true,
        isUserAddeding: false,
      };

    case ACTIONS.CREATE_USER_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isUserAdded: false,
        isUserAddeding: false,
      };

    case ACTIONS.CREATE_USER_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        isUserAdded: undefined,
      };

    case ACTIONS.CREATE_USER_CLEAR_MESSAGE:
      return {
        ...state,
        errorMsg: undefined,
        isUserAdded: undefined,
      };

    case ACTIONS.DEACTIVATEUSER_REQUEST:
      return {
        ...state,
        deactivateUserRequestCompleted: false,
        disableToggle: false,
      };

    case ACTIONS.DEACTIVATEUSER_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        deactivateUserRequestCompleted: true,
        disableToggle: true,
      };

    case ACTIONS.DEACTIVATEUSER_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        deactivateUserRequestCompleted: true,
        disableToggle: false,
      };

    case ACTIONS.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        errorMsg: action.payload,
        deactivateUserRequestCompleted: true,
        disableToggle: false,
      };

    case ACTIONS.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        errorMsg: action.payload,
        activateUserRequestCompleted: true,
        disableToggle: false,
      };

    case ACTIONS.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        activateUserRequestCompleted: true,
        disableToggle: true,
      };

    case ACTIONS.ACTIVATE_DEACTIVATE_CLEAR:
      return {
        ...state,
        errorMsg: '',
        disableToggle: false,
        activateUserRequestCompleted: false,
        deactivateUserRequestCompleted: false
      }
    case ACTIONS.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserData: action.payload,
        isUserUpdated: false,
        isUserUpdating: true,
        errorMsg: undefined,
      };

    case ACTIONS.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUserUpdated: true,
        isUserUpdating: false,
      };

    case ACTIONS.UPDATE_USER_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isUserUpdated: false,
        isUserUpdating: false,
      };

    case ACTIONS.UPDATE_USER_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        isUserUpdated: undefined,
      };

    case ACTIONS.UPDATE_PASSWORD:
      return {
        ...state,
        userPassword: action.payload,
        isUserUpdated: false,
        isUserUpdating: true,
        errorMsg: undefined,
      };

    case ACTIONS.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordSuccess: true,
        isUserUpdated: true,
        isUserUpdating: false,
      };

    case ACTIONS.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        errorMsg: action.payload,
        isUserUpdated: false,
        isUserUpdating: false,
      };

    case ACTIONS.GET_USER_ROLES_LIST:
      return {
        ...state,
        userRolesList: action.payload,
        isRolesLoading: false,
      };

    case ACTIONS.GET_USER_ROLES_LIST_SUCCESS:
      return {
        ...state,
        isRolesLoading: true,
        userRolesList: action.payload,
      };

    case ACTIONS.GET_USER_ROLES_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isRolesLoading: true,
        //remove hardcoding later
        userRolesList: {
          "results": [
            {
              "id": "28936c1d-0343-413d-8ae0-1f7b4f90e18d",
              "role_type": 0,
              "value": 0,
              "label": "Admin"
            },
            {
              "id": "6a48303f-ae66-4c0f-883e-d50ff7c73740",
              "role_type": 0,
              "value": 1,
              "label": "Analyst"
            },
            {
              "id": "5fb66e57-48b9-4e9e-a153-9e16f8665558",
              "role_type": 0,
              "value": 2,
              "label": "Org Admin"
            },
            {
              "id": "94aa74fe-9b9f-4464-a955-148669a22793",
              "role_type": 0,
              "value": 3,
              "label": "Builder"
            },
          ]
        }
      };

    case ACTIONS.GET_USER_ROLES_LIST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default userReducer;
