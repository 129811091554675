import React, { useEffect, useState } from 'react';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import { Table } from "reactstrap";
import "./index.scss";
import AnalyticsAction from "./AnalyticsAction";
import PreviewAction from "./PreviewAction";
import { format } from "date-fns";


const UserSurveyTable = ({ tableData, sortColumnData, sortedColumn, sortType }) => {
  const platFormEnum = [
    "Invisibly",
    "Programmatic",
    "Both"
  ];

  const TABLE_COLUMNS = [
    {
      dataField: "Campaign_name",
      text: "Survey name",
      sortName: "name",
      className: "col-hd-campaign-name"
    },
    {
      dataField: "updated_at",
      text: "Updated at",
      sortName: "updated_at",
      className: "col-hd-update"
    },
    {
      dataField: "Advertiser",
      text: "Advertiser",
      sortName: "brand_v2__name",
      className: "col-hd-advertiser"
    },
    {
      dataField: "Platform",
      text: "Platform",
      sortName: "rtr_type",
      className: "col-hd-platform"
    },
    {
      dataField: "actions",
      sortName: "actions",
      text: "Actions",
      className: "col-hd-actions"
    },
  ];

  const [userData, setUserData] = useState(tableData?.results);
  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

  //Icons setup
  const actionIcons = {
    LIST: "/Icon_assets/svg/list.svg",
    ANALYTICS: "/Icon_assets/svg/analytics.svg",
    ANALYTICS_ACTIVE: "/Icon_assets/svg/analytics_active.svg",
    PREVIEW_ICON: "/Icon_assets/svg/preview-icon.svg",
    DESC_SORT_SELECTED: "/Icon_assets/svg/desc_sort_selected.svg",
    DESC: "/Icon_assets/svg/desc_sort.svg",
    ASC_SORT_SELECTED: "/Icon_assets/svg/asc_sort_selected.svg"
  };

  const goToResearchDetails = (e, id, brand_id, name, orgName, orgId, type, adMetaData) => {
    e.preventDefault();
    const adset_id = adMetaData?.ad_set?.id
    localStorage.setItem('ad_name', name);
    localStorage.setItem('ad_type', type);
    history.push({
      pathname: `${ROUTES.REALTIME_RESEARCH}/details/${id}/${brand_id}/${adMetaData?.rtr_type}/${adset_id}`,
      state: { org_name: orgName, org_id: orgId }
    });
  }

  const goToPreview = (e, msg_id, brand_id, ad_state, externalId, name, rtr_type, data) => {
    e.preventDefault();
    var preview = 1;
    localStorage.setItem("brand_id", brand_id);
    localStorage.setItem("msg_id", msg_id);
    localStorage.setItem("ad_id", externalId);
    var data = {
      ad_name: name, "external_msg_id": externalId,
      "ad_state": ad_state, "msg_id": msg_id,
      isEdit: false,
      isPDP: rtr_type.toString() === "0" ? true : false,
      platformState: rtr_type,
      ad_set_id: data?.ad_set?.id
    }
    history.push({
      pathname: `${ROUTES.REASERACH_CAMPAIGN}/preview/${preview}`,
      state: { detail: data }
    });
  }

  // useEffect(() => {
  //   if (tableData?.results) {
  //     const filteredTableData = tableData.results.filter((d) => { return d.ad_state !== 0 });
  //     setUserData(filteredTableData);
  //   }
  // }, [tableData])

  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("user-survey-table");
    var tableHeight = table.offsetHeight;
    // For resizing column, add dummy div to header, add event when clicked
    Array.prototype.forEach.call(
      table.querySelectorAll(".resized-column"),
      function (th) {
        //th.style.position = 'relative';

        var grip = document.createElement('div');
        grip.className = 'column-resizer';
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.height = tableHeight + 'px';
        grip.style.position = 'absolute';
        grip.style.zIndex = "1";
        grip.style.cursor = 'col-resize';
        grip.addEventListener('mousedown', function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      });

    // listener for dragging
    table.addEventListener('mousemove', function (e) {
      if (thElm) {
        if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
          thElm.style.width = startOffset + e.pageX + 'px';
          thElm.style.minWidth = startOffset + e.pageX + 'px';
        }

        let expandedColumnWidth = (startOffset + e.pageX) + 20;
        if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
          setExpandedTableColWidth(expandedColumnWidth);
        }
      }
    });

    // listner for releasing click
    table.addEventListener('mouseup', function () {
      thElm = undefined;
    });
  }, [])
  /* Resize column setup for table-main end*/


  return (
    <div className="research-table-container">
      <Table className="user-survey-table admin-survey-table table" id="user-survey-table">
        <thead>
          <tr>
            {TABLE_COLUMNS.map((d, i) => {
              if (d.text === "Survey name" || d.text === "Advertiser") {
                return <th key={i} className={d.className + " resized-column"} >
                  <div className="resizer-icon">
                    <span className="first"></span>
                    <span className="second"></span>
                  </div>
                  {d.text}
                  <img onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                    src={sortedColumn === d.sortName ?
                      (sortType === 'desc' ?
                        actionIcons.DESC_SORT_SELECTED : actionIcons.ASC_SORT_SELECTED)
                      : actionIcons.DESC} alt="desc icon" className="index-tip" /></th>;

              }
              return <th key={i} className={d.className} >{d.text}
                {d.text.toLowerCase() !== "actions" ?
                  <img onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                    src={sortedColumn === d.sortName ?
                      (sortType === 'desc' ?
                        actionIcons.DESC_SORT_SELECTED : actionIcons.ASC_SORT_SELECTED)
                      : actionIcons.DESC} alt="desc icon" className="index-tip" />
                  : <></>}
              </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {
            (userData?.map((data, index) => {
              return (
                // <tr key={data.id}>
                //   <td className="survey-column">
                //     <img src={actionIcons.LIST} className="survey-icon" alt="status icon" />
                //     <span className="survey-name" title={data.name} title={data.name}>{data.name}</span>
                //   </td>
                //   <td className="action-column">
                //     <AnalyticsAction
                //       goToResearchDetails={goToResearchDetails}
                //       data={data}
                //       actionIcons={actionIcons}
                //     />
                //   </td>
                // </tr>

                <tr key={data.id}>
                  <td className="survey-column campaign-name-column">
                    <div className="campaign-meta-wrapper">
                      <span className="survey-icon-wrap">
                        <img src={actionIcons.LIST} className="survey-icon" alt="status icon" />
                      </span>
                      <p className="ad-name-wrap">
                        <span className="survey-name" title={data.name}
                          style={{ cursor: data.ad_state !== 0 ? "pointer" : "default" }}
                          onClick={(e) => data.ad_state !== 0 ?
                            goToResearchDetails(e, data.id, data.brand_v2 ? data.brand_v2.id : "",
                              data.name, data.brand_v2 && data.brand_v2.organization ?
                              data.brand_v2.organization.name : "",
                              data.brand_v2.organization ?
                                data.brand_v2.organization.id : "", data.rtr_type, data) :
                            e.preventDefault()}>{data.name}</span>
                      </p>
                    </div>
                  </td>
                  <td className="update-column">
                    <span>{format(new Date(data.updated_at), 'MMMM dd, yyyy')}</span>
                  </td>
                  <td className="advertiser-column">
                    <span>{data.brand_v2 && data.brand_v2.name}</span>
                  </td>
                  <td >
                    <span >	{(platFormEnum[data.rtr_type])}</span>
                  </td>
                  <td className="action-column">
                    <section className="d-flex">
                      <span className="divider"></span>
                      <PreviewAction
                        goToPreview={goToPreview}
                        data={data}
                        actionIcons={actionIcons}
                      />
                      <AnalyticsAction
                        goToResearchDetails={goToResearchDetails}
                        data={data}
                        actionIcons={actionIcons}
                      />
                      <span></span>
                    </section>
                  </td>
                </tr>
              )
            })
            )
          }
        </tbody>
      </Table>
    </div>
  )
}

export default UserSurveyTable;