const ROUTES = {
	SIGNUP: '/signup',
	SIGNIN: '/signin',
	FORGOT_PASSWORD: '/forgot-password',
	CHANGE_PASSWORD: '/change-password',
	DASHBOARD: '/dashboard',
	REALTIME_RESEARCH: '/realtime-research',
	REALTIME_RESEARCH_DETAILS: '/realtime-research/details/:id/:brand_id/:platform/:adsetid',
	PROFILE_SETTINGS: '/profile-settings',
	USERS_LIST: '/users',
	ORGANIZATIONS_LIST: '/organizations',
	EDIT_ORGANIZATION: '/organizations/edit/:id',
	CREATE_ORGANIZATION: '/organizations/add',
	REPORT_BUILDER: '/report-builder',
	SCHEDULED_REPORTS: '/report-builder/scheduled-reports',
	REASERACH_CAMPAIGN: '/research-campaign',
	CAMPAIGN_PREVIEW: '/research-campaign/preview/:preview',
	PREVIEW: '/brands/:brand_id/messages/:msg_id/preview-share',
	CONVERSION: '/conversion',
	CONVERSION_REPORT_BUILDER: '/conversion-report-builder',
	CONVERSION_RULE_LIST: '/conversion/conversion-rule-list/:id',
	CREATE_CONVERSION_RULE: '/conversion/create-rule',
	GET_PIXEL_CODE: '/conversion/pixel-code',
	OFFERS_LIST: "/offers",
	CREATE_OFFERS: "/offers/add-offer",
	EDIT_OFFERS: "/offers/edit-offer/:id",
	OFFER_PREVIEW: "/offers/preview/:msg_id/:brand_id/:ad_id",
	BRAND_OFFER_PREVIEW: "/offers/offer-preview/:msg_id/:brand_id",
	OFFER_SHARE_PREVIEW: "/offers/preview-share/:msg_id/:brand_id/:ad_id",
	BRAND_OFFER_SHARE_PREVIEW: "/offers/offer-preview-share/:msg_id/:brand_id",
	RTR_OFFER_SHARE_PREVIEW: "/rtr/offer-preview-share/:msg_id/:brand_id",
	RTR_OFFER_PREVIEW: "/rtr/preview/:msg_id/:brand_id/:ad_id",
	ADD_USER: '/users/add',
	EDIT_USER: '/users/edit/:id',
	CREATE_BRAND: '/brands/add',
	EDIT_BRAND: '/brands/edit/:id',
	BRANDS_LIST: '/brands',
	SURVEY_RESULTS: '/SurveyResult/:ad_id'
};

export default ROUTES;
