import React from 'react';
import "./index.scss";
import {
  Button, Card, CardBody, Label, Input, FormGroup, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, FormFeedback, CustomInput, Tooltip
} from "reactstrap";
import Loader from '../../Loader';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import ADspinner from "../../Loader/ADspinner";
import { render } from 'fusioncharts';
import PlatformToggle from './PlatformToggle';

const SurveyComplete = (props) => {

  const {
    activationData,
    role,
    preview,
    setActiveStep,
    reloadPreviousScreen,
    activating,
    activateExternalAd,
    adActivated
  } = props;
  return (
    <div className='right-container'>
      <div className="dashboard real-time">
        <section className="title-section dashboard-header-section">
          <>
            <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
              <Button className="btn-transparent" onClick={() => (+preview === 1) ? history.push(`${ROUTES.REALTIME_RESEARCH}`) : setActiveStep(0)}>
                <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
              </Button>
              <h2 id={"survey-header"}
                className="mb-0 pl-4 secondary-title">
                Preview</h2>
            </nav>
            {
              (activationData.ad_state === 0 || activationData.ad_state === 1) && role === 0 &&
              <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
                <div className="text-right form-group">
                  <Button className="in-btn-sm in-btn-info btn-export ml-3 btn-next"
                    style={{ minWidth: '144px', minHeight: '48px' }}
                    disabled={(!(Object.keys(adActivated).length === 0 && adActivated.constructor === Object))}
                    onClick={activateExternalAd}>
                    {activating ?
                      <ADspinner size="large" /> :
                      `Activate`}
                  </Button>
                </div>
              </nav>
            }

          </>
        </section>
        <section className="survey-complete w-100" id="survey-complete">
          {activationData?.platformState === 2 && (
            <PlatformToggle
              data={{}}
              selectPlatform={props.selectedPlatform}
              handlePlatformChange={props.handlePlatformChange}
            />
          )}
          <div id="reload-survey" style={{ marginBottom: "20px" }}>
            <img onClick={reloadPreviousScreen}
              src="/Icon_assets/svg/reload-icon.svg"
              alt="share"
              className="link-image"
            />
            <span className="reload-survey" onClick={reloadPreviousScreen} >Reload Survey</span>
          </div>
          <Card>
            <CardBody>
              <div className="container" style={{}}>
                <p className="vertical-center" style={{}}>Congratulations! You have completed the survey.</p>
              </div>
            </CardBody>
          </Card>
        </section>

      </div>
    </div>
  )
}

export default SurveyComplete;