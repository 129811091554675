import ACTIONS from "../constants/actionTypes";

export const getUserListRequest = (data) => {
  return {
    type: ACTIONS.GET_USER_LIST,
    payload: data,
  };
};

export const getUserListSuccess = (data) => {
  return {
    type: ACTIONS.GET_USER_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserListFailure = (errors) => {
  return {
    type: ACTIONS.GET_USER_LIST_FAILURE,
    payload: errors,
  };
};

export const getUserListFailureClear = () => {
  return {
    type: ACTIONS.GET_USER_LIST_FAILURE_CLEAR,
  };
};

export const uploadProfilePic = (data) => {
  return {
    type: ACTIONS.UPLOAD_PROFILE_PIC_REQUEST,
    payload: data,
  };
};

export const uploadProfilePicSuccess = (data) => {
  return {
    type: ACTIONS.UPLOAD_PROFILE_PIC_SUCCESS,
    payload: data,
  };
};

export const uploadProfilePicFailure = (errors) => {
  return {
    type: ACTIONS.UPLOAD_PROFILE_PIC_FAILURE,
    payload: errors,
  };
};

export const uploadProfilePicFailureClear = () => {
  return {
    type: ACTIONS.UPLOAD_PROFILE_PIC_FAILURE_CLEAR,
  };
};

export const uploadProfilePicClear = () => {
  return {
    type: ACTIONS.UPLOAD_PROFILE_PIC_CLEAR,
  };
};

export const createUserRequest = (data) => {
  return {
    type: ACTIONS.CREATE_USER_REQUEST,
    payload: data,
  };
};

export const createUserSuccess = () => {
  return {
    type: ACTIONS.CREATE_USER_SUCCESS,
  };
};

export const createUserFailure = (error) => {
  return {
    type: ACTIONS.CREATE_USER_FAILURE,
    payload: error,
  };
};

export const createUserFailureClear = () => {
  return {
    type: ACTIONS.CREATE_USER_FAILURE_CLEAR,
  };
};

export const createUserClearMessage = () => {
  return {
    type: ACTIONS.CREATE_USER_CLEAR_MESSAGE,
  };
};

export const deactivateUserRequest = (data) => {
  return {
    type: ACTIONS.DEACTIVATEUSER_REQUEST,
    payload: data,
  };
};

export const deactivateUserSuccess = (data) => {
  return {
    type: ACTIONS.DEACTIVATEUSER_SUCCESS,
    payload: data,
  };
};

export const deactivateUserFailure = (errors) => {
  return {
    type: ACTIONS.DEACTIVATEUSER_FAILURE,
    payload: errors,
  };
};


export const activateUserRequest = (data) => {
  return {
    type: ACTIONS.ACTIVATE_USER_REQUEST,
    payload: data,
  };
}

export const activateUserSuccess = (data) => {
  return {
    type: ACTIONS.ACTIVATE_USER_SUCCESS,
    payload: data,
  };
}

export const activateUserFailure = (error) => {
  return {
    type: ACTIONS.ACTIVATE_USER_FAILURE,
    payload: error,
  };
}

export const updateUserRequest = (data) => {
  return {
    type: ACTIONS.UPDATE_USER_REQUEST,
    payload: data,
  };
};

export const updateUserSuccess = () => {
  return {
    type: ACTIONS.UPDATE_USER_SUCCESS,
  };
};

export const updateUserFailure = (error) => {
  return {
    type: ACTIONS.UPDATE_USER_FAILURE,
    payload: error,
  };
};

export const updateUserFailureClear = () => {
  return {
    type: ACTIONS.UPDATE_USER_FAILURE_CLEAR,
  };
};


export const activateDeactivateClear = () => {
  return {
    type: ACTIONS.ACTIVATE_DEACTIVATE_CLEAR,
  };
};

export const updatePassword = data => ({
  type: ACTIONS.UPDATE_PASSWORD,
  payload: data,
});

export const updatePasswordSucess = data => ({
  type: ACTIONS.UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updatePasswordError = data => ({
  type: ACTIONS.UPDATE_PASSWORD_ERROR,
  payload: data,
});


export const getUserRolesListRequest = (data) => {
  return {
    type: ACTIONS.GET_USER_ROLES_LIST,
    payload: data,
  };
};

export const getUserRolesListSuccess = (data) => {
  return {
    type: ACTIONS.GET_USER_ROLES_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserRolesListFailure = (errors) => {
  return {
    type: ACTIONS.GET_USER_ROLES_LIST_FAILURE,
    payload: errors,
  };
};

export const getUserRolesListFailureClear = () => {
  return {
    type: ACTIONS.GET_USER_ROLES_LIST_FAILURE_CLEAR,
  };
};
