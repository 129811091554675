import React from 'react';
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from '../../components/HeaderNavigation';
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";
import ConversionPage from "../../components/Conversion";
import ConversionRuleList from "../../components/Conversion/ConversionRuleList";
import PixelCode from "../../components/Conversion/PixelCode";
import CreateRule from "../../components/Conversion/CreateRule";
import ConversionReportBuilder from "../../components/Conversion/ConversionReportBuilder";
import "./index.scss";

import history from '../../history';

const Conversion = () => {
  return (
    <Router basename="/" history={history}>
      <>
        <Notifications />
        <HeaderNavigation />
        <div className="conversion-container">
          {(window.location.pathname !== ROUTES.CREATE_CONVERSION_RULE &&
            window.location.pathname !== ROUTES.CONVERSION_REPORT_BUILDER) ? <SideNavigation /> : <></>}
          <div className='right-container'>
            <Switch>
              <Route exact path={ROUTES.CONVERSION} component={ConversionPage} />
              <Route exact path={ROUTES.CONVERSION_RULE_LIST} component={ConversionRuleList} />
              <Route exact path={ROUTES.CREATE_CONVERSION_RULE} component={CreateRule} />
              <Route exact path={ROUTES.GET_PIXEL_CODE} component={PixelCode} />
              <Route exact path={ROUTES.CONVERSION_REPORT_BUILDER} component={ConversionReportBuilder} />
            </Switch>
          </div>
        </div>
      </>
    </Router>
  );
}

export default Conversion;

