import { takeLatest, call, select, put } from "redux-saga/effects";
import { filtersActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function statesRequest(API_URL, jwtToken, data) {
  let params = {}

  params.key = 'country'
  params.location_id = 1 // Location Id for US in backend

  if (data?.limit)
    params.limit = data.limit

  if (data?.offset)
    params.offset = data.offset

  if (data?.search) {
    params.search = data.search
    params.search_type = 'state'
    params.key = 'search'
  }

  API_URL = API_URL + '/v1/location'
  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function citiesRequest(API_URL, jwtToken, data) {
  let params = {}

  params.key = 'all_cities'
  params.location_id = data.id

  if (data?.limit)
    params.limit = data.limit

  if (data?.offset)
    params.offset = data.offset

  if (data?.search) {
    params.search = data.search
    params.search_type = 'city'
    params.key = 'search'
  }

  API_URL = API_URL + '/v1/location'
  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* statesRequestSaga(action) {
  const API_URL = yield select((state) => state.config.API_URL);
  const userData = yield select((state) => state.auth.userData);
  let jwtToken = userData && userData.token ? userData.token : null;
  const result = yield call(
    statesRequest,
    API_URL,
    jwtToken,
    action.payload
  );
  const { item, status } = result.data;

  if (status.status_type.toUpperCase() === "SUCCESS") {

    if (action.payload.search?.length > 0)
      yield put(filtersActionCreator.getStatesSuccess({ count: item.results?.[0]?.states.length, next: null, results: item.results?.[0]?.states }));
    else
      yield put(filtersActionCreator.getStatesSuccess(item));
  }
}

function* citiesRequestSaga(action) {
  const API_URL = yield select((state) => state.config.API_URL);
  const userData = yield select((state) => state.auth.userData);
  let jwtToken = userData && userData.token ? userData.token : null;
  const result = yield call(
    citiesRequest,
    API_URL,
    jwtToken,
    action.payload
  );
  const { item, status } = result.data;

  if (status.status_type.toUpperCase() === "SUCCESS") {
    yield put(filtersActionCreator.getCitiesSuccess(item));
  }
}



export default function* filtersSaga() {
  yield takeLatest(ACTIONS.STATES_REQUEST, statesRequestSaga);
  yield takeLatest(ACTIONS.CITY_REQUEST, citiesRequestSaga);
}