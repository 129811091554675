import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../utils/routeConstants";
import history from "../../history";
import {
  Card, CardBody, Button, Form,
  FormGroup, Row, Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import Loader from '../Loader';
import { brandsActionCreator } from "../../actions";
import "./index.scss";
import RBAC from '../RBAC';
import { PERMISSIONS } from '../../utils/permissionMatrix';
import checkRBAC from '../../utils/checkRBAC';

const ConversionPage = () => {

  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/black_circle_close_icon.svg"
  }

  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(prevState => !prevState);
    if (!dropdownOpen) {
      setMetricOptions([]);
      setTotalOffset(0);
      // setAdCount(0);
      setSearchValue("");
    }
  }
  const [searchValue, setSearchValue] = useState('');
  const [totalOffset, setTotalOffset] = useState(0);
  const [metricsOptions, setMetricOptions] = useState([]);
  const [advertiserName, setAdvertiserName] = useState("");
  const [brandsCount, setBrandsCount] = useState(0);

  const brandsData = useSelector((state) => state.brands.data?.results);
  const adCount = useSelector((state) => state.brands.data?.count || 0);
  const isBrandsLoading = useSelector((state) => state.brands.isLoading);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const userData = useSelector((state) => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);

  const handleAdvertiserChange = (e, ad) => {
    e.preventDefault();
    setAdvertiserName(ad.name);
    localStorage.setItem('selectedConversionAd', JSON.stringify(ad));

    history.push({
      pathname: `${ROUTES.CONVERSION}/conversion-rule-list/${ad.id}`,
      state: { advertiser: ad }
    });
  }
  const getBrandsDropDownOptions = (e) => {
    e.preventDefault();
    const params = {};
    params.limit = 20;
    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }
    if (!dropdownOpen) {
      dispatch(brandsActionCreator.brandsListRequest(params));
    }
  };


  const handleSearch = (evt) => {
    evt.preventDefault();
    let value = evt.target.value.toLowerCase();
    let params = {};
    params.limit = 20;
    params.offset = 0;
    params.searchKey = value;

    setSearchValue(evt.target.value);
    setTotalOffset(0);
    setMetricOptions([]);
    setBrandsCount(0);

    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }

    dispatch(brandsActionCreator.brandsListRequest(params));
  }

  const handleBrandsScroll = () => {
    var count = brandsCount;
    var listCount = metricsOptions.length;
    var brandsDrop = document.getElementById("brands-list");
    if (brandsDrop.scrollTop + 50 >= brandsDrop.scrollHeight - brandsDrop.clientHeight) {
      if (count > listCount && listCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        params.limit = 20;
        params.offset = totalOffset + 10;
        if (searchValue) {
          params.name = searchValue;
        }

        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }

        dispatch(brandsActionCreator.brandsListRequest(params));
      }
    }
  }

  useEffect(() => {
    if ((userData.organization_role !== 0) && !selectedOrganization?.is_rtr_enabled) {
      history.push(ROUTES.DASHBOARD)
      return
    }
  }, [])
  

  useEffect(() => {
    var brandsDrop = document.getElementById("brands-list");
    if (metricsOptions && brandsDrop) {
      brandsDrop.addEventListener('scroll', handleBrandsScroll);
      return () => {
        brandsDrop.removeEventListener('scroll', handleBrandsScroll);
      }
    }
  }, [metricsOptions])

  useEffect(() => {
    if (brandsData && brandsData.length > 0) {
      const newList = metricsOptions.concat(brandsData);
      setMetricOptions(newList);
    }
  }, [brandsData])

  useEffect(() => {
    if (adCount) {
      setBrandsCount(adCount);
    }
  }, [adCount])

  const goToCoversionBuilder = (e) => {
    e.preventDefault();
    history.push({
      pathname: ROUTES.CONVERSION_REPORT_BUILDER,
      state: { source: "conversion-page" }
    });
  }

  return (<>
    <div className="conversion-section report-builder-section app-container">
      <div className="right-container">
        <Form className="report-builder-form">
          <section className="title-section">
            <h2 style={{ paddingLeft: "0px" }}>Conversions</h2>
            <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ} >
              <Button
                className="in-btn-sm in-btn-primary add-new-organization-btn"
                onClick={goToCoversionBuilder}
              >Conversion Report Builder</Button>
            </RBAC>
          </section>
          <div className="report-builder-options">
            {!checkRBAC(userData, userRbac, PERMISSIONS.BUILD_CONVERSION_READ, selectedOrganization) && (<>
              <Card className='border-0' style={{ boxShadow: "2px 8px 12px rgb(19 36 139 / 4%)", borderRadius: "16px" }}>
                <CardBody>
                  <div className='d-flex justify-content-center align-items-center flex-column mb-5 mt-5'>
                    <img src={"/Icon_assets/png/no-rules.png"} alt="no conversion rules found" />
                    <p className='mt-3 mb-5'>You do not have permissions to manage the conversions</p>
                  </div>
                </CardBody>
              </Card>
            </>)}
          <RBAC requiredPermissions={PERMISSIONS.BUILD_CONVERSION_READ} >
            <Card className={"data-card organization-list-section"}>
              <Label className="lblHeader">Select an advertiser to view</Label>
              <Row>
                <Col md={6} sm={12} lg={6}>
                  <div className="brands-option-selector">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      onClick={getBrandsDropDownOptions}
                      className="dropdown-option metrics"
                      direction="down"
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        className="dropdown_toggle"
                      >
                        <span
                          className="dropdown_title"
                          title="Select metric"
                        > {advertiserName ? advertiserName : "Choose an Advertiser"}</span>
                        <img
                          src={
                            dropdownOpen
                              ? statusIcons.ARROW_UP
                              : statusIcons.ARROW_DOWN
                          }
                          className="dropdown-img"
                        ></img>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem className="search-item" toggle={false}>
                          <FormGroup className="search-metrics">
                            <Input
                              value={searchValue}
                              type="text"
                              name="search"
                              id="searchUser"
                              placeholder="Search..."
                              className="search-input"
                              autoComplete="off"
                              onClick={(e) => e.preventDefault()}
                              onChange={(event) => handleSearch(event)}
                            />
                            {/*<img
                              src="/Icon_assets/svg/search-organization-icon.svg"
                              alt="Search"
                              className="search-image"
                            /> */}
                          </FormGroup>
                        </DropdownItem>
                        <div className="metrics-list" id="brands-list">
                          {(!isBrandsLoading && brandsCount === 0 && searchValue !== "") ?
                            (
                              <span className="no-data-available-txt">
                                No Advertiser data available
                              </span>
                            ) 
                            : metricsOptions && metricsOptions.length ?
                              (metricsOptions && (metricsOptions.length > 0 ?
                                metricsOptions.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      key={"metrics" + item.id}
                                      id={"metrics" + item.name + index}>
                                      <div
                                        onClick={(e) => handleAdvertiserChange(e, item)}>
                                        {item.name}
                                      </div>
                                    </DropdownItem>
                                  )
                                })
                                : (<DropdownItem>No matches found</DropdownItem>)
                              ))
                              : <Loader />}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col> 
              </Row>
            </Card>
          </RBAC>
          </div>
        </Form>
      </div>
    </div>
  </>)
};

export default ConversionPage;