import ACTIONS from '../constants/actionTypes';

//AD_ACTIONS
export const getAdListRequest = (data) => {
  return {
    type: ACTIONS.GET_AD_LIST_REQUEST,
    payload: data,
  };
};

export const getAdListSuccess = (data) => {
  return {
    type: ACTIONS.GET_AD_LIST_REQUEST_SUCCESS,
    payload: data,
  };
};

export const getAdListFailure = (errors) => {
  return {
    type: ACTIONS.GET_AD_LIST_REQUEST_FAILURE,
    payload: errors,
  };
};

export const getAdListFailureClear = () => {
  return {
    type: ACTIONS.GET_AD_LIST_REQUEST_FAILURE_CLEAR,
  };
};