import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Label, Button, Card, CardBody, Row, Col, FormFeedback, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput, FormGroup, Input } from "reactstrap";
import { format, addDays } from "date-fns";
import { notificationActionCreator, scheduleReportActionCreator } from "../../../actions"
import ADCalendarSingle from "../../ADCalendarSingle";
import ADBadge from "../../ADBadge";
import ADspinner from "../../Loader/ADspinner";
import SkeletonScheduleReport from "../../Loader_Skeleton/SkeletonScheduleReport";
import Validator from '../../../utils/validators';
import { formatDateUTC } from "../../../utils/dateFormat";

import "./index.scss";

const SheduleReport = ({ type = "save", setOpenPopUp, report_template, id }) => {
  const dispatch = useDispatch();
  //save = POST & update = PUT
  const method = (type === "save") ? "POST" : "PUT";

  //store data start
  const isSaving = useSelector((state) => state.scheduleReport.isSaving);
  const isFetching = useSelector((state) => state.scheduleReport.isFetching);
  const reportScheduleData = useSelector((state) => state.scheduleReport.data);

  //validation object
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);

  //report_name & recipients
  const initialState = {
    name: '',
    email: '',
  };
  const [inputValues, setInputValues] = useState(initialState);

  //icons/images
  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CALENDER_GRAY_ICON: "/Icon_assets/svg/calendar_gray_Icon.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/black_circle_close_icon.svg",
    CALENDER_BLUE_ICON: "/Icon_assets/svg/calendar_blue_Icon.svg",
  }

  //recipients
  const [emailPlaceholderOnFocus, setEmailPlaceholderOnFocus] = useState(false);
  const [recipients, setRecipients] = useState([]);

  //multiple-field-container cols = 6 (default)
  const [multipleFieldsCols, setMultipleFieldsCols] = useState(6);

  //Frequency
  const [frequencyDropdownState, setFrequencyDropdownState] = useState(false);
  const toggleFrequency = () => setFrequencyDropdownState(prevState => !prevState);
  const report_frequency_arr = [
    { id: 1, label: "Daily", value: 0 },
    { id: 2, label: "Weekly", value: 1 },
    { id: 3, label: "Monthly", value: 2 },
  ];
  const [report_frequency, setReportFrequency] = useState(report_frequency_arr[0]);

  //Week Day
  const [customDaysDropdownState, setCustomDaysDropdownState] = useState(false);
  const toggleCustomDaysDropdownState = () => setCustomDaysDropdownState(prevState => !prevState);
  const custom_days_arr = [
    { id: 1, label: "Monday", value: 1 },
    { id: 2, label: "Tuesday", value: 2 },
    { id: 3, label: "Wednesday", value: 3 },
    { id: 4, label: "Thursday", value: 4 },
    { id: 5, label: "Friday", value: 5 },
    { id: 6, label: "Saturday", value: 6 },
    { id: 7, label: "Sunday", value: 7 },
  ];
  const [custom_days, setCustomDays] = useState([custom_days_arr[0].id]);

  //End Date
  const [selectedDatedownState, setSelectedDateDropdownState] = useState(false);
  const toggleSelectedDate = () => setSelectedDateDropdownState(prevState => !prevState);
  const DATE_FORMAT = {
    mmddyyyy: 'MM/dd/yyyy',
    ddMMyyyy: 'dd/MM/yyyy',
    yyyyMMdd: 'yyyy-MM-dd',
    MMDDYYYY: 'MM/DD/YYYY'
  };
  const defaultDate = format(addDays(new Date(), +1), DATE_FORMAT.mmddyyyy);
  const defaultEndDate = addDays(new Date(), +1);
  const [end_date, setEndDate] = useState(defaultEndDate);
  const [displayDate, setDisplayDate] = useState(defaultDate);

  //messages
  const REPORT_INFO_MESSAGES = [
    { id: 1, label: "The scheduled report will be delivered in the morning in the EST/EDT timezone." },
    { id: 2, label: "The scheduled report will be delivered in the morning of the day specified in the EST/EDT timezone." },
    { id: 3, label: "The scheduled report will be delivered in the morning on the 1st of each month in the EST/EDT timezone." }
  ];
  const [reportInfoMessage, setReportInfoMessage] = useState(REPORT_INFO_MESSAGES[0]);

  //functions
  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleFrequencySelect = (e, item) => {
    e.preventDefault();
    setReportFrequency(item);
    const cols = (item.id === report_frequency_arr[1].id) ? 4 : 6; //if its Weekly -> cols should be 4 to show Week Day
    cols !== 4 && setCustomDays([custom_days_arr[0].id]) //if cols is not equal to 4 then reset the custom_days
    setMultipleFieldsCols(cols);
    setReportInfoMessage(REPORT_INFO_MESSAGES[item.value]);
    isFormValid(inputValues.name, recipients, custom_days, inputValues.email, item);
  }

  const handleDateChange = (selectedData) => {
    const newSelectedData = format(new Date(selectedData), DATE_FORMAT.mmddyyyy)
    setEndDate(selectedData);
    setDisplayDate(newSelectedData);
  }

  //textbox value change for report_frequency & recipients
  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    if (e.target.name == "name") {
      isFormValid(e.target.value, recipients, custom_days, inputValues.email, report_frequency); //name & recipients(emails array)
    }

    if (e.target.name == "email") {
      isFormValid(inputValues.name, recipients, custom_days, e.target.value, report_frequency); //name & recipients(emails array)
    }
  }

  const isFormValid = (name, emails, customDays, currentEmail, selectedFrequency) => {
    const isCustomDays = selectedFrequency.value === 1 ? (customDays.length === 0 ? true : false) : false;
    if (name !== "" && emails.length > 0 && !isCustomDays && currentEmail == "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  const handleEmail = (e) => {
    if (e.charCode == 13 || e.type == "blur") { //on Enter key press
      const isValidEmail = updateValidations(e.target.name, e.target.value);
      const isEmailAlreadyPresent = recipients.includes(e.target.value);
      if (!isEmailAlreadyPresent) {
        if (isValidEmail) {
          const emails = [...recipients, e.target.value];
          if (emails.length <= 10) {
            setRecipients(emails);
            setInputValues({ ...inputValues, email: "" });
            isFormValid(inputValues.name, emails, custom_days, "", report_frequency); //name & recipients(emails array)
          } else {
            //if email length is greter than 1o show error mesaage
            dispatch(notificationActionCreator.setNotification({ message: "Enter up to 10 email addresses only", position: "sticky" }))
          }
        }
      } else {
        //validators.email.errorMsg = "Email is already present";
        dispatch(notificationActionCreator.setNotification({ message: `${e.target.value} is already present`, position: "sticky" }))
      }
    }
  }

  const handleRemoveEmail = (index, emailId) => {
    const emails = recipients.filter(email => email !== emailId);
    setRecipients(emails);
    if (emails.length === 0) {//if last email is removed -> save button should be disabled
      setIsValid(false);
    }
    validators.email.errorMsg = "";
  }

  const clearAllEmail = () => {
    setRecipients([]);
    setIsValid(false);
  }
  /* Email functions end */

  const handleCustomDaysChange = (e, item) => {
    let customDays = [...custom_days];
    if (e.target.checked) {
      customDays = [...custom_days, item];
      setCustomDays(customDays);
      isFormValid(inputValues.name, recipients, customDays, inputValues.email, report_frequency)
    } else {
      customDays.splice(customDays.indexOf(item), 1);
      setCustomDays(customDays);
      isFormValid(inputValues.name, recipients, customDays, inputValues.email, report_frequency)
    }
  }

  //onload
  useEffect(() => {
    setstate(validatorsObj);
    if (method === "PUT") {
      //PUT means its an Update operation
      dispatch(scheduleReportActionCreator.getScheduleReportRequest({ id, method: "GET" }));
    }
  }, [])

  //on data received
  useEffect(() => {
    if (!isSaving && !isFetching && reportScheduleData?.report_name && method === "PUT") {
      setInputValues({ name: reportScheduleData.report_name, email: "" });
      setRecipients(reportScheduleData.recipients);
      setReportFrequency(report_frequency_arr[reportScheduleData.report_frequency])
      setReportInfoMessage(REPORT_INFO_MESSAGES[reportScheduleData.report_frequency]);
      const mapDate = new Date(reportScheduleData.end_date)
      const newEndDate = formatDateUTC(mapDate, DATE_FORMAT.MMDDYYYY)
      setEndDate(newEndDate);
      setDisplayDate(newEndDate);
      if (reportScheduleData?.custom_days?.length > 0 && reportScheduleData.report_frequency === 1) {
        setCustomDays(reportScheduleData.custom_days);
        setMultipleFieldsCols(4);
      }else{
        setCustomDays([custom_days_arr[0].id]);
        setMultipleFieldsCols(6);
      }
      setIsValid(true);
      validators.name.valid = true;
    }
  }, [reportScheduleData])

  const handleSave = () => {
    const report_schedule = {};
    report_schedule.report_name = inputValues.name;
    report_schedule.report_frequency = report_frequency.value;
    report_schedule.end_date = format(new Date(end_date), `${DATE_FORMAT.yyyyMMdd} 00:00:00`);
    report_schedule.recipients = recipients;

    if (report_frequency.value === 1) { //if its Weekly then add custom_days
      report_schedule.custom_days = custom_days;
    } else {
      report_schedule.custom_days = [];
    }

    const payload = (method === "POST") ? { report_schedule, report_template, method } : { report_schedule, id, method }
    dispatch(scheduleReportActionCreator.saveScheduleReportRequest(payload));
  }

  return (<>
    <div className="shedule-report-container">
      <div className="shedule-report-wrapper">
        <Card style={{ pointerEvents: `${type === 'view' ? "none" : ""}` }}>
          <CardBody className={`${isFetching ? "loading" : ""}`}>
            {isFetching ? (<>
              <SkeletonScheduleReport />
            </>) : (<>
              <p className="message">
                We will send you a sample scheduled report immediately.
              </p>
              <div className="ad-form shedule-report-form">
                <Row>
                  <Col md={12} sm={12} lg={12}>
                    <FormGroup className="mb-2-rem">
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        required
                        className="form-input form-input-sm mt-2 required"
                        autoComplete="off"
                        value={inputValues.name}
                        onChange={handleInputChange}
                        invalid={validators.name.errorMsg !== ""}
                        tabIndex='1'
                      />
                      <Label className="floating-label required">Report Name <span className="text-danger">*</span></Label>
                      <FormFeedback>{validators.name.errorMsg}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12} lg={12}>
                    <FormGroup className="mb-2-rem">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        required
                        className="form-input form-input-sm mt-2"
                        autoComplete="off"
                        value={inputValues.email}
                        onChange={handleInputChange}
                        invalid={inputValues.email !== "" && validators.email.errorMsg !== ""}
                        onKeyPress={handleEmail}
                        onFocus={() => setEmailPlaceholderOnFocus(true)}
                        onBlur={(e) => {
                          handleEmail(e)
                          setEmailPlaceholderOnFocus(false)}
                        }
                        placeholder={ (emailPlaceholderOnFocus) ? "" : (recipients.length > 0) ? `${recipients.length} email address added` : "Enter up to 10 email addresses"}
                        tabIndex='2'
                      />
                      {(recipients.length > 0 || emailPlaceholderOnFocus) && (<span className="field-placeholder">Email <span className="text-danger">*</span></span>)}
                      {recipients.length > 0 && (<div className="textbox-action-btn" onClick={clearAllEmail}>Clear all</div>)}
                      <FormFeedback>{inputValues.email !== "" && validators.email.errorMsg}</FormFeedback>
                      {recipients.length > 0 && recipients.map((item, index) => <ADBadge
                        key={item}
                        color="dark"
                        className="email-badge"
                        badgeName={item}
                        closeIcon={true}
                        closeCallback={handleRemoveEmail}
                        index={index}
                      />)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="multiple-field-container">
                  <Col md={12} sm={12} lg={multipleFieldsCols}>
                    <div className="option-selector">
                      <p>Frequency <span className="text-danger">*</span></p>
                      <Dropdown
                        isOpen={frequencyDropdownState}
                        toggle={toggleFrequency}
                        className="dropdown-option"
                      >
                        <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                          className="dropdown_toggle"
                          tabIndex='3'
                        >
                          <span
                            className="dropdown_title"
                            title={"Select Frequency"}
                          >
                            {report_frequency.label}
                          </span>
                          <img
                            src={
                              frequencyDropdownState
                                ? statusIcons.ARROW_UP
                                : statusIcons.ARROW_DOWN
                            }
                            className="dropdown-img"
                          ></img>
                        </DropdownToggle>
                        <DropdownMenu>
                          {report_frequency_arr && (
                            report_frequency_arr.map((item) => {
                              return (
                                <DropdownItem
                                  key={item.id}
                                  onClick={(e) => handleFrequencySelect(e, item)}
                                  className={`d-flex justify-content-between align-items-center ${item.id === report_frequency.id ? "dropdown-option-selected" : ""}`}
                                >
                                  <div className="options-container w-100">
                                    {item.label}
                                    {item.id === report_frequency.id ? (<img
                                      src={statusIcons.CHECKED_BLUE_ICON}
                                      alt="frequency selected"
                                      className="option-selected"
                                    />) : ""}
                                  </div>
                                  
                                </DropdownItem>
                              )
                            })
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                  {multipleFieldsCols === 4 && (<>
                    <Col md={12} sm={12} lg={multipleFieldsCols}>
                      <div className="option-selector">
                        <p>Week Day <span className="text-danger">*</span></p>
                        <Dropdown
                          isOpen={customDaysDropdownState}
                          toggle={toggleCustomDaysDropdownState}
                          className="dropdown-option"
                          direction="down"
                          style={{ pointerEvents: `${type === 'view' ? "auto" : ""}`}}
                        >
                          <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            className="dropdown_toggle"
                            tabIndex='4'
                          >
                            <span
                              className="dropdown_title"
                              title={"Select Week Day"}
                            >
                              {custom_days.length > 0 ? `${custom_days.length} day${custom_days.length > 1 ? 's' : ''} Selected` : (<><span>Select Week Day </span></>)}
                            </span>
                            <img
                              src={
                                customDaysDropdownState
                                  ? statusIcons.ARROW_UP
                                  : statusIcons.ARROW_DOWN
                              }
                              className="dropdown-img"
                            ></img>
                          </DropdownToggle>
                          <DropdownMenu right>
                            {custom_days_arr && (
                              custom_days_arr.map((item, index) => {
                                return (
                                  <DropdownItem
                                    key={item.id}
                                    toggle={false}
                                    id={"week-day-item-" + item.value + index}
                                    className={`d-flex justify-content-between align-items-center pr-2 ${custom_days.includes(item.id) ? "dropdown-option-selected" : ""}`}
                                    style={{ pointerEvents: `${type === 'view' ? "none" : ""}`}}
                                  >
                                    <div className="options-container w-100">
                                      <CustomInput
                                        className={`ad-transparent-checkbox`}
                                        type="checkbox"
                                        name={item.value}
                                        value={item.value}
                                        id={item.value}
                                        label={item.label}
                                        checked={custom_days.findIndex(
                                          (element) => element === item.id) > -1 ? true : false
                                        }
                                        onChange={(e) => handleCustomDaysChange(e, item.id)}
                                      />
                                      {custom_days.includes(item.id) ? (<img
                                        src={statusIcons.CHECKED_BLUE_ICON}
                                        alt="Week Day selected"
                                        className="option-selected"
                                      />) : ""}
                                    </div>
                                  </DropdownItem>
                                )
                              })
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  </>)}
                  <Col md={12} sm={12} lg={multipleFieldsCols}>
                    <div className="option-selector">
                      <p>End Date <span className="text-danger">*</span></p>
                      <div className="date-container">
                        <div className="date-wrapper">
                          <nav className="ad-date-container">
                            <Dropdown
                              isOpen={selectedDatedownState}
                              toggle={toggleSelectedDate}
                              className={(selectedDatedownState ? "dropdown-option-selected" : "") + " dropdown-option"}
                            >
                              <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                className="dropdown_toggle"
                                tabIndex='5'
                              >
                                <span
                                  className="dropdown_title"
                                  title="Select end date"
                                  style={{ color: "#0E0E0F" }}
                                >
                                  {displayDate}
                                </span>
                                <img
                                  src={
                                    selectedDatedownState
                                      ? statusIcons.CALENDER_BLUE_ICON
                                      : statusIcons.CALENDER_GRAY_ICON
                                  }
                                  className="dropdown-img"
                                  style={{ width: "15px", padding: "0", cursor: "pointer" }}
                                ></img>
                              </DropdownToggle>
                              <DropdownMenu right className={`ad-date-picker ${multipleFieldsCols === 4 ? "ad-date-picker-minimize" : ""}`}>
                                <DropdownItem toggle={false}>
                                  <ADCalendarSingle
                                    handleDateChange={handleDateChange}
                                    selectedDate={end_date}
                                    closeCalendar={toggleSelectedDate}
                                    disablePastDays={true}
                                    minDateDisalble= {defaultEndDate}
                                  />
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row >
                  <Col md={12} sm={12} lg={12}>
                    <p className="report-info">{reportInfoMessage.label}</p>
                  </Col>
                </Row>
                {type !== 'view' && <Row className="footer-row">
                  <Col md={12} sm={12} lg={12}>
                    <div className="actions">
                      <Button
                        className="in-btn-lg in-btn-info btn-save"
                        type="submit"
                        disabled={isValid ? false : true}
                        onClick={handleSave}
                        tabIndex='6'
                      >
                        {isSaving ? <ADspinner size="large" /> : 'Save'}
                      </Button>
                      <Button
                        className="in-btn-link btn btn-link btn-clear btn-cancel"
                        type="submit"
                        onClick={() => setOpenPopUp(false)}
                        tabIndex='7'
                      >
                        {'Cancel'}
                      </Button>
                    </div>
                  </Col>
                </Row>}
              </div>
            </>)}
          </CardBody>
        </Card>
      </div>
    </div>
  </>)
}

export default SheduleReport;