import { takeLatest, call, select, put } from "redux-saga/effects";
import { brandsActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendBrandsListRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = "";
  let encodeString = "";
  const param = {};
  // param.source = 1;
  param.limit = 100;

  if (payload?.searchKey) {
    encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    param.search = encodeString;
  }

  if (payload?.limit) {
    param.limit = payload.limit;
    param.offset = payload.offset;
  }
  if (payload?.organization) {
    param.organization_id = payload.organization;
  }

  if (payload?.organization_id) {
    param.organization_id = Array.isArray(payload.organization_id) ? payload.organization_id.join() : payload.organization_id;
  }

  if (payload?.id) {
    API_URL = API_URL_PARAM + "/v1/brands/" + payload.id;
  } else
    API_URL = API_URL_PARAM + "/v1/brands";

  return axios({
    url: API_URL,
    method: "GET",
    params: param,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendBrandsListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendBrandsListRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(brandsActionCreator.brandsListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(brandsActionCreator.brandsListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(brandsActionCreator.brandsListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(brandsActionCreator.brandsListFailure("Server Error"));
  }
}

function uploadFile(API_URL_PARAM, jwtToken, file) {
  const formData = new FormData();
  formData.append("file", file);
  const API_URL = API_URL_PARAM + "/v1/brands/upload";
  return axios({
    method: "POST",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
      "Content-Type": "multiplart/form-data",
    },
    data: formData,
  }).catch((err) => {
    console.log(err);
  });
}

function* uploadFileSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const file = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(uploadFile, API_URL, jwtToken, file);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(brandsActionCreator.uploadFileSuccess(item));
      yield put(notificationActionCreator.setNotification({ color: "success", message: status.status_message }));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(brandsActionCreator.uploadFileFailure(errorMsg.join()));
        yield put(notificationActionCreator.setNotification({ message: errorMsg.join() }));
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(brandsActionCreator.uploadFileFailure(errorMsg));
        yield put(notificationActionCreator.setNotification({ message: errorMsg }));
      }
    }
  } catch (error) {
    yield put(brandsActionCreator.uploadFileFailure("Server Error"));
  }
}

function saveBrandRequest(API_URL_PARAM, jwtToken, data, organization_id, method = "POST", id) {
  let API_URL;
  if (method === "POST") {
    API_URL = `${API_URL_PARAM}/v1/organizations/${organization_id}/brands_v2`;
  } else if (method === "PUT") {
    API_URL = `${API_URL_PARAM}/v1/organizations/${organization_id}/brands_v2/${id}`;
  }

  return axios({
    url: API_URL,
    method: method,
    data: data,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* saveBrandRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;

    let organization_id = action.payload.organization_id;
    let method = action.payload?.method;
    let id = action.payload?.id;

    delete action.payload.method;
    delete action.payload.id;
    delete action.payload.organization_id;

    const result = yield call(
      saveBrandRequest,
      API_URL,
      jwtToken,
      action.payload,
      organization_id,
      method,
      id
    );

    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      // if (method === "POST") {
      yield put(brandsActionCreator.saveBrandRequestSuccess(item));
      // } else {
      //yield put(brandsActionCreator.updateBrandRequestSuccess(item));
      // }
      yield put(notificationActionCreator.setNotification({ color: "success", message: status.status_message, position: "fixed" }));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.keys(errorMsgList).map((err) => {
          if (Array.isArray(errorMsgList[err])) {
            return errorMsgList[err][0]
          }else{
            return errorMsgList[err];
          }
        });
        yield put(brandsActionCreator.saveBrandRequestFailure(errorMsg.join("\n")))
        yield put(notificationActionCreator.setNotification({ message: errorMsg.join("<br/>"), position: "fixed" }));
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(brandsActionCreator.saveBrandRequestFailure(errorMsg))
        yield put(notificationActionCreator.setNotification({ message: errorMsg, position: "fixed" }));
      }
    }
  } catch (error) {
    yield put(brandsActionCreator.saveBrandRequestFailure("Server Error"));
    yield put(notificationActionCreator.setNotification({ message: "Server Error"}));
  }
}



function getBrandsByIdRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = "";
  let encodeString = "";
  const param = {};
  // param.source = 1;
  param.limit = 100;

  if (payload?.searchKey) {
    encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    param.search = encodeString;
  }

  if (payload.limit) {
    param.limit = payload.limit;
    param.offset = payload.offset;
  }
  if (payload?.organization) {
    param.organization = payload.organization;
  }

  if (payload?.organization_id) {
    param.organization_id = Array.isArray(payload.organization_id) ? payload.organization_id.join() : payload.organization_id;
  }

  API_URL = API_URL_PARAM + "/v1/brands/" + payload.id;

  return axios({
    url: API_URL,
    method: "GET",
    params: param,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getBrandsByIdSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getBrandsByIdRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(brandsActionCreator.getBrandsByIdSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(brandsActionCreator.getBrandsByIdFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(brandsActionCreator.getBrandsByIdFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(brandsActionCreator.getBrandsByIdFailure("Server Error"));
  }
}

export default function* brandsSagas() {
  yield takeLatest(ACTIONS.BRANDS_LIST_REQUEST, sendBrandsListRequestSaga);
  yield takeLatest(ACTIONS.UPLOAD_FILE_REQUEST, uploadFileSaga);
  yield takeLatest(ACTIONS.SAVE_BRAND_REQUEST, saveBrandRequestSaga);
  yield takeLatest(ACTIONS.GET_BRAND_BY_ID_REQUEST, getBrandsByIdSaga);
}