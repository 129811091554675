import ACTIONS from "../constants/actionTypes";

export const dashboardRequest = (data) => {
  return {
    type: ACTIONS.DASHBOARD_REQUEST,
    payload: data,
  };
};

export const dashboardSuccess = (data) => {
  return {
    type: ACTIONS.DASHBOARD_SUCCESS,
    payload: data,
  };
};

export const dashboardFailure = (errors) => {
  return {
    type: ACTIONS.DASHBOARD_FAILURE,
    payload: errors,
  };
};

export const dashboardFailureClear = () => {
  return {
    type: ACTIONS.DASHBOARD_FAILURE_CLEAR,
  };
};

export const getSpendCtrCpaRequest = (data) => {
  return {
    type: ACTIONS.GET_SPEND_CTR_CPA_REQUEST,
    payload: data
  };
};

export const getSpendCtrCpaSuccess = (data) => {
  return {
    type: ACTIONS.GET_SPEND_CTR_CPA_SUCCESS,
    payload: data,
  };
};

export const getSpendCtrCpaFailure = (errors) => {
  return {
    type: ACTIONS.GET_SPEND_CTR_CPA_FAILURE,
    payload: errors,
  };
};

export const getSpendCtrCpaFailureClear = () => {
  return {
    type: ACTIONS.GET_SPEND_CTR_CPA_FAILURE_CLEAR,
  };
};

export const getSpendClicksConversionRequest = (data) => {
  return {
    type: ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_REQUEST,
    payload: data
  };
};

export const getSpendClicksConversionSuccess = (data) => {
  return {
    type: ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_SUCCESS,
    payload: data,
  };
};

export const getSpendClicksConversionFailure = (errors) => {
  return {
    type: ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_FAILURE,
    payload: errors,
  };
};

export const getSpendClicksConversionFailureClear = () => {
  return {
    type: ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_FAILURE_CLEAR,
  };
};

export const getDSPCampaignsListRequest = (data) => {
  return {
    type: ACTIONS.DSP_CAMPAIGNS_LIST_REQUEST,
    payload: data,
  };
};

export const getDSPCampaignsListSuccess = (data) => {
  return {
    type: ACTIONS.DSP_CAMPAIGNS_LIST_SUCCESS,
    payload: data,
  };
};

export const getDSPCampaignsListFailure = (errors) => {
  return {
    type: ACTIONS.DSP_CAMPAIGNS_LIST_FAILURE,
    payload: errors,
  };
};

export const getDSPCampaignsListFailureClear = () => {
  return {
    type: ACTIONS.DSP_CAMPAIGNS_LIST_FAILURE_CLEAR,
  };
};

export const getDSPCampaignsLineListRequest = (data) => {
  return {
    type: ACTIONS.DSP_CAMPAIGN_LINE_LIST_REQUEST,
    payload: data,
  };
};

export const dashboardCampaignMetricDataRequest = (data) => {
  return {
    type: ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_REQUEST,
    payload: data,
  };
};

export const getDSPCampaignsLineListSuccess = (data) => {
  return {
    type: ACTIONS.DSP_CAMPAIGN_LINE_LIST_SUCCESS,
    payload: data,
  };
};

export const dashboardCampaignMetricDataSuccess = (data) => {
  return {
    type: ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_SUCCESS,
    payload: data,
  };
};

export const getDSPCampaignsLineListFailure = (errors) => {
  return {
    type: ACTIONS.DSP_CAMPAIGN_LINE_LIST_FAILURE,
    payload: errors,
  };
};

export const dashboardCampaignMetricDataFailure = (errors) => {
  return {
    type: ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE,
    payload: errors,
  };
};

export const getDSPCampaignsLineListFailureClear = () => {
  return {
    type: ACTIONS.DSP_CAMPAIGN_LINE_LIST_FAILURE_CLEAR,
  };
};
export const dashboardCampaignMetricDataFailureClear = () => {
  return {
    type: ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE_CLEAR,
  };
};
