import React, { useState } from 'react';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

const CloneRule = ({ actionIcons, goToCreateNewRule, data }) => {
  return (
    <span onClick={(e) => goToCreateNewRule(e, data)}>
      <img src={actionIcons.CLONE_ICON} className="preview-icon" alt="preview"
        style={{ cursor: "pointer", marginLeft: "18px" }} />
    </span >
  )
}

export default CloneRule;