import React from 'react';
import './index.scss';

const Loader = (props) => {
	return (
		<div className="spinner-container" style={props.style}>
			<img src="/Icon_assets/svg/loader.gif" style={{ margin: "20px" }} />
		</div>
	);
}

export default Loader;