import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Button,
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  UncontrolledTooltip, Badge
} from "reactstrap";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import Loader from '../../Loader';
import ADPagination from "../../ADPagination";

import UserSurveyTable from "./UserSurveyTable";
import AdminSurveyTable from "./AdminSurveyTable";
import NoServey from './NoServey';
import {
  realtimeResearchActionCreator, brandsActionCreator,
  organizationActionCreator, surveyActionCreator, notificationActionCreator
} from "../../../actions";
import SkeletonResearchTable from "../../Loader_Skeleton/SkeletonResearchTable";
import "./index.scss";
import RBAC from "../../../components/RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";
import checkRBAC from '../../../utils/checkRBAC'

const RealtimeResearch = () => {
  const MAX_LIMIT_REACHED = 20;
  //Icons setup
  const ICONS = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    SEPERATOR: "/Icon_assets/svg/seperator.svg",
    SEARCH: "/Icon_assets/svg/search.svg",
    SEARCH_ACTIVE: "/Icon_assets/svg/search-active.svg"
  }

  const NoServeyMessage = {
    NO_SURVEY_FOUND: "No survey found.",
    NO_SURVEY_ON_ACCOUNT: "There are no surveys for this account yet."
  }

  /**
 states required for RBAC 
  */
  const userRbac = useSelector(state => state.auth.rbac);

  function isAuthorized(requiredPermissions) {
    return checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization)
  }

  /*** Redux init ***/
  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);
  const [searchImage, setSearchImage] = useState(ICONS.SEARCH);
  const [listLoadCount, setListLoadCount] = useState(0);
  const [orgList, setOrgList] = useState([]);
  const [adList, setAdList] = useState([]);
  const [totalOffset, setTotalOffset] = useState(0);
  const [adCount, setAdCount] = useState([0]);
  const [orgCount, setOrgCount] = useState([0]);

  /*** Pagination setup start***/
  const [currentPage, setCurrentPage] = useState(1);
  const [isPagination, setIsPagination] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [maxLimitSelected, setMaxLimitSelected] = useState(false);
  let PageSize = 10;
  /*** Pagination setup end***/

  //Filter section
  const platformList = [
    { name: "Invisibly", id: 0, value: "Invisibly" },
    { name: "Programmatic", id: 1, value: "Programmatic" },
    { name: "Both", id: 2, value: "Both" }
  ];
  const [platformListFiltered, setPlatformListFiltered] = useState(platformList);
  const [platformDropDownOpen, setPlatformDropdownOpen] = useState(false);
  const togglePlatformDropdown = () => {
    setPlatformDropdownOpen((prevState) => !prevState);
    if (!platformDropDownOpen) {
      setSearchValue("");
    }
  }


  const [advertiserDropdownOpen, setAdvertiserDropdownOpen] = useState(false);
  const toggleAdvertiserDropdown = () => {
    setAdvertiserDropdownOpen((prevState) => !prevState);
    if (!advertiserDropdownOpen) {
      setSearchValue("");
      setAdList([]);
      setTotalOffset(0);
      setAdCount(0);
    }
  }
  const [organizationDropdownOpen, setOrganizationDropdownOpen] = useState(false);
  const toggleorganizationDropdownOpen = () => {
    setOrganizationDropdownOpen((prevState) => !prevState);
    if (!organizationDropdownOpen) {
      setSearchValue("");
      setOrgList([]);
      setTotalOffset(0);
      setOrgCount(0);
    }
  }

  const [selectedAdvertiserList, updateSelectedAdvertiserList] = useState([]);
  const [selectedAdvertiserName, updateSelectedAdvertiserName] = useState([]);
  const [selectedOrganizationName, updateSelectedOrganizationName] = useState([]);
  const [selectedAdvertiserFilterSet, updateSelectedAdvertiserFilterSet] = useState([]);
  const [selectedPlatformName, updateSelectedPlatformName] = useState([]);
  const [selectedPlatformList, updateSelectedPlatformList] = useState([]);
  const [platformHovered, setPlatformHovered] = useState(false);
  const [adHovered, setAdHovered] = useState(false);
  const [orgHovered, setOrgHovered] = useState(false);
  const [selectedOrganizationList, updateSelectedOrganizationList] = useState([]);
  const [selectedOrganizationFilterSet, updateSelectedOrganizationFilterSet] = useState([]);

  const isAdvertiserRequest = useSelector((state) => state.brands.isLoading);
  const advertiserData = useSelector((state) => state.brands.data?.results);

  const isOrganizationRequest = useSelector((state) => state.organization.isLoading);
  const organizationData = useSelector((state) => state.organization.organizationData?.results);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const advertiserCount = useSelector((state) => state.brands.data?.count);
  const organizationCount = useSelector((state) => state.organization.organizationData?.count);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.realtimeResearch.isLoading);
  const tableData = useSelector((state) => state.realtimeResearch.realtimeResearchData);
  const msgs = useSelector((state) => state.realtimeResearch.successMsg);
  const userData = useSelector((state) => state.auth.userData);
  const upadtedAdStateData = useSelector((state) => state.realtimeResearch.upadtedAdStateData);
  const adCloneResponseData = useSelector((state) => state.realtimeResearch.adCloneResponseData);
  const isAdDeleted = useSelector((state) => state.realtimeResearch.isAdDeleted);
  const activatedAdData = useSelector((state) => state.surveyData.activatedAdData);
  const role = userData.organization_role;

  const [brand_id, setBrandId] = useState(selectedOrganization ? selectedOrganization?.brands ?
    selectedOrganization?.brands ? selectedOrganization?.brands.map(el => el.id) : '' : '' : "");
  const [organization_id, setOrgId] = useState(selectedOrganization ? selectedOrganization ? selectedOrganization?.id : "" : "");
  const user_role = userData?.organization_role;
  const [isOnLoadDone, setisOnLoadDone] = useState(false);
  const paramListInitialState = {
    organization_id: user_role === 0 ?
      (selectedOrganization?.id ? (isOnLoadDone ? [selectedOrganization?.id] : []) : []) : //for admin setting Organization
      organization_id ? [organization_id] : [], //for analytics user setting Organization
    brand_id: [],
    user_role: user_role,
    name: "",
    pageIndex: 1,
    offset: 0,
    sortOrder: 'desc',
    sortBy: 'updated_at'
  };
  const [paramList, setParamList] = useState(paramListInitialState);
  /*** Redux end ***/

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('ad_state');
    localStorage.removeItem('brand_id');
    localStorage.removeItem('msg_id');

    if ((userData.organization_role !== 0) && !selectedOrganization?.is_rtr_enabled) {
      history.push(ROUTES.DASHBOARD)
      return
    }

    const params = {};
    if (user_role !== 0) {
      //if user is analytics user (not a admin)
      params.organization_id = organization_id;
    }

    if (paramList.sortOrder) {
      params.sortBy = paramList.sortBy;
      params.sortOrder = paramList.sortOrder;
    }
    if (!maxLimitSelected) {
      params.organization_id = localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : undefined;
      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(params));
    }
    setisOnLoadDone(true);

    //on component unmount clear the selected Organization
    return () => {
      setPlatformListFiltered(platformList);
      // dispatch(organizationActionCreator.removedOrganization());
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("rtrList")) {
      var paramList = JSON.parse(localStorage.getItem('rtrList')) || [];
      setParamList(paramList);
      setCurrentPage(paramList.pageIndex);
      updateSelectedAdvertiserList(paramList.brand_id);
      updateSelectedOrganizationList(paramList.organization_id);
      updateSelectedOrganizationName(paramList.organization_name || []);
      updateSelectedAdvertiserName(paramList.brand_name);
      updateSelectedPlatformList(paramList.rtr_type || []);
      updateSelectedPlatformName(paramList.rtr_name || "");
    }

    if (localStorage.getItem('selectedOrganization')) {
      var params = {};
      params.organization_id = JSON.parse(localStorage.getItem('selectedOrganization')).id;
      if (!maxLimitSelected) {
        dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(params));
      }
    }
    if (localStorage.getItem('researchSurveysSearchValue')) {
      setParamList({ ...paramList, name: localStorage.getItem('researchSurveysSearchValue'), offset: 0, pageIndex: 0 });
    }
  }, [localStorage])

  useEffect(() => {
    if (tableData && tableData.results && tableData.count > 0) {
      //if tableData have more than 1 records show Pagination
      setIsPagination(true);
      if (tableData.next === null) {
        //if tableData have only 1 page set current page to 1
        const checkPageCount = Math.ceil(tableData.count / PageSize);
        setCurrentPage(checkPageCount);
      }
    } else {
      setIsPagination(false);
    }
    setListLoadCount(prevState => prevState + 1);
  }, [tableData]);

  useEffect(() => {
    if (isOnLoadDone) {

      var orgLength = 0, brandsLen = 0;
      const params = {};

      if (user_role !== 0) {
        //if user is analytics user (not a admin)
        params.organization_id = organization_id;
      }

      if (paramList.brand_id && paramList.brand_id.length) {
        brandsLen = paramList.brand_id.length || 0;
        params.brand_id = paramList.brand_id;
      }

      if (paramList.organization_id && paramList.organization_id.length) {
        orgLength = paramList.organization_id.length || 0;
        if (paramList.brand_id.length === 0) {
          params.organization_id = paramList.organization_id;
        }
      }

      if (paramList.rtr_type && paramList.rtr_type.length && paramList.rtr_type.length > 1) {
        params.rtr_type = paramList.rtr_type.toString();
      } else if (paramList.rtr_type && paramList.rtr_type.length === 1) {
        params.rtr_type = paramList.rtr_type[0];
      }

      if (paramList.name) {
        params.name = paramList.name;
      }

      if (paramList.pageIndex) {
        params.offset = paramList.offset;
      }

      if (paramList.sortOrder) {
        params.sortOrder = paramList.sortOrder;
      }

      if (paramList.sortBy) {
        params.sortBy = paramList.sortBy;
      }

      if ((brandsLen + orgLength) <= MAX_LIMIT_REACHED) {
        params.organization_id = paramList.organization_id ? paramList.organization_id : localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : undefined;
        dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(params));
      }
    }
  }, [paramList]);

  useEffect(() => {
    if ((adCloneResponseData && Object.keys(adCloneResponseData).length) || isAdDeleted || (upadtedAdStateData && Object.keys(upadtedAdStateData).length) || (activatedAdData && Object.keys(activatedAdData).length)) {

      paramList.organization_id = localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : undefined;
      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(paramList));
      dispatch(realtimeResearchActionCreator.clearResponseData());
    }
    if (activatedAdData && Object.keys(activatedAdData).length) {
      dispatch(surveyActionCreator.activateAdRequestClear());
    }
  }, [adCloneResponseData, isAdDeleted, upadtedAdStateData, activatedAdData]);

  useEffect(() => {
    if (selectedOrganization && isOnLoadDone) {
      setParamList({ ...paramList, organization_id: [selectedOrganization.id], brand_id: [], name: "" });
      // localStorage.setItem("rtrList",JSON.stringify(data));
      clearAllFilters(); //clear all filters
    }

    if (selectedOrganization === "" && isOnLoadDone) {
      setParamList({ ...paramList, organization_id: [], brand_id: [] });
      clearAllFilters(); //clear all filters
    }

    if (isOnLoadDone && (paramList.organization_id && paramList.organization_id.length)) {
      setParamList({ ...paramList, organization_id: [paramList.organization_id], brand_id: [] });
      clearAllFilters(); //clear all filters
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedAdvertiserList.length + selectedOrganizationList.length > MAX_LIMIT_REACHED) {
      setMaxLimitSelected(true);
      dispatch(notificationActionCreator.setNotification({
        color: "warning",
        message: "<b>Maximum filter items reached.</b> You can select up to 20 Organizations, and advertisers."
      }));
    } else {
      setMaxLimitSelected(false);
    }
  }, [selectedAdvertiserList, selectedOrganizationList]);

  useEffect(() => {
    if (advertiserCount) {
      setAdCount(advertiserCount);
    }

    if (organizationCount) {
      setOrgCount(organizationCount);
    }
    // update the list
    if (advertiserData && advertiserData.length > 0) {
      const newList = adList.concat(advertiserData);
      setAdList(newList);
    }

    if (organizationData && organizationData.length > 0) {
      const newList = orgList.concat(organizationData);
      setOrgList(newList);
    }

  }, [advertiserData, organizationData, advertiserCount, organizationCount]);

  useEffect(() => {
    var advertiserDrop = document.getElementById("advertiserDropDown");

    if (adList && advertiserDrop) {
      advertiserDrop.addEventListener('scroll', handleAdvertiserScroll);
      return () =>
        advertiserDrop.removeEventListener('scroll', handleAdvertiserScroll);
    }
  }, [adList])

  useEffect(() => {
    var organizationDrop = document.getElementById("organizationDropDown");
    if (organizationDrop && orgList && orgList.length > 0) {
      organizationDrop.addEventListener('scroll', handleOrganizationScroll);
      return () =>
        organizationDrop.removeEventListener('scroll', handleOrganizationScroll);
    }

  }, [orgList])

  var thElm;
  var startOffset;
  useEffect(() => {
    var table = document.getElementById("adminSelectedAdvertiser");
    if (advertiserDropdownOpen) {
      table = document.getElementById("adminSelectedAdvertiser");
    }
    if (organizationDropdownOpen) {
      table = document.getElementById("adminSelectedOrganization");
    }

    var resizer = document.getElementById("resizer-col")

    if (advertiserDropdownOpen || organizationDropdownOpen) {
      Array.prototype.forEach.call(
        table.querySelectorAll(".resized-column"),
        function (th) {
          //th.style.position = 'relative';
          if (document.getElementById('resizer') === null) {
            var grip = document.createElement('div');
            grip.className = 'column-resizer';
            grip.id = 'resizer';
            grip.innerHTML = "&nbsp;&nbsp;";
            grip.style.left = `${expandedTableColWidth - 20}px`;
            // grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.position = 'sticky';
            grip.style.top = '0px';
            grip.style.zIndex = "9999";
            grip.style.cursor = 'col-resize';
            grip.style.float = 'right';

            grip.addEventListener('mousedown', function (e) {
              thElm = th;
              startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
          } else {
            var t = document.getElementById('resizer');
            t.style.left = `${expandedTableColWidth - 20}px`;
          }
        });
      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            // resizer.style.width = startOffset + e.pageX + 'px';
            // resizer.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  }, [advertiserDropdownOpen, expandedTableColWidth,
    organizationDropdownOpen])

  const sortColumnData = (colName, sortOrder) => {
    // to do : API to confirm value for sortOrder and col name.
    setParamList({ ...paramList, sortOrder: sortOrder, sortBy: colName });
  }

  const handleAdvertiserScroll = () => {
    var count = adCount;
    var adListCount = adList.length;
    var advertiserDrop = document.getElementById("advertiserDropDown");
    if ((advertiserDrop.offsetHeight + advertiserDrop.scrollTop + 10) >= advertiserDrop.scrollHeight) {
      if (count > adListCount && adListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        if (searchValue !== "") {
          params.search = searchValue;
        }
        dispatch(brandsActionCreator.brandsListRequest(params));
      }
    }
  }

  const handleOrganizationScroll = () => {
    var count = orgCount;
    var orgListCount = orgList.length;
    var organizationDrop = document.getElementById("organizationDropDown");
    if ((organizationDrop.offsetHeight + organizationDrop.scrollTop + 10) >= organizationDrop.scrollHeight) {
      if (count > orgListCount && orgListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        params.report_type = "1,2";
        if (searchValue !== "") {
          params.search = searchValue;
        }
        dispatch(organizationActionCreator.getOrganizationListRequest(params));
      }
    }
  }
  const handleSearch = (event) => {
    //on each search set current page to 1 in Pagination
    localStorage.setItem('researchSurveysSearchValue', event.target.value);
    setCurrentPage(1);
    setParamList({ ...paramList, name: event.target.value, offset: 0, pageIndex: 0 });
  }

  const handleSearchDropDown = (evt, elem) => {
    setSearchValue(evt.target.value);
    const val = evt.target.value;
    let params = {};
    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }

    switch (elem) {
      case 'adminSelectedOrganization':
        params.limit = 10;
        params.name = val;
        params.report_type = "1,2";
        if (val !== "") {
          params.search = val;
        }
        dispatch(organizationActionCreator.getOrganizationListRequest(params));
        setOrgList([]);
        setOrgCount(0);
        setTotalOffset(0);
        break;

      case 'adminSelectedAdvertiser':
        params.limit = 10;
        if (selectedOrganization) {
          params.organization_id = selectedOrganization.id;
        } else {
          if (selectedOrganizationList.length) {
            params.organization_id = selectedOrganizationList;
          }
        }
        if (val !== "") {
          params.searchKey = val;
        }
        dispatch(brandsActionCreator.brandsListRequest(params));
        setAdList([]);
        setAdCount(0);
        setTotalOffset(0);

        break;

      case 'platformDropDown':
        let result = [];
        result = platformList && platformList.filter((data) => {
          return data.name.toLowerCase().search(val.toLowerCase()) != -1;
        });

        var d = [...new Set([...result])]
        setPlatformListFiltered(d);
        break;
      default:
        break;
    }
  }

  const handlePaginationClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
    data.pageIndex = pageIndex;
    data.offset = (pageIndex - 1) * 10
    localStorage.setItem("rtrList", JSON.stringify(data));

    const params = {};

    if (user_role !== 0) {
      //if user is analytics user (not a admin)
      params.organization_id = organization_id;
    }

    if (paramList.brand_id && paramList.brand_id.length) {
      params.brand_id = paramList.brand_id;
    }

    if (paramList.organization_id && paramList.organization_id.length) {
      if (paramList.brand_id.length === 0) {
        params.organization_id = paramList.organization_id;
      }
    }

    if (paramList.name) {
      params.name = paramList.name;
    }

    if (paramList.rtr_type && paramList.rtr_type.length && paramList.rtr_type.length > 1) {
      params.rtr_type = paramList.rtr_type.toString();
    } else if (paramList.rtr_type && paramList.rtr_type.length === 1) {
      params.rtr_type = paramList.rtr_type[0];
    }

    if (paramList.sortOrder) {
      params.sortOrder = paramList.sortOrder;
    }

    if (paramList.sortBy) {
      params.sortBy = paramList.sortBy;
    }

    params.organization_id = localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : undefined;
    dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({ ...params, offset: (pageIndex - 1) * 10 }));
  }

  //Get advertiser list
  const getAdvertiserDropdownOptions = () => {
    const params = {};
    params.limit = 10;
    if (!advertiserDropdownOpen) {
      if (selectedOrganization) {
        params.organization_id = selectedOrganization.id;
      } else {
        if (selectedOrganizationList.length) {
          params.organization_id = selectedOrganizationList;
        }
      }
      dispatch(brandsActionCreator.brandsListRequest(params));
    }
  };

  //Get organization list
  const getOrganizationDropdownOptions = () => {
    if (!organizationDropdownOpen && !selectedOrganization) {
      var param = { report_type: "1,2", limit: 10 }
      dispatch(organizationActionCreator.getOrganizationListRequest(param));
    }
  };

  //advertiser/organization selection filter
  const selectedAllCheckbox = (e, dropdownOption) => {
    switch (dropdownOption) {
      case "all_advertiser_options":
        if (e.target.checked) {
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserFilterSet([]);
          let selectedAdvertisers = [];
          let selectedAdvertiserName = [];
          advertiserData.map((advertiser) => {
            updateSelectedAdvertiserList((selectedAdvertiserList) => {
              selectedAdvertisers.push(advertiser.id);
              return [...selectedAdvertiserList, advertiser.id];
            });

            updateSelectedAdvertiserName((selectedAdvertiserName) => {
              selectedAdvertiserName.push(advertiser.name);
              return [...selectedAdvertiserName, advertiser.name];
            });

            updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [
              ...selectedAdvertiserFilterSet,
              advertiser.id,
            ]);
          });
          var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
          data.brand_id = advertiserData.map(function (ad) { return ad.id });
          data.brand_name = advertiserData.map(function (ad) { return ad.name });
          localStorage.setItem("rtrList", JSON.stringify(data));
          setParamList({ ...paramList, brand_id: selectedAdvertisers });
        } else {
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserName([]);
          updateSelectedAdvertiserFilterSet([]);
          var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
          data.brand_id = [];
          localStorage.setItem("rtrList", JSON.stringify(data));
          setParamList({ ...paramList, brand_id: [] });
        }
        break;
      case "all_organization_options":
        if (e.target.checked) {
          updateSelectedOrganizationList([]);
          updateSelectedOrganizationFilterSet([]);
          let selectedOrganizations = [];
          organizationData.map((organization) => {
            updateSelectedOrganizationList((selectedOrganizationList) => {
              selectedOrganizations.push(organization.id);
              return [...selectedOrganizationList, organization.id];
            });
            updateSelectedOrganizationFilterSet((selectedOrganizationFilterSet) => [
              ...selectedOrganizationFilterSet,
              organization.id,
            ]
            );
          });
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserName([]);
          updateSelectedAdvertiserFilterSet([]);
          var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
          data.organization_id = organizationData.map(function (org) { return org.id });
          data.organization_name = organizationData.map(function (org) { return org.name });
          data.brand_id = [];
          localStorage.setItem("rtrList", JSON.stringify(data));
          setParamList({ ...paramList, organization_id: selectedOrganizations, brand_id: [] });
        } else {
          updateSelectedOrganizationList([]);
          updateSelectedOrganizationName([]);
          updateSelectedOrganizationFilterSet([]);
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserName([]);
          updateSelectedAdvertiserFilterSet([]);
          localStorage.setItem("rtrList", JSON.stringify(paramListInitialState));
          setParamList({ ...paramList, organization_id: [], brand_id: [] });
        }
        break;
      default:
        break;
    }
    setCurrentPage(1);
  };

  const handleAdvertiserCheckboxChange = (e, advertiser_id, filterAdvertiser_id, advertiser_name) => {
    if (e.target.checked) {
      updateSelectedAdvertiserList((selectedAdvertiserList) => {
        setParamList({ ...paramList, brand_id: [...selectedAdvertiserList, advertiser_id] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.brand_id = [...selectedAdvertiserList, advertiser_id];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedAdvertiserList, advertiser_id];
      });

      updateSelectedAdvertiserName((selectedAdvertiserName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.brand_name = [...selectedAdvertiserName, advertiser_name];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedAdvertiserName, advertiser_name];
      });

      updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [
        ...selectedAdvertiserFilterSet,
        filterAdvertiser_id,
      ]);
    } else {
      selectedAdvertiserList.splice(selectedAdvertiserList.indexOf(advertiser_id), 1);
      selectedAdvertiserFilterSet.splice(selectedAdvertiserFilterSet.indexOf(filterAdvertiser_id), 1);
      updateSelectedAdvertiserList((selectedAdvertiserList) => {
        setParamList({ ...paramList, brand_id: [...selectedAdvertiserList] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.brand_id = [...selectedAdvertiserList];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedAdvertiserList]
      });

      selectedAdvertiserName.splice(selectedAdvertiserName.indexOf(advertiser_name), 1);
      updateSelectedAdvertiserName((selectedAdvertiserName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.brand_name = [...selectedAdvertiserName];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedAdvertiserName];
      });

      updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [...selectedAdvertiserFilterSet]);
    }
    setCurrentPage(1);
  };

  const handleOrganizationCheckboxChange = (e, organization_id, id, organization_name) => {
    if (e.target.checked) {
      updateSelectedOrganizationList((selectedOrganizationList) => {
        setParamList({ ...paramList, organization_id: [...selectedOrganizationList, organization_id], brand_id: [] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.organization_id = [...selectedOrganizationList, organization_id];
        data.brand_id = [];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedOrganizationList, organization_id]
      });

      updateSelectedOrganizationName((selectedOrganizationName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.organization_name = [...selectedOrganizationName, organization_name];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedOrganizationName, organization_name];
      });

      updateSelectedAdvertiserFilterSet((selectedOrganizationFilterSet) => [
        ...selectedOrganizationFilterSet,
        organization_id,
      ]);
    } else {
      selectedOrganizationList.splice(selectedOrganizationList.indexOf(organization_id), 1);
      selectedOrganizationFilterSet.splice(selectedOrganizationFilterSet.indexOf(organization_id), 1);
      updateSelectedOrganizationList((selectedOrganizationList) => {
        setParamList({ ...paramList, organization_id: [...selectedOrganizationList], brand_id: [] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.organization_id = [...selectedOrganizationList];
        data.brand_id = [];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedOrganizationList];
      });

      selectedOrganizationName.splice(selectedOrganizationName.indexOf(organization_name), 1);
      updateSelectedOrganizationName((selectedOrganizationName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.organization_name = [...selectedOrganizationName];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedOrganizationName];
      });


      updateSelectedAdvertiserFilterSet((selectedOrganizationFilterSet) => [...selectedOrganizationFilterSet]);
    }

    //clear child selected dropdown options
    updateSelectedAdvertiserList([]);
    updateSelectedAdvertiserName([]);
    updateSelectedAdvertiserFilterSet([]);
    setCurrentPage(1);
  };

  const handlePatformChange = (e, platform_id, platform_name) => {
    if (e.target.checked) {
      updateSelectedPlatformList((selectedPlatformList) => {
        setParamList({ ...paramList, rtr_type: [...selectedPlatformList, platform_id] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.rtr_type = [...selectedPlatformList, platform_id];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedPlatformList, platform_id];
      });

      updateSelectedPlatformName((selectedPlatformName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.rtr_name = [...selectedPlatformName, platform_name];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedPlatformName, platform_name];
      });

    } else {
      selectedPlatformList.splice(selectedPlatformList.indexOf(platform_id), 1);
      // selectedAdvertiserFilterSet.splice(selectedAdvertiserFilterSet.indexOf(filterAdvertiser_id), 1);
      updateSelectedPlatformList((selectedPlatformList) => {
        setParamList({ ...paramList, rtr_type: [...selectedPlatformList] });
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.rtr_type = [...selectedPlatformList];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedPlatformList]
      });

      selectedPlatformName.splice(selectedPlatformName.indexOf(platform_name), 1);
      updateSelectedPlatformName((selectedPlatformName) => {
        var data = JSON.parse(localStorage.getItem("rtrList")) || paramListInitialState;
        data.rtr_name = [...selectedPlatformName];
        localStorage.setItem("rtrList", JSON.stringify(data));
        return [...selectedPlatformName];
      });
    }
    setCurrentPage(1);
  }

  const clearFilterSearch = (e) => {
    e.preventDefault();
    if (selectedOrganization) {
      setParamList({ ...paramList, brand_id: [], rtr_type: [], rtr_name: [] });
    } else {
      setParamList({ ...paramList, organization_id: [], brand_id: [], rtr_type: [], rtr_name: [] });
    }
    clearAllFilters();
    var data = JSON.parse(localStorage.getItem('rtrList'));
    if (data) {
      data.brand_id = [];
      data.organization_id = [];
      data.brand_name = [];
      data.organization_name = [];
      data.rtr_type = [];
      data.rtr_name = [];
      localStorage.setItem("rtrList", JSON.stringify(data));
    }
  };

  const clearAllFilters = () => {
    updateSelectedAdvertiserList([]);
    updateSelectedAdvertiserName([]);
    updateSelectedAdvertiserFilterSet([]);
    updateSelectedOrganizationList([]);
    updateSelectedOrganizationName([]);
    updateSelectedOrganizationFilterSet([]);
    updateSelectedPlatformList([]);
    updateSelectedPlatformName([]);
    setCurrentPage(1);
  }

  return (
    <div className="right-container">
      <div className="dashboard real-time">
        <section className="title-section dashboard-header-section">
          <nav className="left-nav-section">
            <h1 className="main-heading">Realtime Research</h1>
            <p className="sub-heading">View analytics from your Realtime Research surveys.</p>
          </nav>
          <>
            <div className="filter-nav">
              <nav className="right-nav-section">
                {role === 0 &&
                  <nav className="advertiser-list-dropdown-section">
                    <Dropdown
                      isOpen={organizationDropdownOpen}
                      toggle={toggleorganizationDropdownOpen}
                      onClick={getOrganizationDropdownOptions}
                      id={"adminSelectedOrganization"}
                    >
                      <DropdownToggle
                        caret
                        className={`${Object.keys(selectedOrganization).length ? "ad-disabled" : ""}`}
                        disabled={!!selectedOrganization?.id}
                      >
                        <Badge color="primary" id="org-count" onMouseOver={() => setOrgHovered(true)}
                          onMouseOut={() => setOrgHovered(false)}>
                          {selectedOrganizationName && selectedOrganizationName.length > 0 ?
                            selectedOrganizationName.length : ''}</Badge>
                        {
                          orgHovered && <div className="hover-ad-div">
                            {selectedOrganizationName && selectedOrganizationName.map((org) => {
                              return <div>{org}</div>
                            })}
                          </div>
                        }
                        Organization
                        <img
                          src={
                            organizationDropdownOpen
                              ? ICONS.ARROW_UP
                              : ICONS.ARROW_DOWN
                          }
                          className="arrow-down"
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        className="resized-column"
                        style={{ width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px` }}>
                        <DropdownItem toggle={false}>
                          <FormGroup>
                            <Input
                              value={searchValue}
                              type="text"
                              name="search"
                              id="searchUser"
                              placeholder="Search..."
                              className="search-input"
                              autoComplete="off"
                              onChange={(event) => handleSearchDropDown(event, "adminSelectedOrganization")}
                            />
                            <img
                              src="/Icon_assets/svg/search-organization-icon.svg"
                              alt="Search"
                              className="search-image"
                            /></FormGroup>
                        </DropdownItem>
                        <div class="metrics-list" id="organizationDropDown">
                          {(!isOrganizationRequest && orgCount === 0 && searchValue !== "") ?
                            (
                              <span className="no-data-available-txt">
                                No Organization data available
                              </span>
                            ) : orgList && orgList.length ?
                              (
                                orgList && orgList.length ? (
                                  orgList.map((organization, index) => {
                                    return (
                                      <React.Fragment>
                                        <DropdownItem
                                          toggle={false}
                                          key={"organization" + organization.id}
                                          id={"ad" + organization.id + index}
                                        >
                                          <CustomInput
                                            key={"organization-" + organization.id}
                                            className="mt-3"
                                            type="checkbox"
                                            name={organization.name}
                                            value={organization.id}
                                            id={"organization-" + organization.id}
                                            label={organization.name}
                                            checked={
                                              selectedOrganizationList.findIndex(
                                                (element) => element === organization.id
                                              ) > -1
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleOrganizationCheckboxChange(
                                                e,
                                                organization.id,
                                                organization.id,
                                                organization.name
                                              )
                                            }
                                          />
                                        </DropdownItem>
                                        <UncontrolledTooltip
                                          className="ad-tool-tip"
                                          placement="top"
                                          target={"ad" + organization.id + index}
                                        >{organization.name}
                                        </UncontrolledTooltip>
                                      </React.Fragment>
                                    );
                                  }))
                                  : (
                                    <span className="no-data-available-txt">
                                      No Organization data available
                                    </span>
                                  ))
                              : <Loader />
                          }
                        </div>
                        <div className="resizer-icon" id="resizer-col">
                          <span className="first"></span>
                          <span className="second"></span>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                    {(Object.keys(selectedOrganization).length > 0) && (
                      <UncontrolledTooltip
                        className="ad-tool-tip custom-selectedOrganization"
                        placement="top"
                        target={"adminSelectedOrganization"}
                      >{Object.keys(selectedOrganization).length ?
                        (<>
                          <span className="d-block">Organization view is already selected</span>
                        </>) :
                        ""}
                      </UncontrolledTooltip>
                    )}
                  </nav>
                }


                <nav className="advertiser-list-dropdown-section">
                  <Dropdown
                    isOpen={advertiserDropdownOpen}
                    toggle={toggleAdvertiserDropdown}
                    onClick={getAdvertiserDropdownOptions}
                    id={"adminSelectedAdvertiser"}
                  >
                    <DropdownToggle caret>
                      <Badge color="primary" id="ad-count" onMouseOver={() => setAdHovered(true)}
                        onMouseOut={() => setAdHovered(false)}>
                        {selectedAdvertiserList && selectedAdvertiserList.length > 0 ? selectedAdvertiserList.length : ''}</Badge>
                      {
                        adHovered && <div className="hover-ad-div" style={{ right: "43%" }}>
                          {selectedAdvertiserName && selectedAdvertiserName.map((org) => {
                            return <div>{org}</div>
                          })}
                        </div>
                      }
                      Advertiser

                      <img
                        src={
                          advertiserDropdownOpen
                            ? ICONS.ARROW_UP
                            : ICONS.ARROW_DOWN
                        }
                        className="arrow-down"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      className="resized-column"
                      style={{ width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px` }}>
                      <DropdownItem toggle={false}>
                        <FormGroup>
                          <Input
                            value={searchValue}
                            type="text"
                            name="search"
                            id="searchUser"
                            placeholder="Search..."
                            className="search-input"
                            autoComplete="off"
                            onChange={(event) => handleSearchDropDown(event, "adminSelectedAdvertiser")}
                          />
                          <img
                            src="/Icon_assets/svg/search-organization-icon.svg"
                            alt="Search"
                            className="search-image"
                          /></FormGroup>
                      </DropdownItem>
                      <div className="metrics-list" id="advertiserDropDown">
                        {(!isAdvertiserRequest && adCount === 0 && searchValue !== "") ?
                          (
                            <span className="no-data-available-txt">
                              No Advertiser data available
                            </span>
                          ) : adList && adList.length ?
                            (
                              adList && adList.length ? (
                                adList.map((advertiser, index) => {
                                  return (
                                    <React.Fragment>
                                      <DropdownItem
                                        toggle={false}
                                        key={"advertisers" + advertiser.id + index}
                                        id={"ad" + advertiser.id + index}
                                      >
                                        <CustomInput
                                          key={"advertiser-" + advertiser.id}
                                          className="mt-3"
                                          type="checkbox"
                                          name={advertiser.name}
                                          value={advertiser.id}
                                          id={"advertiser-" + advertiser.id}
                                          label={advertiser.name}
                                          checked={
                                            selectedAdvertiserList.findIndex(
                                              (element) => element === advertiser.id
                                            ) > -1
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleAdvertiserCheckboxChange(
                                              e,
                                              advertiser.id,
                                              advertiser.id,
                                              advertiser.name
                                            )
                                          }
                                        />
                                      </DropdownItem>
                                      <UncontrolledTooltip
                                        className="ad-tool-tip"
                                        placement="top"
                                        target={"ad" + advertiser.id + index}
                                      >{advertiser.name}
                                      </UncontrolledTooltip>
                                    </React.Fragment>
                                  );
                                }))
                                : (
                                  <span className="no-data-available-txt">
                                    No Advertiser data available
                                  </span>
                                )
                            ) :
                            <Loader />
                        }</div>
                      <div className="resizer-icon" id="resizer-col">
                        <span className="first"></span>
                        <span className="second"></span>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </nav>

                <nav className="advertiser-list-dropdown-section">
                  <Dropdown
                    isOpen={platformDropDownOpen}
                    toggle={togglePlatformDropdown}
                    id={"adminSelectedPlatform"}
                  >
                    <DropdownToggle caret>
                      <Badge color="primary" id="platform-count" onMouseOver={() => setPlatformHovered(true)}
                        onMouseOut={() => setPlatformHovered(false)}>
                        {selectedPlatformList && selectedPlatformList.length > 0 ? selectedPlatformList.length : ''}</Badge>
                      {
                        platformHovered && <div className="hover-ad-div" style={{ right: "33%" }}>
                          {selectedPlatformName && selectedPlatformName.map((org) => {
                            return <div>{org}</div>
                          })}
                        </div>
                      }
                      Platform

                      <img
                        src={
                          platformDropDownOpen
                            ? ICONS.ARROW_UP
                            : ICONS.ARROW_DOWN
                        }
                        className="arrow-down"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="metrics-list" id="platformDropDown">
                        {platformListFiltered && platformListFiltered.length ?
                          (
                            platformListFiltered.map((platform, index) => {
                              return (
                                <React.Fragment>
                                  <DropdownItem
                                    toggle={false}
                                    key={"platform" + platform.id + index}
                                    id={"platform" + platform.id + index}
                                  >
                                    <CustomInput
                                      key={"platform-" + platform.id}
                                      className="mt-3"
                                      type="checkbox"
                                      name={platform.name}
                                      value={platform.id}
                                      id={"platform-" + platform.id}
                                      label={platform.name}
                                      checked={
                                        selectedPlatformList && selectedPlatformList.findIndex(
                                          (element) => element === platform.id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handlePatformChange(
                                          e,
                                          platform.id,
                                          platform.name
                                        )
                                      }
                                    />
                                  </DropdownItem>
                                  <UncontrolledTooltip
                                    className="ad-tool-tip"
                                    placement="top"
                                    target={"platform" + platform.id + index}
                                  >{platform.name}
                                  </UncontrolledTooltip>
                                </React.Fragment>
                              );
                            }))
                          :
                          (
                            <span className="no-data-available-txt" style={{ padding: 0 }}>
                              No data available
                            </span>
                          )
                        }</div>
                    </DropdownMenu>
                  </Dropdown>
                </nav>


                <nav>
                  <img src={ICONS.SEPERATOR} />
                </nav>
                <nav className="clear-filter-section">
                  <a
                    href="#"
                    className={(selectedAdvertiserList.length > 0 || selectedOrganizationList.length > 0) ? "clear-active-filter-link" : "clear-filter-link"}
                    onClick={(e) => clearFilterSearch(e)}
                  >
                    Clear
                  </a>
                </nav>
              </nav>
            </div>
            <nav className="right-nav-section campaign-btn">
              <RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_WRITE} >
                <Button className="in-btn-sm in-btn-info btn-export"
                  style={{ minWidth: '154px', minHeight: '48px' }}
                  onClick={() => history.push(`${ROUTES.REASERACH_CAMPAIGN}/preview/0`)}>Build Research Campaign</Button>
              </RBAC>
            </nav>
          </>
        </section>
        {/* {role === 0 && (<>
          <section className = "filter-section">
            <nav className={`search-container ${(listLoadCount <= 2 && tableData && tableData.length === 0) ? "d-none" : ""}`}>
            <span style={{fontSize:"22px",color: "#292D32"}}>Research Surveys</span>
              <FormGroup>
                <img
                  src={searchImage}
                  alt="Search"
                  className="search-image"
                />
                <Input
                  type="text"
                  value={paramList.name}
                  name="name"
                  id="searchUser"
                  placeholder="Search..."
                  onChange={(event) => handleSearch(event)}
                  onFocus={() => { setSearchImage(ICONS.SEARCH_ACTIVE) }}
                  onBlur={() => { setSearchImage(ICONS.SEARCH) }}
                />
              </FormGroup>
            </nav>
          </section>
        </>)} */}
      </div>
      <Card className={"data-card realtime-tbl-container"}>
        <CardBody style={{ paddingTop: "4px" }} >
          {
            role === 1 && (<section className="filter-section normal-user-section">
              <nav className="search-container">
                <span style={{ fontSize: "22px", color: "#292D32" }}>Research Surveys</span>
                <FormGroup style={{ float: "right" }}>
                  <img
                    src={searchImage}
                    alt="Search"
                    className="search-image"
                  />
                  <Input
                    type="text"
                    value={paramList.name}
                    name="name"
                    id="searchUser"
                    placeholder="Search..."
                    onChange={(event) => handleSearch(event)}
                    onFocus={() => { setSearchImage(ICONS.SEARCH_ACTIVE) }}
                    onBlur={() => { setSearchImage(ICONS.SEARCH) }}
                    autoComplete="off"
                  />
                </FormGroup>
                <div style={{ clear: "both" }}></div>
              </nav>
            </section>)
          }

          {role === 0 && (<>
            <section className="filter-section">
              <nav className={`search-container ${(listLoadCount <= 2 && tableData && tableData.length === 0) ? "" : ""}`}>
                <span style={{ fontSize: "22px", color: "#292D32", display: "inline-block", marginTop: "15px" }}>Research Surveys</span>
                <FormGroup style={{ float: "right", marginBottom: "1.3rem" }}>
                  <img
                    src={searchImage}
                    alt="Search"
                    className="search-image"
                  />
                  <Input
                    type="text"
                    value={paramList.name}
                    name="name"
                    id="searchUser"
                    placeholder="Search..."
                    onChange={(event) => handleSearch(event)}
                    onFocus={() => { setSearchImage(ICONS.SEARCH_ACTIVE) }}
                    onBlur={() => { setSearchImage(ICONS.SEARCH) }}
                  />
                </FormGroup>
                <div style={{ clear: "both" }}></div>
              </nav>
            </section>
          </>)}
          {isLoading ? (
            <SkeletonResearchTable rows={10} />
          ) :
            (
              tableData && tableData.results && tableData.results.length ?
                (
                  <>
                    {
                      isAuthorized(PERMISSIONS.BUILD_RTR_WRITE) ?
                        <AdminSurveyTable
                          tableData={tableData}
                          sortColumnData={sortColumnData}
                          sortedColumn={paramList.sortBy}
                          sortType={paramList.sortOrder} />
                        :
                        <UserSurveyTable
                          tableData={tableData}
                          sortColumnData={sortColumnData}
                          sortedColumn={paramList.sortBy}
                          sortType={paramList.sortOrder} />
                    }
                  </>
                ) :
                (
                  <NoServey
                    message={msgs === "" ? (listLoadCount <= 2 ?
                      NoServeyMessage.NO_SURVEY_ON_ACCOUNT :
                      NoServeyMessage.NO_SURVEY_FOUND) : msgs
                    }
                  />
                )
            )
          }
          {isPagination ? tableData?.results?.length > 0 && (<>
            <ADPagination
              className="rtr-pagination-bar"
              currentPage={currentPage}
              totalCount={tableData.count}
              pageSize={PageSize}
              handlePaginationClick={handlePaginationClick}
              showNoOfRecords={true}
            />
          </>) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export default RealtimeResearch;
