import axios from "axios";

let configFilePath = '/config.json';

if (process.env.NODE_ENV === 'development') {
    configFilePath = '/config.dev.json';
}

export function getConfig(callback) {
    return axios.get(configFilePath)
        .then(res => {
            callback(res);
        })
        .catch(err => {
            console.log('Error reading config.json');
        });
}