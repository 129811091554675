import React, { useEffect, useState } from 'react'
import AnswerFilterPills from './AnswerFilterPills'
import { Button } from 'reactstrap'
import './index.scss'

function AppliedAnswerFilters(props) {

  const [ansFilterData, setAnswerFilterData] = useState([]);

  useEffect(() => {

    let qIds = new Set(props.answer.map(ans => ans.split('_')[0]))
    let filteredData = props.detailsData.filter(dt => [...qIds].includes(dt.qId)).map(fData => {
      return {
        ...fData,
        options: fData.options.filter(op => props.answer.includes(op.id))
      }
    })

    setAnswerFilterData(filteredData)
  }, [props.answer])

  const removeAnswerFilter = (itemId) => {
    props.setAnswer(props.answer.filter(ans => ans !== itemId))
  }

  const goTo = (id) => {
    document.getElementById("card-" + id).scrollIntoView({ behavior: "smooth" });
    document.activeElement.blur();
  }

  return (<div className="rtr-ans-filter-show-container">
    <div>
      <div >
        <div className='d-flex' style={{ marginBottom: '18px' }}  >
          <img src={"/Icon_assets/svg/rtr-filter.svg"} style={{ alignSelf: 'center' }} alt="filter" height={24} width={24} ></img>
          <div style={{
            alignSelf: 'center',
            fontStyle: 'normal',
            margin: '0 23px 0 15px',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '125%'
          }}>Selected Answers</div>
          <button
            type="button"
            style={{ padding: 0 }}
            className=" btn btn-clear"
            onClick={(e) => { props.setAnswer([]) }}>
            <span style={{ color: "#3257FB", fontSize: "16px", textDecoration: 'underline solid #3257FB', cursor: 'pointer' }}>Clear All</span>
          </button>
        </div>
        <div >
          {ansFilterData && ansFilterData?.map((fData, index) =>
            <>
              <div className="ans-pills-container">
                <div className='d-flex' style={{ flexWrap: "wrap" }}>
                  <div className='d-flex'
                    style={{
                      alignSelf: 'center',
                      marginRight: '12px',
                      color: '#3257FB',
                      textDecoration: 'underline solid #3257FB',
                      cursor: 'pointer'
                    }}
                    onClick={() => goTo(fData.qId)}>{fData.qId}</div>
                  <div className='d-flex' style={{ alignSelf: 'center', marginRight: '12px' }}>{fData.question}</div>
                  {fData.options.map((ans) => <AnswerFilterPills data={ans} removeAnswerFilter={removeAnswerFilter}></AnswerFilterPills>)
                  }</div>
                {ansFilterData.length - 1 !== index && <div tabIndex="-1" className="dropdown-divider"></div>}
              </div>
            </>
          )
          }
        </div>
      </div>
    </div>
  </div>
  )
}

export default AppliedAnswerFilters