
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonResearchTable = ({rows=4}) => {
  return (
    <section className="skeleton-research-table-wrapper">
      <div className="skeleton-table">
        {
          Array(rows).fill(null).map((data, index) => {
            return(
              <>
                <section className='row-container'>
                  <SkeletonElement type="avatar" />
                  <SkeletonElement type="title" key={index}/>
                </section>
                <SkeletonElement type="line" key={index}/>
              </>
            )
          })
        }
        <SkeletonElement type="title"/>
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonResearchTable;