import { combineReducers } from "redux";
import configReducer from "./configReducer";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import realtimeResearchReducer from "./realtimeResearchReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import advertiserReducer from "./advertiserReducer";
import organizationReducer from "./organizationReducer";
import reportBuilderReducer from "./reportBuilderReducer";
import campaignsReducer from "./campaignsReducer";
import lineReducer from "./lineReducer";
import adReducer from "./adReducer";
import surveyReducer from "./surveyReducer";
import brandsReducer from "./brandsReducer";
import conversionReducer from "./conversionReducer";
import offerReducer from "./offerReducer";
import scheduleReportReducer from "./sheduleReportReducer";
import filterReducer from "./filterReducer";


export default combineReducers({
  config: configReducer,
  auth: authReducer,
  user: userReducer,
  notification: notificationReducer,
  dashboard: dashboardReducer,
  realtimeResearch: realtimeResearchReducer,
  advertiser: advertiserReducer,
  organization: organizationReducer,
  reportBuilder: reportBuilderReducer,
  campaign: campaignsReducer,
  line: lineReducer,
  ad: adReducer,
  surveyData: surveyReducer,
  brands: brandsReducer,
  conversion: conversionReducer,
  offer: offerReducer,
  scheduleReport: scheduleReportReducer,
  filters: filterReducer
});
