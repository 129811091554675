import { useState, useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);
  useEffect(() => {
    const onClick = e => {
      // If the active element exists and is clicked outside of
      if (!e.target.matches('.dropbutton') && 
      (!e.target.matches('.name-class')
      && (!e.target.matches('.search-input'))
      && (!e.target.matches('.user-image-container')))) {
        setIsActive(!isActive);
        // setPopoverOpen(!popoverOpen);
				var dropdowns =
				document.getElementsByClassName("dropdownmenu-content");
				
        var popOver = document.getElementsByClassName('fade show pop-drop');
				var i;
				for (i = 0; i < dropdowns.length; i++) {
					var openDropdown = dropdowns[i];
					if (openDropdown.classList.contains('show')) {
						openDropdown.classList.remove('show');
					}
				}

        for (var j = 0; j < popOver.length; j++) {
					var openPopOver = popOver[j];
					if (openPopOver.classList.contains('show')) {
						openPopOver.classList.remove('show');
					}
				}
			}
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};