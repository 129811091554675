export default function checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization) {
  if (userData?.organization_role !== 0) {
    let selectedOrganizationUserRbac = userRbac?.filter(allowedPermission => allowedPermission?.organization_id === selectedOrganization?.id)
    let selectedOrganizationAllowedPermissionPoints = selectedOrganizationUserRbac ? selectedOrganizationUserRbac[0]?.role.permissions : [];

    return requiredPermissions?.every((requiredPermission) => {
      return (
        selectedOrganizationAllowedPermissionPoints?.some(selectedOrganizationAllowedPermissionPoint => {
          return (
            selectedOrganizationAllowedPermissionPoint.label === requiredPermission.permission_point
            &&
            requiredPermission?.access?.some(acs => {
              return (selectedOrganizationAllowedPermissionPoint.actions?.some(act => acs === act.action))
            })
          )
        })
      )
    })
  }
  else {
    return true
  }
}