import React, { useState } from 'react';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

const PreviewAction = ({ goToPreview, data, actionIcons }) => {
  return (
    <span className='align-span'
      onClick={(e) => goToPreview(e, data.message, data.brand_v2 ? data.brand_v2.id : "", data.ad_state, data.id, data.name, data.rtr_type, data)}
    >
  <img src={actionIcons.PREVIEW_ICON} className="preview-icon" alt="preview" />
    </span >
  )
}

export default PreviewAction;