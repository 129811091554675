import ACTIONS from "../constants/actionTypes";

export const brandsListRequest = (data) => {
  return {
    type: ACTIONS.BRANDS_LIST_REQUEST,
    payload: data,
  };
};

export const brandsListSuccess = (data) => {
  return {
    type: ACTIONS.BRANDS_LIST_SUCCESS,
    payload: data,
  };
};

export const brandsListFailure = (errors) => {
  return {
    type: ACTIONS.BRANDS_LIST_FAILURE,
    payload: errors,
  };
};

export const brandsListFailureClear = () => {
  return {
    type: ACTIONS.BRANDS_LIST_FAILURE_CLEAR,
  };
};

//Upload logo
export const uploadFile = (data) => {
  return {
    type: ACTIONS.UPLOAD_FILE_REQUEST,
    payload: data,
  };
};

export const uploadFileSuccess = (data) => {
  return {
    type: ACTIONS.UPLOAD_FILE_SUCCESS,
    payload: data,
  };
};

export const uploadFileFailure = (errors) => {
  return {
    type: ACTIONS.UPLOAD_FILE_FAILURE,
    payload: errors,
  };
};

export const uploadFileFailureClear = () => {
  return {
    type: ACTIONS.UPLOAD_FILE_FAILURE_CLEAR,
  };
};

//POST/PUT
export const saveBrandRequest = (data) => {
  return {
    type: ACTIONS.SAVE_BRAND_REQUEST,
    payload: data,
  };
};

export const saveBrandRequestSuccess = (data) => {
  return {
    type: ACTIONS.SAVE_BRAND_REQUEST_SUCCESS,
    payload: data,
  };
};

export const saveBrandRequestFailure = (errors) => {
  return {
    type: ACTIONS.SAVE_BRAND_REQUEST_FAILURE,
    payload: errors,
  };
};

export const saveBrandRequestFailureClear = () => {
  return {
    type: ACTIONS.SAVE_BRAND_REQUEST_FAILURE_CLEAR,
  };
};

export const resetBrandLogo = () => {
  return {
    type: ACTIONS.RESET_BRAND_LOGO,
  };
};


export const getBrandsByIdRequest = (data) => {
  return {
    type: ACTIONS.GET_BRAND_BY_ID_REQUEST,
    payload: data,
  };
};

export const getBrandsByIdSuccess = (data) => {
  return {
    type: ACTIONS.GET_BRAND_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getBrandsByIdFailure = (errors) => {
  return {
    type: ACTIONS.GET_BRAND_BY_ID_FAILURE,
    payload: errors,
  };
};

export const getBrandsByIdFailureClear = () => {
  return {
    type: ACTIONS.GET_BRAND_BY_ID_FAILURE_CLEAR,
  };
};
