import ACTIONS from "../constants/actionTypes";

export const getRealtimeResearchListRequest = (data) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_LIST_REQUEST,
    payload: data,
  };
};

export const getRealtimeResearchListSuccess = (data, msg) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_LIST_SUCCESS,
    payload: data,
    successMsg: msg
  };
};

export const getRealtimeResearchListFailure = (errors) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_LIST_FAILURE,
    payload: errors,
  };
};

export const getRealtimeResearchListFailureClear = () => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_FAILURE_CLEAR,
  };
};

export const rtrListRequest = (data) => {
  return {
    type: ACTIONS.RTR_LIST_REQUEST,
    payload: data
  };
};

export const rtrListSuccess = (data) => {
  return {
    type: ACTIONS.RTR_LIST_SUCCESS,
    payload: data,
  };
};

export const rtrListFailure = (errors) => {
  return {
    type: ACTIONS.RTR_LIST_FAILURE,
    payload: errors,
  };
};

export const rtrListFailureClear = () => {
  return {
    type: ACTIONS.RTR_LIST_FAILURE_CLEAR,
  };
};


export const getRealtimeResearchDetailsRequest = (data) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_REQUEST,
    payload: data,
  };
};


export const getRealtimeResearchDetailsSuccess = (data) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getRealtimeResearchDetailsFailure = (errors) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_FAILURE,
    payload: errors,
  };
};

export const getRealtimeResearchDetailsFailureClear = () => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_CLEAR,
  };
};



export const getRealtimeResearchDetailsStatRequest = (data) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_REQUEST,
    payload: data
  };
};


export const getRealtimeResearchDetailsStatSuccess = (data) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_SUCCESS,
    payload: data,
  };
};

export const getRealtimeResearchDetailsStatFailure = (errors) => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_FAILURE,
    payload: errors,
  };
};

export const getRealtimeResearchDetailsStatFailureClear = () => {
  return {
    type: ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_CLEAR,
  };
};

export const getExportRawEventsRequest = (data) => {
  return {
    type: ACTIONS.GET_EXPORT_RAW_EVENTS_REQUEST,
    payload: data
  };
}

export const getExportRawEventsSuccess = (data) => {
  return {
    type: ACTIONS.GET_EXPORT_RAW_EVENTS_SUCCESS,
    payload: data
  };
}

export const getExportRawEventsFailure = (errors) => {
  return {
    type: ACTIONS.GET_EXPORT_RAW_EVENTS_FAILURE,
    payload: errors,
  };
};

export const getExportRawEventsClear = () => {
  return {
    type: ACTIONS.GET_EXPORT_RAW_EVENTS_CLEAR,
    payload: {}
  };
};

export const uploadCSV = (data) => {
  return {
    type: ACTIONS.UPLOAD_CSV_REQUEST,
    payload: data,
  };
};

export const updateAdStateRequest = (data) => {
  return {
    type: ACTIONS.UPDATE_AD_STATE_REQUEST,
    payload: data
  };
}

export const updateAdStateSuccess = (data) => {
  return {
    type: ACTIONS.UPDATE_AD_STATE_SUCCESS,
    payload: data
  };
}

export const updateAdStateFailure = (errors) => {
  return {
    type: ACTIONS.UPDATE_AD_STATE_FAILURE,
    payload: errors,
  };
};

export const updateAdStateClear = () => {
  return {
    type: ACTIONS.UPDATE_AD_STATE_CLEAR,
    payload: {}
  };
};

export const getUniversalTagRequest = (data) => {
  return {
    type: ACTIONS.GET_UNIVERSAL_TAG_REQUEST,
    payload: data
  };
}

export const getUniversalTagSuccess = (data) => {
  return {
    type: ACTIONS.GET_UNIVERSAL_TAG_SUCCESS,
    payload: data
  };
}

export const getUniversalTagFailure = (errors) => {
  return {
    type: ACTIONS.GET_UNIVERSAL_TAG_FAILURE,
    payload: errors,
  };
};

export const getUniversalTagClear = () => {
  return {
    type: ACTIONS.GET_UNIVERSAL_TAG_CLEAR,
    payload: {}
  };
};

export const cloneRtrAdRequest = (data) => {
  return {
    type: ACTIONS.CLONE_RTR_AD_REQUEST,
    payload: data
  };
}

export const cloneRtrAdSuccess = (data) => {
  return {
    type: ACTIONS.CLONE_RTR_AD_SUCCESS,
    payload: data
  };
}

export const cloneRtrAdFailure = (errors) => {
  return {
    type: ACTIONS.CLONE_RTR_AD_FAILURE,
    payload: errors,
  };
};

export const cloneRtrAdClear = () => {
  return {
    type: ACTIONS.CLONE_RTR_AD_CLEAR,
    payload: {}
  };
};

export const deleteRtrAdRequest = (data) => {
  return {
    type: ACTIONS.DELETE_RTR_AD_REQUEST,
    payload: data
  };
}

export const deleteRtrAdSuccess = (data) => {
  return {
    type: ACTIONS.DELETE_RTR_AD_SUCCESS,
    payload: data
  };
}

export const deleteRtrAdFailure = (errors) => {
  return {
    type: ACTIONS.DELETE_RTR_AD_FAILURE,
    payload: errors,
  };
};

export const deleteRtrAdClear = () => {
  return {
    type: ACTIONS.DELETE_RTR_AD_CLEAR,
    payload: {}
  };
};

export const clearResponseData = () => {
  return {
    type: ACTIONS.CLEAR_RESPONSE_DATA,
    payload: {}
  };
};

export const getBothCampaignsRequest = (data) => {
  return {
    type: ACTIONS.GET_BOTH_CAMPAIGNS_REQUEST,
    payload: data
  };
}

export const getBothCampaignsSuccess = (data) => {
  return {
    type: ACTIONS.GET_BOTH_CAMPAIGNS_SUCCESS,
    payload: data
  };
}

export const getBothCampaignsFailure = (errors) => {
  return {
    type: ACTIONS.GET_BOTH_CAMPAIGNS_FAILURE,
    payload: errors,
  };
};

export const getBothCampaignsClear = () => {
  return {
    type: ACTIONS.GET_BOTH_CAMPAIGNS_CLEAR,
    payload: {}
  };
};

export const setRTRSurveyDataRequest = (data) => {
  return {
    type: ACTIONS.SET_RTR_SURVEY_DATA_REQUEST,
    payload: data
  };
};

export const setRTRSurveyDataSuccess = (data) => {
  return {
    type: ACTIONS.SET_RTR_SURVEY_DATA_SUCCESS,
    payload: data
  };
};

export const setRTRSurveyDataFailure = (errors) => {
  return {
    type: ACTIONS.SET_RTR_SURVEY_DATA_FAILURE,
    payload: errors
  };
};

export const setRTRSurveyDataClear = () => {
  return {
    type: ACTIONS.SET_RTR_SURVEY_DATA_CLEAR,
    payload: {}
  };
};

export const getRTRByAdIdSRequest = (data) => {
  return {
    type: ACTIONS.GET_RTR_BY_AD_ID_REQUEST,
    payload: data
  };
}

export const getRTRByAdIdSSuccess = (data) => {
  return {
    type: ACTIONS.GET_RTR_BY_AD_ID_SUCCESS,
    payload: data
  };
}

export const getRTRByAdIdSFailure = (error) => {
  return {
    type: ACTIONS.GET_RTR_BY_AD_ID_FAILURE,
    payload: error
  };
}

export const getRTRByAdIdSClear = () => {
  return {
    type: ACTIONS.GET_RTR_BY_AD_ID_CLEAR,
    payload: {}
  };
} 
