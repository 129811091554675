import React from 'react'
import './index.scss'

function Pills(props) {
  return (
    <span className='filter-pill'>
      <span style={{ marginRight: '12px' }}>{props.data.label}</span>
      <div className='d-flex' onClick={() => props.data.func(props.data.value)}>
        <img src="/Icon_assets/svg/close_icon.svg" alt="close" height={14} width={14} ></img>
      </div>
    </span>
  )
}

export default Pills;