import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import history from '../../history';
import { decrypt } from "../../utils/crypto";

//All App Route constants
import ROUTES from "../../utils/routeConstants";
import { authActionCreator, organizationActionCreator, userActionCreator } from '../../actions';

//Public Routes
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ChangePassword from "../ChangePassword";
import ForgotPassword from "../ForgotPassword";


//Protected Routes
import Dashboard from "../Dashboard";
import RealtimeResearch from "../RealtimeResearch";
import Users from "../Users";
import Organizations from "../Organizations";
import ProfileSetting from "../ProfileSetting";
import ReportBuilder from "../ReportBuilder";
import PreviewLink from "../PreviewLink";
import Conversion from "../Conversion";
import Offers from "../Offers";
//user roles
import { userRole } from "../../utils/userRoles";
import RTRPreviewLink from "../RTRPreviewLink";
import BrandPreviewLink from "../BrandPreviewLink";
import ScheduledReportList from "../ReportBuilder/ScheduleReportList";
import checkRBAC from '../../utils/checkRBAC'
import { PERMISSIONS } from "../../utils/permissionMatrix";
import Brands from "../Brands";
import RealtimeSurveyResults from "../../components/RealtimeResearch/RealtimeSurveyResults";

function App() {
  /*Check user if the token is available on local storage - if yes then dispatch signInSuccess*/
  const dispatch = useDispatch();

  /** states required for RBAC */
  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const currentUser = useSelector((state) => state.auth);

  const storeRBAC = useSelector((state) => state.auth.rbac);
  const user = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null);
  const token = user ? user?.token : null;
  if (token && !userData.token) { //have data in localStorage but not in Redux store
    dispatch(authActionCreator.signInSuccess(user)) //update the Redux store
  }

  const encryptedRBAC = localStorage.getItem('$ifb_aes') ? localStorage.getItem('$ifb_aes') : undefined;
  if (encryptedRBAC && !storeRBAC.length) { //have data in localStorage but not in Redux store
    const decryptedRBAC = decrypt(encryptedRBAC, token);
    dispatch(authActionCreator.setRBAC(JSON.parse(decryptedRBAC))) //update the Redux store
  }

  /**
   * user: The current loggedIn user
   * roles: Admin:0, user:1
   */

  const ProtectedRoute = ({
    user,
    roles,
    component: Component,
    userRBACRequired,
    path,
    ...rest
  }) => {
    let isAuthorizedUser;
    const localUser = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null);
    if (userRBACRequired && !(localUser?.organization_role === 0)) { //Routes required to check RBAC
      if (!userData.name && !userRbac.length) { //if store has no data
        if (localUser) {
          const encryptedRBAC = localStorage.getItem('$ifb_aes') ? localStorage.getItem('$ifb_aes') : undefined;
          const localDecryptedRBAC = decrypt(encryptedRBAC, token);
          isAuthorizedUser = checkRBAC(localUser, JSON.parse(localDecryptedRBAC), userRBACRequired, selectedOrganization)
        } else {
          isAuthorizedUser = false
        }

      } else {
        isAuthorizedUser = checkRBAC(userData, userRbac, userRBACRequired, selectedOrganization)
      }
    } else { //Routes not required to check RBAC like ORGANIZATIONS_LIST/PROFILE_SETTINGS
      if ((localUser?.organization_role === 0)) { //if a superAdmin full access
        isAuthorizedUser = true
      } else {
        if (path === ROUTES.PROFILE_SETTINGS) { //normal user access profile page wihout RBAC
          isAuthorizedUser = true
        } else {
          isAuthorizedUser = false
        }
      }
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          if ((user?.isLoggedIn || token) && isAuthorizedUser) {
            return <Component />;
          } else {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        }}
      />
    );
  };

  /**
   * Axios interceptors
   * Each responce check token is expire or not
   */
  axios.interceptors.response.use(function (response) {
    // Setting new JWT for each API for new active session
    /*if (response && response.headers && response.headers.authorization) {
      let authToken = response.headers.authorization;
      localStorage.setItem('jwtToken', authToken);
    }*/

    let status = response.data.status;
    if (status && (status.status_code === 1107 || status.status_code === 1108)) {
      const location = window.location;
      const service = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
      alert(JSON.stringify(status.status_message));
      // Override and disable upcoming alerts in the app
      window.alert = function () { };
      //authActionCreator.logOutRequest();
      dispatch(authActionCreator.logOutRequest());
      dispatch(authActionCreator.resetRBAC());
      dispatch(organizationActionCreator.removedOrganization());
      return false;
    } else {
      return response;
    }
  }, function (error) {
    return Promise.reject(error);
  });

  function isAuthorized(requiredPermissions, isSuperAdmin) {
    return checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization)
  }

  useEffect(() => {
    // const encryptedRBAC = localStorage.getItem('$ifb_aes') ? localStorage.getItem('$ifb_aes') : undefined;
    // if (encryptedRBAC && !storeRBAC.length) { //have data in localStorage but not in Redux store
    //   const decryptedRBAC = decrypt(encryptedRBAC, token);
    //   dispatch(authActionCreator.setRBAC(JSON.parse(decryptedRBAC))) //update the Redux store
    // }
    const roles = localStorage.getItem('roles');
    if (roles) {
      dispatch(userActionCreator.getUserRolesListSuccess(JSON.parse(roles)));
    }
  }, [userData])

  return (
    <Router basename="/" history={history}>
      <>
        <div className="main-app">
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path={ROUTES.SIGNIN} component={SignIn} />
            <Route exact path={ROUTES.SIGNUP} component={SignUp} />
            <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
            <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route exact path={ROUTES.PREVIEW} component={PreviewLink} />
            <Route exact path={ROUTES.OFFER_SHARE_PREVIEW} component={RTRPreviewLink} />
            <Route exact path={ROUTES.BRAND_OFFER_SHARE_PREVIEW} component={BrandPreviewLink} />
            <Route exact path={ROUTES.SURVEY_RESULTS} component={RealtimeSurveyResults} />

            <ProtectedRoute
              exact
              path={ROUTES.DASHBOARD}
              component={Dashboard}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.REPORT_BUILDER}
              component={ReportBuilder}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.REALTIME_RESEARCH}
              component={RealtimeResearch}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_RTR_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.REALTIME_RESEARCH_DETAILS}
              component={RealtimeResearch}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_RTR_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CAMPAIGN_PREVIEW}
              component={RealtimeResearch}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_RTR_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.PROFILE_SETTINGS}
              component={ProfileSetting}
              user={currentUser}
            />

            <ProtectedRoute
              exact
              path={ROUTES.USERS_LIST}
              component={Users}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MANAGE_USER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.ADD_USER}
              component={Users}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_USER_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.EDIT_USER}
              component={Users}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_USER_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.ORGANIZATIONS_LIST}
              component={Organizations}
              user={currentUser}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CREATE_ORGANIZATION}
              component={Organizations}
              user={currentUser}
            />

            <ProtectedRoute
              exact
              path={ROUTES.EDIT_ORGANIZATION}
              component={Organizations}
              user={currentUser}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CONVERSION}
              component={Conversion}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CONVERSION_RULE_LIST}
              component={Conversion}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_CONVERSION_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CREATE_CONVERSION_RULE}
              component={Conversion}
              user={currentUser}
              userRBACRequired={PERMISSIONS.BUILD_CONVERSION_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.GET_PIXEL_CODE}
              component={Conversion}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CONVERSION_REPORT_BUILDER}
              component={Conversion}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.OFFERS_LIST}
              component={Offers}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CREATE_OFFERS}
              component={Offers}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.EDIT_OFFERS}
              component={Offers}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.OFFER_PREVIEW}
              component={Offers}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.BRAND_OFFER_PREVIEW}
              component={Offers}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.RTR_OFFER_PREVIEW}
              component={RealtimeResearch}
              user={currentUser}
              userRBACRequired={PERMISSIONS.OFFER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.SCHEDULED_REPORTS}
              component={ScheduledReportList}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.BRANDS_LIST}
              component={Brands}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MANAGE_BRAND_READ}
            />

            <ProtectedRoute
              exact
              path={ROUTES.CREATE_BRAND}
              component={Brands}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MANAGE_BRAND_WRITE}
            />

            <ProtectedRoute
              exact
              path={ROUTES.EDIT_BRAND}
              component={Brands}
              user={currentUser}
              userRBACRequired={PERMISSIONS.MANAGE_BRAND_WRITE}
            />

            <Route path={'*'} component={(props) => { return token ? <Redirect to={ROUTES.DASHBOARD} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} /> }} />
          </Switch>
        </div>
      </>
    </Router>
  );
}

export default App;
