import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, CustomInput } from 'reactstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import SkeletonTable from '../../../../Loader_Skeleton/SkeletonTable'
import './index.scss'

const AGE_RANGE = [
  "13+",
  "18-20",
  "21-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
];

const statusIcons = {
  ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
  ARROW_DOWN: "/Icon_assets/svg/arrow-down-filter.svg"
}

const AgeRange = (props) => {
  const [ageRangeData, setAgeRangeData] = useState(AGE_RANGE)
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([]);
  const [isAgeRangeFilterOpen, setIsAgeRangeFilterOpen] = useState(false)
  const toggleAgeRangeFilter = () => {
    if (isAgeRangeFilterOpen)
      props.setSelectedAgeRange(selectedAgeRanges)
    setIsAgeRangeFilterOpen(prev => !prev)
  }
  const handleDateRangeSelect = (item) => {
    if (selectedAgeRanges.includes(item))
      setSelectedAgeRanges(selectedAgeRanges.filter(val => val !== item))
    else
      setSelectedAgeRanges(prev => [...prev, item])
  }

  const clearFilter = (e) => {
    e.stopPropagation();
    props.setSelectedAgeRange([]);
    setSelectedAgeRanges([])
  }

  useEffect(() => {
    if (props.isDisplayFilterRemoved) {
      setSelectedAgeRanges(props.selectedAgeRange)
    }
  }, [props.selectedAgeRange])

  return (
    <div className='ad-age-range-filter-container'>
      <Dropdown id='age-range-dropdown' className="d-flex" isOpen={isAgeRangeFilterOpen} toggle={toggleAgeRangeFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div style={{ display: 'flex' }}>
              <div className='dt-title'>Age Range</div>
              {selectedAgeRanges?.length > 0 && <span className='filter-count'> {selectedAgeRanges?.length}</span>}
            </div>
            <div>
              {selectedAgeRanges?.length > 0 && <img style={{ display: "inline-block" }}
                onClick={(e) => clearFilter(e)}
                toggle={false}
                src={"/Icon_assets/svg/close-image.svg"}
                className="arrow-down"
                alt='date-range'
              />}
              <img style={{
                display: "inline-block",
                margin: '0 12px',
                transform: isAgeRangeFilterOpen ? 'rotate(180deg)' : ''
              }}
                src={
                  statusIcons.ARROW_DOWN
                }
                className="arrow-down"
                alt='age-filter'
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu id='age-range-dropdown-menu'>

          <div className='age-range-list' id="ageRangeDropDown">
            <InfiniteScroll
              dataLength={ageRangeData?.length}
              next={() => { }}
              hasMore={false}
              loader={<SkeletonTable rows={10} />}
              scrollableTarget="ageRangeDropDown"
            >
              {ageRangeData.map((item, index) => <DropdownItem toggle={false}>
                <div style={{ height: '40px' }}>
                  <CustomInput
                    style={{ alignSelf: 'center' }}
                    type="checkbox"
                    key={'age-range-checkbox-select' + index}
                    id={'age-range-checkbox-select' + index}
                    checked={selectedAgeRanges.includes(item)}
                    onChange={() => handleDateRangeSelect(item)}
                    label={item}
                  />

                </div>
              </DropdownItem>)
              }
            </InfiniteScroll>
          </div>

        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
export default AgeRange