
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonTable = ({rows=4, showLine = true}) => {
  return (
    <section className="skeleton-table-wrapper">
      <div className="skeleton-table">
        {
          Array(rows).fill(null).map((data, index) => {
            return(
              <>
                <SkeletonElement type="title" key={`skeleton-elem-${index}`}/>
                {showLine && (<SkeletonElement type="line" key={`skeleton-e-${index}`}/>)}
              </>
            )
          })
        }
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonTable;