import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import {
  Button, Card,
  CardBody, Label, Input, FormGroup, Table,
  Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { notificationActionCreator } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../Loader';

const ToastUploadModal = ({ closeToastModal, validateFile, inputValues,
  updateToastFile, toastLoading, csvJSON, setToastJson }) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
  }

  const dragLeave = (e) => {
    e.preventDefault();
  }

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }

  const handleFiles = (files) => {
    var f;
    for (let i = 0; i < files.length; i++) {
      if (validateFile('.csv', files[i])) {

        let reader = new FileReader();
        reader.readAsText(files[i]);

        reader.onload = function (e) {
          const fileJson = csvJSON(e.target.result);
          setToastJson(fileJson);
          if (Number(inputValues.ad_size) === 0 && Array.isArray(fileJson) && fileJson.length > 6) {
            dispatch(notificationActionCreator.setNotification({
              message: "Only 6 toats are allowed"
            }));
            return
          }

          if (Number(inputValues.ad_size) !== 0 && Array.isArray(fileJson) && fileJson.length > 5) {
            dispatch(notificationActionCreator.setNotification({
              message: "Only 5 toats are allowed"
            }));
            return
          }
          
          f = new File([reader.result], files[i].name, { type: "text/csv", lastModified: files[i].lastModified })
          let param = {};
          param.organization_id = selectedOrganization?.id || localStorage.getItem('organizationId');
          param.brand_id = inputValues.brand_id;
          param.file = f;
          updateToastFile(param);
        };

        reader.onerror = function () {
          console.log(reader.error);
        };


      } else {
        dispatch(notificationActionCreator.setNotification({
          message: "Unsupported content type of file, use csv file."
        }));
      }
    }

  }


  const handleFileUpload = (e) => {
    const inputField = { ...inputValues };
    var f;
    const fileList = Array.from(e.target.files);
    if (fileList.length === 1) {
      if (e.target.files.length !== 0 &&
        (validateFile('.csv', e.target.files[0]))) {
        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);

        reader.onload = function () {
          const fileJson = csvJSON(reader.result);
          if (Number(inputValues.ad_size) === 0 && Array.isArray(fileJson) && fileJson.length > 6) {
            dispatch(notificationActionCreator.setNotification({
              message: "Only 6 toats are allowed"
            }));
            return
          }

          if (Number(inputValues.ad_size) !== 0 && Array.isArray(fileJson) && fileJson.length > 5) {
            dispatch(notificationActionCreator.setNotification({
              message: "Only 5 toats are allowed"
            }));
            return
          }

          setToastJson(fileJson);
          f = new File([reader.result], e.target.files[0].name, { type: "text/csv", lastModified: e.target.files[0].lastModified })
          let param = {};
          param.organization_id = localStorage.getItem('organizationId');
          param.brand_id = inputValues.brand_id;
          param.file = f;
          updateToastFile(param);
        };

        reader.onerror = function () {
          console.log(reader.error);
          dispatch(notificationActionCreator.setNotification({
            message: reader.error
          }));
        };
      } else {
        dispatch(notificationActionCreator.setNotification({
          message: "Unsupported content type of file, use csv file."
        }));
      }
    } else {
      dispatch(notificationActionCreator.setNotification({
        message: "Please select a single file."
      }));
    }
  }


  return (
    <div id="campaign-section" className="dashboard real-time">
      <section className="title-section dashboard-header-section" style={{ marginBottom: "35px" }}>
        <>
          <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
            <Button className="btn-transparent" onClick={closeToastModal}>
              <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
            </Button>
            <h2 id={"survey-header"}
              className="mb-0 pl-4 secondary-title">
              Upload CSV for Toast</h2>
          </nav>

          <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
            <div className="text-right form-group">
              {/*<Button className="in-btn-sm in-btn-info btn-export"
                style={{ minWidth: '190px', minHeight: '48px' }}>
                <a href={surveyFile} download={"IFB Toast Template Upload.csv"}>Download Template</a></Button>*/}
            </div>
          </nav>

        </>
      </section>
      <div id="upload-div">
        <div className="drop-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}>
          {
            toastLoading ?
              <Loader style={{ padding: '0', paddingTop: '60px', background: 'none' }} />
              :

              <div className="drop-message">
                <div className="upload-icon">
                  <img src={"/Icon_assets/svg/file-icon.svg"} alt="file-icon" />
                  <span className="upload-label"> Drop a File</span>
                  <div className="selection-text">or select from your computer</div>
                  <Input
                    style={{ width: "100%", top: "40%", left: "40%" }}
                    type="file"
                    name="dropFile"
                    id="dropFile"
                    className="custom-file-upload-input"
                    title=""
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <div className="file-info">Requirements: file format — CSV, up to 5MB.</div>
                </div>
              </div>
          }
        </div>
        <h2 className="form-heading">How should my file be formatted?</h2>
        <h3 className="">There can be up to 5 toasts per CSV. Please use the following header naming conventions & specifications.</h3>

        <Table id="upload-info-table" className="roundedCorners"><tbody>
          <tr>
            <th>Column Name</th>
            <th>Description</th>
            <th>Acceptable Inputs</th>
          </tr>
          <tr>
            <td>id</td>
            <td>This is the ID for each toast in your survey.</td>
            <td>Toast1, Toast2, Toast3, Toast4, Toast5</td>
          </tr>
          <tr>
            <td>title</td>
            <td>This is the headline text for your toast.</td>
            <td>Up to 25 characters.</td>
          </tr>
          <tr>
            <td>subtitle</td>
            <td>This is the body text for your toast.</td>
            <td>Up to 90 characters.</td>
          </tr>
          <tr>
            <td>button_copy</td>
            <td>This is the button copy for your toast.</td>
            <td>Up to 20 characters.</td>
          </tr>
          <tr>
            <td>button_url</td>
            <td>This is the URL for the button on your toast.</td>
            <td>URL starting with https:// or http://</td>
          </tr>
          <tr>
            <td>button_pixel</td>
            <td>This is the event pixel for the button on your toast.</td>
            <td>Copy the event pixel exactly.</td>
          </tr>
        </tbody></Table>
      </div>

    </div >
  )
}

export default ToastUploadModal;