import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback
} from "reactstrap";
import { reportBuilderActionCreator, notificationActionCreator } from "../../../actions";
import Validator from "../../../utils/validators";
import ADspinner from "../../Loader/ADspinner";

import "./index.scss";

const TemplateSaveAs = ({ setOpenPopUp, report_template }) => {
  //store data start
  const isSaving = useSelector((state) => state.reportBuilder.isTemplateSaving);
  const isSaved = useSelector((state) => state.reportBuilder.isTemplateSaved);
  //store data end

  const dispatch = useDispatch();

  //validation object
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState("");

  const handleInputChange = (e) => {
    const checkIsValid = updateValidations(e.target.name, e.target.value);
    setIsValid(checkIsValid);
    setName(e.target.value);
  }

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (report_template) {
      report_template.custom_params.name = name;
      dispatch(reportBuilderActionCreator.saveReportTemplateRequest({...report_template, method: "POST", state: "saveas"}))
    }else{
      dispatch(notificationActionCreator.setNotification({ message: "Please fill out all required fields.", position: "fixed" }))
    }
  }

  //onload
  useEffect(() => {
    setstate(validatorsObj);
  }, [])

  useEffect(() => {
    if (!isSaving && isSaved) {
      setTimeout(() => {
        setOpenPopUp(false) //close the modal
        dispatch(reportBuilderActionCreator.saveReportTemplatesFailurClear()) //clear the flags
        dispatch(reportBuilderActionCreator.getReportTemplates()) //update the list of templates
      }, 1000);
    }
  }, [isSaved]);

  return (<>
    <Form className="ad-form template-save-as-modal">
      <Row className="field-row">
        <Col md={12} sm={12} lg={12}>
          <FormGroup className="mb-2-rem mt-3">
            <Input
              type="text"
              name="name"
              id="name"
              required
              className="form-input form-input-sm mt-2 required"
              autoComplete="off"
              value={name}
              onChange={handleInputChange}
              invalid={validators.name.errorMsg !== ""}
            />
            <Label className="floating-label required">Template Name</Label>
            <FormFeedback>{validators.name.errorMsg}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="action-row mt-3">
        <Col md={12} sm={12} lg={12}>
          <Button
            className="in-btn-lg in-btn-info btn-save"
            type="submit"
            disabled={isValid ? false : true}
            onClick={handleSave}
          >
            {isSaving ? <ADspinner size="large" /> : 'Save'}
          </Button>
          <Button
            className="in-btn-link btn btn-link btn-clear btn-cancel"
            onClick={() => setOpenPopUp(false)}
          >
            {'Cancel'}
          </Button>
        </Col>
      </Row>
    </Form>

  </>)
}

export default TemplateSaveAs;

