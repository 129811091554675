import React, { useState, useEffect } from 'react';

const NoServey = ({message}) => {
  return(
    <div 
      className="no-servey-container d-flex justify-content-center align-items-center"
    >
      <div className="image-wrapper">
        <img
          src={"/Icon_assets/svg/no-servey.svg"}
          alt={"No Servey"}
          title={"No Servey"}
          className={"no-servey-img"}
        />
      </div>
      <div className="text-wrapper">
        <p>{message}</p>
      </div>
      
    </div>
  );
}

export default NoServey;