import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Button,
  Table,
  UncontrolledTooltip,
  PaginationItem,
  PaginationLink,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Input,
} from "reactstrap";
import Loader from '../Loader';
import history from "../../history";

import "./index.scss";
import ROUTES from '../../utils/routeConstants';
import ADPagination from '../ADPagination';
import { brandsActionCreator } from "../../actions";
import SkeletonResearchTable from "../Loader_Skeleton/SkeletonResearchTable";
import PopUpModal from "../PopUpModal";
import Brand from "./Brand";
import RBAC from "../RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";

const BrandList = () => {

  const TABLE_COLUMNS = [
    {
      dataField: "name",
      text: "Advertisers",
      className: "cl-brands"
    },
    {
      dataField: "source",
      text: "Number of Users",
      className: "cl-no-of-users"
    },
    {
      dataField: "organization",
      text: "Organization",
      className: "cl-organization"
    },
    {
      dataField: "actions",
      text: "Actions",
      className: "cl-actions"
    },
  ];

  const dispatch = useDispatch();
  const maxDataCount = 10;
  const [searchText, setSearchText] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isPagination, setIsPagination] = useState(true);
  const [seachImage, setSearchImage] = useState("/Icon_assets/svg/search.svg")

  //modal setup
  const [createBrandmodal, setCreateBrandModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isCreate, setIsCreate] = useState(true);

  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const isLoading = useSelector((state) => state.brands.isLoading);
  const brandData = useSelector((state) => state.brands.data);
  const errorMsg = useSelector((state) => state.brands.errorMsg);
  const brandById = useSelector((state) => state.brands.brandById);

  const MAIN_TABLE_COL_MIN_WIDTH = 180;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

  const closeModal = () => {
    setCreateBrandModal(false);
    let params = {
      organization: localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganization ? selectedOrganization.id : "",
      limit: maxDataCount, offset: 0 * maxDataCount, searchKey: searchText
    }
    dispatch(brandsActionCreator.brandsListRequest(params));
  }
  const handleBrandPopup = (e, action, name) => {
    e.preventDefault();
    setCreateBrandModal(true);
    setModalTitle(action === "new" ? "Add New Advertiser" : `Edit ${name}`);
    setIsCreate(action === "new" ? true : false);
    dispatch(brandsActionCreator.resetBrandLogo());
    dispatch(brandsActionCreator.getBrandsByIdFailureClear());
  }

  const fetchBrandData = (params) => {
    if (params?.search) {
      setSearchText(params?.search)
      setCurrentPage(params.pageNumber)
      dispatch(brandsActionCreator.brandsListRequest({
        organization: localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganization ? selectedOrganization.id : "",
        limit: maxDataCount, offset: (params?.pageNumber - 1) * maxDataCount, searchKey: searchText
      }));
    }
    else {
      setCurrentPage(params?.pageNumber)
      dispatch(brandsActionCreator.brandsListRequest({
        organization: localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganization ? selectedOrganization.id : "",
        limit: maxDataCount, offset: (params?.pageNumber - 1) * maxDataCount, searchKey: searchText
      }));
    }
  }

  const handleInputText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    debounceCall(e)
  }

  /**handle debounce functionality start**/
  const handleSearch = (e) => {

    dispatch(brandsActionCreator.brandsListRequest({
      organization: localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganization ? selectedOrganization.id : "",
      searchKey: e.target.value, limit: maxDataCount, offset: (currentPage * maxDataCount)
    })) //search in the list
  }

  const debounce = (func, wait = 300) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debounceCall = useCallback(debounce(handleSearch), [])
  /**handle debounce functionality end**/


  useEffect(() => {
    dispatch(brandsActionCreator.brandsListRequest({
      organization: localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganization ? selectedOrganization.id : "",
      limit: maxDataCount, offset: 0 * maxDataCount, searchKey: searchText
    }));
    window.scrollTo(0, 0);
  }, []);

  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("brands-table");

    if (table) {
      var tableHeight = table.offsetHeight;
      // For resizing column, add dummy div to header, add event when clicked
      Array.prototype.forEach.call(
        table.querySelectorAll(".resized-column"),
        function (th) {
          //th.style.position = 'relative';

          var grip = document.createElement('div');
          grip.className = 'column-resizer';
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = '5px';
          grip.style.height = tableHeight + 'px';
          grip.style.position = 'absolute';
          grip.style.zIndex = "1";
          grip.style.cursor = 'col-resize';
          grip.addEventListener('mousedown', function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });

          th.appendChild(grip);
        });

      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            thElm.style.width = startOffset + e.pageX + 'px';
            thElm.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  }, [brandData])
  /* Resize column setup for table-main end*/


  return (<>
    <section className="title-section">
      <h2>Advertisers</h2>
      <RBAC requiredPermissions={PERMISSIONS.MANAGE_BRAND_WRITE} >
        <Button
          className="in-btn-sm in-btn-primary add-new-brands-btn"
          onClick={(e) => handleBrandPopup(e, "new")}
        >
          Add New Advertiser
        </Button>
      </RBAC>
    </section>
    <Card className="data-card brands-list-section">
      <CardBody>
        <section className="search-container">
          <FormGroup>
            <InputGroup className="search-group">
              <InputGroupAddon addonType="prepend">
                <img
                  src={seachImage}
                  alt="Search"
                  className="search-image"
                />
              </InputGroupAddon>
              <Input
                type="text"
                name="search"
                placeholder="Search..."
                onChange={(event) => handleInputText(event)}
                autoComplete="off"
                onFocus={() => { setSearchImage("/Icon_assets/svg/search-active.svg") }}
                onBlur={() => { setSearchImage("/Icon_assets/svg/search.svg") }}
              />
            </InputGroup>
          </FormGroup>
        </section>

        {isLoading ? (
          <SkeletonResearchTable rows={10} />
        ) : (
          brandData && brandData.results && brandData.results.length ?
            (
              <>
                <div style={{ width: "auto", overflowX: "scroll", whiteSpace: "nowrap" }}>
                  <Table className="table brands-details-tbl" id="brands-table">
                    <thead>
                      <tr>
                        {TABLE_COLUMNS.map((d, i) => {
                          if (d.text === "Advertisers" || d.text === "Organization") {
                            return <th key={i} className={d.className + " resized-column"} >
                              <div className="resizer-icon">
                                <span className="first"></span>
                                <span className="second"></span>
                              </div>
                              {d.text}
                            </th>;

                          }

                          if (d.text === "Actions") {
                            return (<RBAC requiredPermissions={PERMISSIONS.MANAGE_BRAND_WRITE} >
                              <th key={i} className={d.className}>{d.text}</th>
                            </RBAC>);
                          }
                          return <th key={i} className={d.className}>{d.text}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (brandData.results.map((data, index) => {
                          return (
                            <tr key={data.id}>
                              <td className="brands-column">
                                <span className="brands-name" title={data.name}>{data.name}</span>
                              </td>
                              <td className="no-of-users-column">
                                <span className="brands-no-of-users">
                                  {data?.organization ? data.organization.map(count => count.user_count).reduce((partialSum, a) => partialSum + a, 0) : 0}</span>
                              </td>

                              {data?.organization?.length > 1 ?
                                <>
                                  <td className="brands-column" style={{ cursor: "pointer" }}>
                                    <span id={'opentooltip-' + data.id}
                                      key={'span-' + data.id + '-' + index}>{'Multiple Organizations'}</span>
                                  </td>
                                  <UncontrolledTooltip
                                    placement="top"
                                    className="ad-tool-tip brands-tip"
                                    target={'opentooltip-' + data.id}
                                    key={'opentooltip-' + data.id}
                                  >  {data?.organization.map((i) => <div className="text-left p-1"> {i.name} </div>)} </UncontrolledTooltip > </>
                                :
                                <td className="brands-column">
                                  <span className="brands-name">{data?.organization?.[0]?.name}</span>
                                </td>}

                              <RBAC requiredPermissions={PERMISSIONS.MANAGE_BRAND_WRITE} >
                                <td className="edit-column d-flex justify-content-end" style={{ width: "auto", borderBottom: "none" }}>
                                  <span
                                    className="edit-icon" style={{ paddingRight: "10px", cursor: "pointer" }}
                                    onClick={(e) => {
                                      handleBrandPopup(e, "edit", data.name)
                                      dispatch(brandsActionCreator.getBrandsByIdRequest({ id: data.id }))
                                    }}>
                                    <img
                                      src="/Icon_assets/svg/advertiser-edit-icon.svg"
                                      alt="edit"
                                    />
                                  </span>
                                </td>
                              </RBAC>
                            </tr>
                          )
                        }))
                      }
                    </tbody>
                  </Table>
                </div>
                <ADPagination
                  className="rtr-pagination-bar"
                  currentPage={currentPage}
                  totalCount={brandData?.count}
                  pageSize={maxDataCount}
                  handlePaginationClick={(pageNumber) => fetchBrandData({ pageNumber })}
                  showNoOfRecords={true}
                />
              </>
            ) :
            <p className="no-wallet-data-text">No Advertiser data available</p>
        )
        }
      </CardBody>
    </Card>
    {createBrandmodal &&
      <PopUpModal
        className={'add-update-brand-modal'}
        title={modalTitle}
        openPopUp={createBrandmodal}
        setOpenPopUp={setCreateBrandModal}
      >
        <Brand
          brandData={brandById}
          type={isCreate ? "save" : "update"}
          setOpenPopUp={setCreateBrandModal}
          closeModal={closeModal}
        />
      </PopUpModal>
    }
  </>)
}

export default BrandList;