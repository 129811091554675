import { takeLatest, call, select, put } from "redux-saga/effects";
import { advertiserActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendAdvertiserListRequest(API_URL_PARAM, jwtToken, payload) {
    //const API_URL = API_URL_PARAM + "/v1/advertisers?source=1&limit=100";
    let API_URL = "";
    let encodeString = "";
    const param = {};
    param.source = 1;
    param.limit = 100;

    if (payload?.searchKey) {
      encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
      param.search = encodeString;
    }

    if (payload?.organization) {
      param.organization = payload.organization;
    }

    if (payload?.limit) {
      param.limit = 10;
      param.offset = payload.offset;
    }

    if (payload?.name) {
      param.search = payload.name
    }

    API_URL = API_URL_PARAM + "/v1/advertisers";

    return axios({
      url: API_URL,
      method: "GET",
      params: param,
      headers: {
        Authorization: jwtToken,
      },
      withCredentials: true,
    }).catch((err) => {
      console.log(err);
    });
  }
  
  function* sendAdvertiserListRequestSaga(action) {
    try {
      const API_URL = yield select((state) => state.config.API_URL);
      const userData = yield select((state) => state.auth.userData);
      let jwtToken = userData && userData.token ? userData.token : null;
      const result = yield call(
        sendAdvertiserListRequest,
        API_URL,
        jwtToken,
        action.payload
      );
      const { item, status } = result.data;
      if (status.status_type.toUpperCase() === "SUCCESS") {
        /*const addList = item.results;
        const newAdList = addList.map((d, i) => {
            return {
                selected: false,
                id: d.id,
                name: d.name
            }
        })*/
        yield put(advertiserActionCreator.advertisersListSuccess(item));
      } else {
        const errorMsgList =
          status && status.status_message ? status.status_message : null;
        let errorMsg = "";
        if (typeof errorMsgList === "object") {
          errorMsg = Object.entries(errorMsgList).map((err) => {
            if (err instanceof Object)
              return `${err[0]}: ${JSON.stringify(err[1])}`;
            else return `${err[0]}: ${err[1]}`;
          });
          yield put(advertiserActionCreator.advertisersListFailure(errorMsg.join()));
        } else {
          errorMsg =
            status && status.status_message ? status.status_message : null;
          yield put(advertiserActionCreator.advertisersListFailure(errorMsg));
        }
      }
    } catch (error) {
      yield put(advertiserActionCreator.advertisersListFailure("Server Error"));
    }
  }
  
  export default function* advertiserSagas() {
    yield takeLatest(ACTIONS.ADVERTISERS_LIST_REQUEST, sendAdvertiserListRequestSaga);
  }