import ACTIONS from '../constants/actionTypes';

export const getOfferListRequest = (data) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_REQUEST,
    payload: data,
  };
};

export const getOfferListSuccess = (data) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_SUCESS,
    payload: data,
  };
};

export const getOfferListFailure = (errors) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_FAILURE,
    payload: errors,
  };
};

export const getOfferListFailureClear = () => {
  return {
    type: ACTIONS.GET_OFFER_LIST_FAILURE_CLEAR,
  };
};

export const createBrandOfferFailure = () => {
  return {
    type: ACTIONS.POST_OFFER_FAILURE,
  };
}


export const createBrandOfferRequest = (data) => {
  return {
    type: ACTIONS.POST_OFFER_CREATION_REQUEST,
    payload: data,
  };
}


export const createBrandOfferSuccess = (data) => {
  return {
    type: ACTIONS.POST_OFFER_SUCESS,
    payload: data,
  };
}
export const createBrandOfferFailureClear = () => {
  return {
    type: ACTIONS.POST_OFFER_FAILURE_CLEAR,
    payload: ""
  }
}

  export const deleteOfferRequest = (data) => {
    return {
      type: ACTIONS.DELETE_OFFER_REQUEST,
      payload: data
    };
  }

  export const deleteOfferSuccess = (data) => {
    return {
      type: ACTIONS.DELETE_OFFER_SUCCESS,
      payload: data
    };
  }

  export const deleteOfferFailure = (errors) => {
    return {
      type: ACTIONS.DELETE_OFFER_FAILURE,
      payload: errors,
    };
  };

  export const deleteOfferClear = () => {
    return {
      type: ACTIONS.DELETE_OFFER_CLEAR,
      payload: {}
    };
  };

export const cloneOfferRequest = (data) => {
  return {
    type: ACTIONS.CLONE_OFFER_REQUEST,
    payload: data
  };
}

export const cloneOfferSuccess = (data) => {
  return {
    type: ACTIONS.CLONE_OFFER_SUCCESS,
    payload: data
  };
}

export const cloneOfferFailure = (errors) => {
  return {
    type: ACTIONS.CLONE_OFFER_FAILURE,
    payload: errors,
  };
};

export const cloneOfferClear = () => {
  return {
    type: ACTIONS.CLONE_OFFER_CLEAR,
    payload: {}
  };
};

export const uploadMediaFailure = (errors) => {
  return {
    type: ACTIONS.GET_UPLOAD_MEDIA_FAILURE,
    payload: errors
  }
}

export const uploadMediaSuccess = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_MEDIA_REQUEST_SUCCESS,
    payload: data
  }
}

export const uploadMediaRequest = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_MEDIA_REQUEST,
    payload: data
  }
}

// fire clear events post assignment to state
export const uploadMediaRequestClear = () => {
  return {
    type: ACTIONS.GET_UPLOAD_MEDIA_REQUEST_CLEAR,
    payload: "",
  }
}

export const getOfferListByIdRequest = (data) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_BY_ID_REQUEST,
    payload: data,
  };
};

export const getOfferListIdSuccess = (data) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getOfferListByIdFailure = (errors) => {
  return {
    type: ACTIONS.GET_OFFER_LIST_BY_ID_FAILURE,
    payload: errors,
  };
};
