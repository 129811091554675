import React from 'react';
import './index.scss';
import { Button, Collapse, Label, Row, FormGroup, Input, Col, CustomInput, FormFeedback } from "reactstrap";

const BuilderToast = (props) => {

  const {
    handleInputChange,
    deleteSelectedToast,
    copySelectedToast,
    dataLength,
    adSize,
    showDeleteModal
  } = props;

  const ICONS = {
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    COPY: "/Icon_assets/svg/copy-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",

  };

  return (
    <section className={(props.adState !== 0 ? 'disable-builder' : '') + " builder-section"}>
      <div className="builder-content">
        <div className="builder-header">
          <span className="lbl-question-header">{`Toast ${props.data.id.replace(/[^\d.]/g, '')}`}</span>
          <div className={(props.dataLength === 1 ? 'disable-icon' : '') + " img-container"}
            style={{ marginLeft: "auto" }}
            onClick={(e) => showDeleteModal('toast', props.data)}>
            <img src={ICONS.DELETE} alt="delete question" style={{ width: "18px", height: "20px" }} />
          </div>
          <div 
            className={`img-container copy-question ${adSize === 0 ? (dataLength >= 6  ? 'disable-builder' : '') : (dataLength >= 5  ? 'disable-builder' : '')}`}
            onClick={(e) => copySelectedToast(e, props.data)}
          >
            <img src={ICONS.COPY} alt="copy question" />
          </div>
        </div>

        <div className="builder-data builder-toast-data">
          <Row>
            <Col >
              <Label className="lbl-question">Title</Label>
              <FormGroup >
                <Input
                  type="text"
                  name="title"
                  placeholder='Enter title here'
                  id="title"
                  required
                  className="form-input form-input-sm mt-2 input-question required"
                  autoComplete="off"
                  value={props.data.title || ''}
                  onChange={(e) => handleInputChange(e, props.data)}
                />
              </FormGroup>
              {props.data.title === '' && <FormFeedback>Title can not be empty.</FormFeedback>}
            </Col>
          </Row>

          <Row>
            <Col >
              <Label className="lbl-question">Subtitle <span className='optional-txt'>(Optional)</span></Label>
              <FormGroup >
                <Input
                  type="text"
                  name="subtitle"
                  placeholder='Enter subtitle here'
                  id="subtitle"
                  required
                  className="form-input form-input-sm mt-2 input-question required"
                  autoComplete="off"
                  // style={{ marginBottom: "44px" }}
                  value={props.data.subtitle || ''}
                  onChange={(e) => handleInputChange(e, props.data)}
                />
              </FormGroup>
            </Col>
          </Row>

          {props.adSize === 0 &&
            <Row style={{ marginBottom: "0" }}>
              <Col >
                <Label className="lbl-question redirect-survey">Redirect to Survey</Label>
                {/* {props.toggleEnabled.toggle_survey && props.toggleEnabled.id === props.data.id} */}
                <label className={((props.toggleEnabled?.toggle_survey && props.toggleEnabled?.id !== props.data.id) ? 'disable-switch' : '') + ' switch'}>
                  <input
                    name={"toggle_survey"}
                    type="checkbox"
                    checked={props.data.toggle_survey || ''}
                    onChange={(e) => { handleInputChange(e, props.data, 'toggle_survey', !props.data.toggle_survey) }}
                  />
                  <span className="slider round" style={{ marginLeft: "16px" }}></span>
                </label>

              </Col>
            </Row>
          }
          <Row>
            <Col >
              <Label className="lbl-question">CTA Label</Label>
              <FormGroup >
                <Input
                  placeholder='Enter label for action'
                  type="text"
                  name="button_copy"
                  id="button_copy"
                  required
                  className={((props.toggleEnabled?.toggle_survey && props.toggleEnabled?.id === props.data.id) ? "disable-cta" : " ") + " form-input form-input-sm mt-2 input-question required"}
                  autoComplete="off"
                  value={props.data.button_copy || ''}
                  onChange={(e) => handleInputChange(e, props.data)}
                  readOnly={(props.toggleEnabled?.toggle_survey && props.toggleEnabled?.id === props.data.id)}
                />
              </FormGroup>
              {props.data.button_copy === '' && <FormFeedback>CTA Label can not be empty.</FormFeedback>}
            </Col>
          </Row>

          <Row>
            <Col >
              <Label className="lbl-question">CTA URL</Label>
              <FormGroup >
                <Input
                  placeholder='Enter URL for action'
                  type="text"
                  name="button_url"
                  id="button_url"
                  required
                  className={((props.toggleEnabled?.toggle_survey && props.toggleEnabled?.id === props.data.id) ? "disable-cta" : " ") + " form-input form-input-sm mt-2 input-question required"}
                  autoComplete="off"
                  value={props.data.button_url || ''}
                  onChange={(e) => handleInputChange(e, props.data)}
                  readOnly={(props.toggleEnabled?.toggle_survey && props.toggleEnabled?.id === props.data.id)}
                />
              </FormGroup>
              {props.data.button_url === '' && <FormFeedback>CTA URL can not be empty.</FormFeedback>}
            </Col>
          </Row>

          <Row>
            <Col >
              <Label className="lbl-question">CTA Pixel <span className='optional-txt'>(Optional)</span></Label>
              <FormGroup >
                <Input
                  type="textarea"
                  name="button_pixel"
                  id="bitton_pixel"
                  required
                  className="form-input form-input-sm mt-2 input-pixel-code required"
                  autoComplete="off"
                  value={props.data.button_pixel || ''}
                  onChange={(e) => handleInputChange(e, props.data)}
                />
              </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    </section>);
}

export default BuilderToast;