import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    InputGroup,
    InputGroupAddon,
    FormGroup,
    Input,
} from "reactstrap";
import SkeletonResearchTable from "../../Loader_Skeleton/SkeletonResearchTable";
import history from "../../../history";

import "./index.scss";
import ROUTES from '../../../utils/routeConstants';
import OrganizationDropdown from "./OrganizationDropdown";
import { organizationActionCreator } from "../../../actions";
import ADPagination from '../../ADPagination';

const OrganizationList = () => {

    const maxDataCount = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [isPagination, setIsPagination] = useState(true);
    const [search, setSearch] = useState();
    const [seachImage, setSearchImage] = useState("/Icon_assets/svg/search.svg")

    /*** Redux init ***/
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.organization.isLoading);
    const organizationData = useSelector((state) => state.organization.organizationData);
    const errorMsg = useSelector((state) => state.organization.errorMsg);
    /*** Redux end ***/

    useEffect(() => {
        dispatch(organizationActionCreator.getOrganizationListRequest({ limit: maxDataCount, offset: 0 * maxDataCount }));
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(organizationActionCreator.getOrganizationListFailureClear());
            }, 3000);
        }
    }, [errorMsg]);

    const TABLE_COLUMNS = [
        {
            dataField: "name",
            text: "Organizations",
            className: "cl-organizations"
        },
        {
            dataField: "source",
            text: "Number of Users",
            className: "cl-no-of-users"
        },
        {
            dataField: "actions",
            text: "Actions",
            className: "cl-actions"
        },
    ];

    const goToCreateOrganization = (e) => {
        e.preventDefault();
        history.push(ROUTES.CREATE_ORGANIZATION);
    }

    const fetchOrganizationData = (params) => {
        if (params?.search) {
            setSearch(params?.search)
            setCurrentPage(params?.pageNumber)
            dispatch(organizationActionCreator.getOrganizationListRequest({ limit: maxDataCount, offset: (params?.pageNumber - 1) * maxDataCount, search: params.search }));
        }
        else {
            setCurrentPage(params?.pageNumber)
            dispatch(organizationActionCreator.getOrganizationListRequest({ limit: maxDataCount, offset: (params?.pageNumber - 1) * maxDataCount }));
        }
    }

    const goToEdit = (e, id) => {
        e.preventDefault();
        history.push(`${ROUTES.ORGANIZATIONS_LIST}/edit/${id}`);
    }

    return (
        <>
            <section className="title-section">
                <h2>Organizations</h2>
                <Button
                    className="in-btn-sm in-btn-primary add-new-organization-btn"
                    onClick={goToCreateOrganization}
                >
                    Add New Organization
                </Button>
            </section>
            <Card className="data-card organization-list-section">
                <CardBody>
                    <section className="search-container">
                        <FormGroup>
                            <InputGroup className="search-group">
                                <InputGroupAddon addonType="prepend">
                                    <img
                                        src={seachImage}
                                        alt="Search"
                                        className="search-image"
                                    />
                                </InputGroupAddon>
                                <Input
                                    type="text"
                                    name="search"
                                    placeholder="Search..."
                                    onChange={(event) => fetchOrganizationData({ pageNumber: 1, search: event.target.value })}
                                    autoComplete="off"
                                    onFocus={() => { setSearchImage("/Icon_assets/svg/search-active.svg") }}
                                    onBlur={() => { setSearchImage("/Icon_assets/svg/search.svg") }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </section>

                    {isLoading ? (
                          <SkeletonResearchTable rows={10} />
                    ) : (
                        organizationData && organizationData.results && organizationData.results.length ?
                            (
                                <>

                                    <Table className="table organization-details-tbl">
                                        <thead>
                                            <tr>
                                                {TABLE_COLUMNS.map((d, i) => {
                                                    return <th key={i} className={d.className}>{d.text}</th>;
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (organizationData.results.map((data, index) => {
                                                    return (
                                                        <tr key={data.id}>
                                                            <td className="organization-column">
                                                                <span className="organization-name" title={data.name}>{data.name}</span>
                                                            </td>
                                                            <td className="no-of-users-column">
                                                                <span className="organization-no-of-users">{data.user_count ? data.user_count : 0}</span>
                                                            </td>
                                                            <td className="status-column">
                                                                <img onClick={(e) => { goToEdit(e, data.id) }}
                                                                    src="/Icon_assets/svg/advertiser-edit-icon.svg"
                                                                    alt="settings"
                                                                    className="dropdown-image"
                                                                /> 
                                                                {/* <OrganizationDropdown id={data.id} /> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                }))
                                            }
                                        </tbody>
                                    </Table>
                                    {isPagination ?
                                        <ADPagination
                                            className="rtr-pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={organizationData?.count}
                                            pageSize={maxDataCount}
                                            handlePaginationClick={(pageNumber) => fetchOrganizationData({ pageNumber })}
                                            showNoOfRecords={true}
                                        />
                                        : null}
                                </>
                            ) :
                            <p className="no-wallet-data-text">No Organization data available</p>
                    )
                    }
                </CardBody>
            </Card>
        </>
    )
}

export default OrganizationList;