const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

  return text => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
}
  
const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return encoded => encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
}

const encrypt = (message, slatKey) => {
  slatKey = slatKey.substring(1, 10).replaceAll(/\s/g,'');
  const myCipher = cipher(slatKey)
  const encryptedMessage = myCipher(message);
  return encryptedMessage;
}

const decrypt = (encryptedMessage, slatKey) => {
  slatKey = slatKey.substring(1, 10).replaceAll(/\s/g,'');
  const myDecipher = decipher(slatKey)
  const decryptedMessage = myDecipher(encryptedMessage);
  return decryptedMessage;
}

export { encrypt, decrypt};