import React, { useEffect, useState } from 'react'
import "./index.scss"
import { getVideoThumbnail } from "../../../../utils/VideoStreamingHelper";

export default function StreamThumbNail({ bgSrc, className, children }) {
  const [bgUrl, setBgUrl] = useState(null);

  useEffect(() => {
    if (bgSrc)
      getVideoThumbnail(bgSrc).then(url => { setBgUrl(url) });
  }, []);

  return (<>
    <div
      className={className}
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      {children}
    </div>
  </>)
}