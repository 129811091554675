import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  format,
  startOfQuarter,
  startOfWeek,
  subDays,
  endOfQuarter,
  endOfWeek,
  subWeeks
} from "date-fns";

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  startOYear: startOfYear(addYears(new Date(), 0)),
  endOflastYear: startOfDay(addDays(new Date(), -1)),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
  startOfWeek: startOfWeek(new Date(), {weekStartsOn: 1}),
  lastFourteenDays: startOfDay(addDays(new Date(), -14)),
  lastThirtyDays: startOfDay(addDays(new Date(), -30)),
  startPreviousQuarter:startOfQuarter(startOfQuarter(new Date())-1),
  endPreviousQuarter: endOfQuarter(startOfQuarter(new Date())-1),
  startOfLastWeek: subWeeks(startOfWeek(new Date()), 1),
  endOfLastWeek :  endOfWeek(subWeeks(startOfWeek(new Date()), 1))
};

const dateRanges = [
  {
    label: "Custom",
    date_range: 3,
    startDate: defineds.startOfMonth,
    endDate: defineds.startOfMonth,
  },
  {
    label: 'Today',
    date_range: 4,
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday
  },
  {
    label: "Yesterday",
    date_range: 5,
    startDate: defineds.startOfYesterday,
    endDate: defineds.endOfYesterday,
  },
  {
    label: "This Week",
    date_range: 6,
    startDate: defineds.startOfWeek,
    endDate: defineds.endOfToday,

  },
  {
    label: "Last 7 Days",
    date_range: 0,

    startDate: defineds.endOfLastSevenDays,
    endDate: defineds.endOfToday,

  },
  {
    label: "Last Week",
    date_range: 7,
    startDate: defineds.startOfLastWeek,
    endDate: defineds.endOfLastWeek,
  },
  {
    label: "Last 14 Days",
    date_range: 8,
    startDate: defineds.lastFourteenDays,
    endDate: defineds.endOfToday,

  },
  {
    label: "Last 30 Days",
    date_range: 1,
    startDate: defineds.lastThirtyDays,
    endDate: defineds.endOfToday,

  },
  {
    label: "Month to Date",
    date_range: 9,
    startDate: defineds.startOfMonth,
    endDate: defineds.endOfToday,
  },
  {
    label: "Last Month",
    date_range: 10,
    startDate: defineds.startOfLastMonth,
    endDate: defineds.endOfLastMonth,
  },
  {
    label: "Quarter to Date",
    date_range: 11,
    startDate: defineds.startOfQuarter,
    endDate: defineds.endOfToday,
  },
  {
    label: "Last Quarter",
    date_range: 12,
    startDate: defineds.startPreviousQuarter,
    endDate: defineds.endPreviousQuarter,
  },
  {
    label: "Year to Date",
    date_range: 13,
    startDate: defineds.startOYear,
    endDate: defineds.endOfToday,
  },
]

const getDateRangeById = (dateRangeId = 0) => { //default Last 7 Days
  return dateRanges.find(ele => ele.date_range === dateRangeId)
}

export {
  getDateRangeById
}