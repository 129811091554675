
import React from 'react'
import Shimmer from './Shimmer'

const SkeletonGraph = () => {
  return (
    <section className='skeleton-graph-wrapper'>
      <div className="skeleton-graph">
        <img src="/Icon_assets/svg/graph-skeleton.svg" alt="skeleton"/>
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonGraph;