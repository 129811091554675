import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const CloneRtrAd = ({setOpenCloneAdModal,currentAD,cloneRtrAdRequest}) => {
  return (
    <section className="clone-rtr-ad-content">
      <nav className="clone-rtr-ad-section">
        <p>Are you sure you want to duplicate <span>{'"'+currentAD.name+'"'}?</span></p>
      </nav>
      <nav className="btn-wrapper">
        <Button className="in-btn-sm in-btn-primary" onClick={() => cloneRtrAdRequest(currentAD.campaign.id)}>
          Clone
        </Button>
        <Button color="link" className="in-btn-link" onClick={() => setOpenCloneAdModal(false)}>
          Cancel
        </Button>
      </nav>
    </section>
  )
}

export default CloneRtrAd;