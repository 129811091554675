import React, { useEffect, useState } from 'react';
import {
  Button, Label, Row, FormGroup, Input, Col, CustomInput, FormFeedback,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import './index.scss'
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from '../../Loader_Skeleton/SkeletonTable';
import { useSelector } from 'react-redux';
import TaxonomyAnswerList from './TaxonomyList/TaxonomyAnswerList';

const BuilderAnswer = (props) => {
  const {
    data,
    handleInputChange,
    deleteSelectedAns,
    provided,
    showDeleteModal,
    lastQuestion,
    invisiblyType,
    questionChoice,
    resetAnswerBranching,
    getTaxonomySelection,
    taxonomyAnswerListOption,
    rtrType,
    filterAnserListData,
    clearTaxonomySelection
  } = props;

  const userData = useSelector((state) => state.auth.userData);

  const Icons = {
    DRAG: "/Icon_assets/svg/Drag_enabled.svg",
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",
    ARROW_UP: "/Icon_assets/svg/toggle-survey-ans.svg",
    ANS_BR: "/Icon_assets/svg/rtr-ans-branch.svg",
  }

  const [displayPixel, setDisplayPixel] = useState(false);
  const [toggleAns, setToggleAns] = useState(false);
  const [isAnswerBranchFilterOpen, setIsAnswerBranchFilterOpen] = useState(false);

  const toggleAnsBranch = () => setIsAnswerBranchFilterOpen(prev => !prev)
  const [nextQuesDropDownData, setNextQuesDropDownData] = useState(props.nextQuestionDropdownData);
  const [searchText, setSearchText] = useState('');

  const toggleAnsOption = () => {
    setToggleAns(prevState => !prevState);
  }

  useEffect(() => {
    if (props.answerPixel !== "") {
      setDisplayPixel(true);
    }
  }, [props.answerPixel])

  useEffect(() => {
    if (questionChoice === 'MS') {
      resetAnswerBranching(data.ID);
    }
  }, [questionChoice])

  const searchQuesDropdown = (e) => {
    setSearchText(e.target.value)
    setNextQuesDropDownData(props.nextQuestionDropdownData.filter(item => item.mainLabel.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())))
  }

  return (
    <div key={props.ansId} id={props.ansId}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
      <div className="builder-answer" key={props.ansId} id={`answer-${props.ansId}`}>
        <div>
          <div className='answer-content' key={`content-A${props.index + 1}`} id={`content-A${props.index + 1}`}>
            <div className="img-container drag-container">
              <img src={Icons.DRAG} alt={"drag answer"} />
            </div>
            <div className="answer-number">
              {`A${props.index + 1}`}
            </div>
            <Input
              placeholder='Enter your answer here'
              type="text"
              name={props.ansName}
              id={`ans-${props.ansId}`}
              key={`A${props.index + 1}`}
              required
              className="form-input form-input-sm mt-2 input-question required"
              autoComplete="off"
              value={props.answer || ''}
              onChange={(e) => handleInputChange(e, props.data, 'answer')}
            />
            <div className={`img-container ${props.disableDelete ? 'rtr-disable ' : ''} `}
              id={`delete-${props.ansName}`}
              style={{ marginLeft: "auto", marginRight: "16px" }} onClick={(e) => showDeleteModal('answer', props.data, props.index)}>
              <img src={Icons.DELETE} alt="delete question" style={{ width: "18px", height: "20px" }} key={`delete-A${props.index + 1}`} />
            </div>
            <div className="img-container" style={{ marginLeft: "auto", marginRight: "16px", padding: "17px" }} onClick={toggleAnsOption}>
              <img src={toggleAns ? Icons.ARROW_UP : Icons.ARROW_DOWN} alt="arrow question" style={{ width: "8px", height: "14px" }} />
            </div>
          </div>
          {props.answer === '' && <FormFeedback style={{ marginLeft: '100px' }}>Answer can not be empty.</FormFeedback>}
          {props.asset.template_type?.id === 9 && props.ansName === 'answer2' && (props.answer?.length + props.prevAnswer?.length) > 28 && <FormFeedback style={{ marginLeft: '100px' }}>Combined length of both the answers can have maximum of 28 characters.</FormFeedback>}
        </div>
        {toggleAns &&
          <div className="toggle-ans-div">
            <div className='d-flex'>
              <Dropdown id='next-br-dropdown' className={`d-flex ${((lastQuestion && invisiblyType) || questionChoice === 'MS') ? "disable-platform" : ""}`} isOpen={isAnswerBranchFilterOpen} toggle={toggleAnsBranch}>
                <DropdownToggle >
                  <div className='dr-button'>
                    <div style={{ display: 'flex' }}>
                      <div className='dt-title'>{`Next: ${props.getNextQuestName(props.answerBranch, 'A')}`}</div>
                    </div>
                    <div>
                      <img style={{
                        display: "inline-block",
                        margin: '0 12px',
                        transform: isAnswerBranchFilterOpen ? 'rotate(180deg)' : ''
                      }}
                        src={
                          Icons.ARROW_DOWN
                        }
                        className="arrow-down"
                        alt='next-q-filter'
                      />
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu id='next-q-dropdown-menu'>

                  <div className='search-wrapper'>
                    <Input
                      value={searchText}
                      type="text"
                      name="search"
                      id="searchUser"
                      placeholder="Search..."
                      className="search-input"
                      autocomplete="off"
                      onChange={(event) => { searchQuesDropdown(event) }}
                      disabled={false}
                    />
                    <img
                      src="/Icon_assets/svg/search-organization-icon.svg"
                      alt="Search"
                      className="search-image"
                    />
                  </div>

                  <div className='next-q-list' id="nextQDropDown">
                    <InfiniteScroll
                      dataLength={nextQuesDropDownData?.length}
                      next={() => { }}
                      hasMore={false}
                      loader={<SkeletonTable rows={10} />}
                      scrollableTarget="nextQDropDown"
                    >
                      {nextQuesDropDownData.filter((item) => item.id !== props.data.ID).map((item, index) => <div style={{ width: '100%' }}
                        key={index}
                        className={"radio-list"}
                        onClick={() => {
                          setSearchText('');
                          setNextQuesDropDownData(props.nextQuestionDropdownData);
                          props.changeAnswerBranch(props.data, item, props.ansName)
                        }}
                      >
                        <DropdownItem toggle={true}>
                          <CustomInput type="radio" name="radio1"
                            className={"custom-radio-input"}
                            style={{ width: '100%' }}
                            id={`radio-${index}`}
                            label={
                              <div style={{ width: '100%' }}>
                                <div className={`dr-title ${((item.mainLabel.replace(/\s/g, '') === props.answerBranch.replace(/\s/g, '') ||
                                  (item.mainLabel.replace('Question', 'Message').replace(/\s/g, '')) === props.answerBranch.replace(/\s/g, ''))) ? "active" : ""}`} id={`Org-Name-Tooltip-${index}`}>{item.mainLabel}</div>
                                <div className='dr-title sub' title={item.subLabel}>{item.subLabel}</div>
                              </div>
                            }
                            checked={((item.mainLabel.replace(/\s/g, '') === props.answerBranch.replace(/\s/g, '') ||
                              (item.mainLabel.replace('Question', 'Message').replace(/\s/g, '')) === props.answerBranch.replace(/\s/g, '')))}
                            value={props.data.ID}
                          />
                        </DropdownItem>
                      </div>)
                      }
                    </InfiniteScroll>
                  </div>

                </DropdownMenu>
              </Dropdown>
              {(userData.organization_role === 0) && (data?.taxonomy_question) && rtrType !== "Programmatic" && <TaxonomyAnswerList data={data} getTaxonomySelection={getTaxonomySelection} answerId={props.ansName} taxonomyAnswerListOption={taxonomyAnswerListOption} filterAnserListData={filterAnserListData} clearTaxonomySelection={clearTaxonomySelection} />}
              {props.answerBranch !== '' && <div style={{ marginLeft: '18px' }}>
                <img src={Icons.ANS_BR} alt='branch'></img>
              </div>}
            </div>
            {props.asset.template_type.id !== 9 && <div className='hr'></div>}
            <div className='pixel-div'>Pixel
              <label className={`switch`}>
                <input
                  name={props.ansNameToggle}
                  type="checkbox"
                  id={`ans-toggle-${props.ansId}`}
                  key={`ans-toggle-${props.ansId}`}
                  checked={props.answerToggle}
                  onChange={(e) => {
                    handleInputChange(e, props.data, 'answer', 'toggle', !props.answerToggle)
                    // setDisplayPixel(prevState => !prevState)
                  }}
                />
                <span className="slider round"></span>
              </label>
              {props.answerToggle &&
                <Input
                  type="textarea"
                  name={props.ansNamePixel}
                  id={props.ansNamePixel}
                  className="form-input form-input-sm mt-2 input-question required input-pixel-code"
                  autoComplete="off"
                  value={props.answerPixel}
                  onChange={(e) => handleInputChange(e, props.data, 'answer', 'pixel')} />
              }</div>
          </div>}
      </div>
    </div>
  )
}

export default BuilderAnswer;