import moment from "moment";

function getLocalDate(date, dateformat) {
  let selectedDate = moment.utc().format(date);
  let convertedDate = moment.utc(selectedDate).toDate();
  return moment(convertedDate).local().format(dateformat);
}

function formatDateUTC(date, dateformat = 'MMMM DD, YYYY') {
  return moment.utc(date).format(dateformat);
}

export { getLocalDate, formatDateUTC };