import React, { useState } from 'react'
import PopUpModal from "../../../../components/PopUpModal";
import SheduleReport from '../../../../components/ReportBuilder/ScheduleReport'
import { UncontrolledTooltip } from 'reactstrap'
import DeleteSchedule from './DeleteSchedule';
import RBAC from '../../../../components/RBAC';
import { PERMISSIONS } from '../../../../utils/permissionMatrix';
import './index.scss'

function ScheduleReportListActions({ id, state, refreshTable, currentPage }) {

  const [editModalView, setEditModalView] = useState(false);
  const [previewModalView, setPreviewModalView] = useState(false);
  const [deleteModalView, setDeleteModalView] = useState(false);

  return (<>
    <div className='schedule-report-actions'>
      <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ} >
        <section className="column-actions d-flex">
          <span className='column-actions' id={'openviewtooltip' + id}
            onClick={(e) => setPreviewModalView(true)}
          ><img src={"/Icon_assets/svg/preview-icon.svg"} height='11.67px' width='16.67px' alt="preview" />
          </span >
          <UncontrolledTooltip
            placement="top"
            className="ad-tool-tip"
            target={'openviewtooltip' + id} >
            {'View'}
          </UncontrolledTooltip >
        </section>
      </RBAC>
      <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE} >
        <span className=" d-flex divider"></span>
        <section className="column-actions d-flex" style={{ cursor: `${state === 2 ? "default" : "pointer"}` }}>
          <span className={`column-actions ${state === 2 ? "prevent-user-selection opacity-50" : ""}`} id={'openedittooltip' + id}
            onClick={(state !== 2) ? (e) => setEditModalView(true) : undefined}
          ><img src={"/Icon_assets/svg/edit_icon.svg"} height='15px' width='15px' alt="edit" />
          </span >
          <UncontrolledTooltip
            placement="top"
            className="ad-tool-tip"
            target={'openedittooltip' + id} >
            {'Edit'}
          </UncontrolledTooltip >
        </section>
      </RBAC>
      <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_DELETE} >
        <span className="d-flex divider "></span>
        <section className="column-actions d-flex">
          <span className='column-actions' id={'opendeletetooltip' + id}
            onClick={(e) => setDeleteModalView(true)}
          ><img src={"/Icon_assets/svg/delete-icon.svg"} height='16.67px' width='15px' alt="delete" />
          </span >
          <UncontrolledTooltip
            placement="top"
            className="ad-tool-tip"
            target={'opendeletetooltip' + id} >
            {'Delete'}
          </UncontrolledTooltip >
        </section>
      </RBAC>
    </div>

    <PopUpModal
      className={'schedule-report-modal'}
      title={"Edit Schedule Report"}
      openPopUp={editModalView}
      setOpenPopUp={setEditModalView}
    >
      <SheduleReport
        type={"update"}
        setOpenPopUp={setEditModalView}
        id={id}
      />
    </PopUpModal>

    <PopUpModal
      className={'schedule-report-modal'}
      title={"Preview Schedule Report"}
      openPopUp={previewModalView}
      setOpenPopUp={setPreviewModalView}
    >
      <SheduleReport
        type={"view"}
        setOpenPopUp={setPreviewModalView}
        id={id}
      />
    </PopUpModal>

        <PopUpModal
            className={'schedule-report-modal delete-modal'}
            title={"Delete Reports"}
            openPopUp={deleteModalView}
            setOpenPopUp={setDeleteModalView}
        >
            <DeleteSchedule id={id} setDeleteModalView={setDeleteModalView} refreshTable={refreshTable} currentPage={currentPage} />
        </PopUpModal>

  </>
  )
}

export default ScheduleReportListActions