import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import "./index.scss";

import Validator from "../../utils/validators";
import ROUTES from "../../utils/routeConstants";

import LeftSection from "../../components/LeftSection";
import Notifications from "../../components/Notifications";
import ADspinner from "../../components/Loader/ADspinner";
import { forgotPasswordRequest, forgotPasswordReset } from "../../actions/authActions";
import history from "../../history";

const ForgotPassword = () => {
  /*** State init ***/
  const [inputValues, setInputValues] = useState({ email: "" });
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  /*** State init end ***/

  /*** Redux init ***/
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const forgotpassword = useSelector((state) => state.auth.forgotpassword);
  const { email, isEmailSent } = forgotpassword;
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;
  /*** Redux end ***/

  //Init component default state
  useEffect(() => {
    setstate(validatorsObj);
    dispatch(forgotPasswordReset());
    window.scrollTo(0, 0)
  }, []);

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = validators.email.valid;
    setIsValid(checkValidation);
  };

  const handleReset = (e) => {
    dispatch(forgotPasswordReset());
    window.scrollTo(0, 0);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      dispatch(forgotPasswordRequest(inputValues));
    }
  };

  if(isLoggedIn){
    if (userData.organization_role === 0) {
      //admin access
      return <Redirect to={ROUTES.DASHBOARD} />
    }else{
      //Reporting user access
      if (is_media_enabled) {
        return <Redirect to={ROUTES.DASHBOARD} />
      }else{
        return <Redirect to={ROUTES.REALTIME_RESEARCH} />
      }
    }
  }

  return (
    <>
      <section className="main-container">
        <LeftSection pagename={"forgotpassword"} bgclass={"orange"} />

        <section className="right-section forgot-change-password" style={{ paddingTop: '342px' }}>

          {isEmailSent ?
            (
              <>
                <h2>Thank you!</h2>
                <p className="title-text">
                  We've sent you an email to <span className="ad-border-bottom">{email}</span> to reset your password.
                </p>

                <p className="title-text mt-5">
                  If you do not receive an email from us in a few minutes,
                  please <a onClick={handleReset} className="link ad-link-border-bottom">reset</a> your password again.
                </p>
              </>
            ) :
            (
              <>
                <h2>Forgot password?</h2>
                <p className="title-text">
                  <span className="d-block">Let's reset. Enter your email address to request a new password to get you back into your Invisibly for Brands account.</span>
                </p>

                <Notifications />

                <Form className="user-form-container" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      value={inputValues.email}
                      className="form-input form-input-sm"
                      autoComplete="off"
                      onChange={handleInputChange}
                      placeholder="Email address"
                      invalid={validators.email.errorMsg !== ""}
                    />
                    <FormFeedback>{validators.email.errorMsg}</FormFeedback>
                  </FormGroup>

                  <FormGroup className="actions">
                    <Button
                      className="in-btn-lg in-btn-primary"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isValid ? false : true}
                      style={{minWidth:'201px'}}
                    >
                       {isLoading ?  <ADspinner size="large" /> : 'Submit' }
                    </Button>
                  </FormGroup>
                </Form>
              </>
            )
          }
          <div className="back-to-signin">
          <Button className="in-btn-link blue"
            style={{
              position: 'absolute',
              bottom: '38px',
              fontSize: '20px'
            }}
            onClick={() => history.push(ROUTES.SIGNIN)}
          >Back to sign in</Button>
          </div>


        </section>
      </section>
    </>
  )
}

export default ForgotPassword;