import React, { useEffect } from 'react'
import { Button, Col } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { scheduleReportActionCreator } from "../../../../../actions"
import ADspinner from '../../../../../components/Loader/ADspinner';

function DeleteSchedule({ id, setDeleteModalView, refreshTable, currentPage }) {

    const dispatch = useDispatch();
    const isDeleting = useSelector(state => state.scheduleReport.isDeleting)
    const deleted = useSelector(state => state.scheduleReport.deleted)


    useEffect(() => {
        if (deleted) {
            refreshTable(currentPage)
            //dispatch(scheduleReportActionCreator.getScheduleReportListRequest({ method: "GET" }))
            setDeleteModalView(!deleted)
        }
    }, [deleted])

    const deletedBtnClicked = () => {
        dispatch(scheduleReportActionCreator.deleteScheduleReportRequest(id))
    }

    return (
        <>
            <div className="schedule-report-delete-container">
                <p>{'Are you sure you want to delete selected scheduled reports?'}<br />{'This action cannot be undone.'}</p>
            </div>
            <Col md={12} sm={12} lg={12}>
                <div className="delete-actions">
                    <Button
                        className="in-btn-lg in-btn-info btn-save"
                        onClick={() => setDeleteModalView(false)}
                    >
                        {'No'}
                    </Button>
                    <Button
                        className="in-btn-link btn btn-link btn-clear btn-cancel"
                        onClick={deletedBtnClicked}
                        disabled={isDeleting}
                    >
                        {'Yes, Delete'}
                    </Button>
                </div>
            </Col>
        </>
    )
}

export default DeleteSchedule