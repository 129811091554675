import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActionCreator } from "../../actions/index.js";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import "./index.scss";

const DeactivatePopUpModal = (props) => {
  const dispatch = useDispatch();

  const {
    deactivateOpenPopUp,
    setDeactivateOpenPopUp,
    deactivateUserId,
    deactivateUserName,
  } = props;

  const deactivateUser = () => {
    dispatch(userActionCreator.deactivateUserRequest(deactivateUserId));
    setDeactivateOpenPopUp(false);
  };

  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setDeactivateOpenPopUp(false);
      }}
    >
     <img src="/Icon_assets/svg/close_icon_black.svg" alt="Close" style={{width:'16px', height:'16px'}}/>
    </button>
  );
  return (
    <Modal isOpen={deactivateOpenPopUp} className="pop-up-modal" centered>
      <div className="deactivate-popup">
        <ModalHeader close={closeBtn}>Deactivate User</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'BasisGrotesque'}}>Are you sure you want to deactivate {deactivateUserName}?</p>
          <Form>
            <FormGroup>
              <Button
                className="in-btn-sm in-btn-danger mr-4 btn-deactivate"
                color="danger"
                onClick={deactivateUser}
              >
                Deactivate
              </Button>{" "}
              <Button
                color="link"
                className="in-btn-link"
                onClick={() => setDeactivateOpenPopUp(false)}
              >
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default DeactivatePopUpModal;
