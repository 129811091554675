import React from 'react';
import "../index.scss";
const GenericAnswerMenuElement = ({ menuItemChildren}) => {
  return (
    <>
      <section className="answer-list-menu">{menuItemChildren}</section>
    </>
  );
}

export default GenericAnswerMenuElement;