import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import {
  Card, Button, Form, Label, FormFeedback,
  Row, Col, Input, FormGroup, UncontrolledTooltip, Tooltip,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput
} from 'reactstrap';
import Validator from "../../../utils/validators";
import { conversionActionCreator } from '../../../actions';
import ADspinner from "../../Loader/ADspinner";
import { useLocation } from 'react-router';



const CreateRule = () => {
  const dispatch = useDispatch();
  const validatorsObj = new Validator();
  const location = useLocation();

  const clonedRule = JSON.parse(localStorage.getItem('clonedRule'));
  const isLoading = useSelector((state) => state.conversion.isLoading);
  const createRuleResponse = useSelector((state) => state.conversion.createRules);
  const createRuleError = useSelector((state) => state.conversion.isError);

  const statusIcons = {
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    BACK: "/Icon_assets/svg/back_icon.svg",
    INFO: "/Icon_assets/svg/rule-info.svg",
    ERROR_ICON: "/Icon_assets/svg/error_msg_icon.svg",
    DISABLED_ARROW_DOWN: "/Icon_assets/svg/rule_down_icon.svg"
  };

  const VALUES = {
    EVENT_BASED: "Event Based",
    URL_BASED: "URL Based"
  };

  const initialState = {
    rule_name: "",
    event_type: "",
    rule_type: VALUES.EVENT_BASED,
    rule_url: ""
  }
  const [inputValues, setInputValues] = useState(initialState);
  const [validators, setstate] = useState(validatorsObj);
  const [eventType, setEventType] = useState();
  const [ruleType, setRuleType] = useState(VALUES.EVENT_BASED);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [eventTypeDropDownOpen, setEventTypeDropDownOpen] = useState(false);
  const toggleEventType = () => setEventTypeDropDownOpen(prevState => !prevState);
  const [hoverStatus, setHover] = useState(false);
  const headerRefs = useRef(null);

  const compareSize = (textElementRef) => {
    if (textElementRef && textElementRef.current) {
      var el = textElementRef.current;
      const compare = el.offsetWidth < el.scrollWidth
      setHover(compare);
    }
  };



  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  /*Static arrays start*/
  const dateIntervalArr = [
    { label: "Subscribe", value: "SUBSCB" },
    { label: "Register", value: "REGIST" },
    { label: "Purchase", value: "PRCHS" },
    { label: "Add To Cart", value: "ADCRT" },
    { label: "Add Payment Info", value: "ADPAY" },
    { label: "Add To Wishlist", value: "ADWHT" },
    { label: "Initiate Checkout", value: "CHEKOT" },
    { label: "Lead", value: "LEAD" },
    { label: "Search", value: "SRCH" }

  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    return function cleanup() {
      dispatch(conversionActionCreator.clearCreateRuleResponse());
      setstate(validatorsObj);
    };
  }, []);

  useEffect(() => {
    if (!createRuleError && createRuleResponse
      && Object.keys(createRuleResponse).length !== 0) {

      var brandId = localStorage.getItem('selectedConversionAd') ?
        JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
      history.push(`${ROUTES.CONVERSION}/conversion-rule-list/${brandId}`);
    }

  }, [createRuleResponse, createRuleError]);


  useEffect(() => {
    if (location && location.state) {
      const clonedRule = location.state;
      const inputFields = { ...inputValues };
      inputFields.rule_name = "";
      // inputFields.reward = clonedRule.reward;
      inputFields.reward = (clonedRule.reward % 1 === 0) ? (clonedRule.reward.toFixed(2)) : (clonedRule.reward)
      // check what is returned from API
      var d = dateIntervalArr.find(data => data.value === clonedRule.event_type);
      inputFields.event_type = d.value;
      inputFields.rule_type = clonedRule.rule_type === 0 ? VALUES.EVENT_BASED : VALUES.URL_BASED;
      if (clonedRule.rule_type === 1) {
        inputFields.rule_url = clonedRule.url;
      }

      setRuleType(inputFields.rule_type);
      setEventType(d.value);
      setInputValues(inputFields);
      if (clonedRule.isDetailsPage) {
        const ruleName = location.state;
        // const inputFields = { ...inputValues };
        inputFields.rule_name = ruleName.rule_name;
        setInputValues(inputFields);
        setIsReadOnly(true);
      }
    }
  }, [location]);


  const handleFormSubmit = (e) => {
    e.preventDefault();
    // create data for API to call
    var params = { ...inputValues };
    params.rule_type = inputValues.rule_type === VALUES.URL_BASED ? 1 : 0;
    params.url = inputValues.rule_url;
    params.brandId = localStorage.getItem('selectedConversionAd') ?
      JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
    console.log({ ...params })
    dispatch(conversionActionCreator.createRuleRequest(params));
    window.scrollTo(0, 0);
  }

  const selectRuleType = (e, type) => {
    setRuleType(type);
    const inputFields = { ...inputValues };
    inputFields["rule_type"] = type;
    inputFields["rule_url"] = type === VALUES.EVENT_BASED ? "" : inputFields.rule_url;
    setInputValues(inputFields);
    setstate(validatorsObj);
  }


  const backToConversionList = (e) => {
    e.preventDefault();
    headerRefs.current = null;
    const brandId = localStorage.getItem('selectedConversionAd') ?
      JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
    history.push({ pathname: `${ROUTES.CONVERSION}/conversion-rule-list/${brandId}`, });
  }

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    if (e.target.name === 'rule_url' ||
      e.target.name === 'rule_name') {
      updateValidations(e.target.name, e.target.value);
    }
  }

  const handleRewardChange = (e) => {
    const inputFields = { ...inputValues };
    let val = e.target.value
    if (val === '') {
      val = 0;
    }
    inputFields[e.target.name] = parseFloat(val).toFixed(2);
    setInputValues(inputFields);
  }

  const goToCoversionBuilder = (e) => {
    e.preventDefault();
    history.push(ROUTES.CONVERSION_REPORT_BUILDER);
  }
  return (<>
    <section className="create-rule-section">
      <nav className={"left-nav-section"} style={{ marginBottom: isReadOnly ? "24px" : "" }}>
        <Button className="btn-transparent pl-5 pb-3" style={{ verticalAlign: "super" }} onClick={backToConversionList}>
          <img src={statusIcons.BACK} alt="go to back" className="go-back" />
        </Button>
        <h2 className={isReadOnly ? "read-only-header" : ""} id="rule-header"
          onMouseEnter={() => compareSize(headerRefs)}
          onMouseLeave={() => compareSize(headerRefs)}
          ref={headerRefs}
          style={{ display: "inline-block", paddingLeft: "24px" }}>{isReadOnly ? `Rule: ${inputValues.rule_name}` : `Create New Rule`}</h2>
        {hoverStatus &&
          <UncontrolledTooltip
            className="rule-tool-tip"
            placement="top"
            target={"rule-header"}
          >{`Rule: ${inputValues.rule_name}`}
          </UncontrolledTooltip>
        }
      </nav>
      <nav>
      </nav>
    </section>
    <Form className="create-rule-form">
      <Card className="data-card">
        <section id="header-section">
          <nav className="left-nav-section" style={{ marginTop: "19px" }}>
            <h2>Rule Setup</h2>
          </nav>
          <section className={"form-section " + (isReadOnly ? "read-only-section" : "")}>
            <Row>
              <Col md={12} sm={12} lg={5}>
                <FormGroup style={{ height: "35px" }}>
                  <Input
                    style={{ borderBottom: validators.rule_name.errorMsg ? "1px solid #EB0404" : "" }}
                    type="text"
                    name="rule_name"
                    id="ruleName"
                    required
                    className="form-input form-input-sm mt-2 required"
                    autoComplete="off"
                    value={inputValues.rule_name}
                    onChange={(e) => handleInputChange(e)}
                  />

                  <Label className="floating-heading-label required">Rule Name</Label>
                  {!isReadOnly &&
                    <>
                      <img style={{}} src={statusIcons.INFO} alt="Info icon" id="rule-info" className="index-tip floating-info" />
                      <UncontrolledTooltip
                        placement="right"
                        className="custom-rule-input-tooltip"
                        target="rule-info">
                        Make sure your rule name is correct because <br />it cannot be modified after created.
                      </UncontrolledTooltip></>}
                  {validators.rule_name.errorMsg && <img src={statusIcons.ERROR_ICON} style={{ top: "20px" }}
                    alt="error" className="error-icon" />}
                  <FormFeedback className={validators.rule_name.errorMsg ? "change-float" : ""}>{validators.rule_name.errorMsg}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>

              <Col md={12} sm={12} lg={5}>
                <FormGroup>
                  <Label className="lbl-rule required" style={{ top: "-25px", left: "0px" }}>Event Type</Label>
                  <Dropdown
                    isOpen={eventTypeDropDownOpen}
                    toggle={toggleEventType}
                    className="dropdown-option"
                  >
                    <DropdownToggle
                      tag="span"
                      data-toggle="dropdown"
                      className="dropdown_toggle"
                    >
                      <span
                        style={{ color: eventTypeDropDownOpen ? '#0E0E0F' : '#535559' }}
                        className="dropdown_title"
                        title={"Select Event Type"}
                      >
                        {dateIntervalArr.find(data => data.value === eventType)
                          ? dateIntervalArr.find(data => data.value === eventType).label
                          : (<><span>Select Event Type </span><span className="text-danger">*</span></>)}
                      </span>
                      <img
                        src={
                          eventTypeDropDownOpen
                            ? statusIcons.ARROW_UP
                            : isReadOnly ? statusIcons.DISABLED_ARROW_DOWN : statusIcons.ARROW_DOWN
                        }
                        className="dropdown-img"
                      ></img>
                    </DropdownToggle>
                    <DropdownMenu>
                      {dateIntervalArr && (
                        dateIntervalArr.map((item) => {
                          return (
                            <DropdownItem
                              key={item.label}
                              onClick={() => {
                                setEventType(item.value);
                                const inputFields = { ...inputValues };
                                inputFields["event_type"] = item.value;
                                setInputValues(inputFields);
                              }
                              }
                              className={`d-flex justify-content-between align-items-center ${item.value === eventType ? "dropdown-option-selected" : ""}`}
                            >
                              {item.label}

                              {item.value === eventType ? (<img
                                src={statusIcons.CHECKED_BLUE_ICON}
                                alt="dimension selected"
                                className="option-selected"
                              />) : ""}
                            </DropdownItem>
                          )
                        })
                      )}
                    </DropdownMenu>
                  </Dropdown>

                </FormGroup>
              </Col>
            </Row>
            {/*
            <Row>
              <Col md={12} sm={12} lg={5}>
                <FormGroup>
                  <Label className="lbl-rule">User Reward for Converting</Label>
                  <div style={{ paddingTop: "16px" }}>
                    <Label style={{
                      color: (isReadOnly ? "#535559" : "#0E0E0F"), fontSize: "20px",
                      opacity: isReadOnly ? '0.7' : ''
                    }}>$</Label>
                    <Input
                      type="number"
                      step="0.01"
                      min={0.00}
                      name="reward"
                      id="rewardName"
                      required
                      className="form-input form-input-sm mt-2 required"
                      autoComplete="off"
                      value={inputValues.reward}
                      onBlur={(e) => handleRewardChange(e)}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>*/
            }

            <Row style={{ marginBottom: inputValues.rule_type === VALUES.EVENT_BASED ? "0" : "32px" }}>
              <Col md={12} sm={12} lg={5}>
                <FormGroup>
                  <Label className="lbl-rule required">Rule Type</Label>
                  <div style={{ paddingTop: "18px" }}>
                    <CustomInput type="radio"
                      style={{ paddingBottom: "20px" }}
                      name="rule_type"
                      className={"custom-radio-input " + (isReadOnly ? "custom-radio-read" : "")}
                      id="radio1"
                      label={VALUES.EVENT_BASED}
                      value={VALUES.EVENT_BASED}
                      checked={inputValues.rule_type === VALUES.EVENT_BASED}
                      onChange={(e) => selectRuleType(e, VALUES.EVENT_BASED)} />

                    <CustomInput type="radio"
                      name="rule_type"
                      className={"custom-radio-input"}
                      id="radio2"
                      label={VALUES.URL_BASED}
                      value={VALUES.URL_BASED}
                      checked={inputValues.rule_type === VALUES.URL_BASED}
                      onChange={(e) => selectRuleType(e, VALUES.URL_BASED)} />
                  </div>
                  {
                    inputValues.rule_type === VALUES.URL_BASED &&
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="lbl-rule required">URL Contains</Label>
                          {!isReadOnly &&
                            <>
                              <img style={{ padding: "6px", cursor: "pointer" }} src={statusIcons.INFO} alt="Info icon" id="file-info" className="index-tip" />
                              <UncontrolledTooltip
                                placement="right"
                                className="custom-rule-input-tooltip"
                                target="file-info">
                                If the conversion you're looking to track <br />has a URL that is www.invisibly.com/checkout, <br />you only need to include /checkout in this field.
                              </UncontrolledTooltip>
                            </>}
                          <Input
                            style={{ borderBottom: validators.rule_url.errorMsg ? "1px solid #EB0404" : "" }}
                            placeholder="Enter URL"
                            type="text"
                            name="rule_url"
                            id="url"
                            required
                            className="form-input form-input-sm mt-2 required"
                            autoComplete="off"
                            value={inputValues.rule_url}
                            onChange={(e) => handleInputChange(e)}
                          />
                          {validators.rule_url.errorMsg && <img src={statusIcons.ERROR_ICON}
                            alt="error" className="error-icon" />}
                          <FormFeedback style={{ marginTop: "2px" }}>{validators.rule_url.errorMsg}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                </FormGroup>
              </Col>
            </Row>
          </section>
        </section>
      </Card>
      {!isReadOnly &&
        <FormGroup className="text-right">
          <Button
            className="in-btn-lg in-btn-secondary btn-transparent cancel-org-btn"
            onClick={backToConversionList}
          >Cancel</Button>
          <Button
            className="in-btn-lg in-btn-primary ml-3 create-org-btn"
            type="submit"
            disabled={inputValues.rule_type === VALUES.URL_BASED ?
              (inputValues.rule_name === "" || inputValues.event_type === "" || inputValues.rule_url === ""
                || validators.rule_url.errorMsg !== "" || validators.rule_name.errorMsg !== "")
              : (inputValues.rule_name === "" || inputValues.event_type === ""
                || validators.rule_url.errorMsg !== "" || validators.rule_name.errorMsg !== "")}
            onClick={handleFormSubmit}
          > {isLoading ? <ADspinner /> : `Create Rule`}
          </Button>
        </FormGroup>
      }
    </Form>
  </>)
}

export default CreateRule;