import { takeLatest, takeEvery, call, select, put, take } from "redux-saga/effects";
import { surveyActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function getPreviewRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = API_URL_PARAM + "/v1/brands/" + payload.brands + "/messages/"
    + payload.messages + "/preview";
  if (payload.organization_id) {
    API_URL = API_URL_PARAM + "/v1/brands/" + payload.brands + "/messages/"
      + payload.messages + "/preview?organization_id=" + payload.organization_id;
  }
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      'Authorization': jwtToken
    }
  }).catch((err) => {
    console.log(err);
  });
}

function* getPreviewRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getPreviewRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(surveyActionCreator.getPreviewRequestSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(surveyActionCreator.getPreviewRequestFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.getPreviewRequestFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function getExternalPreviewRequest(API_URL_PARAM, jwtToken, payload) {
  const API_URL = API_URL_PARAM + "/v1/brands/" + payload.brands + "/messages/"
    + payload.messages + "/preview-share?ad_id=" + payload.ad_id;
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      // 'Authorization': jwtToken
    }
  }).catch((err) => {
    console.log(err);
  });
}

function* getExternalPreviewRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getExternalPreviewRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(surveyActionCreator.getExternalPreviewRequestSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(surveyActionCreator.getExternalPreviewRequestFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.getExternalPreviewRequestFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function uploadImageRequest(API_URL, JWTToken, payload) {
  let param = {};
  let UPLOAD_API_URL = API_URL + '/v1/adcreatives/upload';
  if (payload.organization_id)
    param.organization_id = payload.organization_id;

  const formData = new FormData();
  formData.append('brand_id', payload.brand_id);
  formData.append('file', payload.file);
  return axios({
    method: 'POST',
    url: UPLOAD_API_URL,
    params: param,
    headers: {
      Authorization: JWTToken,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  }).then(response => {
    return response.data;
  });
}

function* uploadFileSaga(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let headerImage = action.payload.isHeaderImage;
    let logoImage = action.payload.isLogoImage;
    const result = yield call(
      uploadImageRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.uploadImageSuccess(item, headerImage, logoImage));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.uploadImageFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.uploadImageFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.uploadImageFailure("Server Error"));
  }
}

function requestMimeTypeList(API_URL, jwtToken) {
  let MIME_TYPE_LIST_URL = `${API_URL}/v1/mime-types`;
  return axios({
    url: MIME_TYPE_LIST_URL,
    method: 'get',
    headers: {
      Authorization: jwtToken
    }
  }).catch(err => {
    console.log(err);
  });
}

function* requestMimeTypeListSaga() {
  // while (true) {
  // const action = yield take(ACTIONS.REQUEST_MIME_TYPE_LIST);
  try {
    const API_URL = yield select(state => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(requestMimeTypeList, API_URL, jwtToken);
    let receiveData = yield put(
      surveyActionCreator.receiveMimeTypeList({
        mimeTypeList: result.data.item
      })
    );
  } catch (err) {
    yield put(
      notificationActionCreator.setNotification({
        message: 'server error ' + err
      })
    );
    // }
  }
}

function saveFileRequest(API_URL, jwtToken, payload) {
  let param = {};
  if (payload.organization_id)
    param.organization_id = payload.organization_id;
  const brand_id = payload.brand_id;
  const SAVE_FILES_URL = `${API_URL}/v1/brands/${brand_id}/creative-asset-files`;
  return axios({
    method: 'POST',
    url: SAVE_FILES_URL,
    params: param,
    headers: {
      Authorization: jwtToken,
    },
    data: [payload.file]
  });
}

function* saveFileSaga(action) {

  try {
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const API_URL = yield select(state => state.config.API_URL);
    const result = yield call(saveFileRequest, API_URL, jwtToken, action.payload);
    if (result.data.status.status_type == 'Success') {
      yield put(
        surveyActionCreator.saveFilesSuccess({
          status: result.data.status.status_type,
          res: result.data.item
        }),
      );
      yield put(
        notificationActionCreator.setNotification({
          message: 'Files Uploaded succesfully',
          color: 'success',
        })
      );
    } else {
      yield put(
        surveyActionCreator.saveFilesError({
          status: result.data.status.status_type,
          res: result.data.item
        }),
      );
      yield put(
        notificationActionCreator.setNotification({
          message: result.data.status.status_message[0].file_name[0], //status.status_message,
        })
      );
    }
  } catch (err) {
    yield put(
      notificationActionCreator.setNotification({
        errorMsg: 'server error ' + err,
      })
    );

  }
}



function uploadCSVRequest(API_URL, JWTToken, payload) {
  let param = {};
  if (payload.organization_id)
    param.organization_id = payload.organization_id;
  const UPLOAD_API_URL = API_URL + '/v1/adcreatives/upload';

  const formData = new FormData();
  formData.append('brand_id', payload.brand_id);
  formData.append('file', payload.file);
  return axios({
    method: 'POST',
    url: UPLOAD_API_URL,
    params: param,
    headers: {
      Authorization: JWTToken,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  }).then(response => {
    return response.data;
  });
}

function* uploadCSVFileSaga(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let headerImage = action.payload.isHeaderImage;
    let logoImage = action.payload.isLogoImage;
    const fileName = (action.payload.file && action.payload.file.name) ?
      action.payload.file.name.split('.').slice(0, -1).join('.') : "";
    const result = yield call(
      uploadCSVRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.uploadCSVSuccess(item, headerImage, logoImage));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: fileName ? `${fileName} was uploaded successfully.` : status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.uploadCSVFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.uploadCSVFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.uploadCSVFailure("Server Error"));
  }
}



function submitAdRequest(API_URL, JWTToken, payload) {
  const UPLOAD_API_URL = API_URL + '/v1/rtr';
  var param = {};
  if (payload.organization_id) {
    param.organization_id = payload.organization_id;
  }
  return axios({
    method: 'POST',
    url: UPLOAD_API_URL,
    params: param,
    headers: {
      Authorization: JWTToken,
    },
    data: payload,
  }).then(response => {
    return response.data;
  });
}

function* submitAdSaga(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      submitAdRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    // var result = { "status": { "status_type": "Success", "status_message": "Request Successful", "status_code": 1001 }, "item": { "id": "vomrzk3dn10ok1js", "name": "6july TestAd1", "pixel": "", "created_by": "d4f49b47-ac73-4461-88e7-00cf7e2248b4", "brand_v2": "44a48593-abc0-4df5-b60a-069a96abf637", "ad_set": "6980zge840r8kois", "ad_tracking": null, "ad_analytics": "b930a6e4-f8c0-4cfd-8613-6d0d7f686c0f", "budget": null, "custom_variable": null, "campaign": "6snj0rhkb028q0xu", "tracking_tag": null, "ad_set_name": "6july TestAd1_AdSet", "campaign_state_id": 0, "ad_video_analytics": "c862e537-1899-47d0-a1de-c5c29a33a109", "ad_pixel_analytics": "4f07db28-e245-456d-becb-109c48401da8", "message": "f676fd8a-7f10-4607-9a42-4f15dafa57c8", "platform": 0, "status": 0, "state_transition": [{ "from_state": 0, "to_state": "In-Review", "to_state_id": 1 }], "ad_state": 0, "ad_message_feedback_analytics": { "like": 0, "dislike": 0.0 }, "universal_tag": 0, "ad_template_type": 5, "tags": null } };

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      // localStorage.setItem("msg_id", "f676fd8a-7f10-4607-9a42-4f15dafa57c8");
      // localStorage.setItem("brand_id", "ae9ae5e2-a6bd-49cf-9eac-4ecb49755627");
      let getProgamaticObj = item.filter(i => i.platform_value === 1)
      localStorage.setItem("msg_id", getProgamaticObj?.[0]?.message);
      localStorage.setItem("brand_id", getProgamaticObj?.[0]?.brand_v2?.id);
      localStorage.setItem("ad_state", getProgamaticObj?.[0]?.ad_state);
      yield put(surveyActionCreator.submitAdSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: item?.[0]?.name + " was saved as draft successfully."
        })
      );

    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (errorMsgList &&
        typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.submitAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.submitAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.submitAdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.submitAdFailure("Server Error"));
  }
}

function activateAdRequest(API_URL, JWTToken, payload) {
  const UPLOAD_API_URL = API_URL + `/v1/brands/${payload.brand_id}/external-messages/${payload.ad_id}`;
  var adData = {
    name: payload.name,
    message: payload.message_id,
    ad_state: payload.ad_state
  };

  return axios({
    method: 'PUT',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken,
    },
    data: adData,
  }).then(response => {
    return response.data;
  });
}

function* activateAdSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      activateAdRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.activateAdSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: "Ad state updated succesfully."
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.activateAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.activateAdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.activateAdFailure("Server Error"));
  }
}

function updateDraftedAdRequest(API_URL, JWTToken, payload) {
  const UPLOAD_API_URL = API_URL + `/v1/rtr`;
  var param = {};
  if (payload.organization_id) {
    param.orgainzation_id = payload.organization_id;
  }

  return axios({
    method: 'PUT',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken,
    },
    data: payload,
  }).then(response => {
    return response.data;
  });
}

function* updateDraftedAd(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      updateDraftedAdRequest,
      API_URL,
      jwtToken,
      action.payload
    );


    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {

      yield put(surveyActionCreator.updateDraftedAdSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );

    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.updateDraftedAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.updateDraftedAdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.updateDraftedAdFailure("Server Error"));
  }
}

function uploadToastRequest(API_URL, JWTToken, payload) {
  let param = {};
  let UPLOAD_API_URL = API_URL + '/v1/adcreatives/upload';

  if (payload.organization_id) {
      param.organization_id = payload.organization_id;
  }

  const formData = new FormData();
  formData.append('brand_id', payload.brand_id);
  formData.append('file', payload.file);
  return axios({
    method: 'POST',
    url: UPLOAD_API_URL,
    params: param,
    headers: {
      Authorization: JWTToken,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  }).then(response => {
    return response.data;
  });
}

function* uploadToastFileSaga(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let headerImage = action.payload.isHeaderImage;
    let logoImage = action.payload.isLogoImage;
    const fileName = (action.payload.file && action.payload.file.name) ?
      action.payload.file.name.split('.').slice(0, -1).join('.') : "";
    const result = yield call(
      uploadToastRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.uploadToastSuccess(item, headerImage, logoImage));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: fileName ? `${fileName} was uploaded successfully.` : status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.uploadToastFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.uploadToastFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.uploadToastFailure("Server Error"));
  }
}


function getCampaignByIdRequest(API_URL, JWTToken, payload) {
  const UPLOAD_API_URL = API_URL + `/v1/ads/${payload.id}`;
  return axios({
    method: 'GET',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken
    },
  }).then(response => {
    return response.data;
  });
}

function* getCampaignById(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getCampaignByIdRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.getCampaignByIdSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.getCampaignByIdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.getCampaignByIdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.getCampaignByIdFailure("Server Error"));
  }
}


function getAdAssetsRequest(API_URL, JWTToken, payload) {  
  const UPLOAD_API_URL = API_URL + `/v1/brands/${payload.brand_id}/messages/${payload.message_id}`;
  return axios({
    method: 'GET',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken
    },
  }).then(response => {
    return response.data;
  });
}

function* getAdAssetsById(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let isInvisiblyAdRequest = action.payload?.invisibly
    delete action.payload['invisibly'] //remove flag -> as it stored in above variable
    const result = yield call(
      getAdAssetsRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      if (isInvisiblyAdRequest) {
        yield put(surveyActionCreator.getInvisiblyAdAssetsSuccess(item));
      }else{
        yield put(surveyActionCreator.getAdAssetsSuccess(item));
      }
      
      // yield put(
      //   notificationActionCreator.setNotification({
      //     color: "success",
      //     message: status.status_message
      //   })
      // );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        if (isInvisiblyAdRequest) {
          yield put(surveyActionCreator.getInvisiblyAdAssetsFailure(errorMsg.join()));
        }else{
          yield put(surveyActionCreator.getAdAssetsFailure(errorMsg.join()));
        }
        
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        if (isInvisiblyAdRequest) {
          yield put(surveyActionCreator.getInvisiblyAdAssetsFailure(errorMsg));
        }else{
          yield put(surveyActionCreator.getAdAssetsFailure(errorMsg));
        }
       
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.getAdAssetsFailure("Server Error"));
  }
}

function getTaxonomyQuestionListRequest(API_URL, JWTToken, paramList) {
  let UPLOAD_API_URL;
  if(paramList?.name){
    UPLOAD_API_URL = API_URL + `/v1/taxonomy?type=`+paramList?.type+`&tree=true&name=`+paramList?.name;
  }else{
    UPLOAD_API_URL = API_URL + `/v1/taxonomy?type=`+paramList?.type+`&tree=true`;
  }
  return axios({
    method: 'GET',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken
    },
  }).then(response => {
    return response.data;
  });
}

function* getTaxonomyQuestionListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let paramList = {};
    if(action.payload?.type !== undefined){
      paramList.type = action.payload.type;
    }
    if(action.payload?.name !== undefined){
      paramList.name = action.payload.name;
    }
    const result = yield call(
      getTaxonomyQuestionListRequest,
      API_URL,
      jwtToken,
      paramList
    );

    const { item, status } = result;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      if(!action.payload?.name){
        localStorage.setItem("taxonomyQuestionListData",JSON.stringify(item));
        yield put(surveyActionCreator.getTaxonomyQuestionListSuccess(item));
      }else{
        yield put(surveyActionCreator.getTaxonomyQuestionSearchListSuccess(item));
      }
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.getTaxonomyListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.getTaxonomyListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.getTaxonomyListFailure("Server Error"));
  }
}

function getTaxonomyAnswerListRequest(API_URL, JWTToken, paramList) {
  const UPLOAD_API_URL = API_URL + `/v1/taxonomy?type=`+paramList?.type+'&pagination='+paramList?.pagination;
  return axios({
    method: 'GET',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken
    },
  }).then(response => {
    return response.data;
  });
}

function* getTaxonomyAnswerListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let paramList = {};
    if(action.payload?.type !== undefined){
      paramList.type = action.payload.type;
    }
    if(action.payload?.pagination !== undefined){
      paramList.pagination = action.payload.pagination;
    }
    const result = yield call(
      getTaxonomyAnswerListRequest,
      API_URL,
      jwtToken,
      paramList
    );

    const { item, status } = result;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(surveyActionCreator.getTaxonomyAnswerListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(surveyActionCreator.getTaxonomyListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(surveyActionCreator.getTaxonomyListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(surveyActionCreator.getTaxonomyListFailure("Server Error"));
  }
}

export default function* surveySagas() {
  yield takeLatest(ACTIONS.GET_PREVIEW_REQUEST, getPreviewRequestSaga);
  yield takeLatest(ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST, getExternalPreviewRequestSaga);
  yield takeLatest(ACTIONS.GET_UPLOAD_IMAGE_REQUEST, uploadFileSaga);
  yield takeLatest(ACTIONS.REQUEST_MIME_TYPE_LIST, requestMimeTypeListSaga);
  yield takeLatest(ACTIONS.SAVE_FILE_REQUEST, saveFileSaga);
  yield takeLatest(ACTIONS.GET_UPLOAD_CSV_REQUEST, uploadCSVFileSaga);
  yield takeLatest(ACTIONS.POST_AD_REQUEST, submitAdSaga);
  yield takeLatest(ACTIONS.ACTIVATE_AD_REQUEST, activateAdSaga);
  yield takeLatest(ACTIONS.UPDATE_DRAFTED_AD_REQUEST, updateDraftedAd);
  yield takeLatest(ACTIONS.GET_UPLOAD_TOAST_REQUEST, uploadToastFileSaga);
  yield takeLatest(ACTIONS.GET_CAMPAIGN_BY_ID_REQUEST, getCampaignById);
  yield takeEvery(ACTIONS.GET_AD_ASSET_REQUEST, getAdAssetsById);
  yield takeEvery(ACTIONS.GET_INVISIBLY_AD_ASSET_REQUEST, getAdAssetsById);
  yield takeLatest(ACTIONS.GET_TAXONOMY_QUESTIONS_LIST_REQUEST, getTaxonomyQuestionListRequestSaga);
  yield takeLatest(ACTIONS.GET_TAXONOMY_ANSWER_LIST_REQUEST, getTaxonomyAnswerListRequestSaga);
}