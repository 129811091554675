import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  Label,
  CustomInput
} from "reactstrap";
import { useLocation } from 'react-router-dom'
import "./index.scss";

import Validator from "../../utils/validators";
import ROUTES from "../../utils/routeConstants";

import LeftSection from "../../components/LeftSection";
import Notifications from "../../components/Notifications";
import ADspinner from "../../components/Loader/ADspinner";
import { sendSignUpRequest } from "../../actions/authActions";
import history from "../../history";

const validators = new Validator();

const SignUp = () => {
  let location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const token = new URLSearchParams(location.search).get('token');

  /***State init***/
  const [inputValues, setInputValues] = useState({
    email: email ? email: "",
    password: "",
    name: "",
    token: token ? token: "",
  });
  const [show_input, setShow_input] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  /***State init end***/

  /*** Redux init ***/
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;
  /*** Redux end ***/

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation =
      validators.password.valid && validators.name.valid;
    setIsValid(checkValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validators.isFormValid(inputValues);
    if (isValid) {
      dispatch(sendSignUpRequest(inputValues));
    }
    setIsValid(isValid);
  };

  const togglePasswordShow = () => {
    setShow_input(!show_input);
  };

  useEffect(() => {
    if(isLoggedIn){
      if (userData.organization_role === 0) {
        //admin access
        history.push(ROUTES.DASHBOARD)
      }else{
        //Reporting user access
        if (is_media_enabled) {
          history.push(ROUTES.DASHBOARD)
        }

        if (is_media_enabled && !is_media_enabled) {
          history.push(ROUTES.REALTIME_RESEARCH)
        }

      }
    }
  }, [isLoggedIn])

  return (
    <>
      <section className="main-container signup-container">
        <LeftSection pagename={"signup"} bgclass={"blue"} />

        <section className="right-section" style={{paddingTop: "220px"}}>
          <h2>You've been invited to Invisibly for Brands.</h2>
          <p className="title-text">
            Finish setting up your account.
          </p>

          <Notifications />

          <Form
            className="user-form-container signup-form"
            onSubmit={handleSubmit}
          >
            <FormGroup>
              <Input
                type="text"
                name="email"
                id="email"
                value={inputValues.email}
                className="form-input form-input-sm"
                autoComplete="off"
                placeholder="Email address"
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="name"
                value={inputValues.name}
                className="form-input form-input-sm"
                autoComplete="off"
                onChange={handleInputChange}
                placeholder="Full name"
                invalid={validators.name.errorMsg !== ""}
              />
              <FormFeedback>{validators.name.errorMsg}</FormFeedback>
            </FormGroup>
            <FormGroup className="password-input-section">
              <Input
                type={show_input ? "text" : "password"}
                name="password"
                id="password"
                value={inputValues.password}
                className="form-input form-input-sm"
                autoComplete="off"
                onChange={handleInputChange}
                placeholder="Password"
                invalid={validators.password.errorMsg !== ""}
              />
              <span className="show-hide-text" onClick={togglePasswordShow}>
                {show_input ? "Hide" : "Show"}
              </span>
              <FormFeedback>{validators.password.errorMsg}</FormFeedback>
            </FormGroup>
            
            <FormGroup check className="form-group form-checkbox d-flex" style={{paddingLeft:'0'}}>
              <CustomInput type="checkbox" 
                id="exampleCustomCheckbox" 
                label="" 
                name="signUpAgree"
                id="signUpAgree"
                className="signUpAgree"
                checked={isAgree}
                onChange={() => setIsAgree(!isAgree)}
              />
              <Label className="signUpAgree-label">
                <span className="d-block">By signing up you agree to our <a href="https://www.invisibly.com/tos" className="text-decoration-none text-reset" target="_blank" rel="noreferrer">Terms of Service</a>
               </span> <span className="d-block"> and our <a href="https://www.invisibly.com/privacy-policy" className="text-decoration-none text-reset" target="_blank" rel="noreferrer">Privacy Policy</a>.</span>
              </Label>
            </FormGroup>

            <FormGroup className="actions">
              <Button
                className="in-btn-lg in-btn-primary"
                type="submit"
                onClick={handleSubmit}
                disabled={isValid && isAgree ? false : true}
                style={{minWidth:'157px'}}
              >
                 {isLoading ? <ADspinner size="large" /> : 'Sign up'}
              </Button>
            </FormGroup>
          </Form>

          <div className="sign-in-section">
            <span className="text">Already have an account?</span>
            <Button 
              className="in-btn-lg in-btn-secondary" 
              style={{backgroundColor: 'transparent', maxHeight: '45px',maxWidth: '140px',fontSize: '16px',padding: '10px 20px',marginLeft:'25px'}}
              onClick={()=>{ history.push(ROUTES.SIGNIN) }}
            > 
              Sign in
            </Button>
            {/*Mobile only button link*/}
            <Button 
              className="in-btn-link blue" 
              onClick={()=>{ history.push(ROUTES.SIGNIN) }}
            > 
              Already have an account? Sign in
            </Button>
          </div>
        </section>
      </section>
    </>
  );
};

export default SignUp;
