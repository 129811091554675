import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Navbar, Button,
  Row, Col, Card, CardBody, Label, Input
} from "reactstrap";
import "./index.scss";
import Loader from '../../components/Loader';
import { useParams, useLocation } from "react-router-dom";
import { clickableScript } from "../../utils/previewMessageClickableScript";
import { realtimeResearchActionCreator, offerActionCreator, surveyActionCreator } from "../../actions";
import PlatformToggle from "../../components/RealtimeResearch/RealTimeCampaign/PlatformToggle";

const RTRPreviewLink = ({ props_msg_id, props_brand_id, props_ad_id }) => {
  let { msg_id, brand_id, ad_id } = useParams();
  let location = useLocation();
  if (props_msg_id && props_brand_id && props_ad_id) {
    msg_id = props_msg_id
    brand_id = props_brand_id
    ad_id = props_ad_id
  }
  // const ad_id = new URLSearchParams(location.search).get('ad_id');

  const OrganizationLogo = "/Icon_assets/svg/invisibly-brands-logo.svg";
  const dispatch = useDispatch();
  const msgbodyStyle = { height: '100%', overflow: "auto" };
  const surveyStyle = { height: '90%', overflow: "auto", display: "inherit" };
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [frame, setFrame] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [shadow, setShadow] = useState("2px 8px 12px rgb(19 36 139 / 4%)");
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [message, setMessage] = useState("");
  const [mediaURL, setMediaURL] = useState("");
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");
  const [isSupportBoth, setIsSupportBoth] = useState(!!props_brand_id)

  const offerData = useSelector((state) => state.offer.offerData);
  const isLoading = useSelector((state) => state.offer.isLoading);
  const tableData = useSelector((state) => state.realtimeResearch.realtimeResearchData);
  const previewMessage = useSelector((state) => state.surveyData.externalPreview);

  const isPreviewFetching = useSelector(
    (state) => state.surveyData.isExternalPreviewLoading
  );

  window.closeSurvey = () => {
    setSurveyComplete(true);
  }

  const goToSurveyScreen = () => {
    // var params = {
    //   brands: brand_id,
    //   messages: message
    // };
    setActiveStep(2);
    // dispatch(surveyActionCreator.getPreviewRequest(params));
    var params = {};
    params.brands = brand_id;
    params.messages = message;
    params.ad_id = ad_id;
    if (params.brands && params.messages)
      dispatch(surveyActionCreator.getExternalPreviewRequest(params));
  }

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      setMessage(location.state.detail.message);
    }
  }, [location]);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (previewMessage) {
      const frames = previewMessage && previewMessage.previews && previewMessage.previews.previews &&
        previewMessage.previews.previews["frame_1"] ? previewMessage.previews.previews["frame_1"] : "";

      let keys = Object.keys(frames),
        sizes, width, height;

      let sizeKey = [];
      keys.map((value, index) => {
        sizeKey = value === "size" ? keys.splice(index, 1) : "";
        return sizeKey;
      });


      const previewHtml =
        frames[keys[0]] ? frames[keys[0]] + clickableScript : '<div style=" height:150px; width: 150px;font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';

      if (sizeKey.length > 0) {
        sizes = frames && frames.size ? frames.size : "";
        height = sizes && sizes.height ? sizes.height : "";
        width = sizes && sizes.width ? sizes.width : "";
      }


      setPreviewHtml(previewHtml);
    } else {
      const previewHtml = '<div  style=" font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';
      setPreviewHtml(previewHtml);
    }

  }, [previewMessage]);

  useEffect(() => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }, []);


  useEffect(() => {
    if (offerData) {
      setMediaURL(offerData.media_1_url);
    }
    if (offerData && offerData.ad) {
      setMessage(offerData.message);
      // dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({ message_id: offerData.ad }));
    }
  }, [offerData]);

  useEffect(() => {
    if (tableData) {
      setMessage(tableData.message);
      console.log(tableData)
    }
  }, [tableData]);


  const reload = () => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));

    params = {};
    params.brands = brand_id;
    params.messages = message;
    params.ad_id = ad_id;
    if (params.brands && params.messages)
      dispatch(surveyActionCreator.getExternalPreviewRequest(params));

    var iframe = document.getElementsByName('frame')[0];
    if (iframe)
      iframe.srcdoc = iframe.srcdoc;
    setSurveyComplete(false);
  }

  const handlePlatformChange = (selectedPlatform) => {
    console.log("selectedPlatform from App.js ---> ", selectedPlatform)
  }

  return (
    <>
      {!props_brand_id && (<>
        <Navbar light expand="md" className="navigation-section">
          <span className="logo-image-container center-logo">
            <a
              href="https://www.invisibly.com/home"
              target="_blank"
              rel="noreferrer"
            // onClick={navigateToDashboard}
            >
              <img
                src={OrganizationLogo}
                alt="Invisibly for brand logo"
                className="img-fluid"
                style={{ maxWidth: "176px", maxHeight: "28px" }}
              />
            </a>
          </span>
        </Navbar>

        <Row style={{ margin: 0 }}>
          <Col>
            {isSupportBoth && (<PlatformToggle
              data={{}}
              selectPlatform={0}
              handlePlatformChange={handlePlatformChange}
            />)}
          </Col>
        </Row>
      </>)}

      <Row style={{ margin: 0 }}>
        <Col>

          <div className="iframe-div" style={previewMessage.ad_rtr_type !== 1 ? surveyStyle : msgbodyStyle}>
            <div id="reload-survey" style={{ marginBottom: "20px" }}>
              <img onClick={reload}
                src="/Icon_assets/svg/reload-icon.svg"
                alt="share"
                className="link-image"
              />
              <span className="reload-survey" onClick={reload}>Reload Survey</span>
            </div>
            {
              isPreviewFetching ?
                <Loader />
                :
                activeStep === 0 ?
                  <section style={{ margin: "auto", padding: "10px", cursor: "pointer" }} >
                    <Card className="offer-card center-logo">
                      {isLoading ?
                        (<>
                          <CardBody className="feed-card-body is-details-loading">
                            <div className="loading-data-section offer-data-loading">
                              <Loader />
                            </div>
                          </CardBody>
                        </>)
                        :
                        <CardBody>
                          <div onClick={() => setActiveStep(1)}>
                            <section className="offer-wrapper">
                              <section className="header-banner-section">
                                <div className="card-img" id={`banner-${offerData.id}`}
                                  style={{
                                    backgroundImage: "url('" + mediaURL + "')",
                                    backgroundSize: "100%",
                                    maxHeight: "432px",
                                    minHeight: "120px",
                                    backgroundPosition: "center center",
                                    maxWidth: "240px",
                                    height: "135px",
                                    backgroundPostion: "center top"
                                  }}>
                                  <img src="/Icon_assets/png/arc.png" alt="arc"
                                    className="arc-style-img"
                                    style={{
                                      position: "absolute", bottom: "0px",
                                      width: "100%", left: "0px", display: "none"
                                    }} />
                                </div>
                              </section>
                              <section className="content-section survey-offer"
                                style={{ display: "block" }}>
                                <section className="brand-offer-title survey-offer">
                                  <nav className="brand-card-name">{offerData.type === 2 ? `Survey` : `Brand Offer`}
                                    <span className="brand-name" style={{ display: "none" }}>{offerData.brand_name}</span>
                                  </nav>
                                </section>
                                <nav className="title-part">
                                  <p id={`content-title${offerData.id}`}
                                    className="title-content">{offerData.title}
                                  </p></nav></section>
                            </section>
                            <section className="brand-offer-data-earn-option survey-offer">Earn
                              <img src="/Icon_assets/svg/Invisibly-token-purple-filled.svg" alt="Invisibly point" /> {offerData.monetary}
                            </section>
                          </div>
                        </CardBody>}
                    </Card>

                  </section>
                  :
                  activeStep === 1 ?
                    <div
                      // onClick={() => setActiveStep(2)}
                      className="feeds-details-wrapper">
                      <section className="feeds-details-section d-flex">
                        <div className="feeds-details-card w-100 survey-brand-card survey card">
                          <CardBody className="feed-card-body">
                            <div>
                              <div className="image-column">
                                <div className="feeds-image-container bg-white">
                                  <div className="selected-image-container">
                                    <div className="selected-image-wrapper">
                                      <div className="selected-image selected-video">
                                        <img src={mediaURL}
                                          alt="offer-title" className="selected-feed-image" />

                                        <div className="earn-badge-wrapper">
                                          <div className="earn-badge">
                                            <div className={`badge survey`}>
                                              Earn
                                              <img src={"/Icon_assets/svg/Invisibly-token-purple-filled.svg"}
                                                style={{ paddingLeft: "5px" }} alt="survey" className="badge-icon" />
                                              {offerData?.monetary}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="content-column" id="content-column">
                                <div className="feeds-content-container">
                                  <div className="feeds-content-container">
                                    <p title=""
                                      className="feeds-category card-text"><small className="feeds-category-text">
                                        Survey</small>
                                    </p>
                                    <h1 title="offer-title" className="feeds-title card-title">{offerData.title}</h1>
                                    <p className="feeds-description feeds-description-collapsed free-content survey card-text">{offerData.description}</p>
                                    <Button type="button" onClick={goToSurveyScreen}
                                      className="in-btn-sm in-btn-primary in-btn btn-secondary 
                                      btn-redirect btn-offer btn btn-secondary"> {offerData.button_text}</Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </div>
                      </section>
                    </div>
                    :
                    <div style={{ background: "#9d5cff" }}>
                      <Row>
                        <Col>
                          <div style={{
                            background: "#9d5cff", color: "#fff",
                            fontSize: "18px", height: "15%", paddingTop: "40px",
                            display: surveyComplete ? "none" : "flex",
                          }} className="survey-text">
                            <p>Survey</p>
                          </div>
                          <div className="iframe-div" style={msgbodyStyle}>
                            {
                              isPreviewFetching ?
                                <Loader />
                                :
                                <>
                                  <div className="container" style={{ display: surveyComplete ? "block" : "none" }}>
                                    <p className="vertical-center">Congratulations! You have completed the survey.</p>
                                  </div>

                                  <iframe frameBorder="0" title="previewIframe" name="frame"
                                    style={{
                                      width: `100%`,
                                      height: `100%`,
                                      boxShadow: `${shadow}`,
                                      minHeight: `620px`,
                                      display: surveyComplete ? "none" : "block",
                                    }}
                                    srcdoc={previewHtml} scrolling='no'>
                                  </iframe>
                                </>
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
            }
          </div>
        </Col>
      </Row>
    </>
  )
}

export default RTRPreviewLink;