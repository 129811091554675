
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonSection = () => {
  return (
    <section className='skeleton-section-wrapper'>
      <div className="skeleton-section">
        <SkeletonElement type="title" />
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonSection;