import React from "react";
import { Badge } from "reactstrap";

const ADBadge = ({ color = "dark", className, badgeName = "", closeIcon = true, closeCallback, index, style }) => {
  return(<>
    <Badge color={color} className={className} style={style}>
      {badgeName}
      {closeIcon && (
        <img 
          src="/Icon_assets/svg/close_icon.svg" 
          alt="close icon" 
          className="ml-2 close-icon"
          role="button"
          onClick={() => closeCallback(index, badgeName)}
        />
      )}
    </Badge>
  </>);
}

export default ADBadge;