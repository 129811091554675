import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";

import { notificationActionCreator } from "../../actions";
import "./index.scss";

const Notifications = () => {
    const [visible, setVisible] = useState(true);

    const {
        show,
        data: {
            color = 'danger',
            message = '',
            timeOut = true,
            time = 3000,
            position = 'absolute'
        }
    } = useSelector((state) => state.notification);
    const dispatch = useDispatch();

    if (show && timeOut) {
        setTimeout(() => {
            dispatch(notificationActionCreator.resetNotification());
        }, time);
    }

    const icons = {
        ERROR: '/Icon_assets/svg/error_icon.svg',
        SUCCESS: '/Icon_assets/svg/succes_icon.svg',
        EXCLAIMATORY: '/Icon_assets/svg/exclaimatory-icon.svg',
        INFO:'/Icon_assets/svg/notification-icon.svg'
    }

    if (!message) {
        return <></>;
    }

    return (
        <>
            {window.scrollTo(0, 0)}
            <Alert color={color} isOpen={visible} style={{ position: `${position === "absolute" ? "absolute" : position}` }}>
                <img src={color === 'success' ? icons.SUCCESS :
                    color === "warning" ? icons.EXCLAIMATORY :
                        color === "info" ? icons.INFO
                            : icons.ERROR} alt="Notification Icon" className="notification-icon" />
                <span dangerouslySetInnerHTML={{ __html: message }}></span>
            </Alert>
        </>
    );
}

export default Notifications;