import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const RemoveUserModal = ({ closeModal, yesClicked, cancelRemoveUserModal }) => {
  return (
    <section className="remove-user-content">
      <nav className="remove-user-section">
        <p> ‘Invisibly Admin’ includes all permissions for all organizations. Therefore, other selected roles will be removed.</p>
      </nav>
      <nav className="btn-wrapper remove-user">
        <Button className="in-btn-sm in-btn-primary" onClick={yesClicked}>
          Yes
        </Button>
        <Button color="link" className="in-btn-link" onClick={cancelRemoveUserModal}>
          Cancel
        </Button>
      </nav>
    </section>
  )
}

export default RemoveUserModal;