import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  Label,
  Input,
  UncontrolledTooltip,
  Spinner,
  FormFeedback
} from "reactstrap";
import Loader from "../../Loader";

import "./index.scss";

const DimensionFilter = (props) => {
  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/black_circle_close_icon.svg"
  }

  const [dimensionsFilterDropdownOpen, setDimensionsFilterDropdownOpen] = useState(false);
  const toggleDimensionsFilter = () => setDimensionsFilterDropdownOpen(prevState => !prevState);
  const [filterDimensionsDropdownOpen, setFilterDimensionsDropdownOpen] = useState(false);
  const toggleFilterDimensions = () => setFilterDimensionsDropdownOpen(prevState => !prevState);
  const [dimension, setDimension] = useState();
  const [dimensionsOptions, setDimensionsOptions] = useState(props?.OptionsList);

  const [searchKey, setSearchKey] = useState('');

  const handleSearchKey = (e, dimensionId, currentIndex) => {
    setSearchKey(e.target.value);
    props.dimensionsFilterSearch(e, dimensionId, currentIndex);
  }

  useEffect(() => {
    if (!filterDimensionsDropdownOpen) {
      setSearchKey('');
    }
  }, [filterDimensionsDropdownOpen])

  return (<>
    <Row className="mt-2 filter-container" id={`dimensions-filter-row-${props?.index}`}>
      <Col md={6} sm={12} lg={3}>
        <div className="option-selector dimension-wrapper">
          <Dropdown
            isOpen={dimensionsFilterDropdownOpen}
            toggle={toggleDimensionsFilter}
            className="dropdown-option metrics"
            direction="down"
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              className="dropdown_toggle"
            >
              <span
                className="dropdown_title"
                title="Select dimension"
              >
                {props?.filter?.dimension ? props?.filter?.dimension?.value : "Select Filter"}
              </span>
              <img
                src={
                  dimensionsFilterDropdownOpen
                    ? statusIcons.ARROW_UP
                    : statusIcons.ARROW_DOWN
                }
                className="dropdown-img"
              ></img>
            </DropdownToggle>
            <DropdownMenu>
              <div className="metrics-list">
                {dimensionsOptions && (
                  dimensionsOptions.map((item, index) => {
                    return (
                      <DropdownItem
                        key={"dimension" + item?.id}
                        id={"dimension" + item?.value + index}
                        onClick={(e) => props.handleReportChange('dimension', item, props?.index, "dimensionFilter")}
                        className={`${item?.id === props?.filter?.dimension?.id ? "dropdown-option-selected" : ""}`}
                      >
                        <div className="options-container">
                          <Label className="custom-control-label">
                            {item?.value}
                          </Label>
                          {item?.id === props?.filter?.dimension?.id ? (<img
                            src={statusIcons?.CHECKED_BLUE_ICON}
                            alt="dimension selected"
                            className="option-selected"
                          />) : ""}
                        </div>
                      </DropdownItem>
                    )
                  })
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
          <FormFeedback>{props?.filter?.errorMsgDimension}</FormFeedback>
        </div>
      </Col>
      {props?.filter?.dimension && (
        <Col md={6} sm={12} lg={3}>
          <div className="option-selector dimension-wrapper search-dropdown">
            <Dropdown
              isOpen={filterDimensionsDropdownOpen}
              toggle={toggleFilterDimensions}
              className="dropdown-option"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                className="dropdown_toggle"
              >
                <span
                  className="dropdown_title"
                  title="Select range"
                >
                  <Input
                    value={searchKey}
                    type="text"
                    name="search"
                    id="search"
                    className="value-input"
                    autoComplete="off"
                    onChange={(e) => handleSearchKey(e, props?.filter?.dimension, props?.index)}
                    placeholder={props?.filter?.value?.length > 0 ? `${props?.filter?.value?.length} ${props?.filter?.dimension?.value}${props?.filter?.value?.length > 1 ? 's' : ''} Selected` : props.filter.dimension.value}
                  />
                  {/* {props.filter.isLoading ? <Spinner color="dark" size="sm" className="ml-2 reportbuilder-loader" /> : "" } */}
                </span>
                <img
                  src={
                    filterDimensionsDropdownOpen
                      ? statusIcons.ARROW_UP
                      : statusIcons.ARROW_DOWN
                  }
                  className="dropdown-img"
                ></img>
              </DropdownToggle>
              <DropdownMenu>
                {searchKey.length === 0 ? (<DropdownItem>Please enter 1 more character</DropdownItem>) :
                  !props?.filter?.isLoading ?
                    props?.filter?.dropdownOptions?.length > 0 && searchKey.length !== "" ? (
                      props?.filter?.dropdownOptions.map((item, index) => {
                        return (
                          <DropdownItem
                            toggle={false}
                            key={"dimensions" + item.id + index}
                            id={"dimensions" + item.id + index}
                            className={`${props?.filter?.value.findIndex((element) => element.id === item.id) > -1 ? "dropdown-option-selected" : ""}`}
                          >
                            <div className="options-container">
                              <CustomInput
                                className={`ad-transparent-checkbox`}
                                type="checkbox"
                                name={item.id}
                                value={item.name}
                                id={item.id}
                                label={item.name}
                                checked={props?.filter?.value.findIndex(
                                  (element) => element.id === item.id) > -1 ? true : false
                                }
                                onChange={(e) => props.handleReportChange("dimensionFilterValues", item, props.index, "dimensionFilter", e)}
                              />

                              {props?.filter?.value.findIndex((element) => element.id === item.id) > -1 ? (<img
                                src={statusIcons.CHECKED_BLUE_ICON}
                                alt="dimension selected"
                                className="option-selected"
                              />) : ""}
                            </div>
                          </DropdownItem>
                        )
                      })
                    ) :
                      !props?.filter?.isLoading && (<DropdownItem>No matches found</DropdownItem>)
                    :
                    (
                      <Loader />
                    )
                }
              </DropdownMenu>
            </Dropdown>
            <FormFeedback>{props.filter.errorMsgValue}</FormFeedback>

            <div className={`selected-options ${props.filter.value.length ? "ad-mt-16" : ""}`}>
              {props.filter.value.length <= 2 ?
                (props.filter.value.slice(0, 2).map((item, index) => {
                  return (
                    <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                      {item.name || item.rule_name}
                      <img
                        src="/Icon_assets/svg/close_icon.svg"
                        alt="close icon"
                        className="ml-2 close-icon"
                        onClick={(e) => props.removeSelectedFilterDimensions(e, index, props.index)}
                      />
                    </span>
                  );
                })) :
                (<><span className="badge badge-dark selected-badge" id={`badgeFor${props.index}`}>
                  {`${props.filter.dimension.value}s`}
                  <img
                    src="/Icon_assets/svg/close_icon.svg"
                    alt="close icon"
                    className="ml-2 close-icon"
                    onClick={(e) => props.removeAllFilterDimensions(e, props.index)}
                  />
                </span>
                  <UncontrolledTooltip
                    className="ad-tool-tip"
                    target={`badgeFor${props.index}`}
                    placement="bottom"
                    style={{ maxWidth: "300px" }}
                  >{props.filter.value.map((item) => item.name).join(", ")}
                  </UncontrolledTooltip>
                </>)
              }
              {props?.filter?.value?.length > 0 ? (<span><Button className="ad-btn-link" onClick={(e) => props?.removeAllFilterDimensions(e, props.index)}>Clear all</Button></span>) : ""}
            </div>

          </div>
        </Col>
      )}

      <Col md={6} sm={12} lg={3}>
        <div className="toggle-wrapper">
          <div className="toggle-buttons-wrapper">
            <ButtonGroup className="toggle-buttons">
              <Button
                className={"in-btn-primary btn-toggle"}
                onClick={() => props?.handleReportChange('dimensionFilterOption', 0, props?.index, 'dimensionFilter')}
                active={props?.filter?.option === 0}
              >
                Include
              </Button>
              <Button
                className={"in-btn-primary btn-toggle"}
                onClick={() => props?.handleReportChange('dimensionFilterOption', 1, props?.index, 'dimensionFilter')}
                active={props?.filter?.option === 1}
              >
                Exclude
              </Button>
            </ButtonGroup>
          </div>
          <div className="close-button-wrapper">
            <img
              src={statusIcons.CLOSE_ICON}
              alt="Close Icon"
              className="close-filter"
              onClick={() => { props.removeFilter(props?.index, 'dimension') }}
              id={`remove-dimention-filter-${props?.index}`}
            />
            <UncontrolledTooltip
              className="ad-tool-tip"
              placement="top"
              target={`remove-dimention-filter-${props?.index}`}
            >{"Remove filter"}
            </UncontrolledTooltip>
          </div>
        </div>
      </Col>
      <Col md={6} sm={12} lg={3}>
      </Col>
    </Row>
  </>)
}

export default DimensionFilter;