import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback,
  Table, Row, Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import ADspinner from "../../Loader/ADspinner";
import history from "../../../history";
import ROUTES from "../../../utils/routeConstants";

import { brandsActionCreator, notificationActionCreator, organizationActionCreator } from "../../../actions"

import Validator from "../../../utils/validators";
import "./index.scss";
import SkeletonTable from "../../Loader_Skeleton/SkeletonTable";
import SkeletonModal from "../../Loader_Skeleton/SkeletonModal";
import CLIENT_SIDE_MESSAGES from "../../../utils/clientSideMessages";
import debounce from "../../../utils/debounce";
import InfiniteScroll from 'react-infinite-scroll-component';

const Brand = ({ type = "save", setOpenPopUp, brandData, closeModal, from = "", updateBrandDetails }) => {
  const validatorsObj = new Validator();
  const dispatch = useDispatch();

  //save = POST & update = PUT
  const method = (type === "save") ? "POST" : "PUT";

  const ICONS = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CAMERA: "/Icon_assets/svg/camera-icon.svg",
    DELETE: "/Icon_assets/svg/delete-icon.svg",
  }

  /*redux state start*/
  const isLogoUploading = useSelector((state) => state.brands.isLogoUploading);
  const isLogoUploaded = useSelector((state) => state.brands.isLogoUploaded);
  const logoURL = useSelector((state) => state.brands.logoURL);

  const organizationLoading = useSelector((state) => state.organization.isLoading);
  const organizationData = useSelector((state) => state.organization.organizationData?.results);
  const organizationCount = useSelector((state) => state.organization.organizationData?.count);
  const organizationNextList = useSelector((state) => state.organization.organizationData?.next);
  const savedBrand = useSelector((state) => state.brands.createdBrand);
  const isSaving = useSelector((state) => state.brands.isSaving);
  const isFetching = useSelector((state) => state.brands.isFetching);

  /*redux state end*/

  const [isLogoUpdated, setisLogoUpdated] = useState(false);
  const [validators, setstate] = useState(validatorsObj);
  const initBrand = { brand_name: "", url: "", logo: "", organization: "" };
  const [inputValues, setInputValues] = useState(initBrand);
  const [organizationName, setOrganizationName] = useState("");

  const [selectedOrganization, setSelectedOrganization] = useState();
  const [dropdown, setDropdown] = useState(false);
  const toggledropdown = () => {
    setDropdown(prevState => !prevState)
    if (!dropdown) {
      dispatch(organizationActionCreator.getOrganizationListRequest({ limit: 10, offset: 0 }));
      setOrgList([]);
      setOffSet(0);
    }
  };

  const [orgList, setOrgList] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [isValid, setIsValid] = useState(false);


  //check validations
  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };


  //onload
  useEffect(() => {
    setstate(validatorsObj);
    dispatch(brandsActionCreator.saveBrandRequestFailureClear())
  }, [])

  //on update
  useEffect(() => {
    if (organizationData && organizationData.length > 0) {
      const newList = orgList.concat(organizationData);
      setOrgList(newList);
    }

  }, [organizationData, organizationCount])

  useEffect(() => {
    if (isLogoUploaded) {
      setInputValues({ ...inputValues, logo: logoURL })
      // setisLogoUpdated(true);
    }
  }, [isLogoUploaded])

  useEffect(() => {
    let inputFields = {};
    if (brandData
      && Object.keys(brandData).length !== 0) {
      inputFields["id"] = brandData.id;
      inputFields["brand_name"] = brandData.name;
      updateValidations("brand_name", brandData.name);
      inputFields["url"] = brandData.urls[0];
      updateValidations("url", brandData.urls[0]);
      inputFields["logo"] = brandData.logo;
      // setisLogoUpdated(true);
      setSelectedOrganization(brandData.organization?.[0]);
      setInputValues(inputFields);
      const checkValidation = (validators.brand_name.valid && validators.url.valid && (brandData.organization?.[0]?.name !== ""));
      setIsValid(checkValidation);
    }
  }, [brandData, orgList])

  //hanlde form value change
  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = from === "" ?
      validators.brand_name.valid && validators.url.valid && selectedOrganization?.name
      : validators.brand_name.valid && validators.url.valid;
    setIsValid(checkValidation);
  }

  const handleUploadFile = (e) => {
    if (e.target.files.length !== 0) {
      if ((e.target.files[0].size / (1024 * 1024)).toFixed(2) <= 2.5) {
        dispatch(brandsActionCreator.uploadFile(e.target.files[0]));
      } else {
        dispatch(notificationActionCreator.setNotification({
          message: CLIENT_SIDE_MESSAGES.IMAGE_MAX_SIZE
        }));
      }
    }
  }

  //handle organization select
  const handleorganizationselect = (e, organization) => {
    e.preventDefault()
    setSelectedOrganization(organization)
    setOrganizationName(organization?.name)
    const checkValidation = validators.brand_name.valid && validators.url.valid && organization?.name;
    setIsValid(checkValidation);
  }

  //handle organization search
  const handleOrganizationSearch = (e) => {
    e.preventDefault();
    setDropdown(true)
    setOrganizationName(e.target.value)
    setOrgList([]);
    setOffSet(0);
    debounceCallSearch(e)
  }

  /**handle debounce functionality start**/
  const handleSearch = (e) => {
    var params = { limit: 10, search: e.target.value }
    dispatch(organizationActionCreator.getOrganizationListRequest(params));
  }

  const debounceCallSearch = useCallback(debounce(handleSearch), [])
  /**handle debounce functionality end**/

  const deleteLogo = (e) => {
    e.preventDefault()
    setInputValues({ ...inputValues, logo: "" })
    dispatch(brandsActionCreator.resetBrandLogo())
  }

  //Form submit
  const handleFormSubmit = (e) => {
    const payload = {};
    payload.name = inputValues.brand_name;
    payload.urls = [inputValues.url];
    if (logoURL) {
      payload.logo = inputValues.logo;
    }
    if (from === "") {
      payload.organization_id = selectedOrganization.id
      payload.method = method;
      if (method === 'PUT') {
        payload.id = inputValues.id;
      }
      dispatch(brandsActionCreator.saveBrandRequest(payload))
    }
    // if advertiser is added from create organization flow
    if (from === "organization") {
      // call callback from orgs
      payload.id = Math.floor((Math.random() * 100) + 1);
      payload.selected = true;
      updateBrandDetails(payload);
      dispatch(brandsActionCreator.resetBrandLogo())
    }
  }

  useEffect(() => {
    if (savedBrand && Object.keys(savedBrand).length !== 0
      && Object.getPrototypeOf(savedBrand) === Object.prototype) {
      dispatch(brandsActionCreator.saveBrandRequestFailureClear());
      history.push(ROUTES.BRANDS_LIST);
      //callback of parent to close popup
      setTimeout(closeModal()
        , 1000)
    }

  }, [savedBrand])

  const fetchMoreData = () => {
    let params = {};
    params.limit = 10;
    params.offset = offset + 10;
    if (organizationName.length) {
      params.search = organizationName;
    }
    dispatch(organizationActionCreator.getOrganizationListRequest(params));
    setOffSet(prevState => prevState + 10);
}

  return (<>
    <div className="brand-container mt-2">
      <div className="brand-wrapper">
        {isFetching ? <SkeletonModal style={{ minHeight: "450px" }} fieldStyle={{ minHeight: "80px" }} /> : (<>
          <div
            className="form-container ad-form"
            onSubmit={handleFormSubmit}
          >
            <Row className="fields-row">
              <Col md={12} sm={12} lg={12}>
                <FormGroup className="pb-3">
                  <Label className="field-name">Name <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    name="brand_name"
                    id="brand_name"
                    className="form-input form-input-sm mt-2"
                    autoComplete="off"
                    value={inputValues.brand_name}
                    onChange={handleInputChange}
                    invalid={validators.brand_name.errorMsg !== ""}
                  />

                  <FormFeedback>{validators.brand_name.errorMsg}</FormFeedback>
                </FormGroup>

                <FormGroup className="pb-3">
                  <Label className="field-name">URL <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    name="url"
                    id="url"
                    className="form-input form-input-sm mt-2"
                    autoComplete="off"
                    value={inputValues.url}
                    onChange={handleInputChange}
                    invalid={validators.name.errorMsg !== ""}
                  />
                  <FormFeedback>{validators.url.errorMsg}</FormFeedback>
                </FormGroup>

                <FormGroup className="custom-file-upload-wrapper">
                  <Label className="file-label field-name">
                    Logo (Optional)
                </Label>
                  <div className="custom-file-upload-container">
                    <div className="upload-text">
                      {isLogoUpdated || isLogoUploaded ? "Reload" : "Upload"}
                    </div>
                    <div className="images-container">
                      {isLogoUploading ? (
                        <Spinner
                          color="secondary"
                          size="sm"
                          className="mr-2"
                        />
                      ) : (<>
                        <img
                          src={inputValues.logo ? inputValues.logo : ICONS.CAMERA}
                          alt="Camera Icon"
                          className="user-profile-picture mr-2 rounded"
                          id="camera"
                        />
                      </>
                      )}
                      {(isLogoUploaded || isLogoUpdated) && (<>
                        <img src={ICONS.DELETE} style={{ cursor: "pointer" }} onClick={deleteLogo} alt="Info icon" id="openDeleteTooltip" />
                        <UncontrolledTooltip
                          className="ad-tool-tip"
                          placement="top"
                          target="openDeleteTooltip"
                        >
                          Delete
                      </UncontrolledTooltip>
                      </>)}

                    </div>
                  </div>
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    className="custom-file-upload-input"
                    title=""
                    onChange={(e) => handleUploadFile(e)}
                  />
                </FormGroup>

                {from === "organization" ?
                  <div className="org-info">This advertiser will be created under the organization being added.</div>
                  :
                  <FormGroup className={`pb-3 ${type === "update" ? "disable-platform" : ""}`}>
                    <Label className="field-name">Organization <span className="text-danger">*</span></Label>
                    <div className="option-selector">
                      <Dropdown
                        key={`DropDown0`}
                        isOpen={dropdown}
                        toggle={toggledropdown}
                        className="dropdown-option"
                      >
                        <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                          className="dropdown_toggle"
                        >
                          <span
                            className="dropdown_title"
                            title={"Select template"}
                          >
                            <Input
                              value={organizationName}
                              type="text"
                              name="name"
                              id="name"
                              className="value-input search-organization"
                              autoComplete="off"
                              onChange={(e) => handleOrganizationSearch(e)}
                              placeholder={(selectedOrganization) ? selectedOrganization.name : "Select Organization"}
                              onBlur={() => setOrganizationName("")}
                            />
                          </span>
                          {type !== "update" && (<img
                            src={
                              dropdown
                                ? ICONS.ARROW_UP
                                : ICONS.ARROW_DOWN
                            }
                            className="dropdown-img"
                            alt="caret"
                          />)}
                        </DropdownToggle>
                        <DropdownMenu>
                          <div className="item-container" id="brandorganizationDropDown">
                            {(organizationLoading && (orgList.length === 0)) ?
                              (<DropdownItem style={{ padding: "5px 0 5px 10px", maxHeight: "240px" }}><SkeletonTable showLine={false} /></DropdownItem>) :
                              (!organizationLoading && organizationCount === 0) ?
                                (<DropdownItem>No result found</DropdownItem>) :
                                ((orgList && orgList.length > 0) && (<InfiniteScroll
                                  dataLength={organizationCount}
                                  next={fetchMoreData}
                                  hasMore={organizationNextList !== null}
                                  loader={<DropdownItem style={{ padding: "5px 0 5px 10px", maxHeight: "240px" }}><SkeletonTable showLine={false} /></DropdownItem>}
                                  scrollableTarget="brandorganizationDropDown"
                                >
                                  {
                                    orgList && orgList.length && orgList.map((item) => {
                                      return (
                                        <DropdownItem
                                          key={item.id}
                                          onClick={(e) => handleorganizationselect(e, item)}
                                          className={`d-flex justify-content-between align-items-center ${item.id === selectedOrganization?.id ? "dropdown-option-selected" : ""}`}
                                        >
                                          <span className="template-name" title={item.name}>{item.name}</span>
                                          {item.id === selectedOrganization?.id ? (<img
                                            src={ICONS.CHECKED_BLUE_ICON}
                                            alt="dimension selected"
                                            className="option-selected"
                                          />) : ""}
                                        </DropdownItem>
                                      )
                                    })
                                  }
                                </InfiniteScroll>))
                            }
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <FormFeedback>{validators.name.errorMsg}</FormFeedback>
                  </FormGroup>
                } </Col>
            </Row>

            <Row className="action-row">
              <Col md={12} sm={12} lg={12}>
                <div className="actions">
                  <Button
                    className="in-btn-sm in-btn-info btn-save"
                    type="submit"
                    disabled={isValid ? false : true}
                    onClick={handleFormSubmit}
                    tabIndex='6'
                  >
                    {isSaving ? <ADspinner size="large" /> : type === "update" ? 'Update' : 'Save'}
                  </Button>
                  <Button
                    className="in-btn-link btn btn-link btn-clear btn-cancel"
                    type="submit"
                    onClick={(e) => {
                      setOpenPopUp(false);
                      dispatch(brandsActionCreator.resetBrandLogo())
                    }}
                    tabIndex='7'
                  >
                    {'Cancel'}
                  </Button>
                </div>
              </Col>
            </Row>

          </div>
        </>)}
      </div>
    </div>
  </>)
}

export default Brand;