import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import {
  Button, FormGroup,
  Modal, ModalBody, ModalHeader
} from "reactstrap";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

const LeaveOfferModal = ({ display, closePopup }) => {

  const clearcloseBtn = (
    <button className="close" style={{ opacity: "1" }} onClick={closePopup}>
      <img src={"/Icon_assets/svg/close_icon_black.svg"} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>);

  useEffect(() => {
    console.log(display);
  }, [display])

  return (
    <Modal isOpen={display} className="ad-pop-up-modal rtr-export-modal" centered>
      <ModalHeader close={clearcloseBtn} style={{ color: "#0E0E0F" }}>Leave Offer</ModalHeader>
      <ModalBody>
        <div style={{ color: "#000000" }}>
          Are you sure you want to leave without saving?</div>
        <FormGroup className="text-left" style={{ marginTop: "32px" }}>
          <Button
            className="in-btn-sm in-btn-primary mr-4 export-button"
            disabled={false}
            onClick={closePopup}
            style={{ minWidth: '96px' }}
          >
            Cancel
      </Button>
          <Button
            className="in-btn-link btn-modal-cancle btn btn-link"
            disabled={false}
            onClick={() => history.push(`${ROUTES.OFFERS_LIST}`)}
          >
            Yes, I'm sure
    </Button>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
};

export default LeaveOfferModal;