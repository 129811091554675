import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, CustomInput, Input } from 'reactstrap'
import { filtersActionCreator } from '../../../../../actions'
import InfiniteScroll from 'react-infinite-scroll-component'
import SkeletonTable from '../../../../Loader_Skeleton/SkeletonTable'
import './index.scss'



const statusIcons = {
  ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
  ARROW_DOWN: "/Icon_assets/svg/arrow-down-filter.svg"
}

const CityFilter = (props) => {
  const dispatch = useDispatch()
  const cities = useSelector(state => state.filters.cities.results)
  const isCitiesNext = useSelector(state => state.filters.cities.next)
  const citiesCount = useSelector(state => state.filters.cities.count)
  const isCitiesLoading = useSelector(state => state.filters.isCitiesLoading)

  const [selectedCities, setSelectedCities] = useState([]);
  const [isCityFilterOpen, setIsCityFilterOpen] = useState(false)
  const [dropDownData, setDropdownData] = useState([]);
  const [offset, setOffset] = useState(0)
  const [searchText, setSearchText] = useState('');

  const toggleCityFilter = () => {
    setIsCityFilterOpen(prev => !prev)
    if (!isCityFilterOpen) {
      setDropdownData(prev => [...selectedCities, ...getSelectedFilteredData(prev)])
      handleCitiesScroll()
    }
    else {
      setOffset(0)
      setDropdownData([])
      props.setSelectedCities(selectedCities)
    }
  }


  const getSelectedFilteredData = (data) => {
    selectedCities.forEach((selectedCity => {
      data = data.filter(d => d.id !== selectedCity.id)
    }))
    return data;
  }

  const handleCitiesScroll = (search) => {
    const params = {}
    if (search) {
      params.search = search
      params.limit = 500;
    }
    else {
      params.limit = 50;
      params.offset = offset * 50
      setOffset(prev => prev + 1)
    }
    dispatch(filtersActionCreator.getCitiesRequest(params))
  }

  useEffect(() => {
    if (cities?.length)
      setDropdownData(prev => [...prev, ...getSelectedFilteredData(cities)])
  }, [cities])

  const clearFilter = (e) => {
    e.stopPropagation();
    props.setSelectedCities([]);
    setSelectedCities([])
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    if (searchText.length >= 0) {
      setOffset(0)
      handleCitiesScroll(searchText)
      setDropdownData([...selectedCities])
    }
  }, [searchText])

  const handleCitySelect = (id, label) => {
    if (selectedCities.some(city => city.id === id))
      setSelectedCities(selectedCities.filter(val => val.id !== id))
    else
      setSelectedCities(prev => [...prev, { id, label }])
  }

  useEffect(() => {
    if (props.isDisplayFilterRemoved)
      setSelectedCities(props.selectedCities)
  }, [props.selectedCities])

  return (
    <div className='ad-city-filter-container'>
      <Dropdown className="d-flex" isOpen={isCityFilterOpen} toggle={toggleCityFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div>
              <span className='dt-title'> City</span>
              {selectedCities?.length > 0 && <span className='filter-count'> {selectedCities?.length}</span>}
            </div>
            <div>
              {selectedCities?.length > 0 && <img style={{ display: "inline-block" }}
                onClick={(e) => clearFilter(e)}
                toggle={false}
                src={"/Icon_assets/svg/close-image.svg"}
                className="arrow-down"
                alt='city-filter'
              />}
              <img style={{ display: "inline-block", margin: '0 12px', transform: isCityFilterOpen ? 'rotate(180deg)' : '' }}
                src={
                  statusIcons.ARROW_DOWN
                }
                className="arrow-down"
                alt='city-filter'
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu toggle={false}>
          <div className='city-list-container'>
            <div style={{ marginBottom: '14px', display: 'flex' }} >
              <Input
                type='Text'
                className='search form-control'
                onChange={(e) => handleSearch(e)}
                value={searchText}
              ></Input>
              <img
                src="/Icon_assets/svg/search-organization-icon.svg"
                alt="Search"
                className="search-image"
              />
            </div>
            <div id="citiesDropDown" className='city-list'>
              <InfiniteScroll
                dataLength={isCitiesLoading === false ? citiesCount : 0}
                next={() => handleCitiesScroll()}
                hasMore={isCitiesNext !== null}
                loader={<SkeletonTable rows={10} />}
                scrollableTarget="citiesDropDown"
                endMessage={<div>{dropDownData?.length === 0 ? "No City Found" : ""}</div>}
              >
                {!isCitiesLoading ? dropDownData?.map((item) => <DropdownItem toggle={false}>
                  <div style={{ height: '40px' }}>
                    <CustomInput
                      style={{ alignSelf: 'center' }}
                      type="checkbox"
                      key={'checkbox-select' + item.id}
                      id={item.id}
                      checked={selectedCities.some(city => city.id === item.id)}
                      onChange={() => handleCitySelect(item.id, item.label)}
                      label={item.label}
                    />

                  </div>
                </DropdownItem>

                ) :
                  <SkeletonTable rows={10} />
                }
              </InfiniteScroll>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default CityFilter