import { takeLatest, call, select, put } from "redux-saga/effects";
import { realtimeResearchActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function getrealtimeResearchListRequest(API_URL_PARAM, jwtToken, params, payload) {
  //const API_URL = (payload.user === 0) ? API_URL_PARAM + "/v1/ads" : API_URL_PARAM + "/v1/ads?organization_id=" + payload.organization_id;
  let API_URL = API_URL_PARAM + "/v1/ads";
  if (payload.message_id) {
    API_URL = API_URL_PARAM + "/v1/ads/" + payload.message_id;
  }

  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      'Authorization': jwtToken
    }
  }).catch((err) => {
    console.log(err);
  });
}
//Table data ads
function* getrealtimeResearchListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const user_role = userData?.organization_role;
    let jwtToken = userData && userData.token ? userData.token : null;
    let params = { source: 1, limit: 10, type: 1, platform_value: 1 };
    if (action.payload && action.payload.name) {
      params.name = action.payload.name;
    }
    if (action.payload && action.payload.offset) {
      params.offset = action.payload.offset;
    }
    if (action.payload && action.payload.organization_id && action.payload.organization_id.length) {
      params.organization_id = Array.isArray(action.payload.organization_id) ? action.payload.organization_id.join() : action.payload.organization_id;
    }
    if (action.payload && action.payload.brand_id && action.payload.brand_id.length) {
      params.brand_id = action.payload.brand_id.join();
    }

    if (action.payload && action.payload.rtr_type !== undefined && action.payload.rtr_type.length === 1) {
      params.rtr_type = (action.payload.rtr_type[0] && action.payload.rtr_type[0].toString()) ? action.payload.rtr_type[0] : action.payload.rtr_type
    } else if (action.payload && action.payload.rtr_type !== undefined) {
      params.rtr_type = action.payload.rtr_type.toString()
    }

    if (action.payload && action.payload.sortBy) {
      params.ordering = action.payload && action.payload.sortOrder === 'desc' ? '-' + action.payload.sortBy : action.payload.sortBy;
    }

    const result = yield call(getrealtimeResearchListRequest, API_URL, jwtToken, params, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.getRealtimeResearchListSuccess(item, status &&
        status.status_message && status.status_message !== "Request Successful" ?
        status.status_message : ""));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getRealtimeResearchListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getRealtimeResearchListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    //yield put(realtimeResearchActionCreator.getRealtimeResearchListFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function setRTRSurveyDataRequest(API_URL, JWTToken, payload) {
  API_URL = API_URL + `/v1/brands/${payload.brand_v2}/messages/${payload.id}`;
  delete [payload.brand_v2]
  delete [payload.id]
  const data = payload;
  return axios({
    url: API_URL,
    method: "PUT",
    data,
    headers: {
      'Authorization': JWTToken
    }
  }).catch((err) => {
    console.log(err);
  });
}

function* setRTRSurveyDataSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;

    const result = yield call(
      setRTRSurveyDataRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {

      yield put(realtimeResearchActionCreator.setRTRSurveyDataSuccess(item));

      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: `RTR Survey Data uploaded successfully.`
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.setRTRSurveyDataFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.setRTRSurveyDataFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.setRTRSurveyDataFailure(error));
  }
}

function sendRTRListRequest(API_URL_PARAM, jwtToken, data) {
  //const API_URL = API_URL_PARAM + "/v1/brands?source=1&limit=100";
  let API_URL = API_URL_PARAM + "/v1/brands";
  let param = {};
  let encodeString = "";
  param.source = 1;
  param.limit = 100;

  if (data && data.brand_name) {
    API_URL = API_URL_PARAM + "/v1/brands/" + data.brand_name;
  }

  if (data && data?.organization) {
    param.organization_id = data.organization
  }

  if (data && data?.searchKey) {
    encodeString = data.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    param.search = encodeString;
  }

  if (data && data.limit) {
    param.limit = data.limit;
  }
  if (data && data.offset) {
    param.offset = data.offset;
  }

  if (data && data?.organization_id) {
    param.organization_id = Array.isArray(data.organization_id) ? data.organization_id.join() : data.organization_id;
  }

  return axios({
    url: API_URL,
    method: "GET",
    params: param,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendRTRListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendRTRListRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(realtimeResearchActionCreator.rtrListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        //yield put(realtimeResearchActionCreator.rtrListFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        //yield put(realtimeResearchActionCreator.rtrListFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    //yield put(realtimeResearchActionCreator.rtrListFailure("Server Error"));
    yield put(
      notificationActionCreator.setNotification({
        message: "Server Error"
      })
    );
  }
}


function getRealTimeResearchDetailsRequest(API_URL_PARAM, jwtToken, payload) {
  const zipcode = payload.zipcode;
  let statData = {};

  const dimension = payload.platform === 2 ? 2 : 3 //3 is default
  const dimensionValue = payload.platform === 2 ? payload.adset_id : payload.ad_id // ad_id is default

  if (zipcode) {
    statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3, 4], "metrics": [74, 75, 76, 79], "interval": 8, "dimension_filters": [{ "dimension": dimension, "value": [dimensionValue], "option": 0 }], "metric_filters": [], "other_metric_filters": {} } }
  } else {
    statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3, 4], "metrics": [74, 75, 76, 79], "interval": 8, "dimension_filters": [{ "dimension": dimension, "value": [dimensionValue], "option": 0 }], "metric_filters": [] } }
  }

  let API_URL = (payload.user === 0) ?
    API_URL_PARAM + "/v1/brands/" + payload.id + "/reporting/run"
    : API_URL_PARAM + "/v1/organizations/" + payload.organization_id + "/reporting/run";


  let params = {}

  if (payload.answer) {
    params.answer = payload.answer.join()
  }

  if (payload.state) {
    params.state = payload.state
  }

  if (payload.city) {
    params.city = payload.city
  }

  if (payload.device_type) {
    params.device_type = payload.device_type
  }

  if (payload.gender) {
    params.gender = payload.gender
  }

  if (payload.age_range) {
    params.age_range = payload.age_range
  }

  if (payload.zipcode) {
    params.zip_code = Array.isArray(payload.zipcode) ? payload.zipcode.join() : payload.zipcode
  }

  return axios({
    url: API_URL,
    method: "POST",
    params,
    data: statData,
    headers: {
      'Authorization': jwtToken
    }
  }).catch((err) => {
    console.log(err);
  });
}


function* getRealTimeResearchDetailsRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getRealTimeResearchDetailsRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      const data = parseJSONResponse(item);
      yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsSuccess(data));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function getRealtimeResearchDetailsStatRequest(API_URL_PARAM, jwtToken, payload) {
  const zipcode = payload.zipcode;
  //const statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3], "metrics": [77, 78], "interval": 8, "dimension_filters": [{ "dimension": 3, "value": [payload.ad_id], "option": 0 }], "metric_filters": [] } };
  // const statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": "2021-01-05 00:00:00", "end_date": "2021-04-06 00:00:00", "dimensions": [0, 1, 2, 3], "metrics": [77, 78], "interval": 8, "dimension_filters": [{ "dimension": 3, "value": ["les5yyv4f1mtdp3m"], "option": 0 }], "metric_filters": [] } }

  let statData = {};
  const dimension = payload.platform === 2 ? 2 : 3 //3 is default
  const dimensionValue = payload.platform === 2 ? payload.adset_id : payload.ad_id // ad_id is default
  if (zipcode) {
    statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3], "metrics": [77, 78], "interval": 8, "dimension_filters": [{ "dimension": dimension, "value": [dimensionValue], "option": 0 }], "metric_filters": [], "other_metric_filters": {} } };
  } else {
    statData = { "report": "", "custom_params": { "metric_set": 16, "name": "***", "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3], "metrics": [77, 78], "interval": 8, "dimension_filters": [{ "dimension": dimension, "value": [dimensionValue], "option": 0 }], "metric_filters": [] } };
  }


  const API_URL = (payload.user === 0) ?
    API_URL_PARAM + "/v1/brands/" + payload.id + "/reporting/run"
    : API_URL_PARAM + "/v1/organizations/" + payload.organization_id + "/reporting/run";

  let params = {}

  if (payload.answer) {
    params.answer = payload.answer.join()
  }

  if (payload.state) {
    params.state = payload.state
  }

  if (payload.city) {
    params.city = payload.city
  }

  if (payload.device_type) {
    params.device_type = payload.device_type
  }

  if (payload.gender) {
    params.gender = payload.gender
  }

  if (payload.age_range) {
    params.age_range = payload.age_range
  }

  if (payload.zipcode) {
    params.zip_code = Array.isArray(payload.zipcode) ? payload.zipcode.join() : payload.zipcode
  }

  return axios({
    url: API_URL,
    method: "POST",
    headers: {
      'Authorization': jwtToken
    },
    params,
    data: statData
  }).catch((err) => {
    console.log(err);
  });
}
function* getRealTimeResearchDetailsStatRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getRealtimeResearchDetailsStatRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsStatSuccess(item.length > 0 ? item[0] : []));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsStatFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsStatFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.getRealtimeResearchDetailsStatFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}


function* getExportRawEventsRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getExportRawEventsRequest, API_URL, jwtToken, action.payload);
    const item = result.data;
    const status = result.status;

    if (status === 200) {
      let statusMsg = result.data && result.data.status &&
        result.data.status.status_message || "your export will be emailed to you when ready"
      yield put(realtimeResearchActionCreator.getExportRawEventsSuccess());
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: statusMsg
        })
      );
    }
    else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getExportRawEventsFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getExportRawEventsFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.getExportRawEventsFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function getExportRawEventsRequest(API_URL_PARAM, jwtToken, payload) {
  const statData =
    payload.zip ?
      {
        "report": "",
        "report_format": 2, "custom_params": { "metric_set": 16, "name": payload.fileName, "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3, 4], "metrics": [74, 75, 76, 79], "interval": 8, "dimension_filters": [{ "dimension": 3, "value": [payload.ad_id], "option": 0 }], "metric_filters": [], "other_metric_filters": {} }
      } :
      {
        "report": "",
        "report_format": 2, "custom_params": { "metric_set": 16, "name": payload.fileName, "start_date": payload.start_date, "end_date": payload.end_date, "dimensions": [0, 1, 2, 3, 4], "metrics": [74, 75, 76, 79], "interval": 8, "dimension_filters": [{ "dimension": 3, "value": [payload.ad_id], "option": 0 }], "metric_filters": [] }
      }


  const API_URL = (payload.user === 0) ?
    (payload.answer !== "" ? API_URL_PARAM + "/v1/brands/" + payload.id + "/reporting/run?answer=" + payload.answer :
      API_URL_PARAM + "/v1/brands/" + payload.id + "/reporting/run")
    :
    (payload.answer !== "" ? API_URL_PARAM + "/v1/organizations/" + payload.organization_id + "/reporting/run?answer=" + payload.answer :
      API_URL_PARAM + "/v1/organizations/" + payload.organization_id + "/reporting/run");

  return axios({
    url: API_URL,
    method: "POST",
    data: statData,
    headers: {
      'Authorization': jwtToken,
      "Content-Type": "application/json"
    }
  }).catch((err) => {
    console.log(err);
  });
}

function updateAdStateRequest(API_URL_PARAM, jwtToken, payload, organization_id) {
  const API_URL = API_URL_PARAM + "/v1/campaigns/state-change";
  return axios({
    url: API_URL,
    method: "PUT",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* updateAdStateRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let organization_id = action.payload && action.payload.organization_id;
    const payload = {};
    if (action.payload && action.payload.id) {
      payload.id = action.payload.id;
    }
    if (action.payload && action.payload.state_id) {
      payload.state_id = action.payload.state_id;
    }

    const result = yield call(updateAdStateRequest, API_URL, jwtToken, payload, organization_id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.updateAdStateSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: "Ad state updated succesfully."
        })
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.updateAdStateFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.updateAdStateFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.updateAdStateFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function getUniversalTagRequest(API_URL_PARAM, jwtToken, payload) {
  const API_URL = API_URL_PARAM + "/v1/ads/" + payload.ad_id + "/universal-tag";
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getUniversalTagRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getUniversalTagRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.getUniversalTagSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getUniversalTagFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getUniversalTagFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.getUniversalTagFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function cloneRtrAdRequest(API_URL_PARAM, jwtToken, payload, campaign_id) {
  const API_URL = API_URL_PARAM + "/v1/campaigns/" + campaign_id + "/clone";
  return axios({
    url: API_URL,
    method: "POST",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* cloneRtrAdRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let campaign_id = action.payload && action.payload.campaign_id;
    const payload = {
      ad_clone: true,
      ad_set_clone: true,
      creative_clone: true
    };
    const result = yield call(cloneRtrAdRequest, API_URL, jwtToken, payload, campaign_id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.cloneRtrAdSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: item.name + " was cloned succesfully."
        })
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.cloneRtrAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.cloneRtrAdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.cloneRtrAdFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function deleteRtrAdRequest(API_URL_PARAM, jwtToken, ad_id, hasBothPlatforts, ad_set_id) {
  const API_URL = `${API_URL_PARAM}${hasBothPlatforts ? `/v1/ads?ad_set=${ad_set_id}` : `/v1/ads?ids=${ad_id}`}`;
  return axios({
    url: API_URL,
    method: "DELETE",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* deleteRtrAdRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let ad_id = action.payload && action.payload.ad_id;
    let hasBothPlatforts = action.payload && action.payload.hasBothPlatforts;
    let ad_set_id = action.payload && action.payload.ad_set;
    const result = yield call(deleteRtrAdRequest, API_URL, jwtToken, ad_id, hasBothPlatforts, ad_set_id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.deleteRtrAdSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: "Ad was deleted successfully."
        })
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.deleteRtrAdFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.deleteRtrAdFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.deleteRtrAdFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}


function getBothAdsDetails(API_URL_PARAM, jwtToken, payload, adset_id) {
  const API_URL = API_URL_PARAM + "/v1/adsets/" + adset_id;
  return axios({
    url: API_URL,
    method: "GET",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getBothAdsRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    let adset_id = action.payload && action.payload.adset_id;
    const payload = {
      ad_clone: true,
      ad_set_clone: true,
      creative_clone: true
    };
    const result = yield call(getBothAdsDetails, API_URL, jwtToken, payload, adset_id);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(realtimeResearchActionCreator.getBothCampaignsSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === 'object') {
        errorMsg = Object.entries(errorMsgList).map(err => {
          if (err instanceof Object)
            return (`${err[0]}: ${JSON.stringify(err[1])}`);
          else
            return `${err[0]}: ${err[1]}`;
        });
        yield put(realtimeResearchActionCreator.getBothCampaignsFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(realtimeResearchActionCreator.getBothCampaignsFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(realtimeResearchActionCreator.getBothCampaignsFailure('Server Error'));
    yield put(
      notificationActionCreator.setNotification({
        message: 'Server Error'
      })
    );
  }
}

function getRTRByAdIdRequest(URL, data) {
  const API_URL = URL + "/v1/results/survey";
  let params = {
    id: data.id
  }
  return axios({
    url: API_URL,
    method: "GET",
    params,
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getRTRByAdIdSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const result = yield call(getRTRByAdIdRequest, API_URL, action.payload);
    const { item, status } = result.data;
    if (status.status_message == "Request Successful") {
      let data = parseJSONResponse(item)
      yield put(realtimeResearchActionCreator.getRTRByAdIdSSuccess(data));
    }
    else {
      yield put(realtimeResearchActionCreator.getRTRByAdIdSFailure());
    }
  }
  catch (e) {
    console.log(e)
  }
}


export default function* realtimeResearchSagas() {
  yield takeLatest(ACTIONS.GET_REALTIME_RESEARCH_LIST_REQUEST, getrealtimeResearchListRequestSaga);
  yield takeLatest(ACTIONS.RTR_LIST_REQUEST, sendRTRListRequestSaga);
  yield takeLatest(ACTIONS.GET_REALTIME_RESEARCH_DETAILS_REQUEST, getRealTimeResearchDetailsRequestSaga);
  yield takeLatest(ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_REQUEST, getRealTimeResearchDetailsStatRequestSaga);
  yield takeLatest(ACTIONS.GET_EXPORT_RAW_EVENTS_REQUEST, getExportRawEventsRequestSaga);
  yield takeLatest(ACTIONS.UPDATE_AD_STATE_REQUEST, updateAdStateRequestSaga);
  yield takeLatest(ACTIONS.GET_UNIVERSAL_TAG_REQUEST, getUniversalTagRequestSaga);
  yield takeLatest(ACTIONS.CLONE_RTR_AD_REQUEST, cloneRtrAdRequestSaga);
  yield takeLatest(ACTIONS.DELETE_RTR_AD_REQUEST, deleteRtrAdRequestSaga);
  yield takeLatest(ACTIONS.GET_BOTH_CAMPAIGNS_REQUEST, getBothAdsRequestSaga);
  yield takeLatest(ACTIONS.SET_RTR_SURVEY_DATA_REQUEST, setRTRSurveyDataSaga);
  yield takeLatest(ACTIONS.GET_RTR_BY_AD_ID_REQUEST, getRTRByAdIdSaga)
}

function parseJSONResponse(data) {
  var data_update = [];
  for (var i = 0; i < data.length; i++) {

    if (data[i]["Answer"] === "-") {
      data_update.push({
        "question": data[i]["Question"],
        "total": data[i]["Answer Count"],
        "subHeading": data[i]["Question Type"],
        "options": [],
        "qId": data[i]["QA Index"],
        "nextQuestion": data[i]["Next_question"]
      });
    }
  }

  for (var i = 0; i < data.length; i++) {
    for (var j = 0; j < data_update.length; j++) {
      if (data[i]["Question"] === data_update[j]["question"]
        && (data[i]["Answer"] !== "-")
        && data[i]["QA Index"].indexOf(data_update[j]["qId"]) === 0) {
        data_update[j].options.push({
          "label": data[i]["Answer"],
          "value": parseFloat(data[i]["Answer Percentage (%)"].toFixed(1)),
          "count": data[i]["Answer Count"],
          "ans-index": parseFloat(data[i]["Answer Index (%)"].toFixed(1)),
          "id": data[i]["QA Index"]
        })
      }
    }
  }

  for (var i = 0; i < data_update.length; i++) {
    var t = data_update[i].options && data_update[i].options.length ?
      Math.max.apply(Math, data_update[i].options.map(function (o) {
        return o.value;
      })) : 0;
    data_update[i]["max"] = t;

    var max = data_update[i].options && data_update[i].options.length ?
      data_update[i].options.find(function (e) {
        return parseFloat(e.value.toFixed(1)) === parseFloat(t.toFixed(1));
      }) : 0;
    data_update[i]["selectedOption"] = max["label"];
  }

  return data_update;
}