class Validator {
  email = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          );
        },
        message: "Invalid email",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  editEmail = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          );
        },
        message: "Invalid email",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  password = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return value.length >= 8;
        },
        message: "Min Length: 8 characters",
      },
      {
        test: (value) => {
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            value
          );
        },
        message:
          "one uppercase letter,one lowercase letter, digit and special character required",
      },
      {
        test: (value) => {
          if (
            this.confirm_password.touched &&
            value !== this.confirm_password.value
          ) {
            this.confirm_password.valid = false;
            this.confirm_password.errorMsg = "Passwords do not match";
          } else {
            this.confirm_password.valid = true;
            this.confirm_password.errorMsg = "";
          }
          return true;
        },
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  new_password = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return value.length >= 8;
        },
        message: "Min Length: 8 characters",
      },
      {
        test: (value) => {
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            value
          );
        },
        message:
          "one uppercase letter,one lowercase letter, digit and special character required",
      },
      {
        test: (value) => {
          if (
            this.confirm_password.touched &&
            value !== this.confirm_password.value
          ) {
            this.confirm_password.valid = false;
            this.confirm_password.errorMsg = "Passwords do not match";
          } else {
            this.confirm_password.valid = true;
            this.confirm_password.errorMsg = "";
          }
          return true;
        },
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  confirm_password = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return value.length >= 8;
        },
        message: "Min Length: 8 characters",
      },
      {
        test: (value) => {
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            value
          );
        },
        message:
          "one uppercase letter,one lowercase letter, digit and special character required",
      },
      {
        test: (value) => {
          return this.password.valid && value === this.password.value;
        },
        message: "Passwords do not match",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  fullname = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  name = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  editName = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  change_password = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return value.length >= 8;
        },
        message: "Min Length: 8 characters",
      },
      {
        test: (value) => {
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            value
          );
        },
        message:
          "one uppercase letter,one lowercase letter, digit and special character required",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  new_password = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return value.length >= 8;
        },
        message: "Min Length: 8 characters",
      },
      {
        test: (value) => {
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            value
          );
        },
        message:
          "one uppercase letter,one lowercase letter, digit and special character required",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  zipcode = {
    rules: [
      {
        test: (value) => {
          return value.length >= 5;
        },
        message: "The zip code must contain 5 digits",
      },
      {
        test: (value) => {
          return /^(?:\d{5},\s?)*\d{5}$/.test(
            value
          );
        },
        message:
          "Please enter a valid zip code",
      },
      // {
      //   test: (value) => {
      //     return /^((\s+)??(\d[a-z]|[a-z]\d|[a-z]),?)+?$/.test(
      //       value
      //     );
      //   },
      //   message:
      //     "You entered a duplicate zip code",
      // },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  /*Report Builder fields validation start*/
  start_date_report = {
    rules: [
      {
        test: (value) => {
          if (value != '') {
            return true
          } else {
            return false;
          }
        },
        message: '*required'
      },
      {
        test: (value, param1) => {

          return value <= param1;

        },
        message: 'Campaign Start Date should be less than Campaign End Date'
      }
    ],
    errorMsg: '',
    valid: false,
    state: '',
    value: '',
    touched: false
  }

  requiredRule = {
    rules: [
      {
        test: (value) => {
          if (value !== "") {
            return true
          } else {
            return false;
          }
        },
        message: '*required'
      },
    ],
    errorMsg: '',
    valid: false,
    state: '',
    value: '',
    touched: false
  };
  metricFilterValue = {
    rules: [
      {
        test: (value) => {
          if (value !== "") {
            return true
          } else {
            return false;
          }
        },
        message: '*required'
      },
      {
        test: (value) => {
          return /^[0-9]*$/.test(value) || /\-?\d+\.\d+/g.test(value);
        },
        message: 'Only Numbers allowed'
      }
    ],
    errorMsg: '',
    valid: false,
    state: '',
    value: '',
    touched: false
  };
  dimensionFilterValues = {
    rules: [
      {
        test: (value) => {
          if (value.length) {
            return true;
          } else {
            return false
          }
        },
        message: '*required'
      }
    ],
    errorMsg: '',
    valid: false,
    state: '',
    value: '',
    touched: false
  };
  /*Report Builder fields validation end*/

  /******************************common functions ************************* */

  isValueValid = function (fieldName, value, param1, param2, param3) {
    const fieldVd = this[fieldName];
    fieldVd.errorMsg = "";
    fieldVd.state = value;
    fieldVd.valid = true;
    fieldVd.value = value;
    fieldVd.touched = true;
    const rules = fieldVd.rules;
    for (let i = 0; i < rules.length; i++) {
      if (!rules[i].test(value, param1, param2, param3)) {
        fieldVd.errorMsg = rules[i].message;
        fieldVd.valid = false;
        return false;
      }
    }
    return true;
  };

  updateValidator = (fieldName, value) => {
    const fieldVd = this[fieldName];
    fieldVd.errorMsg = "";
    fieldVd.state = value;
    fieldVd.valid = true;
    fieldVd.value = value;
    fieldVd.touched = true;
    const rules = fieldVd.rules;
    for (let i = 0; i < rules.length; i++) {
      if (!rules[i].test(value)) {
        fieldVd.errorMsg = rules[i].message;
        fieldVd.valid = false;
        this.isValid = false;
        break;
      }
    }
  };

  isFormValid = (fieldNames) => {
    this.isValid = true;
    for (let i = 0; i < fieldNames.length; i++) {
      this.updateValidator(fieldNames[i], this[fieldNames[i]].value);
    }
    return this.isValid;
  };


  url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  headerUrl = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  logoUrl = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  rule_name = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      },
      {
        test: (value) => {
          return !(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value));
        },
        message: "Rule name cannot contain special characters",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  rule_url = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: "*required",
      }
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  media_1_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  media_2_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  media_3_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  media_4_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  media_5_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  media_6_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  media_7_url = {
    rules: [
      {
        test: (value) => {
          return value.startsWith('https') || value.startsWith('http');
        },
        message: "Url should start with https or http",
      },
      {
        test: (value) => {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        },
        message: "Enter valid url",
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  monetary = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return /^(?=.*[1-9])\d*\.?\d*$/.test(value);
        },
        message: 'Enter positive number'
      },
      {
        test: (value) => {
          return /^\d*[1-9]+\d*$/.test(value);
        },
        message: 'Invisibly points cannot have decimals values'
      },
      {
        test: (value) => {
          return (+value <= 500)
        },
        message: 'Invisibly points should be less than 500'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  filters = {
    rules: [
      {
        test: (value) => {
          try { JSON.parse(value); return true || value === "" } catch { return false || value === "" }
        },
        message: 'Enter valid json'
      }
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  keywords = {
    rules: [
      {
        test: (value) => {
          try { JSON.parse(value); return true || value === "" } catch { return false || value === "" }

        },
        message: 'Enter valid json'
      }
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };

  display_order = {
    rules: [
      {
        test: (value) => {
          return /^[0-9]*$/.test(value) || /\-?\d+\.\d+/g.test(value) || value === "";
        },
        message: 'Enter number'
      }
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  offer_name = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return (value.length <= 60)
        },
        message: 'Maximum character limits reached.'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  title = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return (value.length <= 60)
        },
        message: 'Maximum character limits reached.'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  subtitle = {
    rules: [
      {
        test: (value) => {
          return (value.length <= 100)
        },
        message: 'Maximum character limits reached.'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  button_text = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return (value.length <= 20)
        },
        message: 'Maximum character limits reached.'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  description = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return (value.length <= 250)
        },
        message: 'Maximum character limits reached.'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
  brand_name = {
    rules: [
      {
        test: (value) => {
          return value;
        },
        message: '*required'
      },
      {
        test: (value) => {
          return (value.length <= 50)
        },
        message: 'Please enter upto 50 characters only'
      },
      {
        test: (value) => {
          return /^[a-z\d\-_\s]+$/i.test(value);
        },
        message: 'Please enter alphanumeric value'
      },
    ],
    errorMsg: "",
    valid: false,
    state: "",
    value: "",
    touched: false,
  };
}
export default Validator;
