import React, {useEffect} from 'react';
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from '../../components/HeaderNavigation';
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";

import RealtimeResearchList from "../../components/RealtimeResearch/RealtimeResearchList";
import RealtimeResearchDetails from "../../components/RealtimeResearch/RealtimeReasearchDetails";
import RealTimeCampaign from "../../components/RealtimeResearch/RealTimeCampaign";
import "./index.scss";

import history from '../../history';
import RTRPreview from '../../components/RealtimeResearch/RealTimeCampaign/RTRPreview';
import checkRBAC from '../../utils/checkRBAC'
import { PERMISSIONS } from "../../utils/permissionMatrix";

const RealtimeResearch = () => {
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;

  /**
   states required for RBAC 
    */
  const userRbac = useSelector(state => state.auth.rbac);

  function isAuthorized(requiredPermissions) {
    return checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization)
  }

  useEffect(() => {
    if (userData.organization_role !== 0) {
      if (!is_rtr_enabled) {
        history.push(ROUTES.DASHBOARD)
        return
      }
    }
  }, [])
  

  return (
    <Router basename="/" history={history}>
      <>
        <Notifications />
        <HeaderNavigation />
        <div className="app-container" style={{ flexDirection: "column" }}>
          {/* {(ROUTES.REALTIME_RESEARCH === window.location.pathname) ?
            <SideNavigation />
            : <></>
          } */}
          <>
            <Switch>
              <Route exact path={ROUTES.REALTIME_RESEARCH} component={RealtimeResearchList} />
              <Route exact path={ROUTES.REALTIME_RESEARCH_DETAILS} component={RealtimeResearchDetails} />

              {/* {isAuthorized(PERMISSIONS.BUILD_RTR_WRITE) && <> */}
                <Route exact path={ROUTES.CAMPAIGN_PREVIEW} component={RealTimeCampaign} />
                <Route exact path={ROUTES.RTR_OFFER_PREVIEW} component={RTRPreview} />
              {/* </>} */}
              
            </Switch>
          </>
        </div>
      </>
    </Router>
  );
}

export default RealtimeResearch;

