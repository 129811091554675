
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonModal = ({rows=4, bottomButtons=true, style={}, fieldStyle={}}) => {

  return (
    <section className="skeleton-wrapper" style={style}>
      <div className="skeleton-modal">
        {
          Array(rows).fill(null).map((data, index) => {
            return (
              <>
                <section key={index} className={`field field-${index}`} style={fieldStyle}>
                  <SkeletonElement type="title" />
                </section>
              </>
            )
          })
        }
        {bottomButtons && (<section className='field bottom'>
          <div className='row'>
            <div className='col-3'><SkeletonElement type="title" /></div>
            <div className='col-3'><SkeletonElement type="title" /></div>
          </div>
        </section>)}
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonModal;