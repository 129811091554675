import React, {useState} from 'react'
import {Button, ButtonGroup} from "reactstrap";

const PlatformToggle = ({data, selectPlatform = 1, handlePlatformChange}) => {
  /**
   * 1: Programmatic
   * 2: Invisibly
   */
  const [platform, setPlatform] = useState(selectPlatform)

  const updatePlatform = (updatedPlatform) => {
    handlePlatformChange(updatedPlatform)
    setPlatform(updatedPlatform)
  }

  return (
    <div className='toggle-platform'>
      <div className="toggle-buttons-wrapper">
        <ButtonGroup className="toggle-buttons">
          <Button
            className={"in-btn-primary btn-toggle initial-platform"}
            onClick={() => updatePlatform(1)}
            active={platform === 1}
          >
            Programmatic
          </Button>
          <Button
            className={"in-btn-primary btn-toggle"}
            onClick={() => updatePlatform(2)}
            active={platform === 2}
          >
            Invisibly
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default PlatformToggle