import { takeLatest, call, select, put } from "redux-saga/effects";
import { reportBuilderActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendDimensionsListRequest(API_URL_PARAM, jwtToken) {
  const API_URL = API_URL_PARAM + "/v1/dspreports/vzdimensions";
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendDimensionsListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendDimensionsListRequest,
      API_URL,
      jwtToken
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      //Removed VZ from API response
      const updatedItem = item.map(({ id, value, description }) => ({ id, value: value.replace('_VZ', ''), description: description.replace('_VZ', '') }))
      const sortedItem = sortArrayData(updatedItem);
      yield put(reportBuilderActionCreator.dimensionsListSuccess(sortedItem));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(reportBuilderActionCreator.dimensionsListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(reportBuilderActionCreator.dimensionsListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.dimensionsListFailure("Server Error"));
  }
}

function sendMetricsListRequest(API_URL_PARAM, jwtToken) {
  const API_URL = API_URL_PARAM + "/v1/dspreports/vzmetrics";
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendMetricsListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendMetricsListRequest,
      API_URL,
      jwtToken
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      //Removed VZ from API response
      const updatedItem = item.map(({ id, value, description }) => ({ id, value: value.replace(' VZ', ''), description: description.replace(' VZ', '') }))
      const sortedItem = sortArrayData(updatedItem);
      yield put(reportBuilderActionCreator.metricsListSuccess(sortedItem));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(reportBuilderActionCreator.metricsListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(reportBuilderActionCreator.metricsListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.metricsListFailure("Server Error"));
  }
}

function sendRunReportRequest(API_URL_PARAM, jwtToken, payload, userRole, organizationID) {
  let API_URL = "";
  if (organizationID && userRole === 0) {
    API_URL = `${API_URL_PARAM}/v1/organizations/${organizationID}/reporting/run`;
  } else if (userRole === 0) {
    API_URL = `${API_URL_PARAM}/v1/reporting/run`;
  } else {
    API_URL = `${API_URL_PARAM}/v1/organizations/${organizationID}/reporting/run`;
  }

  return axios({
    url: API_URL,
    method: "POST",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendRunReportRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    let jwtToken = userData && userData.token ? userData.token : null;
    let userRole = userData && userData.organization_role;

    let orgId;
    if (userRole !== 0) {
      orgId = selectedOrganization ? selectedOrganization?.id : null;
    }

    if (userRole === 0 && localStorage.getItem('selectedOrganization') !== null) {
      orgId = JSON.parse(localStorage.getItem('selectedOrganization')).id;
    }

    const result = yield call(
      sendRunReportRequest,
      API_URL,
      jwtToken,
      action.payload,
      userRole,
      orgId
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(reportBuilderActionCreator.runReportSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(reportBuilderActionCreator.runReportFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(reportBuilderActionCreator.runReportFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.runReportFailure("Server Error"));
  }
}

function getReportTemplatesRequest(API_URL_PARAM, jwtToken, isAdmin, organization_id, search) {
  let params = {};
  params.limit = 20; //default page limit
  const isSearch = search ? `?search=${search}` : "";
  const API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/reporting${isSearch}` : isAdmin && `/v1/reporting${isSearch}`}`;

  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getReportTemplatesRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;

    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;

    let jwtToken = userData && userData.token ? userData.token : null;
    const search = action.payload?.search;

    const result = yield call(
      getReportTemplatesRequest,
      API_URL,
      jwtToken,
      isAdmin,
      organization_id,
      search
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      let data = modifyTemplateData(item.results);

      yield put(reportBuilderActionCreator.getReportTemplatesSuccess(data));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(reportBuilderActionCreator.getReportTemplatesFailure(errorMsg.join()));
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(reportBuilderActionCreator.getReportTemplatesFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.getReportTemplatesFailure("Server Error"));
  }
}

function saveReportTemplatesRequest(API_URL_PARAM, jwtToken, data, isAdmin, organization_id, method = "POST", id) {
  let API_URL;
  if (method === "POST") {
    API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/reporting` : isAdmin && "/v1/reporting"}`;
  } else if (method === "PUT") {
    API_URL = `${API_URL_PARAM}${organization_id ? `/v1/organizations/${organization_id}/reporting/${id}` : isAdmin && `/v1/reporting/${id}`}`;
  }

  return axios({
    url: API_URL,
    method: method,
    data: data,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* saveReportTemplatesRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;

    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;

    let method = action.payload?.method;
    let id = action.payload?.id;
    let state = action.payload?.state
    delete action.payload.method;
    delete action.payload.id;
    delete action.payload.state;

    const result = yield call(
      saveReportTemplatesRequest,
      API_URL,
      jwtToken,
      action.payload,
      isAdmin,
      organization_id,
      method,
      id
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      if (method === "POST") {
        let data = modifyTemplateData([item]);
        yield put(reportBuilderActionCreator.saveReportTemplateSuccess(data[0]));
        if (state === "save") {
          yield put(reportBuilderActionCreator.setReportTemplate(data[0]));
        }
      }
      else {
        let data = modifyTemplateData([item]);
        yield put(reportBuilderActionCreator.updateReportTemplateSuccess(data[0]))
        yield put(reportBuilderActionCreator.setReportTemplate(data[0]))
      }
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message,
          position: "fixed"
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        if (method === "POST") {
          yield put(reportBuilderActionCreator.saveReportTemplateFailure(errorMsg.join()))
        } else {
          yield put(reportBuilderActionCreator.updateReportTemplateFailure(errorMsg.join()))
        }
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join(),
            position: "fixed"
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        if (method === "POST") {
          yield put(reportBuilderActionCreator.saveReportTemplateFailure(errorMsg))
        } else {
          yield put(reportBuilderActionCreator.updateReportTemplateFailure(errorMsg))
        }
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg,
            position: "fixed"
          })
        );
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.saveReportTemplateFailure("Server Error"));
  }
}

function deleteReportTemplatesRequest(API_URL_PARAM, jwtToken, data, isAdmin, organization_id) {
  const API_URL = `${API_URL_PARAM}${isAdmin ? `/v1/reporting?ids=${data}` : `/v1/organizations/${organization_id}/reporting?ids=${data}`}`;
  return axios({
    url: API_URL,
    method: "DELETE",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* deleteReportTemplatesRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const selectedOrganization = yield select((state) => state.organization.selectedOrganization);
    const isAdmin = userData && userData.organization_role == 0 ? true : false;
    const data = action.payload.toString(); //convert array to string

    const organization_id = selectedOrganization ? selectedOrganization?.id : undefined;

    const result = yield call(
      deleteReportTemplatesRequest,
      API_URL,
      jwtToken,
      data,
      isAdmin,
      organization_id
    );

    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(reportBuilderActionCreator.deleteReportTemplateSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message,
          position: "fixed"
        })
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(reportBuilderActionCreator.deleteReportTemplateFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join(),
            position: "fixed"
          })
        );
      } else {
        errorMsg = status && status.status_message ? status.status_message : null;
        yield put(reportBuilderActionCreator.deleteReportTemplateFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg,
            position: "fixed"
          })
        );
      }
    }
  } catch (error) {
    yield put(reportBuilderActionCreator.deleteReportTemplateFailure("Server Error"));
  }
}

export default function* reportBuilderSagas() {
  yield takeLatest(ACTIONS.DIMENSIONS_LIST_REQUEST, sendDimensionsListRequestSaga);
  yield takeLatest(ACTIONS.METRICS_LIST_REQUEST, sendMetricsListRequestSaga);
  yield takeLatest(ACTIONS.RUN_REPORT_REQUEST, sendRunReportRequestSaga);
  yield takeLatest(ACTIONS.GET_REPORT_TEMPLATES_REQUEST, getReportTemplatesRequestSaga);
  yield takeLatest(ACTIONS.SAVE_REPORT_TEMPLATE_REQUEST, saveReportTemplatesRequestSaga);
  yield takeLatest(ACTIONS.UPDATE_REPORT_TEMPLATE_REQUEST, saveReportTemplatesRequestSaga);
  yield takeLatest(ACTIONS.DELETE_REPORT_TEMPLATE_REQUEST, deleteReportTemplatesRequestSaga);
}

function modifyTemplateData(data) {
  return data?.map((i) => {
    return {
      ...i,
      metrics: i?.metrics?.map((metric) =>
      ({
        id: metric.id,
        value: metric.value.replace(' VZ', ''),
        description: metric.description.replace(' VZ', '')
      })),
      metric_filters: i?.metric_filters?.map((metric) =>
      ({
        ...metric,
        errorMsgMetric: "",
        errorMsgValue: "",
        errorMsgRule: ""
      })),
      dimension_filters: i?.dimension_filters?.map((dimension_filter) =>
      ({
        ...dimension_filter,
        errorMsgDimension: "",
        errorMsgValue: "",
        errorMsgRule: ""
      }))
    }
  })
}

function sortArrayData(orders) {
  orders.sort(function (a, b) {
    let valueA = a.value.toLowerCase();
    let valueB = b.value.toLowerCase();
    if (valueA < valueB) {
      return -1;
    }
    else if (valueA > valueB) {
      return 1;
    }
    return 0;
  });

  return orders;
}