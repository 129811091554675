
import React, { useState, useEffect } from 'react';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import {
    Card, UncontrolledTooltip
} from "reactstrap";
charts(FusionCharts);


const RealTimeChart = ({ data, disableChart }) => {
    const [chartData, setChartData] = useState([]);
    const [legendData, setLegendData] = useState(data);
    const [hidden, setHidden] = useState({});
    const legendList = data.map(d => { var z = {}; z.display = true; z.id = d.id; return z });
    const [selectedLegend, setSelectedLegend] = useState(data.map(d => { var z = {}; z.display = true; z.id = d.id; return z }));
    // elements for obtaining vals
    var val1El = '#F2F3FD';
    var val2El = '#1638CC';
    // first and last colors are fixed heance removing 2 from the length.
    var stepsEl = (data.length - 2) || 0;

    useEffect(() => {
        var colorPallete = ['#1638CC', '#F2F3FD'];
        if (data.length > 2) {
            colorPallete = updateSpitter();
        }
        data.filter(function (item, idx) {
            return item["color"] = colorPallete[idx];

        });

        setChartData(data);

    }, [data])


    // constants for switch/case checking representation type
    const HEX = 1;
    const RGB = 2;
    const RGBA = 3;

    //return a workable RGB int array [r,g,b] from hex representation
    function processHEX(val) {
        //does the hex contain extra char?
        var hex = (val.length > 6) ? val.substr(1, val.length - 1) : val;
        // is it a six character hex?
        if (hex.length > 3) {

            //scrape out the numerics
            var r = hex.substr(0, 2);
            var g = hex.substr(2, 2);
            var b = hex.substr(4, 2);

            // if not six character hex,
            // then work as if its a three character hex
        } else {

            // just concat the pieces with themselves
            var r = hex.substr(0, 1) + hex.substr(0, 1);
            var g = hex.substr(1, 1) + hex.substr(1, 1);
            var b = hex.substr(2, 1) + hex.substr(2, 1);

        }
        // return our clean values
        return [
            parseInt(r, 16),
            parseInt(g, 16),
            parseInt(b, 16)
        ]
    }

    function updateSpitter() {

        var val1RGB = processHEX(val1El);
        var val2RGB = processHEX(val2El);
        var colors = [];

        //the number of steps in the gradient
        var stepsInt = parseInt(stepsEl, 10);
        //the percentage representation of the step
        var stepsPerc = 100 / (stepsInt + 1);

        // diffs between two values 
        var valClampRGB = [
            val2RGB[0] - val1RGB[0],
            val2RGB[1] - val1RGB[1],
            val2RGB[2] - val1RGB[2]
        ];

        // build the color array out with color steps
        for (var i = 0; i < stepsInt; i++) {
            var clampedR = (valClampRGB[0] > 0)
                ? pad((Math.round(valClampRGB[0] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                : pad((Math.round((val1RGB[0] + (valClampRGB[0]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

            var clampedG = (valClampRGB[1] > 0)
                ? pad((Math.round(valClampRGB[1] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                : pad((Math.round((val1RGB[1] + (valClampRGB[1]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

            var clampedB = (valClampRGB[2] > 0)
                ? pad((Math.round(valClampRGB[2] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                : pad((Math.round((val1RGB[2] + (valClampRGB[2]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);
            colors[i] = [
                '#',
                clampedR,
                clampedG,
                clampedB
            ].join('');
        }

        colors.push(val2El);
        colors.unshift(val1El);
        return colors.reverse();
    }

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }


    FusionCharts.options.license({
        key:
            "kJ-7B9snlC4E3A3G2B1B4B1C6D7B3F1F2gzzB2A6D2zE1A2E1oreC7E2B4cetH3C10D7gyF-10D3E2D2H4B2C8D2C5B2C1C1I-8vvB1E6ED1fbqA32B13B9d1sB4TF4D3iizA5C5E3C5C4A3D3A2B9A6C3F5E4F1i1==",
        creditLabel: false,
    });


    const chartConfig = {
        bgColor: disableChart ? "F2F3FD" : "FFFFFF",
        caption: "",
        captionAlignment: "",
        subCaption: "",
        xAxisname: "",
        usePlotGradientColor: "0",
        showPlotBorder: 0,
        numbersuffix: "%",
        primaryAxisOnLeft: "0",
        theme: "fusion",

        showCanvasBorder: 0,
        canvasbgColor: disableChart ? "F2F3FD" : "FFFFFF",
        showBorder: 0,
        bgAlpha: "0",
        canvasTopPadding: 100,

        labelFontColor: "#8A8E97",
        labelFontSize: "12",
        labelFontBold: "0",

        divLineColor: "#EFEFEF",
        divLineAlpha: 90,

        showAlternateHGridColor: "0",
        showToolTip: "0",
        showAlternateHGridColor: "0",
        valueFontBold: 1,
        valueFontSize: 18,
        valueFontColor: "#000000",
        valueFont: "BasisGrotesque",
        labelFont: "BasisGrotesque",
        labelFontSize: 16,
        labelFontColor: "#535559",
        yAxisValueFont: "BasisGrotesque",
        yAxisValueFontSize: 14,
        yAxisValueFontColor: "#000000",

        numDivLines: 3,
        yAxisMinValue: 0,
        yAxisMaxValue: 100,
        adjustDiv: 0,
        labelDisplay: 'auto',
        useEllipsesWhenOverflow: "1",
        showChartLoadingMessage: 1,
        animation: 0,
        maxLabelHeight: 50,
        "labelPadding": "15"
    };

    const dataSource = {
        chart: chartConfig,
        data: chartData
    }

    const updateChartData = (e, item, index, action) => {
        var updated = [];
        if (action === "remove") {
            updated = selectedLegend.filter((a) => {
                if (item.id === a.id) {
                    a.display = false
                }
                return a;
            });
        } else {
            updated = selectedLegend.filter((a) => {
                if (item.id === a.id) {
                    a.display = true;
                }
                return a;
            });

        }
        var confirmedList = data.filter((a) => {
            return selectedLegend.find((p) => (p.id === a.id && p.display === true));
        });
        setChartData(confirmedList);
        setSelectedLegend(updated);
        // setLegendData(chartData)
        setHidden({ ...hidden, [index]: !hidden[index] });
    }

    return (<div className="dashboard realtime-chart" style={{ padding: "0" }}>
        <section className={disableChart ? "performance-graph-container" : "graph-container"}>
            <Card className={disableChart ? 'ad-disabled' : ''}
                style={{ border: "none" }}>

                <div style={{
                    display: "flex", alignItems: "center",
                    justifyContent: "center", height: "20px"
                }}>
                    {legendData.map((data, index) => {
                        return (<>
                            <div style={{ marginRight: "48px", cursor: "pointer" }}
                                id={data.id} onClick={(e) => updateChartData(e, data, index, hidden[index] ? "add" : "remove")}>
                                <span style={{
                                    height: "10px", width: "10px", margin: "5px 8px",
                                    backgroundColor: hidden[index] ? "grey" : data.color, borderRadius: "50%", display: "inline-block"
                                }}></span>
                                <div style={{
                                    display: "inline-block",
                                    maxWidth: "100px", whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: hidden[index] ? "grey" : "#000000"
                                }}>{data.label}</div>
                            </div>
                            <UncontrolledTooltip className="legend-tool-tip"
                                placement="top" target={data.id}>{data.label}
                            </UncontrolledTooltip>
                        </>)
                    })}
                </div>

                <ReactFusioncharts
                    type="column2d"
                    width="100%"
                    height="400"
                    dataFormat="json"
                    dataSource={dataSource}
                />
            </Card>
        </section>
    </div>)
}

export default RealTimeChart;
