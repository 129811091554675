import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isFetched: false,
  advertiserData: [],
};

const advertiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADVERTISERS_LIST_REQUEST:
      return {
        ...state,
        advertiserData: action.payload,
        isLoading: true,
        isFetched: false
      };

    case ACTIONS.ADVERTISERS_LIST_SUCCESS:
      return {
        ...state,
        advertiserData: action.payload,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.ADVERTISERS_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
        isFetched: false
      };

    case ACTIONS.ADVERTISERS_LIST_FAILURE_CLEAR:
      return {
        ...state,
        advertiserData: [],
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default advertiserReducer;
