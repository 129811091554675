import React from "react";
import getImageData from "./ImageMapping";
import "./index.scss";

export const LeftSection = ({ pagename, bgclass }) => {
  /**
   * pagename: ImageMapping is used to get the mapoping image of the perticular page
   * bgclass: Available classes are purple, orange, blue
   */
  const imageData = getImageData(pagename);

  return (
    <>
      <section className={`left-section ${bgclass ? bgclass : ""}`}>
        <span className="peoples-site-logo">
          <a
            href="https://www.invisibly.com/home"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/Icon_assets/svg/logo.svg" alt="Invisibly logo" />
          </a>
        </span>
        <div className="manshen-img-container">
          <img
            src={imageData.imageURL}
            alt={imageData.alt}
            className="manshen-img img-fluid"
          />
        </div>
        <div className="copyright-bottom">
          &copy; Copyright Invisibly 2020. All rights reserved
        </div>
      </section>
    </>
  );
};

export default LeftSection;
