import React, { useEffect, useState } from 'react';
import "./index.scss";
import {
  Button, Card, CardBody, Label, Input, FormGroup, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, FormFeedback, CustomInput, Tooltip
} from "reactstrap";
import Loader from '../../Loader';
import surveyFile from "../../../assets/surveyFile/IFB Toast Template Upload.csv";
import BranchingSurveyFile from "../../../assets/surveyFile/IFB Question Template Upload - Survey.csv";


const GeneralSetup = props => {
  const VALUES = {
    PROGRAMMATIC: "Programmatic",
    INVISIBLY: "Invisibly",
    BOTH: "Both"
  }

  const SIZES = [
    { label: '300×250', value: 0 },
    { label: '300×600', value: 1 },
    { label: '970×250', value: 2 },
    { label: '320×480', value: 3 }
  ]

  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    GO_BACK_ICON: "/Icon_assets/svg/back_icon.svg",
    GENERAL_SETTING_ICON: "/Icon_assets/svg/general-settings-icon.svg",
  }

  const {
    displayToastSection,
    inputValues,
    handleInputChange,
    dropdownOpen,
    toggle,
    isAdvertiserRequest,
    searchValue,
    handleSearch,
    advertiserList,
    setInputValues,
    clearText,
    removeSelection,
    imageLoading,
    handleImageUpload,
    logoImage,
    headerImage,
    setDisplayUploadInfo,
    setDisplayToastInfo,
    isFetching,
    isEdit,
    validators,
    disableCampaign,
    isActiveOrPaused,
    handleRemoveCSV,
    activeSurveyData
  } = props;


  const uploadHeader = (
    <Button className="export-icon" style={{ opacity: "1" }} onClick={(e) => console.log(e)}>
      <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
      <span className="span-label">Upload header image </span>
    </Button>);

  const toastFileBtn = (
    <Button className="in-btn-sm in-btn-info btn-export" style={{ opacity: "1" }} onClick={() => { setDisplayToastInfo(true); window.scrollTo(0, 0) }}>
      <img src={"/Icon_assets/svg/right-icon.svg"} alt="export csv" style={{ margin: "7px 14px 0px 18px", float: "right" }} />
      Upload CSV File
    </Button>);

  const uploadLogo = (
    <Button className="export-icon" style={{ opacity: "1" }} onClick={() => console.log('export button')}>
      <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
      <span className="span-label">Upload logo image</span>
    </Button>);


  const exportFileBtn = (
    <Button className="in-btn-sm in-btn-info btn-export" onClick={() => setDisplayUploadInfo(true)}>
      <img src={"/Icon_assets/svg/right-icon.svg"} alt="export csv" style={{ margin: "7px 14px 0px 18px", float: "right" }} />
      Upload CSV File
    </Button>);


  const downloadCSVBtn = (
    <Button className="export-icon" style={{ opacity: "1", marginLeft: "18px", padding: 0 }} onClick={() => console.log('test')}>
      <a href={BranchingSurveyFile} download={"IFB Question Template Upload - Survey.csv"}>
        <img src={"/Icon_assets/svg/download-icon.svg"} alt="export csv" />
        <span className="span-label">Download Survey Template</span>
      </a>
    </Button>);

  const downloadToastBtn = (
    <Button className="export-icon" style={{ opacity: "1", marginLeft: "18px", padding: 0 }} onClick={() => console.log('test')}>
      <a href={surveyFile} download={"IFB Toast Template Upload.csv"}>
        <img src={"/Icon_assets/svg/download-icon.svg"} alt="export csv" />
        <span className="span-label">Download Toast Template</span>
      </a>
    </Button>);

  return (
    <>
      {isEdit && isFetching ?
        <Loader />
        :
        <>
          <Card className={`data-card`}>
            <h2 className="form-heading">Research Settings</h2>
            <CardBody>
              <div className="ad-form">
                <Table className="ad-table">
                  <Row>
                    <Col md={12} sm={12} lg={4}>
                      <FormGroup >
                        <Input
                          type="text"
                          name="campaignName"
                          id="campaignName"
                          required
                          className="form-input form-input-sm mt-2 required"
                          autoComplete="off"
                          value={inputValues.campaignName}
                          onChange={handleInputChange}
                        />
                        <Label className="floating-heading-label required">Survey Name</Label>
                      </FormGroup></Col>
                    <Col md={12} sm={12} lg={4}>
                      <FormGroup className={isActiveOrPaused ? "disable-platform" : ""}>
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggle}
                          className="dropdown-option metrics"
                          direction="down"
                        >
                          <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            className="dropdown_toggle"
                          >
                            <span
                              className="dropdown_title"
                              title="Select range"
                            >
                              {inputValues.advertiser ? inputValues.advertiser : "Select Advertiser"}
                            </span>
                            <img
                              src={
                                dropdownOpen
                                  ? statusIcons.ARROW_UP
                                  : statusIcons.ARROW_DOWN
                              }
                              className="dropdown-img"
                            ></img>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="search-item" toggle={false}>
                              <FormGroup className="search-metrics">
                                {!isAdvertiserRequest &&
                                  <><Input
                                    value={searchValue}
                                    type="text"
                                    name="search"
                                    id="searchUser"
                                    placeholder="Search..."
                                    className="search-input"
                                    autoComplete="off"
                                    onChange={(event) => handleSearch(event)}
                                  />
                                    <img
                                      src="/Icon_assets/svg/search-organization-icon.svg"
                                      alt="Search"
                                      className="search-image"
                                    /></>}
                              </FormGroup>
                            </DropdownItem>
                            {!isAdvertiserRequest ?
                              (advertiserList && advertiserList.length ?
                                (
                                  advertiserList.map((item, index) => {
                                    return (
                                      <DropdownItem
                                        style={{ paddingLeft: "26px" }}
                                        name={"advertiser"}
                                        key={index}
                                        id={index}
                                      >
                                        <div onClick={(e) => {
                                          const inputFields = { ...inputValues };
                                          inputFields['advertiser'] = item.name;
                                          inputFields["brand_id"] = item.id;
                                          setInputValues(inputFields);
                                        }}>   {item.name}</div>
                                      </DropdownItem>
                                    )
                                  })
                                )
                                :
                                (
                                  <span className="no-data-available-txt">
                                    No Advertiser data available
                                  </span>
                                ))
                              : (
                                <Loader style={{ height: '220px', padding: "0px", paddingTop: '60px' }} />
                              )}
                          </DropdownMenu>

                          <Label className="floating-heading-label required"
                            style={{ top: "-25px" }}>Advertiser</Label>

                        </Dropdown>

                      </FormGroup></Col> </Row>
                  <Row>
                    <Col md={12} sm={12} lg={4}>
                      <FormGroup style={{ position: "relative" }} className={isActiveOrPaused ? "disable-platform" : ""}>
                        <Label className={`floating-label`}>Ad Pixel Code</Label>
                        <Input
                          type="text"
                          name="pixelCode"
                          id="pixel_code"
                          required
                          className="form-input form-input-sm mt-2 input-font"
                          autoComplete="off"
                          value={inputValues.pixelCode}
                          onChange={handleInputChange}
                          // invalid={validators.name.errorMsg !== ""}
                          placeholder="<script>"
                        />
                        {inputValues.pixelCode.length > 0 && <img name="pixelCode" className={'close-icon'} src={"/Icon_assets/svg/close-image.svg"} alt="close"
                          onClick={(e) => clearText(e)} />}
                      </FormGroup>
                    </Col>
                    <Col md={12} sm={12} lg={4}>
                      <FormGroup style={{ position: "relative" }} id="platform"
                        className={disableCampaign ? "disable-platform" : ""}>
                        <Label className={`floating-label`} style={{ top: "2px" }}>Platform</Label>
                        <div>
                          <CustomInput type="radio"
                            style={{ marginRight: "27px" }}
                            name="platform"
                            className={"custom-radio-input"}
                            id="radio1"
                            label={VALUES.PROGRAMMATIC}
                            value={VALUES.PROGRAMMATIC}
                            checked={inputValues.platform === VALUES.PROGRAMMATIC}
                            onChange={(e) => handleInputChange(e, VALUES.PROGRAMMATIC)} />

                          <CustomInput type="radio"
                            name="platform"
                            className={"custom-radio-input"}
                            id="radio2"
                            label={VALUES.INVISIBLY}
                            value={VALUES.INVISIBLY}
                            checked={inputValues.platform === VALUES.INVISIBLY}
                            onChange={(e) => handleInputChange(e, VALUES.INVISIBLY)} />

                          <CustomInput type="radio"
                            style={{ marginRight: "27px" }}
                            name="platform"
                            className={"custom-radio-input"}
                            id="radio3"
                            label={VALUES.BOTH}
                            value={VALUES.BOTH}
                            checked={inputValues.platform === VALUES.BOTH}
                            onChange={(e) => handleInputChange(e, VALUES.BOTH)} />

                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {inputValues.platform !== VALUES.INVISIBLY && <Row><Col md={12} sm={12} lg={6}>
                    <FormGroup style={{ position: "relative" }} id="ad_size"
                      className={isActiveOrPaused ? "disable-platform" : ""}>
                      <Label className={`floating-label required`} style={{ top: "2px" }}>Ad Sizes</Label>
                      <div>
                        {
                          SIZES.map((SIZE) => < CustomInput type="radio"
                            style={{ marginRight: "27px" }}
                            name="ad_size"
                            className={"custom-radio-input"}
                            id={"size-radio" + SIZE.value}
                            label={SIZE.label}
                            value={SIZE.value}
                            checked={Number(inputValues.ad_size) === Number(SIZE.value)}
                            onChange={(e) => handleInputChange(e)} />)
                        }
                      </div>
                    </FormGroup>
                  </Col></Row>}
                  {inputValues.platform === VALUES.INVISIBLY && <Row><Col md={12} sm={12} lg={6}>
                    <FormGroup style={{ position: "relative" }} id="ad_template_type"
                      className={isActiveOrPaused || isEdit ? "disable-platform" : ""}>
                      <Label className={`floating-label required`} style={{ top: "2px" }}>Survey type</Label>
                      <div>
                        <CustomInput type="radio"
                          style={{ marginRight: "27px" }}
                          name="ad_template_type"
                          className={"custom-radio-input"}
                          id={"default-radio"}
                          label="Default"
                          value={5}
                          checked={inputValues.ad_template_type == 5}
                          onChange={(e) => { handleInputChange(e); }}
                        />
                        <CustomInput type="radio"
                          style={{ marginRight: "27px" }}
                          name="ad_template_type"
                          className={"custom-radio-input"}
                          id={"in-feed-radio"}
                          label={"In-feed"}
                          value={9}
                          checked={inputValues.ad_template_type == 9}
                          onChange={(e) => { handleInputChange(e); }}
                        />
                      </div>
                    </FormGroup>
                  </Col></Row>}
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card className={(isActiveOrPaused || inputValues.advertiser === "" ? `ad-disabled ` : ``) + `data-card`}>
            <h2 className="form-heading" style={{ marginBottom: "26px", opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>Survey Setup</h2>
            <span className="form-sub-heading" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>
              {inputValues.selectedFiles && Object.keys(inputValues.selectedFiles).length === 0 && inputValues.selectedFiles.constructor === Object ?
                `Download the survey template to upload your Realtime Research questions and answers.`
                : `Download the Survey template to upload valid CSV file.`} {downloadCSVBtn}</span>

            <CardBody>
              <div className="form-content">
                {
                  inputValues.selectedFiles && Object.keys(inputValues.selectedFiles).length === 0 && inputValues.selectedFiles.constructor === Object ?
                    exportFileBtn :
                    <div className="selection-div">
                      {/* <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" /> */}
                      <span className="span-label" style={{ color: "#000000", paddingLeft: "0px" }}>{inputValues.selectedFiles.name}</span>
                      {inputValues.selectedFiles.name && <img src={"/Icon_assets/svg/black-close-icon.svg"} style={{ marginLeft: '15px', cursor: 'pointer', marginTop: '5px', cursor: "pointer" }} alt="close"
                        onClick={(e) => handleRemoveCSV(e, "questions")} />}
                    </div>
                }
              </div>
            </CardBody>
          </Card>

          {inputValues.platform !== VALUES.INVISIBLY &&
            <>
              <Card className={(isActiveOrPaused || inputValues.advertiser === "" ? `ad-disabled ` : ``) + `data-card`}>
                <h2 className="form-heading" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>Programmatic Toast Setup</h2>
                <span className="form-sub-heading" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>{
                  inputValues.selectedToastFiles && Object.keys(inputValues.selectedToastFiles).length === 0 && inputValues.selectedToastFiles.constructor === Object ?
                    `Download the toast template to upload the toast messages for your Realtime Research survey.`
                    : `Download the Toast template to upload valid CSV file.`} {downloadToastBtn}</span>
                <CardBody>
                  <div className="form-content">
                    {
                      inputValues.selectedToastFiles && Object.keys(inputValues.selectedToastFiles).length === 0 && inputValues.selectedToastFiles.constructor === Object ?
                        toastFileBtn :
                        <div className="selection-div">
                          {/* <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" /> */}
                          <span className="span-label" style={{ color: "#000000", paddingLeft: "0px" }}>{inputValues.selectedToastFiles.name}</span>
                          {inputValues.selectedToastFiles.name && <img src={"/Icon_assets/svg/black-close-icon.svg"} style={{ marginLeft: '15px', cursor: 'pointer', marginTop: '5px' }} alt="close"
                            onClick={(e) => handleRemoveCSV(e, "toasts")} />}
                        </div>
                    }

                  </div>
                </CardBody>
              </Card>

              <Card className={(isActiveOrPaused || inputValues.advertiser === "" ? `ad-disabled ` : ``) + `data-card`}>
                <h2 className="form-heading" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>Programmatic Media Setup
                  <span className="optional-heading">Optional</span>
                </h2>

                <CardBody>
                  <div className="form-content">
                    <div className="media-div" style={{ paddingTop: "0px" }}>
                      <Label className="logo" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>Header Image</Label>
                      {imageLoading && headerImage ?
                        <Loader style={{ padding: "0px" }} />
                        :
                        inputValues.headerImage ?
                          <FormGroup style={{ display: 'block' }}>
                            <div>
                              <div className="selection-div">
                                <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                                <span className="span-label">{inputValues.headerImage}</span>
                                <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close"
                                  onClick={(e) => removeSelection(e, 'header')} />
                              </div>
                              <div className="image-div">
                                <Label className={`floating-label`}>Destination URL</Label>
                                <Input
                                  type="text"
                                  name="headerUrl"
                                  id="headerUrl"
                                  required
                                  className="form-input form-input-sm input-font"
                                  autoComplete="off"
                                  value={inputValues.headerUrl}
                                  onChange={handleInputChange}
                                  placeholder="https://"
                                />
                                {inputValues.headerUrl.length > 0 && <img name="headerUrl" className={'close-icon'} src={"/Icon_assets/svg/close-image.svg"} alt="close"
                                  onClick={(e) => clearText(e)} />}
                                <FormFeedback>{validators.headerUrl.errorMsg}</FormFeedback>
                              </div>
                            </div>
                          </FormGroup>
                          :
                          <div>
                            {uploadHeader}
                            <Input
                              type="file"
                              style={{ top: "45%" }}
                              name="headerImage"
                              id="headerImage"
                              className="custom-file-upload-input"
                              onChange={(e) => handleImageUpload(e)}
                            />
                          </div>}
                    </div>
                    <hr />
                    <div className="media-div">
                      <Label className="logo" style={{ opacity: inputValues.advertiser === "" ? 0.3 : 1 }}>Logo Image</Label>
                      {imageLoading && logoImage ?
                        <Loader style={{ padding: "0px" }} />
                        :
                        inputValues.logoImage ?
                          <FormGroup style={{ display: 'block' }}>
                            <div>
                              <div className="selection-div">
                                <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                                <span className="span-label">{inputValues.logoImage}</span>
                                <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close" onClick={(e) => removeSelection(e, 'logo')} />
                              </div>
                              <div className="image-div">
                                <Label className={`floating-label`}>Destination URL</Label>
                                <Input
                                  type="text"
                                  name="logoUrl"
                                  id="logoUrl"
                                  required
                                  className="form-input form-input-sm input-font"
                                  autoComplete="off"
                                  value={inputValues.logoUrl}
                                  onChange={handleInputChange}
                                  placeholder="https://"
                                />
                                {inputValues.logoUrl.length > 0 && <img name="logoUrl" className={'close-icon'} src={"/Icon_assets/svg/close-image.svg"} alt="close"
                                  onClick={(e) => clearText(e)} />}
                                <FormFeedback>{validators.logoUrl.errorMsg}</FormFeedback>
                              </div>
                            </div>
                          </FormGroup>
                          :
                          <div>
                            {uploadLogo}
                            <Input
                              type="file"
                              style={{ top: "45%" }}
                              name="logoImage"
                              id="logoImage"
                              className="custom-file-upload-input"
                              onChange={(e) => handleImageUpload(e)}
                            />
                          </div>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </>
          }
        </>
      }
    </>
  );
}

export default GeneralSetup;