import { id } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  Input,
  FormGroup,
  Label,
  UncontrolledTooltip,
  FormFeedback
} from "reactstrap";
import MultiSelectBox from "../../ADSelect/MultiSelectBox";
import SelectBox from "../../ADSelect/SelectBox";

import "./index.scss";

const MetricFilter = (props) => {


  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/black_circle_close_icon.svg"
  }

  const [MetricFilterDropdownOpen, setMetricFilterDropdownOpen] = useState(false);
  const toggleMetricFilter = () => setMetricFilterDropdownOpen(prevState => !prevState);
  const [RuleFilterDropdownOpen, setRuleFilterDropdownOpen] = useState(false);
  const toggleRuleFilter = () => setRuleFilterDropdownOpen(prevState => !prevState);
  const [searchValue, setSearchValue] = useState('');
  const [metricsOptions, setMetricOptions] = useState(props.OptionsList);

  const rulesArr = [
    { value: 0, label: '<' },
    { value: 1, label: '>' },
    { value: 2, label: '=' },
    { value: 3, label: '!=' }
  ];

  //Search metrics
  const handleMetricsSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    result = props.OptionsList && props.OptionsList?.length > 0 && props.OptionsList?.filter((data) => {
      return data.value.toLowerCase().search(value) != -1;
    });

    let resultData = [...result];
    setMetricOptions(resultData);
  }

  return (<>
    <Row className="mt-2 filter-container metrics-filter-row" id={`metrics-filter-row-${props.index}`}>
      <Col md={6} sm={12} lg={3}>
        <div className="option-selector metric-wrapper">
          <Dropdown
            isOpen={MetricFilterDropdownOpen}
            toggle={toggleMetricFilter}
            className="dropdown-option metrics"
            direction="down"
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              className="dropdown_toggle"
            >
              <span
                className="dropdown_title"
                title="Select metric"
              >
                {metricsOptions?.find(data => data.id === props.filter.metric) ? metricsOptions?.find(data => data.id === props.filter.metric).value : "Select Filter"}
              </span>
              <img
                src={
                  MetricFilterDropdownOpen
                    ? statusIcons.ARROW_UP
                    : statusIcons.ARROW_DOWN
                }
                className="dropdown-img"
              ></img>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="search-item" toggle={false}>
                <FormGroup className="search-metrics">
                  <Input
                    value={searchValue}
                    type="text"
                    name="search"
                    id="searchUser"
                    placeholder="Search..."
                    className="search-input"
                    autoComplete="off"
                    onChange={(event) => handleMetricsSearch(event)}
                  />
                  <img
                    src="/Icon_assets/svg/search-organization-icon.svg"
                    alt="Search"
                    className="search-image"
                  />
                </FormGroup>
              </DropdownItem>
              <div className="metrics-list">
                {metricsOptions && (
                  metricsOptions?.length > 0 ?
                    metricsOptions?.map((item, index) => {
                      return (
                        <DropdownItem
                          key={"metrics" + item.id}
                          id={"metrics" + item.value + index}
                          onClick={(e) => props.handleReportChange('metric', item.id, props.index, "metricFilter")}
                          className={`${item.id === props.filter.metric ? "dropdown-option-selected" : ""}`}
                        >
                          <div className="options-container">
                            <Label className="custom-control-label">
                              {item.value}
                            </Label>
                            {item.id === props.filter.metric ? (<img
                              src={statusIcons.CHECKED_BLUE_ICON}
                              alt="metrics selected"
                              className="option-selected"
                            />) : ""}
                          </div>
                        </DropdownItem>
                      )
                    }) : (<DropdownItem>No matches found</DropdownItem>)
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
          <FormFeedback>{props.filter.errorMsgMetric}</FormFeedback>
        </div>
      </Col>

      <Col md={6} sm={12} lg={3}>
        <div className="option-selector condition-wrapper d-flex">
          <>
            <Dropdown
              isOpen={RuleFilterDropdownOpen}
              toggle={toggleRuleFilter}
              className="dropdown-option metrics"
              direction="down"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                className="dropdown_toggle"
              >
                <span
                  className="dropdown_title"
                  title="Select Condition"
                >
                  {rulesArr?.find(data => data.value === props.filter.rule) ? rulesArr?.find(data => data.value === props.filter.rule).label : "Condition"}
                </span>
                <img
                  src={
                    RuleFilterDropdownOpen
                      ? statusIcons.ARROW_UP
                      : statusIcons.ARROW_DOWN
                  }
                  className="dropdown-img"
                ></img>
              </DropdownToggle>
              <DropdownMenu>
                <div className="metrics-list">
                  {rulesArr && (
                    rulesArr?.map((item, index) => {
                      return (
                        <DropdownItem
                          key={"rules" + item.value}
                          id={"rules" + item.value + index}
                          onClick={(e) => props.handleReportChange('metricFilterRule', item.value, props.index, "metricFilter")}
                          className={`${item.value === props.filter.rule ? "dropdown-option-selected" : ""}`}
                        >
                          <div className="options-container">
                            <Label className="custom-control-label">
                              {item.label}
                            </Label>
                            {item.value === props.filter.rule ? (<img
                              src={statusIcons.CHECKED_BLUE_ICON}
                              alt="metrics selected"
                              className="option-selected"
                            />) : ""}
                          </div>
                        </DropdownItem>
                      )
                    })
                  )}
                </div>
              </DropdownMenu>
            </Dropdown>
            <FormFeedback>{props.filter.errorMsgRule}</FormFeedback>
          </>
          <div className="toggle-wrapper action-wrapper">
            <Input
              value={props.filter.value}
              type="text"
              name="value"
              id="value"
              className="value-input"
              autoComplete="off"
              onChange={(e) => props.handleReportChange('metricFilterValue', e.target.value, props.index, "metricFilter")}
            />
            <FormFeedback>{props.filter.errorMsgValue}</FormFeedback>

            <div className="close-button-wrapper">
              <img
                src={statusIcons.CLOSE_ICON}
                alt="Close Icon"
                className="close-filter"
                onClick={() => { props.removeFilter(props.index, 'metrics') }}
                id={`remove-metric-filter-${props.index}`}
              />
              <UncontrolledTooltip
                className="ad-tool-tip"
                placement="top"
                target={`remove-metric-filter-${props.index}`}
              >{"Remove filter"}
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
      </Col>

      <Col md={6} sm={12} lg={3}>
        <div className="toggle-wrapper action-wrapper">
        </div>
      </Col>
      <Col md={6} sm={12} lg={3}>
      </Col>
    </Row>
  </>)
}

export default MetricFilter;