import * as getAxios from "axios";
export const getVideoStreamBrand = (url) => {
  if (url) {
    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

    if (RegExp.$3.indexOf('youtu') > -1) {
        var type = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
        var type = 'vimeo';
    }
  }
  
  return {
      type: type,
      id: RegExp.$6
  };
}

export const getVideoThumbnail = async (url, cb) => {
  var videoObj = getVideoStreamBrand(url);
  if (videoObj.type == 'youtube') {
    return `http://img.youtube.com/vi/${videoObj.id}/hqdefault.jpg`
  } else if (videoObj.type == 'vimeo') {
    var videourl = `https://vimeo.com/api/v2/video/${videoObj.id}.json`;
    return await getVimeoData(videourl)
  }
}

async function getVimeoData(url) {
  try {
    const response = await fetch(url);
    var data = await response.json();
    var thumbnailURL = await data[0].thumbnail_small;
    return thumbnailURL;
  } catch (error) {
    console.log(error);
  }
}

/*export const generateVideoStream = (url, width = "100%", height="100%") => {
  var videoObj = getVideoStreamBrand(url);
  var $iframe = $('<iframe>', { width: width, height: height });
  $iframe.attr('frameborder', 0);
  if (videoObj.type == 'youtube') {
      $iframe.attr('src', '//www.youtube.com/embed/' + videoObj.id);
  } else if (videoObj.type == 'vimeo') {
      $iframe.attr('src', '//player.vimeo.com/video/' + videoObj.id);
  }
  return $iframe;
}*/