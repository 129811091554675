import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  Table,
  CardBody,
  Card,
  UncontrolledTooltip,
} from "reactstrap";
import { dashboardActionCreator } from "../../actions";
import Loader from "../../components/Loader";
import "./index.scss";

const TABLE_COLUMNS = [
  // {
  //   dataField: "status",
  //   text: "Status",
  // }, 
  {
    dataField: "flightdates",
    text: "Flight dates",
    className:""
  },
  {
    dataField: "Spend",
    text: "Spend",
    className:""
  },
  {
    dataField: "Impressions",
    text: "Impressions",
    className:""
  },
  {
    dataField: "Clicks",
    text: "Clicks",
    className:""
  },
  {
    dataField: "CPA",
    text: "CPA",
    className:""
  },
  {
    dataField: "CPC",
    text: "CPC",
    className:""
  },
  {
    dataField: "CPM",
    text: "CPM",
    className:""
  },
  {
    dataField: "CTR",
    text: "CTR",
    className:""
  },
  {
    dataField: "Conversion",
    text: "Conversions",
    className:""
  },
  {
    dataField: "hundred_Complete",
    text: "100% Complete",
    className:""
  },
  {
    dataField: "hundred_CompletionRate",
    text: "100% Completion Rate",
    className:" min-width-200"
  },
  {
    dataField: "Viewability",
    text: "Viewability",
    className:""
  },
];

const TOOLTIP_LABELS = [
  { label: 'CPM', value: "The cost per thousand impressions." },
  { label: 'STATUS', value: 'The status of the campaign.', },
  { label: 'Impressions', value: 'Number of times the ad has been seen.' },
  { label: 'CTR', value: 'Rate of clicks per number of impressions.' },
  { label: 'Conversions', value: 'Number of people who completed your desired campaign action.' },
  { label: 'CPA', value: 'Average cost of a user converting during a campaign.' },
  { label: 'CPC', value: 'Average cost of user click' },
  { label: '100% Complete', value: 'Number of video ad impressions that are played to completion.' },
  { label: '100% Completion Rate', value: 'Percentage of video ad impressions that are played to completion.' },
  { label: 'Viewability', value: 'Percentage of impressions where at least 50% of an ad was in-view for at least one second.' }
];

const CampaignTable = ({ dashboardDataRequestCompleted, dashboardData, selectedCampaignLineFilterSet, dateRange, displayColumns }) => {
  const dispatch = useDispatch();
  const tableHideShowLimit = 3;

  //expandedRows state handle which row is expanded
  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedRows, setExpandedRows] = useState([]);
  const [showAllCampaign, setShowAllCampaign] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);
  const [lineNameWidth, setLineNameWidth] = useState(document.getElementsByClassName('primary-col')[0] ? document.getElementsByClassName('primary-col')[0].getBoundingClientRect().width : EXPANDED_TABLE_COL_MIN_WIDTH)
  const [sortedColumn, setSortedColumn] = useState('CampaignName');
  const [sortType, setSortType] = useState('desc');
  const toggleShowRow = (CampaignId) => {
    if (CampaignId == expandedRows[0]) {
      setExpandedRows([]);
    } else {
      setExpandedRows([CampaignId]);
    }
    // const shownState = expandedRows.slice();
    // const index = shownState.indexOf(CampaignId);
    //If shownState has a CampaignId then will remove it from array else will add
    // index >= 0 ? shownState.splice(index, 1) : shownState.push(CampaignId);
    // setExpandedRows(shownState);
  };

  const statusIcons = {
    ACTIVE: "/Icon_assets/svg/green-circle.svg",
    ENDED: "/Icon_assets/svg/orange-circle.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
  };

  const sortData = (e, colName) => {
    var data = [];
    if (colName === 'flightdates') {

      var orders = [...new Set([...dashboardData])];
      if (sortType === 'desc') {
      orders.sort(function (a, b) {
        var dateA = new Date(formatDate(a.start_date)),
          dateB = new Date(formatDate(b.start_date));
        if (dateB < dateA) {
          return -1;
        } else {
          return 1;
        }
      });
    } else {
      orders.sort(function (a, b) {
        var dateA = new Date(formatDate(a.start_date)),
          dateB = new Date(formatDate(b.start_date));
        if (dateB > dateA) {
          return -1;
        } else {
          return 1;
        }
      });
    }
      data = orders;
    } else {
     data = sortArrayData(colName,sortType === 'desc' ? true : false);
    }
    if (data && data.length >= tableHideShowLimit && showAllCampaign !== true){
      setCampaignData(data.slice(0, tableHideShowLimit));
      setShowAllCampaign(false);
    } else {
      setCampaignData(data);
    }

    // setCampaignData(data);
    if (sortType === 'asc') {
      setSortType('desc');
    } else {
      setSortType('asc');
    }
    setSortedColumn(colName);
  }

  const sortArrayData = (prop, asc) => {
    var orders = [...new Set([...dashboardData])]
    orders.sort(function (a, b) {
      if (asc) {
        return (typeof a[prop] === 'string' && typeof b[prop] === 'string') ?
          (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0)
          :
          (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      } else {
        return (typeof a[prop] === 'string' && typeof b[prop] === 'string') ?
          (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0)
          : (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);;
      }
    });
    return orders;
  }

  useEffect(() => {
    var orders = [];
    if (dashboardData && dashboardData.length) {
      orders = [...new Set([...dashboardData])];
      orders = sortArrayData(sortedColumn, sortType === "asc" ? true : false, orders);
    }
    if (dashboardData && dashboardData.length >= tableHideShowLimit && showAllCampaign !== true) {
      setCampaignData(orders.slice(0, tableHideShowLimit));
      setShowAllCampaign(false);
    }else{
      setCampaignData(orders);
    }

    if(dashboardData.length > 0 && expandedRows.length > 0){
      setExpandedRows([]);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (expandedRows.length > 0 && dashboardData.length > 0) {
      dispatch(
        dashboardActionCreator.dashboardCampaignMetricDataRequest({expandedRows, selectedCampaignLineFilterSet, dateRange}
        )
      );
    }
  }, [expandedRows, selectedCampaignLineFilterSet]);

  useEffect(() => {
    setLineNameWidth(document.getElementsByClassName('primary-col')[0].getBoundingClientRect().width);
  });

  const dashboardCampaignMetricData = useSelector(
    (state) => state.dashboard.dashboardCampaignMetricData
  );

  const dashboardCampaignMetricDataRequestCompleted = useSelector(
    (state) => state.dashboard.dashboardCampaignMetricDataRequestCompleted
  );

  const formatDate = (number) => {
    var date = number;
    date = date.split("/").map((e) => (e[0] == "0" ? e.slice(1) : e));
    date = date[1] + "/" + date[0] + "/" + date[2];
    return date;
  };

  const handleViewAllCampaings = (e) => {
    e.preventDefault();
    var orders = [...new Set([...dashboardData])];
    let data = [];
    if(sortType === 'asc') {
      data = sortArrayData(sortedColumn, true, orders);
    } else {
      data = sortArrayData(sortedColumn, false, orders);
    }
    if (showAllCampaign) {
      setCampaignData(data.slice(0, tableHideShowLimit));
      setShowAllCampaign(false);
    }else{
      setCampaignData(data);
      setShowAllCampaign(true);
    }
  };
  /* Resize column setup for table-main start*/
  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("table-main");
    var tableHeight = table.offsetHeight;
    // For resizing column, add dummy div to header, add event when clicked
    Array.prototype.forEach.call(
      table.querySelectorAll(".resized-column"),
      function (th) {
        //th.style.position = 'relative';

        var grip = document.createElement('div');
        grip.className = 'column-resizer';
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.height = tableHeight + 'px';
        grip.style.position = 'absolute';
        grip.style.zIndex = "1";
        grip.style.cursor = 'col-resize';
        grip.addEventListener('mousedown', function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      });

    // listener for dragging
    table.addEventListener('mousemove', function (e) {
      if (thElm) {
        if(startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH){
          thElm.style.width = startOffset + e.pageX + 'px';
          thElm.style.minWidth = startOffset + e.pageX + 'px';
        }

        let expandedColumnWidth = (startOffset + e.pageX) + 20;
        if(expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH){
          setExpandedTableColWidth(expandedColumnWidth);
        }
      }
    });

    // listner for releasing click
    table.addEventListener('mouseup', function () {
      thElm = undefined;
    });
  },[])
  /* Resize column setup for table-main end*/

  return (
    <>
      <div className="ad-table-container">
        <Table className="dashboard-table mb-0" borderless={true} id="table-main">
          <thead className="dashboard-table-header">
            <tr>
              <th className={"sticky-col resized-column primary-col"} onClick = {(e)=> sortData(e, 'CampaignName')}>
              <span>Campaign Name</span>
              <img style={{paddingLeft: "7px",paddingTop: "4px"}}
              src={sortedColumn === 'CampaignName' ? 
              (sortType === 'desc' ? 
              "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
              : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" />
                {/* <span style={{position: "absolute",right: "0",color: "#C4C4C4"}}>||</span>  */}
                <div className="resizer-icon">
                  <span className="first"></span>
                  <span className="second"></span>
                </div>
              </th>
              {TABLE_COLUMNS.map((d, i) => {
                var tipData = TOOLTIP_LABELS.filter(function (t) { return t.label.toLowerCase() === d.text.toLowerCase() });
                if (tipData !== undefined && tipData.length !== 0) {
                  return (<th key={i} className={displayColumns.includes(d.text) ? "" : "d-none" + d.className}
                  onClick = {(e)=> sortData(e, d.dataField)}>
                    <div className="campaign-meta-wrapper">
                      <span style={{ flexShrink: 1 }}>{d.text}</span>
                      <img src={"/Icon_assets/svg/dashboard-info-icon.svg"} alt="Info icon" id={`opentooltip-${i}`} className="index-tip" />
                      <img src={sortedColumn === d.dataField ?
                        (sortType === 'desc' ?
                          "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
                        : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" />
                    </div>
                    <UncontrolledTooltip
                      className="ad-tool-tip"
                      placement="top"
                      target={`opentooltip-${i}`}
                      modifiers={{preventOverflow: {boundariesElement: 'window'}}}
                      style={{ fontFamily: 'BasisGrotesque' }}>{tipData[0].value.includes('\n') ? 
                      tipData[0].value.split('\n').map(str => <>{str}<br/></>)
                       : tipData[0].value}
                    </UncontrolledTooltip>
                  </th>)
                }
                return <th key={i} className={displayColumns.includes(d.text) ? "" : "d-none"} onClick = {(e)=> sortData(e, d.dataField)}>
                  <div className="campaign-meta-wrapper">
                    <span>{d.text}</span>
                    <img src={sortedColumn === d.dataField ?
                      (sortType === 'desc' ?
                        "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
                      : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" />
                  </div></th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body-content">
            {dashboardDataRequestCompleted ? (
              campaignData && campaignData.length > 0 ? (
                campaignData.map((d, i) => {
                  return (
                    <React.Fragment key={'main-table-'+i}>
                    <tr className="td-line" key={'td-line-'+i}>
                        <td
                          className={"campaign-name primary-col-first"}
                        //title={d.CampaignName}
                        >
                          <div className="campaign-meta-wrapper">
                            <img
                              src={
                                expandedRows.includes(d.CampaignId)
                                  ? statusIcons.ARROW_UP
                                  : statusIcons.ARROW_DOWN
                              }
                              className="arrow-up-down"
                              onClick={() => {
                                toggleShowRow(d.CampaignId);
                              }}
                            />
                            <img
                              src={
                                d.Status == "ACTIVE"
                                  ? statusIcons.ACTIVE
                                  : statusIcons.ENDED
                              }
                              alt="active"
                              className="status-icon"
                              title={d.Status}
                            />
                            <span
                              id={`tooltip-main-title-${d.CampaignId}`}
                              className="campaign-ellipis"
                            >
                              {d.CampaignName}
                            </span>
                          </div>
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target={`tooltip-main-title-${d.CampaignId}`}
                          >
                            {d.CampaignName}
                          </UncontrolledTooltip>
                        </td>
                        <td className={(displayColumns.includes("Flight dates") ? '': 'd-none') +" date"}>
                          {formatDate(d.start_date) +
                            "-" +
                            formatDate(d.end_date)}
                        </td>
                        <td className={displayColumns.includes("Spend") ? '': 'd-none'}>${d.Spend.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                        <td className={displayColumns.includes("Impressions") ? '': 'd-none'}>{d.Impressions.toLocaleString()}</td>
                        <td className={displayColumns.includes("Clicks") ? '': 'd-none'}>{d.Clicks.toLocaleString()}</td>
                        <td className={displayColumns.includes("CPA") ? '': 'd-none'}>${d.CPA.toFixed(2)}</td>
                        <td className={displayColumns.includes("CPC") ? '': 'd-none'}>${d.CPC.toFixed(2)}</td>
                        <td className={displayColumns.includes("CPM") ? '': 'd-none'}>${d.CPM.toFixed(2)}</td>
                        <td className={displayColumns.includes("CTR") ? '': 'd-none'}>{d.CTR.toFixed(2)}%</td>
                        <td className={(displayColumns.includes("Conversions") ? '': 'd-none' )}>
                          {d.Conversion.toLocaleString()}
                        </td>
                        <td className={(displayColumns.includes("100% Complete") ? '': 'd-none') +" align-data"}>
                          {d.hundred_Complete.toLocaleString()}
                        </td>
                        <td className={(displayColumns.includes("100% Completion Rate") ? '': 'd-none') +" align-data"} style={{minWidth:'235px'}}>
                          {d.hundred_CompletionRate.toFixed(2)}%
                        </td>
                        <td className={(displayColumns.includes("Viewability") ? '': 'd-none')  }>
                          {d.Viewability.toFixed(2)}%
                        </td>
                      </tr>
                      {expandedRows.includes(d.CampaignId) && (
                        <tr
                          className="campaign-meta-row"
                          key={`campaign-meta-row-${i}`}
                        >
                          <td
                            colSpan={TABLE_COLUMNS.length + 1}
                            className="campaign-meta-col"
                          >
                            <Collapse
                              isOpen={expandedRows.includes(d.CampaignId)}
                            >
                              <Card>
                                <CardBody>
                                  <Table
                                    className="dashboard-table  mb-0"
                                    borderless={true}
                                    id="table-expanded"
                                  >
                                    <thead>
                                      <tr key={`campaign-meta-row-head-${i}`}>
                                        <th
                                          className={"sticky-col campaign-name expanded-resized-column secondary-col"}
                                          //title={d.CampaignName}
                                          style={{ width: `${lineNameWidth}px`, minWidth: `${lineNameWidth}px` }}
                                        >
                                          <span>Line name</span>  
                                          <img style={{ paddingLeft: "7px", paddingTop: "4px", visibility:"hidden"}}
                                            src={sortedColumn === 'CampaignName' ?
                                              (sortType === 'desc' ?
                                                "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
                                              : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" />
                                        </th>
                                        {/* to fix alignment issue */}
                                        {TABLE_COLUMNS.map((d, i) => {
                                          var tipData = TOOLTIP_LABELS.filter(function (t) { return t.label.toLowerCase() === d.text.toLowerCase() });
                                          if (tipData !== undefined && tipData.length !== 0) {
                                            return (<th className={displayColumns.includes(d.text) ? "" : "d-none"} key={i} style={{ visibility: "hidden" }} >
                                              <div className="campaign-meta-wrapper" style={{ visibility: "hidden" }} >
                                                <span style={{ flexShrink: 1 }}>{d.text}</span>
                                                <img src={"/Icon_assets/svg/dashboard-info-icon.svg"} alt="Info icon" id={`opentooltip-${i}`} className="index-tip" style={{ visibility: "hidden" }} />
                                                <img src={sortedColumn === d.dataField ?
                                                  (sortType === 'desc' ?
                                                    "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
                                                  : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" />
                                              </div>
                                            </th>)
                                          }
                                          return <th className={displayColumns.includes(d.text) ? "" : "d-none"} key={i} style={{ visibility: "hidden" }}>
                                            <div className="campaign-meta-wrapper"><span>{d.text}</span>
                                              <img src={sortedColumn === d.dataField ?
                                                (sortType === 'desc' ?
                                                  "/Icon_assets/svg/desc_sort_selected.svg" : "/Icon_assets/svg/asc_sort_selected.svg")
                                                : "/Icon_assets/svg/desc_sort.svg"} alt="desc icon" className="index-tip" /></div></th>;
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dashboardCampaignMetricDataRequestCompleted ? (
                                        dashboardCampaignMetricData ? (
                                          dashboardCampaignMetricData.map(
                                            (data, index) => {
                                              return (
                                                <tr
                                                  className="td-line"
                                                  key={`campaign-meta-row-expand-${index}`}
                                                >
                                                  <td
                                                    className= {"campaign-name secondary-col-first"}
                                                  //title={data.CampaignName}
                                                  >
                                                    <span
                                                      id={`tooltip-meta-name-${index}`}
                                                    >
                                                      {data.LineName}
                                                    </span>
                                                    <UncontrolledTooltip
                                                      className="ad-tool-tip"
                                                      placement="top"
                                                      target={`tooltip-meta-name-${index}`}
                                                    >
                                                      {data.LineName}
                                                    </UncontrolledTooltip>
                                                  </td>
                                                  <td className={(displayColumns.includes("Flight dates") ? '': 'd-none') + " date"}>
                                                    {formatDate(d.start_date) +
                                                      "-" +
                                                      formatDate(d.end_date)}
                                                  </td>
                                                  <td className={(displayColumns.includes("Spend") ? '': 'd-none')}>
                                                     ${data.Spend.toLocaleString('en-US', {maximumFractionDigits:2})}
                                                  </td>
                                                  <td  className={(displayColumns.includes("Impressions") ? '': 'd-none')}>
                                                    {data.Impressions.toLocaleString()}
                                                  </td>
                                                  <td  className={(displayColumns.includes("Clicks") ? '': 'd-none')}>
                                                    {data.Clicks.toLocaleString()}
                                                  </td>
                                                  <td  className={(displayColumns.includes("CPA") ? '': 'd-none')}>
                                                    ${data.CPA.toFixed(2)}
                                                  </td>
                                                  <td  className={(displayColumns.includes("CPC") ? '': 'd-none')}>
                                                    ${data.CPC.toFixed(2)}
                                                  </td>
                                                  <td  className={(displayColumns.includes("CPM") ? '': 'd-none')}>
                                                    ${data.CPM.toFixed(2)}
                                                  </td>
                                                  <td  className={(displayColumns.includes("CTR") ? '': 'd-none')}>
                                                    {data.CTR.toFixed(2)}%
                                                  </td>
                                                  <td  className={(displayColumns.includes("Conversions") ? '': 'd-none') }>
                                                    {data.Conversion.toLocaleString()}
                                                  </td>
                                                  <td className={(displayColumns.includes("100% Complete") ? '': 'd-none') + " align-data"}>
                                                    {data.hundred_Complete.toLocaleString()}
                                                  </td>
                                                  <td className={(displayColumns.includes("100% Completion Rate") ? '': 'd-none') + " align-data"}>
                                                    {data.hundred_CompletionRate.toFixed(
                                                      2
                                                    )}
                                                    %
                                                  </td>
                                                  <td className={(displayColumns.includes("Viewability") ? '': 'd-none') }>
                                                    {data.Viewability.toFixed(
                                                      2
                                                    )}
                                                    %
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr className="td-line">
                                            <td
                                              className="align-data"
                                              colSpan={TABLE_COLUMNS.length}
                                            >
                                              <p className="no-wallet-data-text">
                                                No table data available
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      ) : (
                                        <tr className="td-line">
                                          <td
                                            className="align-data"
                                            colSpan={TABLE_COLUMNS.length}
                                          >
                                              <Loader />
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr className="td-line">
                  <td className="align-data" colSpan={TABLE_COLUMNS.length+1}>
                    <p className="no-wallet-data-text text-center">
                      No table data available
                    </p>
                  </td>
                </tr>
              )
            ) : (
              <tr className="td-line">
                <td className="align-data" colSpan={TABLE_COLUMNS.length}>
                    <Loader />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {dashboardDataRequestCompleted && dashboardData && dashboardData.length > tableHideShowLimit && (
        <div className="text-center mt-3 view-all-btn-wrapper">
          <button
            className="in-btn-link btn btn-link"
            onClick={ handleViewAllCampaings }
          >
            {showAllCampaign ? 'Hide all campaigns' : 'View all campaigns'}
          </button>
        </div>
      )}
    </>
  );
};

export default CampaignTable;
