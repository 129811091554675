
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonScheduleReport = ({rows=4}) => {
  return (
    <section className="skeleton-schedule-report-wrapper skeleton-wrapper">
      <div className="schedule-report">
        <section className='field'>
          <SkeletonElement type="title" />
        </section>
        <section className='field'>
          <SkeletonElement type="title" />
        </section>
        <section className='field'>
          <SkeletonElement type="title" />
        </section>
        <section className='field'>
          <div className='row'>
            <div className='col-4'><SkeletonElement type="title" /></div>
            <div className='col-4'><SkeletonElement type="title" /></div>
            <div className='col-4'><SkeletonElement type="title" /></div>
          </div>
          <div className='row'>
            <div className='col-12'><SkeletonElement type="title" /></div>
          </div>
        </section>
        <section className='field'>
          <div className='row'>
            <div className='col-3'><SkeletonElement type="title" /></div>
            <div className='col-3'><SkeletonElement type="title" /></div>
          </div>
        </section>
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonScheduleReport;