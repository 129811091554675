import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  FormGroup,
  Form,
  CustomInput
} from 'reactstrap'
import './index.scss'



const statusIcons = {
  ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
  ARROW_DOWN: "/Icon_assets/svg/arrow-down-filter.svg",
  ADD: "/Icon_assets/svg/add-circle-button.svg",
  ADD_DISABLED: "/Icon_assets/svg/add-circle-button-disabled.svg",
  REMOVE: "/Icon_assets/svg/remove-circle-button.svg",
}

const ZipCodesFilter = (props) => {

  const [isZipCodeFilterOpen, setIsZipCodeFilterOpen] = useState(false)
  const [zipCodes, setZipCodes] = useState([])
  const [selectedZipCodes, setSelectedZipCodes] = useState([])
  const [addZipCodeText, setAddZipCodeText] = useState('');

  const toggleZipCodeFilter = () => {
    if (isZipCodeFilterOpen)
      props.setSelectedZipCodes(selectedZipCodes)
    setIsZipCodeFilterOpen(prev => !prev)
  }

  const addZipCodeTextToList = () => {
    setZipCodes(prev => [...prev, addZipCodeText])
    setSelectedZipCodes(prev => [...prev, addZipCodeText])
    setAddZipCodeText('')
  }

  const removeZipCodeFromList = (zipCode) => {
    setZipCodes(zipCodes.filter(zc => zc !== zipCode))
    setSelectedZipCodes(selectedZipCodes.filter(selectedZipCode => selectedZipCode !== zipCode))
  }

  const handleZipCodeSelect = (zipCode) => {
    if (selectedZipCodes.includes(zipCode))
      setSelectedZipCodes(selectedZipCodes.filter(selectedZipCode => selectedZipCode !== zipCode))
    else
      setSelectedZipCodes(prev => [...prev, zipCode])
  }

  const handleZipCodeSelectAll = () => {
    if (selectedZipCodes.length === zipCodes.length)
      setSelectedZipCodes([])
    else
      setSelectedZipCodes(zipCodes)
  }

  const clearFilter = (e) => {
    e.stopPropagation();
    props.setSelectedZipCodes([]);
    setSelectedZipCodes([])
  }

  useEffect(() => {
    if (props.isDisplayFilterRemoved)
      setSelectedZipCodes(props.selectedZipCodes)
  }, [props.selectedZipCodes])

  return (
    <div className='ad-zipcode-filter-container'>
      <Dropdown className="d-flex" isOpen={isZipCodeFilterOpen} toggle={toggleZipCodeFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div>
              <span className='dt-title'> Zip Code</span>
              {selectedZipCodes?.length > 0 && <span className='filter-count'> {selectedZipCodes?.length}</span>}
            </div>
            <div>
              {selectedZipCodes?.length > 0 && <img style={{ display: "inline-block" }}
                onClick={(e) => clearFilter(e)}
                toggle={false}
                src={"/Icon_assets/svg/close-image.svg"}
                className="arrow-down"
                alt='zip-code'
              />}
              <img style={{ display: "inline-block", margin: '0 12px', transform: isZipCodeFilterOpen ? 'rotate(180deg)' : '' }}
                src={
                  statusIcons.ARROW_DOWN
                }
                className="arrow-down"
                alt='zip-code'
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu>


          <div className='zip-code-list' id="zipCodeDropDown">
            {zipCodes.length !== 0 && <DropdownItem toggle={false}>
              <div style={{ height: '40px', width: '100%', display: 'flex' }}>
                <CustomInput
                  type="checkbox"
                  style={{ alignSelf: 'center', width: '100%' }}
                  key={'zip-code-checkbox-select-all'}
                  id={'zip-code-checkbox-select-all'}
                  checked={selectedZipCodes.length === zipCodes.length}
                  onChange={() => handleZipCodeSelectAll()}
                  label={'All'}
                />
              </div>
            </DropdownItem>}{
              zipCodes.map((zipCode, index) => <DropdownItem toggle={false}>
                <div style={{ height: '40px', width: '100%', display: 'flex' }}>
                  <CustomInput
                    type="checkbox"
                    style={{ alignSelf: 'center', width: '100%' }}
                    key={'zip-code-checkbox-select' + index}
                    id={'zip-code-checkbox-select' + index}
                    checked={selectedZipCodes.includes(zipCode)}
                    onChange={() => handleZipCodeSelect(zipCode)}
                    label={zipCode}
                  />
                  <div style={{ alignItems: 'center' }} onClick={() => removeZipCodeFromList(zipCode)}>
                    <img src={statusIcons.REMOVE} alt='remove'></img>
                  </div>
                </div>
              </DropdownItem>)
            }
            <DropdownItem toggle={false}>
              <Form className="ad-form">
                <FormGroup>
                  <div style={{ display: 'flex' }}>
                    <Input
                      type="number"
                      name="add-zip"
                      id="add-zip"
                      placeholder='e.g. 63243, 85241'
                      value={addZipCodeText}
                      onChange={(e) => setAddZipCodeText(e.target.value)}
                      invalid={addZipCodeText.length !== 5 && addZipCodeText.length !== 0}
                      className="form-input"
                      autoComplete="off"
                    />
                    <div style={{ alignItems: 'center', display: 'flex' }} onClick={addZipCodeText.length === 5 && !zipCodes.includes(addZipCodeText) ? addZipCodeTextToList : false}>
                      <img src={addZipCodeText.length === 5 && !zipCodes.includes(addZipCodeText) ? statusIcons.ADD : statusIcons.ADD_DISABLED} alt='add'></img>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default ZipCodesFilter