import React, { useState, useEffect, useRef } from 'react'
import {
  FormGroup,
  Input,
  CustomInput,
  Popover,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { organizationActionCreator, userActionCreator, notificationActionCreator } from '../../../actions';
import SkeletonTable from '../../Loader_Skeleton/SkeletonTable'
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.scss'
import history from "../../../history";
import ROUTES from "../../../utils/routeConstants";


function OrganizationSelection() {

  const dispatch = useDispatch()
  const ICONS = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    ORGANIZATION: "/Icon_assets/svg/organization_icon.svg"
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownData, setDropdownData] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [orgOffset, setOrgOffset] = useState(0);
  const [selectedRole, setSelectedRole] = useState('');

  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const userRolesList = useSelector((state) => state.user.userRolesList?.results);
  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const organizationData = useSelector((state) => state.organization.organizationsInRole?.results);
  const organizationDataCount = useSelector((state) => state.organization.organizationsInRole?.count);
  const organizationDataNext = useSelector((state) => state.organization.organizationsInRole?.next);

  const isLoading = useSelector((state) => state.organization.isOrganizationsInRoleLoading);
  function toggle() {
    setDropdownOpen(!isDropdownOpen)

    if (!isDropdownOpen) {
      setOrgOffset(0)
      setDropdownData([])
      dispatch(organizationActionCreator.getOrganizationForRoleListRequest({ limit: 10 }))
    }

    else {
      setOrgOffset(0)
      setDropdownData([])
    }
  }


  useEffect(() => {
    //  dispatch(organizationActionCreator.getOrganizationForRoleListRequest({ limit: 10 }))
  }, [])


  function setSelectedOrganizationRole(id) {
    return userRolesList?.find((ur) => ur.id === userRbac?.find((ur) => ur.organization_id === id)?.role?.id)?.label
  }

  const getOrganizationRoleMap = () => {
    return organizationData?.filter(newOrg => userRbac?.some(oldOrg => oldOrg?.organization_id === newOrg?.id))?.map((org) => {
      return {
        ...org,
        role: {
          id: userRbac?.find((ur) => ur?.organization_id === org.id)?.role.id,
          name: userRolesList?.find((ur) => ur?.id === userRbac?.find((ur) => ur?.organization_id === org.id)?.role.id)?.label
        }
      }
    })
  }

  useEffect(() => {
    if (organizationData &&
      (userRbac?.length > 0 || userData?.organization_role === 0) &&
      userRolesList
    ) {
      if (userData.organization_role !== 0) {
        setDropdownData((prev) => ([...prev, ...getOrganizationRoleMap()]))
        setSelectedRole(setSelectedOrganizationRole(selectedOrganization.id))
      }
      else {
        setDropdownData((prev) => ([...prev, ...organizationData.map(org => { return { ...org, role: { name: 'Invisibly Admin' } } })]))
        setSelectedRole('Invisibly Admin')
      }
    }
  }, [organizationData, userRbac, userRolesList])

  useEffect(() => {
    if (
      (userRbac?.length > 0 || userData?.organization_role === 0) &&
      userRolesList
    ) {
      if (userData.organization_role !== 0) {
        setSelectedRole(setSelectedOrganizationRole(selectedOrganization.id))
      }
      else {
        setSelectedRole('Invisibly Admin')
      }
    }

  }, [userRbac, userRolesList])

  const setOrganizationDropdownOptions = () => {
    toggle()
    setSearchValue("")
  }

  const handleOrganizationScroll = () => {
    let params = {};
    params.limit = 10;
    params.offset = orgOffset + 10;
    if (searchValue.length > 0) {
      params.search = searchValue
    }

    dispatch(organizationActionCreator.getOrganizationForRoleListRequest(params));
    setOrgOffset(orgOffset + 10)
  }

  const searchDropDown = (event) => {
    setSearchValue(event.target.value)
    setDropdownData([])
    dispatch(organizationActionCreator.getOrganizationForRoleListRequest({ limit: 10, search: event.target.value }))
  }

  const handleOrganizationSelect = (id) => {
    if (id === 'invisibly-admin') {
      localStorage.setItem('selectedOrganization', JSON.stringify({}));
      dispatch(organizationActionCreator.setdOrganization({}));
      setSelectedRole('Invisibly Admin')
      dispatch(notificationActionCreator.setNotification({
        color: "info",
        message: `You have switched to Invisibly Admin`
      }));
    }
    else {
      let s = dropdownData.find((org) => org.id === id);
      setSelectedRole(setSelectedOrganizationRole(s.id));
      dispatch(notificationActionCreator.setNotification({
        color: "info",
        message: `You have switched to ${s.name} as ${setSelectedOrganizationRole(s.id) || 'Invisibly Admin'}`
      }));

      localStorage.setItem('selectedOrganization', JSON.stringify(s));
      dispatch(organizationActionCreator.setdOrganization(s));
      // if org is MD enabled then navogate to MB else RD
      if (s.is_media_enabled) {
        history.push(ROUTES.DASHBOARD);
      } else if (s.is_rtr_enabled) {
        history.push(ROUTES.REALTIME_RESEARCH);
      }
    }
    setOrgOffset(0)
    setDropdownData([])
  }

  return <div className='select-org'>
    <Dropdown isOpen={isDropdownOpen} toggle={setOrganizationDropdownOptions}>
      <DropdownToggle className='org-toggle'>
        <div className='dr-button'>
          <div className='selected-org'>
            <div className='selected-org-wrapper'>
              <div className='role-image'>
                <img src={ICONS.ORGANIZATION} alt="Organization" className='role-icon' />
              </div>
              <div className='role-text'>
                <div className='dr-title' style={{ margin: 0 }}>
                  <div className='org-title' id='openOrgNameTooltip'>{selectedOrganization?.name ? selectedOrganization?.name : 'Invisibly Admin'}</div>
                  {selectedOrganization?.name && <div className='dr-title sub'> {selectedRole}</div>}
                </div>
                <UncontrolledTooltip
                  className="ad-tool-tip"
                  placement="top"
                  target="openOrgNameTooltip"
                >
                  {selectedOrganization?.name ? selectedOrganization?.name : 'Invisibly Admin'}
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu  >
        <div >
          <FormGroup >
            <div className='search-wrapper'>
              <Input
                value={searchValue}
                type="text"
                name="search"
                id="searchUser"
                placeholder="Search..."
                className="search-input"
                autocomplete="off"
                onChange={(event) => { searchDropDown(event) }}
                disabled={false}
              />
              <img
                src="/Icon_assets/svg/search-organization-icon.svg"
                alt="Search"
                className="search-image"
              />
            </div>
            {dropdownData.length > 0 ? <div className="org_list" id="organizationDropDown" >
              {dropdownData?.length > 0 ? <>
                {userData?.organization_role === 0 &&
                  <div key={'select-admin'} className={"radio-list"} onClick={() => handleOrganizationSelect('invisibly-admin')} >
                    <CustomInput type="radio" name="radio1"

                      className={"custom-radio-input"}
                      id={`radio-select-admin`}
                      label={
                        <div>
                          <div className='dr-title' style={{ margin: '5px' }}>Invisibly Admin</div>
                        </div>
                      }
                      checked={selectedOrganization?.id === undefined}
                      value={'invisibly-admin'} />
                  </div>}
                <InfiniteScroll
                  dataLength={!isLoading ? organizationDataCount : 0}
                  next={handleOrganizationScroll}
                  // inverse={true}
                  hasMore={organizationDataNext !== null}
                  loader={<SkeletonTable rows={10} />}
                  scrollableTarget="organizationDropDown"
                >{
                    dropdownData?.map((data, index) => {
                      return <div style={{ width: '100%' }} key={index} className={"radio-list"} onClick={() => handleOrganizationSelect(data.id)}>
                        <CustomInput type="radio" name="radio1"
                          className={"custom-radio-input"}
                          style={{ width: '100%' }}
                          id={`radio-${index}`}
                          label={
                            <div style={{ width: '100%' }}>
                              <div className='dr-title' id={`Org-Name-Tooltip-${index}`}>{data.name}</div>
                              <div className='dr-title sub'>{data?.role ? data.role.name : 'Invisibly Admin'}</div>
                            </div>
                          }
                          checked={data.id === selectedOrganization.id}
                          value={data.id}
                        />
                        {(data.name.length > 50) && (
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target={`Org-Name-Tooltip-${index}`}
                          >
                            {data.name}
                          </UncontrolledTooltip>
                        )}
                      </div>
                    }
                    )}</InfiniteScroll>
              </> : <div>No Organization Found</div>}
            </div>
              :
              <div style={{ padding: "0 0px 0 25px" }}><SkeletonTable rows={4}></SkeletonTable></div>
            }

          </FormGroup>
        </div>
      </DropdownMenu>
    </Dropdown>
  </div>
}

export default OrganizationSelection