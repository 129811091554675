import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import Loader from '../../../Loader';
import ROUTES from "../../../../utils/routeConstants";
import history from "../../../../history";
import { useParams, useLocation } from "react-router-dom";
import { offerActionCreator, realtimeResearchActionCreator, surveyActionCreator } from "../../../../actions";
import { Row, Col, Card, CardBody, Button, Input, FormGroup, Label } from "reactstrap";
import { clickableScript } from "../../../../utils/previewMessageClickableScript";
import ADspinner from '../../../Loader/ADspinner';
import RBAC from "../../../../components/RBAC";
import { PERMISSIONS } from "../../../../utils/permissionMatrix";

const RTROfferPreview = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let msg_id, ad_id, brand_id = "";
  let param = useParams();
  msg_id = param.msg_id;
  ad_id = param.ad_id;
  brand_id = param.brand_id;


  const { goToGeneralSetup } = props;

  if (props && (props.brand_id || props.offerId || props.ad_id)) {
    msg_id = props.offerId;
    ad_id = props.ad_id;
    brand_id = props.brand_id;
  }

  const shadow = "2px 8px 12px rgb(19 36 139 / 4%)";
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");

  const offerData = useSelector((state) => state.offer.offerData);
  const offerRequest = useSelector((state) => state.offer.offerRequest);
  const isLoading = useSelector((state) => state.offer.isLoading);
  const tableData = useSelector((state) => state.realtimeResearch.realtimeResearchData);

  const previewMessage = useSelector((state) => state.surveyData.previewSurvey);
  const isPreviewFetching = useSelector((state) => state.surveyData.isLoading);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [isActivationProgress, setIsActivationProgress] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState(props ? props.message : "");
  const [mediaURL, setMediaURL] = useState("");
  const [isActivated, setIsActivated] = useState(false);
  const msgbodyStyle = { height: '100%', overflow: "auto", marginTop: "0", background: "#9d5cff", minHeight: "400px" };

  window.closeSurvey = () => {
    setSurveyComplete(true);
  }
  const goToSurveyScreen = () => {
    var params = {
      brands: brand_id,
      messages: message
    };
    setActiveStep(2);
    dispatch(surveyActionCreator.getPreviewRequest(params));
  }

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      setMessage(location.state.detail.message);
    }
  }, [location]);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (previewMessage) {
      const frames = previewMessage && previewMessage.previews &&
        previewMessage.previews["frame_1"] ? previewMessage.previews["frame_1"] : "";

      let keys = Object.keys(frames),
        sizes, width, height;

      let sizeKey = [];
      keys.map((value, index) => {
        sizeKey = value === "size" ? keys.splice(index, 1) : "";
        return sizeKey;
      });


      const previewHtml =
        frames[keys[0]] ? frames[keys[0]] + clickableScript : '<div style=" height:150px; width: 150px;font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';

      if (sizeKey.length > 0) {
        sizes = frames && frames.size ? frames.size : "";
        height = sizes && sizes.height ? sizes.height : "";
        width = sizes && sizes.width ? sizes.width : "";
      }


      setPreviewHtml(previewHtml);
    } else {
      const previewHtml = '<div  style=" font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';
      setPreviewHtml(previewHtml);
    }

  }, [previewMessage]);

  useEffect(() => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }, []);

  const reload = () => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));

    params = {};
    params.brands = brand_id;
    params.messages = message;
    params.ad_id = ad_id;
    if (params.brands && params.messages)
      dispatch(surveyActionCreator.getExternalPreviewRequest(params));

    var iframe = document.getElementsByName('frame')[0];
    if (iframe)
      iframe.srcdoc = iframe.srcdoc;
    setSurveyComplete(false);
  }

  useEffect(() => {
    let params = {};
    params.brands = brand_id;
    params.messages = message;
    params.ad_id = ad_id;
    if (params.brands && params.messages)
      dispatch(surveyActionCreator.getExternalPreviewRequest(params));
  }, [])


  useEffect(() => {
    if (offerData?.id) {
      setMediaURL(offerData.media_1_url);
      setIsActivated(offerData?.state !== 1)
    }
    if (offerData && offerData.ad) {

      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({ message_id: offerData.ad }));
    }
  }, [offerData]);

  useEffect(() => {
    if (tableData) {
      setMessage(tableData.message);
      console.log(tableData)
    }
  }, [tableData]);

  const goToLastPlace = () => {
    if (props.hideHeader) {
      goToGeneralSetup();
    } else {
      history.push(`${ROUTES.OFFERS_LIST}`)
    }
  }

  const copyLink = () => {
    var copyText = document.getElementById("shareable-link");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  useEffect(() => {
    if (offerRequest?.state === 1 && isActivationProgress === true)
      setTimeout(() => {
        history.push(`${ROUTES.OFFERS_LIST}`)
      }, 2000)
    else {
      setTimeout(() => setIsActivationProgress(false), 2000)
    }
  }, [offerRequest])

  const activate = () => {
    setIsActivationProgress(true)
    let param = {};
    param.state = 1;
    param.data_id = offerData?.id;
    dispatch(offerActionCreator.createBrandOfferRequest(param));
  }

  return (<>
    <section className="survey-details-header">
      <nav className="left-nav-section title-section-with-back pb-3 campaign-header" style={{ paddingLeft: "48px" }}>
        <Button className="btn-transparent"
          onClick={goToLastPlace}
          style={{ verticalAlign: "bottom" }} >
          <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back"
            style={{ verticalAlign: props.hideHeader ? "bottom" : "super" }} />
        </Button>
        <h2 id={"survey-header"} style={{ display: "inline-block" }}
          className="mb-0 pl-4 secondary-title">Preview ({location?.state?.detail?.offer_name})</h2>
      </nav>
      <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
        <div className="text-right form-group">
          {isActivated &&
            <RBAC requiredPermissions={PERMISSIONS.OFFER_WRITE} >
              <Button className="in-btn-sm in-btn-info btn-export ml-3 btn-next"
                style={{ minWidth: '144px', minHeight: '48px' }}
                onClick={activate}>
                {isActivationProgress ? <ADspinner /> :
                  `Activate`}
              </Button></RBAC>}
        </div>
      </nav>
    </section>
    <Row>
      <Col>
        <div id="reload-survey" style={{ marginBottom: "20px" }}>
          <img onClick={reload}
            src="/Icon_assets/svg/reload-icon.svg"
            alt="share"
            className="link-image"
          />
          <span className="reload-survey" onClick={reload}>Reload Offer</span>
        </div>
      </Col>
    </Row>
    {
      activeStep === 0 ?
        <section style={{ margin: "auto", padding: "10px", cursor: "pointer" }} >
          <Card className="offer-card center-logo">
            {isLoading ?
              (<>
                <CardBody className="feed-card-body is-details-loading">
                  <div className="loading-data-section offer-data-loading">
                    <Loader />
                  </div>
                </CardBody>
              </>)
              :
              <CardBody>
                <div onClick={() => setActiveStep(1)}>
                  <section className="offer-wrapper">
                    <section className="header-banner-section">
                      <div className="card-img" id={`banner-${offerData.id}`}
                        style={{
                          backgroundImage: "url('" + mediaURL + "')",
                          backgroundSize: "100%",
                          maxHeight: "432px",
                          minHeight: "120px",
                          backgroundPosition: "center center",
                          maxWidth: "240px",
                          height: "135px",
                          backgroundPostion: "center top"
                        }}>
                        <img src="/Icon_assets/png/arc.png" alt="arc"
                          className="arc-style-img"
                          style={{
                            position: "absolute", bottom: "0px",
                            width: "100%", left: "0px", display: "none"
                          }} />
                      </div>
                    </section>
                    <section className="content-section survey-offer"
                      style={{ display: "block" }}>
                      <section className="brand-offer-title survey-offer">
                        <nav className="brand-card-name">{offerData.type === 2 ? `Survey` : `Brand Offer`}
                          <span className="brand-name" style={{ display: "none" }}>{offerData.brand_name}</span>
                        </nav>
                      </section>
                      <nav className="title-part">
                        <p id={`content-title${offerData.id}`}
                          className="title-content">{offerData.title}
                        </p></nav></section>
                  </section>
                  <section className="brand-offer-data-earn-option survey-offer">Earn
                    <img src="/Icon_assets/svg/Invisibly-token-purple-filled.svg" alt="Invisibly point" /> {offerData.monetary}
                  </section>
                </div>
              </CardBody>}
          </Card>
          <FormGroup>
            {/* <Col> */}
            <div id="link-div" >
              <Label for="shareable-link" className="shareable-link">Sharelabel Link:</Label>
              <Input
                type="text"
                name="link"
                id="shareable-link"
                className="share-input"
                autoComplete="off"
                value={`http://${window.location.host}/offers/preview-share/${localStorage.getItem('id')}/${localStorage.getItem('brand_id')}/${localStorage.getItem("ad_id")}`}
                readOnly={true} />
              <img
                src="/Icon_assets/svg/share-icon.svg"
                alt="share"
                className="link-image"
                style={{ cursor: "pointer" }}
                onClick={copyLink}
              />
            </div>
            {/* </Col> */}
          </FormGroup>
        </section>
        :
        activeStep === 1 ?
          isLoading ?
            (<>
              <CardBody className="feed-card-body is-details-loading">
                <div className="loading-data-section offer-data-loading">
                  <Loader />
                </div>
              </CardBody>
            </>) :
            <div
              // onClick={() => setActiveStep(2)}
              className="feeds-details-wrapper">
              <section className="feeds-details-section d-flex">
                <div className="feeds-details-card w-100 survey-brand-card survey card">
                  <CardBody className="feed-card-body">
                    <div>
                      <div className="image-column">
                        <div className="feeds-image-container bg-white">
                          <div className="selected-image-container">
                            <div className="selected-image-wrapper">
                              <div className="selected-image selected-video">
                                <img src={mediaURL}
                                  alt="offer-title" className="selected-feed-image" />

                                <div className="earn-badge-wrapper">
                                  <div className="earn-badge">
                                    <div className={`badge survey`}>
                                      Earn
                                      <img src={"/Icon_assets/svg/Invisibly-token-purple-filled.svg"}
                                        style={{ paddingLeft: "5px" }} alt="survey" className="badge-icon" />
                                      {offerData?.monetary}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content-column" id="content-column">
                        <div className="feeds-content-container">
                          <div className="feeds-content-container">
                            <p title=""
                              className="feeds-category card-text"><small className="feeds-category-text">
                                Survey</small>
                            </p>
                            <h1 title="offer-title" className="feeds-title card-title">{offerData.title}</h1>
                            <p className="feeds-description feeds-description-collapsed free-content survey card-text">{offerData.description}</p>
                            <Button type="button" onClick={goToSurveyScreen}
                              className="in-btn-sm in-btn-primary in-btn btn-secondary 
                          btn-redirect btn-offer btn btn-secondary"> {offerData.button_text}</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </section>
            </div>
          :
          <div>
            <Row>
              <Col>
                <div style={{
                  background: "#9d5cff", color: "#fff",
                  fontSize: "18px", height: "15%", paddingTop: "40px",
                  display: surveyComplete ? "none" : "flex",
                }} className="survey-text">
                  <p>Survey</p>
                </div>
                <div className="iframe-div" style={msgbodyStyle}>
                  {
                    isPreviewFetching ?
                      <Loader />
                      :
                      <>
                        <div className="container" style={{ display: surveyComplete ? "block" : "none" }}>
                          <p className="vertical-center">Congratulations! You have completed the survey.</p>
                        </div>

                        <iframe frameBorder="0" title="previewIframe" name="frame"
                          style={{
                            width: `100%`,
                            height: `100%`,
                            boxShadow: `${shadow}`,
                            minHeight: `620px`,
                            display: surveyComplete ? "none" : "block",
                          }}
                          srcdoc={previewHtml} scrolling='no'>
                        </iframe>
                      </>
                  }
                </div>
              </Col>
            </Row>



          </div>
    }
  </>);

}

export default RTROfferPreview;