import ACTIONS from "../constants/actionTypes";

export const getStatesRequest = (data) => {
  return {
    type: ACTIONS.STATES_REQUEST,
    payload: data,
  };
};

export const getStatesSuccess = (data) => {
  return {
    type: ACTIONS.STATES_SUCCESS,
    payload: data,
  };
};

export const getCitiesRequest = (data) => {
  return {
    type: ACTIONS.CITY_REQUEST,
    payload: data,
  };
};

export const getCitiesSuccess = (data) => {
  return {
    type: ACTIONS.CITY_SUCCESS,
    payload: data,
  };
};

export const getCitiesFailureClear = (data) => {
  return {
    type: ACTIONS.CITY_FAILURE_CLEAR,
    payload: data,
  };
};