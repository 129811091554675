import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import { Table } from "reactstrap";
import AnalyticsAction from "./AnalyticsAction";
import ActionDropdown from "./ActionDropdown";
import PopUpModal from "../../PopUpModal";
import UniversalTag from "./UniversalTag";
import CloneRtrAd from "./CloneRtrAd";
import DeleteRtrAd from "./DeleteRtrAd";
import StatusDropdown from "./StatusDropdown";
import { realtimeResearchActionCreator, offerActionCreator } from "../../../actions";
import "./index.scss";
import PreviewAction from "./PreviewAction";
import CAMPAIGN_STATES from '../../../utils/campaignStates';
import RBAC from "../../../components/RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";
import checkRBAC from '../../../utils/checkRBAC';

const AdminSurveyTable = ({ tableData, sortColumnData, sortedColumn, sortType }) => {
  const [currentAD, setCurrentAD] = useState({});
  const [openUniversalTagModal, setUniversalTagModal] = useState(false);
  const [openCloneAdModal, setOpenCloneAdModal] = useState(false);
  const [openDeleteAdModal, setOpenDeleteAdModal] = useState(false);
  const statusEnum = CAMPAIGN_STATES;
  //Icons setup
  const actionIcons = {
    LIST: "/Icon_assets/svg/list.svg",
    ANALYTICS: "/Icon_assets/svg/analytics.svg",
    ANALYTICS_ACTIVE: "/Icon_assets/svg/analytics_active.svg",
    PREVIEW_ICON: "/Icon_assets/svg/preview-icon.svg",
    DESC_SORT_SELECTED: "/Icon_assets/svg/desc_sort_selected.svg",
    DESC: "/Icon_assets/svg/desc_sort.svg",
    ASC_SORT_SELECTED: "/Icon_assets/svg/asc_sort_selected.svg"
  };
  const platFormEnum = [
    "Invisibly",
    "Programmatic",
    "Both"
  ]
  const TABLE_COLUMNS = [
    {
      dataField: "Campaign_name",
      text: "Survey name",
      sortName: "name",
      className: "col-hd-campaign-name"
    },
    {
      dataField: "updated_at",
      text: "Updated at",
      sortName: "updated_at",
      className: "col-hd-update"
    },
    {
      dataField: "Organization",
      text: "Organization",
      sortName: "brand_v2__brand_organizations__organization__name",
      className: "col-hd-organization"
    },
    {
      dataField: "Advertiser",
      text: "Advertiser",
      sortName: "brand_v2__name",
      className: "col-hd-advertiser"
    },
    {
      dataField: "Status",
      text: "Status",
      sortName: "ad_state__value",
      className: "col-hd-status"
    },
    {
      dataField: "Platform",
      text: "Platform",
      sortName: "rtr_type",
      className: "col-hd-platform"
    },
    {
      dataField: "actions",
      text: "Actions",
      sortName: "actions",
      className: "col-hd-actions"
    },
  ];

  const dispatch = useDispatch();
  const isUniversalTagFetching = useSelector((state) => state.realtimeResearch.isUniversalTagFetching);
  const universalTagScriptData = useSelector((state) => state.realtimeResearch.universalTagScriptData);
  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

  const MAIN_TABLE_COL_MIN_WIDTH = 180;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

  const getUniversalTagRequest = (ad_id) => {
    setUniversalTagModal(true);
    dispatch(realtimeResearchActionCreator.getUniversalTagRequest({ ad_id: ad_id }));
  }

  const setCloneRtrAdParam = (current_Ad) => {
    setCurrentAD(current_Ad);
    setOpenCloneAdModal(true);
  }

  const cloneRtrAdRequest = (campaign_id) => {
    dispatch(realtimeResearchActionCreator.cloneRtrAdRequest({ campaign_id: campaign_id }));
    setOpenCloneAdModal(false);
  }

  const editSurvey = (data) => {
    // naivgate to rtr page
    localStorage.setItem("brand_id", data.brand_v2 ? data.brand_v2.id : "");
    localStorage.setItem("msg_id", data.message);
    localStorage.setItem("ad_id", data.id);
    if (data.rtr_type.toString() === "0" && data.brand_offer.length)
      localStorage.setItem("id", data.brand_offer[0].id);

    history.push({
      pathname: `${ROUTES.REASERACH_CAMPAIGN}/preview/0`,
      state: {
        detail: {
          ad_name: data.name,
          ad_state: data.ad_state,
          isEdit: true, external_msg_id: data.id, name: data.name,
          brand_id: data.brand_v2.id, brand_name: data.brand_v2.name,
          msg_id: data.message,
          isPDP: data.rtr_type.toString() === "0" ? true : false,
          isOffer: data.brand_offer.length,
          ad_state: data.ad_state,
          isActiveOrPaused: data.ad_state === 4 || data.ad_state === 7,
          adset_id: data?.ad_set?.id
        }
      }
    });
    //call api to get both the ads
    dispatch(realtimeResearchActionCreator.getBothCampaignsRequest({ adset_id: data?.ad_set?.id }))

  }
  const setDeleteRtrAdParam = (current_Ad) => {
    setCurrentAD(current_Ad);
    setOpenDeleteAdModal(true);
  }

  const deleteRtrAdRequest = (adData) => {
    const hasBothPlatforts = !!(adData?.rtr_type === 2); //rtr_type === 2 (means both platform supported)
    dispatch(realtimeResearchActionCreator.deleteRtrAdRequest({ ad_id: adData.id, hasBothPlatforts, ad_set: adData?.ad_set?.id }));
    setOpenDeleteAdModal(false);
  }

  const goToResearchDetails = (e, id, brand_id, name, orgName, orgId, type, adMetaData) => {
    e.preventDefault();
    const adset_id = adMetaData?.ad_set?.id
    localStorage.setItem('ad_name', name);
    localStorage.setItem('ad_type', type);
    history.push({
      pathname: `${ROUTES.REALTIME_RESEARCH}/details/${id}/${brand_id}/${adMetaData?.rtr_type}/${adset_id}`,
      state: { org_name: orgName, org_id: orgId }
    });
  }

  const goToPreview = (e, msg_id, brand_id, ad_state, externalId, name, rtr_type, data) => {
    e.preventDefault();
    localStorage.setItem("campaign_id", data.campaign?.id)
    if (rtr_type.toString() === "0" && data.brand_offer.length) {
      // let isPDP = data.brand_offer.length && data.brand_offer[0].type === 2;
      let messageData = {};
      var preview = 1;
      messageData.goToRTR = true;
      messageData.isOffer = true;
      messageData.message = data.message;
      messageData.ad_name = data.name;
      messageData.ad_state = data.ad_state;
      messageData.external_msg_id = externalId;
      messageData.msg_id = msg_id;
      messageData.isEdit = false;
      messageData.offer_name = data.brand_offer.length ? data.brand_offer[0].offer_name : "";
      messageData.isPDP = rtr_type.toString() === "0" ? true : false;
      messageData.platformState = rtr_type;
      messageData.ad_set_id = data?.ad_set?.id;
      localStorage.setItem("brand_id", brand_id);
      localStorage.setItem("id", data.brand_offer[0].id);
      localStorage.setItem("msg_id", data.message);
      localStorage.setItem("ad_id", data.brand_offer[0].ad);
     

      history.push({
        // pathname: `rtr/preview/${data.brand_offer[0].id}/${brand_id}/${data.brand_offer[0].ad}`,
        pathname: `${ROUTES.REASERACH_CAMPAIGN}/preview/${preview}`,
        state: { detail: messageData }
      });
      dispatch(offerActionCreator.getOfferListFailureClear());
    } else {
      var preview = 1;
      localStorage.setItem("brand_id", brand_id);
      localStorage.setItem("msg_id", msg_id);
      localStorage.setItem("ad_id", externalId);
      var DetailsData = {
        ad_name: name, "external_msg_id": externalId,
        "ad_state": ad_state, "msg_id": msg_id,
        isEdit: false,
        isPDP: rtr_type.toString() === "0" ? true : false,
        isOffer: false,
        platformState: rtr_type,
        ad_set_id: data?.ad_set?.id
      }
      history.push({
        pathname: `${ROUTES.REASERACH_CAMPAIGN}/preview/${preview}`,
        state: { detail: DetailsData }
      });
    }
  }

  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("admin-survey-table");
    var tableHeight = table.offsetHeight;
    // For resizing column, add dummy div to header, add event when clicked
    Array.prototype.forEach.call(
      table.querySelectorAll(".resized-column"),
      function (th) {
        //th.style.position = 'relative';

        var grip = document.createElement('div');
        grip.className = 'column-resizer';
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.height = tableHeight + 'px';
        grip.style.position = 'absolute';
        grip.style.zIndex = "1";
        grip.style.cursor = 'col-resize';
        grip.addEventListener('mousedown', function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      });

    // listener for dragging
    table.addEventListener('mousemove', function (e) {
      if (thElm) {
        if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
          thElm.style.width = startOffset + e.pageX + 'px';
          thElm.style.minWidth = startOffset + e.pageX + 'px';
        }

        let expandedColumnWidth = (startOffset + e.pageX) + 20;
        if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
          setExpandedTableColWidth(expandedColumnWidth);
        }
      }
    });

    // listner for releasing click
    table.addEventListener('mouseup', function () {
      thElm = undefined;
    });
  }, [])
  /* Resize column setup for table-main end*/

  return (
    <>
      <div className="research-table-container">
        <Table className="admin-survey-table" id="admin-survey-table">
          <thead>
            <tr>
              {TABLE_COLUMNS.map((d, i) => {
                if (d.text === "Survey name" || d.text === "Advertiser" || d.text === "Organization") {
                  return <th key={i} className={d.className + " resized-column"} >
                    <div className="resizer-icon">
                      <span className="first"></span>
                      <span className="second"></span>
                    </div>
                    {d.text}
                    <img onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                      src={sortedColumn === d.sortName ?
                        (sortType === 'desc' ?
                          actionIcons.DESC_SORT_SELECTED : actionIcons.ASC_SORT_SELECTED)
                        : actionIcons.DESC} alt="desc icon" className="index-tip" />
                  </th>;

                }
                return <th key={i} className={d.className} >  {d.text}
                  {d.text.toLowerCase() !== "actions" ?
                    <img onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                      src={sortedColumn === d.sortName ?
                        (sortType === 'desc' ?
                          actionIcons.DESC_SORT_SELECTED : actionIcons.ASC_SORT_SELECTED)
                        : actionIcons.DESC} alt="desc icon" className="index-tip" />
                    : <></>}
                </th>;
              })}
            </tr>
          </thead>
          <tbody>
            {
              (tableData.results.map((data, index) => {
                return (
                  <tr id='st-row' key={data.id}>
                    <td className="survey-column campaign-name-column">
                      <div className="campaign-meta-wrapper">
                        <span className="survey-icon-wrap">
                          <img src={actionIcons.LIST} className="survey-icon" alt="status icon" />
                        </span>
                        <p className="ad-name-wrap">
                          <span className="survey-name" title={data.name}
                            style={{ cursor: data.ad_state !== 0 ? "pointer" : "default" }}
                            onClick={(e) => data.ad_state !== 0 ?
                              goToResearchDetails(e, data.id, data.brand_v2 ? data.brand_v2.id : "",
                                data.name, data.brand_v2 && data.brand_v2.organization ?
                                data.brand_v2.organization.name : "",
                                data.brand_v2.organization ?
                                  data.brand_v2.organization.id : "", data.rtr_type, data) : e.preventDefault()}>{data.name}</span>
                          {/* <span>Updated at: {format(new Date(data.updated_at), 'MMM dd, yyyy')} </span> */}
                        </p>
                      </div>
                    </td>
                    <td className="organizati-column">
                      <span>{format(new Date(data.updated_at), 'MMMM dd, yyyy')}</span>
                    </td>
                    <td className="organization-column">
                      <span>{data.brand_v2 && data.brand_v2.organization ? data.brand_v2.organization.name : ""}</span>
                    </td>
                    <td className="advertiser-column">
                      <span>{data.brand_v2 && data.brand_v2.name}</span>
                    </td>
                    <td className={`${checkRBAC(userData, userRbac, PERMISSIONS.BUILD_RTR_WRITE, selectedOrganization) ? "status-column" : "status-column prevent-user-selection"}`}>
                      {data.state_transition.length && data.ad_state !== 1 ?
                        <StatusDropdown data={data} /> :
                        (<span className={(statusEnum[data.ad_state].state_label).toLocaleLowerCase() + " state-change-dropdown d-flex"}> <span className="state-dot"></span>	{statusEnum[data.ad_state].state_label}</span>)
                      }
                    </td>
                    <td >
                      <span >	{(platFormEnum[data.rtr_type])}</span>
                    </td>
                    <td className="action-column">
                      <section className="d-flex">
                        <span className="divider"></span>
                        <PreviewAction
                          goToPreview={goToPreview}
                          data={data}
                          actionIcons={actionIcons}
                        />
                        <AnalyticsAction
                          goToResearchDetails={goToResearchDetails}
                          data={data}
                          actionIcons={actionIcons}
                        />
                        <span className="align-span">
                          <RBAC requiredPermissions={PERMISSIONS.RTR_WRITE_CLONE_DELETE} >
                            <ActionDropdown
                              data={data}
                              getUniversalTagRequest={getUniversalTagRequest}
                              setCloneRtrAdParam={setCloneRtrAdParam}
                              setDeleteRtrAdParam={setDeleteRtrAdParam}
                              editSurvey={editSurvey}
                            />
                          </RBAC>
                        </span>
                      </section>
                    </td>
                  </tr>
                )
              })
              )
            }
          </tbody>
        </Table>
      </div>

      <PopUpModal
        className={'universal-tag-export-modal'}
        title={"Universal Tag Script"}
        openPopUp={openUniversalTagModal}
        setOpenPopUp={setUniversalTagModal}
      >
        <UniversalTag
          setUniversalTagModal={setUniversalTagModal}
          isUniversalTagFetching={isUniversalTagFetching}
          universalTagScriptData={universalTagScriptData}
        />
      </PopUpModal>
      <PopUpModal
        className={'clone-rtr-ad-modal'}
        title={"Clone Survey"}
        openPopUp={openCloneAdModal}
        setOpenPopUp={setOpenCloneAdModal}
      >
        <CloneRtrAd
          currentAD={currentAD}
          setOpenCloneAdModal={setOpenCloneAdModal}
          cloneRtrAdRequest={cloneRtrAdRequest}
        />
      </PopUpModal>
      <PopUpModal
        className={'delete-rtr-ad-modal'}
        title={"Delete Survey"}
        openPopUp={openDeleteAdModal}
        setOpenPopUp={setOpenDeleteAdModal}
      >
        <DeleteRtrAd
          currentAD={currentAD}
          setOpenDeleteAdModal={setOpenDeleteAdModal}
          deleteRtrAdRequest={deleteRtrAdRequest}
        />
      </PopUpModal>
    </>
  )
}

export default AdminSurveyTable;