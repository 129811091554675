import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Notifications from "../../components/Notifications";
import HeaderNavigation from "../../components/HeaderNavigation";
import { Button, ButtonGroup, Card, Row, Col, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput, FormGroup, Input } from "reactstrap";
import ROUTES from "../../utils/routeConstants";
import history from "../../history";
import {
  addDays,
  subDays,
  startOfDay,
  startOfMonth,
  startOfYear,
  addYears,
  format,
  startOfQuarter,
  startOfWeek,
  addMonths,
  endOfMonth,
  endOfDay,
  endOfQuarter
} from "date-fns";
import Validator from "../../utils/validators";

import { reportBuilderActionCreator, advertiserActionCreator, campaignsActionCreator, adActionCreator, lineActionCreator, notificationActionCreator } from "../../actions";
import CLIENT_SIDE_MESSAGES from "../../utils/clientSideMessages";
import ADCalendar from "../../components/ADCalendar";
import DimensionFilter from "../../components/ReportBuilder/DimensionFilter";
import MetricFilter from "../../components/ReportBuilder/MetricFilter";
import ADspinner from "../../components/Loader/ADspinner";
import ReportTable from "../../components/ReportBuilder/ReportTable";
import Loader from "../../components/Loader";
import PopUpModal from "../../components/PopUpModal";
import SheduleReport from "../../components/ReportBuilder/ScheduleReport";
import ReportTemplate from "../../components/ReportBuilder/ReportTemplate";
import { STATES } from "../../components/ReportBuilder/ReportTemplate";
import RBAC from "../../components/RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";
import { getDateRangeById } from "../../utils/calendarDateRange";
import "./index.scss";

const ReportBuilder = () => {
  const selectedTemplate = useSelector((state) => state.reportBuilder.selectedTemplate);
  const [showReportTable, setShowReportTable] = useState(false);
  const dispatch = useDispatch();
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const dateFormat = "yyyy-MM-dd";
  const statusIcons = {
    BAR_ICON: "/Icon_assets/svg/bar_icon.svg",
    SURVEY_ICON: "/Icon_assets/svg/rtr_survey_icon.svg",
    PLUS_ICON: "/Icon_assets/svg/plus_icon_blue.svg",
    MINUS_ICON: "/Icon_assets/svg/minus_icon_blue.svg",
    SEPERATOR_ICON: "/Icon_assets/svg/seperator.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    GO_BACK_ICON: "/Icon_assets/svg/back_icon.svg",
    PLUS_ICON_GRAY_TRAN: "/Icon_assets/svg/plus_icon_gray_transparent.svg",
    PLUS_ICON_BLUE_TRAN: "/Icon_assets/svg/plus_icon_blue_transparent.svg",
    TIMER_GRAY_ICON: "/Icon_assets/svg/timer_gray_icon.svg",
    CALENDER_GRAY_ICON: "/Icon_assets/svg/calendar_gray_Icon.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/black_circle_close_icon.svg",
    CALENDER_BLUE_ICON: "/Icon_assets/svg/calendar_blue_Icon.svg",
  }

  const DATE_FORMAT = {
    mmddyyyy: 'MM/dd/yyyy',
    ddMMyyyy: 'dd/MM/yyyy',
    yyyyMMdd: 'yyyy-MM-dd',
  };

  const dateToYMD = (date, dateRange) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    if (dateRange) {
      return "" + (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
    } else {
      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "-" +
        (m <= 9 ? "0" + m : m) +
        "-" +
        y +
        "+" +
        hh +
        ":" +
        mm +
        ":" +
        ss
      );
    }
  };

  const startTime = (startOfDay(subDays(new Date(), 7)));
  const endTime = (startOfDay(addDays(new Date(), 0)));

  const defaultDateConfig = {
    startTime: startTime,
    endTime: endTime,
    displayDateRange: format(new Date(startTime), DATE_FORMAT.mmddyyyy)+ " - " + format(new Date(endTime), DATE_FORMAT.mmddyyyy),
    dateRangeStr: "Last 7 Days",
    dateRangeEnum: 0
  }

  
  // const dateValue =
  //   dateToYMD(startTime, "date-range") +
  //   " - " +
  //   dateToYMD(startOfDay(addDays(new Date(), -1)), "date-range");
  const dateValue = format(new Date(startTime), DATE_FORMAT.mmddyyyy)+ " - " + format(new Date(endTime), DATE_FORMAT.mmddyyyy);
  // // assigned default last 7 days date to start_date,end_date and date Range value
  const [selectedStartTime, updateSelectedStartTime] = useState(defaultDateConfig.startTime);
  const [selectedEndTime, updateSelectedEndTime] = useState(defaultDateConfig.endTime);
  const [dateRangeValue, updateDateRangeValue] = useState(defaultDateConfig.displayDateRange);
  const [modal, setModal] = useState(false);
  const [report_template, setReportTemplate] = useState({});
  const isSheduleSaved = useSelector(state => state.scheduleReport.isSaved);

  const generateCSV = () => {
    // to be updated with api selector
    // var jsonData = [];
    setLoading(true);
    setTimeout(() => {
      var fileName = "Inv_Report";
      var date = getDateFormat((new Date()));
      fileName += "_" + date;

      var exportCSV = '';
      var header = "";
      for (var idx in reportData[0]) {
        header += idx + ',';
      }
      header = header.slice(0, -1);
      exportCSV += header + '\r\n';
      var row = reportData;

      for (var i = 0; i < row.length; i++) {
        var r = "";
        for (var index in row[i]) {
          r += '"' + row[i][index] + '",';
        }
        r.slice(0, r.length - 1);
        exportCSV += r + '\r\n';
      }
      if (exportCSV === '') {
        console.error("Invalid data");
        return;
      }

      var ua = window.navigator.userAgent;

      if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0) {
        var blob = new Blob([exportCSV], { type: "text/csv;charset=utf-8" });
        navigator.msSaveBlob(blob, fileName + ".csv")
      } else {
        var uri = 'data:text/csv;charset=utf-8,' + encodeURI(exportCSV);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        setLoading(false);
      }
    }, 8000);

  }

  const getDateFormat = (date) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = (date.getHours() < 10 ? '0' : '') + date.getHours();
    let mm = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    let ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

    return (
      "" +
      (m <= 9 ? "0" + m : m) +
      "-" +
      (d <= 9 ? "0" + d : d) +
      "-" +
      y +
      "-" +
      hh +
      ":" +
      mm +
      ":" +
      ss
    );
  };


  const backToDashboard = (e) => {
    e.preventDefault();
    if (showReportTable) {
      setShowReportTable(false);
      setReportBuilderData([]);
      dispatch(reportBuilderActionCreator.runReportClearAll());
    } else {
      history.push(ROUTES.DASHBOARD);
      dispatch(reportBuilderActionCreator.runReportClearAll());
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  /*toggle configurations start*/
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const toggleDateRange = () => setDateDropdownOpen(prevState => !prevState);

  const [selectedDatedownOpen, setSelectedDateDropdownOpen] = useState(false);
  const toggleSelectedDate = () => setSelectedDateDropdownOpen(prevState => !prevState);

  const [dateIntervalDropdownOpen, setDateIntervalDropdownOpen] = useState(false);
  const toggleDateInterval = () => setDateIntervalDropdownOpen(prevState => !prevState);

  const [dimensionsDropdownOpen, setDimensionsDropdownOpen] = useState(false);
  const toggleDimensions = () => setDimensionsDropdownOpen(prevState => !prevState);

  const [metricsDropdownOpen, setMetricsDropdownOpen] = useState(false);
  const toggleMetrics = () => setMetricsDropdownOpen(prevState => !prevState);
  /*toggle configurations end*/

  /*State configurations start*/
  //Form state
  const formInitialState = {
    start_date_report: '',
    end_date_report: '',
    interval: '',
    dimensions: [],
    dimension_filters: [],
    metrics: [],
    metric_filters: []
  };

  const prevFormStateRef = useRef();
  const [formState, setFormState] = useState(formInitialState);
  const [currentTemplateState, setCurrentTemplateState] = useState(STATES[0])
  //Date Range setup
  const [showDateRange, selectDateRange] = useState(false);
  const [dispalyDateRange, setDispalyDateRange] = useState(defaultDateConfig.displayDateRange);
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateConfig.dateRangeStr);
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(defaultDateConfig.dateRangeStr);
  const [dateRangeENUM, setDateRangeENUM] = useState(defaultDateConfig.dateRangeEnum); //last_7_days = 4

  //Interval
  const [interval, setInterval] = useState();

  //Dimensions
  const [dimensions, setDimensions] = useState([]);
  const [noOfDimensions, setNoOfDimensions] = useState(0);
  const [dimension_filters, setDimension_filters] = useState([]);
  const [currentDimensionIndex, setCurrentDimensionIndex] = useState({ index: null, value: null });

  //Metrics
  const [metrics, setMetrics] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noOfMetrics, setNoOfMetrics] = useState(0);
  const [metric_filters, setMetric_filters] = useState([]);
  /*State configurations end*/

  /*Static arrays start*/
  const dateIntervalArr = [
    { id: 8, label: "Cumulative", value: 8 },
    { id: 3, label: "Day", value: 3 },
    { id: 4, label: "Week", value: 4 },
    { id: 5, label: "Month", value: 5 }
  ];

  const dateRangeArr = [
    { value: 1, label: 'Custom', startDate: undefined },
    { value: 2, label: 'All time', startDate: startOfYear(addYears(new Date(), 0)), endDate: endOfDay(new Date()) },
    { value: 3, label: 'Today', startDate: startOfDay(new Date()), endDate: endOfDay(new Date()) },
    { value: 4, label: 'Yesterday', startDate: startOfDay(addDays(new Date(), -1)), endDate: endOfDay(addDays(new Date(), -1)) },
    { value: 5, label: 'This week', startDate: startOfWeek(new Date(), { weekStartsOn: 1 }), endDate: endOfDay(new Date()) },
    { value: 6, label: 'Last 7 days', startDate: startOfDay(addDays(new Date(), -7)), endDate: endOfDay(new Date()) },
    { value: 7, label: 'Last 14 days', startDate: startOfDay(addDays(new Date(), -14)), endDate: endOfDay(new Date()) },
    { value: 8, label: 'Month to Date', startDate: startOfMonth(new Date()), endDate: endOfDay(new Date()) },
    { value: 9, label: 'Last 30 days', startDate: startOfDay(addDays(new Date(), -30)), endDate: endOfDay(new Date()) },
    { value: 10, label: 'Last Month', startDate: startOfMonth(addMonths(new Date(), -1)), endDate: endOfMonth(addMonths(new Date(), -1)) },
    { value: 11, label: 'Quater to Date', startDate: startOfQuarter(new Date()), endDate: endOfDay(new Date()) },
    { value: 11, label: 'Previous Quarter', startDate: startOfQuarter(startOfQuarter(new Date()) - 1), endDate: endOfQuarter(startOfQuarter(new Date()) - 1) },
    { value: 8, label: 'Year to Date', startDate: startOfYear(addYears(new Date(), 0)), endDate: endOfDay(new Date()) }
  ]
  /*Static arrays end*/

  const [dimensionOptions, setDimensionOptions] = useState([]);
  const [metricsOptions, setMetricOptions] = useState([]);
  const [reportBuilderData, setReportBuilderData] = useState([]);

  /*Get date from store start*/
  const isRunning = useSelector((state) => state.reportBuilder.isLoading);
  const isFetched = useSelector((state) => state.reportBuilder.isFetched);
  const dimensionList = useSelector((state) => state.reportBuilder.dimensions);
  const isDimensionsLoading = useSelector((state) => state.reportBuilder.isDimensionsLoading);
  const metricsList = useSelector((state) => state.reportBuilder.metrics);
  const isMetricsLoading = useSelector((state) => state.reportBuilder.isMetricsLoading);
  const reportData = useSelector((state) => state.reportBuilder.reportData);

  const userData = useSelector((state) => state.auth.userData);
  const brand_id = selectedOrganization?.brands ? selectedOrganization?.brands ? selectedOrganization?.brands.map(el => el.id) : '' : '';
  const organization_id = selectedOrganization ? selectedOrganization?.id : "";
  const user = userData.organization_role;
  const params = {}
  params.brand_id = brand_id;
  params.organization_id = organization_id;
  params.user = user;
  /*Get date from store end*/

  /*handle Calender component events start*/
  const getDateFromCalender = (e, dateRange) => {
    e.preventDefault();
    setDispalyDateRange(dateRange.displayDate);
    updateSelectedStartTime(dateRange.startDate);
    updateSelectedEndTime(dateRange.endDate);
    setSelectedDateRange(dateRange.selectedRange);
    setSelectedDateRangeOption(dateRange.selectedRange);
    setDateRangeENUM(dateRange.date_range);
    setSelectedDateDropdownOpen(false);

    handleReportChange('start_date_report', dateRange.startDate);
    checkFormValidation('start_date_report', dateRange.startDate);
    setFormState(prev => ({
      ...prev,
      'start_date_report': dateRange.startDate,
      'end_date_report': dateRange.endDate
    }))
  }

  const defaultTempalateState = () => {
    dispatch(reportBuilderActionCreator.deleteReportTemplatesFailurClear())// reset the flags
    dispatch(reportBuilderActionCreator.updateReportTemplatesFailurClear())// reset the flags
    dispatch(reportBuilderActionCreator.getReportTemplates()) //update the list
    dispatch(reportBuilderActionCreator.resetReportTemplate()) //selected template removed
  }

  const closeCalender = (e) => {
    e.preventDefault();
    setSelectedDateDropdownOpen(false);
  }

  const handleDateRangeOptionSelection = (e, selectedOption) => {
    e.preventDefault();
    if (selectedOption.value === 1) {
      //custom date range selected
      setSelectedDateRange(selectedOption.label);
    } else {
      updateSelectedStartTime(selectedOption.startDate);
      updateSelectedEndTime(selectedOption.endDate);
      //updateSelectedEndTime(startOfDay(new Date()));
      setSelectedDateRange(selectedOption.label);
    }
    handleReportChange('start_date_report', selectedOption.startDate);
    checkFormValidation('start_date_report', selectedOption.startDate);
  }
  /*handle Calender component events end*/

  //Add new dimensions
  const handleDimensionsCheckboxChange = (e, checkboxState) => {
    if (e.target.checked) {
      setDimensions((dimensions) => {
        checkFormValidation('dimensions', [...dimensions, checkboxState]);
        return [...dimensions, checkboxState];
      });

      setNoOfDimensions((prevCount) => prevCount + 1);
    } else {
      dimensions.splice(dimensions.indexOf(checkboxState), 1);
      setDimensions((dimensions) => {
        checkFormValidation('dimensions', [...dimensions]);
        return [...dimensions];
      });
      setNoOfDimensions((prevCount) => prevCount - 1);
    }
  }
  //Remove selected dimensions
  const removeSelectedDimensions = (e, removeItem) => {
    e.preventDefault();
    dimensions.splice(dimensions.indexOf(removeItem), 1);
    //setDimensions((dimensions) => [...dimensions]);
    setDimensions((dimensions) => {
      checkFormValidation('dimensions', [...dimensions]);
      return [...dimensions];
    });
    setNoOfDimensions((prevCount) => prevCount - 1);
  }
  //Remove all dimensions
  const removeAllDimensions = (e) => {
    e.preventDefault();
    setDimensions([]);
    setNoOfDimensions(0);
    checkFormValidation('dimensions', []);
  }

  //Fiter Dimention remove one value
  const removeSelectedFilterDimensions = (e, removeItem, curentIndex) => {
    e.preventDefault();
    let dimension_filters_changed = JSON.parse(JSON.stringify(dimension_filters))
    dimension_filters_changed[curentIndex].value.splice(removeItem, 1)
    setDimension_filters([...dimension_filters_changed]);
    handleReportChange("dimensionFilterValues", [...dimension_filters_changed], curentIndex, "dimensionFilter")
  }

  //Fiter Dimention remove all value
  const removeAllFilterDimensions = (e, curentIndex) => {
    e.preventDefault();
    dimension_filters[curentIndex].value = [];
    setDimension_filters((dimension_filters) => [...dimension_filters]);
    handleReportChange("dimensionFilterValues", dimension_filters[curentIndex].value, curentIndex, "dimensionFilter")
  }

  //Search metrics
  const handleMetricsSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    result = metricsList && metricsList.length > 0 && metricsList.filter((data) => {
      return data.value.toLowerCase().search(value) != -1;
    });

    let resultData = [...result];
    setMetricOptions(resultData);
  }
  //Add new metrics
  const handleMetricsCheckboxChange = (e, checkboxElement) => {
    if (e.target.checked) {
      setMetrics((metrics) => {
        checkFormValidation('metrics', [...metrics, checkboxElement]);
        return [...metrics, checkboxElement];
      });
      setNoOfMetrics((prevCount) => prevCount + 1);
    } else {
      const removeId = metrics.findIndex((element) => element.id === checkboxElement.id);
      metrics.splice(removeId, 1);
      setMetrics((metrics) => {
        checkFormValidation('metrics', [...metrics]);
        return [...metrics];
      });
      setNoOfMetrics((prevCount) => prevCount - 1);
    }
  }
  //Remove selected metrics
  const removeSelectedMetrics = (e, removeItem) => {
    e.preventDefault();
    metrics.splice(metrics.indexOf(removeItem), 1);
    setMetrics((metrics) => {
      checkFormValidation('metrics', [...metrics]);
      return [...metrics];
    });
    setNoOfMetrics((prevCount) => prevCount - 1);
  }
  //Remove all metrics
  const removeAllMetrics = (e) => {
    e.preventDefault();
    setMetrics([]);
    setNoOfMetrics(0);
    checkFormValidation('metrics', []);
  }

  /*** Add/Remove filters start***/
  const addFilter = (type) => {
    if (type === 'metrics') {
      let obj = [...metric_filters];
      obj.push({
        metric: '',
        rule: '',
        value: '',
        errorMsgValue: '',
        errorMsgRule: '',
        errorMsgMetric: '',
        id: `metricID${new Date().getTime()}`
      })

      setMetric_filters(obj);
      setFormState((prevState) => ({
        ...prevState,
        metric_filters: obj
      }))
    } else {
      let obj = [...dimension_filters];
      obj.push({
        errorMsgDimension: '',
        errorMsgValue: '',
        dimension: '',
        value: [],
        option: 0,
        dropdownOptions: null,
        pageNumber: '',
        isLoading: false,
        searchParam: {},
        id: `dimensionID${new Date().getTime()}`
      })

      setDimension_filters(obj);
    }
  }

  const removeFilter = (filterIndex, type) => {
    if (type === 'metrics') {
      let obj = [...metric_filters];
      obj.splice(filterIndex, 1);
      setMetric_filters(obj);
      setFormState((prevState) => ({
        ...prevState,
        metric_filters: obj
      }))

    } else {
      let obj = [...dimension_filters];
      obj.splice(filterIndex, 1);
      setDimension_filters(obj);
      setFormState((prevState) => ({
        ...prevState,
        dimension_filters: obj
      }))
    }
  }
  /*** Add/Remove filters end***/

  /*API calls start*/
  useEffect(() => {
    setstate(validatorsObj);
    dispatch(reportBuilderActionCreator.dimensionsListRequest());
    dispatch(reportBuilderActionCreator.metricsListRequest());
    prevFormStateRef.current = JSON.parse(JSON.stringify(formInitialState));
    checkFormValidation('start_date_report', selectedStartTime);
    return function cleanup() {
      setShowReportTable(false);
      setReportBuilderData([]);
      dispatch(reportBuilderActionCreator.runReportClearAll());
      defaultTempalateState()
    };
  }, []);

  useEffect(() => {
    if (dimensionList) {
      setDimensionOptions(dimensionList)
    }

    if (metricsList) {
      setMetricOptions(metricsList);
    }

    if (metricsDropdownOpen) {
      setSearchValue('');
    }

    if (isFetched && reportData) {
      setReportBuilderData(reportData);
      setShowReportTable(true);
    }

  }, [dimensionList, metricsList, metricsDropdownOpen, reportData]);
  /*API calls end*/

  /*** validation start ***/
  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };
  /*** validation end ***/

  const handleReportChange = (inputName, inputValue, inputIndex = null, filterChangeType = null, e) => {
    if (filterChangeType === "metricFilter") {
      const filterObj = JSON.parse(JSON.stringify([...metric_filters]));
      if (inputName === 'metric') {
        filterObj[inputIndex]['errorMsgMetric'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].metric = inputValue;
      } else if (inputName === 'metricFilterValue') {
        filterObj[inputIndex]['errorMsgValue'] = !updateValidations(inputName, inputValue) ? validators.metricFilterValue.errorMsg : '';
        filterObj[inputIndex].value = inputValue;
      } else if (inputName === "metricFilterRule") {
        filterObj[inputIndex]['errorMsgRule'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].rule = inputValue;
      }
      setMetric_filters(filterObj);
      setFormState((prevState) => ({
        ...prevState,
        metric_filters: filterObj
      }))

    } else if (filterChangeType === "dimensionFilter") {
      let filterObj = JSON.parse(JSON.stringify([...dimension_filters]));
      if (inputName === 'dimension') {
        const checkIsExist = filterObj.some(function (el) {
          return el.dimension.value === inputValue.value;
        });
        if (checkIsExist) {
          dispatch(notificationActionCreator.setNotification({
            message: CLIENT_SIDE_MESSAGES.DIMENTION_EXIST
          }));
        } else {
          filterObj[inputIndex]['errorMsgDimension'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
          filterObj[inputIndex].dimension = inputValue;
          filterObj[inputIndex].option = 0;
          filterObj[inputIndex].dropdownOptions = null;
          filterObj[inputIndex].value = [];
        }
      } else if (inputName === 'dimensionFilterOption') {
        filterObj[inputIndex]['errorMsgRule'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].option = inputValue
      } else if (inputName === 'dimensionFilterValues') {

        filterObj = Array.isArray(inputValue) ? inputValue : filterObj;
        if (e) {
          if (e.target.checked) {
            let valueArr = Array.isArray(inputValue) ? filterObj[inputIndex].value[inputIndex].value : filterObj[inputIndex].value;
            valueArr.push(inputValue);
            filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", valueArr) ? validators.dimensionFilterValues.errorMsg : '';
          } else {
            let valueArr = Array.isArray(inputValue) ? filterObj[inputIndex].value[inputIndex].value : filterObj[inputIndex].value;
            filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", valueArr) ? validators.dimensionFilterValues.errorMsg : '';
            const removeId = valueArr.findIndex((element) => element.id === inputValue.id);
            valueArr.splice(removeId, 1);
          }
        } else {
          filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", inputValue) ? validators.dimensionFilterValues.errorMsg : '';
        }
      }
      setDimension_filters(filterObj);
      setFormState((prevState) => ({
        ...prevState,
        dimension_filters: filterObj
      }))
    }
  }

  const checkFormValidation = (field, value, isFilter = null, index = null, currentReportDetails) => {
    if (isFilter) {

    } else {
      const formStateObj = { ...formState };
      formStateObj[field] = value;
      setFormState(formStateObj);
    }
  }

  const handleRunRequest = (e) => {
    e.preventDefault();
    const payload = buildPayload(true); //true = at end_date 1 day added to include today's data
    if (payload) {
      dispatch(reportBuilderActionCreator.runReportRequest(payload));
    }
  }

  const buildPayload = (isRunRequest = false) => {
    //check dimention validation
    let isDimentionFiterValid = true;
    if (dimension_filters.length > 0) {
      const dimension_filtersObj = [...dimension_filters];
      for (let index = 0; index < dimension_filters.length; index++) {
        if (dimension_filters[index].dimension === "") {
          dimension_filtersObj[index]['errorMsgDimension'] = !updateValidations("requiredRule", dimension_filtersObj[index].dimension) ? validators.requiredRule.errorMsg : '';
        }

        if (dimension_filters[index].value.length === 0) {
          dimension_filtersObj[index]['errorMsgValue'] = !updateValidations("dimensionFilterValues", dimension_filtersObj[index].value) ? validators.dimensionFilterValues.errorMsg : '';
        }
      }
      setDimension_filters(dimension_filtersObj);
      setFormState((prevState) => ({
        ...prevState,
        dimension_filters: dimension_filtersObj
      }))
      isDimentionFiterValid = dimension_filtersObj.every((item) => item.errorMsgDimension === "" && item.errorMsgValue === "")
    }
    //check metric validation
    let isMetricFiterValid = true;
    if (metric_filters.length > 0) {
      const metric_filtersObj = [...metric_filters];
      for (let index = 0; index < metric_filters.length; index++) {
        if (metric_filters[index].metric === "") {
          metric_filtersObj[index]['errorMsgMetric'] = !updateValidations("requiredRule", metric_filtersObj[index].metric) ? validators.requiredRule.errorMsg : '';
        }

        if (metric_filters[index].value === "") {
          metric_filtersObj[index]['errorMsgValue'] = !updateValidations("metricFilterValue", metric_filtersObj[index].value) ? validators.metricFilterValue.errorMsg : '';
        }

        if (metric_filters[index].rule === "") {
          metric_filtersObj[index]['errorMsgRule'] = !updateValidations("requiredRule", metric_filtersObj[index].rule) ? validators.requiredRule.errorMsg : '';
        }

      }
      setMetric_filters(metric_filtersObj);
      setFormState((prevState) => ({
        ...prevState,
        metric_filters: metric_filtersObj
      }))

      isMetricFiterValid = metric_filtersObj.every((item) => item.errorMsgMetric === "" && item.errorMsgValue === "" && item.errorMsgRule === "")
    }

    if (isValid && isDimentionFiterValid && isMetricFiterValid) {
      let fDimensions = dimension_filters.map(filter => {
        let val = filter.value.map(val => val.dsp_id)
        return {
          dimension: filter.dimension.id,
          value: val,
          option: filter.option
        }
      })
      const metricsdata = metrics.map(({ id }) => id);
      const fMetrics = metric_filters.map(({ metric, rule, value }) => ({ metric, rule, value }));

      const endDate = isRunRequest ? format(addDays(selectedEndTime, 1), dateFormat) + " 00:00:00" : format(selectedEndTime, dateFormat) + " 00:00:00"

      const report = {
        "report": "",
        custom_params: {
          "metric_set": 19,
          "name": "***",
          "start_date": format(selectedStartTime, dateFormat) + " 00:00:00",
          "end_date": endDate,
          "date_range": dateRangeENUM,
          "interval": interval,
          "dimensions": dimensions,
          "dimension_filters": fDimensions,
          "metrics": metricsdata,
          "metric_filters": fMetrics
        }
      }

      return report;
    } else {
      return false;
    }
  }

  const isCampaignsListLoading = useSelector((state) => state.campaign.isLoading);
  const campaignList = useSelector((state) => state.campaign.data?.results);
  const lineList = useSelector((state) => state.line.data?.results);
  const adList = useSelector((state) => state.ad.data?.results);
  const advertiserList = useSelector((state) => state.advertiser.advertiserData?.results);

  useEffect(() => {
    const obj = [...dimension_filters];
    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "campaign" && campaignList) {
      obj[currentDimensionIndex.index].dropdownOptions = campaignList;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "line" && lineList) {
      obj[currentDimensionIndex.index].dropdownOptions = lineList;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "ad" && adList) {
      obj[currentDimensionIndex.index].dropdownOptions = adList;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "advertiser" && advertiserList) {
      obj[currentDimensionIndex.index].dropdownOptions = advertiserList;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }


  }, [campaignList, lineList, adList, advertiserList])

  const dimensionsFilterSearch = (e, dimensionId, currentIndex) => {
    const selectedDimentionName = dimensionId.value;
    const dimentionName = selectedDimentionName.toLowerCase();
    const filterObj = [...dimension_filters];
    let params = {};
    setCurrentDimensionIndex({ index: currentIndex, value: dimentionName });
    switch (dimentionName) {
      case "advertiser":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.searchKey = e.target.value;
        dispatch(advertiserActionCreator.advertisersListRequest(params));
        break;
      case "campaign":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.searchKey = e.target.value;
        dispatch(campaignsActionCreator.getCampaignsListRequest(params));
        break;
      case "line":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.searchKey = e.target.value;
        dispatch(lineActionCreator.getLineListRequest(params));
        break;
      case "ad":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.searchKey = e.target.value;
        dispatch(adActionCreator.getAdListRequest(params));
        break;
      default:
        break;
    }
  }

  //form validation update
  useEffect(() => {
    let valid = false;
    for (let i = 0; i < Object.keys(formState).length; i++) {
      if ((formState.start_date_report !== "" && formState.start_date_report !== undefined) &&
        formState.interval !== "" &&
        formState.dimensions.length != 0 &&
        formState.metrics.length != 0) {
        valid = true;
      }
    }
    setIsValid(valid);
  }, [formState]);

  const handleShedule = (e) => {
    e.preventDefault();
    if (selectedDateRangeOption === "Custom" || dateRangeENUM === 3) {
      dispatch(notificationActionCreator.setNotification({ message: "Scheduling does not work for custom date ranges", position: "fixed" }))
    } else {
      setModal(true);
      const payload = buildPayload();
      setReportTemplate(payload);
    }
  }

  const getReportTemplateData = (e) => {
    e.preventDefault();
    const payload = buildPayload();
    setReportTemplate(payload);
    return payload;
  }

  //handle shedule modal after save
  useEffect(() => {
    if (isSheduleSaved) {
      setTimeout(() => {
        setModal(false)
      }, 1000);
    }
  }, [isSheduleSaved])

  const gotoSheduleList = (e) => {
    e.preventDefault();
    history.push(ROUTES.SCHEDULED_REPORTS)
  }

  useEffect(() => {
    if (Object.keys(selectedTemplate).length !== 0) {
      if (selectedTemplate?.date_range !== 3 && selectedTemplate?.date_range !== null) { //if not a custom date_range & not null
        const getDates = getDateRangeById(selectedTemplate?.date_range)
        const newDisplayDate = format(new Date(getDates?.startDate), DATE_FORMAT.mmddyyyy) + " - " + format(new Date(getDates?.endDate), DATE_FORMAT.mmddyyyy) 
        setDispalyDateRange(newDisplayDate)
        updateSelectedStartTime((startOfDay(subDays(new Date(getDates?.startDate), 0))))
        updateSelectedEndTime((startOfDay(subDays(new Date(getDates?.endDate), 0))))
      }else{
        //if its a custom date_range
        const newDisplayDate = format(new Date(selectedTemplate?.start_date), DATE_FORMAT.mmddyyyy) + " - " + format(new Date(selectedTemplate?.end_date), DATE_FORMAT.mmddyyyy) 
        setDispalyDateRange(newDisplayDate)
        updateSelectedStartTime((startOfDay(subDays(new Date(selectedTemplate?.start_date), 0))))
        updateSelectedEndTime((startOfDay(subDays(new Date(selectedTemplate?.end_date), 0))))
      }

      setDateRangeENUM(selectedTemplate?.date_range)

      setMetrics(selectedTemplate?.metrics)

      setDimensions(selectedTemplate?.dimensions?.map(item => item?.id));

      setInterval(selectedTemplate?.interval)

      setMetric_filters(selectedTemplate?.metric_filters)

      setDimension_filters(selectedTemplate?.dimension_filters?.map((df) => ({
        ...df,
        dimension: dimensionOptions?.filter((d) => d?.id === df.dimension)[0],
        value: df.value,
        option: df.option
      })))

      prevFormStateRef.current = {
        ...JSON.parse(JSON.stringify(formInitialState)),
        dimensions: [...selectedTemplate?.dimensions?.map(item => item?.id)],
        metrics: selectedTemplate?.metrics,
        start_date_report: (startOfDay(subDays(new Date(selectedTemplate?.start_date), 0))),
        end_date_report: (startOfDay(subDays(new Date(selectedTemplate?.end_date), 0))),
        date_range: selectedTemplate?.date_range,
        interval: selectedTemplate?.interval,
        metric_filters: selectedTemplate?.metric_filters,
        dimension_filters: selectedTemplate?.dimension_filters?.map((df) => ({
          ...df,
          dimension: dimensionOptions?.filter((d) => d?.id === df.dimension)[0],
          value: df.value,
          option: df.option
        }))
      }

      setFormState((prevState) => ({
        ...prevState,
        dimensions: [...selectedTemplate?.dimensions?.map(item => item?.id)],
        metrics: selectedTemplate?.metrics,
        start_date_report: (startOfDay(subDays(new Date(selectedTemplate?.start_date), 0))),
        end_date_report: (startOfDay(subDays(new Date(selectedTemplate?.end_date), 0))),
        date_range: selectedTemplate?.date_range,
        interval: selectedTemplate?.interval,
        metric_filters: selectedTemplate?.metric_filters,
        dimension_filters: selectedTemplate?.dimension_filters?.map((df) => ({
          ...df,
          dimension: dimensionOptions?.filter((d) => d?.id === df.dimension)[0],
          value: df.value,
          option: df.option
        }))
      }));
    }
  }, [selectedTemplate])

  function mapData(formState) {
    return {
      start_date_report: formState?.start_date_report,
      end_date_report: formState?.end_date_report,
      interval: formState?.interval,
      dimensions: formState?.dimensions,
      dimension_filters: formState?.dimension_filters?.map((d) => ({
        dimension: d?.dimension,
        value: d?.value?.map((v) => ({
          id: v?.id,
          dsp_id: v?.dsp_id,
          name: v?.name
        })),
        option: d?.option
      }
      )),
      metrics: formState.metrics,
      metric_filters: formState?.metric_filters
    }
  }

  useEffect(() => {
    if (Object.keys(selectedTemplate).length !== 0)
      if (!differenceJSON(mapData(prevFormStateRef.current), mapData(formState))) {
        setCurrentTemplateState(STATES[1])
      }
      else {
        setCurrentTemplateState(STATES[2])
      }
  }, [formState]);

  const differenceJSON = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  };

  const handleFormCancel = () => {
    setInterval("")
    setDispalyDateRange(defaultDateConfig.displayDateRange)
    updateSelectedStartTime(defaultDateConfig.startTime)
    updateSelectedEndTime(defaultDateConfig.endTime)
    setSelectedDateRange(defaultDateConfig.dateRangeStr);
    setSelectedDateRangeOption(defaultDateConfig.dateRangeStr);
    setDateRangeENUM(defaultDateConfig.dateRangeEnum);
    
    setDimensions([])
    setMetrics([])
    setMetric_filters([])
    setDimension_filters([])

    checkFormValidation('start_date_report', defaultDateConfig.startTime);
    setFormState(prev => ({
      ...formInitialState,
      'start_date_report': defaultDateConfig.startTime,
      'end_date_report': defaultDateConfig.endTime
    }))
    
    const defaultStateConfig = {...formInitialState, 
      'start_date_report': defaultDateConfig.startTime, 
      'end_date_report': defaultDateConfig.endTime
    }
    
    prevFormStateRef.current = JSON.parse(JSON.stringify(defaultStateConfig));
  }

  /*
  const deepCompare = (arg1, arg2) => {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
      if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]') {
        if (Object.keys(arg1).length !== Object.keys(arg2).length) {
          return false;
        }
        return (Object.keys(arg1).every(function (key) {
          return deepCompare(arg1[key], arg2[key]);
        }));
      }
      return (arg1 === arg2);
    }
    return false;
  }*/

  return (
    <>
      <Notifications />
      <HeaderNavigation />
      <div className="app-container report-builder-section">
        <div className="right-container">
          <div className="title-section" style={{ paddingLeft: '0' }}>
            <div className="title-with-back-button d-flex justify-content-sm-between align-items-center w-100">
              <div className="d-flex">
                <Button className="btn-transparent mr-4" onClick={backToDashboard}>
                  <img src={statusIcons.GO_BACK_ICON} alt="go to back" className="go-back" />
                </Button>
                <h1>{showReportTable ? 'Report' : 'Report Builder'}</h1>
              </div>
              <div className="d-flex">
                <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                  {!showReportTable && (<Button className="in-btn-secondary btn-manage-shedule" onClick={gotoSheduleList}>
                    Manage Scheduled Reports
                  </Button>)}
                </RBAC>
              </div>
            </div>
            {reportData.length > 0 && showReportTable && (<>
              <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE}>
                <Button className="in-btn-primary inverse btn-shedule" onClick={handleShedule}>
                  Schedule Report
                </Button>
              </RBAC>
              <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                <Button onClick={generateCSV}
                  className="in-btn-sm in-btn-primary btn-export-result"
                >
                  {loading ? <ADspinner size="large" /> : 'Export Results'}
                </Button>
              </RBAC>
            </>)}
          </div>

          {!showReportTable ?
            <>
              <Form
                className="report-builder-form"
                onSubmit={handleSubmit}
              >
                <div className="report-builder-options">
                  <Card className="data-card report-option-card">
                    <h2 className="option-title">Report settings</h2>
                    <ReportTemplate
                      handleFormCancel={handleFormCancel}
                      isFormValid={isValid}
                      currentTemplateState={currentTemplateState}
                      getReportTemplateData={getReportTemplateData}
                      report_template={report_template}
                    />
                    <Row>
                      <Col md={12} sm={12} lg={4}>
                        <div className="option-selector">
                          <p style={{ marginLeft: "30px" }}>Date range</p>
                          <div className="date-range-container">
                            <div className="date-range-wrapper">
                              <img
                                src={selectedDatedownOpen ? statusIcons.CALENDER_BLUE_ICON : statusIcons.CALENDER_GRAY_ICON}
                                alt="Calender icon"
                                className="calender-icon"
                              />

                              <nav className="ad-date-range-container">
                                <Dropdown
                                  isOpen={selectedDatedownOpen}
                                  toggle={toggleSelectedDate}
                                  className={(selectedDatedownOpen ? "dropdown-option-selected" : "") + " dropdown-option"}
                                >
                                  <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                    className="dropdown_toggle"
                                  >
                                    <span
                                      className="dropdown_title"
                                      title="Select range"
                                    >
                                      {dispalyDateRange ? dispalyDateRange : 'Date Range'}
                                    </span>
                                    <img
                                      src={
                                        selectedDatedownOpen
                                          ? statusIcons.ARROW_UP
                                          : statusIcons.ARROW_DOWN
                                      }
                                      className="dropdown-img"
                                    ></img>
                                  </DropdownToggle>
                                  <DropdownMenu right className="ad-date-range-picker">
                                    <DropdownItem toggle={false}>
                                      <ADCalendar
                                        getDate={getDateFromCalender}
                                        closeCalender={closeCalender}
                                        selectedDateRangeOption={selectedDateRangeOption}
                                        dateFormat={'dd/MM/yy'}
                                        displayDateFormat={DATE_FORMAT.mmddyyyy}
                                        selectedDate={[{
                                          startDate: selectedStartTime ? selectedStartTime : startOfMonth(new Date()),
                                          endDate: selectedEndTime ? selectedEndTime : startOfMonth(new Date()),
                                          key: "selection",
                                        }]}
                                      />
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} sm={12} lg={6}>
                        <div className="option-selector">
                          <p>Interval</p>
                          <Dropdown
                            isOpen={dateIntervalDropdownOpen}
                            toggle={toggleDateInterval}
                            className="dropdown-option"
                            style={{marginTop:"7px"}}
                          >
                            <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              className="dropdown_toggle"
                            >
                              <span
                                className="dropdown_title"
                                title={"Select interval"}
                              >
                                {dateIntervalArr.find(data => data.id === interval) ? dateIntervalArr.find(data => data.id === interval).label : (<><span>Select interval </span><span className="text-danger">*</span></>)}
                              </span>
                              <img
                                src={
                                  dateIntervalDropdownOpen
                                    ? statusIcons.ARROW_UP
                                    : statusIcons.ARROW_DOWN
                                }
                                className="dropdown-img"
                              ></img>
                            </DropdownToggle>
                            <DropdownMenu>
                              {dateIntervalArr && (
                                dateIntervalArr.map((item) => {
                                  return (
                                    <DropdownItem
                                      key={item.id}
                                      onClick={() => {
                                        setInterval(item.id);
                                        checkFormValidation('interval', item.id);
                                      }
                                      }
                                      className={`d-flex justify-content-between align-items-center ${item.id === interval ? "dropdown-option-selected" : ""}`}
                                    >
                                      {item.label}

                                      {item.id === interval ? (<img
                                        src={statusIcons.CHECKED_BLUE_ICON}
                                        alt="dimension selected"
                                        className="option-selected"
                                      />) : ""}
                                    </DropdownItem>
                                  )
                                })
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="data-card report-option-card">
                    <h2 className="option-title">Dimensions</h2>
                    <Row>
                      <Col md={12} sm={12} lg={6}>
                        <div className="option-selector">
                          <Dropdown
                            isOpen={dimensionsDropdownOpen}
                            toggle={toggleDimensions}
                            className="dropdown-option"
                          >
                            <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              className="dropdown_toggle"
                            >
                              <span
                                className="dropdown_title"
                                title="Select range"
                              >
                                {dimensions?.length > 0 ? `${dimensions?.length} Dimension${dimensions?.length > 1 ? 's' : ''} Selected` : (<><span>Select dimensions </span><span className="text-danger">*</span></>)}
                              </span>
                              <img
                                src={
                                  dimensionsDropdownOpen
                                    ? statusIcons.ARROW_UP
                                    : statusIcons.ARROW_DOWN
                                }
                                className="dropdown-img"
                              ></img>
                            </DropdownToggle>
                            <DropdownMenu>
                              {!isDimensionsLoading ?
                                dimensionOptions && (
                                  dimensionOptions.map((item, index) => {
                                    return (
                                      <DropdownItem
                                        toggle={false}
                                        key={"dimensions" + index}
                                        id={"dimensions" + item.value + index}
                                        className={`${dimensions?.includes(item.id) ? "dropdown-option-selected" : ""}`}
                                      >
                                        {/* {item.name} */}
                                        <div className="options-container">
                                          <CustomInput
                                            className={`ad-transparent-checkbox`}
                                            type="checkbox"
                                            name={item.value}
                                            value={item.value}
                                            id={item.value}
                                            label={item.value}
                                            checked={dimensions?.findIndex(
                                              (element) => element === item.id) > -1 ? true : false
                                            }
                                            onChange={(e) => handleDimensionsCheckboxChange(e, item.id)}
                                          />

                                          {dimensions?.includes(item.id) ? (<img
                                            src={statusIcons.CHECKED_BLUE_ICON}
                                            alt="dimension selected"
                                            className="option-selected"
                                          />) : ""}
                                        </div>

                                      </DropdownItem>
                                    )
                                  })
                                ) : (
                                  <Loader />
                                )}
                            </DropdownMenu>
                          </Dropdown>

                          <div className="selected-options ad-mt-16">
                            {dimensions?.map((item, index) => {
                              return (
                                <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                                  {dimensionOptions.find(data => data.id === item)?.value}
                                  <img
                                    src="/Icon_assets/svg/close_icon.svg"
                                    alt="close icon"
                                    className="ml-2 close-icon"
                                    onClick={(e) => removeSelectedDimensions(e, item)}
                                  />
                                </span>
                              );
                            })}

                            {dimensions?.length > 0 ? (<span><Button className="ad-btn-link" onClick={removeAllDimensions}>Clear all</Button></span>) : ""}
                          </div>

                        </div>
                      </Col>
                    </Row>

                    <div id="dimension-filter-container">
                      {dimension_filters &&
                        dimension_filters.length > 0 ?
                        dimension_filters.map((dFilter, index) => {
                          return (<DimensionFilter
                            key={dFilter.id + index}
                            index={index}
                            OptionsList={dimensionOptions}
                            filter={dFilter}
                            removeFilter={removeFilter}
                            handleReportChange={handleReportChange}
                            dimensionsFilterSearch={dimensionsFilterSearch}
                            currentDimensionIndex={currentDimensionIndex}
                            removeSelectedFilterDimensions={removeSelectedFilterDimensions}
                            removeAllFilterDimensions={removeAllFilterDimensions}
                          />)
                        }) : ""}
                    </div>

                    {dimension_filters.length < 4 && (
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <Button
                            className={`btn-transparent add-filter ${dimension_filters.length > 0 ? 'add-filter-active' : 'add-filter-inactive'}`}
                            onClick={() => addFilter('dimension')}
                          >
                            <img
                              src={dimension_filters.length > 0 ? statusIcons.PLUS_ICON_BLUE_TRAN : statusIcons.PLUS_ICON_GRAY_TRAN}
                              alt="Add Metrics Filters"
                              className="add-filter-img"
                            />
                            Add Dimensions Filters
                          </Button>
                        </Col>
                      </Row>
                    )}


                  </Card>

                  <Card className="data-card report-option-card">
                    <h2 className="option-title">Metrics</h2>
                    <Row>
                      <Col md={12} sm={12} lg={6}>
                        <div className="option-selector">
                          <Dropdown
                            isOpen={metricsDropdownOpen}
                            toggle={toggleMetrics}
                            className="dropdown-option metrics"
                            direction="down"
                          >
                            <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              className="dropdown_toggle"
                            >
                              <span
                                className="dropdown_title"
                                title="Select Metrics"
                              >
                                {metrics?.length > 0 ? `${metrics?.length} Metric${metrics?.length > 1 ? 's' : ''} Selected` : (<><span>Select Metrics </span><span className="text-danger">*</span></>)}
                              </span>
                              <img
                                src={
                                  metricsDropdownOpen
                                    ? statusIcons.ARROW_UP
                                    : statusIcons.ARROW_DOWN
                                }
                                className="dropdown-img"
                              ></img>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="search-item" toggle={false}>
                                <FormGroup className="search-metrics">
                                  <Input
                                    value={searchValue}
                                    type="text"
                                    name="search"
                                    id="searchMetrics"
                                    placeholder="Search..."
                                    className="search-input"
                                    autocomplete="off"
                                    onChange={(event) => handleMetricsSearch(event)}
                                  //disabled = {isLoading}
                                  />
                                  <img
                                    src="/Icon_assets/svg/search-organization-icon.svg"
                                    alt="Search"
                                    className="search-image"
                                  />
                                </FormGroup>
                              </DropdownItem>
                              <div className="metrics-list">
                                {metricsOptions && (
                                  metricsOptions.length > 0 ?
                                    metricsOptions.map((item, index) => {
                                      return (
                                        <DropdownItem
                                          toggle={false}
                                          key={"metrics" + index}
                                          id={"metrics" + item.value + index}
                                          className={`${metrics?.findIndex((element) => element.id === item.id) > -1 ? "dropdown-option-selected" : ""}`}
                                        >
                                          <div className="options-container">
                                            <CustomInput
                                              className={`ad-transparent-checkbox`}
                                              type="checkbox"
                                              name={item.value}
                                              value={item.value}
                                              id={item.value}
                                              label={item.value}
                                              checked={metrics?.findIndex(
                                                (element) => element.id === item.id) > -1 ? true : false
                                              }
                                              onChange={(e) => handleMetricsCheckboxChange(e, item)}
                                            />

                                            {metrics?.findIndex((element) => element.id === item.id) > -1 ? (<img
                                              src={statusIcons.CHECKED_BLUE_ICON}
                                              alt="metrics selected"
                                              className="option-selected"
                                            />) : ""}
                                          </div>

                                        </DropdownItem>
                                      )
                                    }) : (<DropdownItem>No matches found</DropdownItem>)
                                )}
                              </div>
                            </DropdownMenu>
                          </Dropdown>

                          <div className="selected-options ad-mt-16">
                            {metrics?.map((item, index) => {
                              return (
                                <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                                  {item.value}
                                  <img
                                    src="/Icon_assets/svg/close_icon.svg"
                                    alt="close icon"
                                    className="ml-2 close-icon"
                                    onClick={(e) => removeSelectedMetrics(e, item)}
                                  />
                                </span>
                              );
                            })}

                            {metrics?.length > 0 ? (<span><Button className="ad-btn-link" onClick={removeAllMetrics}>Clear all</Button></span>) : ""}
                          </div>

                        </div>
                      </Col>
                    </Row>

                    <div id="metrics-filter-container">
                      {metric_filters &&
                        metric_filters.length > 0 ?
                        metric_filters.map((dFilter, index) => {
                          return (<MetricFilter
                            key={dFilter.id + index}
                            index={index}
                            OptionsList={metricsList}
                            filter={dFilter}
                            removeFilter={removeFilter}
                            handleReportChange={handleReportChange}
                          />)
                        }) : ""}
                    </div>

                    <Row>
                      <Col md={12} sm={12} lg={6}>
                        <Button
                          className={`btn-transparent add-filter ${metric_filters.length > 0 ? 'add-filter-active' : 'add-filter-inactive'}`}
                          onClick={() => addFilter('metrics')}
                        >
                          <img
                            src={metric_filters.length > 0 ? statusIcons.PLUS_ICON_BLUE_TRAN : statusIcons.PLUS_ICON_GRAY_TRAN}
                            alt="Add Metrics Filters"
                            className="add-filter-img"
                          />
                          Add Metrics Filters
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Form>
              <div className="report-builder-footer">
                <p>
                  <img
                    src={statusIcons.TIMER_GRAY_ICON}
                    alt="Timer Icon"
                    className="timer-icon"
                  /> Yesterday's data will be available after 8:30am EST.
                </p>
                <div>
                  <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ} >
                    <Button
                      className="in-btn-lg in-btn-info ml-3 btn-run"
                      type="submit"
                      disabled={isValid ? false : true}
                      onClick={handleRunRequest}
                    >
                      {isRunning ? <ADspinner size="large" /> : 'Run'}
                    </Button>
                  </RBAC>
                  
                  <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE} >
                    <Button
                      className="in-btn-primary inverse btn-shedule"
                      onClick={handleShedule}
                      disabled={isValid ? false : true}
                    >
                      Schedule
                    </Button>
                  </RBAC>
                </div>
              </div>
            </>

            :
            reportBuilderData && (
              <ReportTable
                jsonData={reportBuilderData}  //jsonData needs to replaced with api response
                tableLoaded={isRunning} />
            )
          }

        </div>
      </div>
      <PopUpModal
        className={'schedule-report-modal'}
        title={"Schedule Report"}
        openPopUp={modal}
        setOpenPopUp={setModal}
      >
        <SheduleReport
          type={"save"}
          setOpenPopUp={setModal}
          report_template={report_template}
        />
      </PopUpModal>
    </>
  );

}

export default ReportBuilder;