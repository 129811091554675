import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import {
  Card, Button, Form, Label, FormFeedback, Table,
  Row, Col, Input, FormGroup, UncontrolledTooltip, Tooltip, CardBody,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput
} from 'reactstrap';
import Loader from '../../Loader';
import ADspinner from "../../Loader/ADspinner";
import Validator from "../../../utils/validators";
import { notificationActionCreator, realtimeResearchActionCreator, surveyActionCreator, offerActionCreator } from "../../../actions";
import "./index.scss";
import MediaFilter from './MediaFilter';
import Stepper from "../../Stepper/Stepper";
import RTROfferPreview from './RTROfferPreview';
import InfiniteScroll from 'react-infinite-scroll-component'
import BrandOfferPreivew from "./BrandOfferPreivew";
import { useLocation } from 'react-router';
import LeaveOfferModal from "./LeaveOfferModal";
import SkeletonTable from '../../../components/Loader_Skeleton/SkeletonTable'

let validators = new Validator();

const CreateOffer = () => {
  const myRef = useRef(null);
  const headerRefs = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();


  const VALUES = {
    OFFER: "Offer",
    RTR: "RTR"
  };

  const UNITS = {
    INVISIBLY: "invisibly",
    TEXT: "text"
  };

  const MEDIA_TYPE = {
    IV: "Image/Video",
    STREAM: "Stream",
    HOSTED_MEDIA: "Hosted Media"
  };

  const uploadLogo = (
    <Button className="export-icon" style={{ opacity: "1" }} onClick={() => console.log('export button')}>
      <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
      <span className="span-label">Upload Image/Video</span>
    </Button>);

  const initialState = {
    offer_name: "",
    title: '',
    // url: "",
    description: "",
    brand: "",
    type: VALUES.OFFER,
    media_1_type: "image",
    media_1_url: "",
    ad: "",
    // inv_category: "",
    monetary: "",
    unit: UNITS.INVISIBLY
  };

  const optionalFields = [
    'tp_pixel_negative',
    'tp_pixel_positive',
    'display_order',
    'keywords',
    'filters',
    'tag',
    'subtitle'
  ]

  const validationEnabledFields = [
    'url', 'monetary', 'filters', 'keywords', 'display_order', 'title', 'subtitle', 'offer_name', 'button_text', 'description'
  ];

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  }

  const toggle = () => {
    setSearchValue("");
    let param = {};
    param.limit = 10;
    if (!dropdownOpen) {
      if (selectedOrganization) {
        param.organization = selectedOrganization.id;
      }
      dispatch(realtimeResearchActionCreator.rtrListRequest(param));
    } else {
      setAdvertiserList([]);
      setAdvertiserCount(0);
    }

    setTotalOffset(0);
    setDropdownOpen(prevState => !prevState);
  };

  const toggleRTR = () => {
    setRTRDropdownOpen(prevState => !prevState);
    setSearchValue("");
    let param = {};
    param.brand_id = [inputValues.brand];
    param.limit = 10;
    param.rtr_type = 0;
    if (!rtrDropdownOpen) {
      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(param));
    } else {
      setrtrDataList([]);
      setrtrDataCount(0);
      setTotalOffset(0);
    }

  };
  const [goToPreview, setGoToPreview] = useState(false);
  const [draftData, setDraftData] = useState();
  const [draftCalled, setDraftCalled] = useState(false);
  const [messageId, setMessageId] = useState("");
  const [advertiserId, setAdvertiserId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [initiator, setInitiator] = useState('btn-draft');
  const [offerId, setOfferId] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [disable, setDisable] = useState(true);
  const [fileDataObject, setFileDataObject] = useState({});
  const [mediaUrl, setMediaUrl] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [advertiserList, setAdvertiserList] = useState([]);
  const [totalOffset, setTotalOffset] = useState(0);
  const [advertiserCount, setAdvertiserCount] = useState(0);
  const [rtrDataCount, setrtrDataCount] = useState(0);
  const [rtrDataList, setrtrDataList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [rtrDropdownOpen, setRTRDropdownOpen] = useState(false);
  const [inputValues, setInputValues] = useState(initialState);
  const [noOfFilter, setNoOfFilter] = useState(1);
  const [mediaObj, setMediaObj] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [updatedOfferName, setUpdatedOfferName] = useState("");
  const titleLimit = 60;
  const offerNameLimit = 60;
  const subTitleLimit = 100;
  const buttonLabelLimit = 20;
  const [descriptionLimit, setDescriptionLimit] = useState(250);
  const [characterEntered, setCharacterEntered] = useState(0);
  const [isOfferActive, setIsOfferActive] = useState(false);
  let initialThumbnailsCount = 0;

  const imageLoading = useSelector((state) => state.offer.imageLoading);
  const blobUrl = useSelector((state) => state.offer.blobUrl);
  const mimeTypeList = useSelector((state) => state.surveyData.mimeTypeList);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const isAdvertiserRequest = useSelector((state) => state.realtimeResearch.isLoading);
  const advertiserData = useSelector((state) => state.realtimeResearch.rtrList?.results);
  const adData = useSelector((state) => state.realtimeResearch.rtrList);
  const adCount = useSelector((state) => state.realtimeResearch.rtrList?.count);
  const isAdvertiserNext = useSelector((state) => state.realtimeResearch.rtrList?.next);
  const isLoading = useSelector((state) => state.realtimeResearch.isLoading);
  const rtrList = useSelector((state) => state.realtimeResearch.realtimeResearchData?.results);
  const rtrCount = useSelector((state) => state.realtimeResearch.realtimeResearchData?.count);
  const createdOffer = useSelector((state) => state.offer.offerRequest);
  const offerData = useSelector((state) => state.offer.offerData);
  const isOfferCreating = useSelector((state) => state.offer.isCreating);
  const researchAdData = useSelector((state) => state.realtimeResearch.realtimeResearchData);

  const closeModal = () => {
    dispatch(realtimeResearchActionCreator.rtrListFailureClear());
    setShowPopup(false)
  }

  const getSteps = () => {
    return [
      activeStep !== 0 ?
        {
          title: "General Setup",
          icon: "/Icon_assets/svg/tick-icon.svg",
          onClick: (e) => {
            e.preventDefault();
            setActiveStep(0);
            console.log('onClick', "general setup")
          }
        } :
        {
          title: "General Setup",
          onClick: (e) => {
            e.preventDefault();
            setActiveStep(0);
            console.log('onClick', "general setup")
          }
        },
      {
        title: "Preview",
        onClick: (e) => {
          if (!disable) {
            e.preventDefault()
            console.log('onClick', 2);
            // saveCampaign('btn-preview-step');
          }
        }
      }];
  }

  const steps = getSteps();
  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    BACK: "/Icon_assets/svg/back_icon.svg",
    INFO: "/Icon_assets/svg/rule-info.svg"
  };

  const backToOfferList = (e) => {
    e.preventDefault();
    // history.push(ROUTES.OFFERS_LIST);
    headerRefs.current = null;
    localStorage.removeItem("brand_id");
    localStorage.removeItem("id");
    if ((JSON.stringify(initialState) === JSON.stringify(inputValues)) ||
      (JSON.stringify(draftData) === JSON.stringify(inputValues))) {
      history.push(`${ROUTES.OFFERS_LIST}`);
    } else {
      setShowPopup(true);
    }
  };

  const compareSize = (textElementRef) => {
    if (textElementRef && textElementRef.current) {
      var el = textElementRef.current;
      const compare = el.offsetWidth < el.scrollWidth
      setHover(compare);
    }
  };
  const [hoverStatus, setHover] = useState(false);

  const addFilter = () => {
    let key = "";
    let keyUrl = "", keyName = "";
    let obj = [...mediaObj];
    console.log(obj);

    const inputFields = { ...inputValues };
    // if (noOfFilter === 1) {
    //   key = `media_2_type`;
    //   keyUrl = `media_2_url`;
    // } else {
    let a = obj,
      count = 7,
      missing = []

    for (let i = 2; i <= count; i++) {
      if (a.indexOf(i) === -1) {
        missing.push(i)
      }
    }
    key = `media_${missing[0]}_type`;
    keyUrl = `media_${missing[0]}_url`;
    keyName = `media_${missing[0]}_name`;

    inputFields[key] = "image";
    inputFields[keyUrl] = "";

    setNoOfFilter(prevState => prevState + 1);
    obj.push(missing[0]);
    setMediaObj(obj);
    setInputValues(inputFields);
  }

  const handleFormSubmit = (elemId) => {
    setInitiator(elemId);

    let param = { ...inputValues };
    param.type = param.type === VALUES.RTR ? 2 : 1;
    param.monetary = +param.monetary;
    param.filter = "";
    if (param.type === 1) {
      delete param.rtr;
    }
    if (JSON.stringify(draftData) !== JSON.stringify(inputValues) && draftCalled) {
      setIsAPICalled(true);
      param.data_id = offerId;
      dispatch(offerActionCreator.createBrandOfferRequest(param));
    } else if (!draftCalled) {
      dispatch(offerActionCreator.createBrandOfferRequest(param));
      setIsAPICalled(true);
    } else if (goToPreview && elemId === 'btn-next-step' || goToPreview && elemId === 'btn-preview-step') {
      // call update API
      setActiveStep(1);
      return;
    }
  };

  const updateValidations = (fieldName, value) => {
    var error = validators.isValueValid(fieldName, value);
    if (validators[fieldName].errorMsg !== "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return error;
  };

  const removeSelection = (e) => {
    let inputField = { ...inputValues };
    e.preventDefault();
    setFileDataObject({});
    var key = e.target.name.replace("_name", "_size");
    var keyUrl = e.target.name.replace("_name", "_url");
    inputField[keyUrl] = "";
    delete inputField[key];
    delete inputField[e.target.name];
    setInputValues(inputField);
  }

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    inputFields[e.target.name] = e.target.value;
    if (e.target.type === "radio" && e.target.name.includes("media_")) {
      var k = e.target.name.replace("_type", "_name");
      var k2 = e.target.name.replace("_type", "_size");
      delete inputFields[k2];
      delete inputFields[k];
      var k1 = e.target.name.replace("_type", "_url");
      inputFields[k1] = "";
    }
    if (e.target.type === "radio" && e.target.value.toLowerCase() === VALUES.OFFER.toLowerCase()) {
      delete inputFields["ad"];
      delete inputFields["rtr"];
      if (!("url" in inputFields)) {
        inputFields["url"] = "";
      }
    }

    if (e.target.type === "radio" && e.target.value.toLowerCase() === VALUES.RTR.toLowerCase()) {
      delete inputFields["url"];
    }

    if (optionalFields.includes(e.target.name)) {
      if (inputFields[e.target.name] === '') {
        delete inputFields[e.target.name]
      }
    }

    if (e.target.type === "radio" && (e.target.value.toLowerCase() === VALUES.OFFER.toLowerCase() || e.target.value.toLowerCase() === VALUES.RTR.toLowerCase())) {
      (Object.keys(inputFields).some(function (k) {
        if (~k.indexOf("media_")) {
          if (~k.indexOf("type")) {
            inputFields[k] = "image"
          } else
            inputFields[k] = "";
        }
      }))

    }

    if (e.target.name === "description") {
      setCharacterEntered(e.target.value.length);
    }

    setInputValues(inputFields);
    if (validationEnabledFields.includes(e.target.name)) {
      updateValidations(e.target.name, e.target.value);
    }
  };

  const removeFilter = (e, removeItem) => {
    e.preventDefault();
    var obj = [...mediaObj];
    obj.sort(function (a, b) { return b - a });
    //remove first element with highest num.
    var num = obj.shift();

    obj.sort(function (a, b) { return a - b });
    const inputFields = { ...inputValues };
    // const index = obj.indexOf(removeItem);
    // if (index > -1) {
    //   obj.splice(index, 1);
    // }
    setMediaObj(obj);
    const key = `media_${num}_type`;
    const key2 = `media_${num}_url`;
    const key3 = `media_${num}_name`;
    const key4 = `media_${num}_size`;
    if (key in inputFields && key2 in inputFields) {
      delete inputFields[key];
      delete inputFields[key2];
      delete inputFields[key3];
      delete inputFields[key4];
    }
    setNoOfFilter((prevCount) => prevCount - 1);
    setInputValues(inputFields);
  };

  const readImageFile = async (file) => {
    let fileData = {};//{ ...fileDataObject }
    let reader = new FileReader();
    // let getAspectRatiofn = getAspectRatio;
    reader.onload = await function (e) {
      let img = new Image();
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    return fileData;
  };

  const setFileObjectUploadData = (file, isMobile, name) => {
    let param = {};
    let fileObj = {};//{ ...fileDataObject };
    fileObj['file_size'] = file.size;
    fileObj['file_name'] = file.name;
    fileObj['url'] = file.url;
    isMobile ? fileObj['device_type'] = 1 : fileObj['device_type'] = 0;
    fileObj['mime_type'] = (mimeTypeList.filter(function (Object) { return Object.value == file.type }))[0].id;
    param.file = file;
    setFileDataObject(fileObj);
    dispatch(offerActionCreator.uploadMediaRequest(param));
  }


  const isValidImageFormat = (file) => {
    const validFileExtensions = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
    if (validFileExtensions.includes(file.type.toLowerCase())) {
      return true;
    }
    return false;

  }

  const isValidVideoFormat = (file) => {
    const validFileExtensions = ["video/mp4", "video/mp3", "video/quicktime"];
    if (validFileExtensions.includes(file.type.toLowerCase())) {
      return true;
    }
    return false;

  }

  const handleMediaUpload = (e) => {
    let inputFields = { ...inputValues };
    var key = e.target.name.replace("_url", "_type");
    var sizeKey = e.target.name.replace("_url", "_size");

    let { target } = e;
    setMediaUrl(e.target.name);
    if (target.value.length > 0) {
      const fileList = Array.from(e.target.files);
      if (fileList.length == 1) {
        fileList.map((file, index) => {
          let type = ["image/gif", "image/jpeg", "image/jpg", "image/png", "video/mp4", "video/mp3", "video/quicktime"];
          if (file.type.indexOf("image") > -1 && !isValidImageFormat(file)) {
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid format,  ${file.type} not supported !!`
            }));
            setMediaUrl("");
          } else if (type.includes(file.type)) {
            let isMobile = (/iPhone|iPad|iPod|Android/i).test(navigator.userAgent);
            if (file.type.indexOf("image") > -1) {
              inputFields[sizeKey] = file.size;
              readImageFile(file);
              setFileObjectUploadData(file, isMobile, target.name);
              setInputValues(inputFields);
            } else if (file.type.indexOf("video") > -1 && isValidVideoFormat(file)
              && inputValues.type.toLowerCase() === VALUES.OFFER.toLowerCase()) {
              if (file.type.indexOf("video") > -1) {
                inputFields[key] = "video";
                inputFields[sizeKey] = file.size;
                readImageFile(file);
                setFileObjectUploadData(file, isMobile, target.name);
                setInputValues(inputFields);
              }
            }
            else {
              window.scrollTo(0, 0);
              setMediaUrl("");
              dispatch(notificationActionCreator.setNotification({
                message: `Please select valid format,${file.type} not supported !!`
              }));
            }
          }

          else {
            window.scrollTo(0, 0);
            setMediaUrl("");
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid format, ${file.type} not supported !!`
            }));
          }
        })
      }
    }
    else {
      target.reset();
    }
  };

  const handleSearch = (event, elem) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    let params = {};
    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }
    let value = event.target.value.toLowerCase();
    switch (elem) {
      case "advertiserDropDown":
        params.limit = 10;
        if (value !== "") {
          params.searchKey = value;
        }
        dispatch(realtimeResearchActionCreator.rtrListRequest(params));
        setAdvertiserList([]);
        setAdvertiserCount(0);
        setTotalOffset(0);
        break;
      case "rtrDropdown":
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.brand_id = [inputValues.brand];
        params.limit = 10;
        if (value !== "") {
          params.name = value;
        }
        params.rtr_type = 0;
        dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(params));
        setrtrDataCount(0);
        setrtrDataList([]);
        setTotalOffset(0);
        break;
      default:
        break;

    }
  }

  const goToGeneralSetup = () => {
    setActiveStep(0);
    setIsAPICalled(false);
  }


  useEffect(() => {
    if (adData && adData.count === undefined && isEdit) {
      const inputField = { ...inputValues };
      inputField.advertiser = adData.name;
      setInputValues(inputField);
      setDraftData({ ...draftData, advertiser: adData.name });
      setGoToPreview(true);
    }
  }, [adData]);

  useEffect(() => {
    if (researchAdData && typeof (researchAdData) === 'object' && isEdit
      && researchAdData.name !== undefined) {
      var inputFields = { ...inputValues };
      inputFields['rtr'] = researchAdData.name;
      setInputValues(inputFields);
      setDraftData(inputFields);
    }
  }, [researchAdData])


  useEffect(() => {
    if (location && location.state && location.state.detail &&
      location.state.detail.isEdit) {
      // call API 
      window.scrollTo(0, 0);
      setIsEdit(true);
      var params = {
        source: 1,
        limit: 1,
        id: location.state.detail.offerId
      };
      setOfferId(location.state.detail.offerId);
      dispatch(offerActionCreator.getOfferListByIdRequest(params));
    }
  }, [location])

  useEffect(() => {
    if (activeStep === 1) {
      // to display preview for offer
    }
  }, [activeStep])

  useEffect(() => {
    const inputField = { ...inputValues };
    if (createdOffer && Object.keys(createdOffer).length !== 0) {
      localStorage.setItem("brand_id", createdOffer.brand_id);
      localStorage.setItem("id", createdOffer.id);
      localStorage.setItem("ad_id", createdOffer.ad);

      setMessageId(createdOffer.message);
      setAdvertiserId(createdOffer.ad);
      setBrandId(createdOffer.brand_id);
      setDraftCalled(true);
      setOfferId(createdOffer.id);
      setUpdatedOfferName(createdOffer.offer_name);

      setDraftData(inputField);
      setGoToPreview(true);
      if (initiator === 'btn-next-step' || initiator === 'btn-preview-step') {
        setActiveStep(1);
      }
    }
  }, [createdOffer]);


  useEffect(() => {
    if (offerData && isEdit) {
      setIsOfferActive(offerData.state === 1);
      localStorage.setItem("brand_id", offerData.brand_id);
      localStorage.setItem("id", offerData.id);
      localStorage.setItem("ad_id", offerData.ad);
      var params = { limit: 10, brand_name: offerData.brand };
      setOfferId(offerData.id);
      setBrandId(offerData.brand);
      setAdvertiserId(offerData.ad);
      setUpdatedOfferName(offerData.offer_name);
      dispatch(realtimeResearchActionCreator.rtrListRequest(params));
      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({ message_id: offerData.ad }));
      let missing = [];
      let inputData = { ...offerData };
      Object.keys(inputData).forEach(key => {
        if (inputData[key] === null || inputData[key] === "") {
          delete inputData[key];
        }
      });
      // remove all the null keys
      inputData.type = inputData.type === 2 ? VALUES.RTR : VALUES.OFFER;

      Array(7).fill(null).map((data, index) => {
        initialThumbnailsCount = initialThumbnailsCount + 1;
        if (inputData[`media_${index + 2}_url`] !== undefined && inputData[`media_${index + 2}_url`] !== null) {
          missing.push(index + 2);
        }
        if (inputData[`media_${index + 1}_url`] !== undefined && inputData[`media_${index + 1}_url`] !== null
          && inputData[`media_${index + 1}_type`] !== "stream") {
          // logic to concat names
          var data = inputData[`media_${index + 1}_url`].split("/")[5].split('_');
          var name = "";
          var x = inputData[`media_${index + 1}_url`];
          name = x.split("/")[5].replace(x.split("/")[5].split("_")[0], "").replace("_", "")
          inputData[`media_${index + 1}_name`] = decodeURI(name);
        }
      });

      // fill media Obj to render multiple media
      setMediaObj(missing);
      setNoOfFilter(missing.count + 1);
      setInputValues(inputData);
      setDraftData(inputData);
      setDraftCalled(true);
    }
  }, [offerData]);

  useEffect(() => {
    var inputFields = { ...inputValues };
    var key = mediaUrl.replace("_url", "_name")
    if (blobUrl) {
      inputFields[key] = fileDataObject.file_name;
      inputFields[mediaUrl] = blobUrl;
    }
    setInputValues(inputFields);

  }, [blobUrl]);

  useEffect(() => {
    dispatch(surveyActionCreator.requestMimeTypeList());
    dispatch(realtimeResearchActionCreator.rtrListFailureClear());
    return () => {
      setIsEdit(false);
      dispatch(offerActionCreator.uploadMediaRequestClear());
      dispatch(realtimeResearchActionCreator.rtrListFailureClear());
      validators = new Validator();
      setUpdatedOfferName("");
    }
  }, []);

  useEffect(() => {
    if (adCount) {
      setAdvertiserCount(adCount);
    }

    if (advertiserData && advertiserData.length > 0) {
      const newList = advertiserList.concat(advertiserData);
      setAdvertiserList(newList);
    }

    if (rtrList && rtrList.length) {
      const newRTRList = rtrDataList.concat(rtrList);
      setrtrDataList(newRTRList);
    } else {
      setrtrDataList([]);
      setrtrDataCount(0);
    }

    if (rtrCount) {
      setrtrDataCount(rtrCount);
    }

  }, [advertiserData, adCount, rtrList, rtrCount]);

  useEffect(() => {
    var rtrDrop = document.getElementById("rtrDropDown");

    if (rtrDataList && rtrDrop) {
      rtrDrop.addEventListener('scroll', handleRTRScroll);
      return () =>
        rtrDrop.removeEventListener('scroll', handleRTRScroll);
    }
  }, [rtrDataList]);

  useEffect(() => {
    const param = { ...inputValues };
    if (inputValues.type === VALUES.OFFER)
      delete param['rtr']; delete param['ad'];
    const result = Object.values(param).every(o => o !== "");
    setDisableSubmitBtn(result);
  }, [inputValues]);

  const handleRTRScroll = () => {
    var count = rtrDataCount;
    var adListCount = rtrDataList.length;
    var advertiserDrop = document.getElementById("rtrDropDown");
    if ((advertiserDrop.offsetHeight + advertiserDrop.scrollTop + 10) >= advertiserDrop.scrollHeight) {
      if (count > adListCount && adListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.brand_id = [inputValues.brand];
        params.limit = 10;
        params.offset = totalOffset + 10;
        params.rtr_type = 0;
        if (searchValue !== "") {
          params.name = searchValue;
        }
        params.type = 0;
        dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest(params));
      }
    }
  }

  const handleAdvertiserScroll = () => {
    let params = {};
    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }
    params.limit = 10;
    params.offset = totalOffset + 10;
    if (searchValue !== "") {
      params.searchKey = searchValue;
    }

    dispatch(realtimeResearchActionCreator.rtrListRequest(params));
    setTotalOffset(prev => (prev + 10));
  }

  return (
    <>
      <div style={{ flexDirection: "column", flex: "1" }}>
        <section className="general-section">
          <div className="center-stepper" id="stepper">
            <Stepper
              barStyle={"solid"}
              completeColor={"#3257FB"}
              completeTitleColor={"#FFFFFF"}
              size={24}
              titleFontSize={16}
              circleFontSize={12}
              circleFontColor={"#0E0E0F"}
              activeColor={"#fff"}
              activeTitleColor={"#fff"}
              defaultOpacity={'0.5'}
              defaultTitleOpacity={'0.5'}
              defaultTitleColor={"#fff"}
              defaultBarColor={"#fff"}
              steps={steps}
              activeStep={activeStep} />
          </div>
        </section>

        <div className='right-container'>
          {activeStep === 0 &&
            <section className="create-offer-section">
              <nav className={"left-nav-section"}>
                <Button className="btn-transparent pl-5 pb-3" style={{ verticalAlign: "super" }} onClick={backToOfferList}>
                  <img src={statusIcons.BACK} alt="go to back" className="go-back" />
                </Button>
                <h2 className={"secondary-title"} id="offer-header"
                  onMouseEnter={() => compareSize(headerRefs)}
                  onMouseLeave={() => compareSize(headerRefs)}
                  ref={headerRefs}
                  style={{ display: "inline-block", paddingLeft: "24px", marginBottom: 0, verticalAlign: "bottom" }}>
                  {isEdit ? `Edit ${updatedOfferName ? updatedOfferName : offerData.title ? offerData.title : ""}` : ` Build a new offer`}</h2>
                {hoverStatus && isEdit &&
                  <UncontrolledTooltip
                    className="survey-tool-tip"
                    placement="top"
                    target={"offer-header"}
                  >{`Edit ${offerData.title}`}
                  </UncontrolledTooltip>
                }
              </nav>
              <FormGroup className="text-right">
                {/* <Button
                  className="in-btn-lg in-btn-secondary btn-transparent cancel-offer-btn"
                  onClick={backToOfferList}
                >Cancel</Button> */}
                <Button
                  style={{ minWidth: '144px', minHeight: '48px' }}
                  className="in-btn-lg in-btn-primary ml-3 cancel-offer-btn"
                  type="submit"
                  id="btn-draft"
                  disabled={!disableSubmitBtn || isError}
                  onClick={(e) => handleFormSubmit(e.target.id)}
                >
                  {`${initiator}` === "btn-draft" && isOfferCreating ? <img
                    src={'/Icon_assets/svg/blue_loading_icon.svg'}
                    alt="Loading..."
                    className={`ad-spinner ad-spinner-lg`}
                  /> : isEdit ? `Update` : `Save As Draft`}
                </Button>
                {/* // CHANGE this to handle for submit */}
                <Button
                  id="btn-next-step"
                  style={{ minWidth: '144px', minHeight: '48px' }}
                  disabled={!disableSubmitBtn || isError}
                  className="ml-3 in-btn-lg in-btn-secondary create-offer-btn"
                  onClick={(e) => handleFormSubmit(e.target.id)}
                >{`${initiator}` === "btn-next-step" && isOfferCreating ? <ADspinner size="large" /> : `Next Step`}</Button>

              </FormGroup>
            </section>}
          {activeStep === 0 ?
            <Form className='create-new-offer'>
              <div className="create-offer-form">
                <Card className="data-card">
                  <section id="header-section">
                    <nav className="left-nav-section" style={{ marginTop: "19px" }}>
                      <h2>Offer Setup</h2>
                    </nav>
                  </section>
                  <CardBody>
                    <div className="ad-form">
                      <Table className="ad-table">
                        <Row>
                          <Col md={12} sm={12} lg={4}>
                            <FormGroup style={{ marginTop: "16px" }}>
                              <Input
                                type="text"
                                name="offer_name"
                                id="name"
                                required
                                className="form-input form-input-sm mt-2 required"
                                autoComplete="off"
                                value={inputValues.offer_name}
                                onChange={handleInputChange}
                                invalid={validators.offer_name.errorMsg !== ""}
                              />
                              <Label className="floating-heading-label required">Offer Name</Label>
                              <span className="character-label">{`Max ${offerNameLimit} characters`}</span>
                              <FormFeedback>{validators.offer_name.errorMsg}</FormFeedback>
                            </FormGroup></Col>

                          <Col md={12} sm={12} lg={4}>
                            <FormGroup className={(isOfferActive && isEdit) ? "disable-platform" : ""}>
                              <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                className="dropdown-option metrics"
                                direction="down"
                                id={"adminSelectedAdvertiser"}
                              >
                                <DropdownToggle
                                  tag="span"
                                  data-toggle="dropdown"
                                  className="dropdown_toggle"
                                >
                                  <span
                                    className="dropdown_title"
                                    title="Select advertiser"
                                  >
                                    {inputValues.advertiser ? inputValues.advertiser : "Select Advertiser"}
                                  </span>
                                  <img
                                    src={
                                      dropdownOpen
                                        ? statusIcons.ARROW_UP
                                        : statusIcons.ARROW_DOWN
                                    }
                                    className="dropdown-img"
                                  ></img>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <div style={{ padding: '8px' }}>
                                    <Input
                                      value={searchValue}
                                      type="text"
                                      name="search"
                                      id="searchUser"
                                      placeholder="Search..."
                                      className="search-input"
                                      autoComplete="off"
                                      onChange={(event) => handleSearch(event, "advertiserDropDown")}
                                    />
                                    <img
                                      src="/Icon_assets/svg/search-organization-icon.svg"
                                      alt="Search"
                                      className="search-image"
                                    />
                                  </div>
                                  <div className="metrics-list" id="advertiserDropDownList" style={{ maxHeight: "296px", overflow: "auto" }}>
                                    <InfiniteScroll
                                      dataLength={adCount || 0}
                                      next={handleAdvertiserScroll}
                                      hasMore={isAdvertiserNext !== null}
                                      scrollableTarget="advertiserDropDownList"
                                    >
                                      {advertiserList && advertiserList.length ?
                                        advertiserList.map((item, index) => {
                                          return (
                                            <DropdownItem
                                              style={{ paddingLeft: "26px" }}
                                              name={"advertiser"}
                                              key={index}
                                              id={index}
                                            >
                                              <div onClick={(e) => {
                                                const inputFields = { ...inputValues };
                                                inputFields['advertiser'] = item.name;
                                                inputFields["brand"] = item.id;
                                                inputFields['rtr'] = "";
                                                inputFields["ad"] = "";
                                                setInputValues(inputFields);
                                              }}>   {item.name}</div>
                                            </DropdownItem>
                                          )
                                        })
                                        :
                                        (
                                          <Loader style={{ height: '220px', padding: "0px", paddingTop: '60px' }} />
                                        )
                                      }
                                    </InfiniteScroll></div>
                                </DropdownMenu>

                                <Label className="floating-heading-label required"
                                  style={{ top: "-25px" }}>Advertiser</Label>

                              </Dropdown>

                            </FormGroup></Col>



                        </Row>
                        {/* <Row>
                          <Col md={12} sm={12} lg={4}>
                            <FormGroup style={{ position: "relative" }} id="type"
                              className={""}>
                              <Label className={`floating-label`} style={{ top: "2px" }}>Type</Label>
                              <div className={(isEdit || draftCalled) ? "disable-platform" : ""}>
                                <CustomInput type="radio"
                                  name="type"
                                  className={"custom-radio-input"}
                                  id="radio2"
                                  label={VALUES.RTR}
                                  value={VALUES.RTR}
                                  checked={inputValues.type === VALUES.RTR}
                                  onChange={(e) => handleInputChange(e, VALUES.RTR)} />

                                <CustomInput type="radio"
                                  style={{ marginRight: "27px" }}
                                  name="type"
                                  className={"custom-radio-input"}
                                  id="radio1"
                                  label={VALUES.OFFER}
                                  value={VALUES.OFFER}
                                  checked={inputValues.type === VALUES.OFFER}
                                  onChange={(e) => handleInputChange(e, VALUES.OFFER)} />
                              </div>
                            </FormGroup></Col>
                          <Col md={12} sm={12} lg={4}>
                            {(inputValues.type === VALUES.RTR) &&
                              <FormGroup
                                style={{
                                  pointerEvents: inputValues.brand === "" ? "none" : "",
                                  opacity: inputValues.brand === "" ? "0.4" : "1"
                                }}>
                                <Dropdown
                                  isOpen={rtrDropdownOpen}
                                  toggle={toggleRTR}
                                  className="dropdown-option metrics"
                                  direction="down"
                                >
                                  <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                    className="dropdown_toggle"
                                  >
                                    <span
                                      className="dropdown_title"
                                      title="Select rtr"
                                    >
                                      {inputValues.rtr ? inputValues.rtr : "Select RTR"}
                                    </span>
                                    <img
                                      src={
                                        rtrDropdownOpen
                                          ? statusIcons.ARROW_UP
                                          : statusIcons.ARROW_DOWN
                                      }
                                      className="dropdown-img"
                                    ></img>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem className="search-item" toggle={false}>
                                      <FormGroup className="search-metrics">
                                        <><Input
                                          value={searchValue}
                                          type="text"
                                          name="search"
                                          id="searchUser"
                                          placeholder="Search..."
                                          className="search-input"
                                          autoComplete="off"
                                          onChange={(event) => handleSearch(event, "rtrDropdown")}
                                        />
                                          <img
                                            src="/Icon_assets/svg/search-organization-icon.svg"
                                            alt="Search"
                                            className="search-image"
                                          /></>
                                      </FormGroup>
                                    </DropdownItem>
                                    <div className="metrics-list" id="rtrDropDown"
                                      style={{ maxHeight: "296px", overflow: "auto" }}>
                                      {!isLoading && rtrDataCount === 0 ?
                                        (
                                          <div style={{ textAlign: "center" }}>
                                            <span className="no-data-available-txt">
                                              No Survey data available
                                            </span>
                                          </div>
                                        ) : (rtrDataList && rtrDataList.length) ?
                                          (rtrDataList && rtrDataList.length ?
                                            (
                                              rtrDataList.map((item, index) => {
                                                return (
                                                  <DropdownItem
                                                    style={{ paddingLeft: "26px" }}
                                                    name={"advertiser"}
                                                    key={index}
                                                    id={index}
                                                  >
                                                    <div onClick={(e) => {
                                                      const inputFields = { ...inputValues };
                                                      inputFields['rtr'] = item.name;
                                                      inputFields["ad"] = item.id;
                                                      setInputValues(inputFields);
                                                    }}>   {item.name}</div>
                                                  </DropdownItem>
                                                )
                                              })
                                            )
                                            :
                                            (
                                              <div style={{ textAlign: "center" }}>
                                                <span className="no-data-available-txt">
                                                  No Survey data available
                                                </span>
                                              </div>
                                            ))
                                          : (
                                            <Loader style={{ height: '220px', padding: "0px", paddingTop: '60px' }} />
                                          )}
                                    </div>
                                  </DropdownMenu>

                                  <Label className="floating-heading-label required"
                                    style={{ top: "-25px" }}>RTR Survey</Label>

                                </Dropdown>

                              </FormGroup>
                            }
                          </Col>
                        </Row> */}

                        <Row>
                          <Col md={12} sm={12} lg={4}>
                            <FormGroup >
                              <Input
                                type="text"
                                name="title"
                                id="title"
                                required
                                className="form-input form-input-sm mt-2 required"
                                autoComplete="off"
                                value={inputValues.title}
                                onChange={handleInputChange}
                                invalid={validators.title.errorMsg !== ""}
                              />
                              <Label className="floating-heading-label required">Offer Title</Label>
                              <span className="character-label">{`Max ${titleLimit} characters`}</span>
                              <FormFeedback>{validators.title.errorMsg}</FormFeedback>
                            </FormGroup></Col>
                          <Col md={12} sm={12} lg={4}>
                            <FormGroup >
                              <Input
                                type="text"
                                name="subtitle"
                                id="subtitle"
                                className="form-input form-input-sm mt-2 "
                                autoComplete="off"
                                value={inputValues.subtitle}
                                onChange={handleInputChange}
                                invalid={validators.subtitle.errorMsg !== ""}
                              />
                              <Label className="floating-heading-label">Offer Subtitle</Label>
                              <span className="character-label">{`Max ${subTitleLimit} characters`}</span>
                              <FormFeedback>{validators.subtitle.errorMsg}</FormFeedback>
                            </FormGroup></Col>
                        </Row>
                        {inputValues.type.toLowerCase() === VALUES.OFFER.toLowerCase()
                          &&
                          <Row>
                            <Col md={12} sm={12} lg={4}>
                              <FormGroup >
                                <Input
                                  type="text"
                                  name="url"
                                  id="url"
                                  required
                                  className="form-input form-input-sm mt-3 required"
                                  autoComplete="off"
                                  value={inputValues.url}
                                  onChange={handleInputChange}
                                  invalid={validators.url.errorMsg !== ""}
                                />
                                <Label className="floating-heading-label required" >URL</Label>
                                <FormFeedback>{validators.url.errorMsg}</FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col md={12} sm={12} lg={4}>
                              <FormGroup style={{ marginTop: "16px" }}>
                                <Input
                                  type="text"
                                  name="button_text"
                                  id="button_text"
                                  required
                                  className="form-input form-input-sm mt-2 required"
                                  autoComplete="off"
                                  value={inputValues.button_text}
                                  onChange={handleInputChange}
                                  invalid={validators.button_text.errorMsg !== ""}
                                />
                                <Label className="floating-heading-label required">Button Label</Label>
                                <span className="character-label">{`Max ${buttonLabelLimit} characters`}</span>
                                <FormFeedback>{validators.button_text.errorMsg}</FormFeedback>
                              </FormGroup></Col>
                          </Row>}

                        {inputValues.type.toLowerCase() === VALUES.RTR.toLowerCase()
                          &&
                          <Row>
                            <Col md={12} sm={12} lg={4}>
                              <FormGroup style={{ marginTop: "16px" }}>
                                <Input
                                  type="text"
                                  name="button_text"
                                  id="button_text"
                                  required
                                  className="form-input form-input-sm mt-2 required"
                                  autoComplete="off"
                                  value={inputValues.button_text}
                                  onChange={handleInputChange}
                                  invalid={validators.button_text.errorMsg !== ""}
                                />
                                <Label className="floating-heading-label required">Button Label</Label>
                                <span className="character-label">{`Max ${buttonLabelLimit} characters`}</span>
                                <FormFeedback>{validators.button_text.errorMsg}</FormFeedback>
                              </FormGroup></Col>
                            <Col md={12} sm={12} lg={4}>
                              <FormGroup style={{ marginTop: "16px" }}>
                                <Input
                                  type="text"
                                  name="monetary"
                                  id="monetary"
                                  required
                                  className="form-input form-input-sm mt-2 required"
                                  autoComplete="off"
                                  value={inputValues.monetary}
                                  onChange={handleInputChange}
                                  invalid={validators.monetary.errorMsg !== ""}
                                />
                                <Label className="floating-heading-label required">Invisibly Points</Label>
                                <FormFeedback>{validators.monetary.errorMsg}</FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        }
                        {inputValues.type.toLowerCase() === VALUES.OFFER.toLowerCase() &&
                          <Row>
                            <Col md={12} sm={12} lg={4}>
                              <FormGroup >
                                <Input
                                  type="text"
                                  name="monetary"
                                  id="monetary"
                                  required
                                  className="form-input form-input-sm mt-2 required"
                                  autoComplete="off"
                                  value={inputValues.monetary}
                                  onChange={handleInputChange}
                                  invalid={validators.monetary.errorMsg !== ""}
                                />
                                <Label className="floating-heading-label required">Invisibly Points</Label>
                                <FormFeedback>{validators.monetary.errorMsg}</FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        }


                        <Row>
                          <Col md={24} sm={24} lg={8}>
                            <FormGroup>
                              <Label className="floating-heading-label required" style={{ top: "-25px" }}>Description</Label>
                              <span className="character-label" style={{ position: "absolute", top: "-20px", left: "100px" }}>{`(${characterEntered} of ${descriptionLimit})`}</span>
                              <Input type="textarea" className="form-area" onChange={(e) => handleInputChange(e)}
                                name="description" id="description" style={{}} value={inputValues.description} />
                              <FormFeedback>{validators.description.errorMsg}</FormFeedback>
                            </FormGroup>
                          </Col>

                        </Row>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card className="data-card">
                  <CardBody>
                    <section id="header-section">
                      <nav className="left-nav-section" style={{ marginTop: "19px" }}>
                        <h2 style={{ paddingLeft: "0" }}>Media Setup</h2>
                      </nav>
                    </section>
                    {inputValues.type === VALUES.OFFER &&

                      <div className="ad-form">
                        <Table className="ad-table">
                          <Row>
                            <Col md={24} sm={24} lg={8}>
                              <FormGroup style={{ position: "relative" }} id="type" >
                                <div style={{ display: "flex" }}>
                                  <div className={`floating-label required`} id="media-label-1" style={{ top: "2px", position: "inherit" }}>Select Media</div>
                                  <div>
                                    <img src={"/Icon_assets/svg/info-icon.svg"}
                                      style={{ padding: "6px" }} alt="Info icon" id="opentooltip" />
                                    <UncontrolledTooltip
                                      placement="top"
                                      className="ad-tool-tip"
                                      target="opentooltip"
                                    ><div style={{ padding: "10px", marginLeft: "10px" }}>
                                        <ul style={{ textAlign: "left", marginBottom: "0", fontSize: "12px" }}>
                                          <li>Supported image files types: gif, jpg,and png</li>
                                          <li>Recommended image file size: less than 2MB</li>
                                          <li>Supported video files types: mp3/mp4, mov</li>
                                          <li>Recommended video file size: less then 50MB</li>
                                        </ul>
                                      </div>
                                    </UncontrolledTooltip>
                                  </div>
                                  {((inputValues["media_1_type"].toLowerCase() === "image" && inputValues["media_1_size"] >= 2000000) ||
                                    (inputValues["media_1_type"].toLowerCase() === "video" && inputValues["media_1_size"] >= 50000000))
                                    &&
                                    <div>
                                      <img src={"/Icon_assets/png/warning-icon.png"}
                                        style={{ padding: "6px", height: "28px" }} alt="Info icon" id="warningTip" />
                                      <UncontrolledTooltip
                                        placement="top"
                                        className="ad-tool-tip"
                                        target="warningTip"
                                      ><div style={{ fontSize: "12px" }}>Uploaded file is larger than the recommended file size which may slow down your offer rendering</div>
                                      </UncontrolledTooltip>
                                    </div>}
                                </div>
                                <div id="media-1">
                                  <CustomInput type="radio"
                                    style={{ marginRight: "27px" }}
                                    name="media_1_type"
                                    className={"custom-radio-input"}
                                    id="media-radio1"
                                    label={MEDIA_TYPE.IV}
                                    value={"image"}
                                    checked={inputValues.media_1_type.toLowerCase() === "image" || inputValues.media_1_type.toLowerCase() === "video"}
                                    onChange={(e) => handleInputChange(e, "image")} />

                                  <CustomInput type="radio"
                                    name="media_1_type"
                                    className={"custom-radio-input"}
                                    id="media-radio2"
                                    label={MEDIA_TYPE.HOSTED_MEDIA}
                                    value={MEDIA_TYPE.STREAM.toLowerCase()}
                                    checked={inputValues.media_1_type === MEDIA_TYPE.STREAM.toLowerCase()}
                                    onChange={(e) => handleInputChange(e, MEDIA_TYPE.STREAM)} />

                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={24} sm={24} lg={8}>
                              {inputValues.media_1_type.toLowerCase() !== MEDIA_TYPE.STREAM.toLowerCase() ?
                                ((imageLoading && mediaUrl === "media_1_url") ?
                                  <Loader style={{ padding: "0px" }} />
                                  :
                                  (inputValues["media_1_name"] ?
                                    <>
                                      <div className="selection-div">
                                        <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                                        <span className="span-label">{inputValues.media_1_name}</span>
                                        <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close"
                                          name="media_1_name" onClick={(e) => removeSelection(e)} />
                                      </div>
                                    </>
                                    :
                                    <div>
                                      {uploadLogo}
                                      <Input
                                        type="file"
                                        name="media_1_url"
                                        id="logoImage"
                                        //value={inputValues["media_1_url"]}
                                        className="custom-file-upload-input"
                                        onChange={(e) => handleMediaUpload(e)}
                                      />
                                    </div>))

                                :
                                <FormGroup >
                                  <Input
                                    type="text"
                                    name="media_1_url"
                                    id="media_1_url"
                                    required
                                    className="form-input form-input-sm mt-2 required"
                                    autoComplete="off"
                                    value={inputValues.media_1_url}
                                    onChange={handleInputChange}
                                    invalid={validators.media_1_url.errorMsg !== ""}
                                  />
                                  <Label className="floating-heading-label required">Media URL</Label>
                                  <FormFeedback>{validators.media_1_url.errorMsg}</FormFeedback>

                                </FormGroup>
                              }
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} sm={12} lg={12} style={{ display: "flex" }}>
                              <Button
                                className={`btn-transparent add-filter ${noOfFilter >= 7 ? 'add-filter-inactive' : 'add-filter-active'}`}
                                onClick={addFilter}>
                                <img ref={myRef}
                                  src={noOfFilter === 7 ? "/Icon_assets/svg/plus_icon_gray_transparent.svg" :
                                    "/Icon_assets/svg/plus_icon_blue_transparent.svg"}
                                  alt="Add Metrics Filters"
                                  className="add-filter-img"
                                />Add Media Type</Button>
                              <img src={"/Icon_assets/svg/info-icon.svg"}
                                style={{ marginLeft: "10px", marginTop: "30px", marginBottom: "30px", padding: "1%" }} alt="Info icon" id="mediaType" />
                              <UncontrolledTooltip
                                placement="top"
                                className="ad-tool-tip"
                                target="mediaType"
                              >
                                {"Add upto 7 media types"}
                              </UncontrolledTooltip>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} sm={12} lg={12}>
                              {mediaObj && mediaObj.length > 0 &&
                                mediaObj.map((dFilter, index) => {
                                  return (<MediaFilter
                                    executeScroll={executeScroll}
                                    mediaUrl={mediaUrl}
                                    key={index}
                                    radioId={dFilter}
                                    id={index} keyType={`media_${dFilter}_type`}
                                    keyUrl={`media_${dFilter}_url`}
                                    keyName={`media_${dFilter}_name`}
                                    keySize={`media_${dFilter}_size`}
                                    handleMediaUpload={handleMediaUpload}
                                    handleInputChange={handleInputChange}
                                    removeFilter={removeFilter}
                                    MEDIA_TYPE={MEDIA_TYPE}
                                    validators={validators}
                                    inputValues={inputValues}
                                    imageLoading={imageLoading}
                                    removeSelection={removeSelection}
                                  />)
                                })}
                            </Col>
                          </Row>

                        </Table>
                      </div>
                    }

                    {inputValues.type === VALUES.RTR &&
                      <div className="ad-form">
                        <Table className="ad-table">
                          <Row>
                            <Col md={24} sm={24} lg={8}>
                              <FormGroup style={{ position: "relative" }} id="type" >
                                <div style={{ display: "flex" }}>
                                  <div className={`floating-label required`} id="media-label-1" style={{ top: "2px", position: "inherit" }}>Select Media</div>
                                  <div>
                                    <img src={"/Icon_assets/svg/info-icon.svg"}
                                      style={{ padding: "6px" }} alt="Info icon" id="opentooltip" />
                                    <UncontrolledTooltip
                                      placement="top"
                                      className="ad-tool-tip"
                                      target="opentooltip"
                                    ><div style={{ padding: "10px", marginLeft: "10px" }}>
                                        <ul style={{ textAlign: "left", marginBottom: "0", fontSize: "12px" }}>
                                          <li>Supported image files types: gif, jpg,and png</li>
                                          <li>Recommended image file size: less than 2MB</li>
                                        </ul></div>
                                    </UncontrolledTooltip>
                                  </div>
                                  {((inputValues["media_1_type"].toLowerCase() === "image" && inputValues["media_1_size"] >= 2000000))
                                    &&
                                    <div>
                                      <img src={"/Icon_assets/png/warning-icon.png"}
                                        style={{ padding: "6px", height: "28px" }} alt="Info icon" id="warningTip" />
                                      <UncontrolledTooltip
                                        placement="top"
                                        className="ad-tool-tip"
                                        target="warningTip"
                                      ><div style={{ fontSize: "12px" }}>Uploaded file is larger than the recommended file size which may slow down your offer rendering</div>
                                      </UncontrolledTooltip>
                                    </div>}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={24} sm={24} lg={8}>
                              {
                                ((imageLoading && mediaUrl === "media_1_url") ?
                                  <Loader style={{ padding: "0px" }} />
                                  :
                                  (inputValues["media_1_name"] ?
                                    <>
                                      <div className="selection-div">
                                        <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                                        <span className="span-label">{inputValues.media_1_name}</span>
                                        <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close"
                                          name="media_1_name" onClick={(e) => removeSelection(e)} />
                                      </div>
                                    </>

                                    :
                                    <div>
                                      <Button className="export-icon offer-upload" style={{ opacity: "1" }} onClick={() => console.log('export button')}>
                                        <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
                                        <span className="span-label">Upload Image</span>
                                      </Button>
                                      <Input
                                        type="file"
                                        name="media_1_url"
                                        id="logoImage"
                                        //value={inputValues["media_1_url"]}
                                        className="custom-file-upload-input"
                                        onChange={(e) => handleMediaUpload(e)}
                                      />
                                    </div>))
                              }
                            </Col>
                          </Row>
                        </Table>
                      </div>
                    }

                  </CardBody>
                </Card>
              </div>
            </Form>
            :
            <div className="conversion-container" style={{ marginTop: "40px", flexDirection: "column" }}>
              {(isAPICalled && createdOffer && createdOffer.type === 2 ||
                (!isAPICalled && isEdit && offerData && offerData.type === 2)
                || (createdOffer && createdOffer.type === 2)) ?
                <RTROfferPreview
                  msg_id={messageId}
                  brand_id={brandId}
                  ad_id={advertiserId}
                  hideHeader={true}
                  offerId={offerId}
                  goToGeneralSetup={goToGeneralSetup}
                /> :
                <BrandOfferPreivew
                  updatedOfferName={updatedOfferName}
                  msg_id={messageId}
                  brand_id={brandId}
                  ad_id={advertiserId}
                  hideHeader={true}
                  offerId={offerId}
                  goToGeneralSetup={goToGeneralSetup} />
              }
            </div>

          }
        </div>
        {
          showPopup &&
          <LeaveOfferModal display={showPopup} closePopup={closeModal} />
        }
      </div>
    </>
  )
}

export default CreateOffer;