import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  realtimeResearchData: {},
  isStatDataRequest: false,
  isDetailsRequest: false,
  rtrDetails: {},
  rtrList: {},
  rtrDetailsStat: [{ "Total Respondents": 0, "Total Completes": 0, "Ad": "" }],
  exportRawEvents: {},
  isExport: false,
  isUniversalTagFetching: false,
  universalTagScriptData: {},
  isAdStateUpdating: false,
  upadtedAdStateData: {},
  isAdCloneRequest: false,
  adCloneResponseData: {},
  exportComplete: false,
  isAdDeleteRequest: false,
  isAdDeleted: false,
  adDeleteResponseData: {},
  bothAdsData: {},
  bothAdsLoading: false,
  surveyData: {},
  surveyDataFailure: {},
  surveyLoading: false,
  isRTRSurveyDataLoading: true,
  RTRSurveyData: []
};

const realtimeResearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTIONS.GET_REALTIME_RESEARCH_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: undefined,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_LIST_SUCCESS:
      return {
        ...state,
        realtimeResearchData: action.payload,
        isLoading: false,
        successMsg: action.successMsg
      };

    case ACTIONS.GET_REALTIME_RESEARCH_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        realtimeResearchData: {},
        isLoading: false,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.RTR_LIST_REQUEST:
      return {
        ...state,
        rtrList: action.payload,
        isLoading: true,
        isFetched: false,
        errorMsg: undefined,
      };

    case ACTIONS.RTR_LIST_SUCCESS:
      return {
        ...state,
        rtrList: action.payload,
        isFetched: true,
        isLoading: false,
      };

    case ACTIONS.RTR_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isFetched: false,
        isLoading: false,
      };

    case ACTIONS.RTR_LIST_FAILURE_CLEAR:
      return {
        ...state,
        rtrList: {},
        realtimeResearchData: { results: [], counts: 0 },
        errorMsg: undefined,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_REQUEST:
      return {
        ...state,
        isDetailsRequest: true
      }

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_SUCCESS:
      return {
        ...state,
        rtrDetails: action.payload,
        isDetailsRequest: false,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isDetailsRequest: false,
        rtrDetails: {}
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        isDetailsRequest: false
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_REQUEST:
      return {
        ...state,
        isStatDataRequest: false,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_SUCCESS:
      return {
        ...state,
        rtrDetailsStat: action.payload,
        isStatDataRequest: true,

      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isStatDataRequest: true,
      };

    case ACTIONS.GET_REALTIME_RESEARCH_DETAILS_STAT_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        isStatDataRequest: false
      };

    case ACTIONS.GET_EXPORT_RAW_EVENTS_REQUEST:
      return {
        ...state,
        exportComplete: false,

      };

    case ACTIONS.GET_EXPORT_RAW_EVENTS_SUCCESS:
      return {
        ...state,
        exportComplete: true,
        isExport: false,

      };

    case ACTIONS.GET_EXPORT_RAW_EVENTS_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        exportComplete: false,
      };

    case ACTIONS.GET_EXPORT_RAW_EVENTS_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        exportComplete: false,
        exportRawEvents: {}
      };

    case ACTIONS.UPDATE_AD_STATE_REQUEST:
      return {
        ...state,
        isAdStateUpdating: true,
      };

    case ACTIONS.UPDATE_AD_STATE_SUCCESS:
      return {
        ...state,
        upadtedAdStateData: action.payload,
        isAdStateUpdating: false,
      };

    case ACTIONS.UPDATE_AD_STATE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isAdStateUpdating: false,
      };

    case ACTIONS.UPDATE_AD_STATE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        isAdStateUpdating: false,
        upadtedAdStateData: {}
      };

    case ACTIONS.GET_UNIVERSAL_TAG_REQUEST:
      return {
        ...state,
        isUniversalTagFetching: true,
        universalTagScriptData: {}
      };

    case ACTIONS.GET_UNIVERSAL_TAG_SUCCESS:
      return {
        ...state,
        isUniversalTagFetching: false,
        universalTagScriptData: action.payload
      };

    case ACTIONS.GET_UNIVERSAL_TAG_FAILURE:
      return {
        ...state,
        isUniversalTagFetching: false,
        errorMsg: action.payload
      };

    case ACTIONS.GET_UNIVERSAL_TAG_CLEAR:
      return {
        ...state,
        isUniversalTagFetching: false,
        errorMsg: undefined
      };

    case ACTIONS.CLONE_RTR_AD_REQUEST:
      return {
        ...state,
        isAdCloneRequest: true,
        adCloneResponseData: {}
      };

    case ACTIONS.CLONE_RTR_AD_SUCCESS:
      return {
        ...state,
        isAdCloneRequest: false,
        adCloneResponseData: action.payload
      };

    case ACTIONS.CLONE_RTR_AD_FAILURE:
      return {
        ...state,
        isAdCloneRequest: false,
        errorMsg: action.payload
      };

    case ACTIONS.CLONE_RTR_AD_CLEAR:
      return {
        ...state,
        isAdCloneRequest: false,
        errorMsg: undefined
      };

    case ACTIONS.DELETE_RTR_AD_REQUEST:
      return {
        ...state,
        isAdDeleteRequest: true,
        adDeleteResponseData: {}
      };

    case ACTIONS.DELETE_RTR_AD_SUCCESS:
      return {
        ...state,
        isAdDeleteRequest: false,
        isAdDeleted: true,
        adDeleteResponseData: action.payload
      };

    case ACTIONS.DELETE_RTR_AD_FAILURE:
      return {
        ...state,
        isAdDeleteRequest: false,
        errorMsg: action.payload
      };

    case ACTIONS.DELETE_RTR_AD_CLEAR:
      return {
        ...state,
        isAdDeleteRequest: false,
        isAdDeleted: false,
        errorMsg: undefined
      };
    case ACTIONS.CLEAR_RESPONSE_DATA:
      return {
        ...state,
        adCloneResponseData: {},
        upadtedAdStateData: {},
        isAdDeleted: false
      };
    case ACTIONS.GET_BOTH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        bothAdsData: {},
        bothAdsLoading: true
      };

    case ACTIONS.GET_BOTH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        bothAdsData: action.payload,
        bothAdsLoading: false
      };

    case ACTIONS.GET_BOTH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        bothAdsData: {},
        bothAdsLoading: false
      };

    case ACTIONS.GET_BOTH_CAMPAIGNS_CLEAR:
      return {
        ...state,
        bothAdsData: {},
        bothAdsLoading: false
      };

    case ACTIONS.SET_RTR_SURVEY_DATA_REQUEST:
      return {
        ...state,
        surveyData: {},
        surveyLoading: true
      };
    case ACTIONS.SET_RTR_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        surveyData: action.payload,
        surveyLoading: false
      };
    case ACTIONS.SET_RTR_SURVEY_DATA_FAILURE:
      return {
        ...state,
        surveyDataFailure: {},
        surveyLoading: false
      };
    case ACTIONS.SET_RTR_SURVEY_DATA_CLEAR:
      return {
        ...state,
        surveyData: {},
        surveyDataFailure: {},
        surveyLoading: false
      };
    case ACTIONS.GET_RTR_BY_AD_ID_SUCCESS:
      return {
        ...state,
        RTRSurveyData: action.payload,
        isRTRSurveyDataLoading: false
      };
    case ACTIONS.GET_RTR_BY_AD_ID_FAILURE:
      return {
        ...state,
        RTRSurveyData: { failed: true },
        isRTRSurveyDataLoading: false
      };
    default:
      return state;
  }
};

export default realtimeResearchReducer;
