import React, { useEffect } from "react";
// import "./index.scss"; 
import StreamThumbNail from "../StreamThumbNail";

const Thumbnails = ({ offerDetails, handleCurrentSelection, showTop, elementScrolling, orientation = "vertical", isLoading, currentSelection, thumbnailsCount, handleThumbnailsScroll }) => {
  const ICONS = {
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    VIDEO_PLAY: "/Icon_assets/svg/video-play.svg",
    DEFAULT_THUMBAIL: "/Icon_assets/svg/default-thumbnail.jpg"
  }

  useEffect(() => {
    const element = document.getElementById("thumbnails-list");
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return (() => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    });
  }, []);


  const handleScroll = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const diff = scrollHeight - scrollTop;

    if (Math.floor(diff) > clientHeight) {
      //remove top btn
      handleThumbnailsScroll(false);
    } else {
      //"remove bottom btn
      handleThumbnailsScroll(true);
    }
  }

  return (<>
    {!isLoading && (
      (thumbnailsCount >= 2) && (
        <div className={`thumbnails-container ${orientation === "vertical" ? "d-none d-md-block d-lg-block vertical" : "d-block d-sm-block d-md-none horizontal"}`}>
          <div className="thumbnails-wrapper">
            <div className="thumbnails-list" id="thumbnails-list" onScroll={handleScroll}>
              <ul className={`thumbnails-ul mb-0 ${orientation === "horizontal" ? "d-flex" : ""}`}>

                {Array(thumbnailsCount).fill(null).map((data, index) => {
                  if (offerDetails[`media_${index + 1}_url`] !== undefined) {
                    let thumbnailID = `thumbnail-wrapper-${orientation === "horizontal" ? "mobile" : "desktop"}${index + 1}`;
                    let thumbnailURL = offerDetails[`media_${index + 1}_url`];
                    let thumbnailType = offerDetails[`media_${index + 1}_type`];
                    return (<>
                      <li className="thumbnails-li">
                        <div
                          className={`thumbnail-wrapper ${currentSelection.url === thumbnailURL ? "active-thumbnail" : ""}`}
                          id={thumbnailID}
                          onClick={(e) => handleCurrentSelection(e, thumbnailURL, thumbnailType, index + 1)}
                        >
                          {thumbnailType === "image" ?
                            (<div className="thumbnail-image" style={{ backgroundImage: `url(${thumbnailURL})` }}></div>) :
                            thumbnailType === "video" ?
                              (<>
                                <div className="thumbnail-image" style={{ backgroundImage: `url(${ICONS.DEFAULT_THUMBAIL})` }}></div>
                                <div className="video-icon">
                                  <img src={ICONS.VIDEO_PLAY} alt="Play video" />
                                </div>
                              </>) :
                              (<StreamThumbNail
                                bgSrc={thumbnailURL}
                                className="thumbnail-image"
                              >
                                <div className="video-icon">
                                  <img src={ICONS.VIDEO_PLAY} alt="Play video" />
                                </div>
                              </StreamThumbNail>)
                          }
                        </div>
                      </li>
                    </>);
                  } else {
                    return (<></>);
                  }
                })
                }

              </ul>
            </div>
            {orientation === "vertical" && thumbnailsCount >= 6 ?
              (showTop ?
                (<div className="arrows arrow-up" onClick={() => elementScrolling("top", "thumbnails-list")}>
                  <img src={ICONS.ARROW_DOWN} alt="navigation arrow" />
                </div>) :
                (<div className="arrows arrow-down" onClick={() => elementScrolling("bottom", "thumbnails-list")}>
                  <img src={ICONS.ARROW_DOWN} alt="navigation arrow" />
                </div>)) :
              ("")
            }
          </div>
        </div>
      )
    )}
  </>);
}

export default Thumbnails;