import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import ROUTES from "../../utils/routeConstants";
import "./index.scss";

import RBAC from "../../components/RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";

const SideNavigation = () => {
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;

  const [activeTab, setActiveTab] = useState('');
  const TAB = {
    USER: "User",
    ORGANIZATION: "Organization",
    PROFILE: "Profile",
    CONVERSION: "Conversion",
    OFFERS_LIST: "Offers",
    ADVERTISER: "Advertiser"
  }
  return (
    <section className="side-navigation-wrapper">
      {(ROUTES.DASHBOARD === window.location.pathname || ROUTES.REALTIME_RESEARCH === window.location.pathname) ?
        //Dashboard only (Media/RTR) left navigation
        (<></>)
        /*userData.organization_role === 0 ? 
          //Admin  Access only
          (<Nav vertical>
            <NavItem>
              <NavLink to={ROUTES.DASHBOARD} className="nav-link" activeClassName="selected">
                <img src="/Icon_assets/svg/media_dashboard.svg" alt="Media Dashboard" />
                <span>Media Dashboard</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={ROUTES.REALTIME_RESEARCH} className="nav-link" activeClassName="selected">
                <img src="/Icon_assets/svg/realtime_research.svg" alt="Realtime Research" />
                <span>Realtime Research</span>
              </NavLink>
            </NavItem>
          </Nav>): 
          //User with limited access
          (<Nav vertical>
            {is_media_enabled && (
              <NavItem>
                <NavLink to={ROUTES.DASHBOARD} className="nav-link" activeClassName="selected">
                  <img src="/Icon_assets/svg/media_dashboard.svg" alt="Media Dashboard" />
                  <span>Media Dashboard</span>
                </NavLink>
              </NavItem>
            )}
            {is_rtr_enabled && (
            <NavItem>
              <NavLink to={ROUTES.REALTIME_RESEARCH} className="nav-link" activeClassName="selected">
                <img src="/Icon_assets/svg/realtime_research.svg" alt="Realtime Research" />
                <span>Realtime Research</span>
              </NavLink>
            </NavItem>
            )}
          </Nav>) */
        :
        (<Nav vertical>
          {
            <>
              <NavItem>
                <NavLink to={ROUTES.PROFILE_SETTINGS}
                  className={(activeTab === TAB.PROFILE ? 'selected' : '') + " nav-link"}
                  isActive={(match) => {
                    if (match) {
                      setActiveTab(TAB.PROFILE)
                    }
                  }}>
                  <img src={activeTab === TAB.PROFILE ?
                    "/Icon_assets/svg/active-settings.svg" :
                    "/Icon_assets/svg/settings.svg"} alt="Profile Settings" />
                  <span>Profile Settings</span>
                </NavLink>
              </NavItem>
            </>
          }

          <>
            {(userData.organization_role === 0) && (
              <NavItem>
                <NavLink to={ROUTES.ORGANIZATIONS_LIST} className={(activeTab === TAB.ORGANIZATION ? 'selected' : '') + " nav-link"}
                  isActive={(match) => {
                    if (match) {
                      setActiveTab(TAB.ORGANIZATION)
                    }
                  }}>
                  <img src={activeTab === TAB.ORGANIZATION ? "/Icon_assets/svg/active-organization.svg" :
                    "/Icon_assets/svg/organization_icon.svg"} alt="Organizations" />
                  <span>Organizations</span>
                </NavLink>
              </NavItem>
            )}

            <RBAC requiredPermissions={PERMISSIONS.MANAGE_BRAND_READ}>
              <NavItem>
                <NavLink to={ROUTES.BRANDS_LIST} className={(activeTab === TAB.ADVERTISER ? 'selected' : '') + " nav-link"}
                  isActive={(match) => {
                    if (match) {
                      setActiveTab(TAB.ADVERTISER)
                    }
                  }}>
                  <img src={activeTab === TAB.ADVERTISER ? "/Icon_assets/svg/advertiser_selected.svg" :
                    "/Icon_assets/svg/advertiser_icon.svg"} alt="Organizations" />
                  <span>Advertisers</span>
                </NavLink>
              </NavItem>
            </RBAC>

            <RBAC requiredPermissions={PERMISSIONS.MANAGE_USER_READ}>
              <NavItem>
                <NavLink to={ROUTES.USERS_LIST} className={(activeTab === TAB.USER ? 'selected' : '') + " nav-link"}
                  isActive={(match) => {
                    if (match) {
                      setActiveTab(TAB.USER)
                    }
                  }}>
                  <img src={activeTab === TAB.USER ? "/Icon_assets/svg/active-user.svg" :
                    "/Icon_assets/svg/users.svg"} alt="Users" />
                  <span>Users</span>
                </NavLink>
              </NavItem>
            </RBAC>

            {(userData.organization_role !== 0) && is_rtr_enabled && (<>
              <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                <NavItem>
                  <NavLink to={ROUTES.CONVERSION} className={(activeTab === TAB.CONVERSION ? 'selected' : '') + " nav-link"}
                    isActive={(match) => {
                      if (match) {
                        setActiveTab(TAB.CONVERSION)
                      }
                    }}>
                    <img src={activeTab === TAB.CONVERSION ? "/Icon_assets/svg/conversion-selected.svg" :
                      "/Icon_assets/svg/conversion.svg"} alt="Conversions" />
                    <span>Conversions</span>
                  </NavLink>
                </NavItem>
              </RBAC>
            </>)}

            {(userData.organization_role === 0) && (<>
              <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                <NavItem>
                  <NavLink to={ROUTES.CONVERSION} className={(activeTab === TAB.CONVERSION ? 'selected' : '') + " nav-link"}
                    isActive={(match) => {
                      if (match) {
                        setActiveTab(TAB.CONVERSION)
                      }
                    }}>
                    <img src={activeTab === TAB.CONVERSION ? "/Icon_assets/svg/conversion-selected.svg" :
                      "/Icon_assets/svg/conversion.svg"} alt="Conversions" />
                    <span>Conversions</span>
                  </NavLink>
                </NavItem>
              </RBAC>
            </>)}

            {/* <NavItem>
                <NavLink to={ROUTES.OFFERS_LIST} className={(activeTab === TAB.OFFERS_LIST ? 'selected' : '') + " nav-link"}
                  isActive={(match) => {
                    if (match) {
                      setActiveTab(TAB.OFFERS_LIST)
                    }
                  }}>
                  <img src={activeTab === TAB.OFFERS_LIST ? "/Icon_assets/svg/conversion-selected.svg" :
                    "/Icon_assets/svg/conversion.svg"} alt="Conversions" />
                  <span>Invisibly Offers</span>
                </NavLink>
              </NavItem> */}
          </>
        </Nav>)
      }
    </section>
  );
};

export default SideNavigation;
