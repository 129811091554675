import React, { useState } from 'react';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

const CodeRule = ({ actionIcons, openPixelCode, data }) => {
  return (
    <span>
      <img src={actionIcons.CODE_ICON} className="preview-icon" alt="preview"
        style={{ cursor: "pointer", marginRight: "18px" }}
        onClick={(e) => openPixelCode(e, data)} />
    </span >
  )
}

export default CodeRule;