import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserListRequest,
  createUserClearMessage, updateUserFailureClear,
  activateDeactivateClear,
  getUserListFailureClear
} from "../../actions/userActions";
import { getOrganizationListFailureClear } from "../../actions/organizationActions";
import { userActionCreator } from "../../actions/index.js";
import {
  Card,
  CardBody,
  Button,
  FormGroup,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import ROUTES from "../../utils/routeConstants";
import history from "../../history";
import Loader from "../../components/Loader";
import { Table } from "reactstrap";
import SkeletonResearchTable from "../Loader_Skeleton/SkeletonResearchTable";
import "./index.scss";

import PopUpModal from "../../components/PopUpModal";
import UserForm from "./UserForm";
import Notifications from "../../components/Notifications";
import DeactivatePopUpModal from "../../components/DeactivatePopUpModal";
import ADPagination from "../../components/ADPagination";
import RBAC from "../../components/RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";

const Users = () => {

  const RolesEnum =
  {
    "28936c1d-0343-413d-8ae0-1f7b4f90e18d": "Invisibly Admin",
    "6a48303f-ae66-4c0f-883e-d50ff7c73740": "Analyst",
    "5fb66e57-48b9-4e9e-a153-9e16f8665558": "Admin",
    "94aa74fe-9b9f-4464-a955-148669a22793": "Builder"
  }
  const [openPopUp, setOpenPopUp] = useState(false);
  const [deactivateOpenPopUp, setDeactivateOpenPopUp] = useState(false);
  const [deactivateUserId, setDeactivateUserId] = useState("");
  const [deactivateUserName, setDeactivateUserName] = useState("");
  const [userData, setUserData] = useState("");
  const [popUpValue, setPopUpValue] = useState("");
  const userListData = useSelector((state) => state.user.userListData);
  const isUserAdded = useSelector((state) => state.user.isUserAdded);
  const disableToggle = useSelector((state) => state.user.disableToggle);
  const activateUserRequestCompleted = useSelector((state) => state.user.activateUserRequestCompleted);
  const [searchImage, setSearchImage] = useState("/Icon_assets/svg/search.svg");
  const [paramList, setParamList] = useState({});
  /*** Pagination setup start***/
  const [currentPage, setCurrentPage] = useState(1);
  const [isPagination, setIsPagination] = useState(false);
  let PageSize = 10;
  const isUserUpdated = useSelector((state) => state.user.isUserUpdated);
  /*** Pagination setup end***/


  const updateUserStatus = (event, data) => {
    if (data.status === 1) {
      //if it is active
      setDeactivateUserId(data.id);
      setDeactivateUserName(data.name);
      setDeactivateOpenPopUp(true);
    } else if (data.status === 2) {
      // need to change logic once api is ready
      var param = {};
      param.id = data.id;
      param.status = 1;
      dispatch(userActionCreator.activateUserRequest(param));
    }
  }
  useEffect(() => {
    dispatch(getUserListRequest());
  }, []);

  useEffect(() => {
    if (userListData.results && userListData.count > 0) {
      setIsPagination(true);
      if (userListData.next === null) {
        //if tableData have only 1 page set current page to 1
        const checkPageCount = Math.ceil(userListData.count / PageSize);
        setCurrentPage(checkPageCount);
      }
    } else {
      setIsPagination(false);
    }
  }, [userListData.count]);

  useEffect(() => {
    if (disableToggle) {
      dispatch(getUserListRequest());
      dispatch(activateDeactivateClear());
    }
  }, [disableToggle]);

  useEffect(() => {
    if (activateUserRequestCompleted) {
      dispatch(getUserListRequest());
      dispatch(activateDeactivateClear());
    }
  }, [activateUserRequestCompleted]);

  /*** Redux init ***/
  const dispatch = useDispatch();
  const isUserListFetching = useSelector(
    (state) => state.user.isUserListFetching
  );

  /*** Redux end ***/
  const handleSearch = (event) => {
    //on each search set current page to 1 in Pagination
    setCurrentPage(1);
    setParamList({ ...paramList, searchKey: event.target.value });
    dispatch(getUserListRequest({ ...paramList, searchKey: event.target.value }));
  };

  const handlePaginationClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    dispatch(getUserListRequest({ ...paramList, offset: (pageIndex - 1) * 10 }));
  };

  useEffect(() => {
    if (isUserAdded) {
      setOpenPopUp(false);
      dispatch(createUserClearMessage());
      dispatch(getUserListRequest());
    }
  }, [isUserAdded]);

  useEffect(() => {
    if (isUserUpdated) {
      setOpenPopUp(false);
      dispatch(updateUserFailureClear());
      dispatch(getUserListRequest());
    }
  }, [isUserUpdated]);

  return (
    <React.Fragment>
      <Notifications />
      {/* <HeaderNavigation /> */}
      {/* <SideNavigation /> */}
      <div className="right-container">
        <section className="title-section">
          <h2>Users</h2>
          <RBAC requiredPermissions={PERMISSIONS.BUILD_USER_WRITE} >
            <Button
              name="Add"
              className="in-btn-sm in-btn-primary add-new-user-btn"
              onClick={(e) => {
                // setOpenPopUp(true);
                history.push({
                  pathname: ROUTES.ADD_USER,
                  state: { isEdit: false }
                })
                setPopUpValue(e.target.name);
              }}
            >
              Add New User
          </Button>
          </RBAC>
        </section>
        <Card className={"data-card user-list-section"}>
          <CardBody>
            <section className="search-container">
              <FormGroup>
                <InputGroup className="search-group">
                  <InputGroupAddon addonType="prepend">
                    <img
                      className="search-image"
                      src={searchImage}
                      alt="Search"
                    />
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="search"
                    placeholder="Search..."
                    onChange={(event) => handleSearch(event)}
                    autoComplete='off'
                    onFocus={() => { setSearchImage("/Icon_assets/svg/search-active.svg") }}
                    onBlur={() => { setSearchImage("/Icon_assets/svg/search.svg") }}
                  />
                </InputGroup>
              </FormGroup>
            </section>
            {isUserListFetching ? (
              userListData &&
                userListData.results &&
                userListData.results.length ? (
                <Table className="table user-details-tbl">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      {/* <th>Role</th> */}
                      {<RBAC requiredPermissions={PERMISSIONS.BUILD_USER_WRITE}>
                        <th>Status</th>
                        <th></th>
                      </RBAC>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {userListData.results.map((data, index) => {
                      let roles = [...new Set(data?.organization_roles.map((i) => RolesEnum[i.role]))].sort();
                      return (
                        <tr key={index}>
                          <td className="user-column">
                            <span className="user-name" title={data.status === 0 ? "Pending invitation" : data.name}>
                              {data.status === 0
                                ? "Pending invitation"
                                : data.name}
                            </span>
                          </td>
                          <td className="email-column">
                            <span className="user-email">{data.email}</span>
                          </td>

                          {/* {roles?.length > 1 ?
                            <>
                              <td className="role-column"
                                style={{ cursor: "pointer" }}>
                                <span id={'opentooltip-' + data.id}
                                  key={'span-' + data.id + '-' + index}> {'Multiple Roles'}</span>
                              </td>
                              <UncontrolledTooltip
                                placement="top"
                                className="ad-tool-tip brands-tip"
                                target={'opentooltip-' + data.id}
                                key={'opentooltip-' + data.id}
                              >  {roles.map((i) => <div className="text-left p-1"> {i} </div>)} </UncontrolledTooltip > </>
                            :
                            <td className="role-column">
                              <span className="user-role">{roles.length === 0 ?
                                (data.organization_role === 0 ? "Invisibly Admin" : "") : roles[0] || 'N.A.'}</span>
                            </td>} */}


                          {<RBAC requiredPermissions={PERMISSIONS.MANAGE_USER_DEACTIVATE}>
                            <td className="status-column">
                              {data.status === 0 ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  <label className={`switch`}>
                                    <input
                                      type="checkbox"
                                      checked={data.status === 1}
                                      onChange={(e) => { updateUserStatus(e, data) }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="user-status">
                                    {data.status === 1 ? "Active" : "Deactive"}
                                  </span>
                                </>
                              )}
                            </td>

                            <td className="edit-column d-flex justify-content-end" style={{ width: "auto", borderBottom: "none" }}>
                              {data.status === 0 ? (
                                <>
                                  <span className="resend-invite">
                                    <img
                                      src="/Icon_assets/svg/resendInvite.svg"
                                      alt="resend invite"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      src="/Icon_assets/svg/deleteRequest.svg"
                                      alt="delete request"
                                    />
                                  </span>
                                </>
                              ) : (
                                <span
                                  className="edit-icon"
                                  onClick={() => {
                                    dispatch(getOrganizationListFailureClear());
                                    // setOpenPopUp(true);
                                    // setPopUpValue("Edit");
                                    // call API get by user id
                                    dispatch(getUserListFailureClear());

                                    history.push({
                                      pathname: `/users/edit/${data.id}`,
                                      state: {
                                        isEdit: true,
                                        data
                                      }
                                    })
                                    setUserData(data);
                                  }}
                                >
                                  <img
                                    src="/Icon_assets/svg/edit.svg"
                                    alt="edit"
                                  />
                                </span>
                              )}
                            </td>
                          </RBAC>
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className="no-wallet-data-text">
                  No User List data available
                </p>
              )
            ) : (
              <SkeletonResearchTable rows={10} />
            )}
            {isPagination ? userListData?.results?.length > 0 && (<>
              <ADPagination
                className="users-pagination-bar"
                currentPage={currentPage}
                totalCount={userListData.count}
                pageSize={PageSize}
                handlePaginationClick={handlePaginationClick}
                showNoOfRecords={true}
              />
            </>) : null}
          </CardBody>
        </Card>
      </div>
      <PopUpModal
        width={451}
        title={popUpValue === "Add" ? "Add New User" : "Edit " + userData.name}
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
      >
        <UserForm
          openPopUp={openPopUp}
          setOpenPopUp={setOpenPopUp}
          popUpValue={popUpValue}
          userData={userData}
        />
      </PopUpModal>
      <DeactivatePopUpModal
        deactivateOpenPopUp={deactivateOpenPopUp}
        setDeactivateOpenPopUp={setDeactivateOpenPopUp}
        deactivateUserId={deactivateUserId}
        deactivateUserName={deactivateUserName}
      />
    </React.Fragment>
  );
};

export default Users;
