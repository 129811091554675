import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "./index.scss";
import RBAC from '../../../RBAC';
import { PERMISSIONS } from '../../../../utils/permissionMatrix';

const ActionDropdown = ({ data, getUniversalTagRequest, setCloneRtrAdParam, setDeleteRtrAdParam, editSurvey }) => {
    const statusIcons = {
        STATUS_CHANGE: "/Icon_assets/svg/three_dots_icon.svg",
        STATUS_CHANGE_ACTIVE: "/Icon_assets/svg/three_dots_active_icon.svg",
    }

    const [statusChangeImage, setStatusChangeImage] = useState(statusIcons.STATUS_CHANGE);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="organization-actions-dropdown">
            <DropdownToggle onMouseEnter={() => { setStatusChangeImage(statusIcons.STATUS_CHANGE_ACTIVE) }}
                onMouseLeave={() => { setStatusChangeImage(statusIcons.STATUS_CHANGE) }}>
                <img
                    src={statusChangeImage}
                    alt="settings"
                    className="dropdown-image"
                />
            </DropdownToggle>
            <DropdownMenu>
                    <RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_WRITE}>
                      <DropdownItem onClick={() => { setCloneRtrAdParam(data) }}>
                        <img
                          src="/Icon_assets/svg/clone-icon.svg"
                          alt="clone"
                          className="dropdown-image"
                        />
                        <span>Clone</span>
                      </DropdownItem>
                    </RBAC>
                {
                    (data.ad_state === 0 || data.ad_state === 11 || (data.ad_state === 6)) &&
                    (<RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_DELETE}>
                        <DropdownItem onClick={() => { setDeleteRtrAdParam(data) }}>
                            <img
                                src="/Icon_assets/svg/delete-icon.svg"
                                alt="delete"
                                className="dropdown-image"
                            />
                            <span>Delete</span>
                        </DropdownItem>
                    </RBAC>
                    )
                }

                {
                    (((data.rtr_type === 0 || data.rtr_type === 2) && (data.ad_state === 0 || data.ad_state === 4 || data.ad_state === 7))
                        || (data.rtr_type === 1 && data.ad_state === 0))
                    && (<RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_WRITE}>
                        <DropdownItem onClick={() => { editSurvey(data) }}>
                            <img
                                src="/Icon_assets/svg/edit_icon.svg"
                                alt="edit"
                                className="dropdown-image"
                            />
                            <span>Edit</span>
                        </DropdownItem>
                    </RBAC>)
                }
                {
                    (data.ad_state === 4 && data.rtr_type !== 0) && (
                        <RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_WRITE}>
                            <DropdownItem onClick={() => { getUniversalTagRequest(data.id) }}>
                                <img
                                    src="/Icon_assets/svg/export-tag.svg"
                                    alt="export tag"
                                    className="dropdown-image"
                                />
                                <span>Export Tag</span>
                            </DropdownItem></RBAC>)
                }
            </DropdownMenu>
        </Dropdown>
    )
}

export default ActionDropdown;