import React from 'react';
import './index.scss';

const ADspinner = ({size = 'small'}) => {
  const icons = {
    LOADING : '/Icon_assets/svg/loading_icon.svg',
  }

	return (
		<img 
      src={icons.LOADING} 
      alt="Loading..." 
      className={`ad-spinner ad-spinner-${size === 'small' ? 'sm' : 'lg' }`} 
    />
	);
}

export default ADspinner;