import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const DiscardChange = ({ yesClicked, cancelRemoveUserModal }) => {
  return (
    <section className="remove-user-content">
      <nav className="remove-user-section">
        <p> Unsaved changes to the user will be discarded.</p>
      </nav>
      <nav className="btn-wrapper remove-user">
        <Button className="in-btn-sm in-btn-primary" onClick={yesClicked}>
          Discard without saving
        </Button>
        <Button color="link" className="in-btn-link" onClick={cancelRemoveUserModal}>
          Back to edit
        </Button>
      </nav>
    </section>
  )
}

export default DiscardChange;