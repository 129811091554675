import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import Loader from "../../Loader";
import { useLocation } from 'react-router-dom';
import {
  Card, Button, Form,
} from 'reactstrap';
import "./index.scss";
import ConversionRulesTable from './ConversionRulesTable';
import PixelCode from '../PixelCode';
// import { conversionActionCreator } from '../../../actions';
import RBAC from "../../../components/RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";

const ConversionRuleList = (props) => {

  const dispatch = useDispatch();

  const advertiser = localStorage.getItem('selectedConversionAd') ?
    JSON.parse(localStorage.getItem('selectedConversionAd')).name : "";

  const [pixelId, setPixelId] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [source, setSource] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [count, setCount] = useState(0);

  const backToConversion = (e) => {
    e.preventDefault();
    history.push(ROUTES.CONVERSION);
  }

  const closePixelModal = () => {
    setOpenPopUp(false);
  }

  const goToCoversionBuilder = (e) => {
    e.preventDefault();
    history.push(ROUTES.CONVERSION_REPORT_BUILDER);
  }

  const openRuleDetails = (data) => {
    console.log(data);
    data.isDetailsPage = true;
    history.push({
      pathname:
        ROUTES.CREATE_CONVERSION_RULE,
      state: data
    });
  }

  const openPixelModal = (data, source) => {
    setOpenPopUp(true);
    var evt_source = data.rule_type === 0 ? "Event" : "URL";
    setSource(evt_source);
    setSourceType(data.event_type);
    setPixelId(data.id);
  }

  return (<>
    <section className="conversion-list">
      <nav className="left-nav-section">
        <Button className="btn-transparent pl-5 pb-3" style={{ verticalAlign: "super" }} onClick={backToConversion}>
          <img src="/Icon_assets/svg/back_icon.svg" alt="go to back" className="go-back" />
        </Button>
        <h2 style={{ display: "inline-block", paddingLeft: "24px" }}>{`Advertiser: ${advertiser}`}</h2>
      </nav>
      <nav>
        <Button
          className="in-btn-sm in-btn-primary add-new-organization-btn"
          onClick={goToCoversionBuilder}
        >Conversion Report Builder</Button>
      </nav>
    </section>
    <Form className="form-container conversion-form">
      <Card className="data-card">
        <section id="header-section">
          <nav className="left-nav-section">
            <h2>Rules List</h2>
          </nav>
          {<RBAC requiredPermissions={PERMISSIONS.BUILD_CONVERSION_WRITE}>
            <nav className="right-nav-section">
              <nav className="pixel-section">
                <Button
                  color="link"
                  className={"conversion-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setSource("header");
                    setOpenPopUp(true);
                    // history.push(ROUTES.GET_PIXEL_CODE);
                  }}
                >Get Pixel Code</Button>
              </nav>

              <nav className="filter-section">
                <Button
                  color="link"
                  className={"conversion-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(ROUTES.CREATE_CONVERSION_RULE);
                  }}
                >Create New Rule</Button>
              </nav>
            </nav>
          </RBAC>
          }
        </section>
        <ConversionRulesTable openRuleDetails={openRuleDetails}
          openPixelModal={openPixelModal} />
      </Card>
    </Form>
    {
      <PixelCode openPopUp={openPopUp}
        source={source}
        sourceType={sourceType}
        pixelId={pixelId}
        closePixelModal={closePixelModal} />
    }
  </>);

}

export default ConversionRuleList;