import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { scheduleReportActionCreator } from "../../../../actions";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import STATES from '../../../../utils/scheduledReportStates';
import './index.scss'

const StatusDropdown = ({ data, refreshTable }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const dispatch = useDispatch();

    const prepareAllowedTransitions = (currentState) => {
        const allowedTransitionsStates = STATES[currentState].transaction_state;
        let transitions = []
        allowedTransitionsStates.map((transaction_state) => {
            let item = <DropdownItem onClick={() => { updateAdState(transaction_state) }} key={transaction_state} className={(STATES[transaction_state].state_label).toLocaleLowerCase()}>{STATES[transaction_state].state_label}</DropdownItem>;
            transitions.push(item);
        });
        return transitions;
    }

    const updateAdState = (transaction_state) => {
        dispatch(scheduleReportActionCreator.saveScheduleReportRequest({
            report_schedule: {
                state: transaction_state
            },
            id: data.id,
            method: 'PUT'
        }));
        setTimeout(() => {
            refreshTable()
        }, 2000)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={(STATES[data.state].state_label).toLocaleLowerCase()} >
            <DropdownToggle className={"d-flex"}>
                <span className="state-dot"></span>
                {STATES[data.state].state_label}
            </DropdownToggle>
            <DropdownMenu>

                {prepareAllowedTransitions(data.state)}

            </DropdownMenu>
        </Dropdown >
    )
}

export default StatusDropdown