import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import { Modal, ModalBody, ModalHeader, Button, Input } from 'reactstrap';
import { conversionActionCreator } from '../../../actions';
import Highlight from 'react-highlight';

const PixelCode = (props) => {
  const dispatch = useDispatch();
  const pixel = useSelector((state) => state.conversion.pixel);

  const EVENTS = {
    'Subscribe': 'SUBSCB',
    'Register': 'REGIST',
    'Purchase': 'PRCHS',
    'AddToCart': 'ADCRT',
    'AddPaymentInfo': 'ADPAY',
    'AddToWishlist': 'ADWHT',
    'InitiateCheckout': 'CHEKOT',
    'Lead': 'LEAD',
    'Search': 'SRCH'
  }
  const [pixelCode, setPixelCode] = useState('');
  const { openPopUp,
    closePixelModal,
    source, sourceType, pixelId } = props;

  const closeModal = () => {
    closePixelModal();
    dispatch(conversionActionCreator.getPixelCodeRequestClear());
  }

  const copyPixelCode = () => {
    var copyText = document.getElementById("pixel-code");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

  }

  const copyEventCode = () => {
    var copyText = document.getElementById("event-code");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const closeBtn = (
    <button className="close" onClick={closeModal}>
      <img src="/Icon_assets/svg/close_icon_black.svg" alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>
  );

  useEffect(() => {
    if (openPopUp) {
      // call api for getting pixels code
      const param = {};
      param.brandId = localStorage.getItem('selectedConversionAd') ?
        JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
      dispatch(conversionActionCreator.getPixelCodeRequest(param));
    }
  }, [openPopUp]);

  useEffect(() => {
    if (pixel) {
      setPixelCode(pixel.pixel);
    }

  }, [pixel]);


  // https://cd-qa-api.pymx6.com/v1/brands/ae9ae5e2-a6bd-49cf-9eac-4ecb49755627/pixel

  return (<>
    <div>
      <Modal isOpen={openPopUp} className="pixel-modal" centered size='lg'>
        <ModalHeader close={closeBtn} class="pixel-header">Get Pixel Code</ModalHeader>
        <ModalBody>
          <div className="content-body">
            <div className="step1" style={{ marginBottom: '32px' }}>
              <div className="info-header">Step 1: Locate the header code for your website</div>
              <div className="content">
                <span>{`Find the </head> tags in your webpage code, or locate the header template in your CMS or web platform.`}</span>
              </div>
            </div>
            <div className="step2" style={{ marginBottom: '32px' }}>
              <div className="info-header">Step 2: Copy the entire pixel code and paste it in the website header</div>
              <div className="content">
                <span>{`Copy and paste the below code at the bottom of the header section, just above the <head> tag. The Invisibly pixel code can be added above or below existing tags (such as Google Analytics) in your site header.`}</span>
              </div>
              <div className="code-div">
                <Input id="pixel-code"
                  style={{ opacity: 0, height: "0px" }}
                  value={`<!-- Invisibly Conversion Pixel Code -->\n` + pixelCode}
                  readOnly />
                <Highlight language='html'>
                  {`<!-- Invisibly Conversion Pixel Code -->\n` + pixelCode}
                </Highlight>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="in-btn-sm in-btn-primary add-new-organization-btn"
                  onClick={copyPixelCode}
                > Copy Pixel Code</Button>
              </div>
            </div>

            {source === 'Event' &&
              <div className="step3">
                <div className="info-header">Step 3: Event Code</div>
                <div className="content">
                  <span>{`For generating the custom events from the landing page, use the code snippet given below.`}</span>
                  <div>This method is available across the page after the pixel is installed. Please integrate this method on the user action of your website as needed.</div>
                </div>
                <div className="code-div">
                  <Input id="event-code"
                    style={{ opacity: 0, height: "0px" }}
                    value={`window.ia.sendEvent('` + sourceType + `', {pixelId: '` + pixelId + `'});`}
                    readOnly />
                  <Highlight language='html'>
                    {`window.ia.sendEvent('` + sourceType + `', {pixelId: '` + pixelId + `'});`}
                  </Highlight>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="in-btn-sm in-btn-primary add-new-organization-btn"
                    onClick={copyEventCode}
                  > Copy Event Code</Button>
                </div>
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </div>
  </>)
}

export default PixelCode;