import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.scss";
import configStore from "./store/configureStore";
import { appActionCreator } from "./actions";
import { getConfig } from "./utils/config";
import registerServiceWorker from "./registerServiceWorker";
import reportWebVitals from "./reportWebVitals";

import App from "./containers/App";

// This is common redux store
const store = configStore();

//get the API URL and save it into a global 'CONFIG' object
let configFilePath = "/config.json";

if (process.env.NODE_ENV === "development") {
  configFilePath = "/config.dev.json";
}

getConfig((res) => store.dispatch(appActionCreator.configLoaded(res.data)))
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
    registerServiceWorker();
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  })
  .catch((err) => console.log("Axios config file err: ", err));
