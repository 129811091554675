import { takeLatest, call, select, put } from "redux-saga/effects";
import { conversionActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function rulesListRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = "";
  let encodeString = "";
  const params = {};
  params.source = 1;
  params.limit = 100;

  if (payload.limit) {
    params.limit = payload.limit;
    params.offset = payload.offset;
  }

  if (payload?.searchKey) {
    encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    params.search = encodeString;
  }

  API_URL = (payload.brandId) ?
  API_URL_PARAM + `/v1/brands/${payload.brandId}/conversion-rule` :
  API_URL_PARAM + `/v1/conversion-rules`;

  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* rulesListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      rulesListRequest,
      API_URL,
      jwtToken,
      data
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(conversionActionCreator.rulesListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(conversionActionCreator.rulesListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(conversionActionCreator.rulesListFailure(errorMsg));
      }
    }
  } catch (error) {
    // yield put(conversionActionCreator.rulesListSuccess(rules_list));
    yield put(conversionActionCreator.rulesListFailure("Server Error"));
  }
}


function createRuleRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = API_URL_PARAM + `/v1/brands/${payload.brandId}/conversion-rule`;

  return axios({
    url: API_URL,
    method: "POST",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* createRuleRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      createRuleRequest,
      API_URL,
      jwtToken,
      data
    );
    const { item, status } = result.data;
    if (status.status_type.toLowerCase() === "SUCCESS".toLowerCase()) {
      yield put(conversionActionCreator.createRuleSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(conversionActionCreator.createRuleFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          }));
          yield put(conversionActionCreator.clearCreateRuleResponse());
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(conversionActionCreator.createRuleFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
        yield put(conversionActionCreator.clearCreateRuleResponse());
      }
    }
  } catch (error) {
    yield put(conversionActionCreator.createRuleFailure("Server Error"));
  }
}

function getPixelRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = API_URL_PARAM + `/v1/brands/${payload.brandId}/pixel`;

  return axios({
    url: API_URL,
    method: "GET",
    data: payload,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* getPixelRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getPixelRequest,
      API_URL,
      jwtToken,
      data
    );
    const { item, status } = result.data;
    if (status.status_type.toLowerCase() === "SUCCESS".toLowerCase()) {
      yield put(conversionActionCreator.getPixelCodeSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${JSON.stringify(err[1].toString())}`;
          else return ` ${err[1].toString()}`;
        });
        yield put(conversionActionCreator.getPixelCodeFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          }));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(conversionActionCreator.getPixelCodeFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(conversionActionCreator.getPixelCodeFailure("Server Error"));
  }

}
export default function* conversionSaga() {
  yield takeLatest(ACTIONS.RULES_LIST_REQUEST, rulesListRequestSaga);
  yield takeLatest(ACTIONS.CREATE_RULE_REQUEST, createRuleRequestSaga);
  yield takeLatest(ACTIONS.GET_PIXEL_CODE_REQUEST, getPixelRequestSaga);
}
