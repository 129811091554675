import { PERMISSION_POINTS, PERMISSION_ACTIONS } from "./rbacConstants";


export const PERMISSIONS = {
  OFFER_WRITE: [{
    'permission_point': PERMISSION_POINTS.BUILD_OFFER,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  OFFER_READ: [{
    'permission_point': PERMISSION_POINTS.BUILD_OFFER,
    'access': [PERMISSION_ACTIONS.READ]
  }],
  OFFER_DELETE: [{
    'permission_point': PERMISSION_POINTS.BUILD_OFFER,
    'access': [PERMISSION_ACTIONS.DELETE]
  }],
  OFFER_CLONE: [{
    'permission_point': PERMISSION_POINTS.BUILD_OFFER,
    'access': [PERMISSION_ACTIONS.CLONE]
  }],
  OFFER_WRITE_CLONE_DELETE: [{
    'permission_point': PERMISSION_POINTS.BUILD_OFFER,
    'access': [PERMISSION_ACTIONS.WRITE, PERMISSION_ACTIONS.DELETE, PERMISSION_ACTIONS.CLONE]
  }],
  MEDIA_REPORT_BUILDER_READ: [{
    'permission_point': PERMISSION_POINTS.BUILD_REPORT,
    'access': [PERMISSION_ACTIONS.READ]
  }],
  MEDIA_REPORT_BUILDER_WRITE: [{
    'permission_point': PERMISSION_POINTS.BUILD_REPORT,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  MEDIA_REPORT_BUILDER_DELETE: [{
    'permission_point': PERMISSION_POINTS.BUILD_REPORT,
    'access': [PERMISSION_ACTIONS.DELETE]
  }],

  BUILD_RTR_WRITE: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  BUILD_RTR_DELETE: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.DELETE]
  }],
  BUILD_RTR_CLONE: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.CLONE]
  }],
  BUILD_RTR_ACTIVATE: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  BUILD_RTR_READ: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.READ]
  }],
  RTR_WRITE_CLONE_DELETE: [{
    'permission_point': PERMISSION_POINTS.BUILD_RTR,
    'access': [PERMISSION_ACTIONS.WRITE, PERMISSION_ACTIONS.DELETE, PERMISSION_ACTIONS.CLONE]
  }],
  BUILD_USER_WRITE:[{
  'permission_point': PERMISSION_POINTS.MANAGE_USER,
  'access':[PERMISSION_ACTIONS.WRITE]
  }],
  MANAGE_USER_READ:[{
    'permission_point': PERMISSION_POINTS.MANAGE_USER,
    'access':[PERMISSION_ACTIONS.READ]
  }],
  MANAGE_USER_DEACTIVATE:[{
    'permission_point': PERMISSION_POINTS.MANAGE_USER,
    'access':[PERMISSION_ACTIONS.DEACTIVATE]
  }],
  BUILD_CONVERSION_WRITE: [{
    'permission_point': PERMISSION_POINTS.BUILD_CONVERSION_RULE,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  BUILD_CONVERSION_READ: [{
    'permission_point': PERMISSION_POINTS.BUILD_CONVERSION_RULE,
    'access': [PERMISSION_ACTIONS.READ]
  }],
  MANAGE_BRAND_WRITE: [{
    'permission_point': PERMISSION_POINTS.MANAGE_BRAND,
    'access': [PERMISSION_ACTIONS.WRITE]
  }],
  MANAGE_BRAND_READ: [{
    'permission_point': PERMISSION_POINTS.MANAGE_BRAND,
    'access': [PERMISSION_ACTIONS.READ]
  }]
}