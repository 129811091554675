import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../utils/routeConstants";
import history from "../../history";
import {
  Card, Button,
  Table,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  UncontrolledTooltip,
  CustomInput
} from 'reactstrap';
import { notificationActionCreator, offerActionCreator, organizationActionCreator, brandsActionCreator } from "../../actions";
import OffersOptions from "./OffersOptions";
import DeleteOffer from './DeleteOffer/index';
import ADPagination from "../ADPagination";
import PopUpModal from "../PopUpModal";
import { format } from 'date-fns';
import SkeletonTable from "../Loader_Skeleton/SkeletonTable";
import OFFER_STATES from '../../utils/offerStates'
import "./index.scss";
import './StatusDropdown/index.scss'
import StatusDropdown from './StatusDropdown';
import CloneOffer from "./CloneOffer";
import { useDidMountEffect } from '../../utils/customHooks'
import Loader from '../Loader';
import RBAC from '../RBAC'
import { PERMISSIONS } from '../../utils/permissionMatrix'
import checkRBAC from '../../utils/checkRBAC';

const OfferList = () => {
  const TABLE_COLS = [
    {
      dataField: "offer name",
      text: "Offer Name",
      sortName: "offer_name",
      className: "col-title"
    },
    {
      dataField: "title",
      text: "Offer Title",
      sortName: "title",
      className: "col-title"
    },
    {
      dataField: "org_name",
      text: "Organization",
      sortName: "brand__brand_organizations__organization__name",
      className: "col-title"
    },
    {
      dataField: "brand_name",
      text: "Advertiser",
      sortName: "brand__name",
      className: "col-title"
    },
    {
      dataField: "status",
      text: "Status",
      sortName: "state",
      className: "col-title"
    },
    {
      dataField: "updated_at",
      sortName: "updated_at",
      text: "Updated at",
      className: "col-title"
    },
    {
      dataField: "actions",
      text: "Actions",
      sortName: "",
      className: "col-hd-actions"
    },
  ];

  // const OFFER_TYPE = {
  //   1: "Offer",
  //   2: "RTR"
  // };

  let PageSize = 10;

  const ICONS = {
    SEARCH: "/Icon_assets/svg/search.svg",
    SEARCH_ACTIVE: "/Icon_assets/svg/search-active.svg",
    DESC_SORT_SELECTED: "/Icon_assets/svg/desc_sort_selected.svg",
    DESC: "/Icon_assets/svg/desc_sort.svg",
    ASC_SORT_SELECTED: "/Icon_assets/svg/asc_sort_selected.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
  }

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const offerList = useSelector((state) => state.offer.offerData?.results);
  const totalCount = useSelector((state) => state.offer.offerData?.count);
  const isLoading = useSelector((state) => state.offer.isLoading);
  const error = useSelector((state) => state.offer.errorMsg);
  const isAdvertiserRequest = useSelector((state) => state.brands.isLoading);
  const isOrganizationRequest = useSelector((state) => state.organization.isLoading);
  const offerDeleteResponseData = useSelector((state) => state.offer.offerDeleteResponseData);
  const offerCloneResponseData = useSelector((state) => state.offer.offerCloneResponseData);
  const organizationData = useSelector((state) => state.organization.organizationData?.results);
  const advertiserData = useSelector((state) => state.brands.data?.results);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const userRbac = useSelector(state => state.auth.rbac);

  const [searchText, setSearchText] = useState('');
  const [searchImage, setSearchImage] = useState(ICONS.SEARCH)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOffset, setTotalOffset] = useState(0);
  const [isPagination, setIsPagination] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [currentOffer, setCurrentOffer] = useState({});
  const [selectedItemId, setSelectedItemId] = useState();
  const [openCloneOfferModal, setOpenCloneOfferModal] = useState(false);
  const [sortType, setSortType] = useState('desc');
  const [sortColumn, setSortColumn] = useState('updated_at');
  const [sorted, setSorted] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [advDropdownOpen, setAdvDropdownOpen] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  const [searchAdv, setSearchAdv] = useState(null);
  const [searchOrg, setSearchOrg] = useState(null);

  const [selectedAdv, setSelectedAdv] = useState([]);

  const toggle = () => setDropdownOpen(!dropdownOpen)
  const toggleAdv = () => {
    if (advDropdownOpen) {
      dispatch(brandsActionCreator.brandsListFailureClear());
    }
    setAdvDropdownOpen(!advDropdownOpen)
  }

  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

  const goToOfferCreation = (e) => {
    e.preventDefault();
    history.push(ROUTES.CREATE_OFFERS);
    dispatch(offerActionCreator.createBrandOfferFailureClear());
  }

  const goToPreview = (e, id, brand_id, ad_id, message, isPDP, offer_name) => {
    e.preventDefault();
    let messageData = {}
    messageData.message = message;
    messageData.offer_name = offer_name;
    localStorage.setItem("brand_id", brand_id);
    localStorage.setItem("id", id);
    if (isPDP === 2) {
      localStorage.setItem("ad_id", ad_id);
    }
    history.push({
      pathname: isPDP === 2 ?
        `${ROUTES.OFFERS_LIST}/preview/${id}/${brand_id}/${ad_id}` :
        `${ROUTES.OFFERS_LIST}/offer-preview/${id}/${brand_id}`,
      state: { detail: messageData }
    });
    dispatch(offerActionCreator.getOfferListFailureClear());
  }

  const onEditOffer = (e, id, data) => {
    e.preventDefault();
    localStorage.setItem("brand_id", data.brand);
    localStorage.setItem("id", id);
    if (data.type === 2) {
      localStorage.setItem("ad_id", data.ad);
    }
    dispatch(offerActionCreator.getOfferListFailureClear());
    history.push({
      pathname: `/offers/edit-offer/${id}`,
      state: {
        detail: {
          isEdit: true,
          offerData: data,
          offerId: id,
          offer_name: data.offer_name
        }
      }
    });

  }

  useEffect(() => {
    if (offerDeleteResponseData) {
      dispatch(notificationActionCreator.setNotification({
        color: "success",
        message: "Deleted Successfully"
      }));
      dispatch(offerActionCreator.deleteOfferClear());
      handlePaginationClick(1)
    }
    if (offerCloneResponseData) {
      dispatch(notificationActionCreator.setNotification({
        color: "success",
        message: offerCloneResponseData.offer_name + " offer was cloned successfully."
      }));
      dispatch(offerActionCreator.cloneOfferClear());
      handlePaginationClick(1)
    }
    if (error) {
      dispatch(notificationActionCreator.setNotification({
        color: "warning",
        message: error
      }));
      dispatch(offerActionCreator.deleteOfferClear());
    }
  }, [error || offerDeleteResponseData || offerCloneResponseData])

  const handlePaginationClick = (pageIndex, search) => {
    let params = {
      filters: {
        organization_id: selectedOrganizations.length !== 0 ? selectedOrganizations.join() : '',
        brand_id: selectedAdv.length !== 0 ? selectedAdv.join() : ''
      }
    }
    if (search !== '' || searchText !== '') {
      setSearchText(search)
      params.searchKey = search
    }
    setCurrentPage(pageIndex);
    params.pageIndex = pageIndex;
    params.limit = PageSize;
    params.offset = (pageIndex - 1) * 10;
    params.ordering = sortColumn;
    params.sortBy = sortType;
    setTotalOffset(totalOffset + 10);
    params.organization = JSON.parse(localStorage.getItem('selectedOrganization'))?.id ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganizations.length !== 0 ? selectedOrganizations.join() : ''
    dispatch(offerActionCreator.getOfferListRequest(params));
  }

  const sortColumnData = (colName, sortOrder) => {
    // to do : API to confirm value for sortOrder and col name.
    setSortColumn(colName);
    setSortType(sortOrder);
    setSorted(true)
  }

  const handleOfferDelete = (id) => {
    dispatch(offerActionCreator.deleteOfferRequest(id));
    setSelectedItemId(undefined);
    showDeleteModal(false)
  }

  const handleCloneOffer = (offer_id) => {
    dispatch(offerActionCreator.cloneOfferRequest(offer_id));
    setOpenCloneOfferModal(false);
  }


  useEffect(() => {
    var params = {
      limit: 10,
      ordering: sortColumn,
      sortBy: sortType,
      organization: JSON.parse(localStorage.getItem('selectedOrganization'))?.id ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganizations.length !== 0 ? selectedOrganizations.join() : ''
    };
    dispatch(offerActionCreator.getOfferListRequest(params));
  }, []);

  useEffect(() => {
    if (sorted)
      refreshTable()
  }, [sortType, sortColumn]);

  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("offer-table");

    // For resizing column, add dummy div to header, add event when clicked
    if (table) {
      var tableHeight = table.offsetHeight;
      Array.prototype.forEach.call(
        table.querySelectorAll(".resized-column"),

        function (th) {
          //th.style.position = 'relative';

          var grip = document.createElement('div');
          grip.className = 'column-resizer';
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = '5px';
          grip.style.height = tableHeight + 'px';
          grip.style.position = 'absolute';
          grip.style.zIndex = "1";
          grip.style.cursor = 'col-resize';
          grip.addEventListener('mousedown', function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });

          th.appendChild(grip);
        });

      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            thElm.style.width = startOffset + e.pageX + 'px';
            thElm.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  })
  /* Resize column setup for table-main end*/

  useEffect(() => {
    if (offerList && offerList.length) {
      //if tableData have more than 1 records show Pagination
      setIsPagination(true);
      if (offerList.next === null) {
        //if tableData have only 1 page set current page to 1
        const checkPageCount = Math.ceil(offerList.count / PageSize);
        setCurrentPage(checkPageCount);
      }
    } else {
      setIsPagination(false);
    }
  }, [offerList])

  const getOrganizationDropdownOptions = (isSearch = false) => {
    var param = {}

    if (searchOrg !== null) {
      param.search = searchOrg;
    }
    if (organizationData === undefined || isSearch)
      dispatch(organizationActionCreator.getOrganizationListRequest(param));
  };

  const getAdvOptions = (isOrgChanged = false, isSearch = false) => {
    console.log('getAdvOptions')
    const params = {};
    params.organization_id = localStorage.getItem('selectedOrganization') ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganizations.join();

    if (isSearch) {
      params.searchKey = searchAdv;
    }
    if (advertiserData === undefined || isOrgChanged || isSearch)
      dispatch(brandsActionCreator.brandsListRequest(params));
  }

  const refreshTable = () => {
    setTimeout(() => {
      let params = {
        limit: 10,
        ordering: sortColumn,
        sortBy: sortType,
        filters: {
          organization_id: JSON.parse(localStorage.getItem('selectedOrganization'))?.id ? JSON.parse(localStorage.getItem('selectedOrganization'))?.id : selectedOrganizations.length !== 0 ? selectedOrganizations.join() : '',
          brand_id: selectedAdv.length !== 0 ? selectedAdv.join() : ''
        }
      };

      window.scrollTo(0, 0);
      setCurrentPage(0);
      dispatch(offerActionCreator.getOfferListRequest(params));
    }, 1000)
  }

  useDidMountEffect(() => {
    refreshTable()
  }, [selectedOrganizations, selectedAdv])

  useDidMountEffect(() => {
    getAdvOptions(undefined, true)
  }, [searchAdv])

  useDidMountEffect(() => {
    getOrganizationDropdownOptions(true)
  }, [searchOrg])

  useDidMountEffect(() => {
    getAdvOptions(true)
  }, [selectedOrganizations])

  const selectOrgClick = (e) => {
    if (selectedOrganizations.indexOf(e.target.value) === -1)
      setSelectedOrganizations((prev) => [...prev, e.target.value])
    else
      setSelectedOrganizations((prev) => prev.filter((item) => item !== e.target.value))

    setSelectedAdv([])
  }

  const selectAdvClick = (e) => {
    console.log(e.target.value)
    if (selectedAdv.indexOf(e.target.value) === -1)
      setSelectedAdv((prev) => [...prev, e.target.value])
    else
      setSelectedAdv((prev) => prev.filter((item) => item !== e.target.value))
  }

  const clearFilter = () => {
    if (selectedAdv.length > 0 || selectedOrganizations.length > 0) {
      refreshTable()
      setSelectedAdv([])
      setSelectedOrganizations([]);
    }
  }

  useEffect(() => {
    var table = document.getElementById("organizationFilterDropDown");

    if (dropdownOpen) {
      table = document.getElementById("organizationFilterDropDown");
      console.log(table)
    }
    if (advDropdownOpen) {
      table = document.getElementById("advFilterDropDown");
    }

    var resizer = document.getElementById("resizer-fil-col")
    if (dropdownOpen || advDropdownOpen) {
      Array.prototype.forEach.call(
        table.querySelectorAll(".resized-column"),
        function (th) {
          //th.style.position = 'relative';
          if (document.getElementById('resizerdrop') === null) {
            var grip = document.createElement('div');
            grip.className = 'column-resizer';
            grip.id = 'resizerdrop';
            grip.innerHTML = "&nbsp;&nbsp;";
            grip.style.left = `${expandedTableColWidth - 20}px`;
            // grip.style.bottom = 0;
            grip.style.width = '5px';
            grip.style.position = 'sticky';
            grip.style.top = '0px';
            grip.style.zIndex = "9999";
            grip.style.cursor = 'col-resize';
            grip.style.float = 'right';

            grip.addEventListener('mousedown', function (e) {
              thElm = th;
              startOffset = th.offsetWidth - e.pageX;
            });

            th.appendChild(grip);
          } else {
            var t = document.getElementById('resizerdrop');
            t.style.left = `${expandedTableColWidth - 20}px`;
          }
        });
      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            // resizer.style.width = startOffset + e.pageX + 'px';
            // resizer.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  }, [dropdownOpen, expandedTableColWidth,
    advDropdownOpen])

  return (
    <div className='right-container'>
      <div className="offers-list dashboard">
        <section className="title-section dashboard-header-section">
          <h2> Invisibly Offers</h2>
          <div style={{ display: 'flex' }}>
            <section>
              <div className="filter-nav">
                <nav className="right-nav-section">
                  {userData.organization_role === 0 && <nav className="advertiser-list-dropdown-section">
                    <Dropdown id="organizationFilterDropDown" isOpen={dropdownOpen} toggle={toggle} onClick={() => { getOrganizationDropdownOptions() }}
                      disabled={(Object.keys(selectedOrganization).length > 0)}
                    >
                      <DropdownToggle>
                        {selectedOrganizations.length > 0 && <Badge color="primary" >{selectedOrganizations.length}</Badge>}
                        Organizations
                        <img
                          alt='arrow-down'
                          src={
                            dropdownOpen
                              ? ICONS.ARROW_UP
                              : ICONS.ARROW_DOWN
                          }
                          className="arrow-down"
                        />
                      </DropdownToggle>
                      {
                        <DropdownMenu toggle={false} style={{ width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px` }}>
                          <DropdownItem toggle={false}>
                            <FormGroup>
                              <Input
                                value={searchOrg}
                                type="text"
                                name="search"
                                id="searchOrg"
                                placeholder="Search..."
                                className="search-input"
                                autoComplete="off"
                                onChange={(event) => { setSearchOrg(event.target.value) }}
                              />
                              <img
                                src="/Icon_assets/svg/search-organization-icon.svg"
                                alt="Search"
                                className="search-image"
                              /></FormGroup>
                          </DropdownItem>

                          <div className='metrics-list'>
                            {
                              organizationData?.length > 0 ?
                                organizationData?.map((item) => <DropdownItem toggle={false} >
                                  <CustomInput
                                    key={"organization-" + item.id}
                                    className="mt-3"
                                    type="checkbox"
                                    name={item.name}
                                    value={item.id}
                                    id={"organization-" + item.id}
                                    label={item.name}
                                    checked={selectedOrganizations.indexOf(item.id) > -1}
                                    onChange={(e) => selectOrgClick(e)}
                                  />
                                </DropdownItem>) : isOrganizationRequest ? <Loader /> : <div>No Organization found</div>}
                          </div>
                          <div className="resizer-icon" id="resizer-fil-col">
                            <span className="first"></span>
                            <span className="second"></span>
                          </div>
                        </DropdownMenu>}
                    </Dropdown>
                    {(Object.keys(selectedOrganization).length > 0) && (
                      <UncontrolledTooltip
                        className="ad-tool-tip custom-selectedOrganization"
                        placement="top"
                        target={"organizationFilterDropDown"}
                      >{Object.keys(selectedOrganization).length ?
                        (<>
                          <span className="d-block">Organization view is already selected</span>
                        </>) :
                        ""}
                      </UncontrolledTooltip>
                    )}
                  </nav>}

                  <nav className="advertiser-list-dropdown-section">
                    <Dropdown id="advFilterDropDown" isOpen={advDropdownOpen} toggle={toggleAdv} onClick={() => { getAdvOptions() }}>
                      <DropdownToggle>
                        {selectedAdv.length > 0 && <Badge color="primary" >{selectedAdv.length}</Badge>}
                        Advertiser
                        <img
                          alt='arrow-down'
                          src={
                            advDropdownOpen
                              ? ICONS.ARROW_UP
                              : ICONS.ARROW_DOWN
                          }
                          className="arrow-down"
                        />
                      </DropdownToggle>
                      {
                        <DropdownMenu toggle={false} style={{ width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px` }}>
                          <DropdownItem toggle={false}>
                            <FormGroup>
                              <Input
                                value={searchAdv}
                                type="text"
                                name="search"
                                id="searchAdv"
                                placeholder="Search..."
                                className="search-input"
                                autoComplete="off"
                                onChange={(event) => { setSearchAdv(event.target.value) }}
                              />
                              <img
                                src="/Icon_assets/svg/search-organization-icon.svg"
                                alt="Search"
                                className="search-image"
                              /></FormGroup>
                          </DropdownItem>

                          <div className='metrics-list'>
                            {
                              advertiserData?.length > 0 ?
                                advertiserData?.map((item) => <DropdownItem toggle={false} >
                                  <CustomInput
                                    key={"adv-" + item.id}
                                    className="mt-3"
                                    type="checkbox"
                                    name={item.name}
                                    value={item.id}
                                    id={"adv-" + item.id}
                                    label={item.name}
                                    checked={selectedAdv.indexOf(item.id) > -1}
                                    onChange={(e) => selectAdvClick(e)}
                                  />
                                </DropdownItem>) : isAdvertiserRequest ? <Loader /> : <div>No Advertiser found</div>}
                          </div>

                          <div className="resizer-icon" id="resizer-col">
                            <span className="first"></span>
                            <span className="second"></span>
                          </div>
                        </DropdownMenu>}
                    </Dropdown>
                  </nav>
                  <nav>
                    <img src={ICONS.SEPERATOR} />
                  </nav>
                  <nav className="clear-filter-section">
                    <a
                      href="#"
                      className={(selectedAdv.length > 0 || selectedOrganizations.length > 0) ? "clear-active-filter-link" : "clear-filter-link"}
                      onClick={() => clearFilter()}
                    >
                      Clear
                    </a>
                  </nav>
                </nav>
              </div>
            </section>
            <RBAC requiredPermissions={PERMISSIONS.OFFER_WRITE}>
              <Button
                // disabled={true}
                className="in-btn-sm in-btn-primary add-new-organization-btn"
                onClick={goToOfferCreation}
              >Build a new Offer</Button>
            </RBAC>
          </div>
        </section>
        <Card className={"data-card offer-list-section"}>
          <CardBody>
            <div>
              <span style={{
                fontSize: "22px",
                color: "rgb(41, 45, 50)",
                display: "inline-block",
              }}>Offers</span>
              <section className="search-container" style={{ float: "right" }}>

                <FormGroup>
                  <InputGroup className="search-group">
                    <InputGroupAddon addonType="prepend">
                      <img
                        src={searchImage}
                        alt="Search"
                        className="search-image"
                      />
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search..."
                      onChange={(event) => handlePaginationClick(1, event.target.value)}
                      autoComplete="off"
                      onFocus={() => { setSearchImage(ICONS.SEARCH_ACTIVE) }}
                      onBlur={() => { setSearchImage(ICONS.SEARCH) }}
                    />
                  </InputGroup>
                </FormGroup>
              </section>
              <div style={{ clear: "both" }}></div>
            </div>
            <div className="offer-table-container">
              {isLoading ?
                <section className="skeleton-loading">
                  <SkeletonTable rows={5} />
                </section>
                :
                <Table className="offer-table" id="offer-table">
                  <thead>
                    <tr>
                      {TABLE_COLS.map((d, i) => {
                        if (d.text.toLowerCase() === "offer title" || d.text.toLowerCase() === "offer name"
                          || d.text.toLowerCase() === "advertiser" || d.text.toLowerCase() === "organization") {
                          return <th key={i} className={d.className + " resized-column"} >
                            <div className="resizer-icon">
                              <span className="first"></span>
                              <span className="second"></span>
                            </div>
                            {d.text}
                            <img
                              onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                              src={sortColumn === d.sortName ?
                                (sortType === 'desc' ?
                                  ICONS.DESC_SORT_SELECTED : ICONS.ASC_SORT_SELECTED)
                                : ICONS.DESC} alt="desc icon" className="index-tip" /></th>;

                        }
                        if (d.text.toLowerCase() === "actions") {
                          return <th key={i} className={d.className}>{d.text}
                          </th>
                        }
                        return <th key={i} className={d.className} >{d.text}
                          <img
                            onClick={() => sortColumnData(d.sortName.toLowerCase(), sortType === 'asc' ? 'desc' : 'asc')}
                            src={sortColumn === d.sortName ?
                              (sortType === 'desc' ?
                                ICONS.DESC_SORT_SELECTED : ICONS.ASC_SORT_SELECTED)
                              : ICONS.DESC} alt="desc icon" className="index-tip" /></th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(offerList && offerList.length > 0) ?
                      offerList && offerList.map((data, index) => {
                        return (
                          <tr key={data.id}>
                            <td className="col-title">
                              <span>{data.offer_name}</span>
                            </td>
                            <td className="col-title">
                              <span className="title-name">{data.title}</span>
                            </td>
                            <td className="col-title">
                              <span>{data.brand_v2 && data.brand_v2.organization ? data.brand_v2.organization.name : ""}</span>
                            </td>
                            <td className="col-title">
                              <span>{data.brand_name}</span>
                            </td>
                            {/* <td>
                              <span>{OFFER_TYPE[data.type]}</span>
                            </td> */}
                            <td>
                              <div className='state-change-dropdown'>
                                {data?.state !== 1 ? <StatusDropdown data={data} refreshTable={refreshTable} /> :
                                  <span className={OFFER_STATES[data?.state].state_label.toLocaleLowerCase() + " d-flex"}> <span className="state-dot"></span>{OFFER_STATES[data?.state].state_label}</span>
                                }</div>
                            </td>
                            <td>
                              <span>{format(new Date(data.updated_at), 'MMMM dd, yyyy')}</span>
                            </td>
                            <td className="column-actions">
                              <section className="d-flex">
                                <span
                                  onClick={(e) => goToPreview(e, data.id, data.brand, data.ad, data.message, data.type, data.offer_name)}
                                ><img src={"/Icon_assets/svg/preview-icon.svg"} className="preview-icon" alt="preview" />
                                </span >

                                <RBAC requiredPermissions={PERMISSIONS.OFFER_WRITE_CLONE_DELETE}>
                                  <OffersOptions id={data.id}
                                    onCloneOffer={(id) => { setOpenCloneOfferModal(true); setCurrentOffer(data) }}
                                    onDeleteOffer={(id) => { showDeleteModal(true); setSelectedItemId(id); }}
                                    onEdit={onEditOffer}
                                    data={data} />
                                </RBAC>
                              </section>
                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr>
                        <td colSpan="5" style={{ border: "none" }}>
                          <div className="center" style={{ textAlign: "center" }}>
                            <p> No data available</p>
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              }</div>
            {isPagination ? offerList && offerList.length > 0 && (<>
              <ADPagination
                className="rtr-pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PageSize}
                handlePaginationClick={(pageNumber) => handlePaginationClick(pageNumber, '')}
                showNoOfRecords={true}
              />
            </>) : null}
          </CardBody>
        </Card>
        <PopUpModal
          className={'delete-offer-modal'}
          title={"Delete Offers"}
          openPopUp={deleteModal}
          setOpenPopUp={showDeleteModal} ><div>
            <DeleteOffer
              setOpenDeleteModal={showDeleteModal}
              seletedItemId={selectedItemId}
              deleteRequest={handleOfferDelete} />
          </div>
        </PopUpModal>
        <PopUpModal
          className={'clone-offer-modal'}
          title={"Clone Offer"}
          openPopUp={openCloneOfferModal}
          setOpenPopUp={setOpenCloneOfferModal}
        >
          <CloneOffer
            currentOffer={currentOffer}
            setOpenCloneOfferModal={setOpenCloneOfferModal}
            cloneOfferRequest={handleCloneOffer}
          />
        </PopUpModal>
      </div></div>)

}

export default OfferList;