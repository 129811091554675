import ACTIONS from "../constants/actionTypes";


const initialState = {
  isLoading: false,
  isExternalPreviewLoading: false,
  previewSurvey: "",
  externalPreview: "",
  imageLoading: false,
  blobUrl: "",
  mimeTypeList: [],
  saveFileResult: {},
  adLoading: false,
  adData: [],
  activating: false,
  csvUrl: "",
  csvLoading: false,
  updatedAd: {},
  activatedAdData: {},
  toastLoading: false,
  toastUrl: "",
  isFetching: false,
  isTaxonomyListFetching: false,
  taxonomyQuestionListData:[],
  taxonomyAnswerListData:[],
  campaign: "",
  asset: "",
  invisiblyAsset: ""
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTIONS.GET_PREVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: undefined,
        previewSurvey: ""
      };

    case ACTIONS.GET_PREVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        previewSurvey: action.payload,
      };

    case ACTIONS.GET_PREVIEW_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMsg: undefined,
        previewSurvey: ""
      };

    case ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST:
      return {
        ...state,
        isExternalPreviewLoading: true,
        errorMsg: undefined,
        externalPreview: ""
      };

    case ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        isExternalPreviewLoading: false,
        errorMsg: undefined,
        externalPreview: action.payload
      };

    case ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST_FAILURE:
      return {
        ...state,
        isExternalPreviewLoading: false,
        errorMsg: undefined,
        externalPreview: ""
      };


    case ACTIONS.GET_UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        errorMsg: "",
        isHeaderImage: false,
        isLogoImage: false,
        imageLoading: false
      }

    case ACTIONS.GET_UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        errorMsg: "",
        isHeaderImage: false,
        isLogoImage: false,
        imageLoading: true
      }

    case ACTIONS.GET_UPLOAD_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        blobUrl: action.payload,
        isHeaderImage: action.isHeaderImage,
        isLogoImage: action.isLogoImage,
        imageLoading: false
      }

    case ACTIONS.GET_UPLOAD_IMAGE_REQUEST_CLEAR:
      return {
        ...state,
        blobUrl: "",
        errorMsg: "",
        isHeaderImage: false,
        isLogoImage: false,
        imageLoading: false
      }


    case ACTIONS.REQUEST_MIME_TYPE_LIST:
      return {
        ...state,
        mimeTypeList: [],
      };

    case ACTIONS.RECEIVE_MIME_TYPE_LIST:
      return {
        ...state,
        mimeTypeList: action.mimeTypeList
      };


    case ACTIONS.SAVE_FILE_FAILURE:
      return {
        ...state,
        uploadedFileList: [],
        uploadedFilesResult: null,
        isFileUploading: false,
        saveFileResult: {}
      }

    case ACTIONS.SAVE_FILE_REQUEST:
      return {
        ...state,
        saveFileResult: {},
        // file: action.payload,
        uploadedFileList: [],
        uploadedFilesResult: null
      }

    case ACTIONS.SAVE_FILE_SUCCESS:
      return {
        ...state,
        saveFileResult: action.payload.res[0],
        uploadedFileList: [],
        uploadedFilesResult: null,
        isFileUploading: false
      }

    case ACTIONS.SAVE_FILE_REQUEST_CLEAR:
      return {
        ...state,
        uploadedFileList: [],
        uploadedFilesResult: null,
        isFileUploading: false,
        saveFileResult: {}
      }


    case ACTIONS.GET_UPLOAD_CSV_FAILURE:
      return {
        ...state,
        errorMsg: "",
        csvLoading: false,
        csvUrl: ""
      }

    case ACTIONS.GET_UPLOAD_CSV_REQUEST:
      return {
        ...state,
        errorMsg: "",
        csvLoading: true,
      }

    case ACTIONS.GET_UPLOAD_CSV_REQUEST_SUCCESS:
      return {
        ...state,
        csvUrl: action.payload.blob_url,
        csvLoading: false
      }

    case ACTIONS.GET_UPLOAD_CSV_REQUEST_CLEAR:
      return {
        ...state,
        csvUrl: "",
        errorMsg: "",
        csvLoading: false
      }


    case ACTIONS.POST_AD_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: "",
        adLoading: false,
      }

    case ACTIONS.POST_AD_REQUEST:
      return {
        ...state,
        errorMsg: "",
        adLoading: true,
      }

    case ACTIONS.POST_AD_REQUEST_SUCCESS:
      return {
        ...state,
        adData: action.payload,
        adLoading: false
      }

    case ACTIONS.POST_AD_REQUEST_CLEAR:
      return {
        ...state,
        errorMsg: "",
        adLoading: false,
        adData: {}
      }

    case ACTIONS.ACTIVATE_AD_REQUEST:
      return {
        ...state,
        errorMsg: "",
        activating: true,
      }

    case ACTIONS.ACTIVATE_AD_REQUEST_SUCCESS:
      return {
        ...state,
        activatedAdData: action.payload,
        activating: false
      }

    case ACTIONS.ACTIVATE_AD_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: "",
        activating: false,
      }

    case ACTIONS.ACTIVATE_AD_REQUEST_CLEAR:
      return {
        ...state,
        errorMsg: "",
        activating: false,
        activatedAdData: {}
      }

    case ACTIONS.UPDATE_DRAFTED_AD_FAILURE:
      return {
        ...state,
        errorMsg: "",
        adLoading: false,

      }

    case ACTIONS.UPDATE_DRAFTED_AD_REQUEST:
      return {
        ...state,
        errorMsg: "",
        adLoading: true,
      }

    case ACTIONS.UPDATE_DRAFTED_AD_SUCCESS:
      return {
        ...state,
        updatedAd: action.payload,
        adLoading: false
      }

    case ACTIONS.UPDATE_DRAFTED_AD_REQUEST_CLEAR:
      return {
        ...state,
        errorMsg: "",
        adLoading: false,
        updatedAd: {}
      }

    case ACTIONS.GET_UPLOAD_TOAST_FAILURE:
      return {
        ...state,
        errorMsg: "",
        toastLoading: false,
        toastUrl: ""
      }

    case ACTIONS.GET_UPLOAD_TOAST_REQUEST:
      return {
        ...state,
        errorMsg: "",
        toastLoading: true,
      }

    case ACTIONS.GET_UPLOAD_TOAST_REQUEST_SUCCESS:
      return {
        ...state,
        toastUrl: action.payload.blob_url,
        toastLoading: false
      }

    case ACTIONS.GET_UPLOAD_TOAST_REQUEST_CLEAR:
      return {
        ...state,
        toastUrl: "",
        errorMsg: "",
        toastLoading: false
      }


    case ACTIONS.GET_CAMPAIGN_BY_ID_REQUEST:
      return {
        ...state,
        campaign: "",
        errorMsg: "",
        isFetching: true
      }

    case ACTIONS.GET_CAMPAIGN_BY_ID_FAILURE:
      return {
        ...state,
        campaign: "",
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_CAMPAIGN_BY_ID_CLEAR:
      return {
        ...state,
        campaign: "",
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_AD_ASSET_REQUEST:
      return {
        ...state,
        asset: "",
        errorMsg: "",
        isFetching: true
      }

    case ACTIONS.GET_AD_ASSET_FAILURE:
      return {
        ...state,
        asset: "",
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_AD_ASSET_REQUEST_CLEAR:
      return {
        ...state,
        asset: "",
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_AD_ASSET_SUCCESS:
      return {
        ...state,
        asset: action.payload,
        errorMsg: "",
        isFetching: false
      }

    case ACTIONS.GET_AD_LIST_REQUEST:
      return {
        ...state,
        asset: "",
        errorMsg: "",
        isFetching: false
      }
    
      case ACTIONS.GET_INVISIBLY_AD_ASSET_REQUEST:
        return {
          ...state,
          invisiblyAsset: "",
          errorMsg: "",
          isFetching: true
        }
  
      case ACTIONS.GET_INVISIBLY_AD_ASSET_SUCCESS:
        return {
          ...state,
          invisiblyAsset: action.payload,
          isFetching: false
        }
  
      case ACTIONS.GET_INVISIBLY_AD_ASSET_FAILURE:
        return {
          ...state,
          invisiblyAsset: "",
          errorMsg: action.payload,
          isFetching: false
        }
  
      case ACTIONS.GET_INVISIBLY_AD_ASSET_REQUEST_CLEAR:
        return {
          ...state,
          invisiblyAsset: "",
          errorMsg: "",
          isFetching: false
        }
      
      case ACTIONS.GET_TAXONOMY_QUESTIONS_LIST_REQUEST:
        return {
          ...state,
          taxonomyQuestionListData: [],
          errorMsg: "",
          isTaxonomyListFetching: true
        }

      case ACTIONS.GET_TAXONOMY_QUESTION_LIST_SUCCESS:
        return {
          ...state,
          taxonomyQuestionListData: action.payload,
          errorMsg: "",
          isTaxonomyListFetching: false
        }
      
      case ACTIONS.GET_TAXONOMY_QUESTION_SEARCH_LIST_SUCCESS:
        return {
          ...state,
          taxonomyQuestionSearchListData: action.payload,
          errorMsg: "",
          isTaxonomyListFetching: false
        }

      case ACTIONS.GET_TAXONOMY_ANSWER_LIST_REQUEST:
        return {
          ...state,
          taxonomyAnswerListData: [],
          errorMsg: "",
          isTaxonomyListFetching: true
        }
      
      case ACTIONS.GET_TAXONOMY_ANSWER_LIST_SUCCESS:
        return {
          ...state,
          taxonomyAnswerListData: action.payload,
          errorMsg: "",
          isTaxonomyListFetching: false
        }
        
      case ACTIONS.GET_TAXONOMY_LIST_FAILURE:
        return {
          ...state,
          taxonomyQuestionListData: [],
          taxonomyAnswerListData:[],
          errorMsg: "",
          isTaxonomyListFetching: false
        }
  
      case ACTIONS.GET_TAXONOMY_LIST_REQUEST_CLEAR:
        return {
          ...state,
          taxonomyQuestionListData: [],
          taxonomyAnswerListData:[],
          errorMsg: "",
          isTaxonomyListFetching: false
        }
    default:
      return state;
  }
};

export default surveyReducer;