
import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonAnalyticsTable = ({rows=4}) => {
  return (
    <section className="skeleton-analytics-table-wrapper">
      <div className="skeleton-table">
        <section className='question-type-loader'>
          <SkeletonElement type="title" />
        </section>
        <section className='question-loader'>
          <SkeletonElement type="title" />
        </section>
        {
          Array(rows).fill(null).map((data, index) => {
            return(
              <>
                <SkeletonElement type="title" key={index}/>
                <SkeletonElement type="line" key={index}/>
              </>
            )
          })
        }
      </div>
      <Shimmer />
    </section>
  )
}

export default SkeletonAnalyticsTable;