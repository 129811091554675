import ACTIONS from "../constants/actionTypes";

const initialState = { 
  isLoggedIn: false, 
  isLoading: false, 
  userData: {profile_pic:"", name:""}, 
  forgotpassword: { email: '', isEmailSent: false }, 
  resetPasswordRes:'',
  rbac: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SIGNIN_REQUEST:
      return {
        ...state,
        userData: action.payload,
        isLoading: true,
      };

    case ACTIONS.SIGNIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isLoggedIn: true,
        isLoading: false,
      };
    case ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordRes: action.payload,
        isLoading: false,
      };
    
    case ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordRes: action.payload,
        isLoading: false,
      };
    
    case ACTIONS.RESET_PASSWORD_FAILURE_CLEAR:
      return {
        ...state,
        resetPasswordRes: undefined,
        isLoading: false,
      };

    case ACTIONS.SIGNIN_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoggedIn: false,
        isLoading: false,
      };

    case ACTIONS.SIGNIN_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.SIGNUP_REQUEST:
      return {
        ...state,
        userData: action.payload,
        isLoading: true,
      };

    case ACTIONS.SIGNUP_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isLoggedIn: true,
        isLoading: false,
      };

    case ACTIONS.SIGNUP_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoggedIn: false,
        isLoading: false,
      };

    case ACTIONS.SIGNUP_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.FORGOTPASSWORD_REQUEST:
      return {
        ...state,
        forgotpassword: {
          ...state.forgotpassword,
          email: action.payload.email
        },
        isLoading: true,
      };

    case ACTIONS.FORGOTPASSWORD_SUCCESS:
      return {
        ...state,
        forgotpassword: { ...state.forgotpassword, isEmailSent: true },
        isLoading: false,
      };

    case ACTIONS.FORGOTPASSWORD_FAILURE:
      return {
        ...state,
        forgotpassword: { ...state.forgotpassword, isEmailSent: false },
        isLoading: false,
      };

    case ACTIONS.FORGOTPASSWORD_FAILURE_CLEAR:
      return {
        ...state,
        forgotpassword: { ...state.forgotpassword, isEmailSent: false },
        errorMsg: undefined,
      };

    case ACTIONS.FORGOTPASSWORD_RESET:
      return {
        ...state,
        forgotpassword: { ...state.forgotpassword, isEmailSent: false },
        isLoading: false,
      };

    case ACTIONS.LOG_OUT_SUCCESS:
      return initialState;

    case ACTIONS.UPDATE_USER_INFO:
      return {
        ...state,
        userData: {...state.userData, name: action.payload.name}
      }
    
    case ACTIONS.SET_RBAC:
       return {
        ...state,
        rbac: action.payload
      }
    
    case ACTIONS.RESET_RBAC:
      return {
        ...state,
        rbac: []
      }

    default:
      return state;
  }
};

export default authReducer;
