import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isFetched: false,
  data: [],
  brandData: {},
  isLogoUploading: false,
  isLogoUploaded: false,
  logoURL: "",
  LogoUploadError: undefined,
  createdBrand: {},
  isSaving: false,
  isFetching: false,
  brandById: {}
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BRANDS_LIST_REQUEST:
      return {
        ...state,
        // data: action.payload,
        isLoading: true,
        isFetched: false
      };

    case ACTIONS.BRANDS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataCount: action.payload.count,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.BRANDS_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        data: [],
        dataCount: 0,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.BRANDS_LIST_FAILURE_CLEAR:
      return {
        ...state,
        data: [],
        dataCount: 0,
        errorMsg: undefined,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        logoURL: "",
        isLogoUploading: true,
        isLogoUploaded: false,
        LogoUploadError: undefined,
      };

    case ACTIONS.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        logoURL: action.payload.blob_url,
        isLogoUploading: false,
        isLogoUploaded: true,
      };

    case ACTIONS.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        logoURL: "",
        isLogoUploading: false,
        isLogoUploaded: false,
        LogoUploadError: action.payload,
      };

    case ACTIONS.UPLOAD_FILE_FAILURE_CLEAR:
      return {
        ...state,
        logoURL: "",
        isLogoUploading: false,
        isLogoUploaded: false,
        LogoUploadError: undefined,
      };

    case ACTIONS.RESET_BRAND_LOGO:
      return {
        ...state,
        logoURL: "",
        isLogoUploaded: false
      };

    case ACTIONS.SAVE_BRAND_REQUEST:
      return {
        ...state,
        isSaving: true,
      };

    case ACTIONS.SAVE_BRAND_REQUEST_SUCCESS:
      return {
        ...state,
        createdBrand: action.payload,
        isSaving: false,
      };

    case ACTIONS.SAVE_BRAND_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        createdBrand: {},
        isSaving: false,
      };

    case ACTIONS.SAVE_BRAND_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        createdBrand: {},
        errorMsg: undefined,
      };

    case ACTIONS.GET_BRAND_BY_ID_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        brandById: action.payload,
        isLogoUploaded: action.payload.logo !== "",
        isFetching: false,
      };

    case ACTIONS.GET_BRAND_BY_ID_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        brandById: {},
        isFetching: false,
      };

    case ACTIONS.GET_BRAND_BY_ID_FAILURE_CLEAR:
      return {
        ...state,
        brandById: {},
        errorMsg: undefined,
      };
    default:
      return state;
  }
};

export default brandsReducer;
