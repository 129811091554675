import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Table, Row, Col
} from "reactstrap";
import "./index.scss";
import Loader from "../../Loader";
import history from "../../../history";

import CLIENT_SIDE_MESSAGES from "../../../utils/clientSideMessages"
import ADspinner from "../../Loader/ADspinner";

import Validator from "../../../utils/validators";
import { organizationActionCreator, userActionCreator } from "../../../actions";
import ROUTES from "../../../utils/routeConstants";
import AccessFilter from "./AccessFilter";
import RemoveUserModal from "./RemoveUserModal";
import SaveChangesModal from "./SaveChangesModal";
import PopUpModal from "../../PopUpModal";
import SkeletonSection from "../../Loader_Skeleton/SkeletonSection";
import DiscardChange from "./DiscardChangesModal";

const UserForm = (props) => {
  let { id } = useParams();
  const SuperAdmin = "Admin"; // "this is invisibly admin"
  const location = useLocation();
  const validatorsObj = new Validator();
  const dispatch = useDispatch();

  const [validators, setstate] = useState(validatorsObj);

  /// Redux Store
  const isLoading = useSelector((state) => state.organization.isLoading);
  const editUser = useSelector((state) => state.user.userListData);
  const organizationData = useSelector((state) => state.organization.organizationData?.results);
  const organizationCount = useSelector((state) => state.organization.organizationData?.count);
  const selectedOrganizationState = useSelector((state) => state.organization.selectedOrganization);
  const errorMsg = useSelector((state) => state.organization.errorMsg);
  const isUserAddeding = useSelector((state) => state.user.isUserAddeding);
  const isUserUpdating = useSelector((state) => state.user.isUserUpdating);
  const isUserAdded = useSelector((state) => state.user.isUserAdded);
  const isRolesLoading = useSelector((state) => state.user.isRolesLoading);
  const userRolesList = useSelector((state) => state.user.userRolesList?.results);

  //close modal on Cancel button click
  const [orgList, setOrgList] = useState([]);
  const [totalOffset, setTotalOffset] = useState(0);
  const [orgCount, setOrgCount] = useState([0]);
  const [isEdit, setIsEdit] = useState(false);
  const [editUserData, setEditUserData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const userData = { name: "", email: "", organizations: [] }
  /*** State init ***/
  const [resetModal, setResetModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [superAdminSaveData, SetSuperAdminSaveData] = useState({})
  const [accessFilters, setAccessFilters] = useState([{
    name: '',
    organizations: [],
    org: [],
    role: ""
  }]);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [inputValues, setInputValues] = useState({
    editName: userData.name,
    editEmail: userData.email,
    fullname: "",
    editProfile_pic: "",
    email: "",
    profile_pic: "",
    editOrgId: selectedOrganizationState ? selectedOrganizationState : ""
  });

  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [enableInvite, setEnableInvite] = useState(false);
  const [organizationDropDownOpen, setOrganizationTypeDropDown] = useState(false);
  const [accessTypeDropDownValue, setAccessTypeDropDownValue] = useState(
    "Select Access Type"
  );

  const maxDataCount = 50;
  const [selectedRole, setSelectedRole] = useState([]);
  const [editAccessTypeDropDownValue, setEditAccessTypeDropDownValue,] = useState(userData.organization_role === 0 ? SuperAdmin : "Reporting User");
  const [rolesList, setRolesList] = useState([]);
  const [isEditUserPopulated, setIsEditUserPopulated] = useState(undefined);
  // const rolesList = [
  //   { role_id: 0, name: SuperAdmin, description: "Access to dashboard, RTRs & Offers, reports, organization and user management for all the organizations" },
  //   { role_id: 1, name: "Admin", description: "Access to dashboard, reports, RTRs & Offers, organization and user management for the selected organization" },
  //   { role_id: 2, name: "Builder", description: "Access to dashboard, reports, RTR & Offers for the selected organizations" },
  //   { role_id: 3, name: "Analyst", description: "Access to dashboard, reports for the selected organizations" },
  // ]

  const rolesLabel = {
    "Admin": "Invisibly Admin",
    "Org Admin": "Admin",
    "Builder": "Builder",
    "Analyst": "Analyst"
  };


  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    BACK: "/Icon_assets/svg/back_icon.svg",
    INFO: "/Icon_assets/svg/rule-info.svg",
    PLUS_ICON_BLUE_TRAN: "/Icon_assets/svg/plus_icon_blue_transparent.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
  };

  const inviteUser = (e) => {
    e.preventDefault();
    // const inputFields = { ...inputValues };
    if (!isEdit) {
      // setInputValues(inputFields);
      let payload;
      if (accessFilters.length === 1 && accessFilters[0].name === SuperAdmin) {
        payload = {
          email: inputValues.email,
          organization_role: 0,
        };
      } else {
        let arr = [];
        for (let i = 0; i < accessFilters.length; i++) {
          let a = { role: accessFilters[i].role, org: accessFilters[i].org }
          arr.push(a);
        }

        payload = {
          email: inputValues.email,
          access: arr
        };
      }

      if (isValid) {
        dispatch(userActionCreator.createUserRequest(payload));
      }
    } else {
      // Edit FLOW TO DO :Populate data
      let payload;
      if (accessFilters.length === 1 && accessFilters[0].name === SuperAdmin) {
        payload = {
          id: id,
          name: inputValues.fullname,
          organization_role: 0,
        };
      } else {
        let arr = [];
        for (let i = 0; i < accessFilters.length; i++) {
          let a = { role: accessFilters[i].role, org: accessFilters[i].org }
          arr.push(a);
        }

        payload = {
          id: id,
          name: inputValues.fullname,
          access: arr
        };
      }
      if (isValid) {
        dispatch(userActionCreator.updateUserRequest(payload));
      }
    }
    history.push(ROUTES.USERS_LIST)
  }

  const saveSuperAdminModal = (index) => {
    // call the action when cancel is clicked on modal 
    // based on previous value remove previous value from selectedUserRoles.
    let filterObj = JSON.parse(JSON.stringify([...accessFilters]));
    let inputIndex = superAdminSaveData.inputIndex;
    let data = superAdminSaveData.data;
    var updatedSelectedUserRoles = selectedUserRoles;
    updatedSelectedUserRoles[inputIndex] = {
      id: data.id,
      label: data.label
    }
    // let selRole = JSON.parse(JSON.stringify([...selectedUserRoles]));
    filterObj[inputIndex].name = data.label;
    filterObj[inputIndex].role = data.id;
    filterObj[inputIndex]["org"] = [];
    filterObj[inputIndex]["organizations"] = [];

    setAccessFilters(filterObj);
    setSelectedUserRoles(updatedSelectedUserRoles);
    setResetModal(false);
    //  setSaveModal(true);
    saveUserRoleChanges(filterObj, updatedSelectedUserRoles)
  }

  const toggleOrganizationTypeDropDown = () => {
    setOrganizationTypeDropDown((prevState) => !prevState);
    if (!organizationDropDownOpen) {
      setOrgList([]);
      setTotalOffset(0);
      setOrgCount(0);
      // call clear org list 
      // dispatch(organizationActionCreator.getOrganizationListFailureClear());
    }
  }

  const getOrganizationDropdownOptions = () => {
    if (!organizationDropDownOpen) {
      var param = { limit: 50 }
      dispatch(organizationActionCreator.getOrganizationListRequest(param));
    }
  };

  const removeSelectedOrganizations = (e, data, item) => {
    e.preventDefault();
    var obj = JSON.parse(JSON.stringify([...accessFilters]));
    var org = [];
    for (var i = 0; i < obj.length; i++) {
      if (obj[i].name === data.name) {
        var orgArr = obj[0]["organizations"] || [];
        org = obj[i].org;
        obj[i].org.splice(obj[i].org.indexOf(item), 1);
        obj[i].organizations = obj[i].organizations.filter((org) => org.id !== item);
      }
    }
    selectedOrganization.splice(selectedOrganization.indexOf(data.id), 1);
    setAccessFilters(obj);

  }

  const removeAllOrgnizations = (e, data) => {
    e.preventDefault();
    var obj = JSON.parse(JSON.stringify([...accessFilters]));
    // remove all  orgs for the specific selection, also remove selectedorg
    var org = [];
    for (var i = 0; i < obj.length; i++) {
      if (obj[i].name === data.name) {
        org = obj[i].org;
        obj[i].org = [];
        obj[i].organizations = [];
      }
    }
    setSelectedOrganization(org.filter(function (obj) { return selectedOrganization.indexOf(obj) == -1; }));
    setAccessFilters(obj);
  }

  const removeFilter = (e, filterIndex) => {
    let obj = [...accessFilters];
    var t = obj.splice(filterIndex, 1);
    selectedOrganization.filter(function (obj) { return t[0].org.indexOf(obj) == -1; });
    setAccessFilters(obj);
    // remove selected user roles
    let objUser = [...selectedUserRoles];
    setSelectedUserRoles(objUser.filter((obj) => { return obj.label !== t[0].name }));
    dispatch(organizationActionCreator.getOrganizationListFailureClear());
    // also remove the scroll event handler based on the dilterIndex received
  }

  const changeAccessTypeDropDownValue = (e, item) => {
    setAccessTypeDropDownValue(
      e.currentTarget.getElementsByTagName("h6")[0].innerText
    );
    setEditAccessTypeDropDownValue(
      e.currentTarget.getElementsByTagName("h6")[0].innerText
    );

    // set roles selected in access type
    var obj = {
      name: item.name,
      role: item.id,
      organizations: [],
      description: item.description
    };

    // check on toggle.
    // setAccessFilters([item]);
    setSelectedUserRoles([obj]);
  };

  const handleAccessType = (e, data, inputIndex) => {
    let filterObj = JSON.parse(JSON.stringify([...accessFilters]));
    if (filterObj.length > 1 &&
      data.label === SuperAdmin && !filterObj.map((ob) => ob.name).includes(SuperAdmin)) {
      // launch popup and the rest of ligic will move to yes button.
      setResetModal(true);
      SetSuperAdminSaveData({
        data,
        inputIndex
      })
    }
    else {
      // based on previous value remove previous value from selectedUserRoles.
      var updatedSelectedUserRoles = selectedUserRoles.filter((s) => s.label !== filterObj[inputIndex].name)
      // let selRole = JSON.parse(JSON.stringify([...selectedUserRoles]));
      filterObj[inputIndex].name = data.label;
      filterObj[inputIndex].role = data.id;
      filterObj[inputIndex]["organizations"] = filterObj[inputIndex]["organizations"] || [];

      // set updated record to selected user roles.
      let elem = data;
      elem["organizations"] = filterObj[inputIndex]["organizations"];
      updatedSelectedUserRoles.push(elem);

      setAccessFilters(filterObj);
      setSelectedUserRoles(updatedSelectedUserRoles);
    }

  }

  const handleMultipleOrganization = (e, data, inputIndex) => {
    // TO DO : update set selected role
    let filterObj = JSON.parse(JSON.stringify([...accessFilters]));
    // assign already selected orgs to orgArray
    var orgArr = filterObj[inputIndex]["organizations"] || [];

    if (e.target.checked) {
      // this needs to be updated based on the index of accessFilters and selecteduserroles
      orgArr.push(data);

      filterObj[inputIndex].organizations = orgArr;
      filterObj[inputIndex].org = orgArr.map((org) => org.id);
      setAccessFilters(filterObj);
      setSelectedOrganization((org) => {
        return [...org, data.id];
      });
    } else {
      selectedOrganization.splice(selectedOrganization.indexOf(data.id), 1);
      filterObj[inputIndex].org.splice(filterObj[inputIndex].org.indexOf(data.id), 1);
      filterObj[inputIndex].organizations = orgArr.filter((org) => org.id !== data.id);
      setAccessFilters(filterObj);
    }
  }

  const backToUserList = () => {
    history.push(ROUTES.USERS_LIST);
  }

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    if (e.target.id === 'email') {
      const checkValidation = validators.email.valid;
      setIsValid(checkValidation);
    }
    if (e.target.id === 'fullname') {
      const checkValidation = validators.fullname.valid;
      setIsValid(checkValidation);
    }
  }

  const closeModal = () => {
    setResetModal(false);
  }

  const cancelRemoveUserModal = () => {
    // close the reset user modal and remove it from access fitler
    var obj = JSON.parse(JSON.stringify([...accessFilters]));
    var roles = JSON.parse(JSON.stringify([...selectedUserRoles]));
    // remove Invisibly Admin from accessFilters
    var removedUser = obj.filter((o) => { return o.name !== SuperAdmin })
    setAccessFilters(removedUser);

    //reset the selectedUserRole
    setSelectedUserRoles(roles.filter((r) => r.label !== SuperAdmin));
    closeModal();
  }

  const closeSaveModal = () => {
    // close the reset user modal and remove it from access fitler
    var obj = JSON.parse(JSON.stringify([...accessFilters]));
    var roles = JSON.parse(JSON.stringify([...selectedUserRoles]));
    // remove Invisibly Admin from accessFilters
    var removedUser = obj.filter((o) => { return o.name !== SuperAdmin })
    setAccessFilters(removedUser);

    //reset the selectedUserRole
    setSelectedUserRoles(roles.filter((r) => r.label !== SuperAdmin));

    setSaveModal(false);
  }

  const saveUserRoleChanges = (obj, roles) => {
    setAccessFilters(obj.filter((o) => o.name === SuperAdmin));
    setSelectedUserRoles(roles.filter((r) => r.label === SuperAdmin));
    setSaveModal(false);
  }

  const addAccessFilters = () => {
    let obj = [...accessFilters];
    obj.push({
      name: '',
      organizations: [],
      org: [],
      role: ""
    });

    setAccessFilters(obj);

    // get data from role list and set the data in sselected role
    // accessTypeDropDownValue
    var select = rolesList.filter((role) => {
      return role.name === accessTypeDropDownValue
    })
    setSelectedRole({ ...selectedRole, select });
    dispatch(organizationActionCreator.getOrganizationListFailureClear());
  }

  const callOrgAPI = (e, params) => {
    dispatch(organizationActionCreator.getOrganizationListRequest(params));
  }

  const handleOrganizationScroll = (e) => {
    var count = organizationCount;
    var orgListCount = orgList.length;
    var organizationDrop = document.getElementById(e.target.id);
    if ((organizationDrop.offsetHeight + organizationDrop.scrollTop + 10) >= organizationDrop.scrollHeight) {
      if (count > orgListCount && orgListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        params.limit = 50;
        params.offset = totalOffset + 10;
        debounceCall(e, params);
      }
    }
  }

  const handleOrganizationScroll1 = (e) => {
    var count = organizationCount;
    var orgListCount = orgList.length;
    var organizationDrop = document.getElementById(e.target.id);
    if ((organizationDrop.offsetHeight + organizationDrop.scrollTop + 10) >= organizationDrop.scrollHeight) {
      if (count > orgListCount && orgListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        params.limit = 50;
        params.offset = totalOffset + 10;
        debounceCall(e, params);
      }
    }
  }

  const handleOrganizationScroll2 = (e) => {
    var count = organizationCount;
    var orgListCount = orgList.length;
    var organizationDrop = document.getElementById(e.target.id);
    if ((organizationDrop.offsetHeight + organizationDrop.scrollTop + 10) >= organizationDrop.scrollHeight) {
      if (count > orgListCount && orgListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        params.limit = 50;
        params.offset = totalOffset + 10;
        debounceCall(e, params);
      }
    }
  }

  const debounce = (func, wait = 300) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debounceCall = useCallback(debounce(callOrgAPI), [])

  useEffect(() => {
    // call API to get roles of user
    // dispatch(organizationActionCreator.getOrganizationListRequest());
    dispatch(userActionCreator.getUserRolesListRequest());
    if (id)
      dispatch(userActionCreator.getUserListRequest({ userId: id }));
  }, [])

  useEffect(() => {
    setRolesList(userRolesList);
  }, [userRolesList]);

  useEffect(() => {
    // TO DO : EDIT : to uncomment and check later
    if (editUser?.id && rolesList.length > 0) {
      setEditUserData(editUser);
      // populate my accessfilters and ipnut values
      setInputValues({ ...inputValues, fullname: editUser.name, email: editUser.email });
      // editUser.access

      let uniqueRoles = Array.from(new Set(editUser.organizations.map(or => or.role_id)))
      let payload = [];
      let selectedRolesPayload = [];
      if (editUser.organization_role !== 0) {
        uniqueRoles.forEach((uniqueRole) => {
          let org = editUser.organizations.filter(or => or.role_id === uniqueRole).map(or => or.org.id)
          let organizations = editUser.organizations.filter(or => or.role_id === uniqueRole).map(or => {
            return { ...or.org, label: or.org.name }
          })
          let role = uniqueRole;
          let name = userRolesList.find(ur => ur.id === uniqueRole).label;

          payload.push({
            org,
            organizations,
            role,
            name
          })

          selectedRolesPayload.push({
            id: role,
            label: name
          })

        })
        setAccessFilters(payload)
        setSelectedUserRoles(selectedRolesPayload)
        setIsEditUserPopulated(true)
        setIsValid(true);
      }
      else {
        let uniqueRole = userRolesList.find(ur => ur.value === location.state.data.organization_role);
        payload.push({
          org: [],
          organizations: [],
          role: uniqueRole.id,
          name: uniqueRole.label
        })
        selectedRolesPayload.push({
          id: uniqueRole.id,
          label: uniqueRole.label
        })
        setAccessFilters(payload)
        setSelectedUserRoles(selectedRolesPayload)
        setIsEditUserPopulated(true)
        setIsValid(true);
      }
    }
  }, [editUser, userRolesList]);

  useEffect(() => {
    if (location && location.state) {
      setIsEdit(location.state.isEdit);
      // Call get by user ID
      setEditUserData(location.state.userData);
    }

    // set access object as per gven data:

  }, [location]);

  useEffect(() => {
    if (organizationCount) {
      setOrgCount(organizationCount);
    }
    if (organizationData && organizationData.length > 0) {
      setOrgList(organizationData);
    }

  }, [organizationData, organizationCount]);

  useEffect(() => {
    console.log("****check state and orgs ******")
    console.log("**** selectedUserRoles ******", selectedUserRoles);
    console.log("**** accessFilters ******", accessFilters);
  }, [selectedUserRoles, accessFilters])

  useEffect(() => {
    if (inputValues.email !== "" && accessFilters.length === 1 &&
      accessFilters[0].name === SuperAdmin && isValid) {
      setEnableInvite(true);
    } else if (inputValues.email !== "" && isValid
      && accessFilters.every((a) => a.organizations.length > 0)
      && accessFilters.every((a) => a.name !== "")) {
      setEnableInvite(true);
    } else
      setEnableInvite(false);

  }, [inputValues, accessFilters]);


  useEffect(() => {
    var analystOrganizationDrop = document.getElementById("organizationDropDown-1");
    if (analystOrganizationDrop && orgList && orgList.length > 0) {
      analystOrganizationDrop.addEventListener('scroll', handleOrganizationScroll2);
      return () =>
        analystOrganizationDrop.removeEventListener('scroll', handleOrganizationScroll2);
    }

  }, [orgList, selectedUserRoles, accessFilters])


  useEffect(() => {
    var builderOrganizationDrop = document.getElementById("organizationDropDown-0");
    if (builderOrganizationDrop && orgList && orgList.length > 0) {
      builderOrganizationDrop.addEventListener('scroll', handleOrganizationScroll1);
      return () =>
        builderOrganizationDrop.removeEventListener('scroll', handleOrganizationScroll1);
    }
  }, [orgList, selectedUserRoles, accessFilters])

  useEffect(() => {
    var organizationDrop = document.getElementById("organizationDropDown-2");

    if (organizationDrop && orgList && orgList.length > 0) {
      organizationDrop.addEventListener('scroll', handleOrganizationScroll);
      return () =>
        organizationDrop.removeEventListener('scroll', handleOrganizationScroll);
    }
  }, [orgList, selectedUserRoles, accessFilters])

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(organizationActionCreator.getOrganizationListFailureClear());
      }, 3000);
    }
  }, [errorMsg]);

  useEffect(() => {
    // TO DO : VERIFY POST API INTEGRATION
    if (isUserAdded) {
      history.push(ROUTES.USERS_LIST);
      dispatch(userActionCreator.createUserFailureClear());
    }

  }, [isUserAdded])


  return (
    <div className="right-container">
      <section className="create-user-section">
        <nav className={"left-nav-section"} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          <Button className="btn-transparent pl-5 pb-3" onClick={backToUserList}>
            <img src={statusIcons.BACK} alt="go to back" className="go-back" style={{ verticalAlign: "super" }} />
          </Button>
          <h2 className={"secondary-title"} id="user-header">
            {isEdit ? `Edit ` + inputValues.fullname : ` Add New User`}</h2>
        </nav>
        <section className="body-section">
          <Card className="data-card">
            <section id="header-section">
              <nav className="left-nav-section">
                <h2 className="header card-heading">User Details</h2>
              </nav>
            </section>

            <CardBody style={{ paddingTop: 0 }}>
              <div className='user-creation-table'>
                <Table className="user-table">
                  {isEdit && <Row style={{ marginBottom: '32px' }}>
                    <Col md={12} sm={12} lg={5}>
                      <FormGroup style={{ marginTop: "16px" }}>
                        <Input
                          type="text"
                          name="fullname"
                          id="fullname"
                          required
                          className="form-input form-input-sm mt-2 required"
                          autoComplete="off"
                          value={inputValues.fullname}
                          onChange={handleInputChange}
                          invalid={validators.fullname.errorMsg !== ""}
                        />
                        <Label className="floating-heading-label required">Full Name</Label>
                        <FormFeedback>{validators.fullname.errorMsg}</FormFeedback>
                      </FormGroup></Col>
                  </Row>}
                  <Row>
                    <Col md={12} sm={12} lg={5}>
                      <FormGroup style={{ marginTop: "16px" }}>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          required
                          className="form-input form-input-sm mt-2 required"
                          autoComplete="off"
                          disabled={editUser?.id}
                          value={inputValues.email}
                          onChange={handleInputChange}
                          invalid={validators.email.errorMsg !== ""}
                        />
                        <Label className="floating-heading-label required">Email</Label>
                        <FormFeedback>{validators.email.errorMsg}</FormFeedback>
                      </FormGroup></Col>
                  </Row>

                </Table>

              </div>
            </CardBody>
          </Card>

          <Card className="data-card">
            <section id="header-section">
              <nav className="left-nav-section">
                <h2 className="header card-heading" style={{ marginBottom: "0" }}>Setup Access type</h2>
              </nav>
            </section>
            <CardBody>
              <div className='user-creation-table user-form-container user-component-form'>

                {isEditUserPopulated !== undefined || !isEdit ? <Table className="user-table">
                  {
                    accessFilters &&
                      accessFilters.length > 0 ?
                      accessFilters.map((dFilter, index) => {
                        return (<AccessFilter
                          length={accessFilters.length}
                          index={index}
                          name={rolesLabel[dFilter.name]}
                          key={index}
                          accessOrgList={orgList.filter(item => (accessFilters.filter((as) => { if (as.name !== dFilter.name) return as }).map((ac) => ac.org).flat()).indexOf(item.id) === -1)}
                          filterOrgs={accessFilters.filter((as) => { if (as.name !== dFilter.name) return as }).map((ac) => ac.org).flat()}
                          filter={dFilter}
                          removeAccessFilter={removeFilter}
                          handleMultipleOrganization={handleMultipleOrganization}
                          rolesList={rolesList ? rolesList.filter(r => !selectedUserRoles.some(a => a.label === r.label)) : []}
                          selectedOrganization={selectedOrganization}
                          setSelectedOrganization={setSelectedOrganization}
                          changeAccessTypeDropDownValue={handleAccessType}
                          getOrganizationDropdownOptions={getOrganizationDropdownOptions}
                          removeSelectedOrganizations={removeSelectedOrganizations}
                          removeAllOrgnizations={removeAllOrgnizations}
                          toggleOrganizationTypeDropDown={toggleOrganizationTypeDropDown}
                          user_role={userRolesList && userRolesList[0]}
                          isOrgLoading={isLoading}
                        />)
                      }) : ""}
                  {(accessFilters.length < 3)
                    ?
                    !(accessFilters.length === 1 && accessFilters[0].name === SuperAdmin)
                      ?
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <Button
                            className={`btn-transparent add-filter add-filter-active`}
                            onClick={() => addAccessFilters()}>
                            <img src={statusIcons.PLUS_ICON_BLUE_TRAN} alt="Add Access Type" className="add-filter-img" />
                            Add Access Type
                          </Button>
                        </Col>
                      </Row>
                      : <></>
                    : <></>}

                </Table> : <SkeletonSection />}

              </div>
            </CardBody>
          </Card>
        </section>
        <FormGroup className="text-right">
          <Button
            className="in-btn-lg in-btn-secondary btn-transparent cancel-user-btn"
            onClick={() => {
              if (isEdit)
                setCancelModal(true)
              else
                history.push(ROUTES.USERS_LIST)
            }}
          >Cancel</Button>
          <Button
            className="in-btn-lg in-btn-primary ml-3 create-user-btn"
            type="button"
            onClick={(e) => {
              if (isEdit)
                setSaveModal(true)
              else
                inviteUser(e)
            }}
            disabled={!enableInvite}
          >{isEdit ? isUserUpdating ? <ADspinner /> : 'Save' : isUserAddeding ?
            <ADspinner /> : 'Invite'}</Button>
        </FormGroup>
      </section>

      <PopUpModal
        className={'delete-rtr-ad-modal'}
        title={"Remove other roles? "}
        openPopUp={resetModal}
        setOpenPopUp={setResetModal}
      >
        <RemoveUserModal
          cancelRemoveUserModal={cancelRemoveUserModal}
          yesClicked={saveSuperAdminModal}
        />
      </PopUpModal>

      <PopUpModal
        className={'delete-rtr-ad-modal'}
        title={"Save changes?"}
        openPopUp={saveModal}
        setOpenPopUp={setSaveModal}
      >
        <SaveChangesModal
          closeModal={() => setSaveModal(false)}
          saveUserRoleChanges={inviteUser} />
      </PopUpModal>

      <PopUpModal
        className={'delete-rtr-ad-modal'}
        title={"Discard Changes ?"}
        openPopUp={cancelModal}
        setOpenPopUp={setCancelModal}
      >
        <DiscardChange
          cancelRemoveUserModal={() => setCancelModal(false)}
          yesClicked={() => history.push(ROUTES.USERS_LIST)} />
      </PopUpModal>
    </div>
  );
};

export default UserForm;
