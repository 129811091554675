import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  format,
  startOfQuarter,
  startOfWeek,
  subDays,
  endOfQuarter,
  endOfWeek,
  subWeeks
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./index.scss";

const ADCalendar = ({ getDate, closeCalender, dateFormat, selectedDate, selectedDateRangeOption, conversionBuilder, displayDateFormat}) => {
  /*State config start*/
  // const intialDate = [{
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: "selection",
  // }];
  const [state, setState] = useState(selectedDate);
  /*State config end*/

  if (!displayDateFormat) {
    displayDateFormat = dateFormat;
  }

  /*Calender left-side nav config start*/
  const [dateSidebarOptions, setDateSidebarOptions] = useState(
    conversionBuilder ? 
    [
      {
        label: "Custom",
        date_range: 3,
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.startOfMonth,
        }),
      },
      {
        label: 'Today',
        date_range: 4,
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday
        }) 
      },
      {
        label: "Yesterday",
        date_range: 5,
        range: () => ({
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday,
        }),
      },
    
      {
        label: "Last 7 Days",
        date_range: 0,
        range: () => ({
          startDate: defineds.endOfLastSevenDays,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "This Week",
        date_range: 6,
        range: () => ({
          startDate: defineds.startOfWeek,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Month to Date",
        date_range: 9,
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last Month",
        date_range: 10,
        range: () => ({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
        }),
      },
      {
        label: "Quarter to Date",
        date_range: 11,
        range: () => ({
          startDate: defineds.startOfQuarter,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last Quarter",
        date_range: 12,
        range: () => ({
          startDate: defineds.startPreviousQuarter,
          endDate: defineds.endPreviousQuarter,
        }),
      }] :
    [
    {
      label: "Custom",
      date_range: 3,
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.startOfMonth,
      }),
    },
    {
      label: 'Today',
      date_range: 4,
      range: () => ({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday
      }) 
    },
    {
      label: "Yesterday",
      date_range: 5,
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "This Week",
      date_range: 6,
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 7 Days",
      date_range: 0,
      range: () => ({
        startDate: defineds.endOfLastSevenDays,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last Week",
      date_range: 7,
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: "Last 14 Days",
      date_range: 8,
      range: () => ({
        startDate: defineds.lastFourteenDays,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 30 Days",
      date_range: 1,
      range: () => ({
        startDate: defineds.lastThirtyDays,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Month to Date",
      date_range: 9,
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last Month",
      date_range: 10,
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: "Quarter to Date",
      date_range: 11,
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last Quarter",
      date_range: 12,
      range: () => ({
        startDate: defineds.startPreviousQuarter,
        endDate: defineds.endPreviousQuarter,
      }),
    },
    {
      label: "Year to Date",
      date_range: 13,
      range: () => ({
        startDate: defineds.startOYear,
        endDate: defineds.endOfToday,
      }),
    },
  ]);

  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOYear: startOfYear(addYears(new Date(), 0)),
    endOflastYear: startOfDay(addDays(new Date(), -1)),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
    startOfWeek: startOfWeek(new Date(), {weekStartsOn: 1}),
    lastFourteenDays: startOfDay(addDays(new Date(), -14)),
    lastThirtyDays: startOfDay(addDays(new Date(), -30)),
    startPreviousQuarter:startOfQuarter(startOfQuarter(new Date())-1),
    endPreviousQuarter: endOfQuarter(startOfQuarter(new Date())-1),
    startOfLastWeek: subWeeks(startOfWeek(new Date()), 1),
    endOfLastWeek :  endOfWeek(subWeeks(startOfWeek(new Date()), 1))
  };

  const sideBarOptions = () => {
    const customDateObjects = dateSidebarOptions;
    return customDateObjects;
  };

  const sideBar = sideBarOptions();

  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar),
  ];

  /*Calender left-side nav config end*/

  const handleDateChange = (e) => {
    //selectDateRange(false);
    const dateRange = state[0];
    const selectNextEndDate = startOfDay(addDays(dateRange.endDate, +1));
    const formatDispayDateRange = format(new Date(dateRange.startDate), displayDateFormat) + " - " + format(new Date(dateRange.endDate), displayDateFormat);

    const dateSidebarOptionsList = sideBarOptions();
    const checkSelectore = dateSidebarOptionsList.map((item)=> {
      return {...item, range: item.range() }
    }).filter((item) => {
      if (
        format(new Date(item.range.startDate), dateFormat) == format(new Date(dateRange.startDate), dateFormat) && 
        format(new Date(item.range.endDate), dateFormat) == format(new Date(dateRange.endDate), dateFormat)
          ) {
        return item.label
      }
    });

    const selectedRange = checkSelectore ? (checkSelectore.length > 0 ? checkSelectore[0].label : 'Custom') : 'Custom';
    const date_range = checkSelectore ? (checkSelectore.length > 0 ? checkSelectore[0].date_range : 3) : 3; //custom has date_range = 3
    const newSelectedDateRange = dateSidebarOptions.map(item =>
      item.label === 'Custom' && selectedRange === "Custom" ? 
        { ...item, range: () => ({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }) }
        : item
    );
    getDate(e, {startDate: dateRange.startDate, endDate: dateRange.endDate, displayDate: formatDispayDateRange, selectedRange: selectedRange, selectedRange, date_range: date_range});
    setDateSidebarOptions(newSelectedDateRange);
  };

  return(
    <div className="ad-date-range-component">
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={1}
        monthDisplayFormat={'MMMM yyyy'}
        weekdayDisplayFormat={'EEEEEE'}
        ranges={state}
        staticRanges={staticRanges}
        direction="horizontal"
        color=" #3257FB"
        maxDate={defineds.startOfYesterday}
      />

      <div className="ad-daterange-actions text-right mt-3 mb-2">
        <Button
          className="in-btn-lg in-btn-secondary cancle"
          onClick={(e) => closeCalender(e)}
        >
          Cancel
        </Button>
        <Button
          className="in-btn-lg in-btn-primary ml-3 mr-3 apply"
          onClick={handleDateChange}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default ADCalendar;