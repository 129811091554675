import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Navbar,
  Row, Col, Card, CardBody, Button
} from "reactstrap";
import "./index.scss";
import Loader from '../../components/Loader';
import { useParams, useLocation } from "react-router-dom";
import { organizationActionCreator, offerActionCreator } from "../../actions";
import StreamThumbNail from "../../components/OfferList/CreateOffer/BrandOfferPreivew/StreamThumbNail";
import VideoStreaming from "../../components/OfferList/CreateOffer/VideoStreaming";
import Thumbnails from "../../components/OfferList/CreateOffer/BrandOfferPreivew/Thumbnails";


const BrandPreviewLink = () => {
  let { brand_id, msg_id } = useParams();
  let location = useLocation();
  // const ad_id = new URLSearchParams(location.search).get('ad_id');

  const OrganizationLogo = "/Icon_assets/svg/invisibly-brands-logo.svg";
  const dispatch = useDispatch();
  const msgbodyStyle = { height: '100%', overflow: "auto" };
  const offerData = useSelector((state) => state.offer.offerData);
  const isLoading = useSelector((state) => state.offer.isLoading);
  const tableData = useSelector((state) => state.realtimeResearch.realtimeResearchData);

  let initialThumbnailsCount = 0;
  const [thumbnailsCount, setThumbnailsCount] = useState(initialThumbnailsCount);
  const [nowPlay, setNowPlay] = useState(false);
  const [slideIt, setSlideIt] = useState(false);
  const initialSelectionState = {
    url: offerData.media_1_url,
    type: offerData.media_1_type,
    index: 1
  }
  const [currentSelection, setCurrentSelection] = useState(initialSelectionState);
  const [showTop, setShowTop] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [frame, setFrame] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState("");
  const [shadow, setShadow] = useState("2px 8px 12px rgb(19 36 139 / 4%)");
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");

  const previewMessage = useSelector((state) => state.surveyData.externalPreview);

  const isPreviewFetching = useSelector(
    (state) => state.surveyData.isExternalPreviewLoading
  );

  window.closeSurvey = (exitSurvey) => {
    setSurveyComplete(true);
  }

  const elementScrolling = (scrollingTo, divId) => {
    const element = document.getElementById(divId);
    if (scrollingTo === "top") {
      element.scrollTop = 0;
      setShowTop(false);
    } else if (scrollingTo === "bottom") {
      element.scrollTop = element.scrollHeight - element.clientHeight;
      setShowTop(true);
    }
  }

  const handleCurrentSelection = (e, selectedCurrentUrl, selectedCurrentType, selectedCurrentIndex) => {
    setCurrentSelection({
      ...currentSelection,
      url: selectedCurrentUrl,
      type: selectedCurrentType,
      index: selectedCurrentIndex
    });

    setSlideIt(true);
    setTimeout(() => {
      setSlideIt(false);
    }, 1000);
  }

  const handleThumbnailsScroll = (Scrollstate) => {
    setShowTop(Scrollstate);
  }

  const handleNowPlay = (e) => {
    setNowPlay(true)
  }

  useEffect(() => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }, []);

  useEffect(() => {

    Array(8).fill(null).map((data, index) => {
      if (offerData[`media_${index + 1}_url`] !== undefined && offerData[`media_${index + 1}_url`] !== null) {
        initialThumbnailsCount = initialThumbnailsCount + 1;
      }
    });


    if (offerData?.media_1_type) {
      setCurrentSelection({
        url: offerData.media_1_url,
        type: offerData.media_1_type,
        index: 1
      })
    }

    setThumbnailsCount(initialThumbnailsCount);
    setMessage(offerData.message);
  }, [offerData]);

  const reloadIframe = () => {
    var iframe = document.getElementsByName('frame')[0];
    iframe.srcdoc = iframe.srcdoc;
    setSurveyComplete(false);
  }

  const reload = () => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }

  return (
    <>
      <Navbar light expand="md" className="navigation-section">
        <span className="logo-image-container center-logo">
          <a
            href="https://www.invisibly.com/home"
            target="_blank"
            rel="noreferrer"
          // onClick={navigateToDashboard}
          >
            <img
              src={OrganizationLogo}
              alt="Invisibly for brand logo"
              className="img-fluid"
              style={{ maxWidth: "176px", maxHeight: "28px" }}
            />
          </a>
        </span>
      </Navbar>

      <Row>
        <Col>
          <div id="reload-survey" style={{ marginTop: "20px" }}>
            <img onClick={reload}
              src="/Icon_assets/svg/reload-icon.svg"
              alt="share"
              className="link-image"
            />
            <span className="reload-survey" onClick={reload}>Reload Offer</span>
          </div>
        </Col>
      </Row>

      <div className="brand-offer-card">
        {
          activeStep === 0 ?
            <section style={{ margin: "auto", padding: "10px", cursor: "pointer" }} >
              <Card className="offer-card center-logo">
                <CardBody>
                  <div onClick={() => setActiveStep(1)}>
                    <section className="offer-wrapper">
                      {isLoading ?
                        (<>
                          <CardBody className="feed-card-body is-details-loading">
                            <div className="loading-data-section offer-data-loading">
                              <Loader />
                            </div>
                          </CardBody>
                        </>)
                        :
                        (<>
                          {offerData?.media_1_type === 'stream' &&
                            <div className="card-img"
                              id={`banner${offerData.id}`}>
                              <StreamThumbNail className="card-video-thumbnail"
                                bgSrc={offerData?.media_1_url}
                                children={
                                  (
                                    <>
                                      <img
                                        src="/Icon_assets/svg/video-play.svg"
                                        className="play-btn"
                                      />
                                      <img
                                        src="/Icon_assets/png/arc.png"
                                        style={{
                                          position: "absolute",
                                          bottom: '0px',
                                          width: "100%",
                                        }}
                                        alt="arc"
                                        className="arc-style-img"
                                      />
                                    </>)

                                } />

                            </div>

                          }
                          {currentSelection.type === "image" &&
                            <section className="header-banner-section">
                              <div className="card-img" id={`banner-${offerData.id}`}
                                style={{
                                  backgroundImage: "url('" + currentSelection.url + "')",
                                  backgroundSize: "100%",
                                  maxHeight: "432px",
                                  minHeight: "120px",
                                  backgroundPosition: "center center",
                                  maxWidth: "240px",
                                  height: "135px",
                                  backgroundPostion: "center top"
                                }}>
                                <img src="/Icon_assets/png/arc.png" alt="arc"
                                  className="arc-style-img"
                                  style={{
                                    position: "absolute", bottom: "0px",
                                    width: "100%", left: "0px", display: "none"
                                  }} />
                              </div>
                            </section>}

                          {currentSelection.type === 'video' &&
                            <div
                              className="card-img"
                              id={`banner${offerData.id}`}
                              style={{
                                backgroundImage: `url(/Icon_assets/svg/default-thumbnail.jpg)`,
                                maxHeight: "432px",
                                minHeight: "120px",
                                backgroundPosition: "center center",
                                maxWidth: "240px",
                                height: "135px",
                              }}
                            >
                              <img
                                src="/Icon_assets/svg/video-play.svg"
                                className="play-btn"
                              />
                              <img
                                src="/Icon_assets/png/arc.png"
                                style={{
                                  position: "absolute",
                                  bottom: '0px',
                                  width: "100%",
                                }}
                                alt="arc"
                                className="arc-style-img"
                              />
                            </div>

                          }
                        </>)}
                      <section className="content-section brand-offer"
                        style={{ display: "block" }}>
                        <section className="brand-offer-title brand-offer">
                          <nav className="brand-card-name">{offerData.type === 2 ? `Survey` : `Brand Offer`}
                            <span className="brand-name">{offerData.brand_name}</span>
                          </nav>
                        </section>
                        <nav className="title-part">
                          <p id={`content-title${offerData.id}`}
                            className="title-content">{offerData.title}
                          </p></nav></section>
                    </section>
                    <section className="brand-offer-data-earn-option brand-offer">Earn
                      <img src="/Icon_assets/svg/Invisibly-token-blue-filled.svg"
                        style={{ paddingLeft: "5px" }} alt="Invisibly point" /> {offerData.monetary}
                    </section>
                  </div>
                </CardBody>
              </Card>
            </section>
            :
            activeStep === 1 ?
              <div className="feeds-details-wrapper">
                <section className="feeds-details-section d-flex">
                  <Thumbnails
                    offerDetails={offerData}
                    handleCurrentSelection={handleCurrentSelection}
                    showTop={showTop}
                    elementScrolling={elementScrolling}
                    isLoading={isLoading}
                    currentSelection={currentSelection}
                    thumbnailsCount={thumbnailsCount}
                    handleThumbnailsScroll={handleThumbnailsScroll}
                  />
                  <Card className={`feeds-details-card ${thumbnailsCount <= 1 ? "w-100" : ""} ${offerData.type ? (offerData.type === 1 ? "survey-brand-card brand" : "survey-brand-card survey") : ""}`}>
                    {isLoading ?
                      (<>
                        <CardBody className="feed-card-body is-details-loading">
                          <div className="loading-data-section offer-data-loading">
                            <Loader />
                          </div>
                        </CardBody>
                      </>)
                      :
                      <CardBody className="feed-card-body">
                        <div>
                          <div className={`image-column ${currentSelection.type !== "image" ? "video-column" : ""}`}>
                            <div className={`feeds-image-container ${currentSelection.type !== "stream" && currentSelection.type !== "video" ? "bg-white" : ""}`}>
                              <div className="selected-image-container">
                                <div className={`selected-image-wrapper ${currentSelection.type === "stream" ? "video-stream" : ""}`}>

                                  <div className={`selected-image selected-video ${slideIt ? "slideit" : ""}`}>
                                    {currentSelection.type === "image" ?
                                      <>
                                        <img src={currentSelection.url}
                                          alt={offerData.title} className="selected-feed-image" />
                                      </>
                                      : currentSelection.type === "stream" ?
                                        (<>
                                          <div className="video-stream-wrapper" key={`key` + offerData.id}>
                                            <VideoStreaming
                                              url={currentSelection.url}
                                              autoPlay={(currentSelection.url !== offerData.media_1_url) || nowPlay}
                                            />
                                            {!nowPlay &&
                                              <StreamThumbNail bgSrc={currentSelection.url} className="stream-thumbnail">
                                                <div className="video-icon" onClick={handleNowPlay}>
                                                  <img src={"/Icon_assets/svg/video-play.svg"} alt="Play video" />
                                                </div>
                                              </StreamThumbNail>}
                                          </div>
                                        </>)
                                        : currentSelection.type === "video" ?
                                          (<>
                                            <video width="100%"
                                              controls
                                              autoPlay={(currentSelection.url !== offerData.media_1_url) || nowPlay}
                                              className="selected-feed-video"
                                              id="selected-feed-video"
                                              key={offerData.id + nowPlay}
                                            >
                                              <source src={currentSelection.url} type="video/mp4" />
                                              <source src={currentSelection.url} type="video/ogg" />
                                              Your browser does not support HTML video.
                                            </video>
                                            {(!nowPlay && (
                                              <div
                                                className="stream-thumbnail"
                                                style={{ backgroundImage: `url( "/Icon_assets/svg/default-thumbnail.jpg")` }}
                                              >
                                                <div className="video-icon" onClick={handleNowPlay}>
                                                  <img src={"/Icon_assets/svg/video-play.svg"} alt="Play video" />
                                                </div>
                                              </div>
                                            ))}
                                          </>)
                                          :  //If none of above show image
                                          (offerData?.image ? (<img
                                            src={offerData?.image}
                                            alt={offerData?.title}
                                            className="selected-feed-image"
                                          />)
                                            :
                                            <span className="bg-danger text-white">Format is not supported</span>)
                                    }
                                    < div className="earn-badge-wrapper">
                                      <div className="earn-badge">
                                        <div className={`badge brand`}>
                                          Earn
                                          <img src={"/Icon_assets/svg/Invisibly-token-blue-filled.svg"} alt="survey" className="badge-icon" />
                                          {offerData?.monetary}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-column" id="content-column">
                            <div className="feeds-content-container">
                              <div className="feeds-content-container">
                                <p title=""
                                  className="feeds-category card-text">
                                  <section className="brand-offer-title brand-offer">
                                    <nav className="brand-card-name">Brand Offer
                                      <span className="brand-name">{offerData.brand_name}</span>
                                    </nav>
                                  </section>
                                </p>
                                <h1 title="offer-title" className="feeds-title card-title">{offerData.title}</h1>
                                <p className="feeds-description feeds-description-collapsed free-content survey card-text">{offerData.description}</p>
                                <Button type="button"
                                  // onClick={() => { window.open(offerData.url, "_blank") }}
                                  className="in-btn-sm in-btn-primary in-btn btn-secondary 
                          btn-redirect btn-offer btn btn-secondary">
                                  <img src="/Icon_assets/svg/offer-box.svg" className="offer-box"
                                    style={{ paddingLeft: "5px" }} alt="offer box" />
                                  {offerData.button_text}</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    } </Card>
                </section>
              </div>
              :
              <div>
                <Row>
                  <Col>
                    <div style={{
                      background: "#9d5cff", color: "#fff",
                      fontSize: "18px", height: "15%", paddingTop: "40px",
                      display: surveyComplete ? "none" : "flex",
                    }} className="survey-text">
                      <section className="brand-offer-title brand-offer">
                        <nav className="brand-card-name">Brand Offer
                          <span className="brand-name">{offerData.brand_name}</span>
                        </nav>
                      </section>
                    </div>
                    <div className="iframe-div" style={msgbodyStyle}>
                      {
                        isPreviewFetching ?
                          <Loader />
                          :
                          <>
                            <div className="container" style={{ display: surveyComplete ? "block" : "none" }}>
                              <p className="vertical-center">Congratulations! You have completed the survey.</p>
                            </div>

                            <iframe frameBorder="0" title="previewIframe" name="frame"
                              style={{
                                width: `100%`,
                                height: `100%`,
                                boxShadow: `${shadow}`,
                                minHeight: `620px`,
                                display: surveyComplete ? "none" : "block",
                              }}
                              srcdoc={previewHtml} scrolling='no'>
                            </iframe>
                          </>
                      }
                    </div>
                  </Col>
                </Row>



              </div>
        }
      </div>
    </>
  )
}

export default BrandPreviewLink;