import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isOrgAdded: false,
  isOrgUpdating: false,
  isOrgUpdated: false,
  organizationData: {},
  organizationDataById: {},
  organizationPic: "",
  organizationPicErrors: undefined,
  isUpLoadingPic: false,
  selectedOrganization: JSON.parse(localStorage.getItem('selectedOrganization')) || "",
  selectedRole: localStorage.getItem('selectedRole') || "Admin",
  isOrganizationsInRoleLoading: false,
  organizationsInRole: undefined
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ORGANIZATION_REQUEST:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: true,
        isOrgAdded: false
      };

    case ACTIONS.ADD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: false,
        isOrgAdded: true
      };

    case ACTIONS.ADD_ORGANIZATION_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
        isOrgAdded: false
      };

    case ACTIONS.ADD_ORGANIZATION_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.UPLOAD_ORGANIZATION_PIC_REQUEST:
      return {
        ...state,
        organizationPic: "",
        isUpLoadingPic: true,
        organizationPicErrors: undefined,
      };

    case ACTIONS.UPLOAD_ORGANIZATION_PIC_SUCCESS:
      return {
        ...state,
        organizationPic: action.payload.logo,
        isUpLoadingPic: false,
      };

    case ACTIONS.UPLOAD_ORGANIZATION_PIC_FAILURE:
      return {
        ...state,
        organizationPic: "",
        isUpLoadingPic: false,
        organizationPicErrors: action.payload,
      };

    case ACTIONS.UPLOAD_ORGANIZATION_PIC_FAILURE_CLEAR:
      return {
        ...state,
        organizationPicErrors: undefined,
      };

    case ACTIONS.UPLOAD_ORGANIZATION_PIC_CLEAR:
      return {
        ...state,
        organizationPic: "",
        organizationPicErrors: undefined,
      };

    case ACTIONS.GET_ORGANIZATION_LIST_REQUEST:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: true,
        errorMsg: undefined,
      };

    case ACTIONS.GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_LIST_FAILURE_CLEAR:
      return {
        ...state,
        organizationData: {},
        errorMsg: undefined,
      };

    case ACTIONS.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: true,
      };

    case ACTIONS.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        organizationData: action.payload,
        isOrgUpdating: true,
        isOrgUpdated: false
      };

    case ACTIONS.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
        isOrgUpdating: false,
        isOrgUpdated: true
      };

    case ACTIONS.UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isOrgUpdating: false,
        isOrgUpdated: false
      };

    case ACTIONS.UPDATE_ORGANIZATION_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.SET_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };

    case ACTIONS.REMOVE_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: "",
      };

    case ACTIONS.SET_USER_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };

    case ACTIONS.REMOVE_USER_ROLE:
      return {
        ...state,
        selectedRole: 'Admin',
        selectedOrganization: ''
      };

    case ACTIONS.GET_ORGANIZATION_BYID_SUCCESS:
      return {
        ...state,
        organizationDataById: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_BYID_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_BYID_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.GET_ORGANIZATION_BYID_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: undefined,
        // organizationDataById:{}
      };

    case ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_REQUEST:
      return {
        ...state,
        organizationsInRole: action.payload,
        isOrganizationsInRoleLoading: true,
        errorMsg: undefined,
      };

    case ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_SUCCESS:
      return {
        ...state,
        organizationsInRole: action.payload,
        isOrganizationsInRoleLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isOrganizationsInRoleLoading: false,
      };

    case ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE_CLEAR:
      return {
        ...state,
        organizationsInRole: {},
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default organizationReducer;
