import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import history from "../../history";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  Dropdown,
  DropdownToggle,
  Button,
  NavItem, Alert
} from "reactstrap";
import ROUTES from "../../utils/routeConstants";
import "./index.scss";
import { useDetectOutsideClick } from "./useDetectClickOutside";
import { authActionCreator, realtimeResearchActionCreator, userActionCreator } from "../../actions";
import { organizationActionCreator } from "../../actions";
import OrganizationDropDown from "./organizationDropDown";
import OrganizationSelection from "./OrgnizationSelection";
import RBAC from "../RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";

const HeaderNavigation = () => {

  const isRolesLoading = useSelector((state) => state.user.isRolesLoading);


  const IMAGES = {
    ORG_HEADER: '/Icon_assets/svg/header-organization.svg',
    ADMIN_DROP: '/Icon_assets/svg/dropdown-Icon.svg',
    PROFILE: '/Icon_assets/svg/settings.svg',
    ORG: '/Icon_assets/svg/organization_icon.svg',
    USER: '/Icon_assets/svg/users.svg',
    LOGOUT: '/Icon_assets/svg/logout.svg',
    CONVERSION: '/Icon_assets/svg/conversion.svg',
    BRAND: '/Icon_assets/svg/advertiser_icon.svg',
  }
  const LABEL = {
    ADMIN: 'Admin',
    ORGANIZATION: 'Organization',
    ORGANIZATION_ADMIN: 'Organization Admin'
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = organizationData && organizationData.results && organizationData.results.filter((data) => {
      return data.name.toLowerCase().search(value) != -1;
    });

    var d = [...new Set([...result])]
    setOrgList(d);
  }

  useEffect(() => {
    const roles = localStorage.getItem('roles');
    if (!roles && !isRolesLoading) {
      dispatch(userActionCreator.getUserRolesListRequest());
    }
  }, [])

  const callParentRole = (role) => {
    dispatch(organizationActionCreator.setUserRole(role));
  }

  const [orgList, setOrgList] = useState([]);
  const userData = useSelector((state) => state.auth.userData);
  let userInfo = JSON.parse(localStorage.getItem('user')) || userData;

  const defaultLogo = "/Icon_assets/svg/invisibly-brands-logo.svg";
  const organizationData = useSelector((state) => state.organization.organizationData);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const selectedRole = useSelector((state) => state.organization.selectedRole);
  const errorMsg = useSelector((state) => state.organization.errorMsg);
  const role = userData.organization_role;

  const is_media_enabled = selectedOrganization?.is_media_enabled ? selectedOrganization.is_media_enabled : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? selectedOrganization.is_rtr_enabled : false;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const togglePopOver = () => setPopoverOpen((prevState) => !prevState);
  const [popoverOpen, setPopoverOpen] = useState(false)

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const clickAdminOption = (event) => {
    togglePopOver();
    event.stopPropagation();
  }

  const btnToggle = (event) => {
    document.getElementById("Dropdown").classList.toggle("show");
    setPopoverOpen(false);
    setIsActive(!isActive);
    // set organizationlist
    if (organizationData !== undefined && organizationData.results &&
      organizationData.results.length > 0) {
      setOrgList(organizationData.results);
    }
  }

  const removeUserAndOrganization = () => {
    //localStorage.removeItem('selectedOrganization');
    //localStorage.removeItem('selectedRole');
    // dispatch(organizationActionCreator.removedOrganization());
    // dispatch(organizationActionCreator.removeUserRole());
  }

  const navigateToConversions = (event) => {
    event.preventDefault();
    removeUserAndOrganization();
    history.push(ROUTES.CONVERSION);
  };

  const navigateToOffers = (event) => {
    event.preventDefault();
    removeUserAndOrganization();
    history.push(ROUTES.OFFERS_LIST);
  };

  const navigateToUsers = (event) => {
    event.preventDefault();
    removeUserAndOrganization();
    history.push(ROUTES.USERS_LIST);
  };
  const navigateToOrganizations = (event) => {
    event.preventDefault();
    removeUserAndOrganization();
    history.push(ROUTES.ORGANIZATIONS_LIST);
  };

  const navigateToBrands = (event) => {
    event.preventDefault();
    removeUserAndOrganization();
    history.push(ROUTES.BRANDS_LIST);
  }

  const navigateToProfileSettings = (event) => {
    removeUserAndOrganization();
    event.preventDefault();
    history.push(ROUTES.PROFILE_SETTINGS);
  };

  const navigateToDashboard = (event) => {
    event.preventDefault();
    if (userData.organization_role === 0) {
      history.push(ROUTES.DASHBOARD);
    } else if (userData.organization_role !== 0 && is_media_enabled) {
      history.push(ROUTES.DASHBOARD);
    } else if (userData.organization_role !== 0 && is_rtr_enabled) {
      history.push(ROUTES.REALTIME_RESEARCH);
    }
  };

  const displayHeader = () => {
    return (ROUTES.DASHBOARD === currentPath ||
      ROUTES.REALTIME_RESEARCH === currentPath ||
      ROUTES.REPORT_BUILDER === currentPath ||
      ROUTES.SCHEDULED_REPORTS === currentPath ||
      ROUTES.PROFILE_SETTINGS === currentPath ||
      ROUTES.CONVERSION === currentPath ||
      ROUTES.ORGANIZATIONS_LIST === currentPath ||
      ROUTES.USERS_LIST === currentPath ||
      ROUTES.OFFERS_LIST === currentPath ||
      currentPath === ROUTES.CREATE_ORGANIZATION ||
      currentPath === ROUTES.CREATE_OFFERS ||
      ROUTES.CREATE_CONVERSION_RULE === currentPath ||
      ROUTES.CONVERSION_REPORT_BUILDER === currentPath ||
      ROUTES.REASERACH_CAMPAIGN === currentPath ||
      currentPath.includes('research-campaign/preview/') ||
      currentPath.includes('offers/edit-offer') ||
      currentPath.includes('realtime-research/details') ||
      currentPath.includes('offers/preview') ||
      currentPath.includes('rtr/preview') || currentPath.includes('offers/offer-preview')
      || currentPath.includes('users/add') || currentPath.includes('conversion-rule-list'))
  }

  const logout = () => {
    dispatch(authActionCreator.logOutRequest());
    dispatch(organizationActionCreator.removedOrganization());
    dispatch(organizationActionCreator.removeUserRole());
    dispatch(authActionCreator.resetRBAC());
  };

  const currentPath = window.location.pathname;

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(organizationActionCreator.getOrganizationListFailureClear());
      }, 3000);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (organizationData !== undefined && organizationData.results &&
      organizationData.results.length > 0) {
      setOrgList(organizationData.results);
    }
  }, [organizationData]);

  /* useEffect(() => {
     window.addEventListener('storage', () => {
       const role = localStorage.getItem('selectedRole')
       const org = localStorage.getItem('selectedOrganization');
       userInfo = JSON.parse(localStorage.getItem('user'))
       if (org === null || role === null) {
         dispatch(organizationActionCreator.setUserRole(LABEL.ADMIN));
         dispatch(organizationActionCreator.setdOrganization(""));
         localStorage.removeItem('selectedOrganization');
         localStorage.removeItem('selectedRole');
       }
     });
   }, []);*/

  const handleOrganizationChange = (e) => {
    e.preventDefault();
    dispatch(organizationActionCreator.setUserRole(LABEL.ADMIN));
    removeUserAndOrganization();
    if (currentPath === ROUTES.REALTIME_RESEARCH) {
      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({}));
    }
  }

  return (
    <>
      {/*
        selectedOrganization &&
        (ROUTES.DASHBOARD === currentPath ||
          ROUTES.REALTIME_RESEARCH === currentPath ||
          ROUTES.REPORT_BUILDER === currentPath ||
          ROUTES.SCHEDULED_REPORTS === currentPath ||
          ROUTES.REASERACH_CAMPAIGN === currentPath ||
          currentPath.includes('research-campaign/preview/') ||
          currentPath.includes('realtime-research/details')) &&
        <div id="org-header">
          {/** 
           * If the logged-in user is SuperAdmin/InvisiblyAdmin/organization_role: 0 Show the current selected organization view
           * If the logged-in user is Non-Invisibly Admin and has more than 1 organization Show the current(default selected) selected organization view
         }
          {(userInfo.organizations.length > 1 || role === 0) && (<>
            <Alert color="primary" fade={false}>
              <img src={selectedOrganization.logo || IMAGES.ORG_HEADER} alt="org" style={{ maxHeight: '24px' }} className="dropdown-image" />
              <span title={selectedOrganization.name} className="org-span">{`Organizations view: ${selectedOrganization.name} `}</span>
              {role === 0 && (<>
                <a href="#" className="org-link"
                  onClick={handleOrganizationChange}>
                  Back to Admin
                </a>
              </>)}
            </Alert>
          </>)}
        </div>
     */ }
      <Navbar light expand="md" className="navigation-section">
        <div className="logo-image-container">
          <a
            href="https://www.invisibly.com/home"
            target="_blank"
            rel="noreferrer"
            onClick={navigateToDashboard}
          >
            <div style={{
              maxWidth: "166px", minWidth: "166px", height: "40px", backgroundRepeat: "no-repeat", backgroundSize: '100% 100%',
              backgroundImage: `url(${selectedOrganization?.logo ? selectedOrganization?.logo : defaultLogo})`
            }}>
            </div>
            {
              Object.keys(selectedOrganization).length !== 0 && selectedOrganization?.logo.substring(selectedOrganization?.logo.lastIndexOf('/') + 1).toLowerCase() !== "default_third_party_org_logo.png".toLowerCase()
              && <img
                src="/Icon_assets/svg/Powered by Invisibly.svg"
                alt="powered by inivisbly"
                className=""
                style={{ display: "block", marginTop: "4px" }}
              />
            }
          </a>
        </div>
        <section className="d-md-block d-lg-block dashboard-nav">
          {displayHeader ?
            //Dashboard only (Media/RTR) top navigation
            userData.organization_role === 0 ?
              //Admin  Access only
              (<Nav navbar className="navigation">
                <NavItem>
                  <NavLink to={ROUTES.DASHBOARD} className={`nav-link ${currentPath.includes(ROUTES.REPORT_BUILDER) ? 'active-nav' : ''}`} activeClassName="active-nav">
                    <span>Media Dashboard</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={ROUTES.REALTIME_RESEARCH} className={`nav-link ${(currentPath.includes('research-campaign/preview/') ||
                    currentPath.includes('rtr/preview')) ? 'active-nav' : ''}`} activeClassName="active-nav">
                    <span>Research Dashboard</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={ROUTES.OFFERS_LIST} className={`nav-link ${currentPath === ROUTES.OFFERS_LIST ? 'active-nav' : ''}`} activeClassName="active-nav">
                    <span>Offers Dashboard</span>
                  </NavLink>
                </NavItem>

              </Nav>) :
              //User with limited access
              (<Nav navbar className="navigation">
                {is_media_enabled && (
                  <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ} >
                    <NavItem>
                      <NavLink to={ROUTES.DASHBOARD} className={`nav-link ${ROUTES.REPORT_BUILDER === currentPath ? 'active-nav' : ''}`} activeClassName="active-nav">
                        <span>Media Dashboard</span>
                      </NavLink>
                    </NavItem>
                  </RBAC>

                )}
                {is_rtr_enabled && (<>
                  <RBAC requiredPermissions={PERMISSIONS.BUILD_RTR_READ} >
                    <NavItem>
                      <NavLink to={ROUTES.REALTIME_RESEARCH} className={`nav-link ${(currentPath.includes('research-campaign/preview/') ||
                        currentPath.includes('rtr/preview')) ? 'active-nav' : ''}`} activeClassName="active-nav">
                        <span>Research Dashboard</span>
                      </NavLink>
                    </NavItem>
                  </RBAC>

                  <RBAC requiredPermissions={PERMISSIONS.OFFER_READ} >
                    <NavItem>
                      <NavLink to={ROUTES.OFFERS_LIST} className="nav-link" activeClassName="active-nav">
                        <span>Offers Dashboard</span>
                      </NavLink>
                    </NavItem>
                  </RBAC>
                </>)}
              </Nav>) : ""

          }

        </section>


        <Nav className="ml-auto" navbar>
          <OrganizationSelection />
          <Dropdown id={"dropdown"} isOpen={dropdownOpen} toggle={toggle} ref={dropdownRef} style={{ background: "#fff" }}>
            <DropdownToggle className="user-image-container" onClick={btnToggle}>
              <img
                src={
                  userInfo.profile_pic
                    ? userInfo.profile_pic
                    : "/Icon_assets/svg/no-photo.png"
                }
                alt="User"
                className="user-image"
              />
              <span className="name-class" title={userInfo.name}>
                {userInfo.name}
              </span>

            </DropdownToggle>
            <div id="Dropdown" tabIndex="-1" role="menu" aria-hidden="false" className="dropdownmenu-content dropdown-menu dropdown-menu-right"
              style={{ position: "absolute", willChange: "transform", top: "0px", left: "0px", transform: "translate3d(46px, 48px, 0px)" }}
              x-placement="bottom-end">
              {/*
              role === 0 && (ROUTES.DASHBOARD === currentPath || ROUTES.REALTIME_RESEARCH === currentPath) &&
                <>
                  <Button type="button" tabIndex="0" role="menuitem"
                    className="dropdown-item" id='popover-admin'
                    onClick={(event) => clickAdminOption(event)}>
                    {`View: ${selectedRole === LABEL.ADMIN ? LABEL.ADMIN : LABEL.ORGANIZATION}`}
                    <img src={IMAGES.ADMIN_DROP} alt="icon" className={selectedRole === LABEL.ADMIN ? 'arrow-margin' : 'arrow-org'} />
                  </Button>
                  <div tabIndex="-1" className="dropdown-divider"></div>
                </>
              */}
              <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToProfileSettings(event)}>
                <img src={IMAGES.PROFILE} alt="settings" className="dropdown-image" />
                Profile Settings
              </Button>

              {(role === 0) && (
                <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToOrganizations(event)}>
                  <img src={IMAGES.ORG} alt="Organizations" className="dropdown-image" />
                  Organizations
                </Button>
              )}

              <RBAC requiredPermissions={PERMISSIONS.MANAGE_BRAND_READ}>
                <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToBrands(event)}>
                  <img src={IMAGES.BRAND} alt="Brands" className="dropdown-image" />
                  Advertisers
                </Button>
              </RBAC>

              <RBAC requiredPermissions={PERMISSIONS.MANAGE_USER_READ}>
                <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToUsers(event)}>
                  <img src={IMAGES.USER} alt="users" className="dropdown-image" />
                  Users
                </Button>
              </RBAC>

              {(role !== 0) && is_rtr_enabled && (<>
                <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                  <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToConversions(event)}>
                    <img src={IMAGES.CONVERSION} alt="conversions" className="dropdown-image" />
                    Conversions
                  </Button>
                </RBAC>
              </>)}

              {(role === 0) && (<>
                <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                  <Button type="button" tabIndex="0" role="menuitem" className="dropdown-item" onClick={(event) => navigateToConversions(event)}>
                    <img src={IMAGES.CONVERSION} alt="conversions" className="dropdown-image" />
                    Conversions
                  </Button>
                </RBAC>
              </>)}

              <div tabIndex="-1" className="dropdown-divider"></div>
              <Button type="button" tabIndex="0" role="menuitem" className="logout-button dropdown-item" onClick={(event) => logout(event)}>
                <img src={IMAGES.LOGOUT} alt="logout" className="dropdown-image" />
                Logout
              </Button>
            </div>
          </Dropdown>
        </Nav>
        {
          role === 0 && (ROUTES.DASHBOARD === currentPath || ROUTES.REALTIME_RESEARCH === currentPath) &&
          <OrganizationDropDown
            organizationList={orgList}
            selectedRole={selectedRole}
            popoverOpen={popoverOpen}
            handleSearchValue={handleSearch}
            isActive={isActive}
            callParentRole={callParentRole}
          />
        }
      </Navbar>
    </>
  );
};

export default HeaderNavigation;
