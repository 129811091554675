import ACTIONS from "../constants/actionTypes";

//const initialState = { color: 'danger', message: '', timeOut: true, time: '3000', isClose: false };
const initialState = {show: false, data: ''};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACTIONS.SET_NOTIFICATION:
        return {
            ...state,
            show: true,
            data: action.payload,
        }
  
      case ACTIONS.RESET_NOTIFICATION:
        return {
            ...state,
            show: false,
            data: ''
        }

      default:
        return state;
    }
  };
  
export default notificationReducer;  