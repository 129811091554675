import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import "../index.scss";
import RBAC from '../../RBAC';
import { PERMISSIONS } from '../../../utils/permissionMatrix';

const OffersOptions = (props) => {
  const statusIcons = {
    STATUS_CHANGE: "/Icon_assets/svg/three_dots_icon.svg",
    STATUS_CHANGE_ACTIVE: "/Icon_assets/svg/three_dots_active_icon.svg",
  }

  const [statusChangeImage, setStatusChangeImage] = useState(statusIcons.STATUS_CHANGE);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="organization-actions-dropdown">
      <DropdownToggle onMouseEnter={() => { setStatusChangeImage(statusIcons.STATUS_CHANGE_ACTIVE) }}
        onMouseLeave={() => { setStatusChangeImage(statusIcons.STATUS_CHANGE) }}>
        <img
          src={statusChangeImage}
          alt="settings"
          className="dropdown-image"
        />
      </DropdownToggle>

      <DropdownMenu>
        <RBAC requiredPermissions={(PERMISSIONS.OFFER_WRITE || PERMISSIONS.OFFER_CLONE)}>
          <DropdownItem onClick={props.onCloneOffer.bind(this, props.id)}><img
            src="/Icon_assets/svg/clone-icon.svg"
            alt="clone"
            className="dropdown-image"
          />
            <span>Clone</span></DropdownItem>
        </RBAC>
        <RBAC requiredPermissions={PERMISSIONS.OFFER_DELETE}>
          <DropdownItem onClick={props.onDeleteOffer.bind(this, props.id)}><img
            src="/Icon_assets/svg/delete-icon.svg"
            alt="delete"
            className="dropdown-image"
          />
            <span>Delete</span></DropdownItem>
        </RBAC>
        <RBAC requiredPermissions={PERMISSIONS.OFFER_WRITE}>
          <DropdownItem onClick={(e) => props.onEdit(e, props.id, props.data)}>
            <img
              src="/Icon_assets/svg/edit_icon.svg"
              alt="edit"
              className="dropdown-image"
            />
            <span>Edit</span></DropdownItem>
        </RBAC>
      </DropdownMenu>
    </Dropdown>
  )
}

export default OffersOptions;