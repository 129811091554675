import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Label,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  Table, Row, Col
} from "reactstrap";
import Loader from "../../Loader";
import { organizationActionCreator } from "../../../actions";


const AccessFilter = (props) => {

  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

  const dispatch = useDispatch();
  const {
    user_role,
    filter,
    toggleOrganizationTypeDropDown,
    name,
    index,
    changeAccessTypeDropDownValue,
    rolesList,
    length,
    filterOrgs,
    accessOrgList,
    handleMultipleOrganization,
    filterIndex,
    removeAccessFilter,
    removeSelectedOrganizations,
    removeAllOrgnizations,
    isOrgLoading
  } = props;

  const rolesDesc = {
    "Admin": "Access to dashboard, RTRs & Offers, reports, organization and user management for all the organizations",
    "Org Admin": "Access to dashboard, reports, RTRs & Offers, organization and user management for the selected organization",
    "Builder": "Access to dashboard, reports, RTR & Offers for the selected organizations",
    "Analyst": "Access to dashboard, reports for the selected organizations"
  };

  const rolesLabel = {
    "Admin": "Invisibly Admin",
    "Org Admin": "Admin",
    "Builder": "Builder",
    "Analyst": "Analyst"
  };

  const SuperAdmin = "Admin"; // "this is invisibly admin"

  const [dropdownAccessTypeOpen, setDropdownAccessTypeOpen] = useState(false);
  // const [accessTypeDropDownValue, setAccessTypeDropDownValue] = useState("Select Access Type");

  const toggleAccessTypeDropDown = () => { setDropdownAccessTypeOpen((prevState) => !prevState); };

  const [organizationTypeDropDownValue, setOrganizationTypeDropDownValue] = useState("Select Organizations");


  const onSelectRole = (e, data, index) => {
    // setAccessTypeDropDownValue(data.name);
    changeAccessTypeDropDownValue(e, data, index);
  }

  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/blue_close_icon.svg"
  }

  const [organizationDropdownOpen, setOrganizationDropdownOpen] = useState(false);
  const toggleOrganizationFilter = () => {
    setOrganizationDropdownOpen(prevState => !prevState);

    if (!organizationDropdownOpen) {
      let role_id = userRbac?.find(rb => rb.organization_id === selectedOrganization.id)?.role?.id;
      dispatch(organizationActionCreator.getOrganizationListRequest({ limit: 50, role_id }));
    } else {
      toggleOrganizationTypeDropDown();
      dispatch(organizationActionCreator.getOrganizationListFailureClear());
    }
  }

  return (
    <div className='user-creation-table user-form-container user-component-form'
      style={{ marginTop: 0 }}>
      <Table className="user-table">
        <Row>
          <Col md={12} sm={12} lg={5}>
            <FormGroup>
              <Dropdown
                isOpen={dropdownAccessTypeOpen}
                toggle={toggleAccessTypeDropDown}
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownAccessTypeOpen}
                  className="dropdown_toggle"
                >
                  <span
                    className="dropdown_title"
                    title={name || "Select Access Type"}
                  >
                    {name || "Select Access Type"}
                  </span>
                  <img
                    src={
                      dropdownAccessTypeOpen
                        ? "/Icon_assets/svg/DropdownIcon.svg"
                        : "/Icon_assets/svg/DropDownOpenIcon.svg"
                    }
                    className="dropdown-img"
                  ></img>
                </DropdownToggle>
                <DropdownMenu>
                  <div key={`access-${index}`} id={`access-drop-${index}`} className="access-dropdown">
                    {rolesList.map((item) => {
                      return (<><DropdownItem
                        onClick={(e) => onSelectRole(e, item, props.index)}
                        className={`${item?.role_id === props?.filter?.role_id ? "dropdown-option-selected" : ""}`}>
                        <h6 className="user-label">{rolesLabel[item.label]}</h6>
                        <span className="user-info" style={{ color: "#8A8E97" }}>
                          {rolesDesc[item.label]}
                        </span>
                        <CustomInput
                          id={item.id}
                          type="radio"
                          label=""
                          className="radio-admin"
                          checked={props?.filter?.name.toLowerCase() === item?.label?.toLowerCase()}
                          onChange={() => { }}
                        />
                      </DropdownItem>
                      </>)
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
              <Label className="floating-heading-label required" style={{ top: "-15px" }}>Access Type</Label>
            </FormGroup>
          </Col>
          {((name !== "Invisibly Admin" && name !== "" && name !== undefined) || length !== 1) &&
            <Col md={12} sm={12} lg={5}>
              <FormGroup>
                <Dropdown
                  key={`DropDown-${index}`}
                  isOpen={organizationDropdownOpen}
                  toggle={toggleOrganizationFilter}
                // onClick={getOrganizationDropdownOptions}
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    className="dropdown_toggle"
                  >
                    <span
                      className="dropdown_title"
                      title={organizationTypeDropDownValue}
                    >
                      {filter && filter.organizations && filter.organizations.length ?
                        filter.organizations.length == 1 ? `${filter.organizations.length} Organization selected`
                          : `${filter && filter.organizations && filter.organizations.length} Organizations selected` : organizationTypeDropDownValue}
                    </span>
                    <img
                      src={
                        organizationDropdownOpen
                          ? "/Icon_assets/svg/DropdownIcon.svg"
                          : "/Icon_assets/svg/DropDownOpenIcon.svg"
                      }
                      className="dropdown-img"
                    ></img>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div key={`organizationDropDown-${index}`} id={`organizationDropDown-${index}`} className="org-dropdown">
                      {
                        !isOrgLoading ? (accessOrgList && accessOrgList.length) ?
                          accessOrgList.map((item) => {
                            return (<>
                              <DropdownItem
                                toggle={false}
                                key={`org-${index}-` + item.id}
                                className={`${filter?.org?.includes(item.id) ? "dropdown-option-selected" : ""}`}>
                                <div className="options-container">
                                  <CustomInput
                                    className={`ad-transparent-checkbox`}
                                    type="checkbox"
                                    name={item.name}
                                    value={item.id}
                                    id={`org-${index}-` + item.id}
                                    label={item.name}
                                    checked={filter?.org?.findIndex(
                                      (element) => element === item.id) > -1 ? true : false
                                    }
                                    onChange={(e) => handleMultipleOrganization(e, item, index)}
                                  />

                                  {filter?.org?.includes(item.id) ? (<img
                                    src={statusIcons.CHECKED_BLUE_ICON}
                                    alt="selected"
                                    className="option-selected"
                                  />) : ""}
                                </div>
                              </DropdownItem></>)
                          }) : <div className="dropdown-item" >No organizations</div> : <Loader style={{ padding: "0" }} />}
                    </div>
                  </DropdownMenu>
                </Dropdown>
                <Label className="floating-heading-label required" style={{ top: "-15px" }}>Organizations</Label>
              </FormGroup>
              <div className="selected-options ad-mt-16">
                {filter.org?.map((item, index) => {
                  return (
                    <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                      {filter.organizations.find(data => data.id === item)?.name}
                      <img
                        src="/Icon_assets/svg/close_icon.svg"
                        alt="close icon"
                        className="ml-2 close-icon"
                        onClick={(e) => removeSelectedOrganizations(e, filter, item)}
                      />
                    </span>
                  );
                })}

                {filter.organizations?.length > 0 ? (<span><Button className="ad-btn-link" onClick={(e) => removeAllOrgnizations(e, filter)}>Clear All</Button></span>) : ""}
              </div>
            </Col>
          }
          {index !== 0 &&
            <Col>
              <img
                onClick={(e) => removeAccessFilter(e, props.index)}
                src={statusIcons.CLOSE_ICON}
                alt={"remove access"} />
            </Col>}
        </Row>
      </Table>
    </div>
  );
}

export default AccessFilter;