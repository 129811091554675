import { takeLatest, call, select, put } from "redux-saga/effects";
import { offerActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function getOfferListRequest(API_URL_PARAM, jwtToken, data) {
  let API_URL = "";
  const param = {};
  param.source = 1;
  param.limit = 100;
  param.type = 1;
  if (data?.organization) {
    param.organization_id = data.organization;
  }

  if (data.limit) {
    param.limit = data.limit;
    param.offset = data.offset;
  }

  if (data?.searchKey) {
    let encodeString = data.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    param.search = encodeString;
  }

  if (data && data.ordering) {
    param.ordering = data && data.sortBy === 'desc' ? '-' + data.ordering : data.ordering;
  }
  if (data?.filters?.organization_id !== undefined && data?.filters?.organization_id !== '') {
    param.organization_id = data?.filters?.organization_id
  }

  if (data?.filters?.brand_id !== undefined && data?.filters?.brand_id !== '') {
    param.brand_id = data?.filters?.brand_id
  }

  if (data.id) {
    API_URL = API_URL_PARAM + "/v1/brand-offers/" + data.id;
  } else
    API_URL = API_URL_PARAM + "/v1/brand-offers";

  return axios({
    url: API_URL,
    method: "GET",
    params: param,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}


function getOfferListByIdRequest(API_URL_PARAM, jwtToken, data) {
  let API_URL = "";
  API_URL = API_URL_PARAM + "/v1/brand-offers/" + data.id;


  return axios({
    url: API_URL,
    method: "GET",
    // params: param,
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function deleteOfferRequest(API_URL_PARAM, jwtToken, data) {
  let API_URL = API_URL_PARAM + "/v1/brand-offers/" + data
  return axios({
    url: API_URL,
    method: "DELETE",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function cloneOfferRequest(API_URL_PARAM, jwtToken, brand_offer_id) {
  let API_URL = API_URL_PARAM + "/v1/brand-offers/" + brand_offer_id + "/clone"
  return axios({
    url: API_URL,
    method: "POST",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* cloneOfferRequestSaga(action) {
  const API_URL = yield select((state) => state.config.API_URL);
  const userData = yield select((state) => state.auth.userData);
  let jwtToken = userData && userData.token ? userData.token : null;
  const result = yield call(
    cloneOfferRequest,
    API_URL,
    jwtToken,
    action?.payload
  );

  try {
    const { item, status } = result.data;
    if (status?.status_type?.toUpperCase() === "SUCCESS") {
      yield put(offerActionCreator.cloneOfferSuccess(item));
    }
    else {
      yield put(offerActionCreator.cloneOfferFailure(status?.status_message));
    }
  }
  catch {
    yield put(offerActionCreator.cloneOfferFailure("Server Error"));
  }
}

function* deleteOfferRequestSaga(action) {
  const API_URL = yield select((state) => state.config.API_URL);
  const userData = yield select((state) => state.auth.userData);
  let jwtToken = userData && userData.token ? userData.token : null;
  const result = yield call(
    deleteOfferRequest,
    API_URL,
    jwtToken,
    action?.payload
  );

  try {
    const status = result?.data?.status;
    if (status?.status_type?.toUpperCase() === "SUCCESS") {
      yield put(offerActionCreator.deleteOfferSuccess(status?.status_type));
    }
    else {
      yield put(offerActionCreator.deleteOfferFailure(status?.status_message));
    }
  }
  catch {
    yield put(offerActionCreator.deleteOfferFailure("Server Error"));
  }
}


function* sendOfferListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getOfferListRequest,
      API_URL,
      jwtToken,
      data
    );
    const { item, status } = result.data;
    if (status.status_type && status.status_type.toUpperCase() === "SUCCESS" ) {
      yield put(offerActionCreator.getOfferListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(offerActionCreator.getOfferListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(offerActionCreator.getOfferListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(offerActionCreator.getOfferListFailure("Server Error"));
  }

}


function createBrandOfferRequest(API_URL_PARAM, jwtToken, data) {
  let API_URL = API_URL_PARAM + "/v1/brand-offers";
  if (data.data_id) {
    API_URL = API_URL_PARAM + "/v1/brand-offers/" + data.data_id;
  }

  return axios({
    method: data.data_id ? "PUT" : "POST",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
    },
    data: data,
  }).catch((err) => {
    console.log(err);
  });
}


function* createOfferRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(createBrandOfferRequest, API_URL, jwtToken, data);
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(offerActionCreator.createBrandOfferSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: !data?.offer_name ? "Offer state updated successfully" : status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} : ${err[1]}`;
          else return `${err[0]} : ${err[1]}`;
        });
        yield put(offerActionCreator.createBrandOfferFailure(errorMsg.join()));
        window.scrollTo(0, 0);
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join("\n")
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(offerActionCreator.createBrandOfferFailure(errorMsg));
        window.scrollTo(0, 0);
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    window.scrollTo(0, 0);
    yield put(offerActionCreator.createBrandOfferFailure("Server Error"));
  }
}


function uploadMediaRequest(API_URL, JWTToken, payload) {
  const UPLOAD_API_URL = API_URL + '/v1/brand-offers/upload';

  const formData = new FormData();
  formData.append('file', payload.file);
  return axios({
    method: 'POST',
    url: UPLOAD_API_URL,
    headers: {
      Authorization: JWTToken,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  }).then(response => {
    return response.data;
  });
}

function* uploadFileSaga(action) {
  try {
    // const brand_id = localStorage.getItem('brandId');
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      uploadMediaRequest,
      API_URL,
      jwtToken,
      action.payload
    );

    const { item, status } = result;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(offerActionCreator.uploadMediaSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]} - ${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(offerActionCreator.uploadMediaFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(offerActionCreator.uploadMediaFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(offerActionCreator.uploadMediaFailure("Server Error"));
  }
}


function* sendOfferListByIdRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const dataLoad = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getOfferListByIdRequest,
      API_URL,
      jwtToken,
      dataLoad
    );
    const { data, status } = result;
    if (status === 200) {
      yield put(offerActionCreator.getOfferListIdSuccess(data));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(offerActionCreator.getOfferListByIdFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(offerActionCreator.getOfferListByIdFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(offerActionCreator.getOfferListByIdFailure("Server Error"));
  }

}



export default function* offerSaga() {
  yield takeLatest(ACTIONS.GET_OFFER_LIST_REQUEST, sendOfferListRequestSaga);
  yield takeLatest(ACTIONS.POST_OFFER_CREATION_REQUEST, createOfferRequestSaga);
  yield takeLatest(ACTIONS.CLONE_OFFER_REQUEST, cloneOfferRequestSaga);
  yield takeLatest(ACTIONS.DELETE_OFFER_REQUEST, deleteOfferRequestSaga);
  yield takeLatest(ACTIONS.GET_UPLOAD_MEDIA_REQUEST, uploadFileSaga);
  yield takeLatest(ACTIONS.GET_OFFER_LIST_BY_ID_REQUEST, sendOfferListByIdRequestSaga);

}