import ACTIONS from "../constants/actionTypes";

export const addOrganizationRequest = (data) => {
  return {
    type: ACTIONS.ADD_ORGANIZATION_REQUEST,
    payload: data,
  };
};

export const addOrganizationSuccess = (data) => {
  return {
    type: ACTIONS.ADD_ORGANIZATION_SUCCESS,
    payload: data,
  };
};

export const addOrganizationFailure = (errors) => {
  return {
    type: ACTIONS.ADD_ORGANIZATION_FAILURE,
    payload: errors,
  };
};

export const addOrganizationFailureClear = () => {
  return {
    type: ACTIONS.ADD_ORGANIZATION_FAILURE_CLEAR,
  };
};

export const getOrganizationListRequest = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_LIST_REQUEST,
    payload: data,
  };
};

export const getOrganizationListSuccess = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_LIST_SUCCESS,
    payload: data,
  };
};

export const getOrganizationListFailure = (errors) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_LIST_FAILURE,
    payload: errors,
  };
};

export const getOrganizationListFailureClear = () => {
  return {
    type: ACTIONS.GET_ORGANIZATION_LIST_FAILURE_CLEAR,
  };
};

export const getOrganizationRequest = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_REQUEST,
    payload: data,
  };
};

export const getOrganizationSuccess = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_SUCCESS,
    payload: data,
  };
};

export const getOrganizationFailure = (errors) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FAILURE,
    payload: errors,
  };
};

export const getOrganizationFailureClear = () => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FAILURE_CLEAR,
  };
};

export const updateOrganizationRequest = (data, id) => {
  return {
    type: ACTIONS.UPDATE_ORGANIZATION_REQUEST,
    payload: data,
    id
  };
};

export const updateOrganizationSuccess = (data) => {
  return {
    type: ACTIONS.UPDATE_ORGANIZATION_SUCCESS,
    payload: data,
  };
};

export const updateOrganizationFailure = (errors) => {
  return {
    type: ACTIONS.UPDATE_ORGANIZATION_FAILURE,
    payload: errors,
  };
};

export const updateOrganizationFailureClear = () => {
  return {
    type: ACTIONS.UPDATE_ORGANIZATION_FAILURE_CLEAR,
  };
};

export const uploadOrganizationPic = (data) => {
  return {
    type: ACTIONS.UPLOAD_ORGANIZATION_PIC_REQUEST,
    payload: data,
  };
};

export const uploadOrganizationPicSuccess = (data) => {
  return {
    type: ACTIONS.UPLOAD_ORGANIZATION_PIC_SUCCESS,
    payload: data,
  };
};

export const uploadOrganizationPicFailure = (errors) => {
  return {
    type: ACTIONS.UPLOAD_ORGANIZATION_PIC_FAILURE,
    payload: errors,
  };
};

export const uploadOrganizationPicFailureClear = () => {
  return {
    type: ACTIONS.UPLOAD_ORGANIZATION_PIC_FAILURE_CLEAR,
  };
};

export const uploadOrganizationPicClear = () => {
  return {
    type: ACTIONS.UPLOAD_ORGANIZATION_PIC_CLEAR,
  };
};

export const setdOrganization = (data) => {
  return {
    type: ACTIONS.SET_ORGANIZATION,
    payload: data,
  };
};

export const removedOrganization = (data) => {
  return {
    type: ACTIONS.REMOVE_ORGANIZATION,
    payload: data,
  };
};

export const setUserRole = (data) => {
  return {
    type: ACTIONS.SET_USER_ROLE,
    payload: data,
  };
};

export const removeUserRole = (data) => {
  return {
    type: ACTIONS.REMOVE_USER_ROLE,
    payload: data,
  };
};

export const getOrganizationByIdSuccess = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_BYID_SUCCESS,
    payload: data,
  };
};

export const getOrganizationByIdFailure = (errors) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_BYID_FAILURE,
    payload: errors,
  };
};

export const getOrganizationByIdFailureClear = () => {
  return {
    type: ACTIONS.GET_ORGANIZATION_BYID_FAILURE_CLEAR,
  };
};

export const getOrganizationByIdRequest = () => {
  return {
    type: ACTIONS.GET_ORGANIZATION_BYID_REQUEST,
  };
};

export const getOrganizationForRoleListRequest = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_REQUEST,
    payload: data,
  };
};

export const getOrganizationForRoleListSuccess = (data) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_SUCCESS,
    payload: data,
  };
};

export const getOrganizationForRoleListFailure = (errors) => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE,
    payload: errors,
  };
};

export const getOrganizationForRoleListFailureClear = () => {
  return {
    type: ACTIONS.GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE_CLEAR,
  };
};