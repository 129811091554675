import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Table,
  Button,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";
import HeaderNavigation from "../../components/HeaderNavigation";
import {
  advertiserActionCreator,
  dashboardActionCreator,
  organizationActionCreator,
  notificationActionCreator
} from "../../actions/index.js";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import {
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
} from "reactstrap";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  format,
  startOfQuarter,
  subDays,
  startOfWeek,
  endOfWeek,
  subQuarters,
  endOfQuarter
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ROUTES from "../../utils/routeConstants";
import CampaignTable from "../../components/Campaign/CampaignTable";
import SideNavigation from "../../components/SideNavigation";
import "./index.scss";
import history from "../../history";
import Notifications from "../../components/Notifications";
import SkeletonSection from "../../components/Loader_Skeleton/SkeletonSection";
import SkeletonTable from "../../components/Loader_Skeleton/SkeletonTable";
import SkeletonGraph from "../../components/Loader_Skeleton/SkeletonGraph";
import RBAC from "../../components/RBAC";
import { PERMISSIONS } from "../../utils/permissionMatrix";
charts(FusionCharts);

const Dashboard = () => {
  const ICONS = {
    SEARCH: "/Icon_assets/svg/search-organization-icon.svg",
    SEARCH_ACTIVE: "/Icon_assets/svg/search_organization_active.svg"
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const headers = ["Flight dates", "Spend", "Impressions", "Clicks", "CPA", "CPC", "CPM", "CTR", "Conversions", "100% Complete", "100% Completion Rate", "Viewability"];
  const [selectedCol, setSelectedCol] = useState(JSON.parse(localStorage.getItem('CampaignTableSelection')) || headers);
  const isReportBuilderEnabled = useSelector((state) => state.config.ENABLE_REPORT_BUILDER);
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ?  true : false ;
  var hiddenLeg = ["Spend", "Clicks", "Conversions"];
  /* Spend,Clicks Grpah Implementation Start*/
  // Adding license key for Fusion chart
  FusionCharts.options.license({
    key:
      "kJ-7B9snlC4E3A3G2B1B4B1C6D7B3F1F2gzzB2A6D2zE1A2E1oreC7E2B4cetH3C10D7gyF-10D3E2D2H4B2C8D2C5B2C1C1I-8vvB1E6ED1fbqA32B13B9d1sB4TF4D3iizA5C5E3C5C4A3D3A2B9A6C3F5E4F1i1==",
    creditLabel: false,
  });

  //Get Graph API Data
  const spendClicksConversionData = useSelector(
    (state) => state.dashboard.spendClicksConversionData
  );

  const TOOLTIP_LABELS = {
    CTR:'Rate of clicks per number of impressions.',
    CPA:'Average cost of a user converting during a campaign.',
  };

  const commaSeparatedData = (data) => {
    var x = (data.toString().indexOf('.') > 0) ? data.toFixed(2) : data;
    var amount = x.toString();
    var delimiter = ","; // replace comma if desired
    var a = amount.split('.', 2)
    var d = a[1];
    var i = parseInt(a[0]);
    if (isNaN(i)) { console.log('') }
    var minus = '';
    if (i < 0) { minus = '-'; }
    i = Math.abs(i);
    var n = new String(i);
    var a = [];
    while (n.length > 3) {
      var nn = n.substr(n.length - 3);
      a.unshift(nn);
      n = n.substr(0, n.length - 3);
    }
    if (n.length > 0) { a.unshift(n); }
    n = a.join(delimiter);
    if (d == undefined) { amount = n; }
    else { amount = n + '.' + d; }
    amount = minus + amount;
    return (amount)
  }

  const getFormattedDate = (day) => {
    let options = { month: "short", day: "numeric" };
    let givenDate = new Date(day);
    return givenDate.toLocaleDateString("en-US", options);
  }
  const spend = spendClicksConversionData.map((item) => {
    return { value: item.Spend, label: getFormattedDate(item.day) };
  });

  const clicks = spendClicksConversionData.map((item) => {
    return { value: item.Clicks, label: getFormattedDate(item.day) };
  });

  const conversion = spendClicksConversionData.map((item) => {
    return { value: item.Conversion,label: getFormattedDate(item.day) };
  });

  //calculate max value for Y-axis
  const maxValueOfClicks = Math.max(...clicks.map(c => c.value), 0);
  const maxValueOfConversion = Math.max(...conversion.map(c => c.value), 0);
  const syAxisMaxValue = (maxValueOfClicks > maxValueOfConversion) ? maxValueOfClicks : maxValueOfConversion;

  const date = spendClicksConversionData.map((item) => {
    return { label: getFormattedDate(item.day) };
  });

  const chartConfig = {
    caption: "",
    captionAlignment: "top-left",
    subCaption: "",
    xAxisname: "",
    pYAxisName: "Spend",
    sYAxisName: "Clicks & Conversion",
    numberprefix: "$",
    primaryAxisOnLeft: "0",
    theme: "fusion",

    showCanvasBorder: 0,
    canvasbgColor: "#ffffff",
    canvasBgAngle: 0,
    canvasbgColor: "#FFFFFF",
    canvasbgAlpha: "10",
    showBorder: 0,
    bgColor: "#ffffff",
    bgAlpha: "0",

    canvasLeftPadding: "0",
    canvasTopPadding: "15",
    canvasBottomPadding: "35",
    yAxisNamePadding: "9",
    yAxisValuesPadding: "18",

    pYAxisNameFontSize: "18",
    pYAxisNameFontColor: "#535559",
    pYAxisNameFontBold: "0",
    sYAxisNameFontSize: "18",
    sYAxisNameFontColor: "#535559",
    sYAxisNameFontBold: "0",

    labelFontColor: "#8A8E97",
    labelFontSize: "12",
    labelFontBold: "0",
    pYAxislabelFontSize: "12",
    sYAxislabelFontSize: "12",
    pYAxislabelFontColor: "#8A8E97",
    sYAxislabelFontColor: "#8A8E97",
    divLineColor: "#EFEFEF",
    divLineAlpha: 90,

    showAlternateHGridColor: "0",
    bgColor: "#eeeeee",
    tooltipPosition: "top",
    toolTipColor: "#535559",
    toolTipBorderThickness: "0",
    toolTipBgColor: "#FFFFFF",
    toolTipBgAlpha: "80",
    toolTipBorderRadius: "4",
    toolTipPadding: "5",
    showToolTipShadow: "1",
    toolTipBgAlpha: "80",
    showAlternateHGridColor: "0",
    canvasPadding: 10,

    drawAnchors: "0",
    legendPosition: "top-right",
    palettecolors: "17D7A9,F7698B,F2994A",
    renderAt: "chart-container",
    legenditemfontsize: "16",
    legenditemfontbold: "0",
    legendIconAlpha: "50",
    legendBgAlpha: "30",
    legendBorderAlpha: "0",
    legendShadow: "0",
    legendItemFontColor: "#6A6E74",
    drawCustomLegendIcon: "1",
    legendIconBorderThickness: "1",
    legendIconSides: "1",
    legendNumRows: "3",
    legendNumColumns: "3",


    numDivLines: '3',
    adjustDiv: '0',
    yaxisminvalue: "0",
    formatnumberscale: "0",
    formatnumber: "0",
    drawCrossLine: 0,
    crossLineColor: "#F6698B",
    crossLineAnimation: 1,
    crossLineAnimationDuration: "0.7", // to confirm
    crossLineAlpha: 30,
    showHoverEffect: 1,
    anchorHoverEffect: 1,
    anchorHoverAnimation: 1,
    // hover effect on data point
    anchorBgHoverColor: "#ffffff",
    anchorBorderHoverThickness: "5",
    anchorHoverRadius: "8",
    anchorBgAlpha: 100,
    drawAnchors: "1",
    anchorRadius: "1",
    anchorBorderThickness: "5",
    anchorSides: "0",
    anchorBgColor: "#ffffff",
    showChartLoadingMessage: 1,

    formatnumber: "1",
    formatnumberscale: "1",

    transposeAxis: "1",
    syAxisMaxValue: syAxisMaxValue + 10,
    formatnumberscale: "0",
    sformatnumberscale: "1",
    minimizeTendency: '1',
    plotcolorintooltip: "1",
    plottooltext: "<div id='divTable' class='div-table'>$dataValue</div>",
  };

  const dataSource = {
    chart: chartConfig,
    categories: [
      {
        category: date,
      },
    ],
    dataset: [
      {
        // add properties specific to a data.
        anchorBgColor: "#17D7A9",
        seriesName: "Spend",
        renderAs: "spline",
        dashed: "1",
        showValues: "0",
        anchorBorderColor: "#17D7A9",
        data: spend,
        anchorBorderThickness: 2,
        anchorRadius: 4,
        crossLineColor: "#17D7A9"
      },
      {
        seriesName: "Clicks",
        parentYAxis: "S",
        renderAs: "spline",
        showValues: "0",
        anchorBgColor: "#F7698B",
        data: clicks,
        anchorBorderThickness: 2,
        anchorRadius: 4,
      },
      {
        seriesName: "Conversions",
        parentYAxis: "S",
        renderAs: "spline",
        showValues: "0",
        anchorBgColor: "#F2994A",
        data: conversion,
        anchorBorderThickness: 2,
        anchorRadius: 4,
      },
    ],
  };

  const events = {
    "legendItemClicked": function (eventObj, dataObj) {
      // Create a new array based on current state:
      if (!dataObj.visible) {
        hiddenLeg = hiddenLeg.filter(function (item) { return item !== dataObj.datasetName })
      }
      else if (dataObj.visible) {
        hiddenLeg.push(dataObj.datasetName);
      }
    },
    "dataPlotRollOver": function (eventObj, dataObj) {

      let chart = eventObj.sender,
        dataSource = chart.getJSONData();
      // get all the datesets
      var dataResult = dataSource.dataset.map(function (item) { return item.data });
      var lbl1 = "$" + commaSeparatedData(dataResult[0].filter(function (item) { return item.label === dataObj.categoryLabel })[0].value)
      var lbl2 = commaSeparatedData(dataResult[1].filter(function (item) { return item.label === dataObj.categoryLabel })[0].value)
      var lbl3 = commaSeparatedData(dataResult[2].filter(function (item) { return item.label === dataObj.categoryLabel })[0].value)


      var tooltipHtml = "<div class='table-div'><span class='data-label'>" + dataObj.categoryLabel + "</span><table class='tooltip-table'>";
      // populate tooltip conditionally based on legends getting displayed.
      for (var i in hiddenLeg) {
        if (hiddenLeg[i] === "Spend")
          tooltipHtml += "<tr><td><div class='dot1'></div></td><td class='data-value'><span>" + lbl1 + "</span></td></tr>"
        else if (hiddenLeg[i] === "Clicks")
          tooltipHtml += "<tr><td><div class='dot2'></div></td><td class='data-value'><span>" + lbl2 + "</span></td></tr>"
        else
          tooltipHtml += "<tr><td><div class='dot3'></div></td><td class='data-value'><span>" + lbl3 + "</span></td></tr>"
        console.log(hiddenLeg[i]);

      }
      tooltipHtml += "</table></div>"

      var dataDiv = document.getElementById('divTable');
      dataDiv.innerHTML = hiddenLeg.length === 0 ?
        "<div class='table-div'><span class='data-label'>" + dataObj.categoryLabel + "</span><table class='tooltip-table'><tr><td><div class='dot1'></div></td><td class='data-value'><span>" + lbl1 + "</span></td></tr><tr><td><div class='dot2'></div></td><td class='data-value'><span>" + lbl2 + "</span></td></tr><tr><td><div class='dot3'></div></td><td class='data-value'><span>" + lbl3 + "</span></td></tr></table></div>"
        : tooltipHtml;
      chart.drawCrossline(true);
    }
  }
  /* Spend,Clicks Grpah Implementation Ends*/
  const [valueState, setvalueState] = useState({});
  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

  const toggleTool = (targetName) => {
    if (!valueState[targetName]) {
      setvalueState({
        ...valueState,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      setvalueState({
        ...valueState,
        [targetName]: {
          tooltipOpen: !valueState[targetName].tooltipOpen,
        },
      });
    }
  };

  const isToolTipOpen = (targetName) => {
    return valueState[targetName] ? valueState[targetName].tooltipOpen : false;
  };

  const dispatch = useDispatch();
  const [totalOffset, setTotalOffset] = useState(0);
  const [adList, setAdList] = useState([]);
  const [dspList, setDspList] = useState([]);
  const [dspLineList, setDspLineList] = useState([]);
  const [adCount, setAdCount] = useState([0]);
  const [dspCount, setDspCount] = useState([0]);
  const [dspLineCount, setDspLineCount] = useState([0]);
  const [allAdsSelected, setAllAdsSelected] = useState(false);
  const [allCampaignSelected, setAllCampaignSelected] = useState(false);
  const [allCampaignLineSelected, setAllCampaignLineSelected] = useState(false);
  const [advertiserDropdownOpen, setAdvertiserDropdownOpen] = useState(false);
  const [campaignDropdownOpen, setCampaignDropdownOpen] = useState(false);
  const [campaignLineDropdownOpen, setCampaignLineDropdownOpen] = useState(
    false
  );

  const [selectedAdvertiserList, updateSelectedAdvertiserList] = useState([]);
  const [
    selectedAdvertiserFilterSet,
    updateSelectedAdvertiserFilterSet,
  ] = useState([]);
  const [selectedCampaignNameList, updateSelectedCampaignNameList] = useState(
    []
  );
  const [
    selectedCampaignNameFilterSet,
    updateSelectedCampaignNameFilterSet,
  ] = useState([]);
  const [selectedCampaignLineList, updateSelectedCampaignLineList] = useState(
    []
  );
  const [
    selectedCampaignLineFilterSet,
    updateSelectedCampaignLineFilterSet,
  ] = useState([]);

  const dateToYMD = (date, dateRange) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    if (dateRange) {
      return "" + (d <= 9 ? "0" + d : d) + " " + months[m - 1] + ", " + y;
    } else {
      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "-" +
        (m <= 9 ? "0" + m : m) +
        "-" +
        y +
        "+" +
        hh +
        ":" +
        mm +
        ":" +
        ss
      );
    }
  };


  const startTime = (startOfDay(subDays(new Date(), 7)));
  const endTime = (startOfDay(addDays(new Date(), 0)));
  const dateValue =
    dateToYMD(startTime, "date-range") +
    " - " +
    dateToYMD(startOfDay(addDays(new Date(), -1)), "date-range");
  // assigned default last 7 days date to start_date,end_date and date Range value
  const [selectedStartTime, updateSelectedStartTime] = useState(dateToYMD(startTime));
  const [selectedEndTime, updateSelectedEndTime] = useState(dateToYMD(endTime));
  const [dateRangeValue, updateDateRangeValue] = useState(dateValue);
  const [showDateRange, selectDateRange] = useState(false);
  const [dateSidebarOptions, setDateSidebarOptions] = useState([
    {
      label: "Custom",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.startOfMonth,
      }),
    },
    {
        label: 'Today',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday
        }) 
      },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: defineds.startOflastWeek,
        endDate: defineds.endOflastWeek,
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: "Month to Date",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: "Quarter to Date",
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last Quarter",
      range: () => ({
        startDate: defineds.startofLastQuarter,
        endDate: defineds.endofLastQuarter,
      }),
    },
  ]);
  const isOrganizationSelected = localStorage.getItem("organizationId");

  const toggleAdvertiserDropdown = () =>{
    setAdvertiserDropdownOpen((prevState) => !prevState);
    if(!advertiserDropdownOpen) {
      setAdList([]);
      setTotalOffset(0);
      setAdCount(0);
      setSearchValue("");
      setExpandedTableColWidth(MAIN_TABLE_COL_MIN_WIDTH);
    }

    // if(advertiserDropdownOpen === true && document.getElementById('resizer') !== null) {
    //   document.getElementById('resizer').remove();
    // }
  }
  const toggleCampaignDropdownOpen = () => {
    setCampaignDropdownOpen((prevState) => !prevState);
    if (!campaignDropdownOpen) {
      setDspList([]);
      setTotalOffset(0);
      setDspCount(0);
      setSearchValue("");
      setExpandedTableColWidth(MAIN_TABLE_COL_MIN_WIDTH);
    }
  }
  const toggleCampaignLineDropdownOpen = () => {
    setCampaignLineDropdownOpen((prevState) => !prevState);
    if(!campaignLineDropdownOpen) {
      setDspLineList([]);
      setTotalOffset(0);
      setDspLineCount(0);
      setSearchValue("");
      setExpandedTableColWidth(MAIN_TABLE_COL_MIN_WIDTH);
    }
  }
  const toggleDateRange = () => selectDateRange((prevState) => !prevState);
  const MAX_LIMIT_REACHED = 30;

  const isAdvertiserRequest = useSelector(
    (state) => state.advertiser.isLoading
  );
  const isDSPCampaingsListRequest = useSelector(
    (state) => state.dashboard.isDSPCampaingsListRequest
  );
  const isDSPCampaingLineListRequest = useSelector(
    (state) => state.dashboard.isDSPCampaingLineListRequest
  );
  const isSpendCtrCpaDataRequest = useSelector(
    (state) => state.dashboard.isSpendCtrCpaDataRequest
  );
  const isSpendClicksConversionRequest = useSelector(
    (state) => state.dashboard.isSpendClicksConversionRequest
  );
  const dashboardDataRequestCompleted = useSelector(
    (state) => state.dashboard.dashboardDataRequestCompleted
  );

  const advertiserData = useSelector(
    (state) => state.advertiser.advertiserData?.results
  );
  const advertiserCount = useSelector(
    (state) => state.advertiser.advertiserData?.count || 0);
  const dspDataCount = useSelector(
    (state) => state.dashboard.dspCampaignsData?.count|| 0);
  const dspLineDataCount = useSelector(
    (state) => state.dashboard.dspCampaignLineData?.count || 0);
  const dspCampaignsData = useSelector(
    (state) => state.dashboard.dspCampaignsData?.results
  );
  const dspCampaignLineData = useSelector(
    (state) => state.dashboard.dspCampaignLineData?.results
  );
  const spendCtrCpcData = useSelector(
    (state) => state.dashboard.spendCtrCpaData
  );

  const dashboardData = useSelector((state) => state.dashboard.dashboardData);
  const formatFloatNumber = (number) => {
    return parseFloat(number).toFixed(2);
  };

  const handleSearch = (evt, elem) => {
    setSearchValue(evt.target.value);
    const val = evt.target.value;
    let params = {};
    if (selectedOrganization) {
      params.organization = selectedOrganization.id;
    }
    switch (elem) {
      case 'advertiser_drop':
        params.limit = 10;
        params.name = val;
        dispatch(advertiserActionCreator.advertisersListRequest(params));
        setAdList([]);
        setAdCount(0);
        setTotalOffset(0);
        break;

      case 'campaign_drop':
        if (selectedAdvertiserList.length && !allAdsSelected) {
          params.advertiserList = selectedAdvertiserList
        }
        params.limit = 20;
        params.name = val;
        dispatch(dashboardActionCreator.getDSPCampaignsListRequest(params));
        setDspList([]);
        setTotalOffset(0);
        setDspCount(0);
        break;

      case 'campaign_line_drop':
        if (selectedCampaignNameList.length && !allCampaignSelected) {
          params.campaigns = selectedCampaignNameList;
        }
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 20;
        params.name = val;
        dispatch(dashboardActionCreator.getDSPCampaignsLineListRequest(params));
        setDspLineList([]);
        setDspLineCount(0);
        setTotalOffset(0);
        break;

      default:
        break;
    }
  }

  const handleSearchCampaign = (evt) => {
    setSearchCampaignValue(evt.target.value);
    const value = evt.target.value.toLowerCase();
    let result = [];
    result = dashboardData && dashboardData.filter((data) => {
      return data.CampaignName.toLowerCase().search(value) != -1;
    });

    var d = [...new Set([...result])]
    setCampaignData(d);
  }

  const getAdvertiserDropdownOptions = () => {
    if (!advertiserDropdownOpen) {
      let params = {};
      if(selectedOrganization) {
        params.organization = selectedOrganization.id;
      }
      params.limit = 10;
      dispatch(advertiserActionCreator.advertisersListRequest(params));
    }
  };

  const getCampaignDropdownOptions = () => {
    if (!campaignDropdownOpen) {
      let params = {};
      if (selectedOrganization) {
        params.organization = selectedOrganization.id;
      }
      if (selectedAdvertiserList.length && !allAdsSelected) {
      params.advertiserList = selectedAdvertiserList
      }
      params.limit = 20;
      dispatch(
        dashboardActionCreator.getDSPCampaignsListRequest(
          params
        )
      );
    }
  };

  const getCampaignLineDropdownOptions = () => {
    if (!campaignLineDropdownOpen) {
      let paramsList = {};
      if (selectedAdvertiserList.length && !allAdsSelected) {
        paramsList.advertisers = selectedAdvertiserList;
      }
      if (selectedCampaignNameList.length && !allCampaignSelected) {
        paramsList.campaigns = selectedCampaignNameList;
      }
      if(selectedOrganization) {
        paramsList.organization = selectedOrganization.id;
      }
      paramsList.limit = 20;
      dispatch(
        dashboardActionCreator.getDSPCampaignsLineListRequest(paramsList)
      );
    }
  };

  const handleAdvertiserCheckboxChange = (
    e,
    advertiser_id,
    filterAdvertiser_id
  ) => {
    setAllAdsSelected(false);
    if (e.target.checked) {
      updateSelectedAdvertiserList((selectedAdvertiserList) => [
        ...selectedAdvertiserList,
        advertiser_id,
      ]);
      updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [
        ...selectedAdvertiserFilterSet,
        filterAdvertiser_id,
      ]);
    } else {
      selectedAdvertiserList.splice(selectedAdvertiserList.indexOf(advertiser_id), 1);
      selectedAdvertiserFilterSet.splice(selectedAdvertiserFilterSet.indexOf(filterAdvertiser_id), 1);
      updateSelectedAdvertiserList((selectedAdvertiserList) => [...selectedAdvertiserList]);
      updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [...selectedAdvertiserFilterSet]);
    }

    //clear child selected dropdown options
    updateSelectedCampaignNameList([]);
    updateSelectedCampaignNameFilterSet([]);
    updateSelectedCampaignLineList([]);
    updateSelectedCampaignLineFilterSet([]);

  };

  const handleCampaignCheckboxChange = (e, campaign_id, filterCampaign_id) => {
    setAllCampaignSelected(false);
    if (e.target.checked) {
      updateSelectedCampaignNameList((selectedCampaignNameList) => [
        ...selectedCampaignNameList,
        campaign_id,
      ]);
      updateSelectedCampaignNameFilterSet((selectedCampaignNameFilterSet) => [
        ...selectedCampaignNameFilterSet,
        filterCampaign_id,
      ]);
    } else {
      selectedCampaignNameList.splice(
        selectedCampaignNameList.indexOf(campaign_id),
        1
      );
      selectedCampaignNameFilterSet.splice(
        selectedCampaignNameFilterSet.indexOf(filterCampaign_id),
        1
      );
      updateSelectedCampaignNameList((selectedCampaignNameList) => [
        ...selectedCampaignNameList,
      ]);
      updateSelectedCampaignNameFilterSet((selectedCampaignNameFilterSet) => [
        ...selectedCampaignNameFilterSet,
      ]);
    }

    //clear child selected dropdown options
    updateSelectedCampaignLineList([]);
    updateSelectedCampaignLineFilterSet([]);

  };

  const handleCampaignLineCheckboxChange = (
    e,
    campaignline_id,
    filterCampaignLine_id
  ) => {
    setAllCampaignLineSelected(false);
    if (e.target.checked) {
      updateSelectedCampaignLineList((selectedCampaignLineList) => [
        ...selectedCampaignLineList,
        campaignline_id,
      ]);
      updateSelectedCampaignLineFilterSet((selectedCampaignLineFilterSet) => [
        ...selectedCampaignLineFilterSet,
        filterCampaignLine_id,
      ]);
    } else {
      selectedCampaignLineList.splice(
        selectedCampaignLineList.indexOf(campaignline_id),
        1
      );
      selectedCampaignLineFilterSet.splice(
        selectedCampaignLineFilterSet.indexOf(filterCampaignLine_id),
        1
      );
      updateSelectedCampaignLineList((selectedCampaignLineList) => [
        ...selectedCampaignLineList,
      ]);
      updateSelectedCampaignLineFilterSet((selectedCampaignLineFilterSet) => [
        ...selectedCampaignLineFilterSet,
      ]);
    }
  };

  const selectedAllCheckbox = (e, dropdownOption) => {
    switch (dropdownOption) {
      case "all_advertiser_options":
        if (e.target.checked) {
          setAllAdsSelected(true);
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserFilterSet([]);
          adList.map((advertiser) => {
            updateSelectedAdvertiserList((selectedAdvertiserList) => [
              ...selectedAdvertiserList,
              advertiser.id,
            ]);
            updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [
              ...selectedAdvertiserFilterSet,
              advertiser.dsp_id,
            ]);
          });
        } else {
          setAllAdsSelected(false);
          updateSelectedAdvertiserList([]);
          updateSelectedAdvertiserFilterSet([]);
        }
        break;
      case "all_campaign_options":
        if (e.target.checked) {
          setAllCampaignSelected(true);
          updateSelectedCampaignNameList([]);
          updateSelectedCampaignNameFilterSet([]);
          dspList.map((campaign) => {
            updateSelectedCampaignNameList((selectedCampaignNameList) => [
              ...selectedCampaignNameList,
              campaign.id,
            ]);
            updateSelectedCampaignNameFilterSet(
              (selectedCampaignNameFilterSet) => [
                ...selectedCampaignNameFilterSet,
                campaign.dsp_id,
              ]
            );
          });
        } else {
          setAllCampaignSelected(false);
          updateSelectedCampaignNameList([]);
          updateSelectedCampaignNameFilterSet([]);
        }
        break;
      case "all_campaign_line_options":
        if (e.target.checked) {
          setAllCampaignLineSelected(true);
          updateSelectedCampaignLineList([]);
          updateSelectedCampaignLineFilterSet([]);
          dspLineList.map((campaignLine) => {
            updateSelectedCampaignLineList((selectedCampaignLineList) => [
              ...selectedCampaignLineList,
              campaignLine.id,
            ]);
            updateSelectedCampaignLineFilterSet(
              (selectedCampaignLineFilterSet) => [
                ...selectedCampaignLineFilterSet,
                campaignLine.dsp_id,
              ]
            );
          });
        } else {
          setAllCampaignLineSelected(false);
          updateSelectedCampaignLineList([]);
          updateSelectedCampaignLineFilterSet([]);
        }
        break;
      default:
        break;
    }
  };

  // always set to last 7 days
  const intialDate = [
    {
      startDate: startOfDay(subDays(new Date(), 7)),
      endDate: startOfDay(addDays(new Date(), -1)),
      key: "selection",
    },
  ];

  const [state, setState] = useState(intialDate);
  const [searchValue, setSearchValue] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [searchImage, setSearchImage] = useState(ICONS.SEARCH);
  const [searchCampaignValue, setSearchCampaignValue] = useState("");
  const [maxLimitSelected,setMaxLimitSelected] = useState(false);

  const handleDateChange = (e) => {
    selectDateRange(false);
    const dateRange = state[0];
    const selectNextEndDate = startOfDay(addDays(dateRange.endDate, +1));
    let dateValue =
      dateToYMD(dateRange.startDate, "date-range") +
      " - " +
      dateToYMD(dateRange.endDate, "date-range");
    updateSelectedStartTime(dateToYMD(dateRange.startDate));
    updateSelectedEndTime(dateToYMD(selectNextEndDate));
    updateDateRangeValue(dateValue);

    /*handle custom date label start*/
    const dateSidebarOptions = sideBarOptions();
    const dateFormat = 'd MMM, yy';
    const checkSelectore = dateSidebarOptions.map((item)=> {
      return {...item, range: item.range() }
    }).filter((item) => {
      if (
          format(new Date(item.range.startDate), dateFormat) == format(new Date(dateRange.startDate), dateFormat) && 
          format(new Date(item.range.endDate), dateFormat) == format(new Date(dateRange.endDate), dateFormat)
          ) {
        return item.label
      }
    });

    const selectedRange = checkSelectore ? (checkSelectore.length > 0 ? checkSelectore[0].label : 'Custom') : 'Custom';
    const newSelectedDateRange = dateSidebarOptions.map(item =>
      item.label === 'Custom' && selectedRange === "Custom" ? 
        { ...item, range: () => ({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }) }
        : item
    );
    setDateSidebarOptions(newSelectedDateRange);
    /*handle custom date label end*/
  };

  /*added listener for scroll */
  const handleAdvertiserScroll = () => {
    setvalueState({});
    var count = adCount;
    var adListCount = adList.length;
    var advertiserDrop = document.getElementById("advertiserDropDown");
    if (advertiserDrop.scrollTop + 50 >= advertiserDrop.scrollHeight - advertiserDrop.clientHeight) {
      if (count > adListCount && adListCount !== 0) {
        // document.getElementById('resizer').style.top = `0px`;
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        if (searchValue) {
          params.name = searchValue;
        }
        dispatch(advertiserActionCreator.advertisersListRequest(params));
      } else {
        return;
      }
    }
  }

  const handleCampaignNameScroll = ()=> {
    setvalueState({});
    var count = dspCount;
    var listCount = dspList.length;
    var campaignNameDrop = document.getElementById("campaignNameDrop");
    if (campaignNameDrop.scrollTop+ 50 >= campaignNameDrop.scrollHeight - campaignNameDrop.clientHeight) {
      if (count > listCount && listCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 20;
        params.offset = totalOffset + 10;
        if (searchValue) {
          params.name = searchValue;
        }
        dispatch(dashboardActionCreator.getDSPCampaignsListRequest(params));
      }
    }
  }

  const handleCampaignLineScroll = ()=> {
    setvalueState({});
    var count = dspLineCount;
    var listCount = dspLineList.length;
    var campaignLineDrop = document.getElementById("campaignLineDrop");
    if (campaignLineDrop.scrollTop + 50 >= campaignLineDrop.scrollHeight - campaignLineDrop.clientHeight) {
      if (count > listCount && listCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 20;
        params.offset = totalOffset + 10;
        if (searchValue) {
          params.name = searchValue;
        }
        dispatch(dashboardActionCreator.getDSPCampaignsLineListRequest(params));
      }
    }
  }

 var thElm;
 var startOffset;
  useEffect(() => {
    var table = document.getElementById("advertiserDrop");
    if (advertiserDropdownOpen) {
       table = document.getElementById("advertiserDrop");
    } 
    if (campaignDropdownOpen) {
       table = document.getElementById("campaignDropDown");
    }

    if (campaignLineDropdownOpen) {
       table = document.getElementById("campaignLineDropDown");
    }
    
    // var tableHeight = table.offsetHeight;
    var resizer = document.getElementById("resizer-col")

    if (advertiserDropdownOpen || campaignDropdownOpen || campaignLineDropdownOpen) {
    Array.prototype.forEach.call(
      table.querySelectorAll(".resized-column"),
      function (th) {
        //th.style.position = 'relative';
        if (document.getElementById('resizer') === null) {
        var grip = document.createElement('div');
        grip.className = 'column-resizer';
        grip.id = 'resizer';
        grip.innerHTML = "&nbsp;&nbsp;";
        grip.style.left = `${expandedTableColWidth-20}px`;
        // grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.float = 'right';
        grip.style.position = 'sticky';
        grip.style.top = '0px';
        grip.style.zIndex = "9999";
        grip.style.cursor = 'col-resize';
       
        grip.addEventListener('mousedown', function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      } else {
        var t = document.getElementById('resizer');
        t.style.left = `${expandedTableColWidth-20}px`;
      }
      });
    // listener for dragging
    table.addEventListener('mousemove', function (e) {
      if (thElm) {
        // if(startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH){
          // resizer.style.width = startOffset + e.pageX + 'px';
          // resizer.style.minWidth = startOffset + e.pageX + 'px';
        // }

        let expandedColumnWidth = (startOffset + e.pageX) + 20;
        if(expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH){
          setExpandedTableColWidth(expandedColumnWidth);
        }
      }
    });



    // listner for releasing click
    table.addEventListener('mouseup', function () {
      thElm = undefined;
    });
  }
  }, [advertiserDropdownOpen, expandedTableColWidth,
      campaignDropdownOpen, campaignLineDropdownOpen])

  useEffect(()=>{
    localStorage.setItem('CampaignTableSelection', JSON.stringify(selectedCol));
  },[selectedCol]);

 useEffect(()=> {
  if (advertiserCount) 
    setAdCount(advertiserCount);
  if (dspDataCount)
    setDspCount(dspDataCount);
  if (dspLineDataCount)
    setDspLineCount(dspLineDataCount);
  
  if (advertiserData?.length) {
    const newList = adList.concat(advertiserData);
    setAdList(newList);
  }
 
  if (dspCampaignsData?.length) {
    const newList = dspList.concat(dspCampaignsData);
    setDspList(newList);
  }
  if (dspCampaignLineData?.length) {
    const newList = dspLineList.concat(dspCampaignLineData);
    setDspLineList(newList);
  }
 },[advertiserCount, advertiserData, dspCampaignsData, 
    dspCampaignLineData, dspDataCount, dspLineDataCount]);

useEffect(()=>{
  var advertiserDrop = document.getElementById("advertiserDropDown");
  if (adList) {
    if (advertiserDrop) {
      advertiserDrop.addEventListener('scroll', handleAdvertiserScroll);
    }
  
  
  if (allAdsSelected) {
  adList.map((advertiser) => {
    updateSelectedAdvertiserList((selectedAdvertiserList) => [
      ...selectedAdvertiserList,
      advertiser.id,
    ]);
    updateSelectedAdvertiserFilterSet((selectedAdvertiserFilterSet) => [
      ...selectedAdvertiserFilterSet,
      advertiser.dsp_id,
    ]);
  });
  }
  return()=>
  advertiserDrop && advertiserDrop.removeEventListener('scroll' , handleAdvertiserScroll);

  }

}, [adList]);

useEffect (()=> {
  var campaignNameDrop = document.getElementById("campaignNameDrop");
  if (dspList) {
    if (campaignNameDrop) {
      campaignNameDrop.addEventListener('scroll', handleCampaignNameScroll);
    }
    
    if (allCampaignSelected) {
      dspList.map((campaign) => {
        updateSelectedCampaignNameList((selectedCampaignNameList) => [
          ...selectedCampaignNameList,
          campaign.id,
        ]);
        updateSelectedCampaignNameFilterSet(
          (selectedCampaignNameFilterSet) => [
            ...selectedCampaignNameFilterSet,
            campaign.dsp_id,
          ]
        );
      });
    }
    return() => {
      campaignNameDrop && campaignNameDrop.removeEventListener('scroll', handleCampaignNameScroll);
    }
  }
}, [dspList])

useEffect (()=> {
  var campaignLineDrop = document.getElementById("campaignLineDrop");
  if (dspLineList) {
    if (campaignLineDrop) {
      campaignLineDrop.addEventListener('scroll', handleCampaignLineScroll);
    }
    
    if (allCampaignLineSelected) {
      dspLineList.map((campaignLine) => {
        updateSelectedCampaignLineList((selectedCampaignLineList) => [
          ...selectedCampaignLineList,
          campaignLine.id,
        ]);
        updateSelectedCampaignLineFilterSet(
          (selectedCampaignLineFilterSet) => [
            ...selectedCampaignLineFilterSet,
            campaignLine.dsp_id,
          ]
        );
      });
  }
    return() => {
      campaignLineDrop && campaignLineDrop.removeEventListener('scroll', handleCampaignLineScroll);
    }
  }
}, [dspLineList])

  useEffect(() => {
    var total = (selectedAdvertiserFilterSet.length + selectedCampaignNameFilterSet.length 
                + selectedCampaignLineFilterSet.length) || 0;
    if (total <= MAX_LIMIT_REACHED) {
      let params = {};
      if (selectedAdvertiserFilterSet.length && !allAdsSelected) {
        params.advertiser_ids = selectedAdvertiserFilterSet;
      }
      if (selectedCampaignNameFilterSet.length && !allCampaignSelected) {
        params.campaign_ids = selectedCampaignNameFilterSet;
      }
      if (selectedCampaignLineFilterSet.length && !allCampaignLineSelected) {
        params.line_ids = selectedCampaignLineFilterSet;
      }
      if (selectedStartTime) {
        params.start_time = selectedStartTime;
      }
      if (selectedEndTime) {
        params.end_time = selectedEndTime;
      }
      if (selectedOrganization) {
        params.organization = selectedOrganization.id;
      }
      dispatch(dashboardActionCreator.getSpendCtrCpaRequest(params));
      dispatch(dashboardActionCreator.getSpendClicksConversionRequest(params));
      dispatch(dashboardActionCreator.dashboardRequest(params));
    }
  }, [
    selectedAdvertiserFilterSet,
    selectedCampaignNameFilterSet,
    selectedCampaignLineFilterSet,
    selectedStartTime,
    selectedEndTime,
    selectedOrganization
  ]);

  //on component unmount clear the selected Organization
  useEffect(() => {
    localStorage.removeItem("researchSurveysSearchValue");
    return () => {
      // dispatch(organizationActionCreator.removedOrganization());
    }
  },[]);

  useEffect(() => {
    if(selectedAdvertiserList.length + selectedCampaignLineList.length
      + selectedCampaignNameList.length > MAX_LIMIT_REACHED) {
        setMaxLimitSelected(true);
        dispatch(notificationActionCreator.setNotification({
          color: "warning",
          message: "<b>Maximum filter items reached.</b> You can select up to 30 campaigns, advertisers, and lines to view at once. <br/>Please deselect some options if you would like to make additional selections."
        }));
    } else {
      setMaxLimitSelected(false);
    }
  }, [selectedAdvertiserList, selectedCampaignLineList, selectedCampaignNameList])

  useEffect(() => {
    if (dashboardData) {
      setCampaignData(dashboardData);
    }
  }, [dashboardData]);

  const clearFilterSearch = (e) => {
    //set filters,start and end date to default last 7 days
    e.preventDefault();
    updateSelectedAdvertiserList([]);
    updateSelectedAdvertiserFilterSet([]);
    updateSelectedCampaignNameList([]);
    updateSelectedCampaignNameFilterSet([]);
    updateSelectedCampaignLineList([]);
    updateSelectedCampaignLineFilterSet([]);
    updateSelectedStartTime(dateToYMD(startTime));
    updateSelectedEndTime(dateToYMD(endTime));
    updateDateRangeValue(dateValue);
    setState(intialDate);
    setAllCampaignLineSelected(false);
    setAllAdsSelected(false);
    setAllCampaignSelected(false);
  };

  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOYear: startOfYear(addYears(new Date(), 0)),
    endOflastYear: startOfDay(addDays(new Date(), -1)),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    startOflastWeek: startOfDay(subDays(new Date(), 7)),
    endOflastWeek: endOfDay(subDays(new Date(), 1)),
    startofLastQuarter: subQuarters(startOfMonth(new Date()),1),
    endofLastQuarter: endOfQuarter(subQuarters(startOfMonth(new Date()),1)),
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date())
  };

  const sideBarOptions = () => {
    const customDateObjects = dateSidebarOptions;

    return customDateObjects;
  };

  const sideBar = sideBarOptions();
  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar),
  ];

  const formatDate = (number) => {
    var date = number;
    date = date.split("/").map((e) => (e[0] == "0" ? e.slice(1) : e));
    date = date[1] + "/" + date[0] + "/" + date[2];
    return date;
  };

  const allHeadersSelectedCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedCol([]);
      headers.map((val) => {
        setSelectedCol((selectedCol) => {
          return [...selectedCol, val];
        });
      });
    }
    else {
      setSelectedCol([]);
    }
  }

  const handleHeaderCheckboxChange = (e, headerName) => {
    if (e.target.checked) {
      setSelectedCol((selectedCol) => {
        return [...selectedCol, headerName]
      });
    }
    else {
      selectedCol.splice(selectedCol.indexOf(headerName), 1);
      setSelectedCol((selectedCol) => {
        return [...selectedCol];
      });
    }
  }

  const handleExportToCSV = (e) => {
    e.preventDefault();
    if (dashboardData.length > 0) {
      //Headers    
      const headersSequence = ["CampaignName", "Flightdates", "Spend", "Impressions", "Clicks", "CPA", "CPC", "CPM", "CTR", "Conversion", "hundred_Complete", "hundred_CompletionRate", "Viewability"];

      //Data sequencing
      const dataMapping = dashboardData.map(row => {
        return {
          "CampaignName": row.CampaignName,
          "Flightdates": formatDate(row.start_date) + "-" + formatDate(row.end_date),
          "Spend": "$" + row.Spend.toFixed(2),
          "Impressions": row.Impressions.toLocaleString(),
          "Clicks": row.Clicks.toLocaleString(),
          "CPA": "$" + row.CPA.toFixed(2),
          "CPC": "$" + row.CPC.toFixed(2),
          "CPM": "$" + row.CPM.toFixed(2),
          "CTR": row.CTR.toFixed(2) + "%",
          "Conversion": row.Conversion.toLocaleString(),
          "hundred_Complete": row.hundred_Complete.toLocaleString(),
          "hundred_CompletionRate": row.hundred_CompletionRate.toFixed(2) + "%",
          "Viewability": row.Viewability.toFixed(2) + "%"
        }
      });

      const csvRows = [];
      csvRows.push(headersSequence.join(','));

      //rows
      for (const row of dataMapping) {
        const values = headersSequence.map(header => {
          const escapedQuotes = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escapedQuotes}"`;
        });
        csvRows.push(values.join(','));
      }

      //download setup
      const csvFileName = generateCSVFileName();
      const csvData = csvRows.join('\n');
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.setAttribute('hidden', '');
      tempLink.setAttribute('href', url);
      tempLink.setAttribute('download', csvFileName);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }

  // if (userData.organization_role !== 0) {
  //   if (!is_media_enabled) {
  //     return <Redirect to={ROUTES.REALTIME_RESEARCH} />
  //   }
  // }

  const generateCSVFileName = () => {
    var today = new Date();
    var y = today.getFullYear();
    var m = `${today.getMonth() + 1}`.padStart(2, '0');
    var d = `${today.getDate()}`.padStart(2, '0');
    var h = `${today.getHours()}`.padStart(2, '0');
    var mi = `${today.getMinutes() + 1}`.padStart(2, '0');
    var s = `${today.getSeconds() + 1}`.padStart(2, '0');
    return `Campaigns-${m}-${d}-${y}-${h}-${mi}-${s}.csv`;
  }

  const gotoReportBuilder = (e) => {
    e.preventDefault();
    history.push(ROUTES.REPORT_BUILDER);
  }

  return (
    <>
      <HeaderNavigation />
      <Notifications/>
      <div className="dashboard-container" style={{display:"flex", marginTop: "40px"}}>
        {/* <SideNavigation /> */}
        <div className="dashboard">
          <section className="dashboard-header-section">
            <nav className="left-nav-section">
              <h1>Media Dashboard</h1>
            </nav>
            <nav className="right-nav-section">
              <nav className="advertiser-list-dropdown-section">
                <Dropdown
                  isOpen={advertiserDropdownOpen}
                  toggle={toggleAdvertiserDropdown}
                  onClick={getAdvertiserDropdownOptions}
                  id="advertiserDrop"
                >
                  <DropdownToggle caret>
                    Advertiser
                  <img
                      src={
                        advertiserDropdownOpen
                          ? "/Icon_assets/svg/arrow-up.svg"
                          : "/Icon_assets/svg/arrow-down.svg"
                      }
                      className="arrow-down"
                    />
                  </DropdownToggle>
                  <DropdownMenu 
                    className="resized-column"
                    style={{width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px`}}>
                 
                          <DropdownItem className="search-item" toggle={false}>
                          <FormGroup className="search-metrics"> 
                              <Input
                                value={searchValue}
                                type="text"
                                name="search"
                                id="searchUser"
                                placeholder="Search..."
                                className="search-input"
                                autoComplete="off"
                                onChange={(event) => handleSearch(event, "advertiser_drop")}
                              />
                              <img
                                src="/Icon_assets/svg/search-organization-icon.svg"
                                alt="Search"
                                className="search-image"
                              /></FormGroup>
                          </DropdownItem>
                          <div className="metrics-list" id="advertiserDropDown">
                          { (!isAdvertiserRequest && adCount === 0 && searchValue !== "" ) ? 
                        (
                          <span className="no-data-available-txt">
                            No Advertiser data available
                          </span>
                        ) :
                        adList && adList.length ? (
                        adList && adList.length ? (
                          adList.map((advertiser, index) => {
                            return (
                              <React.Fragment>
                                <DropdownItem
                                  toggle={false}
                                  key={"advertiser" + index}
                                  id={"ad" + advertiser.dsp_id + index}
                                >
                                  <CustomInput
                                    className="mt-3"
                                    type="checkbox"
                                    name={advertiser.name}
                                    value={advertiser.id}
                                    id={"advertiser-" + advertiser.id}
                                    label={advertiser.name}
                                    checked={
                                      ((selectedAdvertiserList.findIndex(
                                        (element) => element === advertiser.id
                                      ) > -1 ) || allAdsSelected)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleAdvertiserCheckboxChange(
                                        e,
                                        advertiser.id,
                                        advertiser.dsp_id
                                      )
                                    }
                                  />
                                </DropdownItem>
                                {!isAdvertiserRequest && <Tooltip
                                  className="ad-tool-tip"
                                  placement="bottom"
                                  target={"ad" + advertiser.dsp_id + index}
                                  isOpen={isToolTipOpen(
                                    `advertiser-${advertiser.dsp_id}${index}`
                                  )}
                                  toggle={() =>
                                    toggleTool(`advertiser-${advertiser.dsp_id}${index}`)
                                  }
                                >
                                  {advertiser.name}
                                </Tooltip>}
                              </React.Fragment>
                            );
                          })
                      ) : (
                        <span className="no-data-available-txt">
                          No Advertiser data available
                        </span>
                      )
                    ) : (
                        <Loader />
                    )}
                    </div>
                    <div className="resizer-icon" id="resizer-col">
                    <span className="first"></span>
                    <span className="second"></span>
                  </div>

                  </DropdownMenu>
                </Dropdown>
              </nav>
              <nav className="campaign-list-dropdown-section">
                <Dropdown
                  id="campaignDropDown"
                  isOpen={campaignDropdownOpen}
                  toggle={toggleCampaignDropdownOpen}
                  onClick={getCampaignDropdownOptions}
                >
                  <DropdownToggle caret>
                    Campaign name
                  <img
                      src={
                        campaignDropdownOpen
                          ? "/Icon_assets/svg/arrow-up.svg"
                          : "/Icon_assets/svg/arrow-down.svg"
                      }
                      className="arrow-down"
                    />
                  </DropdownToggle>
                  <DropdownMenu 
                  className="resized-column"
                  style={{width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px`}}>

                    <DropdownItem toggle={false}>
                      <FormGroup>
                        <Input
                          value={searchValue}
                          type="text"
                          name="search"
                          id="searchUser"
                          placeholder="Search..."
                          className="search-input"
                          autoComplete="off"
                          onChange={(event) => handleSearch(event, "campaign_drop")}
                        />
                        <img
                          src="/Icon_assets/svg/search-organization-icon.svg"
                          alt="Search"
                          className="search-image"
                        /></FormGroup>
                    </DropdownItem>
                    <div className="metrics-list" id="campaignNameDrop">
                    {(isDSPCampaingsListRequest &&
                     dspCount === 0 && searchValue !== "") ?
                      (
                        <span className="no-data-available-txt">
                          No Campaign data available
                        </span>
                      ) :
                      dspList && dspList.length ? (
                        dspList && dspList.length ?
                          (dspList.map((campaign, index) => {
                            return (
                              <>
                                <DropdownItem
                                  toggle={false}
                                  key={"campaign" + index}
                                  id={"campaign" + campaign.dsp_id + index}
                                >
                                  <CustomInput
                                    className="mt-3"
                                    type="checkbox"
                                    name={campaign.name}
                                    value={campaign.id}
                                    id={campaign.id}
                                    label={campaign.name}
                                    checked={
                                      (selectedCampaignNameList.findIndex(
                                        (element) => element === campaign.id
                                      ) > -1 || allCampaignSelected)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleCampaignCheckboxChange(
                                        e,
                                        campaign.id,
                                        campaign.dsp_id
                                      )
                                    }
                                  />
                                </DropdownItem>
                                {!isDSPCampaingsListRequest && <Tooltip
                                  className="ad-tool-tip"
                                  placement="bottom"
                                  target={"campaign" + campaign.dsp_id + index}
                                  isOpen={isToolTipOpen(
                                    `campaign-${campaign.dsp_id}${index}`
                                  )}
                                  toggle={() =>
                                    toggleTool(`campaign-${campaign.dsp_id}${index}`)
                                  }
                                >
                                  {campaign.name}
                                </Tooltip>}
                              </>
                            );
                          })
                          ) : (
                            <span className="no-data-available-txt">
                              No Campaign data available
                            </span>
                          )
                      ) : (
                        <Loader />
                      )}
                      </div>
                       <div className="resizer-icon" id="resizer-col">
                    <span className="first"></span>
                    <span className="second"></span>
                  </div>
                  </DropdownMenu>
                </Dropdown>
              </nav>
              <nav className="campaign-line-list-dropdown-section">
                <Dropdown
                  id="campaignLineDropDown"
                  isOpen={campaignLineDropdownOpen}
                  toggle={toggleCampaignLineDropdownOpen}
                  onClick={getCampaignLineDropdownOptions}
                >
                  <DropdownToggle caret>
                    Campaign line
                  <img
                      src={
                        campaignLineDropdownOpen
                          ? "/Icon_assets/svg/arrow-up.svg"
                          : "/Icon_assets/svg/arrow-down.svg"
                      }
                      className="arrow-down"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                  className="resized-column"
                  style={{width: `${expandedTableColWidth}px`, minWidth: `${expandedTableColWidth}px`}}>
                   
                          <DropdownItem toggle={false}>
                            <FormGroup>
                              <Input
                                value={searchValue}
                                type="text"
                                name="search"
                                id="searchUser"
                                placeholder="Search..."
                                className="search-input"
                                autoComplete="off"
                                onChange={(event) => handleSearch(event, "campaign_line_drop")}
                              />
                              <img
                                src="/Icon_assets/svg/search-organization-icon.svg"
                                alt="Search"
                                className="search-image"
                              /></FormGroup>
                          </DropdownItem>
                          <div className="metrics-list"  id="campaignLineDrop">
                      { (isDSPCampaingLineListRequest && dspLineCount === 0 && searchValue !== "" ) ? 
                          (
                            <span className="no-data-available-txt">
                              No Campaign Line data available
                            </span>
                          ) :
                      dspLineList && dspLineList.length ? (
                      dspLineList && dspLineList.length ? (
                        dspLineList.map((campaignLine, index) => {
                            return (
                              <>
                                <DropdownItem
                                  toggle={false}
                                  key={"campaignLine" + index}
                                  id={"campaignLine" + campaignLine.dsp_id + index}
                                >
                                  <CustomInput
                                    className="mt-3"
                                    type="checkbox"
                                    name={campaignLine.name}
                                    value={campaignLine.id}
                                    id={campaignLine.id}
                                    label={campaignLine.name}
                                    checked={
                                      (selectedCampaignLineList.findIndex(
                                        (element) => element === campaignLine.id
                                      ) > -1 || allCampaignLineSelected)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleCampaignLineCheckboxChange(
                                        e,
                                        campaignLine.id,
                                        campaignLine.dsp_id
                                      )
                                    }
                                  />
                                </DropdownItem>
                                { isDSPCampaingLineListRequest && <Tooltip
                                  className="ad-tool-tip"
                                  placement="bottom"
                                  target={"campaignLine" + campaignLine.dsp_id + index}
                                  isOpen={isToolTipOpen(
                                    `campaignLine-${campaignLine.dsp_id}${index}`
                                  )}
                                  toggle={() =>
                                    toggleTool(`campaignLine-${campaignLine.dsp_id}${index}`)
                                  }
                                >
                                  {campaignLine.name}
                                </Tooltip>}
                              </>
                            );
                          })
                      ) : (
                        <span className="no-data-available-txt">
                          No Campaign Line data available
                        </span>
                      )
                    ) : (
                        <Loader />
                    )}
                    </div>
                     <div className="resizer-icon" id="resizer-col">
                    <span className="first"></span>
                    <span className="second"></span>
                  </div>
                  </DropdownMenu>
                </Dropdown>
              </nav>
              <nav className="date-range-container">
                <Dropdown isOpen={showDateRange} toggle={toggleDateRange}>
                  <DropdownToggle caret>
                    <span>
                      {!dateRangeValue ? <span>Date range</span> : ""}
                      {dateRangeValue ? (
                        <span className="date-range-input">
                          <FormGroup>
                            <div
                              id="displaydateValue"
                              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "79px", height: "19px" }}>
                              {dateRangeValue}
                            </div>
                            <Input
                              type="text"
                              name="date-value"
                              id="dateValue"
                              value={dateRangeValue}
                              className="form-input"
                              autoComplete="off"
                              style={{ opacity: "0", position: "absolute" }}
                              readOnly
                            />
                          </FormGroup>
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target="displaydateValue"
                          >
                            {dateRangeValue}
                          </UncontrolledTooltip>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <img
                      src={
                        showDateRange
                          ? "/Icon_assets/svg/arrow-up.svg"
                          : "/Icon_assets/svg/arrow-down.svg"
                      }
                      className="arrow-down"
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="ad-date-range-picker">
                    <DropdownItem toggle={false}>
                    <div className="ad-date-range-component">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        monthDisplayFormat = {"MMMM yyyy"}
                        weekdayDisplayFormat = {"EEEEEE"}
                        ranges={state}
                        staticRanges={staticRanges}
                        direction="horizontal"
                        color=" #3257FB"
                        maxDate={defineds.startOfYesterday}
                      />
                      </div>
                    </DropdownItem>
                    <div className="ad-daterange-actions text-right mt-3 mb-2">
                      <Button
                        className="in-btn-lg in-btn-secondary cancle"
                        onClick={() => selectDateRange(false)}
                      >
                        Cancel
                    </Button>
                      <Button
                        className="in-btn-lg in-btn-primary ml-3 mr-3 apply"
                        onClick={handleDateChange}
                      >
                        Apply
                    </Button>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </nav>
              <nav>
                <img src="/Icon_assets/svg/seperator.svg" />
              </nav>
              <nav className="clear-filter-section">
                <a
                  href="#"
                  className="clear-filter-link"
                  onClick={(e) => clearFilterSearch(e)}
                >
                  Clear
              </a>
              </nav>
            </nav>
            <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ} >
              {isReportBuilderEnabled && (
                <nav className="left-nav-button-section">
                  <Button
                    style={{ minWidth: '154px', minHeight: '48px' }}
                    className="in-btn-sm in-btn-info btn-export"
                    onClick={gotoReportBuilder}
                  >
                    {"Report Builder"}
                  </Button>
                </nav>
              )}
            </RBAC>
          </section>
          <section className="spend-ctr-cpa-container">
            <nav className={(!isSpendCtrCpaDataRequest ? "spinner-container-div":"")}>
              {isSpendCtrCpaDataRequest ? (
                <React.Fragment>
                  <span className="label-txt">Spend</span>
                  <span className="amount-txt">
                  { "$" + (spendCtrCpcData.Spend ? spendCtrCpcData.Spend.toLocaleString('en-US', {maximumFractionDigits:2}) : formatFloatNumber(0) ) }
                  </span>
                </React.Fragment>
              ) : (
                <section className="skeleton-loading">
                  <SkeletonSection />
                </section>
              )}
            </nav>
            <nav className={(!isSpendCtrCpaDataRequest ? "spinner-container-div":"")}>
              {isSpendCtrCpaDataRequest ? (
                <React.Fragment>
                  <span className="label-txt">CTR</span>
                  <img src={"/Icon_assets/svg/dashboard-info-icon.svg"} className="dashboard-info" alt="info icon" id="ctr-tooltip"/>
                  <span className="amount-txt">
                    {formatFloatNumber(spendCtrCpcData.CTR ? spendCtrCpcData.CTR : 0)}%
                </span>
                <UncontrolledTooltip
                        className="custom-file-upload-input-tooltip tip-width"
                        placement="top"
                        target="ctr-tooltip"
                        style={{fontFamily:'BasisGrotesque'}}
                        >
                            {TOOLTIP_LABELS.CTR}
                  </UncontrolledTooltip>
                </React.Fragment>
              ) : (
                <section className="skeleton-loading">
                  <SkeletonSection />
                </section>
              )}
            </nav>
            <nav className={(!isSpendCtrCpaDataRequest ? "spinner-container-div":"")}>
              {isSpendCtrCpaDataRequest ? (
                <React.Fragment>
                  <span className="label-txt">CPA</span>
                  <img src={"/Icon_assets/svg/dashboard-info-icon.svg"} className="dashboard-info" alt="info icon" id="cpa-tooltip"/>
                  <UncontrolledTooltip
                        className="custom-file-upload-input-tooltip tip-width"
                        placement="top"
                        target="cpa-tooltip"
                        style={{fontFamily:'BasisGrotesque'}}
                        >
                            {TOOLTIP_LABELS.CPA}
                  </UncontrolledTooltip>

                  <span className="amount-txt">
                    ${formatFloatNumber(spendCtrCpcData.CPA ? spendCtrCpcData.CPA : 0)}
                  </span>
                </React.Fragment>
              ) : (
                <section className="skeleton-loading">
                  <SkeletonSection />
                </section>
              )}
            </nav>
          </section>
          <section className="performance-graph-container">
            <Card className={(!isSpendClicksConversionRequest ? "graph-div spinner-container-div" : "") +" card-container"}>
              <h2>Performance</h2>
              <section className= "graph-section">
                {isSpendClicksConversionRequest ? (
                  spendClicksConversionData ? (
                    <ReactFusioncharts
                      type="mscombidy2d"
                      width="100%"
                      height="400"
                      dataFormat="json"
                      dataSource={dataSource}
                      events={events}
                    />
                  ) : (
                    <p className="no-wallet-data-text">No table data available</p>
                  )
                ) : (
                  <section className="skeleton-loading">
                    <SkeletonGraph />
                  </section>
                )}
              </section>
            </Card>
          </section>
          <Card className={(dashboardDataRequestCompleted ? "card-container dashboard-table-data-container" : "spinner-container-div")}>
            <h2>Campaigns</h2>
            {dashboardDataRequestCompleted ?
              <>
                <div id="campaign_section">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', display: "inline-block", float: "right" }}>

                  <div className="form-group"> 
                  <div className="form-group">
                  <img
                      src={searchImage}
                      alt="Search"
                      className="search-image"
                    />
                    <Input
                      value={searchCampaignValue}
                      type="text"
                      name="search"
                      id="searchUser"
                      placeholder="Search..."
                      className="search-input"
                      autoComplete="off"
                      onChange={(event) => handleSearchCampaign(event)}
                      onFocus={() => { setSearchImage(ICONS.SEARCH_ACTIVE) }}
                      onBlur={() => { setSearchImage(ICONS.SEARCH) }}
                    />
                   
                    </div>
                    <Dropdown direction="left" id={"setting_dropdown"} isOpen={dropdownOpen} toggle={toggle} style={{ background: "#fff" }}>
                      <DropdownToggle style={{paddingTop: "0px"}}>
                        <img
                          src={dropdownOpen ? "/Icon_assets/svg/campaign_setting_selected.svg" : "/Icon_assets/svg/campaign_setting.svg"}
                          alt="Export to CSV"
                          style={{ width: '40px', height: '40px', marginRight: "24px", cursor: 'pointer' }}
                          id="campaign-setting"
                        />
                      </DropdownToggle>
                      <DropdownMenu style={{ borderRadius: "8px" }} >
                        <React.Fragment>
                          <DropdownItem toggle={false}>
                            <CustomInput
                              key="all"
                              className="campaign-item"
                              type="checkbox"
                              name="All"
                              value="all"
                              id="all_headers"
                              label="All Columns"
                              checked={(selectedCol && selectedCol.length) === (headers && headers.length)}
                              onChange={(e) =>
                                allHeadersSelectedCheckbox(e, "all_headers")
                              }
                            />
                          </DropdownItem>
                          <DropdownItem divider style={{marginLeft : "16px", marginRight: "16px"}}/>
                          {headers.map((header, index) => {
                            return (
                              <DropdownItem name={"campaign col"}
                                key={index}
                                id={index}
                                toggle={false}
                                className="campaign-item">
                                <CustomInput
                                  // className="mt-3"
                                  type="checkbox"
                                  name={index}
                                  value={header}
                                  id={"header-" + index}
                                  label={header}
                                  checked={
                                    selectedCol.findIndex(
                                      (element) => element === header
                                    ) > -1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleHeaderCheckboxChange(
                                      e, e.target.value)
                                  }}
                                />
                              </DropdownItem>
                            )
                          })}
                        </React.Fragment>
                      </DropdownMenu>
                    </Dropdown>
                      <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_READ}>
                        <>
                          <img
                            src="/Icon_assets/svg/export_to_csv.svg"
                            alt="Export to CSV"
                            style={{ width: '40px', height: '40px', cursor: dashboardData.length > 0 ? 'pointer' : 'default' }}
                            onClick={handleExportToCSV}
                            id="ExporttoCSVToolTip"
                          />
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target="ExporttoCSVToolTip"
                            style={{ minWidth: '151px', minHeight: '52px', fontFamily: 'BasisGrotesque' }}
                          >
                            {dashboardData.length > 0 ?
                              (<>
                                <span className="d-block">Data exports at </span>
                                <span className="d-block">campaign level only.</span>
                              </>) :
                              (<>
                                <span className="d-block">No campaign data available</span>
                                <span className="d-block">to export.</span>
                              </>)
                            }
                          </UncontrolledTooltip>
                        </>
                      </RBAC>
            </div>
            </div>
          </div>
          <CardBody className={(dashboardDataRequestCompleted ? "" : "spinner-container-div")}>
            <CampaignTable
                displayColumns={selectedCol}
                dashboardData={campaignData}
                dashboardDataRequestCompleted={dashboardDataRequestCompleted}
                selectedCampaignLineFilterSet={selectedCampaignLineFilterSet}
                dateRange={{start_time: selectedStartTime, end_time: selectedEndTime}}
              />
            </CardBody>
            </>
         :
          <section className="skeleton-loading">
            <SkeletonTable rows={4}/>
          </section>
          }
         </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
