import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isFetched: false,
  rulesList: [],
  createRules: {},
  isError: false,
  isPixelLoading: false
};

const conversionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RULES_LIST_REQUEST:
      return {
        ...state,
        rulesList: action.payload,
        isLoading: true,
      };

    case ACTIONS.RULES_LIST_SUCCESS:
      return {
        ...state,
        rulesList: action.payload,
        isLoading: false,
      };

    case ACTIONS.RULES_LIST_FAILURE:
      return {
        ...state,
        rulesList: action.payload,
        isLoading: false,
      };

    case ACTIONS.CREATE_RULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS.CREATE_RULE_SUCCESS:
      return {
        ...state,
        createRules: action.payload,
        isLoading: false,
      };

    case ACTIONS.CREATE_RULE_FAILURE:
      return {
        ...state,
        createRules: action.payload,
        isLoading: false,
        isError: true
      };

    case ACTIONS.CLEAR_CREATE_RULE_RESPONSE:
      return {
        ...state,
        createRules: {},
        isLoading: false,
        isError: false
      }

    case ACTIONS.CLEAR_RULE_LIST:
      return {
        ...state,
        rulesList: [],
        isLoading: false,
      }

    case ACTIONS.GET_PIXEL_CODE_REQUEST_CLEAR:
      return {
        ...state,
        pixel: "",
        isPixelLoading: false
      }

    case ACTIONS.GET_PIXEL_CODE_REQUEST:
      return {
        ...state,
        isPixelLoading: true
      }

    case ACTIONS.GET_PIXEL_CODE_RESPONSE:
      return {
        ...state,
        pixel: action.payload,
        isPixelLoading: false
      }

    case ACTIONS.GET_PIXEL_CODE_FAILURE:
      return {
        ...state,
        pixel: "",
        isPixelLoading: false
      }
    default:
      return state;
  }
}

export default conversionReducer;