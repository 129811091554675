import { useSelector } from 'react-redux';
import checkRBAC from '../../utils/checkRBAC';

const RBAC = ({ requiredPermissions, children }) => {

  const userData = useSelector(state => state.auth.userData);
  const userRbac = useSelector(state => state.auth.rbac);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

  return (
    checkRBAC(userData, userRbac, requiredPermissions, selectedOrganization) ?
      children : <></>
  )
}
export default RBAC;
