const OFFER_STATES = [
    {
        id: 0,
        state_label: 'Expired',
        transaction_state: []
    },
    {
        id: 1,
        state_label: 'Active',
        transaction_state: []
    },
    {
        id: 2,
        state_label: 'Deleted',
        transaction_state: []
    },
    {
        id: 3,
        state_label: 'Failed',
        transaction_state: []
    },
    {
        id: 4,
        state_label: 'Draft',
        transaction_state: [1]
    }
]

export default OFFER_STATES;