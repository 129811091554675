import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { realtimeResearchActionCreator, surveyActionCreator } from "../../../../actions";
import CAMPAIGN_STATES from '../../../../utils/campaignStates';
import "./index.scss";

const StatusDropdown = ({ data }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const statusEnum = CAMPAIGN_STATES;

  const dispatch = useDispatch();
  const prepareAllowedTransitions = (currentAdState) => {
    const allowedTransitionsData = data.state_transition.map(transition => transition.to_state_id);
    const transitions = [];

    allowedTransitionsData.map((transition_id) => {
      if (transition_id === 1) {
        transition_id = 4;
      }
      let item = <DropdownItem onClick={() => { updateAdState(transition_id, currentAdState) }} key={transition_id} className={(statusEnum[transition_id].state_label).toLocaleLowerCase()}>{statusEnum[transition_id].action_label}</DropdownItem>;
      transitions.push(item);
    });

    return transitions;
  }

  const updateAdState = (state_id, currentAdState) => {
    // removed the condition for CLEAR-11590, to call campaign/state-change API.
    var params = {};
    if (currentAdState === 0) {
      params.brand_id = data.brand_v2?.id;
      params.message_id = data.message;
      params.ad_state = 4;
      params.pixel = "";
      params.tags = null;
      params.tracking_tag = "";
      params.ad_id = data.id;
      params.name = data.name;
      dispatch(surveyActionCreator.activateAdRequest(params));
    } else {
      params.ad_id = data.id;
      params.name = data.name;
      params.brand_id = data.brand_v2?.id;
      params.message_id = data.message;
      params.ad_state = 4;
      dispatch(realtimeResearchActionCreator.updateAdStateRequest({ id: data.campaign.id, state_id: state_id, organization_id: data.brand_v2.organization.id }));
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={(statusEnum[data.ad_state].state_label).toLocaleLowerCase() + " state-change-dropdown"} >
      <DropdownToggle className="d-flex text-left">
        <span className="state-dot"></span>
        {statusEnum[data.ad_state].state_label}
      </DropdownToggle>
      <DropdownMenu>
        {prepareAllowedTransitions(data.ad_state)}
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusDropdown;