import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import {
  Button, Card,
  CardBody, Label, Input, FormGroup, Table,
  Modal, ModalBody, ModalHeader
} from 'reactstrap';
import Loader from '../../Loader';

const UploadTemplate = ({ type, dragOver, dragEnter, dragLeave, handleFileUpload, fileDrop, csvLoading, closeUpload }) => {

  return (
    <div className="dashboard real-time">
      <section className="title-section dashboard-header-section" style={{ marginBottom: "35px" }}>
        <>
          <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
            <Button className="btn-transparent" onClick={closeUpload}>
              <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
            </Button>
            <h2 id={"survey-header"}
              className="mb-0 pl-4 secondary-title">
              {`Upload CSV for Branching Survey`}</h2>
          </nav>

          <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
            <div className="text-right form-group">
              {/*<Button className="in-btn-sm in-btn-info btn-export"
                style={{ minWidth: '190px', minHeight: '48px' }}>
                <a href={BranchingSurveyFile} download={"surveyFile/IFB Question Template Upload - Survey.csv"}>Download Template</a></Button> */}
            </div>
          </nav>

        </>
      </section>

      <div id="upload-div">

        <div className="drop-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}>
          {
            csvLoading ?
              <Loader style={{ padding: '0', paddingTop: '60px', background: 'none' }} />
              :

              <div className="drop-message">
                <div className="upload-icon">
                  <img src={"/Icon_assets/svg/file-icon.svg"} alt="file-icon" />
                  <span className="upload-label"> Drop a File</span>
                  <div className="selection-text">or select from your computer</div>
                  <Input
                    style={{ width: "100%", top: "40%", left: "40%" }}
                    type="file"
                    name="dropFile"
                    id="dropFile"
                    className="custom-file-upload-input"
                    title=""
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <div className="file-info">Requirements: file format — CSV, up to 5MB.</div>
                </div>
              </div>
          }
        </div>

        <h2 className="form-heading">How should my file be formatted?</h2>
        <h3 className="">There can be up to 25 multi- or single selection questions per CSV. Please use the following header & naming conventions.</h3>
        <Table id="upload-info-table" className="roundedCorners">
          <tbody>
            <tr> <th>Column Name</th> <th>Description</th> <th>Acceptable Inputs</th> </tr>
            <tr><td>id</td><td>This is the ID for each question in your survey.<br /> Each number should be unique.</td><td>Unique number between 1 to 25.</td></tr>
            <tr><td>question_type</td> <td>Specify if your survey is single selection or multi selection.</td> <td>SS, MS</td> </tr>
            <tr><td>shuffle_type</td> <td>To decrease potential bias within your surveys, you can set the<br /> question answers to shuffle in a random or reverse order. If left<br /> blank, the system will default to none.</td> <td>None, Random, Reverse</td> </tr>
            <tr><td>question</td> <td>This is the question for your survey.</td> <td>Up to 85 characters.</td> </tr> 
            <tr><td>next_question</td> <td>This is a required field for all multi-choice questions. If this<br/>column is filled, when a user answers this questions, they will<br/>be redirected to whichever question or toast you specify.<br/><br/>This can also work for single choice questions, but is not<br/>required if the answer branch field is used.</td> <td>q1, q2, q3, q4, q5... q25. toast1, toast2, toast3, toast4, toast5.</td> </tr> 
            <tr><td>answerX</td> <td>Instead of x, use a number between 1 to 6. This is the answer <br />for your survey.</td> <td>Up to 17 characters. Surveys without header images and four or<br /> fewer answers can have up to 40 characters.</td> </tr>
            <tr><td>answerX_pixel</td> <td>The event pixel code for each answer. Instead of x, use a<br /> number between 1 to 6. This is the event pixel for each answer.</td> <td>Copy the event pixel exactly.</td> </tr>
           <tr><td>answerX_branch</td><td>This is a required field for all single selection questions. If this<br />next_question field is blank. If this column is filled, when a user <br/>answers this question, they will be redirected to whichever <br/> question or toast you specify.</td><td>q1, q2, q3, q4, q5... q25. toast1, toast2, toast3, toast4, toast5.</td></tr> </tbody></Table>
      </div>
    </div>
  )
}

export default UploadTemplate;