import { takeLatest, call, select, put } from "redux-saga/effects";
import { userActionCreator, notificationActionCreator, authActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function getUserListRequest(API_URL_PARAM, jwtToken, payload) {
  let API_URL = "";
  let encodeString = "";
  let params = {};

  params.type = 2;

  if (payload?.offset) {
    params.offset = payload.offset;
  }

  if (payload?.searchKey) {
    encodeString = payload.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
    params.search = encodeString;
  }

  if (payload?.userId) {
    API_URL = API_URL_PARAM + "/v1/users/" + payload.userId;
  } else
    API_URL = API_URL_PARAM + "/v1/users";

  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
  }).catch((err) => {
    console.log(err);
  });
}

function* getUserListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getUserListRequest, API_URL, jwtToken, data);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(userActionCreator.getUserListSuccess(item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(userActionCreator.getUserListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.getUserListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(userActionCreator.getUserListFailure("Server Error"));
  }
}

function uploadProfilePicRequest(API_URL_PARAM, jwtToken, file) {
  const formData = new FormData();
  formData.append("file", file);
  const API_URL = API_URL_PARAM + "/v1/users/upload-profile-pic";
  return axios({
    method: "POST",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
      "Content-Type": "multiplart/form-data",
    },
    data: formData,
  }).catch((err) => {
    console.log(err);
  });
}

function* uploadProfilePicRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const file = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(uploadProfilePicRequest, API_URL, jwtToken, file);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(userActionCreator.uploadProfilePicSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(userActionCreator.uploadProfilePicFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.uploadProfilePicFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(userActionCreator.uploadProfilePicFailure("Server Error"));
  }
}

function createUserRequest(API_URL_PARAM, jwtToken, data) {
  const API_URL = API_URL_PARAM + "/v1/users?type=2";
  return axios({
    method: "POST",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
    },
    data: data,
  }).catch((err) => {
    console.log(err);
  });
}

function sendDeactivateUserRequest(
  API_URL_PARAM,
  userDetails,
  jwtToken,
  userId
) {
  const API_URL = API_URL_PARAM + "/v1/users/" + userId + '?type=2';
  return axios({
    url: API_URL,
    method: "DELETE",
    headers: {
      Authorization: jwtToken,
    },
    data: userDetails,
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* createUserRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(createUserRequest, API_URL, jwtToken, data);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(userActionCreator.createUserSuccess());
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(userActionCreator.createUserFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.createUserFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(userActionCreator.createUserFailure("Server Error"));
  }
}

function* sendDeactivateUserRequestSaga(action) {
  try {
    const userId = action.payload;
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendDeactivateUserRequest,
      API_URL,
      userData,
      jwtToken,
      userId
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(userActionCreator.deactivateUserSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
      // localStorage.setItem("user", JSON.stringify(item));
      //   history.push(ROUTES.DASHBOARD);
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(userActionCreator.deactivateUserFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.deactivateUserFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(userActionCreator.deactivateUserFailure("Server Error"));
  }
}

export default function* authSagas() {
  yield takeLatest(ACTIONS.GET_USER_LIST, getUserListRequestSaga);
  yield takeLatest(
    ACTIONS.UPLOAD_PROFILE_PIC_REQUEST,
    uploadProfilePicRequestSaga
  );
  yield takeLatest(ACTIONS.CREATE_USER_REQUEST, createUserRequestSaga);
  yield takeLatest(
    ACTIONS.DEACTIVATEUSER_REQUEST,
    sendDeactivateUserRequestSaga
  );
  yield takeLatest(
    ACTIONS.ACTIVATE_USER_REQUEST,
    activateUserRequestSaga
  );
  yield takeLatest(ACTIONS.UPDATE_USER_REQUEST, updateUserRequestSaga);
  yield takeLatest(ACTIONS.UPDATE_PASSWORD, updateUserPasswordSaga);
  yield takeLatest(ACTIONS.GET_USER_ROLES_LIST, getUserRolesListSaga)
}

function* activateUserRequestSaga(action) {
  try {
    const userId = action.payload.id;
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      activateUserRequest,
      API_URL,
      userData,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(userActionCreator.activateUserSuccess(item));
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(userActionCreator.activateUserFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.activateUserFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(userActionCreator.activateUserFailure("Server Error"));
  }
}

// to do  as per schema
function activateUserRequest(
  API_URL_PARAM,
  userDetails,
  jwtToken,
  user
) {
  const API_URL = API_URL_PARAM + "/v1/users/" + user.id + '?type=2';
  return axios({
    url: API_URL,
    method: "PATCH",
    headers: {
      Authorization: jwtToken,
    },
    data: { status: user.status },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function updateUserRequest(API_URL_PARAM, jwtToken, data) {
  const API_URL = API_URL_PARAM + `/v1/users/${data.id}?type=2`;
  return axios({
    method: "PATCH",
    url: API_URL,
    headers: {
      Authorization: jwtToken,
    },
    data: data,
  }).catch((err) => {
    console.log(err);
  });
}

function* updateUserRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload;
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(updateUserRequest, API_URL, jwtToken, data);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {
      let storageData = JSON.parse(localStorage.getItem("user"));
      if (item.id === storageData.id) {
        storageData.name = item.name;
        storageData.profile_pic = item.profile_pic;
        localStorage.setItem('user', JSON.stringify(storageData));
      }
      yield put(userActionCreator.updateUserSuccess());
      yield put(
        notificationActionCreator.setNotification({
          color: "success",
          message: status.status_message
        })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(userActionCreator.updateUserFailure(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.updateUserFailure(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(userActionCreator.updateUserFailure("Server Error"));
  }
}

function updateUserPasswordRequest(apiUrl, data, token, authRequired = true) {
  const options = {
    url: apiUrl,
    method: 'post',
    data
  };
  if (authRequired) {
    const jwtToken = token;
    options.headers = { 'Authorization': jwtToken };
  }
  return axios(options).catch(err => {
    //console.log(err);
  });
}

function* updateUserPasswordSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const data = action.payload;
    const apiUrl = `${API_URL}/v1/users/update-password`;
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(updateUserPasswordRequest, apiUrl, data, jwtToken);
    const { item, status } = result.data;

    if (status && status.status_type && status.status_type.toUpperCase() === 'SUCCESS') {
      yield put(userActionCreator.updatePasswordSucess(item));
      yield put(notificationActionCreator.setNotification({
        color: "success",
        message: status.status_message
      })
      );
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[1]}`;
          else return `${err[1]}`;
        });
        yield put(userActionCreator.updatePasswordError(errorMsg.join()));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg.join()
          })
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.updatePasswordError(errorMsg));
        yield put(
          notificationActionCreator.setNotification({
            message: errorMsg
          })
        );
      }
    }
  } catch (error) {
    yield put(userActionCreator.updatePasswordError("Server Error"));
  }
}

function getUserRolesListRequest(API_URL_PARAM, jwtToken) {
  // to get the user roles list form API
  let API_URL = "";
  let encodeString = "";
  let params = {};

  params.type = 2;

  // TO DO : REMOVE AFTER API INTEGRATION
  API_URL = API_URL_PARAM + "/v1/organization-roles";

  return axios({
    url: API_URL,
    method: "GET",
    params: params,
    headers: {
      Authorization: jwtToken,
    },
  }).catch((err) => {
    console.log(err);
  });
}

function* getUserRolesListSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    const data = action.payload ? action.payload : "";
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getUserRolesListRequest, API_URL, jwtToken, data);
    const { item, status } = result.data;

    if (status.status_type.toUpperCase() === "SUCCESS") {

      let f_item = {
        ...item,
        results: userData.organization_role !== 0 ? item.results.filter(i => i.value !== 0) : item.results
      }
      yield put(userActionCreator.getUserRolesListSuccess(f_item));
      localStorage.setItem('roles', JSON.stringify(f_item));
    } else {
      const errorMsgList =
        status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(userActionCreator.getUserRolesListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(userActionCreator.getUserRolesListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(userActionCreator.getUserRolesListFailure("Server Error"));
  }
}
