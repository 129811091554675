const CAMPAIGN_STATES = [
  {
    transition_id:'0',
    state_label:'Draft',
    action_label:'Draft'
  },
  {
    transition_id:'1',
    state_label:'In-review',
    action_label:'In-review'
  },
  {
    transition_id:'2',
    state_label:'Initializing',
    action_label:'Initialize'
  },
  {
    transition_id:'3',
    state_label:'Failed',
    action_label:'Fail'
  },
  {
    transition_id:'4',
    state_label:'Active',
    action_label:'Active'
  },
  {
    transition_id:'5',
    state_label:'Active: Not Delivering',
    action_label:'Active: Not Delivering'
  },
  {
    transition_id:'6',
    state_label:'Canceled',
    action_label:'Cancel'
  },
  {
    transition_id:'7',
    state_label:'Paused',
    action_label:'Pause'
  },
  {
    transition_id:'8',
    state_label:'',
    action_label:''
  },
  {
    transition_id:'9',
    state_label:'Resuming',
    action_label:'Resume'
  },
  {
    transition_id:'10',
    state_label:'Archived',
    action_label:'Archive'
  },
  {
    transition_id:'11',
    state_label:'Completed',
    action_label:'Complete'
  },
  {
    transition_id:'12',
    state_label:'Canceling',
    action_label:'Cancel'
  },
  {
    transition_id:'13',
    state_label:'Pausing',
    action_label:'Pause'
  },
  {
    transition_id:'14',
    state_label:'Approved',
    action_label:'Approve'
  },
  {
    transition_id:'15',
    state_label:'Denied',
    action_label:'Deny'
  },
  {
    transition_id:'16',
    state_label:'Expired',
    action_label:'Expire'
  },
  {
    transition_id:'17',
    state_label:'Completed-draft',
    action_label:'Complete-draft'
  },
  {
    transition_id:'18',
    state_label:'',
    action_label:''
  },
  {
    transition_id:'19',
    state_label:'Underperforming',
    action_label:'Underperform'
  }
];
export default CAMPAIGN_STATES;