import React from 'react';
import { Switch, Route, Router } from "react-router-dom";

import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from '../../components/HeaderNavigation';
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";

import OrganizationList from "../../components/Organizations/OrganizationList";
import CreateOrganization from "../../components/Organizations/CreateOrganization";
import EditOrganization from "../../components/Organizations/EditOrganization";

import "./index.scss";

import history from '../../history';

const Organizations = () => {
    return (
        <Router basename="/" history={history}>
          <>
            <Notifications />
            <HeaderNavigation />
            <div className="organization-list-container">
            {window.location.pathname === ROUTES.ORGANIZATIONS_LIST && <SideNavigation />}
                <div className='right-container'>
                    <Switch>
                        <Route exact path={ROUTES.ORGANIZATIONS_LIST} component={OrganizationList} />
                        <Route exact path={ROUTES.CREATE_ORGANIZATION} component={CreateOrganization} />
                        <Route exact path={ROUTES.EDIT_ORGANIZATION} component={EditOrganization} />
                    </Switch>
                </div>
            </div>
          </>
        </Router>
      );
}

export default Organizations; 
