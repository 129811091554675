import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoggedIn: false,
  dashboardDataRequestCompleted: false,
  dashboardCampaignMetricDataRequestCompleted: false,
  isSpendCtrCpaDataRequest: false,
  isSpendClicksConversionRequest: false,
  dashboardData: [],
  dashboardCampaignMetricData: [],
  spendCtrCpaData: [],
  spendClicksConversionData: [],
  isDSPCampaingsListRequest: false,
  dspCampaignsData: [],
  dspCampaignLineData: []
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DASHBOARD_REQUEST:
      return {
        ...state,
        dashboardDataRequestCompleted: false,
      };

    case ACTIONS.DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        dashboardDataRequestCompleted: true,
      };

    case ACTIONS.DASHBOARD_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        dashboardDataRequestCompleted: true,
        dashboardData: [],
      };

    case ACTIONS.DASHBOARD_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_REQUEST:
      return {
        ...state,
        dashboardCampaignMetricDataRequestCompleted: false,
      };

    case ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_SUCCESS:
      return {
        ...state,
        dashboardCampaignMetricData: action.payload,
        dashboardCampaignMetricDataRequestCompleted: true,
      };

    case ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        dashboardCampaignMetricDataRequestCompleted: true,
      };

    case ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.GET_SPEND_CTR_CPA_REQUEST:
      return {
        ...state,
        isSpendCtrCpaDataRequest: false,
      };

    case ACTIONS.GET_SPEND_CTR_CPA_SUCCESS:
      return {
        ...state,
        isSpendCtrCpaDataRequest: true,
        spendCtrCpaData: action.payload,
      };

    case ACTIONS.GET_SPEND_CTR_CPA_FAILURE:
      return {
        ...state,
        isSpendCtrCpaDataRequest: true,
        errorMsg: action.payload,
      };

    case ACTIONS.GET_SPEND_CTR_CPA_FAILURE_CLEAR:
      return {
        ...state,
        isSpendCtrCpaDataRequest: false,
        errorMsg: undefined,
      };

    case ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_REQUEST:
      return {
        ...state,
        isSpendClicksConversionRequest: false,
      };

    case ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_SUCCESS:
      return {
        ...state,
        isSpendClicksConversionRequest: true,
        spendClicksConversionData: action.payload,
      };

    case ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_FAILURE:
      return {
        ...state,
        isSpendClicksConversionRequest: true,
        errorMsg: action.payload,
      };

    case ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_FAILURE_CLEAR:
      return {
        ...state,
        isSpendClicksConversionRequest: false,
        errorMsg: undefined,
      };

    case ACTIONS.DSP_CAMPAIGNS_LIST_REQUEST:
      return {
        ...state,
        dspCampaignsData: action.payload,
        isDSPCampaingsListRequest: false,
      };

    case ACTIONS.DSP_CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        dspCampaignsData: action.payload,
        isDSPCampaingsListRequest: true,
      };

    case ACTIONS.DSP_CAMPAIGNS_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isDSPCampaingsListRequest: true,
      };

    case ACTIONS.DSP_CAMPAIGNS_LIST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };
    
    case ACTIONS.DSP_CAMPAIGN_LINE_LIST_REQUEST:
      return {
        ...state,
        dspCampaignLineData: action.payload,
        isDSPCampaingLineListRequest: false
      };

    case ACTIONS.DSP_CAMPAIGN_LINE_LIST_SUCCESS:
      return {
        ...state,
        dspCampaignLineData: action.payload,
        isDSPCampaingLineListRequest: true
      };

    case ACTIONS.DSP_CAMPAIGN_LINE_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isDSPCampaingLineListRequest: true
      };

    case ACTIONS.DSP_CAMPAIGN_LINE_LIST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
