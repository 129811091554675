import React from 'react';
import { Switch, Route, Router } from "react-router-dom";

import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from '../../components/HeaderNavigation';
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";

import BrandList from "../../components/Brands/BrandList";
import Brand from '../../components/Brands/Brand';
import "./index.scss";

import history from '../../history';


const Brands = () => {
    return (
        <Router basename="/" history={history}>
          <>
            <Notifications />
            <HeaderNavigation />
            <div className="brands-list-container">
            {window.location.pathname !== ROUTES.CREATE_BRAND && <SideNavigation />}
                <div className='right-container'>
                    <Switch>
                        <Route exact path={ROUTES.BRANDS_LIST} component={BrandList} />
                        {/* <Route exact path={ROUTES.CREATE_BRAND} component={CreateBrand} /> */}
                        {/* <Route exact path={ROUTES.EDIT_BRAND} component={CreateBrand} /> */}
                    </Switch>
                </div>
            </div>
          </>
        </Router>
      );
}

export default Brands; 
