import React from "react";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./index.scss";

const ADCalendarSingle = ({ handleDateChange, selectedDate = new Date(), closeCalendar, disablePastDays = false, minDateDisalble = new Date()}) => {
  
  const handleSelect = (newDate) => {
    handleDateChange(newDate);
    closeCalendar(false);
  }

  return (
    <div className="ad-date-component">
      <Calendar
        date={new Date(selectedDate)}
        onChange={handleSelect}
        monthDisplayFormat={'MMMM yyyy'}
        weekdayDisplayFormat={'EEEEEE'}
        direction="horizontal"
        color=" #3257FB"
        minDate={disablePastDays ? minDateDisalble : undefined}
      />
    </div>
  )
}

export default ADCalendarSingle;