import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { Button, Collapse, Label, Row, FormGroup, Input, Col, CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback } from "reactstrap";
import InfiniteScroll from 'react-infinite-scroll-component'
import SkeletonTable from '../../../components/Loader_Skeleton/SkeletonTable'
import BuilderAnswer from './BuilderAnswer';
import QuestionBranchingModal from './QuestionBranchingModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import TaxonomyQuestionList from './TaxonomyList/TaxonomyQuestionList';

const BuilderQuestion = (props) => {
  const [isNextQuestionFilterOpen, setIsNextQuestionFilterOpen] = useState(false);
  const [nextQuesDropDownData, setNextQuesDropDownData] = useState(props.nextQuestionDropdownData);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef();
  const {
    onDragEndAnswer,
    handleInputChange,
    deleteSelectedAns,
    deleteSelectedQuestion,
    addNewAnswer,
    changeNextQues,
    data,
    closeQuestionBranchModal,
    displayQuestionBranchModal,
    confirmQuestionChange,
    copySelectedQuestion,
    showDeleteModal,
    rtrType,
    questionObject,
    nextQuestion,
    nextOption,
    resetAnswerBranching,
    getTaxonomySelection,
    clearTaxonomySelection,
    taxonomyAnswerListOption,
    filterAnserListData,
    asset
  } = props;

  const userData = useSelector((state) => state.auth.userData);

  const ICONS = {
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    COPY: "/Icon_assets/svg/copy-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",
    ARROW_UP: "/Icon_assets/svg/toggle-up.svg",
    VALID_ICON: '/Icon_assets/svg/valid-tick-icon.svg',
    DRAG: "/Icon_assets/svg/Drag.svg",
    NEXT_QUES: "/Icon_assets/svg/next-question-arrow.svg",
  };

  const QUESTION_TYPE = {
    SINGLE_CHOICE: 'Single Choice',
    MULTIPLE_CHOICE: 'Multi Choice',
  }

  const QUESTION_TYPE_VALUE = {
    SINGLE_CHOICE: 'SS',
    MULTIPLE_CHOICE: 'MS',
  }

  const SHUFFLE_TYPE = {
    NONE: "None",
    RANDOM: "Random",
    REVERSE: "Reverse"
  }

  useEffect(() => {
    inputRef.current.focus();
  })

  const toggleNextQuestionFilter = () => {
    setIsNextQuestionFilterOpen(prev => !prev)
  }

  const getNextQuestName = (nQ, type) => {

    switch (type) {
      case 'Q': {
        if (nQ === '')
          return ''

        if (nQ?.includes('Toast'))
          return nQ

        return 'Question ' + Number((props?.data?.next_question.match(/[\d\.]+/g)))
      }
      case 'A': {
        if (nQ === '')
          return 'Default'

        if (nQ?.includes('Toast'))
          return nQ

        return 'Question ' + Number((nQ.match(/[\d\.]+/g)))

      }
      default: { }
    }
  }

  const searchQuesDropdown = (e) => {
    setSearchText(e.target.value)
    setNextQuesDropDownData(props.nextQuestionDropdownData.filter(item => item.mainLabel.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())))
  }

  useEffect(() => {
    setNextQuesDropDownData(props.nextQuestionDropdownData)
  }, [props.nextQuestionDropdownData])

  return (
    <section className={(props.adState !== 0 ? 'disable-builder' : '') + " builder-section"}>
      <div className="builder-content">
        <div className="builder-header">
          <span className="lbl-question-header">{`Question ${props?.data?.ID}`}</span>
          <img src={ICONS.NEXT_QUES} alt="next question" style={{ margin: '0 32px 0px 28px' }}></img>
          <Dropdown id='next-q-dropdown' className={`d-flex next-q-dropdown ${(rtrType === "Invisibly" && questionObject[questionObject.length - 1]?.ID === data?.ID) ? "disable-platform" : ""}`} isOpen={isNextQuestionFilterOpen} toggle={toggleNextQuestionFilter}>
            <DropdownToggle >
              <div className='dr-button'>
                <div style={{ display: 'flex' }}>
                  <div className='dt-title'>{`Next: ${getNextQuestName(props?.data?.next_question, 'Q')}`}</div>
                </div>
                <div>
                  <img style={{
                    display: "inline-block",
                    margin: '0 12px',
                    transform: isNextQuestionFilterOpen ? 'rotate(180deg)' : ''
                  }}
                    src={
                      ICONS.ARROW_DOWN
                    }
                    className="arrow-down"
                    alt='next-q-filter'
                  />
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu id='next-q-dropdown-menu'>
              <div className='search-wrapper'>
                <Input
                  value={searchText}
                  type="text"
                  name="search"
                  id="searchUser"
                  placeholder="Search..."
                  className="search-input"
                  autocomplete="off"
                  onChange={(event) => { searchQuesDropdown(event) }}
                  disabled={false}
                />
                <img
                  src="/Icon_assets/svg/search-organization-icon.svg"
                  alt="Search"
                  className="search-image"
                />
              </div>
              <div className='next-q-list' id="nextQDropDown">
                <InfiniteScroll
                  dataLength={nextQuesDropDownData?.length}
                  next={() => { }}
                  hasMore={false}
                  loader={<SkeletonTable rows={10} />}
                  scrollableTarget="nextQDropDown"
                >
                  {nextQuesDropDownData.filter((item) => item.id !== data.ID).map((item, index) => <div style={{ width: '100%' }}
                    key={index}
                    className={"radio-list"}
                    onClick={() => {
                      setSearchText('');
                      setNextQuesDropDownData(props.nextQuestionDropdownData);
                      changeNextQues(data.ID, item)
                    }}
                  >
                    <DropdownItem toggle={true}>
                      <CustomInput type="radio" name="radio1"
                        className={"custom-radio-input"}
                        style={{ width: '100%' }}
                        id={`radio-${index}`}
                        label={
                          <div style={{ width: '100%' }}>
                            <div className={`dr-title ${((item.mainLabel.replace(/\s/g, '') === props.data.next_question.replace(/\s/g, '') ||
                              (item.mainLabel.replace('Question', 'Message').replace(/\s/g, '')) === props.data.next_question.replace(/\s/g, ''))) ? "active" : ""}`} id={`Org-Name-Tooltip-${index}`}>{item.mainLabel}</div>
                            <div className='dr-title sub' title={item.subLabel}>{item.subLabel}</div>
                          </div>
                        }
                        checked={((item.mainLabel.replace(/\s/g, '') === props.data.next_question.replace(/\s/g, '') ||
                          (item.mainLabel.replace('Question', 'Message').replace(/\s/g, '')) === props.data.next_question.replace(/\s/g, '')))}
                        value={data.ID}
                      />
                    </DropdownItem>
                  </div>)
                  }
                </InfiniteScroll>
              </div>
            </DropdownMenu>
          </Dropdown>
          {(userData.organization_role === 0) && rtrType !== "Programmatic" && <TaxonomyQuestionList data={data} getTaxonomySelection={getTaxonomySelection} clearTaxonomySelection={clearTaxonomySelection} />}
          <div className={(props.questionObject?.length === 1 ? 'disable-icon' : '') + " img-container"}
            style={{ marginLeft: "auto" }}
            onClick={(e) => showDeleteModal('question', props.data)}>
            <img src={ICONS.DELETE} alt="delete question"
              style={{ width: "18px", height: "20px" }} />
          </div>
          <div
            className={`img-container copy-question ${props?.questionObject?.length >= 25 ? 'disable-builder' : ''}`}
            onClick={(e) => copySelectedQuestion(e, props.data)}
          >
            <img src={ICONS.COPY} alt="copy question" />
          </div>
        </div>
        <div className="builder-data">
          <Row>
            <Col >
              <Label className="lbl-question">Question</Label>
              <FormGroup >
                <Input
                  ref={inputRef}
                  type="text"
                  name="question"
                  id="question"
                  placeholder='Enter your question here'
                  required
                  className="form-input form-input-sm mt-2 input-question required"
                  autoComplete="off"
                  value={data.question || ''}
                  onChange={(e) => handleInputChange(e, data)}
                />
                {data.question === '' && <FormFeedback>Question can not be empty.</FormFeedback>}
                {data?.question?.length > 80 && asset.template_type?.id === 9 && <FormFeedback>Question can have maximum of 80 characters</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ marginBottom: "42px" }}>
                <Label className="lbl-question">Question Type</Label>
                <div style={{ marginTop: "15px" }}>
                  <CustomInput
                    type="radio"
                    style={{ marginRight: "27px" }}
                    name="question_type"
                    className={"custom-radio-input lbl-type"}
                    id="radio1"
                    label={QUESTION_TYPE.SINGLE_CHOICE}
                    value={QUESTION_TYPE_VALUE.SINGLE_CHOICE}
                    checked={data.question_type === 'SS'}
                    // checked={false}
                    onChange={(e) => handleInputChange(e, data, QUESTION_TYPE.SINGLE_CHOICE)} />

                  <CustomInput type="radio"
                    name="question_type"
                    className={"custom-radio-input lbl-type"}
                    id="radio2"
                    label={QUESTION_TYPE.MULTIPLE_CHOICE}
                    value={QUESTION_TYPE_VALUE.MULTIPLE_CHOICE}
                    checked={data.question_type === 'MS'}
                    onChange={(e) => handleInputChange(e, data, QUESTION_TYPE.MULTIPLE_CHOICE)} />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ marginBottom: "42px" }}>
                <Label className="lbl-question">Shuffle Type</Label>
                <div style={{ marginTop: "15px" }}>
                  <CustomInput
                    type="radio"
                    style={{ marginRight: "27px" }}
                    name="shuffle_type"
                    className={"custom-radio-input lbl-type"}
                    id="shuffle_radio1"
                    label={SHUFFLE_TYPE.NONE}
                    value={SHUFFLE_TYPE.NONE}
                    checked={data.shuffle_type === SHUFFLE_TYPE.NONE}
                    onChange={(e) => handleInputChange(e, data, SHUFFLE_TYPE.NONE)} />

                  <CustomInput type="radio"
                    name="shuffle_type"
                    className={"custom-radio-input lbl-type"}
                    id="shuffle_radio2"
                    label={SHUFFLE_TYPE.RANDOM}
                    value={SHUFFLE_TYPE.RANDOM}
                    checked={data.shuffle_type === SHUFFLE_TYPE.RANDOM}
                    onChange={(e) => handleInputChange(e, data, SHUFFLE_TYPE.RANDOM)} />

                  <CustomInput type="radio"
                    name="shuffle_type"
                    className={"custom-radio-input lbl-type"}
                    id="shuffle_radio3"
                    label={SHUFFLE_TYPE.REVERSE}
                    value={SHUFFLE_TYPE.REVERSE}
                    checked={data.shuffle_type === SHUFFLE_TYPE.REVERSE}
                    onChange={(e) => handleInputChange(e, data, SHUFFLE_TYPE.REVERSE)} />
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label className="lbl-question">Answers</Label>
                <DragDropContext onDragEnd={onDragEndAnswer}>
                  <Droppable droppableId="answer-drag-list">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {data?.answer.map((ans, i) => {
                          return (
                            <Draggable draggableId={ans.id} index={i}>
                              {provided => (
                                <BuilderAnswer
                                  prevAnswer={data?.answer[0].answer1}
                                  ref={provided.innerRef}
                                  provided={provided}
                                  disableDelete={data.answer?.length === 2}
                                  ansId={`Q-${data.ID}-ans-${ans.id}`}
                                  data={data}
                                  key={`Q-${data.ID}-ans-${ans.id}`}
                                  id={`Q-${data.ID}-ans-${ans.id}`}
                                  answerPixel={ans[`${ans.id}_pixel`]}
                                  answerBranch={ans[`${ans.id}_branch`]}
                                  answer={ans[`${ans.id}`]}
                                  answerToggle={ans[`${ans.id}_toggle`]}
                                  index={i}
                                  ansName={`${ans.id}`}
                                  ansNamePixel={`${ans.id}_pixel`}
                                  ansNameBranch={`${ans.id}_branch`}
                                  ansNameToggle={`${ans.id}_toggle`}
                                  handleInputChange={handleInputChange}
                                  deleteSelectedAns={deleteSelectedAns}
                                  nextQuestionDropdownData={props.nextQuestionDropdownData}
                                  getNextQuestName={getNextQuestName}
                                  changeAnswerBranch={props.changeAnswerBranch}
                                  showDeleteModal={showDeleteModal}
                                  lastQuestion={questionObject[questionObject.length - 1]?.ID === data?.ID}
                                  invisiblyType={rtrType === "Invisibly"}
                                  questionChoice={data.question_type}
                                  resetAnswerBranching={resetAnswerBranching}
                                  getTaxonomySelection={getTaxonomySelection}
                                  rtrType={rtrType}
                                  asset={asset}
                                  taxonomyAnswerListOption={taxonomyAnswerListOption}
                                  filterAnserListData={filterAnserListData}
                                  clearTaxonomySelection={clearTaxonomySelection}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </FormGroup>
            </Col>
          </Row>

          {(data?.answer?.length < 6) && props.asset.template_type?.id === 5 &&
            <Button className="add-more-answer"
              onClick={() => addNewAnswer(data, data.answer)}>
              +  Add Answer
            </Button>
          }
        </div>
      </div>
      {displayQuestionBranchModal &&
        <QuestionBranchingModal
          display={displayQuestionBranchModal}
          closePopup={closeQuestionBranchModal}
          confirmQuestionChange={confirmQuestionChange}
          dataID={data.ID}
          nextQuestion={nextQuestion}
          nextOption={nextOption}
        // itemID={item.ID} 
        />
      }
    </section >

  )


}

export default BuilderQuestion;