import ACTIONS from '../constants/actionTypes';

const initialState = {
  reportData: [],
  isLoading: false,
  isFetched: false,
  dimensions: [],
  isDimensionsLoading: false,
  isDimensionsFetched: false,
  metrics: [],
  isMetricsLoading: false,
  isMetricsFetched: false,
  reportTemplateList: [],
  templatesFetching: false,
  templatesFetched: false,
  selectedTemplate: {},
  isTemplateSaving: undefined,
  isTemplateSaved: undefined,
  templateData: {},
  isTemplateUpdating: false,
  isTemplateUpdated: false,
  isTemplateDeleting: false,
  isTemplateDeleted: false,
};

const reportBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DIMENSIONS_LIST_REQUEST:
      return {
        ...state,
        dimensions: action.payload,
        isDimensionsLoading: true,
        isDimensionsFetched: false
      };

    case ACTIONS.DIMENSIONS_LIST_SUCCESS:
      return {
        ...state,
        dimensions: action.payload,
        isDimensionsLoading: false,
        isDimensionsFetched: true
      };

    case ACTIONS.DIMENSIONS_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        dimensions: [],
        isDimensionsLoading: false,
        isDimensionsFetched: false
      };

    case ACTIONS.DIMENSIONS_LIST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.METRICS_LIST_REQUEST:
      return {
        ...state,
        metrics: action.payload,
        isMetricsLoading: true,
        isMetricsFetched: false
      };

    case ACTIONS.METRICS_LIST_SUCCESS:
      return {
        ...state,
        metrics: action.payload,
        isMetricsLoading: false,
        isMetricsFetched: true
      };

    case ACTIONS.METRICS_LIST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        metrics: [],
        isMetricsLoading: false,
        isMetricsFetched: false
      };

    case ACTIONS.METRICS_LIST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.RUN_REPORT_REQUEST:
      return {
        ...state,
        reportData: action.payload,
        isLoading: true,
        isFetched: false
      };

    case ACTIONS.RUN_REPORT_SUCCESS:
      return {
        ...state,
        reportData: action.payload,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.RUN_REPORT_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        reportData: [],
        isLoading: false,
        isFetched: false
      };

    case ACTIONS.RUN_REPORT_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.RUN_REPORT_CLEAR_ALL:
      return {
        ...state,
        errorMsg: undefined,
        reportData: [],
        isLoading: false,
        isFetched: false
      };

    case ACTIONS.GET_REPORT_TEMPLATES_REQUEST:
      return {
        ...state,
        reportTemplateList: [],
        templatesFetching: true,
        templatesFetched: false
      };

    case ACTIONS.GET_REPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        reportTemplateList: action.payload,
        templatesFetching: false,
        templatesFetched: true
      };

    case ACTIONS.GET_REPORT_TEMPLATES_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        reportTemplateList: [],
        templatesFetching: false,
        templatesFetched: false
      };

    case ACTIONS.GET_REPORT_TEMPLATES_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.SET_REPORT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };

    case ACTIONS.RESET_REPORT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: {},
      };

    case ACTIONS.SAVE_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        templateData: action.payload,
        isTemplateSaving: true,
        isTemplateSaved: false,
      };

    case ACTIONS.SAVE_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
        isTemplateSaving: false,
        isTemplateSaved: true,
      };

    case ACTIONS.SAVE_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        templateData: {},
        isTemplateSaving: false,
        isTemplateSaved: false
      };

    case ACTIONS.SAVE_REPORT_TEMPLATE_FAILURE_CLEAR:
      return {
        ...state,
        isTemplateSaving: false,
        isTemplateSaved: false,
        errorMsg: undefined,
      };

    case ACTIONS.UPDATE_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        templateData: action.payload,
        isTemplateUpdating: true,
        isTemplateUpdated: false,
      };

    case ACTIONS.UPDATE_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
        isTemplateUpdating: false,
        isTemplateUpdated: true,
      };

    case ACTIONS.UPDATE_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        templateData: {},
        isTemplateUpdating: false,
        isTemplateUpdated: false
      };

    case ACTIONS.UPDATE_REPORT_TEMPLATE_FAILURE_CLEAR:
      return {
        ...state,
        isTemplateUpdating: false,
        isTemplateUpdated: false,
        errorMsg: undefined,
      };

    case ACTIONS.DELETE_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        templateData: action.payload,
        isTemplateDeleting: true,
        isTemplateDeleted: false,
      };

    case ACTIONS.DELETE_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
        isTemplateDeleting: false,
        isTemplateDeleted: true,
      };

    case ACTIONS.DELETE_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        templateData: {},
        isTemplateDeleting: false,
        isTemplateDeleted: false
      };

    case ACTIONS.DELETE_REPORT_TEMPLATE_FAILURE_CLEAR:
      return {
        ...state,
        isTemplateDeleting: false,
        isTemplateDeleted: false,
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default reportBuilderReducer;