import OFFER_ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isCreating: false,
  offerData: [],
  createOffer: [],
  isOfferDeleteRequest: false,
  isOfferCloneRequest: false,
  isOfferDeleted: false,
  isOfferCloned: false,
  offerDeleteResponseData: null,
  offerCloneResponseData: null,
  errorMsg: undefined,
  imageLoading: false,
  blobUrl: "",
  offerRequest:{}
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_ACTIONS.GET_OFFER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case OFFER_ACTIONS.GET_OFFER_LIST_SUCESS:
      return {
        ...state,
        isLoading: false,
        offerData: action.payload
      }

    case OFFER_ACTIONS.GET_OFFER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        offerData: []
      }

    case OFFER_ACTIONS.GET_OFFER_LIST_FAILURE_CLEAR:
      return {
        ...state,
        isLoading: false,
        offerData: []
      }

    case OFFER_ACTIONS.POST_OFFER_CREATION_REQUEST:
      return {
        ...state,
        // offerRequest: action.payload,
        isCreating: true
      }

    case OFFER_ACTIONS.POST_OFFER_SUCESS:
      return {
        ...state,
        isCreating: false,
        offerRequest: action.payload
      }

    case OFFER_ACTIONS.POST_OFFER_FAILURE:
      return {
        ...state,
        isCreating: false,
        offerRequest: []
      }

    case OFFER_ACTIONS.POST_OFFER_FAILURE_CLEAR:
      return {
        ...state,
        isCreating: false,
        offerRequest: []
      }
    case OFFER_ACTIONS.DELETE_OFFER_REQUEST:
      return {
        ...state,
        isOfferDeleteRequest: true,
        offerDeleteResponseData: null
      };

    case OFFER_ACTIONS.DELETE_OFFER_SUCCESS:
      return {
        ...state,
        isOfferDeleteRequest: false,
        isOfferDeleted: true,
        offerDeleteResponseData: action.payload
      };

    case OFFER_ACTIONS.DELETE_OFFER_FAILURE:
      return {
        ...state,
        isOfferDeleteRequest: false,
        isOfferDeleted: false,
        errorMsg: action.payload
      };

    case OFFER_ACTIONS.DELETE_OFFER_CLEAR:
      return {
        ...state,
        offerDeleteResponseData: null,
        isOfferDeleteRequest: false,
        isOfferDeleted: false,
        errorMsg: undefined
      };
    
    case OFFER_ACTIONS.CLONE_OFFER_REQUEST:
      return {
        ...state,
        isOfferCloneRequest: true,
        offerCloneResponseData: null
      };

    case OFFER_ACTIONS.CLONE_OFFER_SUCCESS:
      return {
        ...state,
        isOfferCloneRequest: false,
        isOfferCloned: true,
        offerCloneResponseData: action.payload
      };

    case OFFER_ACTIONS.CLONE_OFFER_FAILURE:
      return {
        ...state,
        isOfferCloneRequest: false,
        isOfferCloned: false,
        errorMsg: action.payload
      };

    case OFFER_ACTIONS.CLONE_OFFER_CLEAR:
      return {
        ...state,
        offerCloneResponseData: null,
        isOfferCloneRequest: false,
        isOfferCloned: false,
        errorMsg: undefined
      };  
    
    case OFFER_ACTIONS.GET_UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        errorMsg: "",
        imageLoading: false
      }

    case OFFER_ACTIONS.GET_UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        errorMsg: "",
        imageLoading: true
      }

    case OFFER_ACTIONS.GET_UPLOAD_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        blobUrl: action.payload["blob_url"],
        imageLoading: false
      }

    case OFFER_ACTIONS.GET_UPLOAD_MEDIA_REQUEST_CLEAR:
      return {
        ...state,
        blobUrl: "",
        errorMsg: "",
        imageLoading: false
      }

      case OFFER_ACTIONS.GET_OFFER_LIST_BY_ID_REQUEST:
        return {
          ...state,
          isLoading: true
        }
  
      case OFFER_ACTIONS.GET_OFFER_LIST_BY_ID_SUCCESS:
        return {
          ...state,
          isLoading: false,
          offerData: action.payload
        }
  
      case OFFER_ACTIONS.GET_OFFER_LIST_BY_ID_FAILURE:
        return {
          ...state,
          isLoading: false,
          offerData: []
        }
    default:
      return state;
  }
}

export default offerReducer;