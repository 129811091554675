import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import "./index.scss";

import Validator from "../../utils/validators";
import ROUTES from "../../utils/routeConstants";

import LeftSection from "../../components/LeftSection";
import Notifications from "../../components/Notifications";
import ADspinner from "../../components/Loader/ADspinner";
import { sendSignInRequest } from "../../actions/authActions";
import history from "../../history";

const validators = new Validator();

const SignIn = () => {
  /*** State init ***/
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });
  const [show_input, setShow_input] = useState(false);
  const [isValid, setIsValid] = useState(false);
  /*** State init end ***/

  /*** Redux init ***/
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;
  /*** Redux end ***/
  
  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = validators.email.valid && validators.password.valid;
    setIsValid(checkValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validators.isFormValid(inputValues);
    if (isValid) {
      dispatch(sendSignInRequest(inputValues));
    }
    setIsValid(isValid);
  };

  const togglePasswordShow = () => {
    setShow_input(!show_input);
  };

  useEffect(() => {
    if(isLoggedIn){
      if (userData.organization_role === 0) {
        //admin access
        //return <Redirect to={ROUTES.DASHBOARD} />
        history.push(ROUTES.DASHBOARD)
      }else{
        //Reporting user access
        if (is_media_enabled) {
          //return <Redirect to={ROUTES.DASHBOARD} />
          history.push(ROUTES.DASHBOARD)
        }

        if (is_media_enabled && !is_media_enabled) {
          //return <Redirect to={ROUTES.REALTIME_RESEARCH} />
          history.push(ROUTES.REALTIME_RESEARCH)
        }
      }
    }
  }, [isLoggedIn])
  

  return (
    <>
      <section className="main-container">
        <LeftSection pagename={"signin"} bgclass={"purple"} />

        <section className="right-section" style={{paddingTop:'263px'}}>
          <h2>Sign in</h2>
          {/* <p className="title-text">
            Access your reporting dashboard to review your campaign performance.
          </p> */}

          <Notifications />
          
          <Form className="user-form-container" onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="text"
                name="email"
                id="email"
                value={inputValues.email}
                className="form-input form-input-sm"
                autoComplete="off"
                onChange={handleInputChange}
                placeholder="Email address"
                invalid={validators.email.errorMsg !== ""}
              />
              <FormFeedback>{validators.email.errorMsg}</FormFeedback>
            </FormGroup>
            <FormGroup className="password-input-section">
              <Input
                type={show_input ? "text" : "password"}
                name="password"
                id="password"
                value={inputValues.password}
                className="form-input form-input-sm"
                autoComplete="off"
                onChange={handleInputChange}
                placeholder="Password"
                invalid={validators.password.errorMsg !== ""}
              />
              <span className="show-hide-text" onClick={togglePasswordShow}>
                {show_input ? "Hide" : "Show"}
              </span>
              <FormFeedback>{validators.password.errorMsg}</FormFeedback>
            </FormGroup>
            <FormGroup className="actions">
              <Button
                className="in-btn-lg in-btn-primary"
                type="submit"
                onClick={handleSubmit}
                disabled={isValid ? false : true}
                style={{minWidth:'194px'}}
              > 
                {isLoading ? 
                  <ADspinner size="large" /> : 
                  (!validators.email.errorMsg && validators.email.touched ? "Get started" : "Sign in") 
                }
              </Button>

              <Link to={ROUTES.FORGOT_PASSWORD} className="forgot-password-link">
                Forgot password?
              </Link>
            </FormGroup>
          </Form>

          {/* <div className="sign-in-section">
            <span className="text">Don’t have an account?</span>
            <Link to={ROUTES.SIGNUP} className="link">
              Sign up
            </Link>
          </div> */}
        </section>
      </section>
    </>
  );
};

export default SignIn;
