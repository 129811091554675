import React, { useEffect, useState } from 'react';
import GenericQuestionMenuElement from './GenericQuestionMenuElement';
import GenericAnswerMenuElement from './GenericAnswerMenuElement';
import AnswerMenuItem from './AnswerMenuItem';
import QuestionMenuItem from './QuestionMenuItem';
import '../index.scss';

const GenerateItemList = (props) => {
  const [taxonomyListOption, setTaxonomyListOption] = useState([]);
  const {taxonomyQuestionListData,taxonomyAnswerListData,data,getTaxonomySelection,answerId} = props;

  useEffect(() => {
    if (taxonomyQuestionListData && taxonomyQuestionListData.length) {
      loadQuestionListStructure();
    }
  }, [taxonomyQuestionListData]);

  useEffect(() => {
    if (taxonomyAnswerListData && taxonomyAnswerListData.length) {
      loadAnswerListStructure();
    }
  }, [taxonomyAnswerListData]);

  const questionMenuItem = (item, i) => {
    let menuItem;
    if (item.child.length === 0) {
      let menuItemChildren;
      menuItemChildren = (
        <QuestionMenuItem
          name="taxonomy_question"
          index={i}
          item={item}
          getTaxonomySelection={getTaxonomySelection}
          data={data}
        />
      )
      menuItem = (
        <div className="individual-category-wrapper leaf-node-child">
          <GenericQuestionMenuElement
            item={item}
            menuItemChildren={menuItemChildren}
            name="taxonomy_question"
            index={i}
            getTaxonomySelection={getTaxonomySelection}
            data={data}
          />
        </div>
      )
    } else {
      let menuItemChildren = item.child.map((item, i) => {
        let menuItem = questionMenuItem(item, i);
        return menuItem;
      });
      menuItem = (
        <div key={i} className={`individual-category-wrapper ${item.parent !== undefined ? 'non-first-level-child' : ''}`}>
          <GenericQuestionMenuElement 
            item={item}
            menuItemChildren={menuItemChildren}
            name="taxonomy_question"
            index={i}
            getTaxonomySelection={getTaxonomySelection}
            data={data}
          />
        </div>
      );
    }
    return menuItem;
  };

  const loadQuestionListStructure = async () => {
    let menuItems = taxonomyQuestionListData.map((item, i) => {
      let menuItem = questionMenuItem(item, i);
      return menuItem;
    });
    setTaxonomyListOption(menuItems);
  };

  const loadAnswerListStructure = async () => {
    let menuItems = taxonomyAnswerListData.map((item, i) => {
      let menuItem = answerMenuItem(item, i);
      return menuItem;
    });
    setTaxonomyListOption(menuItems);
  }

  const answerMenuItem = (item, i) => {
    let menuItem;
    let menuItemChildren;
    menuItemChildren = (
      <AnswerMenuItem
        name="taxonomy_answer"
        index={i}
        item={item}
        getTaxonomySelection={getTaxonomySelection}
        data={data}
        answerId={answerId}
      />
    )
    menuItem = (
      <div className="answer-item-wrapper">
        <GenericAnswerMenuElement
          menuItemChildren={menuItemChildren}
        />
      </div>
    )
    return menuItem;
  };

  return (
    <>
      {taxonomyListOption}
    </>
  )
}

export default GenerateItemList;