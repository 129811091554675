import React, { useEffect, useState } from 'react';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

charts(FusionCharts);

FusionCharts.options.license({
  key:
    "kJ-7B9snlC4E3A3G2B1B4B1C6D7B3F1F2gzzB2A6D2zE1A2E1oreC7E2B4cetH3C10D7gyF-10D3E2D2H4B2C8D2C5B2C1C1I-8vvB1E6ED1fbqA32B13B9d1sB4TF4D3iizA5C5E3C5C4A3D3A2B9A6C3F5E4F1i1==",
  creditLabel: false,
});


function hex(c) {
  var s = "0123456789abcdef";
  var i = parseInt(c);
  if (i == 0 || isNaN(c))
    return "00";
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
function convertToHex(rgb) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s) { return (s.charAt(0) == '#') ? s.substring(1, 7) : s }

/* Convert a hex string to an RGB triplet */
function convertToRGB(hex) {
  var color = [];
  color[0] = parseInt((trim(hex)).substring(0, 2), 16);
  color[1] = parseInt((trim(hex)).substring(2, 4), 16);
  color[2] = parseInt((trim(hex)).substring(4, 6), 16);
  return color;
}

function generateColor(colorStart, colorEnd, colorCount) {
  // The beginning of your gradient
  var start = convertToRGB(colorStart);

  // The end of your gradient
  var end = convertToRGB(colorEnd);

  // The number of colors to compute
  var len = colorCount;

  //Alpha blending amount
  var alpha = 0.0;

  var saida = [];
  let i;
  for (i = 0; i < len; i++) {
    var c = [];
    alpha += (1.0 / len);

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    saida.push(convertToHex(c));

  }
  return saida;
}


const Chart = (props) => {

  const [palette, setPalette] = useState(generateColor('#945FF6', '#E4D7F0', props.data.length))
  const size = useWindowSize();

  const data = {
    chart: {
      usePlotGradientColor: "0",
      showPlotBorder: 0,
      numberSuffix: "%",
      primaryAxisOnLeft: "0",
      patternSize: 10,
      theme: "fusion",
      showCanvasBorder: 0,
      canvasBgColor: "FFFFFF",
      showBorder: 0,
      bgAlpha: "0",
      divLineColor: "#EFEFEF",
      showAlternateHGridColor: "0",
      showToolTip: "0",
      valueFontBold: 1,
      valueFontSize: 14,
      valueFontColor: "#000000",
      valueFont: "BasisGrotesque",
      labelFont: "BasisGrotesque",
      labelFontSize: 14,
      labelFontColor: "#535559",
      yAxisValueFont: "BasisGrotesque",
      yAxisValueFontSize: 14,
      yAxisValueFontColor: "#000000",
      numDivLines: size.width < 768 ? 0 : 3,
      yAxisMinValue: 0,
      yAxisMaxValue: 100,
      adjustDiv: 0,
      labelDisplay: 'auto',
      useEllipsesWhenOverflow: 1,
      showChartLoadingMessage: 1,
      animation: 0,
      maxLabelHeight: 50,
      labelPadding: 15,
      plotSpacePercent: 10
    },
    "data": props.data.map((d, index) => { return { ...d, color: palette[index] } }),
    events: {
      defaultPrevented: true
    }
  }

  return <div style={{ pointerEvents: size.width < 768 ? 'none' : '' }}>
    <ReactFusioncharts
      type={size.width < 768 ? "bar2d" : 'column2d'}
      width="100%"
      height="400"
      dataFormat="json"
      dataSource={data}
    />
  </div>
}

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default Chart;
