import ACTIONS from "../constants/actionTypes";

const initialState = {
  isStatesLoading: undefined,
  states: [],
  isCitiesLoading: undefined,
  cities: [],
};

const filterReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTIONS.STATES_REQUEST:
      return {
        ...state,
        states: action.payload,
        isStatesLoading: true
      };
    case ACTIONS.STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        isStatesLoading: false
      };
    case ACTIONS.CITY_REQUEST:
      return {
        ...state,
        cities: action.payload,
        isCitiesLoading: true
      };
    case ACTIONS.CITY_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        isCitiesLoading: false
      };
    case ACTIONS.CITY_FAILURE_CLEAR:
      return {
        ...state,
        cities: [],
        isCitiesLoading: undefined
      };
    default: {
      return state;
    }
  }
}

export default filterReducer;
