import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Label,
  Input,
  UncontrolledTooltip, FormGroup,
  FormFeedback, Table, Button, CustomInput
} from "reactstrap";
import Loader from '../../Loader';
import "./index.scss";

const MediaFilter = props => {

  const MEDIA_TYPE = {
    IV: "Image/Video",
    STREAM: "Stream",
    HOSTED_MEDIA: "Hosted Media"
  };

  const uploadLogo = (
    <Button className="export-icon" style={{ opacity: "1" }} onClick={() => console.log('export button')}>
      <img src={"/Icon_assets/svg/export-icon.svg"} alt="export csv" />
      <span className="span-label">Upload Image/Video</span>
    </Button>);

  const {
    keySize,
    executeScroll,
    mediaUrl,
    removeSelection,
    imageLoading,
    radioId,
    id,
    removeFilter,
    keyUrl,
    keyType,
    keyName,
    handleInputChange,
    handleMediaUpload,
    validators,
    inputValues } = props;

  return (

    <div className="ad-form" key={id}>
      <Table className="ad-table" key={id}>
        <hr />
        <Row>
          <Col md={24} sm={24} lg={8}>
            <FormGroup style={{ position: "relative" }} id={`type${radioId}`} >
              <div style={{ display: "flex" }}>
                <div className={`floating-label`} style={{ top: "2px", paddingTop: "10px", position: "inherit" }}>{`Select Media`}</div>
                <div>
                  <img src={"/Icon_assets/svg/info-icon.svg"}
                    style={{ padding: "10px", paddingTop: "16px" }} alt="Info icon" id={`opentooltip${radioId}`} />
                  <UncontrolledTooltip
                    placement="top"
                    className="ad-tool-tip"
                    target={`opentooltip${radioId}`}
                  >
                    <div style={{ padding: "10px", marginLeft: "10px" }}>
                      <ul style={{ textAlign: "left", marginBottom: "0", fontSize: "12px" }}>
                        <li>Supported image files types: gif, jpg,and png</li>
                        <li>Recommended image file size: less than 2MB</li>
                        <li>Supported video files types: mp3/mp4, mov</li>
                        <li>Recommended video file size: less then 50MB</li>
                      </ul>
                    </div>
                  </UncontrolledTooltip>
                </div>
                {((inputValues[keyType].toLowerCase() === "image" && inputValues[keySize] >= 2000000) ||
                  (inputValues[keyType].toLowerCase() === "video" && inputValues[keySize] >= 50000000))
                  &&
                  <div>
                    <img src={"/Icon_assets/png/warning-icon.png"}
                      style={{ padding: "6px", height: "28px", marginTop: "10px" }} alt="Info icon" id={`warningTip${radioId}`} />
                    <UncontrolledTooltip
                      placement="top"
                      className="ad-tool-tip"
                      target={`warningTip${radioId}`}
                    ><div style={{ fontSize: "12px" }}>Uploaded file is larger than the recommended file size which may slow down your offer rendering</div>
                    </UncontrolledTooltip>
                  </div>}
                <div className="close-button-wrapper" style={{ paddingLeft: "9%", paddingTop: "8px" }}>
                  <img
                    src={"/Icon_assets/svg/blue_close_icon.svg"}
                    alt="Close Icon"
                    className="close-filter"
                    onClick={(e) => {
                      removeFilter(e, radioId);
                      executeScroll();
                    }}
                    id={`remove-dimention-filter-${id}`}
                  />
                  <UncontrolledTooltip
                    className="ad-tool-tip"
                    placement="top"
                    target={`remove-dimention-filter-${id}`}
                  >{"Remove Media"}
                  </UncontrolledTooltip>
                </div>
              </div>

              <div id={`media-${id + 1}`}>
                <CustomInput type="radio"
                  style={{ marginRight: "27px" }}
                  name={keyType}
                  className={"custom-radio-input"}
                  id={`media-radio${radioId}-1`}
                  label={MEDIA_TYPE.IV}
                  value={"image"}
                  checked={inputValues[keyType].toLowerCase() === "image" || inputValues[keyType].toLowerCase() === "video"}
                  onChange={(e) => handleInputChange(e, "image")} />


                <CustomInput type="radio"
                  name={keyType}
                  className={"custom-radio-input"}
                  id={`media-radio${radioId}-2`}
                  label={MEDIA_TYPE.HOSTED_MEDIA}
                  value={MEDIA_TYPE.STREAM.toLowerCase()}
                  checked={inputValues[keyType] === MEDIA_TYPE.STREAM.toLowerCase()}
                  onChange={(e) => handleInputChange(e, MEDIA_TYPE.STREAM)} />

              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={24} sm={24} lg={8}>
            {inputValues[keyType].toLowerCase() !== MEDIA_TYPE.STREAM.toLowerCase() ?
              ((imageLoading && mediaUrl === keyUrl) ?
                <Loader style={{ padding: "0px" }} />
                :
                (inputValues[keyName] ?
                  <div className="selection-div">
                    <img src={"/Icon_assets/svg/export-icon.svg"} alt="export`" />
                    <span className="span-label">{inputValues[keyName]}</span>
                    <img src={"/Icon_assets/svg/close-image.svg"} style={{ marginLeft: '15px', cursor: 'pointer' }} alt="close"
                      name={keyName} onClick={(e) => removeSelection(e)} />
                  </div>
                  :
                  <div>
                    {uploadLogo}
                    <Input
                      type="file"
                      name={keyUrl}
                      id={keyUrl}
                      className="custom-file-upload-input"
                      onChange={(e) => handleMediaUpload(e)}
                    />

                  </div>))
              :
              <FormGroup >
                <Input
                  type="text"
                  name={keyUrl}
                  id={keyUrl}
                  required
                  className="form-input form-input-sm mt-2 required"
                  autoComplete="off"
                  value={inputValues[keyUrl]}
                  onChange={handleInputChange}
                  invalid={validators[keyUrl].errorMsg !== ""}
                />
                <Label className="floating-heading-label required">Media URL</Label>
                <FormFeedback>{validators[keyUrl].errorMsg}</FormFeedback>
              </FormGroup>
            }
          </Col>
          <Col>
            {/* <div className="close-button-wrapper">
              <img
                src={"/Icon_assets/svg/blue_close_icon.svg"}
                alt="Close Icon"
                className="close-filter"
                onClick={(e) => removeFilter(e, radioId)}
                id={`remove-dimention-filter-${id}`}
              />
              <UncontrolledTooltip
                className="ad-tool-tip"
                placement="top"
                target={`remove-dimention-filter-${id}`}
              >{"Remove Media"}
              </UncontrolledTooltip>
            </div> */}
          </Col>
        </Row>
      </Table>
    </div >
  )
}

export default MediaFilter;