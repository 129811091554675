import ACTIONS from '../constants/actionTypes';

//DIMENSIONS LIST
export const dimensionsListRequest = (data) => {
  return {
    type: ACTIONS.DIMENSIONS_LIST_REQUEST,
    payload: data,
  };
};

export const dimensionsListSuccess = (data) => {
  return {
    type: ACTIONS.DIMENSIONS_LIST_SUCCESS,
    payload: data,
  };
};

export const dimensionsListFailure = (errors) => {
  return {
    type: ACTIONS.DIMENSIONS_LIST_FAILURE,
    payload: errors,
  };
};

export const dimensionsListFailureClear = () => {
  return {
    type: ACTIONS.DIMENSIONS_LIST_FAILURE_CLEAR,
  };
};

//METRICS LIST
export const metricsListRequest = (data) => {
  return {
    type: ACTIONS.METRICS_LIST_REQUEST,
    payload: data,
  };
};

export const metricsListSuccess = (data) => {
  return {
    type: ACTIONS.METRICS_LIST_SUCCESS,
    payload: data,
  };
};

export const metricsListFailure = (errors) => {
  return {
    type: ACTIONS.METRICS_LIST_FAILURE,
    payload: errors,
  };
};

export const metricsListFailureClear = () => {
  return {
    type: ACTIONS.METRICS_LIST_FAILURE_CLEAR,
  };
};

//RUN REPORT
export const runReportRequest = (data) => {
  return {
    type: ACTIONS.RUN_REPORT_REQUEST,
    payload: data,
  };
};

export const runReportSuccess = (data) => {
  return {
    type: ACTIONS.RUN_REPORT_SUCCESS,
    payload: data,
  };
};

export const runReportFailure = (errors) => {
  return {
    type: ACTIONS.RUN_REPORT_FAILURE,
    payload: errors,
  };
};

export const runReportFailureClear = () => {
  return {
    type: ACTIONS.RUN_REPORT_FAILURE_CLEAR,
  };
};

export const runReportClearAll = () => {
  return {
    type: ACTIONS.RUN_REPORT_CLEAR_ALL,
  };
};

//REPORT TEMPLATE LIST
export const getReportTemplates = (data) => {
  return {
    type: ACTIONS.GET_REPORT_TEMPLATES_REQUEST,
    payload: data,
  };
};

export const getReportTemplatesSuccess = (data) => {
  return {
    type: ACTIONS.GET_REPORT_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const getReportTemplatesFailure = (errors) => {
  return {
    type: ACTIONS.GET_REPORT_TEMPLATES_FAILURE,
    payload: errors,
  };
};

export const getReportTemplatesFailureClear = () => {
  return {
    type: ACTIONS.GET_REPORT_TEMPLATES_FAILURE_CLEAR,
  };
};

//SET/RESET
export const setReportTemplate = (data) => {
  return {
    type: ACTIONS.SET_REPORT_TEMPLATE,
    payload: data,
  };
};

export const resetReportTemplate = () => {
  return {
    type: ACTIONS.RESET_REPORT_TEMPLATE,
  };
};

//SAVE TEMPLATE
export const saveReportTemplateRequest = (data) => {
  return {
    type: ACTIONS.SAVE_REPORT_TEMPLATE_REQUEST,
    payload: data,
  };
};

export const saveReportTemplateSuccess = (data) => {
  return {
    type: ACTIONS.SAVE_REPORT_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const saveReportTemplateFailure = (errors) => {
  return {
    type: ACTIONS.SAVE_REPORT_TEMPLATE_FAILURE,
    payload: errors,
  };
};

export const saveReportTemplatesFailurClear = () => {
  return {
    type: ACTIONS.SAVE_REPORT_TEMPLATE_FAILURE_CLEAR,
  };
};

//UPDATE TEMPLATE
export const updateReportTemplateRequest = (data) => {
  return {
    type: ACTIONS.UPDATE_REPORT_TEMPLATE_REQUEST,
    payload: data,
  };
};

export const updateReportTemplateSuccess = (data) => {
  return {
    type: ACTIONS.UPDATE_REPORT_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const updateReportTemplateFailure = (errors) => {
  return {
    type: ACTIONS.UPDATE_REPORT_TEMPLATE_FAILURE,
    payload: errors,
  };
};

export const updateReportTemplatesFailurClear = () => {
  return {
    type: ACTIONS.UPDATE_REPORT_TEMPLATE_FAILURE_CLEAR,
  };
};

//DELETE TEMPLATE
export const deleteReportTemplateRequest = (data) => {
  return {
    type: ACTIONS.DELETE_REPORT_TEMPLATE_REQUEST,
    payload: data,
  };
};

export const deleteReportTemplateSuccess = (data) => {
  return {
    type: ACTIONS.DELETE_REPORT_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const deleteReportTemplateFailure = (errors) => {
  return {
    type: ACTIONS.DELETE_REPORT_TEMPLATE_FAILURE,
    payload: errors,
  };
};

export const deleteReportTemplatesFailurClear = () => {
  return {
    type: ACTIONS.DELETE_REPORT_TEMPLATE_FAILURE_CLEAR,
  };
};