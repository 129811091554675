import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import Loader from "../../Loader";
import { useLocation } from 'react-router-dom';
import {
  Card, Button, Form, Table
} from 'reactstrap';
import ADPagination from "../../ADPagination";
import NoRules from "./NoRules";
import { conversionActionCreator } from '../../../actions';
import CloneRule from "./CloneRule";
import CodeRule from "./CodeRule";
import { format } from "date-fns";
import "./index.scss";
import { cloneRtrAdRequest } from '../../../actions/realtimeResearchActions';
import RBAC from "../../../components/RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";

const ConversionRulesTable = ({ openRuleDetails, openPixelModal }) => {

  const actionIcons = {
    CODE_ICON: "/Icon_assets/svg/code-icon.svg",
    CLONE_ICON: "/Icon_assets/svg/clone-rule.svg",
  };

  const RULE_TYPE = {
    0: "Event",
    1: "URL"
  }

  const EVENTS = {
    'SUBSCB': 'Subscribe',
    'REGIST': 'Register',
    'PRCHS': 'Purchase',
    'ADCRT': 'Add To Cart',
    'ADPAY': 'Add Payment Info',
    'ADWHT': 'Add To Wishlist',
    'CHEKOT': 'Initiate Checkout',
    'LEAD': 'Lead',
    'SRCH': 'Search'
  }
  const dispatch = useDispatch();
  /*** Pagination setup start***/
  const [totalOffset, setTotalOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPagination, setIsPagination] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let PageSize = 10;
  /*** Pagination setup end***/

  const MAIN_TABLE_COL_MIN_WIDTH = 200;
  const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
  const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);
  const [lineNameWidth, setLineNameWidth] = useState(document.getElementsByClassName('primary-col')[0] ? document.getElementsByClassName('primary-col')[0].getBoundingClientRect().width : EXPANDED_TABLE_COL_MIN_WIDTH)
  const [rules, setRules] = useState([]);
  const [count, setCount] = useState(0);

  const rulesData = useSelector((state) => state.conversion.rulesList);
  const isLoading = useSelector((state) => state.conversion.isLoading);

  const TABLE_COLUMNS = [
    {
      dataField: "rule_name",
      text: "Name",
      className: ""
    },
    {
      dataField: "rule_id",
      text: "Rule ID",
      className: ""
    },
    {
      dataField: "event_type",
      text: "Event Type",
      className: ""
    },
    {
      dataField: "rule_type",
      text: "Rule Type",
      className: ""
    },
    {
      dataField: "created_at",
      text: "Created At",
      className: ""
    },
    {
      dataField: "actions",
      text: "Actions",
      className: "col-hd-actions"
    }];

  var thElm;
  var startOffset;

  useEffect(() => {
    var table = document.getElementById("table-main");
    // For resizing column, add dummy div to header, add event when clicked
    if (table) {
      var tableHeight = table.offsetHeight;
      Array.prototype.forEach.call(
        table.querySelectorAll(".resized-column"),
        function (th) {
          //th.style.position = 'relative';

          var grip = document.createElement('div');
          grip.className = 'column-resizer';
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = '5px';
          grip.style.height = tableHeight + 'px';
          grip.style.position = 'absolute';
          grip.style.zIndex = "1";
          grip.style.cursor = 'col-resize';
          grip.addEventListener('mousedown', function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });

          th.appendChild(grip);
        });

      // listener for dragging
      table.addEventListener('mousemove', function (e) {
        if (thElm) {
          if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
            thElm.style.width = startOffset + e.pageX + 'px';
            thElm.style.minWidth = startOffset + e.pageX + 'px';
          }

          let expandedColumnWidth = (startOffset + e.pageX) + 20;
          if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
            setExpandedTableColWidth(expandedColumnWidth);
          }
        }
      });

      // listner for releasing click
      table.addEventListener('mouseup', function () {
        thElm = undefined;
      });
    }
  });
  /* Resize column setup for table-main end*/

  useEffect(() => {
    if (rulesData && rulesData.results && rulesData.results.length) {
      setRules(rulesData.results);
      setCount(rulesData.count);
      setIsPagination(true);
      if (rulesData.next === null) {
        //if tableData have only 1 page set current page to 1
        const checkPageCount = Math.ceil(rulesData.count / PageSize);
        setCurrentPage(checkPageCount);
      }
    } else {
      setIsPagination(false);
    }
  }, [rulesData]);

  useEffect(() => {
    // call api for rules list
    var param = {};
    param.pageIndex = 0;
    param.limit = PageSize;
    param.offset = totalOffset;
    // param.offset = 
    param.brandId = localStorage.getItem('selectedConversionAd') ?
      JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
    dispatch(conversionActionCreator.rulesListRequest(param));

    return function cleanUp() {
      setCurrentPage(1);
      dispatch(conversionActionCreator.clearRulesListRequest())
    }
  }, []);


  const handlePaginationClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    const params = {};
    params.pageIndex = pageIndex;
    params.limit = PageSize;
    params.offset = (pageIndex - 1) * 10;
    setTotalOffset(totalOffset + 10);
    params.brandId = localStorage.getItem('selectedConversionAd') ?
      JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
    dispatch(conversionActionCreator.rulesListRequest(params));
  }

  const goToCreateNewRule = (e, data) => {
    e.preventDefault();
    localStorage.setItem('clonedRule', JSON.stringify(data));
    history.push({
      pathname:
        ROUTES.CREATE_CONVERSION_RULE,
      state: data
    });
  }

  const openPixelCode = (e, data) => {
    openPixelModal(data);
  }
  return (<>
    <div className="rules-table-container" style={{ overflowX: "scroll", overflowY: "hidden" }}>
      {
        isLoading ?
          <Loader />
          :
          rules.length > 0 ?

            <>
              <Table className="dashboard-table" borderless={true} id="table-main">
                <thead className="dashboard-table-header">
                  <tr>

                    {TABLE_COLUMNS.map((d, i) => {
                      if (d.text === "Name") {
                        return <th key={i} className={"sticky-col resized-column primary-col sticky-header"}

                          style={{ width: `${lineNameWidth}px`, minWidth: `${lineNameWidth}px` }}>
                          <div className="resizer-icon">
                            <span className="first"></span>
                            <span className="second"></span>
                          </div>
                          {d.text}</th>;
                      }

                      if (d.text === 'Actions') {
                        return (<RBAC requiredPermissions={PERMISSIONS.BUILD_CONVERSION_WRITE}>
                          <th key={i} className={d.className}>
                            <div>
                              <span>{d.text}</span>
                            </div></th> </RBAC>);
                      }
                      return (<th key={i} className={d.className}>
                        <div>
                          <span>{d.text}</span>
                        </div></th>);
                    })}
                  </tr>
                </thead>
                <tbody className="table-body-content">
                  {!isLoading ?
                    (rules && rules.length > 0 ?
                      (rules.map((d, i) => {
                        return (
                          <React.Fragment key={'main-table-' + i}>
                            <tr className="td-line" key={'td-line-' + i}>
                              <td className={"rule-name primary-col-first sticky-header"} onClick={() => openRuleDetails(d)}>{d.rule_name}</td>
                              <td className={""}>{d.id}</td>
                              <td className={""}>{EVENTS[d.event_type]}</td>
                              <td className={""}>{RULE_TYPE[d.rule_type]}</td>
                              <td className={""}>{format(new Date(d.created_at), 'MM/dd/yyyy')}</td>
                              <RBAC requiredPermissions={PERMISSIONS.BUILD_CONVERSION_WRITE}>
                                <td className="action-column">
                                  <section className="d-flex">
                                    <CodeRule actionIcons={actionIcons}
                                      data={d}
                                      openPixelCode={openPixelCode} />
                                    <span className="divider"></span>
                                    <CloneRule actionIcons={actionIcons}
                                      data={d}
                                      goToCreateNewRule={goToCreateNewRule} />
                                  </section>
                                </td>
                              </RBAC>
                            </tr>
                          </React.Fragment>
                        );
                      }))
                      :
                      (
                        <tr className="td-line">
                          <td className="align-data" colSpan={TABLE_COLUMNS.length + 1}>
                            <p className="no-wallet-data-text text-center">No table data available</p>
                          </td>
                        </tr>
                      ))
                    :
                    (
                      <tr className="td-line">
                        <td className="align-data" colSpan={TABLE_COLUMNS.length}>
                          <Loader />
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>

            </>
            :
            <NoRules message={"There are no conversion rules for this account yet."} />
      }

    </div>
    {isPagination ? rulesData?.results?.length > 0 && (<>
      <ADPagination
        className="rtr-pagination-bar"
        currentPage={currentPage}
        totalCount={count}
        pageSize={PageSize}
        handlePaginationClick={handlePaginationClick}
        showNoOfRecords={true}
      />
    </>) : null}
  </>)
}

export default ConversionRulesTable;