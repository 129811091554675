import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const DeleteOffer = ({ setOpenDeleteModal, seletedItemId, deleteRequest }) => {
    return (
        <section className="delete-offer-content">
            <nav className="delete-offer-section">
                <p>Are you sure you want to delete this offer?</p>
            </nav>
            <nav className="btn-wrapper">
                <Button className="in-btn-sm in-btn-primary" onClick={() => deleteRequest(seletedItemId)}>
                    Continue
                </Button>
                <Button color="link" className="in-btn-link" onClick={() => setOpenDeleteModal(false)}>
                    Cancel
                </Button>
            </nav>
        </section>
    )
}

export default DeleteOffer;