import ACTIONS from "../constants/actionTypes";


export const rulesListRequest = (data) => {
  return {
    type: ACTIONS.RULES_LIST_REQUEST,
    payload: data,
  };
};

export const rulesListSuccess = (data) => {
  return {
    type: ACTIONS.RULES_LIST_SUCCESS,
    payload: data,
  };
};

export const rulesListFailure = (errors) => {
  return {
    type: ACTIONS.RULES_LIST_FAILURE,
    payload: errors,
  };
};


export const createRuleRequest = (data) => {
  return {
    type: ACTIONS.CREATE_RULE_REQUEST,
    payload: data,
  };
};

export const createRuleSuccess = (data) => {
  return {
    type: ACTIONS.CREATE_RULE_SUCCESS,
    payload: data,
  };
};

export const createRuleFailure = (errors) => {
  return {
    type: ACTIONS.CREATE_RULE_FAILURE,
    payload: errors,
  };
};


export const clearCreateRuleResponse = () => {
  return {
    type: ACTIONS.CLEAR_CREATE_RULE_RESPONSE,
    payload: "",

  };
};

export const clearRulesListRequest = () => {
  return {
    type: ACTIONS.CLEAR_RULE_LIST,
    payload: []
  };
};



export const getPixelCodeRequest = (data) => {
  return {
    type: ACTIONS.GET_PIXEL_CODE_REQUEST,
    payload: data,
  };
};

export const getPixelCodeSuccess = (data) => {
  return {
    type: ACTIONS.GET_PIXEL_CODE_RESPONSE,
    payload: data,
  };
};

export const getPixelCodeFailure = (errors) => {
  return {
    type: ACTIONS.GET_PIXEL_CODE_FAILURE,
    payload: errors,
  };
};


export const getPixelCodeRequestClear = () => {
  return {
    type: ACTIONS.GET_PIXEL_CODE_REQUEST_CLEAR,
    payload: "",

  };
};