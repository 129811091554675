import React, { useState, useEffect } from "react";
import { organizationActionCreator } from "../../actions";
import Loader from '../Loader';
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import {
  Button,
  FormGroup, Input,
  Badge, CustomInput, Popover
} from "reactstrap";

const OrganizationDropDown = ({ organizationList, callParentRole, popoverOpen, handleSearchValue, isActive, selectedRole }) => {

  const LABEL = {
    ADMIN: 'Admin',
    ORGANIZATION: 'Organization'
  };

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.organization.isLoading);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // set search value to blank on unmount.
    return () => {
      if (!isActive) {
        setSearchValue('');
      }
    };
  }, [isActive]);

  const selectOrganization = (e, organization) => {
    dispatch(organizationActionCreator.setdOrganization(organization));
    localStorage.setItem('selectedOrganization', JSON.stringify(organization));
    localStorage.setItem('selectedRole', LABEL.ORGANIZATION);
    dispatch(organizationActionCreator.setUserRole(LABEL.ORGANIZATION));
    e.stopPropagation();
  }

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    handleSearchValue(event);
  }

  const onClickAdmin = (event) => {
    callParentRole(LABEL.ADMIN);
    dispatch(organizationActionCreator.removedOrganization());
    dispatch(organizationActionCreator.setUserRole(LABEL.ADMIN));
    event.stopPropagation();
  }

  const onClickOrganization = (event) => {
    callParentRole(LABEL.ORGANIZATION);
    dispatch(organizationActionCreator.getOrganizationListRequest());
    event.stopPropagation();
  }

  return (
    <Popover placement="bottom" isOpen={popoverOpen} id="org_div" hideArrow={true}
      target="dropdown" className={popoverOpen ? "fade show pop-drop" : 'fade'} >
      <div className={isActive ? 'show' : 'd-none'}>
        <div
          className={(selectedRole === LABEL.ORGANIZATION) ? 'popover popover-height'
            : 'popover'}>
          {/* //  + (selectedRole === LABEL.ORGANIZATION) ? 'popover-height':''} > */}
          <div className="popover-inner">
            <Button type="button" tabIndex="0" role="menuitem"
              className={"dropdown-item " + (selectedRole === LABEL.ADMIN ? 'highlight-selection' : '')} id='admin'
              onClick={(event) => onClickAdmin(event)}>
              {LABEL.ADMIN}
              <Badge color={selectedRole === LABEL.ADMIN ? "primary" : "secondary"}
                style={{ margin: "0px 0px 0 66px" }}>Default</Badge>
            </Button>
            <Button type="button" tabIndex="1" role="menuitem"
              className={"dropdown-item " + (selectedRole === LABEL.ORGANIZATION ? 'highlight-selection' : '')} id='organization'
              onClick={(event) => onClickOrganization(event)}>
              {LABEL.ORGANIZATION}
              {selectedRole === LABEL.ORGANIZATION ?
                <Badge color="primary" style={{ marginLeft: "19px" }}>Active</Badge> : ''}
            </Button>

            {selectedRole === LABEL.ORGANIZATION &&
              <FormGroup>
                <Input
                  value={searchValue}
                  type="text"
                  name="search"
                  id="searchUser"
                  placeholder="Search..."
                  className="search-input"
                  autocomplete="off"
                  onChange={(event) => handleSearch(event)}
                  disabled={isLoading}
                />
                <img
                  src="/Icon_assets/svg/search-organization-icon.svg"
                  alt="Search"
                  className="search-image"
                />
              </FormGroup>
            }
            {selectedRole === LABEL.ORGANIZATION && <div className="org_list">
              {isLoading ?
                (
                  <Loader />
                )
                :
                (
                  organizationList && organizationList.length ?
                    (organizationList.map((data, index) => {
                      return <div key={index} className={"radio-list"} style={{ paddingBottom: '8px' }}>
                        <CustomInput type="radio" name="radio1"
                          className={"custom-radio-input"}
                          id={`radio-${index}`}
                          label={data.name}
                          value={data.name}
                          checked={data.id === selectedOrganization.id}
                          onChange={(event) => selectOrganization(event, data)} />
                      </div>
                    }))
                    :
                    <p className="no-wallet-data-text">No Organization data available</p>)}
            </div>}
          </div>
        </div>
      </div>
    </Popover>
  )
}



export default OrganizationDropDown;