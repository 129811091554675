import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router';
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import {
  Button, Card, Row, Col, CustomInput,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import {
  addDays,
  subDays,
  startOfDay,
  startOfMonth,
  format,
} from "date-fns";
import Validator from "../../../utils/validators";
import ADCalendar from "../../../components/ADCalendar";
import CLIENT_SIDE_MESSAGES from "../../../utils/clientSideMessages";
import { reportBuilderActionCreator, brandsActionCreator,
   conversionActionCreator, notificationActionCreator, offerActionCreator } from "../../../actions";
import DimensionFilter from "./DimensionFilter";
import MetricFilter from "./MetricsFilter";
import ReportTable from "./ReportTable";
import ADspinner from "../../../components/Loader/ADspinner";
import "./index.scss";

const ConversionReportBuilder = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const offerDataList = useSelector((state) => state.offer.offerData?.results);
  const offerDataCount = useSelector((state) => state.offer.offerData?.count);
  const isRunning = useSelector((state) => state.reportBuilder.isLoading);
  const isFetched = useSelector((state) => state.reportBuilder.isFetched);
  const rulesList = useSelector((state) => state.conversion.rulesList?.results);
  const rulesDataCount = useSelector((state) => state.conversion.rulesList?.count);
  const advertiserList = useSelector((state) => state.brands.data?.results);
  const advertiserCount = useSelector((state) => state.brands.data?.count);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const reportData = useSelector((state) => state.reportBuilder.reportData);

  const validatorsObj = new Validator();
  const [showReportTable, setShowReportTable] = useState(false);
  const [reportBuilderData, setReportBuilderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  const dateFormat = "yyyy-MM-dd";

  const dateToYMD = (date, dateRange) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    if (dateRange) {
      return "" + (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
    } else {
      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "-" +
        (m <= 9 ? "0" + m : m) +
        "-" +
        y +
        "+" +
        hh +
        ":" +
        mm +
        ":" +
        ss
      );
    }
  };

  const dateIntervalArr = [
    { id: 8, label: "Cumulative", value: 8 },
    { id: 3, label: "Day", value: 3 },
    { id: 4, label: "Week", value: 4 },
    { id: 5, label: "Month", value: 5 }
  ];

  const DimensionDrop = [
    { id: 15, label: "Advertiser", value: "Advertiser" },
    { id: 12, label: "Event Type", value: "event_type" },
    { id: 16, label: "Offer", value: "Offer" },
    { id: 11, label: "Rule", value: "rule" },
    { id: 14, label: "Rule ID", value: "rule_id" },
    { id: 13, label: "Rule Type", value: "rule_type" }
  ];

  const DimensionType = [
    { id: 1, label: "Event Type", value: 0 },
    { id: 2, label: "Rule Type", value: 1 },
  ];

  const EventType = [
    { id: 0, name: "Subscribe", value: "SUBS" },
    { id: 1, name: "Register", value: "REGIST" },
    { id: 2, name: "Purchase", value: "PRCHS" },
    { id: 3, name: "Add To Cart", value: "ADCRT" },
    { id: 4, name: "Add Payment Info", value: "ADPAY" },
    { id: 5, name: "Add To Wishlist", value: "ADWHT" },
    { id: 6, name: "Initiate Checkout", value: "CHEKOT" },
    { id: 7, name: "Lead", value: "LEAD" },
    { id: 8, name: "Search", value: "SRCH" }
  ];

  const MetricsDrop = [
    { id: 105, label: "Clicks", value: "clicks" },
    { id: 102, label: "Conversions", value: "conversions" },
    { id: 101, label: "Detail View", value: "detailsview" },
    { id: 104, label: "Impressions", value: "impressions" },
    { id: 103, label: "Payments", value: "payments" },
  ]

  const MetricsDrop2 = [
    { id: 102, label: "Conversions", value: "conversions" },
    { id: 103, label: "Payments", value: "payments" },
  ]

  const RuleType = [
    { id: 0, name: "Event", value: "Event" },
    { id: 1, name: "URL", value: "URL" },
  ];

  const [adList, setAdList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [offerCount, setOfferCount] = useState(0);
  const [totalOffset, setTotalOffset] = useState(0);
  const [adCount, setAdCount] = useState(0);
  const [rules, setRules] = useState([]);
  const [rulesDataList, setRulesDataList] = useState([]);
  const [rulesCount, setRulesCount] = useState(0);
  const [currentDimensionIndex, setCurrentDimensionIndex] = useState({ index: null, value: null });
  const [metrics, setMetrics] = useState([]);
  const [conversion, setConversion] = useState({ name: '', option: 1, value: 1, rule: 2 });
  const [searchValue, setSearchValue] = useState('');
  const [noOfMetrics, setNoOfMetrics] = useState(0);
  const [metric_filters, setMetric_filters] = useState([]);
  const [conversion_filter, setConversion_filter] = useState([]);
  const [metricsOptions, setMetricOptions] = useState(MetricsDrop);
  const [filteredKey, setFilteredKey] = useState("");
  const [searchedDimension, setSearchedDimension] = useState('');
  const [dimension_filters, setDimension_filters] = useState([]);
  const [dimensionOptions, setDimensionOptions] = useState(DimensionDrop);
  const [noOfDimensions, setNoOfDimensions] = useState(0);
  const [source, setSource] = useState("");
  const [interval, setInterval] = useState();
  const [dimensions, setDimensions] = useState([]);
  const startTime = (startOfDay(subDays(new Date(), 7)));
  const endTime = (startOfDay(addDays(new Date(), 0)));
  const dateValue =
    dateToYMD(startTime, "date-range") +
    " - " +
    dateToYMD(startOfDay(addDays(new Date(), -1)), "date-range");
  const [selectedStartTime, updateSelectedStartTime] = useState(startTime);
  const [selectedEndTime, updateSelectedEndTime] = useState(endTime);
  const [selectedDateRange, setSelectedDateRange] = useState("Last 7 Days");
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState();
  const [displayDateRange, setdisplayDateRange] = useState(dateValue);
  const [dateIntervalDropdownOpen, setDateIntervalDropdownOpen] = useState(false);
  const toggleDateInterval = (e) => {
    e.preventDefault();
    setDateIntervalDropdownOpen(prevState => !prevState);
  }
  const formInitialState = {
    start_date_report: startTime,
    interval: '',
    dimensions: [],
    dimension_filters: [],
    metrics: [],
    metric_filters: []
  };
  const [formState, setFormState] = useState(formInitialState);
  const [dimensionIntervalDropdownOpen, setDimensionIntervalDropdownOpen] = useState(false);
  const toggleDimensionInterval = (e) => {
    e.preventDefault();
    setDimensionIntervalDropdownOpen(prevState => !prevState);
  }

  const [metricsDropdownOpen, setMetricsDropdownOpen] = useState(false);
  const toggleMetrics = () => setMetricsDropdownOpen(prevState => !prevState);

  const [selectedDatedownOpen, setSelectedDateDropdownOpen] = useState(false);
  const toggleSelectedDate = (e) => {
    e.preventDefault();
    setSelectedDateDropdownOpen(prevState => !prevState);
  }

  //Remove all dimensions
  const removeAllDimensions = (e) => {
    e.preventDefault();
    setDimensions([]);
    setNoOfDimensions(0);
    checkFormValidation('dimensions', []);
  }

  //Remove selected dimensions
  const removeSelectedDimensions = (e, removeItem) => {
    e.preventDefault();
    dimensions.splice(dimensions.indexOf(removeItem), 1);
    //setDimensions((dimensions) => [...dimensions]);
    setDimensions((dimensions) => {
      checkFormValidation('dimensions', [...dimensions]);
      return [...dimensions];
    });
    setNoOfDimensions((prevCount) => prevCount - 1);
  }

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleReportChange = (inputName, inputValue, inputIndex = null, filterChangeType = null, e) => {
    if (filterChangeType === "metricFilter") {
      const filterObj = [...metric_filters];
      if (inputName === 'metric') {
        filterObj[inputIndex]['errorMsgMetric'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].metric = inputValue;
      } else if (inputName === 'metricFilterValue') {
        filterObj[inputIndex]['errorMsgValue'] = !updateValidations(inputName, inputValue) ? validators.metricFilterValue.errorMsg : '';
        filterObj[inputIndex].value = inputValue;
      } else if (inputName === "metricFilterRule") {
        filterObj[inputIndex]['errorMsgRule'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].rule = inputValue;
      }
      setMetric_filters(filterObj);
    } else if (filterChangeType === "dimensionFilter") {
      const filterObj = [...dimension_filters];
      if (inputName === 'dimension') {
        const checkIsExist = filterObj.some(function (el) {
          return el.dimension.value === inputValue.value;
        });
        if (checkIsExist) {
          dispatch(notificationActionCreator.setNotification({
            message: CLIENT_SIDE_MESSAGES.DIMENTION_EXIST
          }));
        } else {
          filterObj[inputIndex]['errorMsgDimension'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
          filterObj[inputIndex].dimension = inputValue;
          filterObj[inputIndex].option = 0;
          filterObj[inputIndex].dropdownOptions = null;
          filterObj[inputIndex].value = [];
        }
      } else if (inputName === 'dimensionFilterOption') {
        filterObj[inputIndex]['errorMsgRule'] = !updateValidations("requiredRule", inputValue) ? validators.requiredRule.errorMsg : '';
        filterObj[inputIndex].option = inputValue
      } else if (inputName === 'dimensionFilterValues') {
        if (e) {
          if (e.target.checked) {
            let valueArr = filterObj[inputIndex].value;
            valueArr.push(inputValue);
            filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", valueArr) ? validators.dimensionFilterValues.errorMsg : '';
          } else {
            let valueArr = filterObj[inputIndex].value;
            filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", valueArr) ? validators.dimensionFilterValues.errorMsg : '';
            const removeId = valueArr.findIndex((element) => element.id === inputValue.id);
            valueArr.splice(removeId, 1);
          }
        } else {
          filterObj[inputIndex]['errorMsgValue'] = !updateValidations("dimensionFilterValues", inputValue) ? validators.dimensionFilterValues.errorMsg : '';
        }
      }
      setDimension_filters(filterObj);
    } else if (filterChangeType === "ConversionWindow") {
      const filterObj = [...conversion_filter].length > 0 ? [...conversion_filter] : [{ metric: '', option: '', value: '', rule: 2 }];
      // to decide format
      if (inputName === "metric") {
        filterObj[0].metric = inputValue;
      } else if (inputName === "metricFilterRule") {
        filterObj[0].rule = 2;
      } else if (inputName === "metricFilterValue") {
        filterObj[0].value = inputValue;
      }
      setConversion_filter(filterObj);
    }
  }

  const removeSelectedFilterDimensions = (e, removeItem, curentIndex) => {
    e.preventDefault();
    dimension_filters[curentIndex].value.splice(removeItem, 1);
    setDimension_filters((dimension_filters) => [...dimension_filters]);
    // setCurrentDimensionIndex((dim)=> [...dim]);
    handleReportChange("dimensionFilterValues", dimension_filters[curentIndex].value, curentIndex, "dimensionFilter")
  }

  //Fiter Dimention remove all value
  const removeAllFilterDimensions = (e, curentIndex) => {
    e.preventDefault();
    dimension_filters[curentIndex].value = [];
    setDimension_filters((dimension_filters) => [...dimension_filters]);
    handleReportChange("dimensionFilterValues", dimension_filters[curentIndex].value, curentIndex, "dimensionFilter")
  }

  const clearAdData = () => {
    setAdList([]);
    setTotalOffset(0);
    setAdCount(0);
    setRulesCount(0);
    setRulesDataList([]);
    setOfferList([]);
    setOfferCount(0);
  }

  const dimensionsFilterSearch = (e, dimensionId, currentIndex) => {
    const selectedDimentionName = dimensionId.value;
    const dimentionName = selectedDimentionName.toLowerCase();
    setFilteredKey(dimentionName);
    setSearchedDimension(e.target.value);
    const filterObj = [...dimension_filters];
    let params = {};
    setCurrentDimensionIndex({ index: currentIndex, value: dimentionName });
    switch (dimentionName) {
      case "advertiser":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.searchKey = e.target.value.toLowerCase();
        params.limit = 10;
        dispatch(brandsActionCreator.brandsListRequest(params));
        setAdList([]);
        setAdCount(0);
        setTotalOffset(0);
        break;
      case "offer":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        params.limit = 10;
        params.searchKey = e.target.value.toLowerCase();
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        // replace with offers API
        dispatch(offerActionCreator.getOfferListRequest(params));
        setOfferList([]);
        setOfferCount(0);
        setTotalOffset(0);
        break;
      case "rule_id":
      case "rule":
        filterObj[currentIndex].isLoading = true;
        setDimension_filters(filterObj);
        params.searchKey = e.target.value.toLowerCase();
        params.limit = 10;
        dispatch(conversionActionCreator.rulesListRequest(params));
        setRulesDataList([]);
        setRulesCount(0);
        setTotalOffset(0);
        break;
      case "event_type":
        const eventList = [...EventType];
        const filteredList = eventList.filter(function (item) { return (item.name.toLowerCase().indexOf(e.target.value) > -1) });
        filterObj[currentIndex].dropdownOptions = filteredList;
        filterObj[currentIndex].isLoading = false;
        setDimension_filters(filterObj);
        break;

      case "rule_type":
        const ruleList = [...RuleType];
        const filteredRuleList = ruleList.filter(function (item) { return (item.name.toLowerCase().indexOf(e.target.value) > -1) });
        filterObj[currentIndex].dropdownOptions = filteredRuleList;
        filterObj[currentIndex].isLoading = false;
        setDimension_filters(filterObj);
      default:
        break;
    }
  }

  //Add new metrics
  const handleMetricsCheckboxChange = (e, checkboxElement) => {
    if (e.target.checked) {
      setMetrics((metrics) => {
        checkFormValidation('metrics', [...metrics, checkboxElement]);
        return [...metrics, checkboxElement];
      });
      setNoOfMetrics((prevCount) => prevCount + 1);

    } else {
      const removeId = metrics.findIndex((element) => element.id === checkboxElement.id);
      metrics.splice(removeId, 1);
      setMetrics((metrics) => {
        checkFormValidation('metrics', [...metrics]);
        return [...metrics];
      });
      setNoOfMetrics((prevCount) => prevCount - 1);
    }
  }

  // to show data on UI
  const handleConversionData = (inputName, inputValue, inputIndex = null, filterChangeType = null, conversionData) => {
    const data = { ...conversion };
    if (inputName === "metricFilterRule") {
      //Duration - days/hours
      data.option = conversionData.value
    } else if (inputName === "metricFilterValue") {
      // value of days/hour
      data.value = conversionData.value
    } else {
      // conversion rule id
      data.name = conversionData.value;
    }
    setConversion(data);
    handleReportChange(inputName, inputValue, inputIndex, filterChangeType);
  }
  //Remove selected metrics
  const removeSelectedMetrics = (e, removeItem) => {
    e.preventDefault();
    metrics.splice(metrics.indexOf(removeItem), 1);
    setMetrics((metrics) => {
      checkFormValidation('metrics', [...metrics]);
      return [...metrics];
    });
    setNoOfMetrics((prevCount) => prevCount - 1);
    if (removeItem && removeItem.label === "Conversions") {
      setConversion({ name: '', option: 1, value: 1 });
      setConversion_filter([]);
    }
  }
  //Remove all metrics
  const removeAllMetrics = (e) => {
    e.preventDefault();
    setMetrics([]);
    setConversion_filter([]);
    setNoOfMetrics(0);
    checkFormValidation('metrics', []);
    setConversion({ name: '', option: 1, value: 1 });
  }

  const statusIcons = {
    BACK: "/Icon_assets/svg/conversion-back.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CALENDER_GRAY_ICON: "/Icon_assets/svg/conversion-calendar.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
    CLOSE_ICON: "/Icon_assets/svg/blue_close_icon.svg",
    CALENDER_BLUE_ICON: "/Icon_assets/svg/conversion-calendar-selected.svg",
    PLUS_ICON: "/Icon_assets/svg/plus_icon_blue.svg",
    PLUS_ICON_GRAY_TRAN: "/Icon_assets/svg/plus_icon_gray_transparent.svg",
    PLUS_ICON_BLUE_TRAN: "/Icon_assets/svg/plus_icon_blue_transparent.svg",
  };

  const backToConversionList = (e) => {
    e.preventDefault();
    if (showReportTable) {
      setShowReportTable(false);
      setReportBuilderData([]);
      dispatch(reportBuilderActionCreator.runReportClearAll());
    } else if (source === "conversion-page")
      history.push(ROUTES.CONVERSION);
    else {
      var brandId = localStorage.getItem('selectedConversionAd') ?
        JSON.parse(localStorage.getItem('selectedConversionAd')).id : '';
      history.push(`${ROUTES.CONVERSION}/conversion-rule-list/${brandId}`);
    }
  }

  const getDateFromCalender = (e, dateRange) => {
    e.preventDefault();
    setdisplayDateRange(dateRange.displayDate);
    updateSelectedStartTime(dateRange.startDate);
    updateSelectedEndTime(dateRange.endDate);
    setSelectedDateRange(dateRange.selectedRange);
    setSelectedDateRangeOption(dateRange.selectedRange);
    setSelectedDateDropdownOpen(false);
    handleReportChange('start_date_report', dateRange.startDate);
    checkFormValidation('start_date_report', dateRange.startDate);
  }


  const addFilter = (type) => {
    if (type === 'metrics') {
      let obj = [...metric_filters];
      obj.push({
        metric: '',
        rule: '',
        value: '',
        errorMsgValue: '',
        errorMsgRule: '',
        errorMsgMetric: '',
        id: `metricID${new Date().getTime()}`
      })

      setMetric_filters(obj);
    } else {
      let obj = [...dimension_filters];
      obj.push({
        errorMsgDimension: '',
        errorMsgValue: '',
        dimension: '',
        value: [],
        option: 0,
        dropdownOptions: null,
        pageNumber: '',
        isLoading: false,
        searchParam: {},
        id: `dimensionID${new Date().getTime()}`
      })

      setDimension_filters(obj);
    }
  }

  const removeFilter = (filterIndex, type) => {
    if (type === 'metrics') {
      let obj = [...metric_filters];
      obj.splice(filterIndex, 1);
      setMetric_filters(obj);
    } else {
      let obj = [...dimension_filters];
      obj.splice(filterIndex, 1);
      setDimension_filters(obj);
      let currDimension = {currentDimensionIndex};

      var key = Object.keys(currentDimensionIndex)[filterIndex];
      delete currDimension[key];
      setCurrentDimensionIndex(currDimension);
    }
  }

  const closeCalender = (e) => {
    e.preventDefault();
    setSelectedDateDropdownOpen(false);
  }

  const handleDimensionsChange = (e, checkboxState) => {
    e.preventDefault();
    if (e.target.checked) {
      setDimensions((dimensions) => {
        checkFormValidation('dimensions', [...dimensions, checkboxState]);
        return [...dimensions, checkboxState];
      });

      setNoOfDimensions((prevCount) => prevCount + 1);
    } else {
      dimensions.splice(dimensions.indexOf(checkboxState), 1);
      setDimensions((dimensions) => {
        checkFormValidation('dimensions', [...dimensions]);
        return [...dimensions];
      });
      setNoOfDimensions((prevCount) => prevCount - 1);
    }
  }

  const checkFormValidation = (field, value, isFilter = null, index = null, currentReportDetails) => {
    if (isFilter) {
    } else {
      const formStateObj = { ...formState };
      formStateObj[field] = value;
      setFormState(formStateObj);
    }
  }

  const handleRunRequest = (e) => {
    e.preventDefault();
    //check dimention validation
    let isDimentionFiterValid = true;
    if (dimension_filters.length > 0) {
      const dimension_filtersObj = [...dimension_filters];
      for (let index = 0; index < dimension_filters.length; index++) {
        if (dimension_filters[index].dimension === "") {
          dimension_filtersObj[index]['errorMsgDimension'] = !updateValidations("requiredRule", dimension_filtersObj[index].dimension) ? validators.requiredRule.errorMsg : '';
        }

        if (dimension_filters[index].value.length === 0) {
          dimension_filtersObj[index]['errorMsgValue'] = !updateValidations("dimensionFilterValues", dimension_filtersObj[index].value) ? validators.dimensionFilterValues.errorMsg : '';
        }
      }
      setDimension_filters(dimension_filtersObj);
      isDimentionFiterValid = dimension_filtersObj.every((item) => item.errorMsgDimension === "" && item.errorMsgValue === "")
    }
    //check metric validation
    let isMetricFiterValid = true;
    if (metric_filters.length > 0) {
      const metric_filtersObj = [...metric_filters];
      for (let index = 0; index < metric_filters.length; index++) {
        if (metric_filters[index].metric === "") {
          metric_filtersObj[index]['errorMsgMetric'] = !updateValidations("requiredRule", metric_filtersObj[index].metric) ? validators.requiredRule.errorMsg : '';
        }

        if (metric_filters[index].value === "") {
          metric_filtersObj[index]['errorMsgValue'] = !updateValidations("metricFilterValue", metric_filtersObj[index].value) ? validators.metricFilterValue.errorMsg : '';
        }

        if (metric_filters[index].rule === "") {
          metric_filtersObj[index]['errorMsgRule'] = !updateValidations("requiredRule", metric_filtersObj[index].rule) ? validators.requiredRule.errorMsg : '';
        }

      }
      setMetric_filters(metric_filtersObj);
      isMetricFiterValid = metric_filtersObj.every((item) => item.errorMsgMetric === "" && item.errorMsgValue === "" && item.errorMsgRule === "")
    }

    if (isValid && isDimentionFiterValid && isMetricFiterValid) {
      let fDimensions = dimension_filters.map(filter => {
        let val = filter.value.map(val => val.dsp_id || val.id)
        return {
          dimension: filter.dimension.id,
          value: val,
          option: filter.option
        }
      })
      const metricsdata = metrics.map(({ id }) => id);
      const fMetrics = metric_filters.map(({ metric, rule, value }) => ({ metric, rule, value }));

      const cMetrics = conversion_filter.map(({ metric, rule, value }) => ({ metric, rule, value }));
      const report = {
        "report": "",
        custom_params: {
          "metric_set": 20,
          "name": "***",
          "start_date": format(selectedStartTime, dateFormat) + " 00:00:00",
          "end_date": format(addDays(selectedEndTime, 1), dateFormat) + " 00:00:00",
          "interval": interval,
          "dimensions": dimensions,
          "dimension_filters": fDimensions,
          "metric_filters": fMetrics.concat(cMetrics),
          "metrics": metricsdata,
        }
      }
      dispatch(reportBuilderActionCreator.runReportRequest(report));
    }
  }
  const getDateFormat = (date) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = (date.getHours() < 10 ? '0' : '') + date.getHours();
    let mm = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    let ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

    return (
      "" +
      (m <= 9 ? "0" + m : m) +
      "-" +
      (d <= 9 ? "0" + d : d) +
      "-" +
      y +
      "-" +
      hh +
      ":" +
      mm +
      ":" +
      ss
    );
  };


  const generateCSV = () => {
    // to be updated with api selector
    // var jsonData = [];
    setLoading(true);
    setTimeout(() => {
      var fileName = "Inv_Report";
      var date = getDateFormat((new Date()));
      fileName += "_" + date;

      var exportCSV = '';
      var header = "";
      for (var idx in reportData[0]) {
        header += idx.replace(" Inv", "") + ',';
      }
      header = header.slice(0, -1);
      exportCSV += header + '\r\n';
      var row = reportData;

      for (var i = 0; i < row.length; i++) {
        var r = "";
        for (var index in row[i]) {
          if (index.toLowerCase() === "Payments".toLowerCase()) {
            r += '"' + '$' + row[i][index] + '",';
          } else
            r += '"' + row[i][index] + '",';
        }
        r.slice(0, r.length - 1);
        exportCSV += r + '\r\n';
      }
      if (exportCSV === '') {
        console.error("Invalid data");
        return;
      }

      var ua = window.navigator.userAgent;

      if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0) {
        var blob = new Blob([exportCSV], { type: "text/csv;charset=utf-8" });
        navigator.msSaveBlob(blob, fileName + ".csv")
      } else {
        var uri = 'data:text/csv;charset=utf-8,' + encodeURI(exportCSV);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        setLoading(false);
      }
    }, 8000);

  }

  const handleAdvertiserScroll = () => {
    var count = adCount;
    var adListCount = adList.length;
    var advertiserDrop = document.getElementById("advertiserDropDown");
    if ((advertiserDrop.offsetHeight + advertiserDrop.scrollTop + 10) >= advertiserDrop.scrollHeight) {
      if (count > adListCount && adListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        if (searchedDimension !== "") {
          params.searchKey = searchedDimension;
        }
        dispatch(brandsActionCreator.brandsListRequest(params));
      }
    }
  }

  const handleRuleScroll = () => {
    var count = rulesDataCount;
    var ruleListCount = rulesDataList.length;
    var advertiserDrop = document.getElementById("ruleDropDown");
    if ((advertiserDrop.offsetHeight + advertiserDrop.scrollTop + 10) >= advertiserDrop.scrollHeight) {
      if (count > ruleListCount && ruleListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        if (searchedDimension !== "") {
          params.searchKey = searchedDimension;
        }
        dispatch(conversionActionCreator.rulesListRequest(params));
      }
    }
  }

  const handleOfferScroll = () => {
    var count = offerDataCount;
    var offerListCount = offerList.length;
    var advertiserDrop = document.getElementById("offerDropDown");
    if ((advertiserDrop.offsetHeight + advertiserDrop.scrollTop + 10) >= advertiserDrop.scrollHeight) {
      if (count > offerListCount && offerListCount !== 0) {
        setTotalOffset(totalOffset + 10);
        let params = {};
        if (selectedOrganization) {
          params.organization = selectedOrganization.id;
        }
        params.limit = 10;
        params.offset = totalOffset + 10;
        if (searchedDimension !== "") {
          params.searchKey = searchedDimension;
        }
        // update it with offers API.
        dispatch(offerActionCreator.getOfferListRequest(params));
      }
    }
  }

  useEffect(() => {
    var arr1 = [15, 16];
    var arr2 = dimensions;
    if (arr1.some(item => arr2.includes(item))) {
      setMetricOptions(MetricsDrop);
    } else {
      setMetricOptions(MetricsDrop2);
    }
  }, [dimensions])

  useEffect(() => {
    setstate(validatorsObj);
    return function cleanup() {
      setShowReportTable(false);
      setReportBuilderData([]);
      dispatch(reportBuilderActionCreator.runReportClearAll());
    };

  }, []);

  useEffect(() => {
    if (location && location.state) {
      const source = location.state?.source;
      setSource(source);
    }
  }, [location]);

  useEffect(() => {
    const obj = [...dimension_filters];
    var advertiserDrop = document.getElementById("advertiserDropDown");
    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "advertiser" && adList) {
      obj[currentDimensionIndex.index].dropdownOptions = adList;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (adList && advertiserDrop) {
      advertiserDrop.addEventListener('scroll', handleAdvertiserScroll);
      return () =>
        advertiserDrop.removeEventListener('scroll', handleAdvertiserScroll);
    }

  }, [adList]);

  useEffect(() => {
    const obj = [...dimension_filters];
    var advertiserDrop = document.getElementById("ruleDropDown");

    if ((currentDimensionIndex.index !== null && currentDimensionIndex.value === "rule_id" ||
      currentDimensionIndex.index !== null && currentDimensionIndex.value === "rule") && rulesDataList) {
      // store rules list in another state as well.
      var t = [...new Set([...rulesDataList])];
      for (var i = 0; i < t.length; i++) {
        t[i].name = t[i]['rule_name'];
      }
      obj[currentDimensionIndex.index].dropdownOptions = t;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (rulesDataList && advertiserDrop) {
      advertiserDrop.addEventListener('scroll', handleRuleScroll);
      return () =>
        advertiserDrop.removeEventListener('scroll', handleRuleScroll);
    }

  }, [rulesDataList]);


  useEffect(() => {
    const obj = [...dimension_filters];
    var advertiserDrop = document.getElementById("offerDropDown");

    if (currentDimensionIndex.index !== null && currentDimensionIndex.value === "offer" && offerList) {
      // store rules list in another state as well.
      var t = [...new Set([...offerList])];
      for (var i = 0; i < t.length; i++) {
        t[i].name = t[i]['offer_name'];
      }
      obj[currentDimensionIndex.index].dropdownOptions = t;
      obj[currentDimensionIndex.index].isLoading = false;
      setDimension_filters(obj);
    }

    if (offerList && advertiserDrop) {
      advertiserDrop.addEventListener('scroll', handleOfferScroll);
      return () =>
        advertiserDrop.removeEventListener('scroll', handleOfferScroll);
    }

  }, [offerList]);


  useEffect(() => {
    const obj = [...dimension_filters];
    if (isFetched && reportData) {
      setReportBuilderData(reportData);
      setShowReportTable(true);
    }

    if (advertiserCount) {
      setAdCount(advertiserCount);
    }

    if (advertiserList && advertiserList.length > 0) {
      const newList = adList.concat(advertiserList);
      setAdList(newList);
    }

    if (rulesDataCount) {
      setRulesCount(advertiserCount);
    }

    if (rulesList && rulesList.length > 0) {
      const newList = rulesDataList.concat(rulesList);
      setRulesDataList(newList);
    }

    if (offerDataCount) {
      setOfferCount(offerDataCount);
    }

    if (offerDataList && offerDataList.length > 0) {
      const newList = offerList.concat(offerDataList);
      setOfferList(newList);
    }
    setRules(rulesList);

  }, [rulesList, rulesDataCount, advertiserList, advertiserCount, reportData, offerDataList, offerDataCount]);

  useEffect(() => {
    let valid = false;
    for (let i = 0; i < Object.keys(formState).length; i++) {
      if ((formState.start_date_report !== "" && formState.start_date_report !== undefined) &&
        formState.interval !== "" &&
        formState.dimensions.length != 0 &&
        formState.metrics.length != 0) {
        valid = true;
      }
    }
    setIsValid(valid);
  }, [formState]);
  return (<>
    <div className="" id="report-builder-section">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <nav className={"left-nav-section"} style={{}}>
          <Button className="btn-transparent pl-5 pb-3" style={{ verticalAlign: "super" }} onClick={backToConversionList}>
            <img src={statusIcons.BACK} alt="go to back" className="go-back" />
          </Button>
          <h2 className={""} id="rule-header"
            style={{ color: "#0E0E0F", display: "inline-block", paddingLeft: "24px" }}>
            {showReportTable ? 'Report' : `Invisibly Report Builder`}</h2>
        </nav>
        {showReportTable && (
          <Button onClick={generateCSV}
            disabled={reportBuilderData.length === 0}
            style={{ minWidth: "153px", minHeight: "48px" }}
            className="in-btn-sm in-btn-primary btn-export-result"
          >
            {loading ? <ADspinner size="large" /> : 'Export Results'}
          </Button>
        )}
      </div>
      {!showReportTable ?
        <div id="conversion-report-form" className="report-builder-form">
          <div className="report-builder-options">
            <Card className="data-card report-option-card">
              <h2 className="option-title">Report settings</h2>
              <Row>
                <Col md={12} sm={12} lg={4}>
                  <div className="option-selector">
                    <p style={{ marginLeft: "20px" }}>Date range</p>
                    <div className="date-range-container">
                      <div className="date-range-wrapper">
                        <nav className="ad-date-range-container">
                          <Dropdown
                            isOpen={selectedDatedownOpen}
                            toggle={toggleSelectedDate}
                            className={(selectedDatedownOpen ? "dropdown-option-selected" : "") + " dropdown-option"}
                          >
                            <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              className="dropdown_toggle"
                            >
                              <span
                                className="dropdown_title"
                                title="Select range"
                              >
                                {selectedDateRange ? selectedDateRange : 'Date Range'}
                              </span>
                              <img
                                src={
                                  selectedDatedownOpen
                                    ? statusIcons.CALENDER_BLUE_ICON
                                    : statusIcons.CALENDER_GRAY_ICON
                                }
                                className="dropdown-img"
                              ></img>
                            </DropdownToggle>
                            <DropdownMenu right className="ad-date-range-picker">
                              <DropdownItem toggle={false}>
                                <ADCalendar
                                  conversionBuilder={true}
                                  getDate={getDateFromCalender}
                                  closeCalender={closeCalender}
                                  selectedDateRangeOption={selectedDateRangeOption}
                                  dateFormat={'dd/MM/yy'}
                                  selectedDate={[{
                                    startDate: selectedStartTime ? selectedStartTime : startOfMonth(new Date()),
                                    endDate: selectedEndTime ? selectedEndTime : startOfMonth(new Date()),
                                    key: "selection",
                                  }]}
                                />
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </nav>
                        {/* <img
                        src={selectedDatedownOpen ? statusIcons.CALENDER_BLUE_ICON : statusIcons.CALENDER_GRAY_ICON}
                        alt="Calender icon"
                        className="calender-icon"
                      /> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} sm={12} lg={6}>
                  <div className="option-selector">
                    <p>Interval</p>
                    <Dropdown
                      isOpen={dateIntervalDropdownOpen}
                      toggle={toggleDateInterval}
                      className="dropdown-option"
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        className="dropdown_toggle"
                      >
                        <span
                          className="dropdown_title"
                          title={"Select interval"}
                        >
                          {dateIntervalArr.find(data => data.id === interval) ? dateIntervalArr.find(data => data.id === interval).label : (<><span>Select interval </span><span className="text-danger">*</span></>)}
                        </span>
                        <img
                          src={
                            dateIntervalDropdownOpen
                              ? statusIcons.ARROW_UP
                              : statusIcons.ARROW_DOWN
                          }
                          className="dropdown-img"
                        ></img>
                      </DropdownToggle>
                      <DropdownMenu>
                        {dateIntervalArr && (
                          dateIntervalArr.map((item) => {
                            return (
                              <DropdownItem
                                key={item.id}
                                onClick={() => {
                                  setInterval(item.id);
                                  checkFormValidation('interval', item.id);
                                }}
                                className={`d-flex justify-content-between align-items-center ${item.id === interval ? "dropdown-option-selected" : ""}`}
                              >
                                {item.label}

                                {item.id === interval ? (<img
                                  src={statusIcons.CHECKED_BLUE_ICON}
                                  alt="dimension selected"
                                  className="option-selected"
                                />) : ""}
                              </DropdownItem>
                            )
                          })
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>

            </Card>

            <Card className="data-card report-option-card">
              <h2 className="option-title">Dimensions </h2>
              <Row>
                <Col md={12} sm={12} lg={6}>
                  <div className="option-selector">
                    <Dropdown
                      isOpen={dimensionIntervalDropdownOpen}
                      toggle={toggleDimensionInterval}
                      className="dropdown-option"
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        className="dropdown_toggle"
                      >
                        <span
                          className="dropdown_title"
                          title={"Select dimensions"}
                        >
                          {/* {DimensionDrop.find(data => data.id === dimension) ? DimensionDrop.find(data => data.id === dimension).label : (<><span>Select dimensions </span><span className="text-danger">*</span></>)} */}
                          {noOfDimensions > 0 ? `${noOfDimensions} Dimension${noOfDimensions > 1 ? 's' : ''} Selected` : (<><span>Select dimensions </span><span className="text-danger">*</span></>)}
                        </span>
                        <img
                          src={
                            dimensionIntervalDropdownOpen
                              ? statusIcons.ARROW_UP
                              : statusIcons.ARROW_DOWN
                          }
                          className="dropdown-img"
                        ></img>
                      </DropdownToggle>
                      <DropdownMenu>
                        {DimensionDrop && (
                          DimensionDrop.map((item, index) => {
                            return (
                              <DropdownItem
                                toggle={false}
                                key={"dimensions" + index}
                                id={"dimensions" + item.value + index}
                                className={`${dimensions.includes(item.id) ? "dropdown-option-selected" : ""}`}
                              >
                                <div className="options-container" >

                                  <CustomInput
                                    className={`ad-transparent-checkbox`}
                                    type="checkbox"
                                    name={item.label}
                                    value={item.value}
                                    id={item.value}
                                    label={item.label}
                                    checked={dimensions.findIndex((element) => element === item.id) > -1 ? true : false}
                                    onChange={(e) => handleDimensionsChange(e, item.id)}
                                  />
                                  {dimensions.includes(item.id) ? (<img
                                    src={statusIcons.CHECKED_BLUE_ICON}
                                    alt="dimension selected"
                                    className="option-selected"
                                  />) : ""}
                                </div>
                              </DropdownItem>
                            )
                          })
                        )}
                      </DropdownMenu>
                    </Dropdown>
                    <div className="selected-options ad-mt-16">
                      {dimensions.map((item, index) => {
                        return (
                          <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                            { dimensionOptions.find(data => data.id === item).label}
                            <img
                              src="/Icon_assets/svg/close_icon.svg"
                              alt="close icon"
                              className="ml-2 close-icon"
                              onClick={(e) => removeSelectedDimensions(e, item)}
                            />
                          </span>
                        );
                      })}

                      {dimensions.length > 0 ? (<span><Button className="ad-btn-link" onClick={removeAllDimensions}>Clear all</Button></span>) : ""}
                    </div>
                  </div>
                </Col>
              </Row>

              <div id="dimension-filter-container">
                {dimension_filters &&
                  dimension_filters.length > 0 ?
                  dimension_filters.map((dFilter, index) => {
                    return (<DimensionFilter
                      clearAdData={clearAdData}
                      filteredKey={filteredKey}
                      key={dFilter.id + index}
                      index={index}
                      OptionsList={dimensionOptions.filter(d => d.label !== "Rule ID")}
                      filter={dFilter}
                      removeFilter={removeFilter}
                      handleReportChange={handleReportChange}
                      dimensionsFilterSearch={dimensionsFilterSearch}
                      currentDimensionIndex={currentDimensionIndex}
                      removeSelectedFilterDimensions={removeSelectedFilterDimensions}
                      removeAllFilterDimensions={removeAllFilterDimensions}
                    />)
                  }) : ""}
              </div>

              {dimension_filters.length < 4 && (
                <Row>
                  <Col md={12} sm={12} lg={12}>
                    <Button
                      className={`btn-transparent add-filter ${dimension_filters.length > 0 ? 'add-filter-active' : 'add-filter-inactive'}`}
                      onClick={() => addFilter('dimension')}>
                      {/* onClick={() => console.log('trst')}> */}
                      <img
                        src={dimension_filters.length > 0 ? statusIcons.PLUS_ICON_BLUE_TRAN : statusIcons.PLUS_ICON_GRAY_TRAN}
                        alt="Add Metrics Filters"
                        className="add-filter-img"
                      />
                          Add Dimensions Filters
                        </Button>
                  </Col>
                </Row>
              )}
            </Card>

            <Card className="data-card report-option-card">
              <h2 className="option-title">Metrics</h2>
              <Row>
                <Col md={12} sm={12} lg={6}>
                  <div className="option-selector">
                    <Dropdown
                      isOpen={metricsDropdownOpen}
                      toggle={toggleMetrics}
                      className="dropdown-option metrics"
                      direction="down"
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        className="dropdown_toggle"
                      >
                        <span
                          className="dropdown_title"
                          title="Select Metrics"
                        >
                          {noOfMetrics > 0 ? `${noOfMetrics} Metric${noOfMetrics > 1 ? 's' : ''} Selected` : (<><span>Select Metrics </span><span className="text-danger">*</span></>)}
                        </span>
                        <img
                          src={
                            metricsDropdownOpen
                              ? statusIcons.ARROW_UP
                              : statusIcons.ARROW_DOWN
                          }
                          className="dropdown-img"
                        ></img>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="metrics-list">
                          {metricsOptions && (
                            metricsOptions.length > 0 ?
                              metricsOptions.map((item, index) => {
                                return (
                                  <DropdownItem
                                    toggle={false}
                                    key={"metrics" + index}
                                    id={"metrics" + item.value + index}
                                    className={`${metrics.findIndex((element) => element.id === item.id) > -1 ? "dropdown-option-selected" : ""}`}
                                  >
                                    <div className="options-container">
                                      <CustomInput
                                        className={`ad-transparent-checkbox`}
                                        type="checkbox"
                                        name={item.label}
                                        value={item.value}
                                        id={item.value}
                                        label={item.label}
                                        checked={metrics.findIndex(
                                          (element) => element.id === item.id) > -1 ? true : false
                                        }
                                        onChange={(e) => handleMetricsCheckboxChange(e, item)}
                                      />

                                      {metrics.findIndex((element) => element.id === item.id) > -1 ? (<img
                                        src={statusIcons.CHECKED_BLUE_ICON}
                                        alt="metrics selected"
                                        className="option-selected"
                                      />) : ""}
                                    </div>

                                  </DropdownItem>
                                )
                              }) : (<DropdownItem>No matches found</DropdownItem>)
                          )}
                        </div>
                      </DropdownMenu>
                    </Dropdown>

                    <div className="selected-options ad-mt-16">
                      {metrics.map((item, index) => {
                        return (
                          <span className="badge badge-dark selected-badge" key={"selected-badge" + index}>
                            {item.label}
                            <img
                              src="/Icon_assets/svg/close_icon.svg"
                              alt="close icon"
                              className="ml-2 close-icon"
                              onClick={(e) => removeSelectedMetrics(e, item)}
                            />
                          </span>
                        );
                      })}

                      {metrics.length > 0 ? (<span><Button className="ad-btn-link" onClick={removeAllMetrics}>Clear all</Button></span>) : ""}
                    </div>
                  </div>
                </Col>
              </Row>

              {/* {metrics.findIndex((element) => element.label === "Conversions") > -1 &&
                <div id="metrics-filter-container">
                  <ConversionWindow
                    conversionFilter={conversion_filter}
                    conversion={conversion}
                    statusIcons={statusIcons}
                    handleReportChange={handleReportChange}
                    handleConversionData={handleConversionData}
                  />
                </div>} */}

              <div id="metrics-filter-container">
                {metric_filters &&
                  metric_filters.length > 0 ?
                  metric_filters.map((dFilter, index) => {
                    return (
                      <MetricFilter
                        key={dFilter.id + index}
                        index={index}
                        OptionsList={metricsOptions}
                        filter={dFilter}
                        removeFilter={removeFilter}
                        handleReportChange={handleReportChange}
                      />)
                  }) : ""}
              </div>

              <Row>
                <Col md={12} sm={12} lg={6}>
                  <Button
                    className={`btn-transparent add-filter ${metric_filters.length > 0 ? 'add-filter-active' : 'add-filter-inactive'}`}
                    onClick={() => addFilter('metrics')}
                  >
                    <img
                      src={metric_filters.length > 0 ? statusIcons.PLUS_ICON_BLUE_TRAN : statusIcons.PLUS_ICON_GRAY_TRAN}
                      alt="Add Metrics Filters"
                      className="add-filter-img"
                    />
                    Add Metrics Filters
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="report-builder-footer">
            <Button
              className="in-btn-lg in-btn-info ml-3 btn-run"
              type="submit"
              disabled={isValid ? false : true}
              onClick={handleRunRequest}>
              {isRunning ? <ADspinner size="large" /> : 'Run'}
            </Button>
          </div>
        </div>
        :
        reportBuilderData && (
          <ReportTable
            jsonData={reportBuilderData}
            tableLoaded={isRunning} />
        )}
    </div>
  </>)

}

export default ConversionReportBuilder;