import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import Loader from '../../../Loader';
import ROUTES from "../../../../utils/routeConstants";
import history from "../../../../history";
import { useParams, useLocation } from "react-router-dom";
import StreamThumbNail from "./StreamThumbNail";
import VideoStreaming from "../VideoStreaming";
import { offerActionCreator, realtimeResearchActionCreator, surveyActionCreator } from "../../../../actions";
import { Row, Col, Card, CardBody, Button, Input, FormGroup, Label } from "reactstrap";
import { clickableScript } from "../../../../utils/previewMessageClickableScript";
import Thumbnails from "./Thumbnails";
import ADspinner from '../../../Loader/ADspinner';
import RBAC from "../../../../components/RBAC";
import { PERMISSIONS } from "../../../../utils/permissionMatrix";

const BrandOfferPreview = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let msg_id, ad_id, brand_id = "";
  let param = useParams();
  msg_id = param.msg_id;
  ad_id = param.ad_id;
  brand_id = param.brand_id;


  const { goToGeneralSetup } = props;

  if (props && (props.brand_id || props.offerId || props.ad_id)) {
    msg_id = props.offerId;
    ad_id = props.ad_id;
    brand_id = props.brand_id;
  }

  const shadow = "2px 8px 12px rgb(19 36 139 / 4%)";
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");

  const offerData = useSelector((state) => state.offer.offerData);
  const offerRequest = useSelector((state) => state.offer.offerRequest);
  const isLoading = useSelector((state) => state.offer.isLoading);
  const tableData = useSelector((state) => state.realtimeResearch.realtimeResearchData);

  let initialThumbnailsCount = 0;
  const [thumbnailsCount, setThumbnailsCount] = useState(initialThumbnailsCount);


  const previewMessage = useSelector((state) => state.surveyData.previewSurvey);
  const isPreviewFetching = useSelector((state) => state.surveyData.isLoading);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState(props ? props.message : "");
  const [mediaURL, setMediaURL] = useState("");
  const [nowPlay, setNowPlay] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [isActivationProgress, setIsActivationProgress] = useState(false);
  const [slideIt, setSlideIt] = useState(false);
  const initialSelectionState = {
    url: offerData.media_1_url,
    type: offerData.media_1_type,
    index: 1
  }
  const [currentSelection, setCurrentSelection] = useState(initialSelectionState);
  const [showTop, setShowTop] = useState(false);
  const msgbodyStyle = { height: '100%', overflow: "auto", marginTop: "0", background: "#9d5cff", minHeight: "400px" };

  window.closeSurvey = () => {
    setSurveyComplete(true);
  }

  const elementScrolling = (scrollingTo, divId) => {
    const element = document.getElementById(divId);
    if (scrollingTo === "top") {
      element.scrollTop = 0;
      setShowTop(false);
    } else if (scrollingTo === "bottom") {
      element.scrollTop = element.scrollHeight - element.clientHeight;
      setShowTop(true);
    }
  }

  const handleCurrentSelection = (e, selectedCurrentUrl, selectedCurrentType, selectedCurrentIndex) => {
    setCurrentSelection({
      ...currentSelection,
      url: selectedCurrentUrl,
      type: selectedCurrentType,
      index: selectedCurrentIndex
    });

    setSlideIt(true);
    setTimeout(() => {
      setSlideIt(false);
    }, 1000);
  }

  const handleThumbnailsScroll = (Scrollstate) => {
    setShowTop(Scrollstate);
  }

  const handleNowPlay = (e) => {
    setNowPlay(true)
  }

  const goToSurveyScreen = () => {
    var params = {
      brands: brand_id,
      messages: message
    };
    setActiveStep(2);
    dispatch(surveyActionCreator.getPreviewRequest(params));
  }

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      setMessage(location.state.detail.message);
    }
  }, [location]);

  useEffect(() => {

    Array(8).fill(null).map((data, index) => {
      if (offerData[`media_${index + 1}_url`] !== undefined && offerData[`media_${index + 1}_url`] !== null) {
        initialThumbnailsCount = initialThumbnailsCount + 1;
      }
    });

    if (offerData?.id)
      setIsActivated(offerData?.state !== 1)

    if (offerData?.media_1_type) {
      setCurrentSelection({
        url: offerData.media_1_url,
        type: offerData.media_1_type,
        index: 1
      })
    }

    setThumbnailsCount(initialThumbnailsCount);
  }, [offerData]);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (previewMessage) {
      const frames = previewMessage && previewMessage.previews &&
        previewMessage.previews["frame_1"] ? previewMessage.previews["frame_1"] : "";

      let keys = Object.keys(frames),
        sizes, width, height;

      let sizeKey = [];
      keys.map((value, index) => {
        sizeKey = value === "size" ? keys.splice(index, 1) : "";
        return sizeKey;
      });


      const previewHtml =
        frames[keys[0]] ? frames[keys[0]] + clickableScript : '<div style=" height:150px; width: 150px;font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';

      if (sizeKey.length > 0) {
        sizes = frames && frames.size ? frames.size : "";
        height = sizes && sizes.height ? sizes.height : "";
        width = sizes && sizes.width ? sizes.width : "";
      }


      setPreviewHtml(previewHtml);
    } else {
      const previewHtml = '<div  style=" font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';
      setPreviewHtml(previewHtml);
    }

  }, [previewMessage]);

  useEffect(() => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }, []);


  useEffect(() => {
    if (offerData) {
      setMediaURL(offerData.media_1_url);
    }
    if (offerData && offerData.ad) {

      dispatch(realtimeResearchActionCreator.getRealtimeResearchListRequest({ message_id: offerData.ad }));
    }
  }, [offerData]);

  useEffect(() => {
    if (tableData) {
      setMessage(tableData.message);
    }
  }, [tableData]);

  const goToLastPlace = () => {
    if (props.hideHeader) {
      goToGeneralSetup();
    } else {
      history.push(`${ROUTES.OFFERS_LIST}`)
    }
  }

  const copyLink = () => {
    var copyText = document.getElementById("shareable-link");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }
  useEffect(() => {
    if (offerRequest?.state === 1 && isActivationProgress === true)
      setTimeout(() => {
        history.push(`${ROUTES.OFFERS_LIST}`)
      }, 2000)
    else {
      setTimeout(() => setIsActivationProgress(false), 2000)
    }
  }, [offerRequest])

  const activate = () => {
    setIsActivationProgress(true)
    let param = {};
    param.state = 1;
    param.data_id = offerData?.id;
    dispatch(offerActionCreator.createBrandOfferRequest(param));
  }

  const reload = () => {
    var params = {
      source: 1,
      limit: 1,
      id: msg_id
    };
    dispatch(offerActionCreator.getOfferListByIdRequest(params));
  }

  return (<>

    <section className="offer-details-header">
      <nav className="left-nav-section title-section-with-back pb-3 campaign-header" style={{ paddingLeft: "48px" }}>
        <Button className="btn-transparent"
          onClick={goToLastPlace}
          style={{ verticalAlign: "bottom" }} >
          <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back"
            style={{ verticalAlign: props.hideHeader ? "bottom" : "super" }} />
        </Button>
        <h2 id={"survey-header"} style={{ display: "inline-block" }}
          className="mb-0 pl-4 secondary-title">
          {`Preview ( ${props.updatedOfferName ? props.updatedOfferName :
            location?.state?.detail?.offer_name})`}</h2>
      </nav>
      <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
        <div className="text-right form-group">
          {isActivated &&
            <RBAC requiredPermissions={PERMISSIONS.OFFER_WRITE} >
              <Button className="in-btn-sm in-btn-info btn-export ml-3 btn-next"
                style={{ minWidth: '144px', minHeight: '48px' }}
                onClick={activate}>
                {isActivationProgress ? <ADspinner /> :
                  `Activate`}
              </Button>
            </RBAC>}
        </div>
      </nav>
    </section>

    <Row>
      <Col>
        <div id="reload-survey" style={{ marginBottom: "20px" }}>
          <img onClick={reload}
            src="/Icon_assets/svg/reload-icon.svg"
            alt="share"
            className="link-image"
          />
          <span className="reload-survey" onClick={reload}>Reload Offer</span>
        </div>
      </Col>
    </Row>

    <div className="brand-offer-card">
      {
        activeStep === 0 ?
          <section style={{ margin: "auto", padding: "10px", cursor: "pointer" }} >
            <Card className="offer-card center-logo">
              <CardBody>
                <div onClick={() => setActiveStep(1)}>
                  <section className="offer-wrapper">
                    {isLoading ?
                      (<>
                        <CardBody className="feed-card-body is-details-loading">
                          <div className="loading-data-section offer-data-loading">
                            <Loader />
                          </div>
                        </CardBody>
                      </>)
                      :
                      (<>
                        {offerData?.media_1_type === 'stream' &&
                          <div className="card-img"
                            id={`banner${offerData.id}`}>
                            <StreamThumbNail className="card-video-thumbnail"
                              bgSrc={offerData?.media_1_url}
                              children={
                                (
                                  <>
                                    <img
                                      src="/Icon_assets/svg/video-play.svg"
                                      className="play-btn"
                                    />
                                    <img
                                      src="/Icon_assets/png/arc.png"
                                      style={{
                                        position: "absolute",
                                        bottom: '0px',
                                        width: "100%",
                                      }}
                                      alt="arc"
                                      className="arc-style-img"
                                    />
                                  </>)

                              } />

                          </div>

                        }
                        {currentSelection.type === "image" &&
                          <section className="header-banner-section">
                            <div className="card-img" id={`banner-${offerData.id}`}
                              style={{
                                backgroundImage: "url('" + currentSelection.url + "')",
                                backgroundSize: "100%",
                                maxHeight: "432px",
                                minHeight: "120px",
                                backgroundPosition: "center center",
                                maxWidth: "240px",
                                height: "135px",
                                backgroundPostion: "center top"
                              }}>
                              <img src="/Icon_assets/png/arc.png" alt="arc"
                                className="arc-style-img"
                                style={{
                                  position: "absolute", bottom: "0px",
                                  width: "100%", left: "0px", display: "none"
                                }} />
                            </div>
                          </section>}

                        {currentSelection.type === 'video' &&
                          <div
                            className="card-img"
                            id={`banner${offerData.id}`}
                            style={{
                              backgroundImage: `url(/Icon_assets/svg/default-thumbnail.jpg)`,
                              maxHeight: "432px",
                              minHeight: "120px",
                              backgroundPosition: "center center",
                              maxWidth: "240px",
                              height: "135px",
                            }}
                          >
                            <img
                              src="/Icon_assets/svg/video-play.svg"
                              className="play-btn"
                            />
                            <img
                              src="/Icon_assets/png/arc.png"
                              style={{
                                position: "absolute",
                                bottom: '0px',
                                width: "100%",
                              }}
                              alt="arc"
                              className="arc-style-img"
                            />
                          </div>

                        }
                      </>)}
                    <section className="content-section brand-offer"
                      style={{ display: "block" }}>
                      <section className="brand-offer-title brand-offer">
                        <nav className="brand-card-name">{offerData.type === 2 ? `Survey` : `Brand Offer`}
                          <span className="brand-name">{offerData.brand_name}</span>
                        </nav>
                      </section>
                      <nav className="title-part">
                        <p id={`content-title${offerData.id}`}
                          className="title-content">{offerData.title}
                        </p></nav></section>
                  </section>
                  <section className="brand-offer-data-earn-option brand-offer">Earn
                    <img src="/Icon_assets/svg/Invisibly-token-blue-filled.svg"
                      style={{ paddingLeft: "5px" }} alt="Invisibly point" /> {offerData.monetary}
                  </section>
                </div>
              </CardBody>
            </Card>
            <FormGroup>
              {/* <Col> */}
              <div id="link-div" >
                <Label for="shareable-link" className="shareable-link">Sharelabel Link:</Label>
                <Input
                  type="text"
                  name="link"
                  id="shareable-link"
                  className="share-input"
                  autoComplete="off"
                  value={`http://${window.location.host}/offers/offer-preview-share/${localStorage.getItem('id')}/${localStorage.getItem('brand_id')}`}
                  readOnly={true} />
                <img
                  src="/Icon_assets/svg/share-icon.svg"
                  alt="share"
                  className="link-image"
                  style={{ cursor: "pointer" }}
                  onClick={copyLink}
                />
              </div>
              {/* </Col> */}
            </FormGroup>
          </section>
          :
          activeStep === 1 ?
            <div className="feeds-details-wrapper">
              <section className="feeds-details-section d-flex">
                <Thumbnails
                  offerDetails={offerData}
                  handleCurrentSelection={handleCurrentSelection}
                  showTop={showTop}
                  elementScrolling={elementScrolling}
                  isLoading={isLoading}
                  currentSelection={currentSelection}
                  thumbnailsCount={thumbnailsCount}
                  handleThumbnailsScroll={handleThumbnailsScroll}
                />
                <Card className={`feeds-details-card ${thumbnailsCount <= 1 ? "w-100" : ""} ${offerData.type ? (offerData.type === 1 ? "survey-brand-card brand" : "survey-brand-card survey") : ""}`}>
                  {isLoading ?
                    (<>
                      <CardBody className="feed-card-body is-details-loading">
                        <div className="loading-data-section offer-data-loading">
                          <Loader />
                        </div>
                      </CardBody>
                    </>)
                    :
                    <CardBody className="feed-card-body">
                      <div>
                        <div className={`image-column ${currentSelection.type !== "image" ? "video-column" : ""}`}>
                          <div className={`feeds-image-container ${currentSelection.type !== "stream" && currentSelection.type !== "video" ? "bg-white" : ""}`}>
                            <div className="selected-image-container">
                              <div className={`selected-image-wrapper ${currentSelection.type === "stream" ? "video-stream" : ""}`}>

                                <div className={`selected-image selected-video ${slideIt ? "slideit" : ""}`}>
                                  {currentSelection.type === "image" ?
                                    <>
                                      <img src={currentSelection.url}
                                        alt={offerData.title} className="selected-feed-image" />
                                    </>
                                    : currentSelection.type === "stream" ?
                                      (<>
                                        <div className="video-stream-wrapper" key={`key` + offerData.id}>
                                          <VideoStreaming
                                            url={currentSelection.url}
                                            autoPlay={(currentSelection.url !== offerData.media_1_url) || nowPlay}
                                          />
                                          {!nowPlay &&
                                            <StreamThumbNail bgSrc={currentSelection.url} className="stream-thumbnail">
                                              <div className="video-icon" onClick={handleNowPlay}>
                                                <img src={"/Icon_assets/svg/video-play.svg"} alt="Play video" />
                                              </div>
                                            </StreamThumbNail>}
                                        </div>
                                      </>)
                                      : currentSelection.type === "video" ?
                                        (<>
                                          <video width="100%"
                                            controls
                                            autoPlay={(currentSelection.url !== offerData.media_1_url) || nowPlay}
                                            className="selected-feed-video"
                                            id="selected-feed-video"
                                            key={offerData.id + nowPlay}
                                          >
                                            <source src={currentSelection.url} type="video/mp4" />
                                            <source src={currentSelection.url} type="video/ogg" />
                                            Your browser does not support HTML video.
                                          </video>
                                          {(!nowPlay && (
                                            <div
                                              className="stream-thumbnail"
                                              style={{ backgroundImage: `url( "/Icon_assets/svg/default-thumbnail.jpg")` }}
                                            >
                                              <div className="video-icon" onClick={handleNowPlay}>
                                                <img src={"/Icon_assets/svg/video-play.svg"} alt="Play video" />
                                              </div>
                                            </div>
                                          ))}
                                        </>)
                                        :  //If none of above show image
                                        (offerData?.image ? (<img
                                          src={offerData?.image}
                                          alt={offerData?.title}
                                          className="selected-feed-image"
                                        />)
                                          :
                                          <span className="bg-danger text-white">Format is not supported</span>)
                                  }
                                  < div className="earn-badge-wrapper">
                                    <div className="earn-badge">
                                      <div className={`badge brand`}>
                                        Earn
                                        <img src={"/Icon_assets/svg/Invisibly-token-blue-filled.svg"} alt="survey" className="badge-icon" />
                                        {offerData?.monetary}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-column" id="content-column">
                          <div className="feeds-content-container">
                            <div className="feeds-content-container">
                              <p title=""
                                className="feeds-category card-text">
                                <section className="brand-offer-title brand-offer">
                                  <nav className="brand-card-name">Brand Offer
                                    <span className="brand-name">{offerData.brand_name}</span>
                                  </nav>
                                </section>
                              </p>
                              <h1 title="offer-title" className="feeds-title card-title">{offerData.title}</h1>
                              <p className="feeds-description feeds-description-collapsed free-content survey card-text">{offerData.description}</p>
                              <Button type="button"
                                className="in-btn-sm in-btn-primary in-btn btn-secondary 
                          btn-redirect btn-offer btn btn-secondary">
                                <img src="/Icon_assets/svg/offer-box.svg" className="offer-box"
                                  style={{ paddingLeft: "5px" }} alt="offer box" />
                                {offerData.button_text}</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  } </Card>
              </section>
            </div>
            :
            <div>
              <Row>
                <Col>
                  <div style={{
                    background: "#9d5cff", color: "#fff",
                    fontSize: "18px", height: "15%", paddingTop: "40px",
                    display: surveyComplete ? "none" : "flex",
                  }} className="survey-text">
                    <section className="brand-offer-title brand-offer">
                      <nav className="brand-card-name">Brand Offer
                        <span className="brand-name">{offerData.brand_name}</span>
                      </nav>
                    </section>
                  </div>
                  <div className="iframe-div" style={msgbodyStyle}>
                    {
                      isPreviewFetching ?
                        <Loader />
                        :
                        <>
                          <div className="container" style={{ display: surveyComplete ? "block" : "none" }}>
                            <p className="vertical-center">Congratulations! You have completed the survey.</p>
                          </div>

                          <iframe frameBorder="0" title="previewIframe" name="frame"
                            style={{
                              width: `100%`,
                              height: `100%`,
                              boxShadow: `${shadow}`,
                              minHeight: `620px`,
                              display: surveyComplete ? "none" : "block",
                            }}
                            srcdoc={previewHtml} scrolling='no'>
                          </iframe>
                        </>
                    }
                  </div>
                </Col>
              </Row>
            </div>
      }
    </div>
  </>);

}

export default BrandOfferPreview;