import React, { useState, useEffect } from 'react'
import { CustomInput } from 'reactstrap'
import './index.scss'

const DEVICES = [
  {
    id: 1,
    label: 'Mobile',
    value: 'Mobile'
  },
  {
    id: 2,
    label: 'Desktop',
    value: 'Desktop'
  }
]

function DeviceFilter(props) {

  const [devices, setDevices] = useState(DEVICES)
  const [selectedDevices, setSelectedDevices] = useState([]);

  const handleDeviceSelect = (device) => {
    if (selectedDevices.some(selectedDevice => selectedDevice.id === device.id))
      setSelectedDevices(selectedDevices.filter(selectedDevice => selectedDevice.id !== device.id))
    else
      setSelectedDevices(prev => [...prev, { ...device }])
  }

  useEffect(() => {
    if (!props.isDisplayFilterRemoved)
      props.setSelectedDevices(selectedDevices)
  }, [selectedDevices])

  useEffect(() => {
    if (props.isDisplayFilterRemoved)
      setSelectedDevices(props.selectedDevices)

  }, [props.selectedDevices])

  const clearFilter = (e) => {
    e.stopPropagation();
    setSelectedDevices([])
    props.setSelectedDevices([])
  }

  return (
    <div className='device-filter-container'>
      <div className='d-flex device-title-container'>
        <div className='device-title'>
          Device Type
        </div>
        {selectedDevices.length > 0 && <img style={{ display: "inline-block", cursor: 'pointer' }}
          onClick={(e) => clearFilter(e)}
          toggle={false}
          src={"/Icon_assets/svg/close-image.svg"}
          className="arrow-down"
          alt='device'
        />}
      </div>
      {
        devices.map(device =>
          <CustomInput
            key={"device" + device.id}
            className="mt-3 device-item"
            type="checkbox"
            name={device.label}
            value={device.value}
            id={"device" + device.id}
            label={device.label}
            checked={selectedDevices.some(selectedDevice => selectedDevice.id === device.id)}
            onChange={(e) => handleDeviceSelect(device)}
          />
        )
      }

    </div>
  )
}

export default DeviceFilter