import React, { useEffect, useState } from 'react'
import { CustomInput } from 'reactstrap'
import './index.scss'

const GENDERS = [
  {
    id: 1,
    label: 'Male',
    value: 'male'
  },
  {
    id: 2,
    label: 'Female',
    value: 'female'
  },
  {
    id: 3,
    label: 'Non Binary',
    value: 'non-binary'
  },
  {
    id: 4,
    label: 'Unknown',
    value: 'unknown'
  },
]

function GenderFilter(props) {
  const [genders, setGenders] = useState(GENDERS)
  const [selectedGenders, setSelectedGenders] = useState([]);

  const handleGenderSelect = (gender) => {
    if (selectedGenders.some(selectedGender => selectedGender.id === gender.id))
      setSelectedGenders(selectedGenders.filter(selectedGender => selectedGender.id !== gender.id))
    else
      setSelectedGenders(prev => [...prev, { ...gender }])
  }

  useEffect(() => {
    if (!props.isDisplayFilterRemoved)
      props.setSelectedGenders(selectedGenders)
  }, [selectedGenders])

  useEffect(() => {
    if (props.isDisplayFilterRemoved)
      setSelectedGenders(props.selectedGenders)

  }, [props.selectedGenders])

  const clearFilter = (e) => {
    e.stopPropagation();
    setSelectedGenders([])
    props.setSelectedGenders([])
  }

  return (
    <div className='gender-filter-container'>
      <div className='d-flex gender-title-container'>
        <div className='gender-title'>
          Gender
        </div>
        {selectedGenders.length > 0 && <img style={{ display: "inline-block", cursor: 'pointer' }}
          onClick={(e) => clearFilter(e)}
          toggle={false}
          src={"/Icon_assets/svg/close-image.svg"}
          className="arrow-down"
          alt='gender'
        />}
      </div>
      {
        genders.map(gender =>
          <CustomInput
            key={"gender" + gender.id}
            className="mt-3 gender-item"
            type="checkbox"
            name={gender.label}
            value={gender.value}
            id={"gender" + gender.id}
            label={gender.label}
            checked={selectedGenders.some(selectedGender => selectedGender.id === gender.id)}
            onChange={(e) => { handleGenderSelect(gender) }}
          />
        )
      }

    </div>
  )
}

export default GenderFilter