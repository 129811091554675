
import React, { useState, useEffect } from 'react';
import "./index.scss";
import {
  Modal,
  ModalHeader, Form,
  FormGroup, Tooltip,
  ModalBody, Label, Input, CustomInput, Button
} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { realtimeResearchActionCreator } from "../../../actions";
import {
  addDays,
  startOfDay,
  sub,
  format
} from "date-fns";
import ADspinner from "../../Loader/ADspinner";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ExportModal = ({ open, closeModal, exportId, exportBrandId,
  surveyName, exportData, zip, answer, startTime, endTime,
  response, completes, orgImage }) => {

  const VALUES = {
    PDF: "PDF",
    CSV: "CSV",
    RAW_EVENTS: "Raw Events",
    SUMMARY_TABLES: "Summary Tables"

  }

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);

  const closeExportModal = () => {
    setSubReportType(VALUES.SUMMARY_TABLES);
    dispatch(realtimeResearchActionCreator.getExportRawEventsClear());
    closeModal();
  };

  const rawEvents = useSelector(
    (state) => state.realtimeResearch.exportRawEvents
  );

  const exportComplete = useSelector(
    (state) => state.realtimeResearch.exportComplete
  );

  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

  const [reportType, setReportType] = useState(VALUES.CSV);
  const [subReportType, setSubReportType] = useState(VALUES.SUMMARY_TABLES);
  const [loading, setLoading] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [eventsTooltipOpen, setEventsTooltipOpen] = useState(false);
  const eventsToggle = () => setEventsTooltipOpen(!eventsTooltipOpen);

  const [summaryTooltipOpen, setSummaryTooltipOpen] = useState(false);
  const summaryToggle = () => setSummaryTooltipOpen(!summaryTooltipOpen);
  const [orgDataImage, setOrgDataImage] = useState(orgImage);
  const clearcloseBtn = (
    <button className="modal-close" style={{ opacity: "1" }} onClick={closeExportModal}>
      <img src={"/Icon_assets/svg/close_icon_black.svg"} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>);

  const selectSubReport = (e, type) => {
    setSubReportType(type);
  }

  const dateToYMD = (date) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();

    return (
      "" +
      y +
      "-" +
      (m <= 9 ? "0" + m : m) +
      "-" + (d <= 9 ? "0" + d : d) +
      " " + "00:00:00");
  };


  const exportSummaryTable = () => {
    if (exportData) {
      let exportJSON2 = [], finalArray = [],
        inputArray = [], outputArray = [], jsonString = '[';

      if (Object.keys(exportData).length !== 0) {
        for (var i = 0; i < exportData.length; i++) {
          const dataMapping = exportData[i].options.map(row => {
            return {
              "Answer ID": row.id,
              "Answer": row.label,
              "Answer (%)": row.value,
              "Count": row.count,
              "Index": row["ans-index"]
            }
          });

          dataMapping.filter(function (d) {
            d["Question"] = exportData[i]["question"];
            d["Question ID"] = exportData[i]["qId"];
            d["Total"] = exportData[i]["total"]
          })
          exportJSON2.push(dataMapping.flat());

        }

        var flatArray = exportJSON2.flat();
        flatArray.filter(function (flat) {
          finalArray.push(
            {
              ["Question ID"]: flat["Question ID"],
              ["Question"]: flat["Question"],
              ["Answer ID"]: flat["Answer ID"].split("_")[1] || "",
              ["Answer"]: flat["Answer"],
              ["Answer Count"]: flat["Count"],
              ["Answer Percentage"]: flat["Answer (%)"] + '%',
              ["Answer Index"]: flat["Index"] + '%',

              // ["Total"]: flat["Total"]
            })
        });

        for (var i = 0; i < finalArray.length; i++) {
          for (const [key, value] of Object.entries(finalArray[i])) {
            inputArray.push('"' + key + '":"' + (value.toString().replace(/(\r\n|\n|\r)/gm, "").replace(/ +(?= )/g, '').replace(/"/g, '\'') + '"'));
          }
          outputArray.push('{' + inputArray.join(',') + '}');
        }
        jsonString += outputArray.join(",") + ']';
      }

      sendToCSV(jsonString);

    }
  }

  const exportDataToCsv = () => {

    if (reportType === VALUES.PDF) {
      setLoading(true);
      setTimeout(() => {
        console.debug('waiting');
        generatePDF();
      }, 8000);

    }
    else if (subReportType === VALUES.SUMMARY_TABLES) {
      setLoading(true);
      exportSummaryTable();

    } else if (subReportType === VALUES.RAW_EVENTS) {
      setLoading(true);
      // call api for raw events
      let param = {};
      const organization_id = selectedOrganization ? selectedOrganization?.id : "";
      const user = userData.organization_role;
      param.id = exportBrandId;
      param.end_date = dateToYMD(startOfDay(addDays(new Date(), 1)));
      param.start_date = dateToYMD(startOfDay(sub(new Date(), { months: 6 })));
      param.ad_id = exportId;
      param.organization_id = organization_id;
      param.user = user;
      param.answer = "";
      // set filters as applied on survey page.
      if (zip.length > 0) {
        param.zip = zip
      }
      if (answer.length > 0) {
        param.answer = answer.join();
      }
      if ((startTime !== undefined && startTime !== "") && (endTime !== undefined && endTime !== "")) {
        param.end_date = endTime
        param.start_date = startTime
      }
      var fileName = surveyName;
      var date = getDateFormat((new Date()));
      fileName += "_" + date;
      param.fileName = fileName;
      dispatch(realtimeResearchActionCreator.getExportRawEventsRequest(param));
    }
  }

  const getDateFormat = (date) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = (date.getHours() < 10 ? '0' : '') + date.getHours();
    let mm = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    let ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

    return (
      "" +
      (m <= 9 ? "0" + m : m) +
      "-" +
      (d <= 9 ? "0" + d : d) +
      "-" +
      y +
      "-" +
      hh +
      "_" +
      mm +
      "_" +
      ss
    );
  };

  const convertImgToBase64 = (url, callback, outputFormat) => {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var img = new Image;
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL(outputFormat || 'image/png');
      callback.call(this, dataURL, img.width);
      canvas = null;
      setOrgDataImage(dataURL);
    };
    img.src = url;
  }

  const sendToCSV = (data) => {
    var fileName = surveyName;
    var date = getDateFormat((new Date()));
    fileName += "_" + date;

    var data = typeof data != 'object' ? JSON.parse(data) : data;
    var exportCSV = '';

    var header = "";
    for (var idx in data[0]) {
      header += idx + ',';
    }
    header = header.slice(0, -1);
    exportCSV += header + '\r\n';

    for (var i = 0; i < data.length; i++) {
      var row = "";
      for (var index in data[i]) {
        row += '"' + data[i][index] + '",';
      }
      row.slice(0, row.length - 1);
      exportCSV += row + '\r\n';
    }

    if (exportCSV === '') {
      console.error("Invalid data");
      return;
    }

    var ua = window.navigator.userAgent;

    if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0) {
      var blob = new Blob([exportCSV], { type: "text/csv;charset=utf-8" });
      navigator.msSaveBlob(blob, fileName + ".csv")
    } else {
      var uri = 'data:text/csv;charset=utf-8,' + encodeURI(exportCSV);
      var link = document.createElement("a");
      link.href = uri;
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
    setLoading(false);
    closeExportModal();
  }

  useEffect(() => {
    if (exportComplete) {
      setLoading(false);
      closeExportModal();
      dispatch(realtimeResearchActionCreator.getExportRawEventsClear());
    }
    if (open) {
      setReportType(VALUES.CSV);
    }

    if (orgImage) {
      convertImgToBase64(orgImage, function (base64Img) {
        setOrgDataImage(base64Img)
      });
    }

  }, [exportComplete, open]);



  const generatePDF = () => {

    setReportType(VALUES.PDF);
    const DOC = new jsPDF('landscape', 'px', 'a4');
    // var DOC = new jsPDF()
    // DOC.addFileToVFS("./BasisGrotesquePro-Bold.ttf", BasisGrotesqueProBold);
    // DOC.addFont("BasisGrotesquePro-Bold.ttf", "BasisGrotesqueProBold", "bold")
    const pageWidth = DOC.internal.pageSize.getWidth();
    const pageHeight = DOC.internal.pageSize.getHeight();
    var dt = format(new Date(), 'eeee, LLLL d, yyyy');
    var st_date = format(startOfDay(sub(new Date(), { months: 6 })), 'MM/dd/yyyy')
    var end_date = format(new Date(), 'MM/dd/yyyy')
    var stDate = startTime === undefined ? st_date : format(new Date(startTime), 'MM/dd/yyyy')
    var endDate = endTime === undefined ? end_date : format(sub(new Date(endTime), { days: 1 }), 'MM/dd/yyyy')
    var suveryName = `Survey for ${surveyName}`;
    var num = DOC.splitTextToSize(suveryName, 170);
    var survey;
    if (num.length > 2) {
      survey = num[0] + " " + num[1].replace(/.{0,3}$/, '...')
    } else if (num.length <= 2) {
      survey = num;
    }

    survey = DOC.splitTextToSize(survey, 170);

    // ADD INTO PAGE AND FILTER PAGE
    DOC.addImage("/Icon_assets/png/INV-FB-Cover-Blue 1.png", "PNG", 65, 0, pageWidth, pageHeight, undefined, 'FAST');
    DOC.addImage("/Icon_assets/png/Rectangle 221.png", "PNG", 0, 0, 430, pageHeight, undefined, 'FAST');
    if (orgDataImage) {
      DOC.addImage(orgDataImage, "PNG", 35, (pageHeight - 50), 70, 25, undefined, 'FAST');
    } else
      DOC.addImage("/Icon_assets/png/pdf-page-logo.png", "PNG", 35, (pageHeight - 60), 70, 25, undefined, 'FAST');
    DOC.setDrawColor('#0E0E0F');
    DOC.setFontSize(30);
    DOC.setTextColor('#ffffff');
    DOC.setFont('helvetica');
    DOC.text(survey, 35, (pageHeight / 2 - 50));

    if (num.length < 2) {
      DOC.setFontSize(15);
      DOC.setTextColor('#ffffff');
      DOC.setFont('helvetica');
      DOC.text(dt, 35, (pageHeight / 2 - 30));
    } else {
      DOC.setFontSize(15);
      DOC.setTextColor('#ffffff');
      DOC.setFont('helvetica');
      DOC.text(dt, 35, (pageHeight / 2));
    }

    DOC.addPage();
    DOC.setTextColor('#3257FB');
    DOC.setFontSize(40);
    DOC.setFont('helvetica');
    DOC.text('Key Metrics', 35, 100);

    DOC.setTextColor('#535559');
    DOC.setFontSize(10);
    DOC.setFont('helvetica');
    DOC.text('DATE RANGE', 35, 140);
    DOC.setTextColor('#191818')
    DOC.setFontSize(15);
    DOC.setFont('helvetica');
    DOC.text(stDate + ' - ' + endDate, 35, 160);

    DOC.setFontSize(10);
    DOC.setTextColor('#535559');
    DOC.setFont('helvetica');
    DOC.text('TOTAL RESPONSES', 35, 185);
    DOC.setTextColor('#191818')
    DOC.setFontSize(15);
    DOC.setFont('helvetica');
    DOC.text(response.toString(), 35, 205);

    DOC.setFontSize(10);
    DOC.setTextColor('#535559');
    DOC.setFont('helvetica');
    DOC.text('TOTAL COMPLETES ', 35, 225);
    DOC.setTextColor('#191818')
    DOC.setFontSize(15);
    DOC.setFont('helvetica');
    DOC.text(completes.toString(), 35, 245);

    DOC.setFontSize(10);
    DOC.setTextColor('#535559');
    DOC.setFont('helvetica');
    DOC.text('ZIP CODES', 35, 270);
    DOC.setTextColor('#191818')
    DOC.setFontSize(15);
    DOC.setFont('helvetica');
    DOC.text(zip.length > 0 ? zip.join(', ') : 'N/A', 35, 290);

    DOC.setDrawColor('#0E0E0F');
    DOC.line(pageWidth, (pageHeight - 45), 0, (pageHeight - 45));
    DOC.setLineWidth(0.1);
    if (orgImage) {
      convertImgToBase64(orgImage, function (base64Img) {
        DOC.addImage(base64Img, "PNG", 35, (pageHeight - 35), 70, 30, undefined, 'FAST')
      });
    } else
      DOC.addImage("/Icon_assets/png/footer_logo.png", "PNG", 35, (pageHeight - 30), 100, 15, undefined, 'FAST');


    const divTo = document.getElementsByClassName('data-card card');
    var promiseArray = [];
    // clone the element then make the changes.
    for (let i = 0; i < divTo.length; i++) {
      divTo[i].style.width = '1423px';
      divTo[i].style.boxShadow = 'none';
      if (divTo[i].querySelector('.performance-graph-container') !== null) {
        divTo[i].querySelector('.performance-graph-container').style.paddingBottom = '16px';
        var graph = divTo[i].querySelector('.performance-graph-container')
        var graphEle = graph.getElementsByTagName('g');
        for (const g of graphEle) {
          if (g.classList && g.classList.toString().includes('dataset-axis')) {

            var svgElem = g.getElementsByTagName('text')
            if (svgElem.length > 0) {
              for (var node of svgElem) {
                if (node.getAttribute('font-size') && node.getAttribute('font-size') === '14px') {
                  node.removeAttribute('font-size');
                  node.style.fontSize = '17px';
                  node.removeAttribute("font-size");
                  node.setAttribute("font-family", "helvetica");
                  node.replaceWith(node);

                } else {
                  node.style.fontSize = '20px';
                  node.removeAttribute("font-size");
                  node.setAttribute("font-family", "helvetica");
                  node.replaceWith(node);
                }
              }
            }
          }
        }
      }
      divTo[i].style.fontFamily = 'helvetica';
      divTo[i].style.paddingBottom = '50px';

      divTo[i].querySelector('.survey-sub-title').style.display = 'none';
      divTo[i].querySelector('.export-question-header').style.display = 'none';
      divTo[i].querySelector('.survey-icon').style.display = 'none';
      divTo[i].querySelector('.survey-name').style.paddingTop = '32px';
      divTo[i].querySelector('.survey-name').style.paddingLeft = '40px';
      divTo[i].querySelector('.survey-name').style.paddingBottom = '60px';
      divTo[i].querySelector('.survey-name').style.fontSize = '32px';
      divTo[i].querySelector('.survey-name').style.lineHeight = '2.5rem';
      var title = divTo[i].querySelector('.survey-name').innerHTML;
      if (title.length > 150) {
        divTo[i].querySelector('.survey-name').innerHTML = title.substring(0, 165).replace(/.{0,3}$/, '...')
      }
      // divTo[i].querySelector('.survey-name').style.paddingBottom = '32px';
      if (divTo[i].querySelector('table thead .index-tip') !== null)
        divTo[i].querySelector('table thead .index-tip').style.display = 'none';
      divTo[i].querySelector('.survey-name').style.whiteSpace = 'unset';
      divTo[i].querySelector('.survey-name').style.maxWidth = '95%';
      divTo[i].querySelector('.survey-name').style.wordBreak = 'break-word';

      if (divTo[i].querySelector('.survey-total') !== null) {
        divTo[i].querySelector('.survey-total').style.paddingBottom = '36px';
        divTo[i].querySelector('.survey-total').style.paddingTop = '32px';
        divTo[i].querySelector('.survey-total').style.fontSize = '20px';
      }
      // go to each answer and remove white-space attribute

      var selectedTd = divTo[i].querySelectorAll('table tbody tr td .selected');
      for (var j = 0; j < selectedTd.length; j++) {
        selectedTd[j].style.fontWeight = '700'
      }

      var selectedRow = divTo[i].querySelectorAll('table tbody tr.selected');
      for (var j = 0; j < selectedRow.length; j++) {
        selectedRow[j].style.fontWeight = '700'
      }

      var ans_col = divTo[i].querySelectorAll('table tbody tr td.answers-column');
      for (var j = 0; j < ans_col.length; j++) {
        ans_col[j].style.whiteSpace = 'unset'
      }

      var progress_col = divTo[i].querySelectorAll('table tbody tr td.progress-column');
      for (var j = 0; j < progress_col.length; j++) {
        progress_col[j].childNodes[0].style.width='auto'
      }
      // get font attirbute for svg :
      // var svgElem = divTo[i].getElementsByTagName("text");
      // if (svgElem) {
      //   for (const node of svgElem) {
      //     node.style.fontSize = '24px';
      //     node.setAttribute("font-family", "helvetica");
      //     node.setAttribute("font-size", "null");
      //     node.replaceWith(node);
      //   }
      // }

      var dataLbl = divTo[i].querySelectorAll(".fusioncharts-datalabels");
      if (dataLbl[1]) {
        var t = dataLbl[1].getElementsByTagName("text");
        for (const node of t) {
          node.setAttribute("font-size", "24px");
          node.setAttribute("font-family", "helvetica");
          node.replaceWith(node);
        }
      }



      var header = divTo[i].getElementsByTagName("h1");
      if (header) {
        for (const node of header) {
          node.style.fontFamily = "helvetica";
          node.style.lineHeight = '48px';
        }
      }
      var thead = divTo[i].getElementsByTagName('th');
      if (thead) {
        for (const node of thead) {
          node.style.fontSize = "24px";
          node.style.padding = "0.75rem";
          // node.style.textAlign = "left";
        }
      }


      var row = divTo[i].getElementsByTagName('tr');
      if (row) {
        for (const node of row) {
          node.style.paddingTop = "24px";
          node.style.paddingBottom = "24px";
          node.style.height = "78px";
          node.style.fontSize = '24px';
          node.style.setLineWidth = '48px';
          node.style.lineHeight = '2.5rem';
        }
      }


      divTo[i].style.borderRadius = "56px"; // TODO
      promiseArray.push(
        html2canvas(divTo[i], {
          scrollY: -window.scrollY,
          scale: 1,
          backgroundColor: "#ffffff",
          // height: divTo[i].getBoundingClientRect().height
        }));
    }

    Promise.all(promiseArray).then(canvas => {
      for (var i = 0; i < canvas.length; i++) {

        const divImage = canvas[i].toDataURL('image/jpeg');
        // looping response array to get the images uri
        DOC.addPage();
        const pageWidth = DOC.internal.pageSize.getWidth();
        const pageHeight = DOC.internal.pageSize.getHeight();

        // const widthRatio = pageWidth / canvas[i].width;
        // const heightRatio = pageHeight / canvas[i].height;
        // const ratio = widthRatio > heightRatio ? widthRatio : heightRatio;

        // const canvasWidth = canvas[i].width * ratio;
        // const canvasHeight = canvas[i].height * ratio;


        var maxHeight = (pageHeight - 35);
        var maxWidth = (pageWidth - 80);


        var ratio1 = 0;
        var width = canvas[i].width;
        var height = (canvas[i].height - 60);

        var new_height = height;
        var new_width = width;


        if (width > maxWidth && width > height) {

          ratio1 = width / height;
          new_height = maxWidth / ratio1;
          new_width = maxWidth; // Set new width

        } else if (height > maxHeight && height > width) {

          ratio1 = height / width;
          new_width = maxWidth;
          new_height = (maxWidth / ratio1);
        } else {

          new_width = maxWidth;
          new_height = maxHeight;
        }

        let position = 40;
        const imgWidth = new_width;

        const imgHeight = ((canvas[i].height - 60) * imgWidth) / canvas[i].width;
        const pageHt = maxHeight;

        let heightLeft = imgHeight;
        // let heightLeft = new_height;

        DOC.setFillColor("#fafafa");
        DOC.rect(0, 0, pageWidth, pageHeight, "F");
        DOC.addImage(divImage, 'jpeg', 40, 40, imgWidth, imgHeight, undefined, 'FAST');
        heightLeft -= pageHt + 10;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          DOC.addPage();
          DOC.addImage(divImage, 'jpeg', 40, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHt;
        }
        DOC.setDrawColor('#0E0E0F');
        DOC.line(pageWidth, (pageHeight - 45), 0, (pageHeight - 45));
        DOC.setLineWidth(0.5);
        if (orgDataImage) {
          DOC.addImage(orgDataImage, "PNG", 35, (pageHeight - 35), 70, 30, undefined, 'FAST')
        } else
          DOC.addImage("/Icon_assets/png/footer_logo.png", "PNG", 35, (pageHeight - 30), 100, 15, undefined, 'FAST');
      }
      var fileName = surveyName;
      var date = getDateFormat((new Date()));
      fileName += "_" + date;
      DOC.save(`${fileName}.pdf`);
      setLoading(false);
      closeExportModal();
    });
    // REMOVE formatting DONE FROM DOCUMENT.
    for (let i = 0; i < divTo.length; i++) {
      divTo[i].style.width = null;
      divTo[i].style.boxShadow = null;
      divTo[i].style.fontFamily = 'BasisGrotesque';
      divTo[i].style.paddingBottom = null;
      if (divTo[i].querySelector('.survey-total') !== null) {
        divTo[i].querySelector('.survey-total').style.paddingBottom = null;
        divTo[i].querySelector('.survey-total').style.paddingTop = null;
        divTo[i].querySelector('.survey-total').style.fontSize = null;
      }

      divTo[i].querySelector('.survey-sub-title').style.display = null;
      divTo[i].querySelector('.survey-icon').style.display = null;
      divTo[i].querySelector('.export-question-header').style.display = 'flex';
      divTo[i].querySelector('.survey-name').style.paddingTop = null;
      divTo[i].querySelector('.survey-name').style.paddingLeft = null;
      divTo[i].querySelector('.survey-name').style.paddingBottom = null;
      divTo[i].querySelector('.survey-name').style.fontSize = null;
      divTo[i].querySelector('.survey-name').style.lineHeight = null;
      divTo[i].querySelector('.survey-name').style.fontFamily = null;
      if (divTo[i].querySelector('table thead .index-tip') !== null)
        divTo[i].querySelector('table thead .index-tip').style.display = 'revert';
      divTo[i].querySelector('.survey-name').style.whiteSpace = 'nowrap'
      divTo[i].querySelector('.survey-name').style.maxWidth = '75%';
      divTo[i].querySelector('.survey-name').style.wordBreak = 'unset';
      // go to each answer and remove white-space attribute
      var ans_col = divTo[i].querySelectorAll('table tbody tr td.answers-column');
      for (var j = 0; j < ans_col.length; j++) {
        ans_col[j].style.whiteSpace = 'nowrap'
      }

      var selectedTd = divTo[i].querySelectorAll('table tbody tr td .selected');
      for (var j = 0; j < selectedTd.length; j++) {
        selectedTd[j].style.fontWeight = 'bold'
      }

      var selectedRow = divTo[i].querySelectorAll('table tbody tr.selected');
      for (var j = 0; j < selectedRow.length; j++) {
        selectedRow[j].style.fontWeight = 'bold'
      }

      var progress_col = divTo[i].querySelectorAll('table tbody tr td.progress-column');
      for (var j = 0; j < progress_col.length; j++) {
        progress_col[j].childNodes[0].style.width = '70px';
      }

      var graph = divTo[i].querySelector('.performance-graph-container');
      if (divTo[i].querySelector('.performance-graph-container') !== null) {
        var graphEle = graph.getElementsByTagName('g');
        for (const g of graphEle) {
          if (g.classList && g.classList.toString().includes('dataset-axis')) {

            var svgElem = g.getElementsByTagName('text')
            if (svgElem.length > 0) {
              for (var node of svgElem) {
                if (node.getAttribute('font-size') && node.getAttribute('font-size') === '16px') {
                  node.removeAttribute('font-size');
                  node.style.fontSize = '14px';
                  node.removeAttribute("font-size");
                  node.replaceWith(node);

                } else {
                  node.style.fontSize = '16px';
                  node.removeAttribute("font-size");
                  node.replaceWith(node);
                }
              }
            }
          }
        }
      }

      var dataLbl = divTo[i].querySelectorAll(".fusioncharts-datalabels");
      if (dataLbl[1]) {
        var t = dataLbl[1].getElementsByTagName("text");
        for (const node of t) {
          node.setAttribute("font-size", "18px");
          node.replaceWith(node);
        }
      }
      var svgElem = divTo[i].getElementsByTagName("text");
      if (svgElem) {
        for (const node of svgElem) {
          node.setAttribute("font-family", "BasisGrotesque");
          node.replaceWith(node);
        }
      }

      var header = divTo[i].getElementsByTagName("h1");
      if (header) {
        for (const node of header) {
          node.style.fontFamily = "BasisGrotesque";
        }
      }

      var header = divTo[i].getElementsByTagName("h1");
      if (header) {
        for (const node of header) {
          node.style.lineHeight = null;
        }
      }
      var thead = divTo[i].getElementsByTagName('th');
      if (thead) {
        for (const node of thead) {
          node.style.fontSize = null;
          node.style.padding = null;
        }
      }


      var row = divTo[i].getElementsByTagName('tr');
      if (row) {
        for (const node of row) {
          node.style.paddingTop = null;
          node.style.paddingBottom = null;
          node.style.height = null;
          node.style.fontSize = null;
          node.style.setLineWidth = null;
          node.style.lineHeight = null;
        }
      }


    }

  }
  return (
    <Modal isOpen={open} className="ad-pop-up-modal rtr-export-modal" centered>
      <ModalHeader close={clearcloseBtn}>{"Export Survey Results"}</ModalHeader>
      <ModalBody>
        <Form className="export-form">
          <div>
            <Label className="export-info">File Format
              <img style={{ padding: "6px" }} src={"/Icon_assets/svg/export-info-icon.svg"} alt="Info icon" id="file-info" className="index-tip" />
              <Tooltip
                placement="right"
                className="custom-export-input-tooltip"
                isOpen={tooltipOpen}
                target="file-info"
                toggle={toggle}
              >
                Export your data in a convenience <br /> format. CSV does not include plots.
              </Tooltip>
            </Label>

            <div className="export-toggle-btn">
              <div className="btn-group export-toggle">
                <Button className={"in-btn-secondary first " + (reportType === VALUES.PDF ? 'selected-report' : "")} onClick={() => setReportType(VALUES.PDF)}>PDF</Button>
                <Button className={"in-btn-secondary second " + (reportType === VALUES.CSV ? 'selected-report' : "")} onClick={() => setReportType(VALUES.CSV)}>CSV</Button>
              </div>
            </div>
            {reportType === VALUES.PDF ?
              <div className="export-info" style={{ marginTop: "11px" }}>Any filters on the dashboard will apply to the exported data.</div>
              :
              <>
                <div className="export-info margin-div">Data View</div>
                <div className="export-radio-input">
                  <label>
                    <input id="radio1" type="radio" name="radio1" className={"custom-radio-input"} value={VALUES.SUMMARY_TABLES}
                      checked={subReportType === VALUES.SUMMARY_TABLES}
                      onChange={(e) => selectSubReport(e, VALUES.SUMMARY_TABLES)} />
                    <span>{VALUES.SUMMARY_TABLES}</span>
                  </label>
                  <img style={{ padding: "6px" }} src={"/Icon_assets/svg/export-info-icon.svg"} alt="Info icon" id="summary-info" className="index-tip" />
                  <Tooltip
                    placement="right"
                    className="custom-export-input-tooltip"
                    isOpen={summaryTooltipOpen}
                    target="summary-info"
                    toggle={summaryToggle}
                  >The tables for each question <br />on the dashboard.</Tooltip>
                </div>
                <div className="export-radio-input">
                  <label>
                    <input id="radio2" type="radio" name="radio2" className={"custom-radio-input"} value={VALUES.RAW_EVENTS}
                      checked={subReportType === VALUES.RAW_EVENTS}
                      onChange={(e) => selectSubReport(e, VALUES.RAW_EVENTS)} />
                    <span>{VALUES.RAW_EVENTS}</span>
                  </label>
                  <img style={{ padding: "6px" }} src={"/Icon_assets/svg/export-info-icon.svg"} alt="Info icon" id="events-info" className="index-tip" />
                  <Tooltip
                    placement="right"
                    className="custom-export-input-tooltip"
                    isOpen={eventsTooltipOpen}
                    target="events-info"
                    toggle={eventsToggle}
                  >All individual responses in your survey.</Tooltip>
                </div>
              </>}

          </div>
          <FormGroup className="text-left" style={{ marginTop: "32px" }}>
            <Button
              className="export-button in-btn-sm mr-4"
              disabled={false}
              onClick={exportDataToCsv}
              style={{ minWidth: '96px', minWidth: '48px' }}
            >
              {(!loading) ? "Export" : <ADspinner size="large" />}
            </Button>
            <Button
              className="in-btn-link btn-modal-cancle btn btn-link"
              disabled={false}
              onClick={closeExportModal}
            >
              Cancel
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal >)
}

export default ExportModal;