import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { offerActionCreator } from "../../../actions";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import OFFER_STATES from '../../../utils/offerStates';
import './index.scss'

const StatusDropdown = ({ data, refreshTable, disabled }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const dispatch = useDispatch();

    const prepareAllowedTransitions = (currentState) => {
        const allowedTransitionsStates = OFFER_STATES[currentState].transaction_state;
        let transitions = []
        allowedTransitionsStates.map((transaction_state) => {
            let item = <DropdownItem onClick={() => { updateAdState(transaction_state) }} key={transaction_state} className={(OFFER_STATES[transaction_state].state_label).toLocaleLowerCase()}>{OFFER_STATES[transaction_state].state_label}</DropdownItem>;
            transitions.push(item);
        });
        return transitions;
    }

    const updateAdState = (transaction_state) => {
        let param = {};
        param.state = transaction_state;
        param.data_id = data.id;
        dispatch(offerActionCreator.createBrandOfferRequest(param));
        refreshTable();
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}
            className={(OFFER_STATES[data.state].state_label).toLocaleLowerCase()}
            disabled={disabled}
        >
            <DropdownToggle className={"d-flex"}>
                <span className="state-dot"></span>
                {OFFER_STATES[data.state].state_label}
            </DropdownToggle>
            <DropdownMenu>
                {prepareAllowedTransitions(data.state)}
            </DropdownMenu>
        </Dropdown >
    )
}

export default StatusDropdown