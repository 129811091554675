const APP_ACTIONS = {
  CONFIG_LOADED: "CONFIG_LOADED",
};

const FILTERS_ACTION = {
  STATES_REQUEST: "STATES_REQUEST",
  STATES_SUCCESS: "STATES_SUCCESS",
  STATES_FAILURE: "STATES_FAILURE",
  STATES_FAILURE_CLEAR: "STATES_FAILURE_CLEAR",

  CITY_REQUEST: "CITY_REQUEST",
  CITY_SUCCESS: "CITY_SUCCESS",
  CITY_FAILURE: "CITY_FAILURE",
  CITY_FAILURE_CLEAR: "CITY_FAILURE_CLEAR",
}

const AUTH_ACTIONS = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",
  SIGNIN_FAILURE_CLEAR: "SIGNIN_FAILURE_CLEAR",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  SIGNUP_FAILURE_CLEAR: "SIGNUP_FAILURE_CLEAR",

  LOG_OUT_REQUEST: "LOG_OUT_REQUEST",
  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  FORGOTPASSWORD_REQUEST: "FORGOTPASSWORD_REQUEST",
  FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
  FORGOTPASSWORD_FAILURE: "FORGOTPASSWORD_FAILURE",
  FORGOTPASSWORD_FAILURE_CLEAR: "FORGOTPASSWORD_FAILURE_CLEAR",
  FORGOTPASSWORD_RESET: "FORGOTPASSWORD_RESET",
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_FAILURE_CLEAR: 'RESET_PASSWORD_FAILURE_CLEAR',
  UPDATE_USER_INFO: "UPDATE_USER_INFO",

  SET_RBAC: "SET_RBAC",
  RESET_RBAC: "RESET_RBAC",
};

const USER_ACTIONS = {
  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "GET_USER_LIST_FAILURE",
  GET_USER_LIST_FAILURE_CLEAR: "GET_USER_LIST_FAILURE_CLEAR",

  UPLOAD_PROFILE_PIC_REQUEST: "UPLOAD_PROFILE_PIC_REQUEST",
  UPLOAD_PROFILE_PIC_SUCCESS: "UPLOAD_PROFILE_PIC_SUCCESS",
  UPLOAD_PROFILE_PIC_FAILURE: "UPLOAD_PROFILE_PIC_FAILURE",
  UPLOAD_PROFILE_PIC_FAILURE_CLEAR: "UPLOAD_PROFILE_PIC_FAILURE_CLEAR",
  UPLOAD_PROFILE_PIC_CLEAR: "UPLOAD_PROFILE_PIC_CLEAR",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  CREATE_USER_FAILURE_CLEAR: "CREATE_USER_FAILURE_CLEAR",
  CREATE_USER_CLEAR_MESSAGE: "CREATE_USER_CLEAR_MESSAGE",
  DEACTIVATEUSER_REQUEST: "DEACTIVATEUSER_REQUEST",
  DEACTIVATEUSER_SUCCESS: "DEACTIVATEUSER_SUCCESS",
  DEACTIVATEUSER_FAILURE: "DEACTIVATEUSER_FAILURE",
  ACTIVATE_USER_SUCCESS: "ACTIVATE_USER_SUCCESS",
  ACTIVATE_USER_REQUEST: "ACTIVATE_USER_REQUEST",
  ACTIVATE_USER_FAILURE: "ACTIVATE_USER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  UPDATE_USER_FAILURE_CLEAR: "UPDATE_USER_FAILURE_CLEAR",
  ACTIVATE_DEACTIVATE_CLEAR: "ACTIVATE_DEACTIVATE_CLEAR",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR',

  GET_USER_ROLES_LIST: "GET_USER_ROLES_LIST",
  GET_USER_ROLES_LIST_SUCCESS: "GET_USER_ROLES_LIST_SUCCESS",
  GET_USER_ROLES_LIST_FAILURE: "GET_USER_ROLES_LIST_FAILURE",
  GET_USER_ROLES_LIST_FAILURE_CLEAR: "GET_USER_ROLES_LIST_FAILURE_CLEAR",
};

const NOTIFICATION_ACTIONS = {
  SET_NOTIFICATION: "SET_NOTIFICATION",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
};
const DASHBOARD_ACTIONS = {
  DSP_CAMPAIGNS_LIST_REQUEST: "DSP_CAMPAIGNS_LIST_REQUEST",
  DSP_CAMPAIGNS_LIST_SUCCESS: "DSP_CAMPAIGNS_LIST_SUCCESS",
  DSP_CAMPAIGNS_LIST_FAILURE: "DSP_CAMPAIGNS_LIST_FAILURE",
  DSP_CAMPAIGNS_LIST_FAILURE_CLEAR: "DSP_CAMPAIGNS_LIST_FAILURE_CLEAR",
  DSP_CAMPAIGN_LINE_LIST_REQUEST: "DSP_CAMPAIGN_LINE_LIST_REQUEST",
  DSP_CAMPAIGN_LINE_LIST_SUCCESS: "DSP_CAMPAIGN_LINE_LIST_SUCCESS",
  DSP_CAMPAIGN_LINE_LIST_FAILURE: "DSP_CAMPAIGN_LINE_LIST_FAILURE",
  DSP_CAMPAIGN_LINE_LIST_FAILURE_CLEAR: "DSP_CAMPAIGN_LINE_LIST_FAILURE_CLEAR",
  DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
  DASHBOARD_FAILURE: "DASHBOARD_FAILURE",
  DASHBOARD_FAILURE_CLEAR: "DASHBOARD_FAILURE_CLEAR",
  GET_SPEND_CTR_CPA_REQUEST: "GET_SPEND_CTR_CPA_REQUEST",
  GET_SPEND_CTR_CPA_SUCCESS: "GET_SPEND_CTR_CPA_SUCCESS",
  GET_SPEND_CTR_CPA_FAILURE: "GET_SPEND_CTR_CPA_FAILURE",
  GET_SPEND_CTR_CPA_FAILURE_CLEAR: "GET_SPEND_CTR_CPA_FAILURE_CLEAR",
  GET_SPEND_CLICKS_CONVERSIONS_REQUEST: "GET_SPEND_CLICKS_CONVERSIONS_REQUEST",
  GET_SPEND_CLICKS_CONVERSIONS_SUCCESS: "GET_SPEND_CLICKS_CONVERSIONS_SUCCESS",
  GET_SPEND_CLICKS_CONVERSIONS_FAILURE: "GET_SPEND_CLICKS_CONVERSIONS_FAILURE",
  GET_SPEND_CLICKS_CONVERSIONS_FAILURE_CLEAR:
    "GET_SPEND_CLICKS_CONVERSIONS_FAILURE_CLEAR",
  DASHBOARD_CAMPAIGN_METRIC_DATA_REQUEST:
    "DASHBOARD_CAMPAIGN_METRIC_DATA_REQUEST",
  DASHBOARD_CAMPAIGN_METRIC_DATA_SUCCESS:
    "DASHBOARD_CAMPAIGN_METRIC_DATA_SUCCESS",
  DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE:
    "DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE",
  DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE_CLEAR:
    "DASHBOARD_CAMPAIGN_METRIC_DATA_FAILURE_CLEAR",
};

const REALTIME_RESEARCH_ACTIONS = {
  GET_REALTIME_RESEARCH_LIST_REQUEST: "GET_REALTIME_RESEARCH_LIST_REQUEST",
  GET_REALTIME_RESEARCH_LIST_SUCCESS: "GET_REALTIME_RESEARCH_LIST_SUCCESS",
  GET_REALTIME_RESEARCH_LIST_FAILURE: "GET_REALTIME_RESEARCH_LIST_FAILURE",
  GET_REALTIME_RESEARCH_FAILURE_CLEAR: "GET_REALTIME_RESEARCH_FAILURE_CLEAR",

  RTR_LIST_REQUEST: "RTR_LIST_REQUEST",
  RTR_LIST_SUCCESS: "RTR_LIST_SUCCESS",
  RTR_LIST_FAILURE: "RTR_LIST_FAILURE",
  RTR_LIST_FAILURE_CLEAR: "RTR_LIST_FAILURE_CLEAR",

  GET_RTR_BY_AD_ID_REQUEST: "GET_RTR_BY_AD_ID_REQUEST",
  GET_RTR_BY_AD_ID_SUCCESS: "GET_RTR_BY_AD_ID_SUCCESS",
  GET_RTR_BY_AD_ID_FAILURE: "GET_RTR_BY_AD_ID_FAILURE",
  GET_RTR_BY_AD_ID_CLEAR: "GET_RTR_BY_AD_ID_CLEAR",

  GET_REALTIME_RESEARCH_DETAILS_REQUEST: "GET_REALTIME_RESEARCH_DETAILS_REQUEST",
  GET_REALTIME_RESEARCH_DETAILS_SUCCESS: "GET_REALTIME_RESEARCH_DETAILS_SUCCESS",
  GET_REALTIME_RESEARCH_DETAILS_FAILURE: "GET_REALTIME_RESEARCH_DETAILS_FAILURE",
  GET_REALTIME_RESEARCH_DETAILS_CLEAR: "GET_REALTIME_RESEARCH_DETAILS_CLEAR",
  GET_REALTIME_RESEARCH_DETAILS_STAT_REQUEST: "GET_REALTIME_RESEARCH_DETAILS_STAT_REQUEST",
  GET_REALTIME_RESEARCH_DETAILS_STAT_SUCCESS: "GET_REALTIME_RESEARCH_DETAILS_STAT_SUCCESS",
  GET_REALTIME_RESEARCH_DETAILS_STAT_FAILURE: "GET_REALTIME_RESEARCH_DETAILS_STAT_FAILURE",
  GET_REALTIME_RESEARCH_DETAILS_STAT_CLEAR: "GET_REALTIME_RESEARCH_DETAILS_STAT_CLEAR",

  GET_EXPORT_RAW_EVENTS_REQUEST: "GET_EXPORT_RAW_EVENTS_REQUEST",
  GET_EXPORT_RAW_EVENTS_SUCCESS: "GET_EXPORT_RAW_EVENTS_SUCCESS",
  GET_EXPORT_RAW_EVENTS_FAILURE: "GET_EXPORT_RAW_EVENTS_FAILURE",
  GET_EXPORT_RAW_EVENTS_CLEAR: "GET_EXPORT_RAW_EVENTS_CLEAR",

  UPDATE_AD_STATE_REQUEST: "UPDATE_AD_STATE_REQUEST",
  UPDATE_AD_STATE_SUCCESS: "UPDATE_AD_STATE_SUCCESS",
  UPDATE_AD_STATE_FAILURE: "UPDATE_AD_STATE_FAILURE",
  UPDATE_AD_STATE_CLEAR: "UPDATE_AD_STATE_CLEAR",

  GET_UNIVERSAL_TAG_REQUEST: "GET_UNIVERSAL_TAG_REQUEST",
  GET_UNIVERSAL_TAG_SUCCESS: "GET_UNIVERSAL_TAG_SUCCESS",
  GET_UNIVERSAL_TAG_FAILURE: "GET_UNIVERSAL_TAG_FAILURE",
  GET_UNIVERSAL_TAG_CLEAR: "GET_UNIVERSAL_TAG_CLEAR",

  CLONE_RTR_AD_REQUEST: "CLONE_RTR_AD_REQUEST",
  CLONE_RTR_AD_SUCCESS: "CLONE_RTR_AD_SUCCESS",
  CLONE_RTR_AD_FAILURE: "CLONE_RTR_AD_FAILURE",
  CLONE_RTR_AD_CLEAR: "CLONE_RTR_AD_CLEAR",

  DELETE_RTR_AD_REQUEST: "DELETE_RTR_AD_REQUEST",
  DELETE_RTR_AD_SUCCESS: "DELETE_RTR_AD_SUCCESS",
  DELETE_RTR_AD_FAILURE: "DELETE_RTR_AD_FAILURE",
  DELETE_RTR_AD_CLEAR: "DELETE_RTR_AD_CLEAR",

  GET_BOTH_CAMPAIGNS_REQUEST: "GET_BOTH_CAMPAIGNS_REQUEST",
  GET_BOTH_CAMPAIGNS_SUCCESS: "GET_BOTH_CAMPAIGNS_SUCCESS",
  GET_BOTH_CAMPAIGNS_FAILURE: "GET_BOTH_CAMPAIGNS_FAILURE",
  GET_BOTH_CAMPAIGNS_CLEAR: "GET_BOTH_CAMPAIGNS_CLEAR",

  CLEAR_RESPONSE_DATA: "CLEAR_RESPONSE_DATA",

  SET_RTR_SURVEY_DATA_REQUEST: "SET_RTR_SURVEY_DATA_REQUEST",
  SET_RTR_SURVEY_DATA_SUCCESS: "SET_RTR_SURVEY_DATA_SUCCESS",
  SET_RTR_SURVEY_DATA_FAILURE: "SET_RTR_SURVEY_DATA_FAILURE",
  SET_RTR_SURVEY_DATA_CLEAR: "SET_RTR_SURVEY_DATA_CLEAR",

};

const ADVERTISER_ACTIONS = {
  ADVERTISERS_LIST_REQUEST: "ADVERTISERS_LIST_REQUEST",
  ADVERTISERS_LIST_SUCCESS: "ADVERTISERS_LIST_SUCCESS",
  ADVERTISERS_LIST_FAILURE: "ADVERTISERS_LIST_FAILURE",
  ADVERTISERS_LIST_FAILURE_CLEAR: "ADVERTISERS_LIST_FAILURE_CLEAR",
};

const ORGANIZATION_ACTIONS = {
  ADD_ORGANIZATION_REQUEST: "ADD_ORGANIZATION_REQUEST",
  ADD_ORGANIZATION_SUCCESS: "ADD_ORGANIZATION_SUCCESS",
  ADD_ORGANIZATION_FAILURE: "ADD_ORGANIZATION_FAILURE",
  ADD_ORGANIZATION_FAILURE_CLEAR: "ADD_ORGANIZATION_FAILURE_CLEAR",

  GET_ORGANIZATION_LIST_REQUEST: "GET_ORGANIZATION_LIST_REQUEST",
  GET_ORGANIZATION_LIST_SUCCESS: "GET_ORGANIZATION_LIST_SUCCESS",
  GET_ORGANIZATION_LIST_FAILURE: "GET_ORGANIZATION_LIST_FAILURE",
  GET_ORGANIZATION_LIST_FAILURE_CLEAR: "GET_ORGANIZATION_LIST_FAILURE_CLEAR",

  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",
  GET_ORGANIZATION_FAILURE_CLEAR: "GET_ORGANIZATION_FAILURE_CLEAR",
  GET_ORGANIZATION_BYID_SUCCESS: "GET_ORGANIZATION_BYID_SUCCESS",
  GET_ORGANIZATION_BYID_FAILURE: "GET_ORGANIZATION_BYID_FAILURE",
  GET_ORGANIZATION_BYID_FAILURE_CLEAR: "GET_ORGANIZATION_BYID_FAILURE_CLEAR",
  GET_ORGANIZATION_BYID_REQUEST: "GET_ORGANIZATION_BYID_REQUEST",

  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",
  UPDATE_ORGANIZATION_FAILURE_CLEAR: "UPDATE_ORGANIZATION_FAILURE_CLEAR",

  UPLOAD_ORGANIZATION_PIC_REQUEST: "UPLOAD_ORGANIZATION_PIC_REQUEST",
  UPLOAD_ORGANIZATION_PIC_SUCCESS: "UPLOAD_ORGANIZATION_PIC_SUCCESS",
  UPLOAD_ORGANIZATION_PIC_FAILURE: "UPLOAD_ORGANIZATION_PIC_FAILURE",
  UPLOAD_ORGANIZATION_PIC_FAILURE_CLEAR: "UPLOAD_ORGANIZATION_PIC_FAILURE_CLEAR",
  UPLOAD_ORGANIZATION_PIC_CLEAR: "UPLOAD_ORGANIZATION_PIC_CLEAR",

  SET_ORGANIZATION: "SET_ORGANIZATION",
  REMOVE_ORGANIZATION: "REMOVE_ORGANIZATION",
  SET_USER_ROLE: "SET_USER_ROLE",
  REMOVE_USER_ROLE: "REMOVE_USER_ROLE",

  GET_ORGANIZATION_FOR_ROLE_LIST_REQUEST: "GET_ORGANIZATION_FOR_ROLE_LIST_REQUEST",
  GET_ORGANIZATION_FOR_ROLE_LIST_SUCCESS: "GET_ORGANIZATION_FOR_ROLE_LIST_SUCCESS",
  GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE: "GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE",
  GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE_CLEAR: "GET_ORGANIZATION_FOR_ROLE_LIST_FAILURE_CLEAR",
};

const REPORT_BUILDER = {
  RUN_REPORT_REQUEST: 'RUN_REPORT_REQUEST',
  RUN_REPORT_SUCCESS: 'RUN_REPORT_SUCCESS',
  RUN_REPORT_FAILURE: 'RUN_REPORT_FAILURE',
  RUN_REPORT_FAILURE_CLEAR: 'RUN_REPORT_FAILURE_CLEAR',
  RUN_REPORT_CLEAR_ALL: 'RUN_REPORT_CLEAR_ALL',

  DIMENSIONS_LIST_REQUEST: "DIMENSIONS_LIST_REQUEST",
  DIMENSIONS_LIST_SUCCESS: "DIMENSIONS_LIST_SUCCESS",
  DIMENSIONS_LIST_FAILURE: "DIMENSIONS_LIST_FAILURE",
  DIMENSIONS_LIST_FAILURE_CLEAR: "DIMENSIONS_LIST_FAILURE_CLEAR",

  METRICS_LIST_REQUEST: "METRICS_LIST_REQUEST",
  METRICS_LIST_SUCCESS: "METRICS_LIST_SUCCESS",
  METRICS_LIST_FAILURE: "METRICS_LIST_FAILURE",
  METRICS_LIST_FAILURE_CLEAR: "METRICS_LIST_FAILURE_CLEAR",

  GET_REPORT_TEMPLATES_REQUEST: "GET_REPORT_TEMPLATES_REQUEST",
  GET_REPORT_TEMPLATES_SUCCESS: "GET_REPORT_TEMPLATES_SUCCESS",
  GET_REPORT_TEMPLATES_FAILURE: "GET_REPORT_TEMPLATES_FAILURE",
  GET_REPORT_TEMPLATES_FAILURE_CLEAR: "GET_REPORT_TEMPLATES_FAILURE_CLEAR",

  GET_REPORT_TEMPLATE_REQUEST: "GET_REPORT_TEMPLATE_REQUEST",
  GET_REPORT_TEMPLATE_SUCCESS: "GET_REPORT_TEMPLATE_SUCCESS",
  GET_REPORT_TEMPLATE_FAILURE: "GET_REPORT_TEMPLATE_FAILURE",
  GET_REPORT_TEMPLATE_FAILURE_CLEAR: "GET_REPORT_TEMPLATE_FAILURE_CLEAR",

  SAVE_REPORT_TEMPLATE_REQUEST: "SAVE_REPORT_TEMPLATE_REQUEST",
  SAVE_REPORT_TEMPLATE_SUCCESS: "SAVE_REPORT_TEMPLATE_SUCCESS",
  SAVE_REPORT_TEMPLATE_FAILURE: "SAVE_REPORT_TEMPLATE_FAILURE",
  SAVE_REPORT_TEMPLATE_FAILURE_CLEAR: "SAVE_REPORT_TEMPLATE_FAILURE_CLEAR",

  UPDATE_REPORT_TEMPLATE_REQUEST: "UPDATE_REPORT_TEMPLATE_REQUEST",
  UPDATE_REPORT_TEMPLATE_SUCCESS: "UPDATE_REPORT_TEMPLATE_SUCCESS",
  UPDATE_REPORT_TEMPLATE_FAILURE: "UPDATE_REPORT_TEMPLATE_FAILURE",
  UPDATE_REPORT_TEMPLATE_FAILURE_CLEAR: "UPDATE_REPORT_TEMPLATE_FAILURE_CLEAR",

  DELETE_REPORT_TEMPLATE_REQUEST: "DELETE_REPORT_TEMPLATE_REQUEST",
  DELETE_REPORT_TEMPLATE_SUCCESS: "DELETE_REPORT_TEMPLATE_SUCCESS",
  DELETE_REPORT_TEMPLATE_FAILURE: "DELETE_REPORT_TEMPLATE_FAILURE",
  DELETE_REPORT_TEMPLATE_FAILURE_CLEAR: "DELETE_REPORT_TEMPLATE_FAILURE_CLEAR",

  SET_REPORT_TEMPLATE: "SET_REPORT_TEMPLATE",
  RESET_REPORT_TEMPLATE: "RESET_REPORT_TEMPLATE",
};

const SHEDULE_REPORT_ACTIONS = {
  SAVE_SHEDULE_REPORT_REQUEST: "SAVE_SHEDULE_REPORT_REQUEST",
  SAVE_SHEDULE_REPORT_REQUEST_SUCCESS: "SAVE_SHEDULE_REPORT_REQUEST_SUCCESS",
  SAVE_SHEDULE_REPORT_REQUEST_FAILURE: "SAVE_SHEDULE_REPORT_REQUEST_FAILURE",
  SAVE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR: "SAVE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR",

  GET_SHEDULE_REPORT_REQUEST: "GET_SHEDULE_REPORT_REQUEST",
  GET_SHEDULE_REPORT_REQUEST_SUCCESS: "GET_SHEDULE_REPORT_REQUEST_SUCCESS",
  GET_SHEDULE_REPORT_REQUEST_FAILURE: "GET_SHEDULE_REPORT_REQUEST_FAILURE",
  GET_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR: "GET_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR",

  GET_SHEDULE_REPORT_LIST_REQUEST: "GET_SHEDULE_REPORT_LIST_REQUEST",
  GET_SHEDULE_REPORT_LIST_REQUEST_SUCCESS: "GET_SHEDULE_REPORT_LIST_REQUEST_SUCCESS",
  GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE: "GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE",
  GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE_CLEAR: "GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE_CLEAR",

  DELETE_SHEDULE_REPORT_REQUEST: "DELETE_SHEDULE_REPORT_REQUEST",
  DELETE_SHEDULE_REPORT_REQUEST_SUCCESS: "DELETE_SHEDULE_REPORT_REQUEST_SUCCESS",
  DELETE_SHEDULE_REPORT_FAILURE: "DELETE_SHEDULE_REPORT_FAILURE",
  DELETE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR: "DELETE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR",
}

const CAMPAIGNS_ACTIONS = {
  GET_CAMPAIGNS_LIST_REQUEST: "GET_CAMPAIGNS_LIST_REQUEST",
  GET_CAMPAIGNS_LIST_REQUEST_SUCCESS: "GET_CAMPAIGNS_LIST_REQUEST_SUCCESS",
  GET_CAMPAIGNS_LIST_REQUEST_FAILURE: "GET_CAMPAIGNS_LIST_REQUEST_FAILURE",
  GET_CAMPAIGNS_LIST_REQUEST_FAILURE_CLEAR: "GET_CAMPAIGNS_LIST_REQUEST_FAILURE_CLEAR",
};

const LINE_ACTIONS = {
  GET_LINE_LIST_REQUEST: "GET_LINE_LIST_REQUEST",
  GET_LINE_LIST_REQUEST_SUCCESS: "GET_LINE_LIST_REQUEST_SUCCESS",
  GET_LINE_LIST_REQUEST_FAILURE: "GET_LINE_LIST_REQUEST_FAILURE",
  GET_LINE_LIST_REQUEST_FAILURE_CLEAR: "GET_LINE_LIST_REQUEST_FAILURE_CLEAR",
};

const AD_ACTIONS = {
  GET_AD_LIST_REQUEST: "GET_AD_LIST_REQUEST",
  GET_AD_LIST_REQUEST_SUCCESS: "GET_AD_LIST_REQUEST_SUCCESS",
  GET_AD_LIST_REQUEST_FAILURE: "GET_AD_LIST_REQUEST_FAILURE",
  GET_AD_LIST_REQUEST_FAILURE_CLEAR: "GET_AD_LIST_REQUEST_FAILURE_CLEAR",
};

const SURVEY_ACTIONS = {
  GET_PREVIEW_REQUEST_FAILURE: "GET_PREVIEW_REQUEST_FAILURE",
  GET_PREVIEW_REQUEST_SUCCESS: "GET_PREVIEW_REQUEST_SUCCESS",
  GET_PREVIEW_REQUEST: "GET_PREVIEW_REQUEST",
  GET_EXTERNAL_PREVIEW_REQUEST_SUCCESS: "GET_EXTERNAL_PREVIEW_REQUEST_SUCCESS",
  GET_EXTERNAL_PREVIEW_REQUEST: "GET_EXTERNAL_PREVIEW_REQUEST",
  GET_EXTERNAL_PREVIEW_REQUEST_FAILURE: "GET_EXTERNAL_PREVIEW_REQUEST_FAILURE",
  GET_UPLOAD_IMAGE_REQUEST: "GET_UPLOAD_IMAGE_REQUEST",
  GET_UPLOAD_IMAGE_REQUEST_SUCCESS: "GET_UPLOAD_IMAGE_REQUEST_SUCCESS",
  GET_UPLOAD_IMAGE_FAILURE: "GET_UPLOAD_IMAGE_FAILURE",
  GET_UPLOAD_IMAGE_REQUEST_CLEAR: "GET_UPLOAD_IMAGE_REQUEST_CLEAR",
  REQUEST_MIME_TYPE_LIST: 'REQUEST_MIME_TYPE_LIST',
  RECEIVE_MIME_TYPE_LIST: 'RECEIVE_MIME_TYPE_LIST',
  SAVE_FILE_REQUEST: "SAVE_FILE_REQUEST",
  SAVE_FILE_REQUEST_CLEAR: "SAVE_FILE_REQUEST_CLEAR",
  SAVE_FILE_SUCCESS: "SAVE_FILE_SUCCESS",
  SAVE_FILE_FAILURE: "SAVE_FILE_FAILURE",
  GET_UPLOAD_CSV_FAILURE: "GET_UPLOAD_CSV_FAILURE",
  GET_UPLOAD_CSV_REQUEST: "GET_UPLOAD_CSV_REQUEST",
  GET_UPLOAD_CSV_REQUEST_SUCCESS: "GET_UPLOAD_CSV_REQUEST_SUCCESS",
  GET_UPLOAD_CSV_REQUEST_CLEAR: "GET_UPLOAD_CSV_REQUEST_CLEAR",
  POST_AD_REQUEST_FAILURE: "POST_AD_REQUEST_FAILURE",
  POST_AD_REQUEST: "POST_AD_REQUEST",
  POST_AD_REQUEST_SUCCESS: "POST_AD_REQUEST_SUCCESS",
  POST_AD_REQUEST_CLEAR: "POST_AD_REQUEST_CLEAR",
  ACTIVATE_AD_REQUEST_FAILURE: "ACTIVATE_AD_REQUEST_FAILURE",
  ACTIVATE_AD_REQUEST: "ACTIVATE_AD_REQUEST",
  ACTIVATE_AD_REQUEST_SUCCESS: "ACTIVATE_AD_REQUEST_SUCCESS",
  ACTIVATE_AD_REQUEST_CLEAR: "ACTIVATE_AD_REQUEST_CLEAR",
  UPDATE_DRAFTED_AD_REQUEST: "UPDATE_DRAFTED_AD_REQUEST",
  UPDATE_DRAFTED_AD_FAILURE: "UPDATE_DRAFTED_AD_FAILURE",
  UPDATE_DRAFTED_AD_SUCCESS: "UPDATE_DRAFTED_AD_SUCCESS",
  UPDATE_DRAFTED_AD_REQUEST_CLEAR: "UPDATE_DRAFTED_AD_REQUEST_CLEAR",
  GET_UPLOAD_TOAST_REQUEST: "GET_UPLOAD_TOAST_REQUEST",
  GET_UPLOAD_TOAST_FAILURE: "GET_UPLOAD_TOAST_FAILURE",
  GET_UPLOAD_TOAST_REQUEST_SUCCESS: "GET_UPLOAD_TOAST_REQUEST_SUCCESS",
  GET_UPLOAD_TOAST_REQUEST_CLEAR: "GET_UPLOAD_TOAST_REQUEST_CLEAR",
  GET_CAMPAIGN_BY_ID_CLEAR: "GET_CAMPAIGN_BY_ID_CLEAR",
  GET_CAMPAIGN_BY_ID_REQUEST: "GET_CAMPAIGN_BY_ID_REQUEST",
  GET_CAMPAIGN_BY_ID_SUCCESS: "GET_CAMPAIGN_BY_ID_SUCCESS",
  GET_CAMPAIGN_BY_ID_FAILURE: "GET_CAMPAIGN_BY_ID_FAILURE",
  GET_AD_ASSET_REQUEST: "GET_AD_ASSET_REQUEST",
  GET_AD_ASSET_SUCCESS: "GET_AD_ASSET_SUCCESS",
  GET_AD_ASSET_FAILURE: "GET_AD_ASSET_FAILURE",
  GET_AD_ASSET_REQUEST_CLEAR: "GET_AD_ASSET_REQUEST_CLEAR",
  GET_INVISIBLY_AD_ASSET_REQUEST: "GET_INVISIBLY_AD_ASSET_REQUEST",
  GET_INVISIBLY_AD_ASSET_SUCCESS: "GET_INVISIBLY_AD_ASSET_SUCCESS",
  GET_INVISIBLY_AD_ASSET_FAILURE: "GET_INVISIBLY_AD_ASSET_FAILURE",
  GET_INVISIBLY_AD_ASSET_REQUEST_CLEAR: "GET_INVISIBLY_AD_ASSET_REQUEST_CLEAR",
  GET_TAXONOMY_QUESTIONS_LIST_REQUEST: "GET_TAXONOMY_QUESTIONS_LIST_REQUEST",
  GET_TAXONOMY_QUESTION_LIST_SUCCESS: "GET_TAXONOMY_QUESTION_LIST_SUCCESS",
  GET_TAXONOMY_QUESTION_SEARCH_LIST_SUCCESS: "GET_TAXONOMY_QUESTION_SEARCH_LIST_SUCCESS",
  GET_TAXONOMY_ANSWER_LIST_REQUEST: "GET_TAXONOMY_ANSWER_LIST_REQUEST",
  GET_TAXONOMY_ANSWER_LIST_SUCCESS: "GET_TAXONOMY_ANSWER_LIST_SUCCESS",
  GET_TAXONOMY_LIST_FAILURE: "GET_TAXONOMY_LIST_FAILURE",
  GET_TAXONOMY_LIST_REQUEST_CLEAR: "GET_TAXONOMY_LIST_REQUEST_CLEAR",
}

const BRANDS_ACTIONS = {
  BRANDS_LIST_REQUEST: "BRANDS_LIST_REQUEST",
  BRANDS_LIST_SUCCESS: "BRANDS_LIST_SUCCESS",
  BRANDS_LIST_FAILURE: "BRANDS_LIST_FAILURE",
  BRANDS_LIST_FAILURE_CLEAR: "BRANDS_LIST_FAILURE_CLEAR",

  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_FILE_FAILURE_CLEAR: "UPLOAD_FILE_FAILURE_CLEAR",

  SAVE_BRAND_REQUEST: "SAVE_BRAND_REQUEST",
  SAVE_BRAND_REQUEST_SUCCESS: "SAVE_BRAND_REQUEST_SUCCESS",
  SAVE_BRAND_REQUEST_FAILURE: "SAVE_BRAND_REQUEST_FAILURE",
  SAVE_BRAND_REQUEST_FAILURE_CLEAR: "SAVE_BRAND_REQUEST_FAILURE_CLEAR",

  GET_BRAND_BY_ID_REQUEST: "GET_BRAND_BY_ID_REQUEST",
  GET_BRAND_BY_ID_SUCCESS: "GET_BRAND_BY_ID_SUCCESS",
  GET_BRAND_BY_ID_FAILURE: "GET_BRAND_BY_ID_FAILURE",
  GET_BRAND_BY_ID_FAILURE_CLEAR: "GET_BRAND_BY_ID_FAILURE_CLEAR",

  RESET_BRAND_LOGO: "RESET_BRAND_LOGO",
};

const CONVERSION_ACTIONS = {
  RULES_LIST_REQUEST: "RULES_LIST_REQUEST",
  RULES_LIST_SUCCESS: "RULES_LIST_SUCCESS",
  RULES_LIST_FAILURE: "RULES_LIST_FAILURE",
  CREATE_RULE_REQUEST: "CREATE_RULE_REQUEST",
  CREATE_RULE_SUCCESS: "CREATE_RULE_SUCCESS",
  CREATE_RULE_FAILURE: "CREATE_RULE_FAILURE",
  CLEAR_CREATE_RULE_RESPONSE: "CLEAR_CREATE_RULE_RESPONSE",
  CLEAR_RULE_LIST: "CLEAR_RULE_LIST",
  GET_PIXEL_CODE_REQUEST_CLEAR: "GET_PIXEL_CODE_REQUEST_CLEAR",
  GET_PIXEL_CODE_RESPONSE: "GET_PIXEL_CODE_RESPONSE",
  GET_PIXEL_CODE_REQUEST: "GET_PIXEL_CODE_REQUEST",
  GET_PIXEL_CODE_FAILURE: "GET_PIXEL_CODE_FAILURE"

};

const OFFER_ACTIONS = {
  GET_OFFER_LIST_REQUEST: "GET_OFFER_LIST_REQUEST",
  GET_OFFER_LIST_SUCESS: "GET_OFFER_LIST_SUCESS",
  GET_OFFER_LIST_FAILURE: "GET_OFFER_LIST_FAILURE",
  GET_OFFER_LIST_FAILURE_CLEAR: "GET_OFFER_LIST_FAILURE_CLEAR",
  POST_OFFER_CREATION_REQUEST: "POST_OFFER_CREATION_REQUEST",
  POST_OFFER_SUCESS: "POST_OFFER_SUCESS",
  POST_OFFER_FAILURE: "POST_OFFER_FAILURE",
  POST_OFFER_FAILURE_CLEAR: "POST_OFFER_FAILURE_CLEAR",
  DELETE_OFFER_REQUEST: "DELETE_OFFER_REQUEST",
  DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",
  DELETE_OFFER_FAILURE: "DELETE_OFFER_FAILURE",
  DELETE_OFFER_CLEAR: "DELETE_OFFER_CLEAR",
  CLONE_OFFER_REQUEST: "CLONE_OFFER_REQUEST",
  CLONE_OFFER_SUCCESS: "CLONE_OFFER_SUCCESS",
  CLONE_OFFER_FAILURE: "CLONE_OFFER_FAILURE",
  CLONE_OFFER_CLEAR: "CLONE_OFFER_CLEAR",
  GET_UPLOAD_MEDIA_REQUEST: "GET_UPLOAD_MEDIA_REQUEST",
  GET_UPLOAD_MEDIA_REQUEST_SUCCESS: "GET_UPLOAD_MEDIA_REQUEST_SUCCESS",
  GET_UPLOAD_MEDIA_FAILURE: "GET_UPLOAD_MEDIA_FAILURE",
  GET_UPLOAD_MEDIA_REQUEST_CLEAR: "GET_UPLOAD_MEDIA_REQUEST_CLEAR",
  GET_OFFER_LIST_BY_ID_REQUEST: "GET_OFFER_LIST_BY_ID_REQUEST",
  GET_OFFER_LIST_BY_ID_SUCCESS: "GET_OFFER_LIST_BY_ID_SUCCESS",
  GET_OFFER_LIST_BY_ID_FAILURE: "GET_OFFER_LIST_BY_ID_FAILURE"
}
export default {
  ...APP_ACTIONS,
  ...AUTH_ACTIONS,
  ...USER_ACTIONS,
  ...NOTIFICATION_ACTIONS,
  ...DASHBOARD_ACTIONS,
  ...REALTIME_RESEARCH_ACTIONS,
  ...ADVERTISER_ACTIONS,
  ...ORGANIZATION_ACTIONS,
  ...REPORT_BUILDER,
  ...SHEDULE_REPORT_ACTIONS,
  ...CAMPAIGNS_ACTIONS,
  ...LINE_ACTIONS,
  ...AD_ACTIONS,
  ...SURVEY_ACTIONS,
  ...BRANDS_ACTIONS,
  ...CONVERSION_ACTIONS,
  ...OFFER_ACTIONS,
  ...FILTERS_ACTION
};
