import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { brandsActionCreator  } from "../../actions";
import "./index.scss";

const PopUpModal = (props) => {
    const dispatch = useDispatch();
    const { className, title, openPopUp, setOpenPopUp, children, brandData } = props;

    const closeBtn = (
        <button className="close" onClick={() => { setOpenPopUp(false) }}>
           <img src="/Icon_assets/svg/close_icon_black.svg" alt="Close" style={{width:'16px', height:'16px'}}/>
        </button>
    );

    useEffect(() => {
        return () => {
            dispatch(brandsActionCreator.resetBrandLogo());
        }
    }, []);

    return (
        <Modal isOpen={openPopUp} className={className + " pop-up-modal"} centered style={{ width: props.width }}>
            <ModalHeader close={closeBtn}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}

export default PopUpModal;