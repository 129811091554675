import React, { useEffect, useState } from 'react'
import {
    Card,
    Table,
    CardBody,
    CustomInput,
    Button,
    UncontrolledTooltip
} from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import history from "../../../history";
import Notifications from "../../../components/Notifications";
import HeaderNavigation from "../../../components/HeaderNavigation";
import { scheduleReportActionCreator } from "../../../actions"
import { format } from 'date-fns';
import SkeletonTable from "../../../components/Loader_Skeleton/SkeletonTable";
import ScheduleReportListActions from './ScheduleReportListActions'
import './index.scss';
import ROUTES from '../../../utils/routeConstants';
import StatusDropdown from './StatusDropdown';
import PopUpModal from "../../../components/PopUpModal";
import DeleteSchedule from './ScheduleReportListActions/DeleteSchedule';
import RBAC from '../../../components/RBAC';
import { PERMISSIONS } from '../../../utils/permissionMatrix';
import checkRBAC from '../../../utils/checkRBAC';
import { formatDateUTC } from "../../../utils/dateFormat";
import ADPagination from '../../../components/ADPagination';

function ScheduledReportList() {

    const MAIN_TABLE_COL_MIN_WIDTH = 200;
    const EXPANDED_TABLE_COL_MIN_WIDTH = 220;
    const [expandedTableColWidth, setExpandedTableColWidth] = useState(EXPANDED_TABLE_COL_MIN_WIDTH);

    const dispatch = useDispatch();

    const FREQUENCY = ['Daily', 'Weekly', 'Monthly']
    const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const DATE_FORMAT = {
        MMMMddyyyy: 'MMMM dd, yyyy',
        MMMMDDYYYY: 'MMMM DD, YYYY'
    };

    const [selectedItems, setSelectedItems] = useState({})
    const [allSelected, setAllSelected] = useState(false)
    const [noneSelected, setNoneSelected] = useState(true)


    const isSaved = useSelector((state) => state.scheduleReport.isSaved);
    const reportScheduleData = useSelector((state) => state.scheduleReport.list);
    const isFetching = useSelector((state) => state.scheduleReport.isListFetching);
    const isDeleting = useSelector(state => state.scheduleReport.isDeleting)
    const deleted = useSelector(state => state.scheduleReport.deleted)
    const [deleteModalView, setDeleteModalView] = useState(false);
    const [deleteIds, setDeleteIds] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    /*checkRBAC function prerequisite start*/
    const userData = useSelector(state => state.auth.userData);
    const userRbac = useSelector(state => state.auth.rbac);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    /*checkRBAC function prerequisite end*/

    const TABLE_COLS = [
        {
            dataField: "report_name",
            text: "Name",
            className: 'table-title'
        },
        {
            dataField: "status",
            text: "Status",
            className: 'table-title status'
        },
        {
            dataField: "report_frequency",
            text: "Frequency",
            className: 'table-title report-frequency'
        },
        {
            dataField: "custom_days",
            text: "Day of Week",
            className: 'table-title custom-days'
        },
        {
            dataField: "updated_at",
            text: "Last Updated Date",
            className: 'table-title updated-at'
        },
        {
            dataField: "end_date",
            text: "End Date",
            className: 'table-title end-date'
        },
        {
            dataField: "actions",
            text: "Actions",
            className: 'action'
        }
    ]

    useEffect(() => {
        refreshTable(currentPage)
        setAllSelected(false)
        setNoneSelected(true)
    }, [])

    useEffect(() => {
        reportScheduleData?.results?.forEach((item) => {
            selectedItems[item?.id] = false;
        })
    }, [reportScheduleData])

    useEffect(() => {
        if (deleted) {
            dispatch(scheduleReportActionCreator.deleteScheduleReportRequestFailureClear())
            refreshTable(currentPage);
        }
        if (isSaved) {
            setTimeout(() => {
                dispatch(scheduleReportActionCreator.saveScheduleReportRequestFailureClear())
                refreshTable();
                setCurrentPage(1)
            }, 1000)
        }

    }, [deleted, isSaved])

    function onAllCheckEvent() {

        if (!getIsAllTrue()) {
            reportScheduleData?.results?.forEach((item) => {
                selectedItems[item?.id] = true;
            })
            setAllSelected(true)
            setNoneSelected(false)
        }
        else {
            reportScheduleData?.results?.forEach((item) => {
                selectedItems[item?.id] = false;
            })
            setAllSelected(false)
            setNoneSelected(true)
        }

        setSelectedItems(selectedItems)
    }

    const getIsAllTrue = () => {
        for (var o in selectedItems)
            if (!selectedItems[o]) return false;
        return true;
    }

    const getIsAllFalse = () => {
        for (var o in selectedItems)
            if (selectedItems[o]) return false;
        return true;
    }

    const onCheckEvent = (id) => {
        selectedItems[id] = !selectedItems[id]
        setAllSelected(getIsAllTrue())
        setNoneSelected(getIsAllFalse)
        setSelectedItems((prevState) => ({
            ...prevState
        }));
    }

    const deletedBtnClicked = () => {
        let id = Object.keys(Object.fromEntries(Object.entries(selectedItems).filter(value => value[1]))).join(',');
        setDeleteIds(id)
        setDeleteModalView(true)
    }

    const refreshTable = (pageNumber = 1) => {
        dispatch(scheduleReportActionCreator.getScheduleReportListRequest({ method: "GET", offset: (pageNumber - 1) * 10 }))
        setSelectedItems({})
    }

    var thElm;
    var startOffset;

    useEffect(() => {
        var table = document.getElementById("schedule-report");
        // For resizing column, add dummy div to header, add event when clicked
        if (table) {
            var tableHeight = table.offsetHeight;
            Array.prototype.forEach.call(
                table.querySelectorAll(".resized-column"),

                function (th) {
                    //th.style.position = 'relative';

                    var grip = document.createElement('div');
                    grip.className = 'column-resizer';
                    grip.innerHTML = "&nbsp;";
                    grip.style.top = 0;
                    grip.style.right = 0;
                    grip.style.bottom = 0;
                    grip.style.width = '15px';
                    grip.style.height = tableHeight + 'px';
                    grip.style.position = 'absolute';
                    grip.style.zIndex = "1";
                    grip.style.cursor = 'col-resize';
                    grip.addEventListener('mousedown', function (e) {
                        thElm = th;
                        startOffset = th.offsetWidth - e.pageX;
                    });

                    th.appendChild(grip);
                });

            // listener for dragging
            table.addEventListener('mousemove', function (e) {
                if (thElm) {
                    if (startOffset + e.pageX >= MAIN_TABLE_COL_MIN_WIDTH) {
                        thElm.style.width = startOffset + e.pageX + 'px';
                        thElm.style.minWidth = startOffset + e.pageX + 'px';
                    }

                    let expandedColumnWidth = (startOffset + e.pageX) + 20;
                    if (expandedColumnWidth >= EXPANDED_TABLE_COL_MIN_WIDTH) {
                        setExpandedTableColWidth(expandedColumnWidth);
                    }
                }
            });

            // listner for releasing click
            table.addEventListener('mouseup', function () {
                thElm = undefined;
            });
        }
    })

    return (
        <>
            <Notifications />
            <HeaderNavigation />
            <div className='schedule-report-list-header'>
                <div className="schedule-report-list-header-right-container">
                    <div class="title-with-back-button d-flex justify-content-sm-between align-items-center w-100">
                        <div class="d-flex">
                            <button type="button" class="btn-transparent mr-4 btn btn-secondary" onClick={() => { history.push(ROUTES.REPORT_BUILDER) }}>
                                <img src="/Icon_assets/svg/back_icon.svg" alt="go to back" class="go-back" />
                            </button>
                            <h1>{'Scheduled Reports'}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='schedule-report-list-container'>
                <Card className="data-card ">
                    <CardBody>
                        <div style={{ display: 'flex' }}>
                            <span className='table-main-title d-flex'>{'Reports List'}</span>
                            <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_DELETE}>
                              <span>
                                <Button className="in-btn-secondary  d-flex btn-delete-schedule" disabled={noneSelected} onClick={() => { deletedBtnClicked() }} >
                                  {isDeleting ? '...' : <img src={"/Icon_assets/svg/delete-icon.svg"} alt="delete all" />}
                                </Button>
                              </span>
                            </RBAC>
                        </div>
                        <div>
                            {isFetching ? <section className="skeleton-loading">
                                <SkeletonTable rows={10} />
                            </section> :
                                <div style={{ overflowY: 'scroll' }}>
                                    <Table className="organization-details-tbl" id="schedule-report">
                                        <thead>
                                            <tr>
                                              <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_DELETE}>
                                                <th className='table-title'>
                                                  <CustomInput
                                                    type="checkbox"
                                                    id={"checkbox"}
                                                    value={allSelected}
                                                    checked={allSelected}
                                                    onChange={(e) => { onAllCheckEvent() }}
                                                  />
                                                </th>
                                              </RBAC>
                                               
                                                {TABLE_COLS.map((d, i) => {
                                                    return d.text.toLowerCase() === 'name' ? <th key={i} className={d.className + " resized-column"} >
                                                        <div className="resizer-icon">
                                                            <span className="first"></span>
                                                            <span className="second"></span>
                                                        </div>
                                                        {d.text}</th> :

                                                        <th key={i} className={d.className} >{d.text}</th>;
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reportScheduleData && reportScheduleData?.results?.length > 0 ? (reportScheduleData?.results?.map((item) => {
                                                    return (<tr key={item.id} >
                                                            <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_DELETE}>
                                                            <td key={'checkbox' + item.id}>
                                                            <CustomInput
                                                              type="checkbox"
                                                              key={'checkbox-select' + item.id}
                                                              id={item.id}
                                                              checked={selectedItems[item.id] == true}
                                                              onChange={() => onCheckEvent(item.id)}
                                                            />
                                                          </td>
                                                        </RBAC>
                                                        
                                                        <td key={'report_name' + item.id}>
                                                            <span>{item.report_name}</span>
                                                        </td>
                                                        <td key={'state' + item.id} className={`${ checkRBAC(userData, userRbac, PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE ,selectedOrganization) ? "" : "prevent-user-selection" }`}>
                                                            <div className='state-change-dropdown' ><StatusDropdown data={item} refreshTable={refreshTable} /></div>
                                                        </td>
                                                        <td key={'report_frequency' + item.id}>
                                                            <span>{FREQUENCY[item.report_frequency]}</span>
                                                        </td>
                                                        <td key={'custom_days' + item.id}>
                                                            {
                                                                item?.custom_days?.length <= 1 ? item?.custom_days.map((i) => <span> {WEEK_DAYS[i - 1]} </span>) :
                                                                    <> <span alt="Info icon" id={'opentooltip' + item.id}>{'Multiple Days'}</span>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            className="ad-tool-tip"
                                                                            target={'opentooltip' + item.id}

                                                                        >
                                                                            {item?.custom_days.map((i) => <div className="text-left p-1"> {WEEK_DAYS[i - 1]} </div>)}

                                                                        </UncontrolledTooltip ></>
                                                            }
                                                        </td>
                                                        <td key={'updated_at' + item.id}>
                                                            <span>{format(new Date(item.updated_at), DATE_FORMAT.MMMMddyyyy)}</span>
                                                        </td>
                                                        <td key={'end_date' + item.id}>
                                                            <span>{formatDateUTC(item.end_date, DATE_FORMAT.MMMMDDYYYY)}</span>
                                                        </td>
                                                        <td key={'column-actions' + item.id} className="action-column">
                                                            <ScheduleReportListActions id={item.id} state={item.state} refreshTable={refreshTable} currentPage={currentPage} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })) :
                                                    (<>
                                                        <tr>
                                                            <td colSpan={TABLE_COLS.length + 1} style={{ textAlign: "center" }}>No data available</td>
                                                        </tr>
                                                    </>)
                                            }
                                        </tbody>
                                    </Table>
                                    {(userData?.organization_role === 0) && (!Object.keys(selectedOrganization).length > 0) && (reportScheduleData?.results?.length > 0) && <ADPagination
                                        className="rtr-pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={reportScheduleData?.count}
                                        pageSize={10}
                                        handlePaginationClick={(pageNumber) => { setCurrentPage(pageNumber); refreshTable(pageNumber); }}
                                        showNoOfRecords={true}
                                    />}
                                </div>}
                        </div>
                    </CardBody>
                </Card>
            </div>
            <PopUpModal
                className={'schedule-report-modal delete-modal'}
                title={"Delete Schedule Report"}
                openPopUp={deleteModalView}
                setOpenPopUp={setDeleteModalView}
            >
                <DeleteSchedule id={deleteIds} setDeleteModalView={setDeleteModalView} refreshTable={refreshTable} currentPage={currentPage} />
            </PopUpModal>
        </>
    )
}

export default ScheduledReportList