import ACTIONS from '../constants/actionTypes';

//LINE_ACTIONS
export const getLineListRequest = (data) => {
  return {
    type: ACTIONS.GET_LINE_LIST_REQUEST,
    payload: data,
  };
};

export const getLineListSuccess = (data) => {
  return {
    type: ACTIONS.GET_LINE_LIST_REQUEST_SUCCESS,
    payload: data,
  };
};

export const getLineListFailure = (errors) => {
  return {
    type: ACTIONS.GET_LINE_LIST_REQUEST_FAILURE,
    payload: errors,
  };
};

export const getLineListFailureClear = () => {
  return {
    type: ACTIONS.GET_LINE_LIST_REQUEST_FAILURE_CLEAR,
  };
};