import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import {
  Button, FormGroup,
  Modal, ModalBody, ModalHeader
} from "reactstrap";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";

const QuestionBranchingModal = ({ display, closePopup, confirmQuestionChange, nextQuestion,nextOption, dataID, itemID }) => {
  const clearcloseBtn = (
    <button className="close" style={{ opacity: "1" }} onClick={closePopup}>
      <img src={"/Icon_assets/svg/close_icon_black.svg"} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>);


  return (
    <Modal isOpen={display} className="ad-pop-up-modal branching-modal" centered >
      <ModalHeader close={clearcloseBtn} className="answer-branching" style={{ color: "#0E0E0F" }}>Reset Answer Level Branching?</ModalHeader>
      <ModalBody className="branching-modal-body">
        <div>
          There is answer level branching present. Arranging questions/toasts by drag-and-drop will reset all answer level branching.
        </div><br/>
        <div>
          You have to manually update all answer level branching links.
        </div>
        <FormGroup className="text-left" style={{ marginTop: "32px" }}>
          <Button
            className="in-btn-sm in-btn-primary mr-4 export-button"
            disabled={false}
            onClick={()=> confirmQuestionChange(nextQuestion,nextOption)}
            style={{ minWidth: '86px' }}
          >
            Yes
          </Button>
          <Button
            className="in-btn-link btn-modal-cancle btn btn-link"
            disabled={false}
            onClick={closePopup}
          >
            Cancel
          </Button>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}
export default QuestionBranchingModal;