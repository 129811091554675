import ACTIONS from "../constants/actionTypes";

const initialState = {
  isSaving: false,
  isSaved: false,
  isFetching: false,
  data: {},
  isListFetching: false,
  list: [],
  listPayload: {},
  isDeleting: false,
  deleted: false,
};

const scheduleReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_SHEDULE_REPORT_REQUEST:
      return {
        ...state,
        data: action.payload,
        isSaving: true,
        isSaved: false,
      };

    case ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSaving: false,
        isSaved: true,
      };

    case ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        data: {},
        isSaving: false,
        isSaved: false
      };

    case ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errorMsg: undefined,
      };

    case ACTIONS.GET_SHEDULE_REPORT_REQUEST:
      return {
        ...state,
        data: action.payload,
        isFetching: true,
      };

    case ACTIONS.GET_SHEDULE_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };

    case ACTIONS.GET_SHEDULE_REPORT_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        data: {},
        isFetching: false,
      };

    case ACTIONS.GET_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST:
      return {
        ...state,
        listPayload: action.payload,
        isListFetching: true,
      };

    case ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isListFetching: false,
      };

    case ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        list: [],
        isListFetching: false,
      };

    case ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    case ACTIONS.DELETE_SHEDULE_REPORT_REQUEST:
      return {
        ...state,
        data: action.payload,
        isDeleting: true,
      };

    case ACTIONS.DELETE_SHEDULE_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleted: true,
        successMsg: action.payload
      };

    case ACTIONS.DELETE_SHEDULE_REPORT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleted: false,
        errorMsg: action.payload
      };

    case ACTIONS.DELETE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        isDeleting: false,
        deleted: false,
        errorMsg: undefined
      };

    default:
      return state;
  }
};

export default scheduleReportReducer;
