import React, { useEffect, useState } from 'react'
import CityFilter from './CityFilter'
import DateRangeFilter from './DateRangeFilter'
import { Button } from 'reactstrap'
import './index.scss'
import StateFilter from './StateFilter'
import ZipCodesFilter from './ZipCodesFilter'
import GenderFilter from './GenderFilter'
import DeviceFilter from './DeviceType'
import AgeRange from './AgeRange'


const ICONS = {
  'FILTER': "/Icon_assets/svg/filter-icon.svg"
}

function RealtimeResearchFilter(props) {

  const [filterActive, setFiltersActive] = useState(true);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedZipCodes, setSelectedZipCodes] = useState([]);
  const [selectedAgeRange, setSelectedAgeRange] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isDisplayFilterRemoved, setIsDisplayFilterRemoved] = useState(false);

  const getFilterDisplayData = (type, data, func) => {
    return {
      type,
      label: `${type}: ${data}`,
      value: data,
      func: (e) => func(e)
    }
  }

  const FilterDeletePressed = () => setIsDisplayFilterRemoved(true)
  const resetFilterDeletePressed = () => setIsDisplayFilterRemoved(false)

  const removeDateRange = (data) => {
    setSelectedDateRange([])
    FilterDeletePressed()
  }
  const removeState = (data) => {
    setSelectedStates(selectedStates.filter(selectedState => selectedState.label !== data))
    FilterDeletePressed()
  }
  const removeCity = (data) => {
    setSelectedCities(selectedCities.filter(selectedCity => selectedCity.label !== data))
    FilterDeletePressed()
  }
  const removeZipCode = (data) => {
    setSelectedZipCodes(selectedZipCodes.filter(selectedZipCode => selectedZipCode !== data))
    FilterDeletePressed()
  }
  const removeAgeRange = (data) => {
    setSelectedAgeRange(selectedAgeRange.filter(selectedAge => selectedAge !== data))
    FilterDeletePressed()
  }
  const removeGender = (data) => {
    setSelectedGenders(selectedGenders.filter(selectGender => selectGender.label !== data))
    FilterDeletePressed()
  }
  const removeDevice = (data) => {
    setSelectedDevices(selectedDevices.filter(selectedDevice => selectedDevice.label !== data))
    FilterDeletePressed()
  }

  useEffect(() => {
    setFiltersActive(JSON.stringify(props.filters) === JSON.stringify(getAllFilters()))
  }, [selectedStates,
    selectedDateRange,
    selectedCities,
    selectedZipCodes,
    selectedAgeRange,
    selectedGenders,
    selectedDevices])

  const resetAll = () => {
    FilterDeletePressed(true)
    setSelectedStates([])
    setSelectedAgeRange([])
    setSelectedCities([])
    setSelectedDateRange([])
    setSelectedDevices([])
    setSelectedGenders([])
    setSelectedZipCodes([])
    FilterDeletePressed()
  }

  const getAnyFilterApplied = () => {
    return selectedStates.length !== 0 ||
      selectedAgeRange.length !== 0 ||
      selectedCities.length !== 0 ||
      selectedDateRange.length !== 0 ||
      selectedDevices.length !== 0 ||
      selectedGenders.length !== 0 ||
      selectedZipCodes.length !== 0 ||
      props.filters.length !== 0
  }

  useEffect(() => {
    if (props.isClearPressed) {
      resetAll()
    }
    setFiltersActive(JSON.stringify(props.filters) === JSON.stringify(getAllFilters()))
  }, [props.filters])

  useEffect(() => {
    if (isDisplayFilterRemoved)
      applyFilters()

  }, [isDisplayFilterRemoved])

  const applyFilters = () => {
    props.setFilters(getAllFilters())
    resetFilterDeletePressed()
    props.setIsClearPressed(false)
  }

  function getAllFilters() {
    return [...selectedDateRange.map(selectedDR => getFilterDisplayData('Date', selectedDR, removeDateRange)),
    ...selectedStates.map(selectedState => getFilterDisplayData('State', selectedState.label, removeState)),
    ...selectedCities.map(selectedCity => getFilterDisplayData('City', selectedCity.label, removeCity)),
    ...selectedZipCodes.map(selectedZipCode => getFilterDisplayData('PIN', selectedZipCode, removeZipCode)),
    ...selectedAgeRange.map(selectedAge => getFilterDisplayData('Age', selectedAge, removeAgeRange)),
    ...selectedGenders.map(selectGender => getFilterDisplayData('Gender', selectGender.label, removeGender)),
    ...selectedDevices.map(selectedDevice => getFilterDisplayData('Device', selectedDevice.label, removeDevice))
    ];
  }

  return (
    <div className="rtr-filter-container">
      <div style={{ justifyContent: 'space-between', marginBottom: '24px' }}>
        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', marginBottom: '24px' }}>
          <div style={{ display: 'flex' }}>
            <img src={ICONS.FILTER} style={{ alignSelf: "center", marginLeft: '12px' }} alt='filter'></img>
            <span style={{ alignSelf: "center", marginLeft: '15px' }}>Filters</span>
          </div>
          <Button
            className="icon-button"
            onClick={(e) => {
              e.preventDefault();
              props.setShowFilter(false)
            }}
          >
            <img src="/Icon_assets/svg/back_icon.svg" alt="export" ></img>
          </Button>
        </div>
        {!getAnyFilterApplied() ? <div className='no-filter-selected' style={{ alignSelf: "center", marginLeft: '12px' }}>No Filters Selected</div> : <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', marginTop: '16px', marginBottom: '16px', marginLeft: '12px' }}>
          <button
            type="button"
            className=" btn btn-clear"
            onClick={(e) => props.resetAllFilters(e)}>
            <span style={{ color: "#3257FB", fontSize: "16px", }}>Clear All</span>
          </button>

          <Button
            className="in-btn-info btn-export apply-btn "
            onClick={applyFilters}
            disabled={filterActive}>Apply</Button>
        </div>}
      </div>
      <div>
        <div className='filter'>
          <DateRangeFilter
            isDisplayFilterRemoved={isDisplayFilterRemoved}
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange} />
        </div>
        <div tabIndex="-1" className="dropdown-divider"></div>
        <div className='filter'>
          <StateFilter
            isDisplayFilterRemoved={isDisplayFilterRemoved}
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
          />
        </div>
        <div tabIndex="-1" className="dropdown-divider"></div>
        <div className='filter'>
          <CityFilter
            isDisplayFilterRemoved={isDisplayFilterRemoved}
            selectedCities={selectedCities}
            setSelectedCities={setSelectedCities} />
        </div>
        <div tabIndex="-1" className="dropdown-divider"></div>
        <div className='filter'>
          <ZipCodesFilter
            selectedZipCodes={selectedZipCodes}
            isDisplayFilterRemoved={isDisplayFilterRemoved}
            setSelectedZipCodes={setSelectedZipCodes}
          />
        </div>
        {props?.platform === 0 && <div><div tabIndex="-1" className="dropdown-divider"></div>
          <div className='filter'>
            <AgeRange
              selectedAgeRange={selectedAgeRange}
              setSelectedAgeRange={setSelectedAgeRange}
              isDisplayFilterRemoved={isDisplayFilterRemoved} />
          </div>
          <div tabIndex="-1" className="dropdown-divider"></div>
          <div className='filter'>
            <GenderFilter
              selectedGenders={selectedGenders}
              setSelectedGenders={setSelectedGenders}
              isDisplayFilterRemoved={isDisplayFilterRemoved}
            />
          </div></div>}
        <div tabIndex="-1" className="dropdown-divider"></div>
        <div className='filter'>
          <DeviceFilter
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            isDisplayFilterRemoved={isDisplayFilterRemoved} />
        </div>
      </div>
    </div>
  )
}

export default RealtimeResearchFilter