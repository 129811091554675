import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { usePagination, DOTS } from '../../utils/customHooks/usePagination';

const ADPagination = props => {
  const {
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 10,
    className = "",
    handlePaginationClick,
    showNoOfRecords = true
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  //If less than 2 pages in result dont show the pagination
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    handlePaginationClick(currentPage + 1)
  };

  const onPrevious = () => {
    handlePaginationClick(currentPage - 1)
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  
  return(<div className={`pagination-bar-sitelist ${className}`}>
    <Pagination aria-label="Page navigation">

      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          previous
          onClick={onPrevious}
        >
        </PaginationLink>
      </PaginationItem>

      {paginationRange.map((pageNumber, pageIndex) => {
        if (pageNumber === DOTS) {
          return (<PaginationItem disabled key={pageIndex}>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>);
        }

        return (
          <PaginationItem
            key={pageIndex}
            active={pageNumber === currentPage}
            disabled={pageNumber === currentPage}
          >
            <PaginationLink
              onClick={ () => handlePaginationClick(pageNumber)}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        );
      })}

      <PaginationItem disabled={currentPage === lastPage}>
        <PaginationLink
          next
          onClick={onNext}
        >
        </PaginationLink>
      </PaginationItem>

    </Pagination>
    { showNoOfRecords ?
      (<div className="no-of-records">
        <span>{totalCount} Results</span>
      </div>) : 
    "" }
  </div>);
};

export default ADPagination;
