export const PERMISSION_POINTS = {
  MANAGE_ORGANIZATION: 'manage_organization',
  MANAGE_USER: 'manage_user',
  BUILD_RTR: 'build_rtr',
  MANAGE_BRAND: 'manage_brand',
  MANAGE_SITE: 'manage_site',
  MANAGE_TAG: 'manage_tag',
  MANAGE_REPORT: 'manage_report',
  MANAGE_FILTER: 'manage_filter',
  MANAGE_AUDIENCE: 'manage_audience',
  MANAGE_ORGANIZATION_LEVEL_BRANDS: 'manage_organization_level_brands',
  MANAGE_CREATIVE_ASSET_FILE: 'manage_creative_asset_file',
  MANAGE_ORG_WIDE_TEMPLATE: 'manage_org_wide_template',
  MANAGE_PAYMENT_PROFILE: 'manage_payment_profile',
  MANAGE_BANK: 'manage_bank',
  MANAGE_LOCATION: 'manage_location',
  MANAGE_TRANSACTION: 'manage_transaction',
  MANAGE_IABCATEGORY: 'manage_iabcategory',
  BUILD_REPORT: 'build_report',
  MANAGE_EXTERNAL_MESSAGE: 'manage_external_message',
  MANAGE_ADVERTISER_admin: 'manage_advertiser_admin',
  MANAGE_DSPCAMPAIGN: 'manage_dspcampaign',
  BUILD_CONVERSION_RULE: 'build_conversion_rule',
  BUILD_OFFER: 'build_offer',
}

export const PERMISSION_ACTIONS = {
  READ: 'read',
  WRITE: 'write',
  DELETE: 'delete',
  DEACTIVATE: 'deactivate',
  ARCHIVE: 'archive',
  CLONE: 'clone'
}