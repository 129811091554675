import ACTIONS from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  isFetched: false,
  data: [],
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_CAMPAIGNS_LIST_REQUEST:
      return {
        ...state,
        data: action.payload,
        isLoading: true,
        isFetched: false
      };

    case ACTIONS.GET_CAMPAIGNS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isFetched: true
      };

    case ACTIONS.GET_CAMPAIGNS_LIST_REQUEST_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        data: [],
        isLoading: false,
        isFetched: false
      };

    case ACTIONS.GET_CAMPAIGNS_LIST_REQUEST_FAILURE_CLEAR:
      return {
        ...state,
        errorMsg: undefined,
      };

    default:
      return state;
  }
};

export default campaignsReducer;
