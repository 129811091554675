import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const DeleteRtrAd = ({setOpenDeleteAdModal,currentAD,deleteRtrAdRequest}) => {
  return (
    <section className="delete-rtr-ad-content">
      <nav className="delete-rtr-ad-section">
        <p>Are you sure you want to delete this survey?</p>
      </nav>
      <nav className="btn-wrapper">
        <Button className="in-btn-sm in-btn-primary" onClick={() => deleteRtrAdRequest(currentAD)}>
          Continue
        </Button>
        <Button color="link" className="in-btn-link" onClick={() => setOpenDeleteAdModal(false)}>
          Cancel
        </Button>
      </nav>
    </section>
  )
}

export default DeleteRtrAd;