import ACTIONS from "../constants/actionTypes";

export const advertisersListRequest = (data) => {
  return {
    type: ACTIONS.ADVERTISERS_LIST_REQUEST,
    payload: data,
  };
};

export const advertisersListSuccess = (data) => {
  return {
    type: ACTIONS.ADVERTISERS_LIST_SUCCESS,
    payload: data,
  };
};

export const advertisersListFailure = (errors) => {
  return {
    type: ACTIONS.ADVERTISERS_LIST_FAILURE,
    payload: errors,
  };
};

export const advertisersListFailureClear = () => {
  return {
    type: ACTIONS.ADVERTISERS_LIST_FAILURE_CLEAR,
  };
};
