import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Navbar,
  Row, Col
} from "reactstrap";
import "./index.scss";
import Loader from '../../components/Loader';
import { useParams, useLocation } from "react-router-dom";
import { surveyActionCreator } from "../../actions";
import { clickableScript } from "../../utils/previewMessageClickableScript";
import { authActionCreator } from "../../actions";
import { organizationActionCreator } from "../../actions";
import PlatformToggle from "../../components/RealtimeResearch/RealTimeCampaign/PlatformToggle";
import RTRPreviewLink from "../RTRPreviewLink";

const PreviewLink = () => {
  let { brand_id, msg_id } = useParams();
  let location = useLocation();
  const ad_id = new URLSearchParams(location.search).get('ad_id');

  const ad1_id = new URLSearchParams(location.search).get('ad1_id');
  const msg1_id = new URLSearchParams(location.search).get('msg1_id');

  const [platformPreview, setPlatformPreview] = useState(1);
  const [isSupportBoth, setIsSupportBoth] = useState(!!ad1_id)

  const OrganizationLogo = "/Icon_assets/svg/invisibly-brands-logo.svg";
  const dispatch = useDispatch();
  const msgbodyStyle = { height: '100%', overflow: "auto" };
  const surveyStyle = { height: '90%', overflow: "auto", background: "#9d5cff", display: "inherit", paddingBottom: "50px" };
  const surveyStyle1 = { height: '70%', overflow: "hidden", background: "#9d5cff", display: "inherit", paddingBottom: "50px" };
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [frame, setFrame] = useState("");
  const [shadow, setShadow] = useState("2px 8px 12px rgb(19 36 139 / 4%)");
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");

  const previewMessage = useSelector((state) => state.surveyData.externalPreview);

  const isPreviewFetching = useSelector(
    (state) => state.surveyData.isExternalPreviewLoading
  );

  useEffect(() => {
    var iframe = document.getElementsByTagName('iframe')[0],
      iDoc = iframe?.contentWindow
        || iframe?.contentDocument;
    if (iDoc) {

      iDoc.addEventListener('mouseup', () => {
        var dataUrl = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById("toastButtonlink7")?.getAttribute('data-url')
        if (dataUrl && dataUrl.toLowerCase().includes("surveyresult".toLowerCase())) {
          document.getElementsByTagName('iframe')?.[0].contentWindow.document.getElementById("toastButtonlink7").setAttribute('data-url', dataUrl.replace('undefined', ad_id));
        }
      });
    };
  })

  window.closeSurvey = (exitSurvey) => {
    setSurveyComplete(true);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (previewMessage) {
      const frames = previewMessage && previewMessage.previews &&
        previewMessage.previews.previews["frame_1"] ? previewMessage.previews.previews["frame_1"] : "";

      let url = previewMessage && previewMessage.previews &&
        previewMessage.previews.tracking_url ? `${previewMessage.previews.tracking_url}` : '';
      let clickScript = clickableScript;
      let scriptClickable = clickScript.replace('API_URL', url).replace('isPreview', 'true')

      let keys = Object.keys(frames),
        sizes, width, height;

      let sizeKey = [];
      keys.map((value, index) => {
        sizeKey = value === "size" ? keys.splice(index, 1) : "";
        return sizeKey;
      });


      const previewHtml =
        frames[keys[0]] ? frames[keys[0]] + scriptClickable : '<div>No Previews Available. Create message and try again...</div>';

      if (sizeKey.length > 0) {
        sizes = frames && frames.size ? frames.size : "";
        height = sizes && sizes.height ? sizes.height : "";
        width = sizes && sizes.width ? sizes.width : "";
      }


      setFrame(frames);
      setHeight((+height + 16) + "px");
      setWidth((+width + 16) + "px");
      setPreviewHtml(previewHtml);
    }
  }, [previewMessage]);

  useEffect(() => {
    var params = {};
    params.brands = brand_id;
    params.messages = msg_id;
    params.ad_id = ad_id;
    if (params.brands && params.messages)
      dispatch(surveyActionCreator.getExternalPreviewRequest(params));
  }, []);

  const reloadIframe = () => {
    var iframe = document.getElementsByName('frame')[0];
    iframe.srcdoc = iframe.srcdoc;
    setSurveyComplete(false);
  }

  const handlePlatformChange = (selectedPlatform) => {
    setPlatformPreview(selectedPlatform)
    if (selectedPlatform === 1) {
      var params = {};
      params.brands = brand_id;
      params.messages = msg_id;
      params.ad_id = ad_id;
      if (params.brands && params.messages)
        dispatch(surveyActionCreator.getExternalPreviewRequest(params));
    }
  }

  return (
    <>
      <Navbar light expand="md" className="navigation-section">
        <span className="logo-image-container center-logo">
          <a
            href="https://www.invisibly.com/home"
            target="_blank"
            rel="noreferrer"
          // onClick={navigateToDashboard}
          >
            <img
              src={OrganizationLogo}
              alt="Invisibly for brand logo"
              className="img-fluid"
              style={{ maxWidth: "176px", maxHeight: "28px" }}
            />
          </a>
        </span>
      </Navbar>

      <Row style={{ margin: 0 }}>
        <Col className={"row justify-content-center"} style={{ marginTop: "40px" }}>
          {isSupportBoth && (<PlatformToggle
            data={{}}
            selectPlatform={platformPreview}
            handlePlatformChange={handlePlatformChange}
          />)}
        </Col>
      </Row>
      {platformPreview === 1 ? (<>

        <Row style={{ margin: 0 }}>
          <Col className={"row justify-content-center"} style={{ marginTop: "82px" }}>
            <div id="pr-reload-survey" onClick={reloadIframe} style={{ marginBottom: "20px" }}>
              <img
                src="/Icon_assets/svg/reload-icon.svg"
                alt="share"
                className="link-image center-logo"
              />
              <span className="pr-reload-survey">Reload Survey</span>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col className="d-flex">
            <div className="iframe-div"
              style={(previewMessage.ad_rtr_type === 0 && !surveyComplete) ? surveyStyle :
                (previewMessage.ad_rtr_type === 0 && surveyComplete) ?
                  surveyStyle1 :
                  msgbodyStyle}>
              {
                isPreviewFetching ?
                  <Loader />
                  :
                  <>
                    <div className="container" style={{ display: surveyComplete ? "block" : "none" }}>
                      <p className="vertical-center">Congratulations! You have completed the survey.</p>
                    </div>

                    {previewMessage.ad_rtr_type === 0 && !surveyComplete &&
                      <div style={{
                        width: "100%",
                        background: "#9d5cff", color: "#fff",
                        fontSize: "18px", height: "15%", paddingTop: "40px"
                      }} className="survey-text">
                        <p>Survey</p>
                      </div>}
                    <iframe frameBorder="0" title="previewIframe" name="frame"
                      style={{
                        display: surveyComplete ? "none" : "block",
                        width: width ? `${width}` : "100%",
                        height: height ? `${height}` : `100vh`,
                        boxShadow: `${shadow}`,
                      }}
                      srcdoc={previewHtml} scrolling='no'>
                    </iframe>
                  </>
              }
            </div>
          </Col>
        </Row>
      </>) : (<>
        <RTRPreviewLink
          props_msg_id={msg1_id}
          props_brand_id={brand_id}
          props_ad_id={ad1_id}
        />
      </>)}
    </>
  )
}

export default PreviewLink;