import React from 'react';
import QuestionMenuItem from './QuestionMenuItem';
import "../index.scss";

const GenericQuestionMenuElement = ({ item,menuItemChildren,name,index,getTaxonomySelection,data}) => {
  const generateDisplayValue = (data) =>{
    let displayValue = (data?.parent)? (data?.taxonomy.split(data.parent)[1]).replaceAll('_','').replaceAll('-',' ') : data?.taxonomy.replaceAll('_',' ').replaceAll('-',' ');   
    return displayValue;
  }

  return (
    <>
      { item?.child?.length !== 0 && 
        (
          <section className={`individual-category`}>
            {
              <>
                {
                  item?.display_value !== "Demographic" ? (
                    <nav>
                      <QuestionMenuItem
                        name="taxonomy_question"
                        index={index}
                        item={item}
                        getTaxonomySelection={getTaxonomySelection}
                        data={data}
                      />
                    </nav>
                  ):(
                    <nav>
                      <label className="category-lbl" id={item.id}>{generateDisplayValue(item)}</label>
                    </nav>
                  )
                }
              </>
            }
          </section>
        )
      }
      <section className="children category-lbl">{menuItemChildren}</section>
    </>
  );
}

export default GenericQuestionMenuElement;