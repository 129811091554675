import ACTIONS from '../constants/actionTypes';

//POST
export const saveScheduleReportRequest = (data) => {
  return {
    type: ACTIONS.SAVE_SHEDULE_REPORT_REQUEST,
    payload: data,
  };
};

export const saveScheduleReportRequestSuccess = (data) => {
  return {
    type: ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_SUCCESS,
    payload: data,
  };
};

export const saveScheduleReportRequestFailure = (errors) => {
  return {
    type: ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_FAILURE,
    payload: errors,
  };
};

export const saveScheduleReportRequestFailureClear = () => {
  return {
    type: ACTIONS.SAVE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR,
  };
};

//GET
export const getScheduleReportRequest = (data) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_REQUEST,
    payload: data,
  };
};

export const getScheduleReportRequestSuccess = (data) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_REQUEST_SUCCESS,
    payload: data,
  };
};

export const getScheduleReportRequestFailure = (errors) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_REQUEST_FAILURE,
    payload: errors,
  };
};

export const getScheduleReportRequestFailureClear = () => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR,
  };
};

//GET LIST

export const getScheduleReportListRequest = (data) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST,
    payload: data,
  };
};

export const getScheduleReportListRequestSuccess = (data) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_SUCCESS,
    payload: data,
  };
};

export const getScheduleReportListRequestFailure = (errors) => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE,
    payload: errors,
  };
};

export const getScheduleReportListRequestFailureClear = () => {
  return {
    type: ACTIONS.GET_SHEDULE_REPORT_LIST_REQUEST_FAILURE_CLEAR,
  };
};

//DELETE
export const deleteScheduleReportRequest = (data) => {
  return {
    type: ACTIONS.DELETE_SHEDULE_REPORT_REQUEST,
    payload: data,
  };
};

export const deleteScheduleReportRequestSuccess = (data) => {
  return {
    type: ACTIONS.DELETE_SHEDULE_REPORT_REQUEST_SUCCESS,
    payload: data,
  };
};

export const deleteScheduleReportRequestFailure = (errors) => {
  return {
    type: ACTIONS.DELETE_SHEDULE_REPORT_REQUEST_FAILURE,
    payload: errors,
  };
};

export const deleteScheduleReportRequestFailureClear = () => {
  return {
    type: ACTIONS.DELETE_SHEDULE_REPORT_REQUEST_FAILURE_CLEAR,
  };
};