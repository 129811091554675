import ACTIONS from "../constants/actionTypes";

export const setNotification = (data) => {
    return {
      type: ACTIONS.SET_NOTIFICATION,
      payload: data
    };
};

export const resetNotification = () => {
    return {
      type: ACTIONS.RESET_NOTIFICATION
    };
};
