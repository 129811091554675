import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useLocation } from 'react-router-dom'
import "./index.scss";

import Validator from "../../utils/validators";
import ROUTES from "../../utils/routeConstants";

import LeftSection from "../../components/LeftSection";
import Notifications from "../../components/Notifications";
import ADspinner from "../../components/Loader/ADspinner";
import { sendResetPasswordRequest } from "../../actions/authActions";
import history from "../../history";

const validators = new Validator();

const ChangePassword = () => {
  let location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  /***State init***/
  const [inputValues, setInputValues] = useState({
    password: "",
    token: token,
    rePassword: ""
  });
  const [show_input, setShow_input] = useState(false);
  const [show_reinput, setShow_reinput] = useState(false);
  const [isPasswordMatching, setisPasswordMatching] = useState(true);
  const [isValid, setIsValid] = useState(false);
  // const [isAgree, setIsAgree] = useState(false);
  /***State init end***/

  /*** Redux init ***/
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const resetPasswordRes = useSelector((state) => state.auth.resetPasswordRes);
  const userData = useSelector((state) => state.auth.userData);
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const is_media_enabled = selectedOrganization?.is_media_enabled ? true : false;
  const is_rtr_enabled = selectedOrganization?.is_rtr_enabled ? true : false;
  /*** Redux end ***/

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    if (e.target.name === 'rePassword') {
      e.target.value !== inputValues.password ? setisPasswordMatching(false) : setisPasswordMatching(true)
    } else {
      updateValidations(e.target.name, e.target.value);
    }
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = validators.password.valid && !isPasswordMatching
    setIsValid(checkValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validators.isFormValid(inputValues);
    const params = new URLSearchParams(location.search);
    if (isValid && isPasswordMatching) {
      dispatch(sendResetPasswordRequest({
        token: params.get('token'),
        password: inputValues.password
      }));
    }
    setIsValid(isValid);
  };

  const togglePasswordShow = () => {
    setShow_input(!show_input);
  };

  const toggleRePasswordShow = () => {
    setShow_reinput(!show_reinput);
  };

  if(isLoggedIn){
    if (userData.organization_role === 0) {
      //admin access
      return <Redirect to={ROUTES.DASHBOARD} />
    }else{
      //Reporting user access
      if (is_media_enabled) {
        return <Redirect to={ROUTES.DASHBOARD} />
      }else{
        return <Redirect to={ROUTES.REALTIME_RESEARCH} />
      }
    }
  }

  return (
    <>
      <section className="main-container signup-container">
        <LeftSection pagename={"changepassword"} bgclass={"orange"} />

        <section className="right-section" style={{ paddingTop: true ? '408px' : '341px' }}>
          {resetPasswordRes === 'Success' ?
            <>
              <h2>Password successfully changed!</h2>
              {/* <p className="title-text">
                Your password has been succesfuly changed!
              </p> */}
              <FormGroup>
                <Button
                  style={{ marginTop: '41px' }}
                  className="in-btn-lg in-btn-primary"
                  type="submit"
                  onClick={() => history.push(ROUTES.SIGNIN)}
                >
                  Sign In
                </Button>
              </FormGroup>
            </> :
            <><h2>Change Password</h2>
              <Notifications />
              <Form
                className="user-form-container signup-form"
                onSubmit={handleSubmit}
              >
                <FormGroup className="password-input-section">
                  <Input
                    type={show_input ? "text" : "password"}
                    name="password"
                    id="password"
                    value={inputValues.password}
                    className="form-input form-input-sm"
                    autoComplete="off"
                    onChange={handleInputChange}
                    placeholder="New password"
                    invalid={validators.password.errorMsg !== ""}
                  />
                  <span className="show-hide-text" onClick={togglePasswordShow}>
                    {show_input ? "Hide" : "Show"}
                  </span>
                  <FormFeedback>{validators.password.errorMsg}</FormFeedback>
                </FormGroup>

                <FormGroup className="password-input-section">
                  <Input
                    type={show_reinput ? "text" : "password"}
                    name="rePassword"
                    id="rePassword"
                    value={inputValues.rePassword}
                    className="form-input form-input-sm"
                    autoComplete="off"
                    onChange={handleInputChange}
                    placeholder="Re-enter password"
                    invalid={!isPasswordMatching}
                  />
                  <span className="show-hide-text" onClick={toggleRePasswordShow}>
                    {show_reinput ? "Hide" : "Show"}
                  </span>
                  <FormFeedback>{!isPasswordMatching && 'Password is not matching'}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Button
                    className="in-btn-lg in-btn-primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isValid ? false : true}
                    style={{minWidth:'201px'}}
                  >
                     {isLoading  ?  <ADspinner size="large" /> : 'Submit'}
                  </Button>
                </FormGroup>
              </Form>

              <Button className="in-btn-link blue"
                style={{
                  position: 'absolute',
                  bottom: '38px',
                  fontSize: '20px'
                }}
                onClick={() => history.push(ROUTES.SIGNIN)}
              >Back to sign in</Button>
            </>}
        </section>
      </section>
    </>
  );
};

export default ChangePassword;
