import React, { useEffect, } from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import ROUTES from "../../utils/routeConstants";
import HeaderNavigation from "../../components/HeaderNavigation"
import SideNavigation from '../../components/SideNavigation';
import Notifications from "../../components/Notifications";
import history from '../../history';
import User from "../../components/User";
import UserForm from "../../components/User/UserForm";
import "./index.scss";

const Users = () => {
  return (
    <Router basename="/" history={history}>
      <>
        <Notifications />
        <HeaderNavigation />
        <div className="users-list-container">
          {(ROUTES.USERS_LIST === window.location.pathname) ?
            <SideNavigation />
            : <></>}
          <>
            <Switch>
              <Route exact path={ROUTES.USERS_LIST} component={User} />
              <Route exact path={ROUTES.ADD_USER} component={UserForm} />
              <Route exact path={ROUTES.EDIT_USER} component={UserForm} />
            </Switch>
          </>
        </div>
      </>
    </Router>
  );
}

export default Users;
