import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from '../../../Loader_Skeleton/SkeletonTable';
import GenerateItemList from './GenerateItemList';
import { surveyActionCreator } from '../../../../actions';
import '../index.scss';

const TaxonomyQuestionList = (props) => {
  const {
    data,
    rtrType,
    questionObject,
    getTaxonomySelection,
    clearTaxonomySelection
  } = props;

  const [isTaxonomyListFilterOpen, setTaxonomyListFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [taxonomyQuestionListData, setTaxonomyQuestionListData] = useState(localStorage.getItem('taxonomyQuestionListData') ? JSON.parse(localStorage.getItem('taxonomyQuestionListData')):[]);
  const taxonomyQuestionSearchListData = useSelector((state) => state.surveyData.taxonomyQuestionSearchListData);
  
  useEffect(()=>{
    if(searchText!=='' && taxonomyQuestionSearchListData?.results){
      setTaxonomyQuestionListData(taxonomyQuestionSearchListData)
    }else{
      setTaxonomyQuestionListData(JSON.parse(localStorage.getItem('taxonomyQuestionListData')));
    }
  },[searchText,taxonomyQuestionSearchListData])
  
  const ICONS = {
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    COPY: "/Icon_assets/svg/copy-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",
    ARROW_UP: "/Icon_assets/svg/toggle-up.svg",
    VALID_ICON: '/Icon_assets/svg/valid-tick-icon.svg',
    DRAG: "/Icon_assets/svg/Drag.svg",
    NEXT_QUES: "/Icon_assets/svg/next-question-arrow.svg",
  };

  const dispatch = useDispatch();

  const toggleTaxonomyListFilter = () => {
    setTaxonomyListFilterOpen(prev => !prev);
    setSearchText('');
  }

  const searchQuesDropdown = (e) => {
    setSearchText(e.target.value);
    let questionParamList = {}
    questionParamList.type = 0;
    questionParamList.name = e.target.value;
    dispatch(surveyActionCreator.getTaxonomyQuestionListRequest(questionParamList));
  }

  const clearSelectedOption = (event) => {
    clearTaxonomySelection(event,'questionlist',data);
    setTaxonomyListFilterOpen(prev => !prev);
  }

  return (
    <>
      <Dropdown id='taxonomy-question-list-dropdown' className={`taxonomy-question-list d-flex next-q-dropdown ${(rtrType==="Invisibly" && questionObject[questionObject.length -1]?.ID === data?.ID) ? "disable-platform" : ""  }`} isOpen={isTaxonomyListFilterOpen} toggle={toggleTaxonomyListFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div style={{ display: 'flex' }}>
              <div className='dt-title'>{ data && data?.taxonomy_question ? data.taxonomy_question_label : 'Select Taxonomy'}</div>
            </div>
            <div>
              <img style={{
                display: "inline-block",
                margin: '0 12px',
                transform: isTaxonomyListFilterOpen ? 'rotate(180deg)' : ''
              }}
                src={
                  ICONS.ARROW_DOWN
                }
                className="arrow-down"
                alt='next-q-filter'
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu id='taxonomy-question-list-dropdown-menu'>
          <div className='search-wrapper'>
            <Input
              value={searchText}
              type="text"
              name="search"
              id="searchUser"
              placeholder="Search..."
              className="search-input"
              autocomplete="off"
              onChange={(event) => { searchQuesDropdown(event) }}
            />
            <img
              src="/Icon_assets/svg/search-organization-icon.svg"
              alt="Search"
              className="search-image"
            />
          </div>
          {
            taxonomyQuestionListData?.results?.length && (<div className="clear-filter-link">
            <a href="#" onClick={(event) => {clearSelectedOption(event)}}>Clear</a></div>)
          }
          <div className='next-q-list' id="questionListDropDown">
            <InfiniteScroll
              dataLength={(taxonomyQuestionListData?.results?.length)?taxonomyQuestionListData.results.length:0}
              next={() => { }}
              hasMore={false}
              loader={<SkeletonTable rows={10} />}
              scrollableTarget="questionListDropDown"
            >
              {
                taxonomyQuestionListData?.results?.length ? (
                  <GenerateItemList taxonomyQuestionListData={taxonomyQuestionListData.results} data={data} getTaxonomySelection={getTaxonomySelection}/>
                ):(
                  <nav className='no-answer-list-section'>No record found</nav>
                )
              }
            </InfiniteScroll>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default TaxonomyQuestionList;