import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Label, Row, FormGroup, Input, Col, CustomInput, UncontrolledTooltip } from "reactstrap";
import ADspinner from "../../Loader/ADspinner";
import BuilderAnswer from "./BuilderAnswer";
import BuilderQuestion from './BuilderQuestion';
import BuilderToast from './BuilderToast';
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './index.scss';
import PopUpModal from '../../PopUpModal';
import BuilderDeleteModal from './BuilderDeleteModal';
import { RTRBuilderResponseToJSON, RTRBuilderTaxononyResponse } from '../../../utils/parsers';

const Builder = (props) => {

  const QuestionList = React.memo(function QuestionList({ questionSectionData }) {
    return questionSectionData.map((question, index) => {
      return (
        <Draggable draggableId={`question-${question.ID}`} index={index}>
          {provided => (
            <div ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`capsule ${selectedQues[0]?.ID === question.ID ? 'capsule-selected' : ''}`} key={`question-${question.ID}`} onClick={() => { validateQuesionAnsList(questionObject); displaySelectedQuestion(question.ID); }}>
              <img src={ICONS.DRAG_ENABLED} alt="drag icon" className='first-icon' />
              <div>{`Question ${question.ID}`}</div>
              <img src={getIsError(question) ? ICONS.VALID_ICON : ICONS.ERROR_ICON} alt={"valid icon"} className='second-icon' />
            </div>
          )}
        </Draggable>
      );
    })
  });

  const ToastList = React.memo(function ToastList({ toastSectionData }) {
    return toastSectionData.map((toast, index) => {
      return (
        <Draggable draggableId={toast.id} index={index}>
          {provided => (
            <div ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`capsule ${selectedToastData[0]?.id === toast.id ? 'capsule-selected' : ''}`} key={`toast-${toast.id}`} onClick={() => { validateToastList(toastSectionData); displaySelectedToast(toast.id) }}>
              <img src={ICONS.DRAG_ENABLED} alt="drag icon" className='first-icon' />
              <div>{`Toast ${index + 1}`}</div>
              <img src={!getIsToastError(toast) ? ICONS.ERROR_ICON : ICONS.VALID_ICON} alt={"valid icon"} className='second-icon' />
            </div>
          )}
        </Draggable>
      );
    })
  });

  const isRTRSaving = useSelector((state) => state.realtimeResearch.surveyLoading);
  const taxonomyAnswerListData = useSelector((state) => state.surveyData.taxonomyAnswerListData);

  const RTR_TYPE = {
    PROGRAMMATIC: "Programmatic",
    INVISIBLY: "Invisibly",
    BOTH: "Both"
  }

  const ICONS = {
    DELETE: "/Icon_assets/svg/delete-icon.svg",
    COPY: "/Icon_assets/svg/copy-icon.svg",
    ARROW_DOWN: "/Icon_assets/svg/next-question-icon.svg",
    ARROW_UP: "/Icon_assets/svg/toggle-up.svg",
    VALID_ICON: '/Icon_assets/svg/valid-tick-icon.svg',
    ERROR_ICON: '/Icon_assets/svg/error-icon.svg',
    DRAG: "/Icon_assets/svg/Drag.svg",
    DRAG_ENABLED: "/Icon_assets/svg/Drag_enabled.svg",
  };

  const QUESTION_TYPE = {
    SINGLE_CHOICE: 'Single Choice',
    MULTIPLE_CHOICE: 'Multiple Choice',
  }

  const SHUFFLE_TYPE = {
    NONE: "None",
    RANDOM: "Random",
    REVERSE: "Reverse"
  }

  const [displayQuestionBranchModal, setDisplayQuestionBranchModal] = useState(false);
  const [errorQues, setErrorQues] = useState({});
  const [errorToast, setErrorToast] = useState({});
  const [nextQuestion, setNextQuestion] = useState();
  const [nextQuestionDropdownData, setNextQuestionDropdownData] = useState([]);
  const [nextOption, setNextOption] = useState()
  const [displayToastPanel, setDisplayToastPanel] = useState(false);
  const [displayQuestionsPanel, setDisplayQuestionsPanel] = useState(false);
  const [Questions, setQuestions] = useState()
  const [toasts, setToasts] = useState();
  const [selectedQues, setSelectedQues] = useState([]);
  const [destinationQues, setDestinationQues] = useState([]);
  const [displayQuestion, setDisplayQuestion] = useState(false);
  const [selectedToastData, setSelectedToastData] = useState([]);
  const [displayToast, setDisplayToast] = useState(false);

  // set props data here
  const [toastSectionData, setToastSectionData] = useState([]);
  // populating this object based on props
  const [isDeleted, setIsDeleted] = useState(0);
  const [optionAdded, setOptionAdded] = useState(0);
  const [oldButton, setOldButton] = useState("");
  const [answerIdx, setAnswerIdx] = useState(0);
  const [deleteOption, setDeleteOption] = useState('');
  const [deleteRecord, setDeleteRecord] = useState('');
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [oldButtonURL, setOldButtonURL] = useState("");
  const [isToastDeleted, setIsToastDeleted] = useState('Toast0');
  const [questionObject, setQuestionObject] = useState([]);
  const [isQuestionAdded, setIsQuestionAdded] = useState(false);
  const [isToastAdded, setIsToastAdded] = useState(false);
  const [isQuestionEdit, setIsQuestionEdit] = useState(false);
  const [initiator, setInitiator] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [questionJson, setQuestionJson] = useState([]);
  const [toastJson, setToastJson] = useState([]);
  const [isQuestionIncomplete, setQuestionIncomplete] = useState(false);
  const asset = useSelector((state) => state.surveyData.asset);
  const invisiblyAsset = useSelector((state) => state.surveyData.invisiblyAsset);
  const questionsRef = useRef();
  const toastsRef = useRef();
  const [issQuestionCopied, setIsQuestionCopied] = useState()
  const [isToastCopied, setIsToastCopied] = useState()
  const [isQuestionDnD, setIsQuestionDnD] = useState()
  const [isAnswerDnD, setIsAnswerDnD] = useState()
  const [questionDnDMetadata, setQuestionDnDMetadata] = useState()
  const [questionModalDnD, setQuestionModalDnD] = useState(false);
  const [taxonomyAnswerListOption, setTaxonomyAnswerListOption] = useState([]);
  const { surveyName,
    editCampaign,
    isActiveOrPaused,
    adLoading,
    // questionJson,
    // toastJson,
    goToPreviewScreen,
    rtrType
  } = props;

  useEffect(() => {
    if (rtrType !== RTR_TYPE.BOTH) {
      if (asset && asset.frames) {
        let data;
        if (Object.keys(asset?.frames).length) {
          data = RTRBuilderResponseToJSON(asset.frames.frame_1);
          if (!(data?.toasts.length)) {
            let toastData = {
              button_copy: "Shop now",
              button_pixel: "",
              button_url: "https://www.invisibly.com",
              id: "Toast1",
              subtitle: "You can learn more about our products by shopping on our site.",
              title: "Thanks for your feedback!",
              toggle_survey: false
            }
            data.toasts.push(toastData);
          }
        } else {
          const frameData = {
            "group_1.Q1": undefined,
            "group_1.Q1_ANS2": undefined,
            "group_1.Q1_ANS3": undefined,
            "group_26.button_component_1": "Shop now",
            "group_26.text_component_1": "Thanks for your feedback!",
            "group_26.text_component_2": "You can learn more about our products by shopping on our site.",
            "group_26.toast_button_link": "https://www.invisibly.com"
          }
          data = RTRBuilderResponseToJSON(frameData);
          data.questions[0].question_type = "SS";
          data.questions[0].shuffle_type = "None";
        }
        if (asset?.taxonomy_mapping) {
          const newDataObjet = RTRBuilderTaxononyResponse(data.questions, asset.taxonomy_mapping);
          setQuestionJson(newDataObjet);
        } else {
          setQuestionJson(data.questions);
        }
        setToastJson(data.toasts);
        questionsRef.current = JSON.parse(JSON.stringify(data.questions))
        toastsRef.current = JSON.parse(JSON.stringify(data.toasts))
      }
    } else {
      if (asset && asset.frames && asset.frames.frame_1) {
        console.log("Both", asset.frames.frame_1);
        if (asset.frames.frame_1) {
          const data = RTRBuilderResponseToJSON(asset.frames.frame_1)
          if (asset?.taxonomy_mapping) {
            const newDataObjet = RTRBuilderTaxononyResponse(data.questions, asset.taxonomy_mapping);
            setQuestionJson(newDataObjet);
          } else {
            setQuestionJson(data.questions);
          }
          setToastJson(data.toasts);
          questionsRef.current = JSON.parse(JSON.stringify(data.questions))
          toastsRef.current = JSON.parse(JSON.stringify(data.toasts))
        }
      }
    }
  }, [asset, invisiblyAsset]);

  useEffect(() => {
    if (Object.keys(toastJson).length || toastJson?.length) {
      setToastSectionData(toastJson);
      setDisplayToastPanel(toastJson.length > 0);
    }
  }, [toastJson])

  //if there is any change in questionJson & toastJson
  useEffect(() => {
    if (questionJson && questionJson.length) {
      setDisplayQuestionsPanel(questionJson.length > 0);
      setQuestionObject(questionJson);
      // if (props.backFromPreview) {
      //   setQuestionObject(questionJson)
      // } else {
      //   var ques = [];
      //   for (var j = 0; j < questionJson.length; j++) {
      //     var data = [];
      //     var obj = {};
      //     obj.ID = questionJson[j].ID;
      //     obj.question = questionJson[j].question;
      //     obj.shuffle_type = questionJson[j].shuffle_type;
      //     obj.question_type = questionJson[j].question_type;
      //     obj.next_question = (/^\d+$/.test(questionJson[j].next_question)) ? `Question ${questionJson[j].next_question}` : questionJson[j].next_question;

      //     if (props.fromCSV) {

      //       for (var i = 1; i <= 6; i++) {
      //         var val = (Object.fromEntries(Object.entries(questionJson[j]).filter(([key]) => key.includes(`answer${i}`))));
      //         // check if atleast one the value should be populated else donot add the answer.
      //         if (!Object.values(val).every(x => (x === null || x === '' || x === undefined))) {
      //           val.id = `answer${i}`
      //           val[`answer${i}_toggle`] = false;
      //           if (/^\d+$/.test((val[`answer${i}_branch`]))) {
      //             val[`answer${i}_branch`] = `Question ${val[`answer${i}_branch`]}`
      //           }
      //           data.push(val);
      //         }
      //       }
      //       obj.answer = data;

      //       if (obj.answer.length === 0) {
      //         obj.answer = [
      //           {
      //             answer1: undefined,
      //             answer1_branch: "",
      //             answer1_pixel: "",
      //             answer1_toggle: false,
      //             id: "answer1",
      //           },
      //           {
      //             answer2: undefined,
      //             answer2_branch: "",
      //             answer2_pixel: "",
      //             answer2_toggle: false,
      //             id: "answer2"
      //           }]
      //       }

      //     } else {
      //       obj.answer = questionJson[j].answer || [
      //         {
      //           answer1: undefined,
      //           answer1_branch: "",
      //           answer1_pixel: "",
      //           answer1_toggle: false,
      //           id: "answer1",
      //         },
      //         {
      //           answer2: undefined,
      //           answer2_branch: "",
      //           answer2_pixel: "",
      //           answer2_toggle: false,
      //           id: "answer2"
      //         }];
      //     }
      //     ques.push(obj);
      //   }
      //   setQuestionObject(ques);
      // }
    }
  }, [questionJson])

  useEffect(() => {
    if (questionObject.length) {
      if (isQuestionIncomplete) {
        setQuestionIncomplete(false);
        return;
      }
      if (isQuestionAdded) {
        // THIS LOGIC BREAKS FOR DELETE QUESTION WHEN DELETED OPTION WITHOUT SEQUENCE
        displaySelectedQuestion(questionObject.length)
      } else if (!isQuestionEdit && !issQuestionCopied && !isQuestionDnD && !isAnswerDnD && !displayQuestion) {
        displaySelectedQuestion(1)
        // newly added question to remain in focus
        if ((selectedQues[0] &&
          selectedQues[0].answer.every((d, idx) => selectedQues[0].answer[`answer${idx + 1}`] === undefined)
          && selectedQues[0].question !== undefined)) {
          displaySelectedQuestion(selectedQues[0].ID)
        }
      }
      if (isQuestionDnD) {
        displaySelectedQuestion(isQuestionDnD)
        setIsQuestionDnD(undefined)
      }
      if (isAnswerDnD) {
        displaySelectedQuestion(isAnswerDnD)
        setIsAnswerDnD(undefined)
      }
      if (issQuestionCopied) {
        displaySelectedQuestion(issQuestionCopied)
        setIsQuestionCopied(undefined) //reset copy state
      }
      if (isDeleted > 0) {
        displaySelectedQuestion(isDeleted)
      }
      else if (isDeleted === 0 && optionAdded !== 0) {
        displaySelectedQuestion(optionAdded)
      }
    }
  }, [questionObject]);

  useEffect(() => {
    // THIS LOGIC BREAKS FOR DELETE TOAST WHEN DELETED OPTION WITHOUT SEQUENCE
    if (toastSectionData.length) {
      if (isToastAdded) { //for to add new
        displaySelectedToast(toastSectionData[toastSectionData.length - 1].id);
        setIsToastAdded(false) //reset isToastAdded
      } else if (+(isToastDeleted.replace(/[^\d.]/g, '')) > 0) { //for delete
        displaySelectedToast(isToastDeleted);
        setIsToastDeleted("Toast0") //reset isToastDeleted 
      } else { //for clone
        if (isToastCopied) {
          displaySelectedToast(isToastCopied);
          setIsToastCopied(undefined) //reset copy state
        }
      }
    }
  }, [toastSectionData]);

  const handleQuestionInputChange = (e, data, component, isToggle, toggleVal) => {
    e.preventDefault()
    setIsQuestionEdit(true);
    setIsDeleted(data.ID)
    if (component === 'answer') {
      let changedData = data;
      var key1 = e.target.name;
      var val = e.target.value;

      if (isToggle === 'toggle') {
        let toggleData = data.answer.filter(f => f.id === e.target.name.split('_')[0]);
        toggleData[0][e.target.name] = toggleVal;
        if (toggleVal === false) {
          toggleData[0][e.target.name.split('_')[0] + "_pixel"] = '';
        }
      }

      const updatedAnswer = changedData.answer.map(obj => {
        for (var key in obj) {
          if (obj.hasOwnProperty(key1)) {
            if (key1.indexOf('toggle') === -1) {
              obj[key1] = val;
            }
            return obj;
          }
        }
        return obj;
      });

      const newArr = questionObject.map(obj => {
        if (+obj.ID === +data.ID) {
          return changedData;
        }
        return obj;
      });

      setQuestionObject(newArr);
    } else {
      let changedData = data;
      changedData[e.target.name] = e.target.value;
      const newArr = questionObject.map(obj => {
        if (+obj.ID === +data.ID) {
          return changedData;
        }
        return obj;
      });

      setQuestionObject(newArr);
    }

  }

  const getTaxonomySelection = (event, data, name, item, answerId) => {
    let changedData = data;
    if (name === "taxonomy_question") {
      changedData[name] = item.id;
      changedData["taxonomy"] = item.taxonomy;
      changedData["taxonomy_question_label"] = item?.display_value;
      changedData?.answer.forEach((dataObject) => {
        if (dataObject?.taxonomy) {
          delete dataObject.taxonomy;
          delete dataObject.taxonomy_answer;
          delete dataObject.taxonomy_answer_label;
        }
      });
      const answerListOption = taxonomyAnswerListData?.results.filter((data, index) => data.taxonomy === item.taxonomy);
      setTaxonomyAnswerListOption(answerListOption);
    } else {
      changedData?.answer?.forEach((ans, index) => {
        if (ans.id === answerId) {
          ans[name] = item.id;
          ans["taxonomy"] = item.taxonomy;
          ans["taxonomy_answer_label"] = item?.display_value;
        }
      });
    }
    const newArr = questionObject.map(obj => {
      if (+obj.ID === +data.ID) {
        return changedData;
      }
      return obj;
    });
    setQuestionObject(newArr);
  }

  const clearTaxonomySelection = (event, listDropdown, data, answerId) => {
    let changedData = data;
    event.preventDefault();
    if (listDropdown === 'questionlist') {
      delete changedData.taxonomy;
      delete changedData.taxonomy_question;
      delete changedData.taxonomy_question_label;
    } else {
      changedData?.answer?.forEach((ans, index) => {
        if (ans.id === answerId) {
          delete ans.taxonomy;
          delete ans.taxonomy_answer;
          delete ans.taxonomy_answer_label;
        }
      });
    }
    const newArr = questionObject.map(obj => {
      if (+obj.ID === +data.ID) {
        return changedData;
      }
      return obj;
    });
    setQuestionObject(newArr);
  }

  const filterAnserListData = (serachText) => {
    const currentAnswerListOption = taxonomyAnswerListData?.results.filter((data, index) => data.taxonomy === selectedQues[0].taxonomy);
    const filterAnswerListOption = currentAnswerListOption.filter((data, index) => (data.display_value).toLowerCase().includes(serachText.toLowerCase()));
    setTaxonomyAnswerListOption(filterAnswerListOption);
  }

  const handleToastInputChange = (e, data, toggleSurvey, toggleVal) => {
    let changedData = JSON.parse(JSON.stringify(data));
    changedData[e.target.name] = e.target.value;

    if (toggleSurvey === "toggle_survey") {
      changedData[e.target.name] = toggleVal;
      if (toggleVal) {
        // changedData[]
        // update url and button text
        setOldButton(data.button_copy);
        setOldButtonURL(data.button_url);
        changedData.button_copy = "See Survey Result"
        changedData.button_url = window.location.origin + '/SurveyResult'

      } else {
        changedData.button_copy = oldButton;
        changedData.button_url = oldButtonURL;
      }

    }
    const newArr = toastSectionData.map(obj => {
      if (obj.id === data.id) {
        return changedData;
      }
      return obj;
    });
    setIsToastDeleted(data.id);
    setToastSectionData(newArr);
  }

  const deleteSelectedAns = (data, index) => {
    setIsDeleted(data.ID)
    setOptionAdded(0)
    // remove selected ans from question
    let changedData = data;
    let d = questionObject.filter((a) => {
      if (a.ID === data.ID) {
        return (a)
      }
    })[0].answer.filter((q, idx) => { return idx !== index })
    let answerArr = [];
    d.filter((a, index) => {
      let ansObj = {};
      let sorted = Object.keys(a).sort().reduce(function (result, key) {
        result[key] = a[key];
        return result;
      }, {});
      let val = Object.values(sorted);
      ansObj[`answer${index + 1}`] = val[0]
      ansObj[`answer${index + 1}_branch`] = val[1]
      ansObj[`answer${index + 1}_pixel`] = val[2]
      ansObj[`answer${index + 1}_toggle`] = val[3]
      ansObj[`id`] = `answer${index + 1}`
      answerArr.push(ansObj)
    })

    changedData.answer = answerArr;

    const newArr = questionObject.map(obj => {
      if (+obj.ID === +data.ID) {
        return changedData;
      }
      return obj;
    });

    setQuestionObject(newArr);
    setDisplayDeleteModal(false);
    setDeleteOption("");
    setDeleteRecord("");
  }

  const deleteSelectedData = () => {
    if (deleteOption === 'question') {
      deleteSelectedQuestion(deleteRecord)
    } else if (deleteOption === 'toast') {
      deleteSelectedToast(deleteRecord);
    } else if (deleteOption === "answer") {
      deleteSelectedAns(deleteRecord, answerIdx);
    }
  }

  const deleteSelectedQuestion = (data) => {
    //remove deleted
    let updatedQuestions = questionObject.filter((q) => {
      return +q.ID !== +data.ID
    });

    updatedQuestions = updatedQuestions.map((question, i) => {
      let data = question;
      if ((updatedQuestions.length - 1) === i) { //if last question
        data.next_question = `Toast 1`
      } else {
        data.next_question = `Question ${i + 2}`
      }
      return data
    })

    //re-order the list
    updatedQuestions.forEach((r, idx) => r.ID = idx + 1);
    //update the records
    updatedQuestions.forEach((ques, index) => {
      ques.answer.forEach((a, idx) => {
        ques.answer[idx][`answer${idx + 1}_branch`] = ''
      })
    })
    const newSelectedId = (data.ID - 1)
    setIsQuestionAdded(false);
    setIsDeleted(newSelectedId);
    setQuestionObject(updatedQuestions);
    setDisplayDeleteModal(false);
    setDeleteOption("");
    setDeleteRecord("");
  }

  const deleteSelectedToast = (data) => {
    // //remove deleted
    var removedToast = toastSectionData.filter((q) => {
      return q.id !== data.id
    });


    removedToast.filter((r, idx) => r.id = `Toast${idx + 1}`);

    let deletedId = +(data.id.replace(/[^\d.]/g, '')) >= removedToast.length ? removedToast[removedToast.length - 1].id :
      removedToast.length >= 2 ? (data.id) : 'Toast1';

    setIsToastDeleted(deletedId);
    setToastSectionData(removedToast);

    let updatedQuestions = JSON.parse(JSON.stringify(questionObject));

    updatedQuestions = updatedQuestions.map((data, i) => {
      if ((updatedQuestions.length - 1) === i) { //if last question
        data.next_question = `Toast 1`
      } else {
        data.next_question = `Question ${i + 2}`
      }
      return data
    })

    updatedQuestions.forEach((ques, index) => {
      ques.answer.forEach((a, idx) => {
        ques.answer[idx][`answer${idx + 1}_branch`] = ''
      })
    })


    setQuestionObject(updatedQuestions);
    setDisplayDeleteModal(false);
    setDeleteOption("");
    setDeleteRecord("");
  }

  const copySelectedQuestion = (e, data) => {
    e.preventDefault()
    const id = data.ID + 1
    var newQuestion = { ...data, ID: id }
    var newData = JSON.parse(JSON.stringify(questionObject));
    newData.splice(id - 1, 0, newQuestion)
    newData = newData.map((question, i) => {
      return { ...question, ID: i + 1 }
    })

    setQuestionObject(newData);
    setIsQuestionCopied(id)
  }

  const copySelectedToast = (e, data) => {
    e.preventDefault()
    let newId = selectedToastData[0].id.replace(/[^\d.]/g, '')
    const id = `Toast${+newId + 1}`
    var newToast = { ...data, id: id }
    let newData = JSON.parse(JSON.stringify(toastSectionData));
    newData.splice(parseInt(newId), 0, newToast)
    newData = newData.map((toast, i) => {
      return { ...toast, id: `Toast${i + 1}` }
    });
    setToastSectionData(newData);
    setIsToastCopied(id)
  }

  const showDeleteModal = (op, data, index) => {
    setAnswerIdx(index);
    setDisplayDeleteModal(true);
    setDeleteOption(op);
    setDeleteRecord(data)
  }

  const closeDeleteModal = () => {
    setDisplayDeleteModal(false);
  }

  const toggleToastPanel = () => {
    setOldButton("");
    setOldButtonURL("");
    setDisplayToastPanel(!displayToastPanel)
  }

  const toggleQuestionPanel = () => {
    setDisplayQuestionsPanel(!displayQuestionsPanel);
  }

  const addNewAnswer = (data, answer) => {
    //setOptionAdded(data.ID);
    // caaled when add answer is clicked in the question
    setIsDeleted(0);
    let questionsData = JSON.parse(JSON.stringify(questionObject));
    var ansData = JSON.parse(JSON.stringify(answer));
    let ansObj = {}
    ansObj[`answer${answer.length + 1}`] = ''
    ansObj[`answer${answer.length + 1}_branch`] = ''
    ansObj[`answer${answer.length + 1}_pixel`] = ''
    ansObj[`id`] = `answer${answer.length + 1}`
    ansData.push(ansObj);
    var filterData = questionsData.filter(d => +d.ID === +data.ID)
    filterData[0].answer = ansData;
    setQuestionObject(questionsData);
    setSelectedQues(filterData)
  }

  useEffect(() => {
    questionObject.map((ques) => {
      setErrorQues({ [ques.ID]: getIsError(ques) })
    })
    const checkIsValid = validateForm()
    setIsValid(checkIsValid)
  }, [questionObject])

  useEffect(() => {
    toastSectionData.map((toast) => {
      setErrorToast({ [toast.id]: getIsToastError(toast) })
    })
    const checkIsValid = validateForm()
    setIsValid(checkIsValid)
  }, [toastSectionData])

  const validateForm = () => {
    //check question validation
    const isQuestionValid = questionObject.every((ques) => {

      if (asset.template_type?.id === 9) {
        return ((ques?.question !== "" && ques?.question !== undefined && ques?.question.length <= 80) && ques?.answer?.every((ans, index) => {
          let answer = ques.answer[index];
          let lengthToBeCompared = index === 0 ? 27 : ques.answer[0].answer1?.length ? (28 - ques.answer[0].answer1?.length) : 27
          return (answer[ans.id] !== '' && answer[ans.id] !== undefined) ? !(answer[ans.id]?.length > lengthToBeCompared) : false;
        }))
      } else {
        return ((ques?.question !== "" && ques?.question !== undefined) && ques?.answer?.every((ans, index) => {
          let answer = ques.answer[index];
          return (answer[ans.id] !== '' && answer[ans.id] !== undefined)
        }))
      }
    })
    //check toast validation
    const isToastValid = toastSectionData.every((toast) => {
      return ((toast?.button_copy !== "" && toast?.button_copy !== undefined) && (toast?.title !== "" && toast?.title !== undefined) && (toast?.button_url !== "" && toast?.button_url !== undefined))
    })

    return (isQuestionValid && isToastValid);
  }

  const getIsAnyError = () => (!Object.values(errorQues).every(err => err)) || (!Object.values(errorToast).every(err => err))

  const addNewQuestion = () => {
    //add new question in the questionjson and add two answers by default
    var newQuestion =
    {
      ID: (questionObject.length + 1),
      answer: [
        {
          answer1: undefined,
          answer1_branch: "",
          answer1_pixel: "",
          answer1_toggle: false,
          id: "answer1",
        },
        {
          answer2: undefined,
          answer2_branch: "",
          answer2_pixel: "",
          answer2_toggle: false,
          id: "answer2"
        }],
      next_question: "",
      question: undefined,
      question_type: "SS",
      shuffle_type: "None"
    }
    var newData = [...questionObject];
    if (rtrType === "Invisibly") {
      newData[newData.length - 1].next_question = ""
    }
    newData.push(newQuestion)

    setQuestionObject(newData);
    setIsQuestionAdded(true);
  }

  const addNewToast = () => {
    // for now have assigned id as last id+1 
    // later need to change this logic
    let newId = toastSectionData[toastSectionData.length - 1].id.replace(/[^\d.]/g, '')
    var newToast = {
      "id": `Toast${toastSectionData.length + 1}`,
      "title": undefined,
      "subtitle": undefined,
      "button_copy": undefined,
      "button_url": undefined,
      "button_pixel": undefined
    }
    var newData = JSON.parse(JSON.stringify(toastSectionData));
    newData.push(newToast)
    setToastSectionData(newData);
    setIsToastAdded(true);
  }

  const displaySelectedQuestion = (id) => {
    let selectedQuestion = questionObject.filter((q) => +q.ID === +id);
    setSelectedQues(selectedQuestion);
    setDisplayQuestion(true);
    setDisplayToast(false);
    setIsToastAdded(false);
    setIsQuestionAdded(false);
    setSelectedToastData({});
    setIsDeleted(0);
  }

  const validateQuesionAnsList = (questionObject) => {
    let isErrorInQuestion = false;
    const newData = questionObject.map((quesAnsObj) => {
      if (quesAnsObj?.question === undefined) {
        quesAnsObj.question = '';
        isErrorInQuestion = true;
      }
      quesAnsObj?.answer?.forEach((ans, index) => {
        if (ans[`answer${index + 1}`] === undefined) {
          ans[`answer${index + 1}`] = '';
          isErrorInQuestion = true;
        }
      });
      return quesAnsObj;
    });

    if (isErrorInQuestion) {
      setQuestionIncomplete(true);
      setQuestionObject(newData);
    }
  }

  const displaySelectedToast = (id) => {
    let selectedToast = toastSectionData.filter((t) => t.id === id)
    setSelectedToastData(selectedToast)
    setDisplayQuestion(false);
    setDisplayToast(true);
    setIsQuestionAdded(false);
    setSelectedQues({});
  }

  const validateToastList = (ToastDataObject) => {
    let isErrorInToast = false;
    const newData = ToastDataObject.map((toastInfoObj) => {
      toastInfoObj.title = (toastInfoObj?.title === undefined) ? '' : toastInfoObj.title;
      toastInfoObj.button_copy = (toastInfoObj?.button_copy === undefined) ? '' : toastInfoObj.button_copy;
      toastInfoObj.button_url = (toastInfoObj?.button_url === undefined) ? '' : toastInfoObj.button_url;
      isErrorInToast = (toastInfoObj?.title === '' || toastInfoObj.button_copy === '' || toastInfoObj.button_url === '') ? true : false;
      return toastInfoObj;
    });
    if (isErrorInToast) {
      setToastSectionData(newData);
    }
  }

  const closeQuestionBranchModal = () => {
    setDisplayQuestionBranchModal(false);
  }

  const changeNextQues = (selectedQues1, nextQuestion) => {
    setIsDeleted(selectedQues1)
    setNextQuestion(selectedQues1);
    setNextOption(nextQuestion);
    if (!(selectedQues[0].answer.every((d, idx) => selectedQues[0].answer[idx][`answer${idx + 1}_branch`] === ''))) {
      setDisplayQuestionBranchModal(true);
    } else {
      // If the question is newly added don't show conformation popup
      confirmQuestionChange(selectedQues1, nextQuestion);
    }
  }

  const confirmQuestionChange = (nextQue, nextOpt) => {
    setQuestionObject(questionObject.map(item => {
      if (item.ID === nextQue) {
        return {
          ...item,
          next_question: `${nextOpt.mainLabel}`,
          answer: item.answer.map(ans => { return { ...ans, [`${ans.id}_branch`]: '' } })
        }
      }
      return item;
    }))

    closeQuestionBranchModal();
  }

  const resetAnswerBranching = (questionId) => {
    const newData = questionObject.map((quesAnsObj) => {
      if (quesAnsObj.ID === questionId) {
        quesAnsObj?.answer?.forEach((ans, index) => {
          ans[`${ans.id}_branch`] = '';
        });
      }
      return quesAnsObj;
    });
    setQuestionObject(newData);
  }


  const changeAnswerBranch = (selectedQues, dropdownSelectedItem, selectedAns) => {
    setIsDeleted(selectedQues?.ID)
    let updatedObject = questionObject.map((ques, index) => {
      if (ques.ID === selectedQues.ID) {
        ques.next_question = "";
        ques.answer = ques.answer.map(ans => {
          if (ans.id == selectedAns) {
            return { ...ans, [`${ans.id}_branch`]: dropdownSelectedItem.mainLabel }
          }
          return ans;
        })
        return ques
      }
      return ques
    })

    setQuestionObject(updatedObject)
  }

  const getIsError = (data) => {

    let a = data?.answer?.every((ans, index) => {
      let answer = data.answer[index];
      if (asset.template_type?.id === 9) {
        let lengthToBeCompared = index === 0 ? 27 : data.answer[0].answer1?.length ? (28 - data.answer[0].answer1?.length) : 27
        return answer[ans.id] !== '' ? !(answer[ans.id]?.length > lengthToBeCompared) : false;
      } else {
        return answer[ans.id] !== '';
      }
    })
    let isError = true;
    if (asset.template_type?.id === 9) {
      if (data?.question !== '' && a) {
        if (data?.question?.length === undefined) {
          isError = true;
        } else if (data?.question?.length > 80) {
          isError = false;
        }
      } else {
        isError = false;
      }
    } else {
      isError = data?.question !== '' && a;
    }
    return isError;
  }

  const getIsToastError = (data) => {
    const isError = data.button_copy !== '' &&
      data.title !== '' &&
      data.button_url !== '';
    return isError
  }

  useEffect(() => {
    setNextQuestionDropdownData([...questionObject.map(ques => {
      return {
        id: ques.ID,
        mainLabel: 'Question ' + ques.ID,
        subLabel: ques.question
      }
    }), ...toastSectionData.map(toast => {
      return {
        id: toast.id,
        mainLabel: `Toast ${toast.id.replace(/[^\d.]/g, '')}`,
        subLabel: toast.title
      }
    })])
  }, [questionObject, toastSectionData])

  useEffect(() => {
    if (selectedQues.length && taxonomyAnswerListData?.results) {
      const answerListOption = taxonomyAnswerListData?.results?.filter((data, index) => data.taxonomy === selectedQues[0].taxonomy);
      setTaxonomyAnswerListOption(answerListOption);
    }
  }, [selectedQues, taxonomyAnswerListData]);

  const onDragEndQuestion = (result) => {
    const { destination, source } = result;

    // dropped at an invalid location
    if (!destination) {
      return;
    }

    //dropped at the same location, to verify
    if (destination.droppableId === source.droppableId &&
      destination.index === source.index) {
      return;
    }

    //reorder toast ids
    let tempState = JSON.parse(JSON.stringify(questionObject));
    /**Store data to get prev reference start */
    let prevState = JSON.parse(JSON.stringify(questionObject));

    /**Store data to get prev reference end */
    tempState.splice(result.destination.index, 0, tempState.splice(result.source.index, 1)[0]);

    //re-arrange list
    tempState = tempState.map((question, i) => {
      return { ...question, ID: i + 1 }
    })

    let selectedQuestion = tempState.filter((q) => q.ID === destination.index + 1);
    let previousQuestion = tempState.filter((q) => q.ID === source.index + 1);
    setDestinationQues(previousQuestion);
    setQuestionDnDMetadata({ ...questionDnDMetadata, prevState: prevState, prevSelect: selectedQuestion })
    setQuestionObject(tempState);
    setIsQuestionDnD(selectedQuestion[0].ID)
    setSelectedQues(selectedQuestion)
    setSelectedToastData({});
    setDisplayQuestion(true);
    setDisplayToast(false);
    setIsToastAdded(false);
    setIsQuestionAdded(false);
    if (!(selectedQues[0].answer.every((d, idx) => selectedQues[0].answer[idx][`answer${idx + 1}_branch`] === '')) || !(previousQuestion[0].answer.every((d, idx) => previousQuestion[0].answer[idx][`answer${idx + 1}_branch`] === ''))) {
      setQuestionModalDnD(true);
    }
  }

  const handleConfirmDnDQuestion = () => {
    let tempState = JSON.parse(JSON.stringify(questionObject));
    tempState = tempState.map((question, i) => {
      let data = question;
      if (data.ID === selectedQues[0].ID || data.ID === destinationQues[0].ID) {
        if ((tempState.length - 1) === i) { //if last question
          data.next_question = `Toast 1`
        } else {
          data.next_question = `Question ${i + 2}`
        }
        data.answer.forEach((ans, index) => {
          ans[`answer${index + 1}_branch`] = ""
        })
      }
      return data
    })
    setQuestionObject(tempState);
    setIsQuestionDnD(questionDnDMetadata.prevSelect[0].ID)
    setSelectedQues(questionDnDMetadata.prevSelect)
    setQuestionModalDnD(false)
  }

  const handleCancelDnDQuestion = () => {
    let tempState = JSON.parse(JSON.stringify(questionDnDMetadata.prevState));
    tempState = tempState.map((question, i) => {
      return { ...question, ID: i + 1 }
    })
    setQuestionObject(tempState);
    setIsQuestionDnD(questionDnDMetadata.prevSelect[0].ID)
    setSelectedQues(questionDnDMetadata.prevSelect)
    setQuestionModalDnD(false)
  }

  const onDragEndToast = (result) => {
    const { destination, source } = result;

    // dropped at an invalid location
    if (!destination) {
      return;
    }

    //dropped at the same location, to verify
    if (destination.droppableId === source.droppableId &&
      destination.index === source.index) {
      return;
    }

    //reorder toast ids
    let tempState = JSON.parse(JSON.stringify(toastSectionData));
    tempState.splice(result.destination.index, 0, tempState.splice(result.source.index, 1)[0]);
    tempState.filter((r, idx) => r.id = `Toast${idx + 1}`);
    let selectedToast = tempState.filter((q) => q.id === `Toast${result.destination.index + 1}`)
    setSelectedToastData(selectedToast);
    setSelectedQues({});
    setDisplayQuestion(false);
    setDisplayToast(true);
    setIsToastAdded(false);
    setIsQuestionAdded(false);
    // removedToast.filter((r, idx) => r.id = `Toast${idx + 1}`);
    setToastSectionData(tempState);
  }

  const onDragEndAnswer = result => {
    const { destination, source, draggableId } = result;

    // dropped at an invalid location
    if (!destination) {
      return;
    }

    //dropped at the same location, to verify
    if (destination.droppableId === source.droppableId &&
      destination.index === source.index) {
      return;
    }

    //reorder toast ids
    let tempState = JSON.parse(JSON.stringify(questionObject));
    // get the specific question 
    let specificQ = tempState.filter((q) => +q.ID === +selectedQues[0]?.ID)[0]
    // under specificQ get the answer object
    let updatedAns = specificQ.answer;
    //swap answers
    updatedAns.splice(result.destination.index, 0, updatedAns.splice(result.source.index, 1)[0]);
    //assign back the swaped answer to specificQ

    updatedAns = updatedAns.map((ans, i) => {
      let update = Object.keys(ans).map((key, index) => {
        let newObj = {}
        if (key === "id") {
          newObj["id"] = `answer${i + 1}`
        } else {
          newObj[key.replace(/\d+/g, i + 1)] = ans[key]
        }
        return newObj
      })

      const result = Object.assign({}, ...update);
      return result
    })

    specificQ.answer = updatedAns

    // now update the specificQ to the question object
    setIsAnswerDnD(specificQ.ID)
    setSelectedQues([specificQ])
    setQuestionObject(tempState);
  }

  return (<>
    <div>
      <div className='right-container'>
        <div id="campaign-section">
          <div className="dashboard real-time">
            <section className="title-section dashboard-header-section">
              <>
                <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
                  <Button className="btn-transparent" onClick={() => props.handleBackClick({ questions: questionObject, toasts: toastSectionData })}>
                    <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
                  </Button>
                  <h2 id={"survey-header"}
                    className="mb-0 pl-4 secondary-title">
                    {`Analytics(${props.adName})`}</h2>
                  {props?.adName && (
                    <UncontrolledTooltip
                      placement="top"
                      className="ad-tool-tip brands-tip"
                      target={'survey-header'}
                      key={`survey-header`}
                    >
                      {`Analytics(${props.adName})`}
                    </UncontrolledTooltip>
                  )}
                </nav>

                <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
                  <div className="text-right form-group">
                    {<Button className={(props.adState !== 0 ? 'btn-draft-inactive' : 'btn-draft') + " in-btn-sm in-btn-info btn-export"}
                      id="btn-draft"
                      style={{ minWidth: '144px', minHeight: '48px', opacity: !isValid ? '0.3' : '' }}
                      disabled={(props.adState === 0) ? !isValid : (props.adState !== 0 || !isValid)}
                      onClick={(e) => {
                        setInitiator("Save")
                        props.handleSubmitRTRBuilder(e, questionObject, toastSectionData, "draft")
                      }}>
                      {initiator === 'Save' && isRTRSaving ?
                        <img
                          src={'/Icon_assets/svg/blue_loading_icon.svg'}
                          alt="Loading..."
                          className={`ad-spinner ad-spinner-lg`}
                        />
                        : (editCampaign && isActiveOrPaused) ? `Update` : `Save as Draft`}</Button>}

                    <Button className={(false ? 'btn-next' : 'btn-next-active') + " in-btn-sm in-btn-info btn-export ml-3"}
                      style={{ minWidth: '144px', minHeight: '48px', opacity: !isValid ? '0.3' : '' }}
                      id="btn-next-step"
                      disabled={!isValid}
                      onClick={(e) => {
                        setInitiator("Next")
                        if (props.adState === 0)
                          props.handleSubmitRTRBuilder(e, questionObject, toastSectionData, "next step")
                        else
                          goToPreviewScreen("next step");
                      }}>
                      {initiator === 'Next' && isRTRSaving ? <ADspinner size="large" /> : `Next Step`}</Button>
                  </div>
                </nav>

              </>
            </section>
            {/* page content */}
            <section className="parent-section">
              {/* left section */}
              <section className="side-navigation">
                <div className="heading-nav" onClick={toggleQuestionPanel}>
                  <img src={displayQuestionsPanel ? ICONS.ARROW_UP : ICONS.ARROW_DOWN} alt="arrow down" />
                  <Label className="lbl-question"> Questions</Label>
                </div>
                <Collapse isOpen={displayQuestionsPanel}>
                  <DragDropContext onDragEnd={onDragEndQuestion}>
                    <Droppable droppableId="question-drag-list">
                      {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          <QuestionList questionSectionData={questionObject} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {
                    questionObject.length < 25 &&
                    <Button className={(props.adState !== 0 ? 'disable-builder' : '') + " add-more-question"} onClick={addNewQuestion}>
                      <span>+</span></Button>
                  }
                </Collapse>
                {rtrType !== RTR_TYPE.INVISIBLY &&
                  <>
                    <hr style={{ marginBottom: "18px", marginTop: "16px", marginRight: "32px" }} />

                    <div className="heading-nav" onClick={toggleToastPanel}>
                      <img src={displayToastPanel ? ICONS.ARROW_UP : ICONS.ARROW_DOWN} alt="arrow down" />
                      <Label className="lbl-question"> Toasts</Label>
                    </div>
                    <Collapse isOpen={displayToastPanel}>
                      <DragDropContext onDragEnd={onDragEndToast}>
                        <Droppable droppableId="toast-drag-list">
                          {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <ToastList toastSectionData={toastSectionData} />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {
                        props.adSize === 0 ?
                          toastSectionData?.length < 6 &&
                          <Button className={(props.adState !== 0 ? 'disable-builder' : '') + " add-more-toast"} onClick={addNewToast}>
                            <span>+</span></Button>
                          :
                          toastSectionData?.length < 5 &&
                          <Button className={(props.adState !== 0 ? 'disable-builder' : '') + " add-more-toast"} onClick={addNewToast}>
                            <span>+</span></Button>
                      }
                    </Collapse>

                  </>
                }
              </section>
              {/* right section, Question section */}

              {displayQuestion &&
                <BuilderQuestion
                  onDragEndAnswer={onDragEndAnswer}
                  questionObject={questionObject}
                  data={selectedQues[0]}
                  deleteSelectedQuestion={deleteSelectedQuestion}
                  deleteSelectedAns={deleteSelectedAns}
                  handleInputChange={handleQuestionInputChange}
                  changeNextQues={changeNextQues}
                  addNewAnswer={addNewAnswer}
                  adState={props.adState}
                  displayQuestionBranchModal={displayQuestionBranchModal}
                  closeQuestionBranchModal={closeQuestionBranchModal}
                  confirmQuestionChange={confirmQuestionChange}
                  nextQuestionDropdownData={nextQuestionDropdownData}
                  changeAnswerBranch={changeAnswerBranch}
                  copySelectedQuestion={copySelectedQuestion}
                  showDeleteModal={showDeleteModal}
                  rtrType={rtrType}
                  nextQuestion={nextQuestion}
                  nextOption={nextOption}
                  resetAnswerBranching={resetAnswerBranching}
                  getTaxonomySelection={getTaxonomySelection}
                  clearTaxonomySelection={clearTaxonomySelection}
                  taxonomyAnswerListOption={taxonomyAnswerListOption}
                  filterAnserListData={filterAnserListData}
                  asset={asset}
                />
              }

              {displayToast && rtrType !== RTR_TYPE.INVISIBLY &&
                <BuilderToast
                  data={selectedToastData[0]}
                  deleteSelectedToast={deleteSelectedToast}
                  handleInputChange={handleToastInputChange}
                  toggleEnabled={toastSectionData.filter(f => f.toggle_survey)?.[0]}
                  dataLength={toastSectionData.length}
                  adState={props.adState}
                  adSize={props.adSize}
                  copySelectedToast={copySelectedToast}
                  showDeleteModal={showDeleteModal}
                  rtrType={rtrType}
                />
              }
            </section>
          </div>
        </div>
      </div>
    </div>
    <PopUpModal
      className={'csv-delete-confirm-modal delete-question question-dnd'}
      title={"Reset Answer Level Branching?"}
      openPopUp={questionModalDnD}
      setOpenPopUp={setQuestionModalDnD}
    >
      <Row>
        <Col lg="12" md="12" sm="12" className='content'>
          <p>
            There is answer level branching present. Arranging questions/toasts by drag-and-drop will reset all answer level branching.<br /><br />
            You have to manually update all answer level branching links.
          </p>
        </Col>
        <Col lg="12" md="12" sm="12" className='actions'>
          <Button
            className='in-btn-lg in-btn-info btn-replace btn-no btn btn-secondary'
            onClick={() => handleConfirmDnDQuestion()}
          >
            Yes
          </Button>
          <Button
            className='in-btn-link btn btn-link btn-cancel btn btn-secondary'
            onClick={() => handleCancelDnDQuestion()}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </PopUpModal>
    <PopUpModal
      className={'delete-rtr-ad-modal'}
      title={`Delete ${deleteOption}`}
      openPopUp={displayDeleteModal}
      setOpenPopUp={() => setDisplayDeleteModal(false)}
    >
      <BuilderDeleteModal
        data={deleteOption}
        deleteRecord={deleteRecord}
        deleteSelectedData={deleteSelectedData}
        cancelDelete={closeDeleteModal}
      />
    </PopUpModal>
  </>)

}

export default Builder;