import React from "react";
import "./index.scss";
import {getVideoStreamBrand} from "../../../../utils/VideoStreamingHelper";

const VideoStreaming = ({url, autoPlay}) => {
  const stream = getVideoStreamBrand(url);
  return (
    <>
      {stream.type === "youtube" ? 
        (<>
          <iframe 
            src={`https://www.youtube.com/embed/${stream.id}?autoplay=${autoPlay}&start=0`}
            frameborder='0'
            allow='autoplay; encrypted-media'
            allowfullscreen=""
            title='youtube video'
            width="100%"
            height="100%"
            controls="1"
          />
        </>) : 
        (stream.type === "vimeo") ? 
        (<>
          <iframe 
            src={`https://player.vimeo.com/video/${stream.id}?autoplay=${autoPlay}#t=0m0s`} 
            title='vimeo video' 
            width="100%"
            height="100%"
            webkitallowfullscreen 
            mozallowfullscreen 
            frameborder="0" 
            allow="autoplay; encrypted-media" 
            allowfullscreen=""
            autoplay
          />
        </>) : 
      (<>Currently not Supported</>)}
    </>
  );
};

export default VideoStreaming;