const ImageMapping = [
  {
    pagename: "signin",
    imageURL: "/Icon_assets/png/banner_signin.png",
    alt: "Activation Dashboard Sign in",
  },
  {
    pagename: "signup",
    imageURL: "/Icon_assets/png/banner_signup.png",
    alt: "Activation Dashboard Sign up",
  },
  {
    pagename: "forgotpassword",
    imageURL: "/Icon_assets/png/banner_forgot_password.png",
    alt: "Activation Dashboard forgot password",
  },
  {
    pagename: "changepassword",
    imageURL: "/Icon_assets/png/banner_forgot_password.png",
    alt: "Activation Dashboard change password",
  },
];

const getImageData = (name) => {
  return ImageMapping.find((res) => res.pagename === name);
};

export default getImageData;
