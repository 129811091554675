import ACTIONS from "../constants/actionTypes";

export const getPreviewRequestSuccess = (data) => {
  return {
    type: ACTIONS.GET_PREVIEW_REQUEST_SUCCESS,
    payload: data,
  };
};


export const getPreviewRequest = (data) => {
  return {
    type: ACTIONS.GET_PREVIEW_REQUEST,
    payload: data,
  };
};

export const getPreviewRequestFailure = (errors) => {
  return {
    type: ACTIONS.GET_PREVIEW_REQUEST_FAILURE,
    payload: errors,
  };
};


export const getExternalPreviewRequestSuccess = (data) => {
  return {
    type: ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST_SUCCESS,
    payload: data,
  };
};


export const getExternalPreviewRequest = (data) => {
  return {
    type: ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST,
    payload: data,
  };
};

export const getExternalPreviewRequestFailure = (errors) => {
  return {
    type: ACTIONS.GET_EXTERNAL_PREVIEW_REQUEST_FAILURE,
    payload: errors,
  };
};

export const uploadImageFailure = (errors) => {
  return {
    type: ACTIONS.GET_UPLOAD_IMAGE_FAILURE,
    payload: errors,
  }
}

export const uploadImageSuccess = (data, headerImage, logoImage) => {
  return {
    type: ACTIONS.GET_UPLOAD_IMAGE_REQUEST_SUCCESS,
    payload: data,
    isHeaderImage: headerImage,
    isLogoImage: logoImage
  }
}

export const uploadImageRequest = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_IMAGE_REQUEST,
    payload: data,
  }
}

// fire clear events post assignment to state
export const uploadImageRequestClear = () => {
  return {
    type: ACTIONS.GET_UPLOAD_IMAGE_REQUEST_CLEAR,
    payload: "",
    isHeaderImage: false,
    isLogoImage: false
  }
}

export const requestMimeTypeList = () => {
  return {
    type: ACTIONS.REQUEST_MIME_TYPE_LIST,
    payload: {}
  };
};


export const receiveMimeTypeList = payload => {
  return {
    type: ACTIONS.RECEIVE_MIME_TYPE_LIST,
    mimeTypeList: payload.mimeTypeList
  };
};



export const saveFilesFailure = (errors) => {
  return {
    type: ACTIONS.SAVE_FILE_FAILURE,
    payload: errors,
  }
}

export const saveFilesSuccess = (data) => {
  return {
    type: ACTIONS.SAVE_FILE_SUCCESS,
    payload: data,
  }
}

export const saveFileRequest = (data) => {
  return {
    type: ACTIONS.SAVE_FILE_REQUEST,
    payload: data,
  }
}

// fire clear events post assignment to state
export const saveFileRequestClear = (data) => {
  return {
    type: ACTIONS.SAVE_FILE_REQUEST_CLEAR,
    payload: data,
  }
}



export const uploadCSVFailure = (errors) => {
  return {
    type: ACTIONS.GET_UPLOAD_CSV_FAILURE,
    payload: errors,
  }
}

export const uploadCSVSuccess = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_CSV_REQUEST_SUCCESS,
    payload: data
  }
}

export const uploadCSVRequest = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_CSV_REQUEST,
    payload: data
  }
}

// fire clear events post assignment to state
export const uploadCSVRequestClear = () => {
  return {
    type: ACTIONS.GET_UPLOAD_CSV_REQUEST_CLEAR,
    payload: "",
  }
}

export const submitAdFailure = (errors) => {
  return {
    type: ACTIONS.POST_AD_REQUEST_FAILURE,
    payload: errors,
  }
}

export const submitAdSuccess = (data) => {
  return {
    type: ACTIONS.POST_AD_REQUEST_SUCCESS,
    payload: data
  }
}

export const submitAdRequest = (data) => {
  return {
    type: ACTIONS.POST_AD_REQUEST,
    payload: data
  }
}

// fire clear events post assignment to state
export const submitAdRequestClear = () => {
  return {
    type: ACTIONS.POST_AD_REQUEST_CLEAR,
    payload: "",
  }
}

export const activateAdRequest = (data) => {
  return {
    type: ACTIONS.ACTIVATE_AD_REQUEST,
    payload: data
  }
}

export const activateAdSuccess = (data) => {
  return {
    type: ACTIONS.ACTIVATE_AD_REQUEST_SUCCESS,
    payload: data
  }
}

export const activateAdFailure = (errors) => {
  return {
    type: ACTIONS.ACTIVATE_AD_REQUEST_FAILURE,
    payload: errors,
  }
}

// fire clear events post assignment to state
export const activateAdRequestClear = () => {
  return {
    type: ACTIONS.ACTIVATE_AD_REQUEST_CLEAR,
    payload: "",
  }
}

export const updateDraftedAdFailure = (errors) => {
  return {
    type: ACTIONS.UPDATE_DRAFTED_AD_FAILURE,
    payload: errors,
  }
}

export const updateDraftedAdRequestClear = () => {
  return {
    type: ACTIONS.UPDATE_DRAFTED_AD_REQUEST_CLEAR,
    payload: "",
  }
}

export const updateDraftedAdSuccess = (data) => {
  return {
    type: ACTIONS.UPDATE_DRAFTED_AD_SUCCESS,
    payload: data
  }
}

export const updateDraftedAdRequest = (data) => {
  return {
    type: ACTIONS.UPDATE_DRAFTED_AD_REQUEST,
    payload: data
  }
}

export const uploadToastFailure = (errors) => {
  return {
    type: ACTIONS.GET_UPLOAD_TOAST_FAILURE,
    payload: errors,
  }
}

export const uploadToastSuccess = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_TOAST_REQUEST_SUCCESS,
    payload: data
  }
}

export const uploadToastRequest = (data) => {
  return {
    type: ACTIONS.GET_UPLOAD_TOAST_REQUEST,
    payload: data
  }
}

export const uploadToastRequestClear = () => {
  return {
    type: ACTIONS.GET_UPLOAD_TOAST_REQUEST_CLEAR,
    payload: "",
  }
}

export const getCampaignByIdRequest = (data) => {
  return {
    type: ACTIONS.GET_CAMPAIGN_BY_ID_REQUEST,
    payload: data,
  }
}

export const getCampaignByIdSuccess = (data) => {
  return {
    type: ACTIONS.GET_CAMPAIGN_BY_ID_SUCCESS,
    payload: data,
  }
}

export const getCampaignByIdFailure = () => {
  return {
    type: ACTIONS.GET_CAMPAIGN_BY_ID_FAILURE,
    payload: "",
  }
}

export const getCampaignByIdClear = () => {
  return {
    type: ACTIONS.GET_CAMPAIGN_BY_ID_CLEAR,
    payload: "",
  }
}

export const getAdAssetsRequest = (data) => {
  return {
    type: ACTIONS.GET_AD_ASSET_REQUEST,
    payload: data,
  }
}

export const getAdAssetsSuccess = (data) => {
  return {
    type: ACTIONS.GET_AD_ASSET_SUCCESS,
    payload: data,
  }
}

export const getAdAssetsFailure = () => {
  return {
    type: ACTIONS.GET_AD_ASSET_FAILURE,
    payload: "",
  }
}

export const getAdAssetsClear = () => {
  return {
    type: ACTIONS.GET_AD_ASSET_REQUEST_CLEAR,
    payload: "",
  }
}

//invisibly adset
export const getInvisiblyAdAssetsRequest = (data) => {
  return {
    type: ACTIONS.GET_INVISIBLY_AD_ASSET_REQUEST,
    payload: data,
  }
}

export const getInvisiblyAdAssetsSuccess = (data) => {
  return {
    type: ACTIONS.GET_INVISIBLY_AD_ASSET_SUCCESS,
    payload: data,
  }
}

export const getInvisiblyAdAssetsFailure = () => {
  return {
    type: ACTIONS.GET_INVISIBLY_AD_ASSET_FAILURE,
    payload: "",
  }
}

export const getInvisiblyAdAssetsClear = () => {
  return {
    type: ACTIONS.GET_INVISIBLY_AD_ASSET_REQUEST_CLEAR,
    payload: "",
  }
}

export const getTaxonomyQuestionListRequest = (data) => {
  return {
    type: ACTIONS.GET_TAXONOMY_QUESTIONS_LIST_REQUEST,
    payload: data,
  }
}

export const getTaxonomyQuestionListSuccess = (data) => {
  return {
    type: ACTIONS.GET_TAXONOMY_QUESTION_LIST_SUCCESS,
    payload: data,
  }
}

export const getTaxonomyQuestionSearchListSuccess = (data) => {
  return {
    type: ACTIONS.GET_TAXONOMY_QUESTION_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

export const getTaxonomyAnswerListRequest = (data) => {
  return {
    type: ACTIONS.GET_TAXONOMY_ANSWER_LIST_REQUEST,
    payload: data,
  }
}

export const getTaxonomyAnswerListSuccess = (data) => {
  return {
    type: ACTIONS.GET_TAXONOMY_ANSWER_LIST_SUCCESS,
    payload: data,
  }
}

export const getTaxonomyListFailure = () => {
  return {
    type: ACTIONS.GET_TAXONOMY_LIST_FAILURE,
    payload: "",
  }
}

export const getTaxonomyListRequestClear = () => {
  return {
    type: ACTIONS.GET_TAXONOMY_LIST_REQUEST_CLEAR,
    payload: "",
  }
}