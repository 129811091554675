import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Button, FormGroup, Label, Input, Alert, Form, FormFeedback } from "reactstrap";
import "./index.scss";
import SideNavigation from '../../components/SideNavigation';
import Validator from "../../utils/validators";
import HeaderNavigation from '../../components/HeaderNavigation';
import Notifications from "../../components/Notifications";
import PopUpModal from "../../components/PopUpModal";
import {
  uploadProfilePic,
  uploadProfilePicClear,
  createUserFailureClear,
  uploadProfilePicFailureClear,
  updateUserRequest, updatePassword
} from "../../actions/userActions";
import ADspinner from "../../components/Loader/ADspinner";

const validatorsObj = new Validator();

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const profilePic = useSelector((state) => state.user.profilePic);
  const profilePicErrors = useSelector((state) => state.user.profilePicErrors);
  const errorMsg = useSelector((state) => state.user.errorMsg);
  const isUserUpdated = useSelector((state) => state.user.isUserUpdated);
  const isUserUpdating = useSelector((state) => state.user.isUserUpdating);
  const isprofilePicUploaded = !!profilePic; // need to change this aflag
  const userData = useSelector((state) => state.auth.userData);
  const user_role = userData.organization_role;
  const [validators, setstate] = useState(validatorsObj);
  const data = JSON.parse(localStorage.getItem('user'));
  const initialState = {
    email: data.email,
    id: data.id,
    profile_pic: data.profile_pic || "",
    fullname: data.name,
    new_password: "",
    password: "",
    confirmPasswordVal: "",
    passwordVal: ""
  }
  const [picUploaded, setPicUploaded] = useState(false);
  const [inputValues, setInputValues] = useState(initialState);
  const [serverMsg, setserverMsg] = useState(false);
  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const [openPopUp, setOpenPopUp] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const updateUser = (e) => {
    e.preventDefault();
    if (changeName) {
      // call code to update Name of user
      var payload = { name: inputValues.fullname, id: inputValues.id }
      dispatch(updateUserRequest(payload));
    } else {
      // set code to change password of user
      var payload = {
        new_password: inputValues.new_password,
        current_password: inputValues.password,
        email: inputValues.email,
        id: inputValues.id
      };
      dispatch(updatePassword(payload));
    }
  }
  const handleUploadFile = (e) => {
    setPicUploaded(true);
    if (e.target.files.length !== 0) {
      dispatch(uploadProfilePic(e.target.files[0]));
    }
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    if (e.target.id === 'newPassword') {
      const checkValidation = validators.password.valid;
      setIsValid(checkValidation);
    }
    if (e.target.id === 'currentPassword') {
      const checkValidation = validators.new_password.valid;
      setIsValid(checkValidation);
    }
  };

  const closeModal = () => {
    setstate(new Validator());
    setOpenPopUp(false);
    setInputValues(initialState);
    setIsValid(false);
  }
  // added code for profile picture
  useEffect(() => {
    localStorage.removeItem("researchSurveysSearchValue");
    dispatch(uploadProfilePicClear());
    setstate(validatorsObj);
  }, []);

  useEffect(()=> {
    if (isUserUpdated) {
      setOpenPopUp(false);
      setChangeName(false);
    }
  },[isUserUpdated])


  useEffect(() => {
    if (errorMsg) {
      setserverMsg(true);
      setTimeout(() => {
        setserverMsg(false);
        dispatch(createUserFailureClear());
      }, 3000);
    }
    if (profilePicErrors) {
      setserverMsg(true);
      setTimeout(() => {
        setserverMsg(false);
        dispatch(uploadProfilePicFailureClear());
      }, 3000);
    }

    if (profilePic && picUploaded) {
      // call patch api of user to update user in DB.
      var payload = { profile_pic: profilePic, id: inputValues.id}
      dispatch(updateUserRequest(payload));
      setPicUploaded(false);
    }
  }, [errorMsg, profilePic, profilePicErrors]);

  useEffect (() => {
    if (userData) {
      dispatch(uploadProfilePicFailureClear());
    }
  },[userData]);

  return (
    <>
      <Notifications />
      <HeaderNavigation />
      <div className="users-list-container">
      {user_role === 0 && <SideNavigation/>}
        <div className='right-container'>
          <section className="title-section">
            <h2>Profile Settings</h2>
          </section>
          <Card className="data-card profile-Setting-form">
            <CardBody>
              <Form className="ad-form profile-seeting-form">
                <FormGroup className="profile-pic-formgrp">
                  <div className="profilePic-wrap">
                    <img style={{width: "56px", height: "56px"}}
                      src={ data.profile_pic ?
                           data.profile_pic : "/Icon_assets/svg/no-photo.png" 
                      }
                    ></img>
                  </div>

                  <Button className="export-icon" style={{
                    opacity: "1", borderColor: "transparent",
                    backgroundColor: "transparent",
                    paddingLeft: 0,
                    cursor: "pointer"
                  }} onClick={(e) => console.log(e)}>
                    <span className="span-label">{data.profile_pic ? 'Change Photo' : 'Add Photo'} </span>
                  </Button>
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    className="custom-file-upload-input"
                    title=""
                    onChange={(e) => handleUploadFile(e)}
                  />
                </FormGroup>
                <FormGroup>

                  <Input
                    type="text"
                    name="fullname"
                    id="fullname"
                    required
                    value={data.name}
                    className="form-input"
                    autoComplete="off"
                    readOnly
                  />
                  <Label for="fullName" className={`floating-label ${data.name ? 'floating-label-active' : ''}`}>
                    Full Name
                    </Label>
                  {/* <FormFeedback>{this.validators.email.errorMsg}</FormFeedback> */}
                  <div
                    className="change"
                    
                    onClick={() => {
                      setOpenPopUp(true);
                      setChangeName(true)
                    }}
                  >
                    Change
                  </div>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    required
                    // value={this.state.userData.email}
                    value={userData.email}
                    className="form-input"
                    autoComplete="off"
                    // onChange={this.handleInputChange}
                    // invalid={this.validators.email.errorMsg !== ''}
                    readOnly
                  />
                  <Label for="email" className={`floating-label ${userData.email ? 'floating-label-active' : ''}`}>
                    Email
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Input
                    // type={this.state.show_input ? "text" : "password"}
                    name="password"
                    id="password"
                    value={"**************"}
                    className="form-input form-input-sm"
                    autoComplete="off"
                    // onChange={this.handleInputChange}
                    // invalid={this.validators.password.errorMsg !== ''}
                    readOnly
                  />
                  <Label for="password" className={`floating-label ${userData.email ? 'floating-label-active' : ''}`}>
                    Password
                    </Label>
                  <div
                    className="change"
                    style ={{ pointerEvents: "none", color: "#8A8E97" }}
                    onClick={() => {
                      setOpenPopUp(true);
                      setChangeName(false)
                    }}
                  >
                    Change
                    </div>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          {/* <Button color="red" className="in-btn-link red" style={{ marginLeft: 34 }} onClick={() => setOpenPopUp(false)}>Log Out</Button> */}
        </div>
      </div>
      <PopUpModal
        className={"change-profile"}
        width={528}
        title={changeName ? "Update Name" : "Change Password"}
        openPopUp={openPopUp}
        setOpenPopUp={closeModal}
      >
          <Form className="ad-form update-form"  onSubmit={updateUser}>
            {changeName ?
              <FormGroup>
                <Label for="fullName" className="popup-label">
                  Full Name
              </Label>
                <Input
                  type="text"
                  name="fullname"
                  id="fullname"
                  required
                  value={inputValues.fullname}
                  className="form-input"
                  autoComplete="off"
                  onChange={handleInputChange}
                  style={{padding: "6px 0 6px 0px"}}
                // invalid={this.validators.email.errorMsg !== ''}
                />
                {/* <FormFeedback>{this.validators.email.errorMsg}</FormFeedback> */}
              </FormGroup>
              :
              <>
                <FormGroup>
                  <Label className="popup-label">Current Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="currentPassword"
                    required
                    value={inputValues.password}
                    className="form-input"
                    autoComplete="off"
                    onChange={handleInputChange}
                  // invalid={this.validators.email.errorMsg !== ''}
                  />
                  <FormFeedback>{validators.password.errorMsg}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label className="popup-label">New Password</Label>
                  <Input
                    type="password"
                    name="new_password"
                    id="newPassword"
                    required
                    value={inputValues.new_password}
                    className="form-input"
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                  <FormFeedback>{validators.new_password.errorMsg}</FormFeedback>
                </FormGroup>
              </>
            }
            <FormGroup>
            <Button
              className="in-btn-sm in-btn-primary submit-btn"
              type="submit"
              disabled={changeName ? inputValues.fullname === "" :
                ((inputValues.new_password === "" || inputValues.password === "")
                  || (inputValues.new_password !== "" && validators.new_password.errorMsg !== "")
                   || (inputValues.password !== "" &&  validators.password.errorMsg !== ""))}
            >
                {isUserUpdating ? <ADspinner/> : `Update` }
              </Button>
              <Button color="link"
                className="cancel-btn"
                onClick={() => {
                  setOpenPopUp(false);
                  setInputValues(initialState);
                  setIsValid(false);
                  setChangeName(false)
                }}>Cancel</Button>
            </FormGroup>
          </Form> 
      </PopUpModal>
    </>
  );
};

export default ProfileSetting;
