import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, CustomInput, Input } from 'reactstrap'
import { filtersActionCreator } from '../../../../../actions'
import SkeletonTable from '../../../../Loader_Skeleton/SkeletonTable'
import './index.scss'


const statusIcons = {
  ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
  ARROW_DOWN: "/Icon_assets/svg/arrow-down-filter.svg"
}

const StateFilter = (props) => {
  const dispatch = useDispatch()

  //redux states
  const states = useSelector(state => state?.filters?.states?.results)
  const statesCount = useSelector(state => state?.filters?.states?.count)
  const isStatesNext = useSelector(state => state?.filters?.states?.next)
  const isStatesLoading = useSelector(state => state?.filters?.isStatesLoading)

  //component states
  const [offset, setOffset] = useState(0)
  const [dropDownData, setDropdownData] = useState([]);
  const [isStateFilterOpen, setIsStateFilterOpen] = useState(false)
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchText, setSearchText] = useState('');


  const toggleStateFilter = () => {
    setIsStateFilterOpen(prev => !prev)
    if (!isStateFilterOpen) {
      setDropdownData(prev => [...selectedStates, ...getSelectedFilteredData(prev)])
      handleStatesScroll()
    }
    else {
      setOffset(0)
      setDropdownData([])
      setSearchText('')
      props.setSelectedStates(selectedStates)
    }
  }

  const getSelectedFilteredData = (data) => {
    selectedStates.forEach((selectedStates => {
      data = data.filter(d => d.id !== selectedStates.id)
    }))
    return data;
  }

  useEffect(() => {
    if (states?.length)
      setDropdownData(prev => [...prev, ...getSelectedFilteredData(states)])
  }, [states])

  const handleStatesScroll = (search) => {
    const params = {}
    if (search) {
      params.search = search
      params.limit = 500;
    }
    else {
      params.limit = 50;
      params.offset = offset * 50
      setOffset(prev => prev + 1)
    }
    dispatch(filtersActionCreator.getStatesRequest(params))
  }

  const handleStateSelect = (id, label, index) => {
    if (selectedStates.some(state => state.id === id))
      setSelectedStates(selectedStates.filter(val => val.id !== id))
    else
      setSelectedStates(prev => [...prev, { id, label, index }])
  }

  const clearFilter = (e) => {
    e.stopPropagation();
    props.setSelectedStates([]);
    setSelectedStates([])
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    if (searchText.length >= 0) {
      setOffset(0)
      handleStatesScroll(searchText)
      setDropdownData([...selectedStates])
    }
  }, [searchText])

  useEffect(() => {
    if (props.isDisplayFilterRemoved)
      setSelectedStates(props.selectedStates)
  }, [props.selectedStates])

  return (
    <div className='ad-state-filter-container'>
      <Dropdown className="d-flex" isOpen={isStateFilterOpen} toggle={toggleStateFilter}>
        <DropdownToggle >
          <div className='dr-button'>
            <div>
              <span className='dt-title'>State</span>
              {selectedStates?.length > 0 && <span className='filter-count'> {selectedStates?.length}</span>}
            </div>
            <div>
              {selectedStates?.length > 0 && <img style={{ display: "inline-block" }}
                onClick={(e) => clearFilter(e)}
                toggle={false}
                src={"/Icon_assets/svg/close-image.svg"}
                className="arrow-down"
                alt='state-filter'
              />}
              <img style={{ display: "inline-block", margin: '0 12px', transform: isStateFilterOpen ? 'rotate(180deg)' : '' }}
                src={
                  statusIcons.ARROW_DOWN
                }
                className="arrow-down"
                alt='state-filter'
              /></div>

          </div>
        </DropdownToggle>
        <DropdownMenu toggle={false} positionFixed={false}>
          <div className='state-list-container' >
            <div style={{ marginBottom: '14px', display: 'flex' }} >
              <Input
                type='Text'
                className='search form-control'
                placeholder='Search...'
                onChange={(e) => handleSearch(e)}
                value={searchText}
              ></Input>
              <img
                src="/Icon_assets/svg/search-organization-icon.svg"
                alt="Search"
                className="search-image"
              />
            </div>
            <div id="statesDropDown" className='state-list'>
              <InfiniteScroll
                dataLength={statesCount || 0}
                next={() => handleStatesScroll()}
                hasMore={isStatesNext !== null}
                loader={<SkeletonTable rows={10} />}
                scrollableTarget="statesDropDown"
                endMessage={<div>{dropDownData?.length === 0 ? "No State Found" : ""}</div>}
              >
                {!isStatesLoading ? dropDownData?.map((item, index) => <DropdownItem toggle={false}>
                  <div style={{ height: '40px' }}>
                    <CustomInput
                      type="checkbox"
                      style={{ alignSelf: 'center' }}
                      key={'checkbox-select' + item.id}
                      id={item.id}
                      checked={selectedStates.some(state => state.id === item.id)}
                      onChange={() => handleStateSelect(item.id, item.label, index)}
                      label={item.label}
                    />

                  </div>
                </DropdownItem>

                ) :
                  <SkeletonTable rows={10} />
                }
              </InfiniteScroll>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  )
}

export default StateFilter