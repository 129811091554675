import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import {
  addDays,
  subDays,
  startOfDay,
  format,
} from "date-fns";
import ADCalendar from '../../../../ADCalendar';
import './index.scss'

const DATE_FORMAT = {
  mmddyyyy: 'MM/dd/yyyy',
  ddMMyyyy: 'dd/MM/yyyy',
  yyyyMMdd: 'yyyy-MM-dd',
  MMDDYYYY: 'MM/DD/YYYY'
};

const statusIcons = {
  ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
  ARROW_DOWN: "/Icon_assets/svg/arrow-down-filter.svg"
}

const startTime = (startOfDay(subDays(new Date(), 7)));
const endTime = (startOfDay(addDays(new Date(), 0)));

const defaultDateConfig = {
  startDate: startTime,
  endDate: endTime,
  displayDateRange: format(new Date(startTime), DATE_FORMAT.mmddyyyy) + " - " + format(new Date(endTime), DATE_FORMAT.mmddyyyy),
}

function DateRangeFilter(props) {
  const [showDateRange, selectShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState();

  const toggleDateRange = () => {
    selectShowDateRange((prevState) => !prevState)
  };

  const getDateFromCalender = (e, dateRange) => {
    setDateRange(dateRange);
    props.setSelectedDateRange([dateRange?.displayDate])
    selectShowDateRange((prevState) => !prevState)
  }


  const clearFilter = (e) => {
    e.stopPropagation();
    setDateRange();
    props.setSelectedDateRange([])
  }

  useEffect(() => {
    if (props.isDisplayFilterRemoved) {
      setDateRange(props.selectedDateRange)
    }
  }, [props.selectedDateRange])

  return (
    <div className='ad-date-range-container'>
      <Dropdown className="d-flex" isOpen={showDateRange} toggle={toggleDateRange}>
        <DropdownToggle style={{ whiteSpace: "nowrap" }}>
          <div className='dr-button'>
            <div>
              <div className='dt-title'>{"Date Range"}</div>
              {dateRange?.displayDate && <div className='dt-title sub'>{dateRange?.displayDate}</div>}
            </div>
            <div>
              {dateRange?.displayDate && <img style={{ display: "inline-block" }}
                onClick={(e) => clearFilter(e)}
                toggle={false}
                src={"/Icon_assets/svg/close-image.svg"}
                className="arrow-down"
                alt='date-range'
              />}
              <img style={{ display: "inline-block", margin: '0 12px', transform: showDateRange ? 'rotate(180deg)' : '' }}
                src={
                  statusIcons.ARROW_DOWN
                }
                className="arrow-down"
                alt='date-range'
              />
            </div>
          </div>

        </DropdownToggle>
        <DropdownMenu className="ad-date-range-picker">
          <DropdownItem toggle={false}>
            <ADCalendar
              getDate={getDateFromCalender}
              closeCalender={() => selectShowDateRange(false)}
              dateFormat={'dd/MM/yy'}
              displayDateFormat={DATE_FORMAT.mmddyyyy}
              selectedDate={[{
                startDate: dateRange?.startDate || defaultDateConfig.startDate,
                endDate: dateRange?.endDate || defaultDateConfig.endDate,
                key: "selection",
              }]}
            />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default DateRangeFilter