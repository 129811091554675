import React from 'react'
import Pills from '../Pills'
import { Button } from 'reactstrap'
import './index.scss'

function AppliedFilters(props) {
  return (<div className="rtr-filter-show-container">
    <div className='d-flex'>
      <div >
        <div className='d-flex' style={{ marginLeft: !props.showFilter ? '60px' : '6px', marginBottom: '12px' }}>
          <div style={{ alignSelf: 'center' }}>Selected Filters</div>
          <button
            type="button"
            style={{ padding: '0', marginLeft: '18px' }}
            className=" btn btn-clear"
            onClick={(e) => props.resetAllFilters(e)}>
            <span style={{ color: "#3257FB", fontSize: "16px", }}>Clear All</span>
          </button>
        </div>
        <div className='d-flex' >
          {!props.showFilter && <div className='d-flex' style={{ marginTop: '6px', marginRight: '8px' }}>
            <Button
              className="icon-button d-flex"
              onClick={() => props.setShowFilter(true)}
            >
              <img src="/Icon_assets/svg/filter.svg" alt="filter" ></img>
            </Button>
          </div>}
          <div className="pills-container">
            {
              props.filters.map((filter) => <Pills data={filter}></Pills>)
            }
          </div>
        </div>

      </div>
    </div>
  </div>
  )
}

export default AppliedFilters