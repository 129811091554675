
import React, { useRef, useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import "./index.scss";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import Loader from '../../Loader';
import ADspinner from "../../Loader/ADspinner";
import DetailsRow from './DetailsRow';
import { realtimeResearchActionCreator, organizationActionCreator } from "../../../actions";
import {
  Card, CardBody, Button, UncontrolledTooltip, DropdownMenu, DropdownItem, Dropdown, DropdownToggle, FormGroup, Input, Container, Row, Col,
  Modal,
  ModalHeader,
  Form,
  Tooltip,
  ModalBody,
  Label,
  CustomInput,
  FormFeedback,
  ButtonGroup
} from "reactstrap";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  format,
  startOfQuarter,
  sub
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import RealTimeChart from "../RealtimeChart";
import CreatableSelect from 'react-select/creatable';
import ExportModal from "./ExportModal";
import SkeletonSection from "../../Loader_Skeleton/SkeletonSection";
import Validator from "../../../utils/validators";
import SkeletonAnalyticsTable from "../../Loader_Skeleton/SkeletonAnalyticsTable";
import RealtimeResearchFilter from "./RealtimeResearchFilter";
import AppliedFilters from "./AppliedFilters";
import AppliedAnswerFilters from './AppliedAnswerFilters'
import RBAC from "../../RBAC";

const RealtimeResearchDetails = () => {
  let disableChart = false;
  const myRefs = useRef([]);
  const rowRefs = useRef([]);
  const headerRefs = useRef(null);

  const validatorsObj = new Validator();
  const [validators, setValidators] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);
  const initialState = {
    zipcode: ''
  };
  const [inputValues, setInputValues] = useState(initialState);
  const [orgLogo, setOrgLogo] = useState("");
  const [logoId, setLogoId] = useState("");
  const [isClearPressed, setIsClearPressed] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [eventsTooltipOpen, setEventsTooltipOpen] = useState(false);
  const eventsToggle = () => setEventsTooltipOpen(!eventsTooltipOpen);

  const [summaryTooltipOpen, setSummaryTooltipOpen] = useState(false);
  const summaryToggle = () => setSummaryTooltipOpen(!summaryTooltipOpen);

  const clearcloseBtn = (
    <button className="close" onClick={() => { setOpenExportModal(false) }}>
      <img src={"/Icon_assets/svg/close_icon_black.svg"} alt="Close" style={{ width: '16px', height: '16px' }} />
    </button>
  );

  let { id, brand_id, platform, adsetid } = useParams();
  const [selectedPlatform, setSelectedPlatform] = useState(Number(platform))

  const [filters, setFilters] = useState([]);
  const [selectionData, setSelectionData] = useState([]);

  /*Survey filters start*/
  const [showDateRange, selectDateRange] = useState(false);
  const [showZipCode, SetShowZipCode] = useState(false);
  const [showZipcodeDropdown, setShowZipcodeDropdown] = useState(false);
  const toggleDateRange = () => selectDateRange((prevState) => !prevState);
  const toggleZipCode = () => SetShowZipCode((prevState) => !prevState);
  const toggleZipcodeDropdown = () => setShowZipcodeDropdown((prevState) => !prevState);
  const [selectedStartTime, updateSelectedStartTime] = useState();
  const [selectedEndTime, updateSelectedEndTime] = useState();
  const [dateRangeValue, updateDateRangeValue] = useState();
  const [dispalyDateRange, setDispalyDateRange] = useState();
  const [clearFilterActive, setClearFilterActive] = useState(false)
  const intialDate = [{
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  }];
  const [state, setState] = useState(intialDate);

  const [zipCode, setZipCode] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [noOfZipCodes, setNoOfZipCodes] = useState(0);

  const [zipcodeAutocomplete, setZipcodeAutocomplete] = useState([]);
  const [defaultZipCodes, setDefaultZipCodes] = useState([]);
  const [selectAllZipcode, setSelectAllZipcode] = useState(false);
  const [dateSidebarOptions, setDateSidebarOptions] = useState([
    {
      label: "Custom",
      range: () => ({
        startDate: selectedStartTime ? selectedStartTime : defineds.startOfMonth,
        endDate: selectedEndTime ? selectedEndTime : defineds.startOfMonth,
      }),
    },
    {
      label: "All time",
      range: () => ({
        startDate: defineds.startOYear,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: defineds.endOfLastSevenDays,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Month to date",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Quarter to Date",
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Year to Date",
      range: () => ({
        startDate: defineds.startOYear,
        endDate: defineds.endOfToday,
      }),
    },
  ]);

  const toggleAllToChart = (val) => {
    var d = {};
    if (val === 'chart') {
      for (var i = 0; i < cardCount; i++) {
        d[i] = true;
      }
    } else {
      for (var i = 0; i < cardCount; i++) {
        d[i] = false;
      }
    }
    setHidden(d);
  }

  useEffect(() => {
    setClearFilterActive(zipCode.length !== 0 || dispalyDateRange)
  }, [zipCode, dispalyDateRange])

  const handleDateChange = (e) => {
    selectDateRange(false);
    const dateRange = state[0];
    const selectNextEndDate = startOfDay(addDays(dateRange.endDate, +1));
    let dateValue =
      dateToYMD(dateRange.startDate, "date-range") +
      " - " +
      dateToYMD(dateRange.endDate, "date-range");
    updateSelectedStartTime(dateToYMD(dateRange.startDate));
    updateSelectedEndTime(dateToYMD(selectNextEndDate));
    updateDateRangeValue(dateValue);
    const formatDispayDateRange = format(new Date(dateRange.startDate), 'd MMM, yy') + " - " + format(new Date(dateRange.endDate), 'd MMM, yy');
    setDispalyDateRange(formatDispayDateRange);

    /*handle custom date label start*/
    const dateSidebarOptions = sideBarOptions();
    const dateFormat = 'd MMM, yy';
    const checkSelectore = dateSidebarOptions.map((item) => {
      return { ...item, range: item.range() }
    }).filter((item) => {
      if (
        format(new Date(item.range.startDate), dateFormat) == format(new Date(dateRange.startDate), dateFormat) &&
        format(new Date(item.range.endDate), dateFormat) == format(new Date(dateRange.endDate), dateFormat)
      ) {
        return item.label
      }
    });

    const selectedRange = checkSelectore ? (checkSelectore.length > 0 ? checkSelectore[0].label : 'Custom') : 'Custom';
    const newSelectedDateRange = dateSidebarOptions.map(item =>
      item.label === 'Custom' && selectedRange === "Custom" ?
        {
          ...item, range: () => ({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          })
        }
        : item
    );
    setDateSidebarOptions(newSelectedDateRange);
    /*handle custom date label end*/
  };
  const clearFilterSearch = (e) => {
    e.preventDefault();
    updateSelectedStartTime();
    updateSelectedEndTime();
    updateDateRangeValue();
    setState(intialDate);
    setDispalyDateRange();
    setInputValues(initialState);
    SetShowZipCode(false);
    setZipCode([]);
    setNoOfZipCodes(0);
  };

  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOYear: startOfYear(addYears(new Date(), 0)),
    endOflastYear: startOfDay(addDays(new Date(), -1)),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
  };

  const sideBarOptions = () => {
    const customDateObjects = dateSidebarOptions;

    return customDateObjects;
  };

  const sideBar = sideBarOptions();
  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar),
  ];
  /*Survey filters end*/

  const statusIcons = {
    BAR_ICON: "/Icon_assets/svg/bar_icon.svg",
    SURVEY_ICON: "/Icon_assets/svg/rtr_survey_icon.svg",
    PLUS_ICON: "/Icon_assets/svg/plus_icon_blue.svg",
    MINUS_ICON: "/Icon_assets/svg/minus_icon_blue.svg",
    SEPERATOR_ICON: "/Icon_assets/svg/seperator.svg",
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    GO_BACK_ICON: "/Icon_assets/svg/back_icon.svg",
    RELOAD_ICON: "/Icon_assets/svg/reload-icon.svg",
    BAR_ICON_HIGHLIGHT: "/Icon_assets/svg/bar_icon_highlight.svg",
    LIST_ICON_HIGHLIGHT: "/Icon_assets/svg/list_icon_highlight.svg",
    DISABLED_BAR_ICON: "/Icon_assets/svg/disabled_bar_icon.svg",
    DISABLED_LIST_ICON: "/Icon_assets/svg/disabled_list_icon.svg",
    BRANCH_ICON: "/Icon_assets/svg/branch_icon.svg"
  }
  const dispatch = useDispatch();
  let location = useLocation();
  const userData = useSelector((state) => state.auth.userData);
  const organizationData = useSelector((state) => state.organization.organizationDataById);
  const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
  const bothPlatformData = useSelector((state) => state.realtimeResearch.bothAdsData);

  const compareSize = (textElementRef) => {
    if (textElementRef && textElementRef.current) {
      var el = textElementRef.current;
      const compare = el.offsetWidth < el.scrollWidth
      setHover(compare);
    }
  };

  const [hoverStatus, setHover] = useState(false);

  const selection = {
    MULTIPLE_SELECTION: "Multiple Selection",
    MULTIPLE_CHOICE: "Multiple Choice",
    SINGLE_SELECTION: "Single Selection",
    SINGLE_CHOICE: "Single Choice",
    SINGLE_TOOL_TIP: "Respondent can only submit one answer.",
    MULTIPLE_TOOL_TIP: "Respondents can submit multiple answers."
  };


  const researchSearchData = useSelector(
    (state) => state.realtimeResearch.rtrDetailsStat
  );

  const isStatDataRequest = useSelector(
    (state) => state.realtimeResearch.isStatDataRequest
  );

  const isDetailsRequest = useSelector(
    (state) => state.realtimeResearch.isDetailsRequest
  );

  const detailsData = useSelector(
    (state) => state.realtimeResearch.rtrDetails
  )

  const [hidden, setHidden] = useState({});
  const [cardCount, setCardCount] = useState(0);
  const [openExportModal, setOpenExportModal] = useState(false);

  const toggleHide = index => {
    setHidden({ ...hidden, [index]: !hidden[index] });
  };

  const backToOrgList = (e) => {
    e.preventDefault();
    myRefs.current = null;
    rowRefs.current = null;
    headerRefs.current = null;
    history.push(ROUTES.REALTIME_RESEARCH);
  }

  const dateToYMD = (date) => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();

    return (
      "" +
      y +
      "-" +
      (m <= 9 ? "0" + m : m) +
      "-" + (d <= 9 ? "0" + d : d) +
      " " + "00:00:00");
  };

  const loadApis = () => {
    let params = {};
    const organization_id = selectedOrganization ? selectedOrganization?.id : "";
    const user = userData.organization_role;
    var d = new Date();
    d.setMonth(d.getMonth() - 6);
    params.id = brand_id;
    params.end_date = dateToYMD(startOfDay(addDays(new Date(), 1)));
    params.start_date = dateToYMD(startOfDay(sub(new Date(), { months: 6 })));
    params.ad_id = getAdId(selectedPlatform);
    params.organization_id = organization_id;
    params.user = user;
    params.platform = selectedPlatform;
    params.adset_id = adsetid;

    //Date Filter
    let dateFilters = filters.filter(data => data.type === 'Date')
    let [startDate, endDate] = dateFilters.length > 0 ? dateFilters[0].value?.split('-') : [undefined, undefined]

    if (startDate) {
      updateSelectedStartTime(startDate)
      params.start_date = dateToYMD(new Date(startDate));
    }
    if (endDate) {
      updateSelectedEndTime(endDate)
      params.end_date = dateToYMD(new Date(endDate));
    }

    //Zip filter
    let zipFilters = filters.filter(data => data.type === 'PIN')
    if (zipFilters.length > 0) {
      params.zipcode = zipFilters.map(zipFilter => zipFilter.value);
      setZipCode(params.zipcode)
    }

    if (params?.zipcode?.length === 1) {
      params.zipcode = params.zipcode[0] + ','
    }

    if (answer.length > 0) {
      params.answer = answer;
    }

    //city filter
    let cityFilters = filters.filter(data => data.type === 'City')
    if (cityFilters.length > 0) {
      params.city = cityFilters.map(data => data.value).join();
    }

    //state filter
    let stateFilters = filters.filter(data => data.type === 'State')
    if (stateFilters.length > 0) {
      params.state = stateFilters.map(date => date.value).join();
    }

    //device-type filter
    let deviceTypeFilters = filters.filter(data => data.type === 'Device')
    if (deviceTypeFilters.length > 0) {
      params.device_type = deviceTypeFilters.map(date => date.value).join();
    }

    //gender filter
    let genderFilters = filters.filter(data => data.type === 'Gender')
    if (genderFilters.length > 0) {
      params.gender = genderFilters.map(date => date.value.toLowerCase().replace(' ', '-')).join();
    }

    //age range filter
    let ageRangeFilters = filters.filter(data => data.type === 'Age')
    if (ageRangeFilters.length > 0) {
      params.age_range = ageRangeFilters.map(date => date.value).join();
    }

    dispatch(realtimeResearchActionCreator.getRealtimeResearchDetailsRequest(params));
    dispatch(realtimeResearchActionCreator.getRealtimeResearchDetailsStatRequest(params));
    window.scrollTo(0, 0);
  }

  const getAdId = (selected_Platform) => {
    if (Number(platform) !== 2) {
      return id
    }

    if (selected_Platform === 2 && Number(platform) === 2) {
      return id
    } else {
      if (selected_Platform !== 2) {
        /**
         * platform_value: 1 => programmatic
         * platform_value: 2 => invisibly
        */
       if (selected_Platform === 1) {
        const ads = bothPlatformData?.ads?.find(ad => ad.platform_value === 1);
        return ads?.id
       }else if(selected_Platform === 0){
         const ads = bothPlatformData?.ads?.find(ad => ad.platform_value === 2);
         return ads?.id
       }
      }
    }
  }

  const resetAllFilters = (e) => {
    // remove other filters
    setFilters([])
    setIsClearPressed(true)
    e.preventDefault();
    clearFilterSearch(e);
    var d = {};
    for (var i = 0; i < cardCount.length; i++) {
      d[i] = false;
    }
    setHidden(d);
  }

  useEffect(() => {
    if (Number(platform) === 2 && adsetid) {
      dispatch(realtimeResearchActionCreator.getBothCampaignsRequest({ adset_id: adsetid }))
    }

    return () => {
      if (Number(platform) === 2 && adsetid) {
        dispatch(realtimeResearchActionCreator.getBothCampaignsClear())
      }
    }
  }, [])


  /*useEffect(() => {
      let param = {};
      const organization_id = selectedOrganization ? selectedOrganization?.id : "";
      const user = userData.organization_role;
      param.id = brand_id;
      param.end_date = dateToYMD(startOfDay(addDays(new Date(), 1)));
      param.start_date = dateToYMD(startOfDay(sub(new Date(), { months: 6 })));
      param.ad_id = id;
      param.organization_id = organization_id;
      param.user = user;
      dispatch(realtimeResearchActionCreator.getRealtimeResearchDetailsRequest(param));
      dispatch(realtimeResearchActionCreator.getRealtimeResearchDetailsStatRequest(param));
      setValidators(validatorsObj);
      window.scrollTo(0, 0);
  }, []);*/

  //componentDidMount & shouldComponentUpdate handle in single useEffect to avoid multiple re-rendering
  useEffect(() => {
    loadApis();
  }, [answer]);

  useEffect(() => {
    if (detailsData?.length) {
      var d = {};
      let selectionData = [];
      setCardCount(detailsData.length);
      for (var i = 0; i < detailsData.length; i++) {
        d[i] = false;
        selectionData[i] = {
          question: detailsData[i]?.question,
          questionId: detailsData[i]?.qId,
          isSelectable: false,
          selectedAnswers: []
        }
      }
      setSelectionData(selectionData)
      setHidden(d)
    }
  }, [detailsData])

  useEffect(() => {
    if (location && location.state && location.state.org_name) {
      let param = {};
      param.id = location.state.org_id
      setLogoId(location.state.org_id);
      dispatch(organizationActionCreator.getOrganizationRequest(param.id))
    }
  }, [location]);

  useEffect(() => {
    if (organizationData) {
      setOrgLogo(organizationData.logo);
    }
  }, [organizationData]);

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    updateValidations(e.target.name, e.target.value);
    inputFields[e.target.name] = e.target.value;
    setInputValues(inputFields);
    const checkValidation = validators.zipcode.valid;
    setIsValid(checkValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      let zipCodeObj = inputValues.zipcode;
      const zipCodeArr = zipCodeObj.map((item) => item.value);
      setZipCode(zipCodeArr);
      setNoOfZipCodes(zipCodeArr.length);
      SetShowZipCode(false);

      const localZipCodesArr = localStorage.getItem("zipcodes") ? JSON.parse(localStorage.getItem("zipcodes")) : [];
      const newLocalStorageArr = [...new Set([...localZipCodesArr, ...zipCodeArr])]
      localStorage.setItem("zipcodes", JSON.stringify(newLocalStorageArr));
    }
  }

  const handleAnsClick = (e, selectedRowStateArr) => {
    e.preventDefault();
    setAnswer(selectedRowStateArr);
  }

  const handleAllZipCodeSelect = (e) => {
    if (e.target.checked) {
      let zipCodeObj = [...inputValues.zipcode];
      const zipCodeArr = zipCodeObj.map((item) => item.value);
      setZipCode(zipCodeArr);
      setNoOfZipCodes(zipCodeArr.length);
    } else {
      setZipCode([]);
      setNoOfZipCodes(0);
    }
  }

  const handleZipCodeCheckboxChange = (e, checkedZipcode) => {
    if (e.target.checked) {
      setZipCode((zipcode) => [
        ...zipcode, checkedZipcode
      ])
      setNoOfZipCodes((preCount) => preCount + 1);
    } else {
      zipCode.splice(zipCode.indexOf(checkedZipcode), 1);
      setZipCode((zipcode) => [...zipcode]);
      setNoOfZipCodes((preCount) => preCount - 1);
    }
  }

  const handleZipOnChange = (newValue, actionMeta) => {
    const inputFields = { ...inputValues };
    inputFields["zipcode"] = newValue;
    setInputValues(inputFields);
    setIsValid(true);
    const inputFieldsArr = inputFields.zipcode.map((item) => item.value);
    const checkedZipCount = inputFieldsArr.reduce((a, c) => a + zipCode.includes(c), 0);
    setNoOfZipCodes(checkedZipCount);
  };

  const HandleZipcodeValidation = (inputValue, selectValue, selectOptions, accessors) => {
    const regex = /^(\d{5})?$/;
    const testValidation = regex.test(inputValue) && inputValue.length !== 0;
    if (testValidation) {
      setIsValid(true);
      return true;
    } else {
      return false;
    }
  }

  const customSelectStyle = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      whiteSpace: "nowrap",
      overflowY: "auto",
      textOverflow: "ellipsis",
      maxWidth: "270px",
      maxHeight: "70px",
      fontSize: "12px",
      border: 0,
      '&:hover': {
        border: 0,
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "150px",
      // maxHeight: "172px",
      height: "172px",
      overflow: "auto",
      '&::-webkit-scrollbar': {
        width: "4px"
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        //background: #f1f1f1; 
        boxShadow: "inset 0 0 5px #FAFAFA",
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: "#F2F3FD",
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: "#F2F3FD",
      }
    }),
  };
  useEffect(() => {
    loadApis()
  }, [filters, selectedPlatform])

  const setSelectionEnabled = (index) => {
    let data = JSON.parse(JSON.stringify(selectionData));
    data[index].isSelectable = !data[index].isSelectable;
    setSelectionData(data)
  }

  const updatePlatform = (e, updatedPlatform) => {
    resetAllFilters(e)
    setSelectedPlatform(updatedPlatform)
  }

  useEffect(() => {
    setSelectedPlatform(Number(platform))
  }, [])

  return (
    <>
      {/* {console.log(cardCount)} */}
      <div className="right-container realtime-container">
        <div className="rtr-survey-container">
          <section className="survey-details-header">
            <nav className="left-nav-section">
              <section className="title-section-with-back rtr-header">
                <Button className="btn-transparent" onClick={backToOrgList} style={{ marginTop: "-9px" }}>
                  <img src={statusIcons.GO_BACK_ICON} alt="go to back" className="go-back" />
                </Button>

                <h2 id={"survey-header"} style={{ color: "#0E0E0F", marginTop: "-10px" }}
                  className="mb-0 pl-4 secondary-title"
                  onMouseEnter={() => compareSize(headerRefs)}
                  onMouseLeave={() => compareSize(headerRefs)}
                  ref={headerRefs}>
                  {isStatDataRequest ? researchSearchData["Ad"] !== undefined ?
                    `Analytics (${researchSearchData["Ad"]})`
                    : `Analytics (${localStorage.getItem('ad_name')})`
                    : `Analytics ()`}</h2>
                {hoverStatus &&
                  <UncontrolledTooltip
                    className="survey-tool-tip"
                    placement="top"
                    target={"survey-header"}
                  >{`Analytics (${localStorage.getItem('ad_name')})`}
                  </UncontrolledTooltip>
                }
              </section>
            </nav>
            {Number(platform) === 2 && (
              /*sent empty array of object to the RBAC will show component only for Super Admin*/
              <RBAC requiredPermissions={[{}]} > 
                <nav className={"platform-container"}>
                  <div className="platform-wrapper">
                    <ButtonGroup className="toggle-buttons">
                      <Button
                        className={"in-btn-primary btn-toggle"}
                        onClick={(e) => updatePlatform(e, 0)}
                        active={selectedPlatform === 0}
                        disabled={!bothPlatformData?.ads?.length}
                      >
                        Invisibly
                      </Button>
                      <Button
                        className={"in-btn-primary btn-toggle btn-middle"}
                        onClick={(e) => updatePlatform(e, 1)}
                        active={selectedPlatform === 1}
                        disabled={!bothPlatformData?.ads?.length}
                      >
                        Programmatic
                      </Button>
                      <Button
                        className={"in-btn-primary btn-toggle"}
                        onClick={(e) => updatePlatform(e, 2)}
                        active={selectedPlatform === 2}
                      >
                        Both
                      </Button>
                    </ButtonGroup>
                  </div>
                </nav>
              </RBAC>
            )}
          </section>
          <div style={{ display: 'flex' }}>
            <div style={{ display: showFilter > 0 ? 'block' : 'none' }}>
              <RealtimeResearchFilter
                setFilters={setFilters}
                resetAllFilters={resetAllFilters}
                setIsClearPressed={setIsClearPressed}
                filters={filters}
                setShowFilter={setShowFilter}
                isClearPressed={isClearPressed}
                platform={selectedPlatform}
              />
            </div>
            <div style={{ width: showFilter ? 'calc(100% - 376px)' : '100%' }}>
              {filters.length > 0 && <AppliedFilters showFilter={showFilter} setShowFilter={setShowFilter} filters={filters} resetAllFilters={resetAllFilters} />}
              {answer.length > 0 && <AppliedAnswerFilters answer={answer} detailsData={detailsData} setAnswer={setAnswer} />}
              <div style={{ display: 'flex', width: '100%' }}>
                {(!showFilter && filters.length === 0) && <Button
                  className="icon-button d-flex"
                  style={{
                    marginRight: '16px',
                    alignSelf: "center",
                    marginBottom: "32px"
                  }}
                  onClick={() => setShowFilter(true)}
                >
                  <img src="/Icon_assets/svg/filter.svg" height={24} width={24} alt="filter" ></img>
                </Button>}
                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                  <nav style={{ marginBottom: '32px' }} className={(isStatDataRequest ? "" : "spinner-container-div analytics-dashboard-loading") + " center-nav-section"}>
                    {isStatDataRequest ?
                      <div className="survey-total-card">
                        <span className="span-label">Total Respondents</span>
                        <span id={"respondents"} className="span-value">{researchSearchData["Total Respondents"] ? parseInt(researchSearchData["Total Respondents"]) : 0}</span>
                        {
                          researchSearchData["Total Respondents"] && researchSearchData["Total Respondents"].toString().length > 5 &&
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target={"respondents"}
                          >{researchSearchData["Total Respondents"] ? researchSearchData["Total Respondents"] === '-' ? 0 : researchSearchData["Total Respondents"] : 0}
                          </UncontrolledTooltip>
                        }
                      </div>
                      : (
                        <SkeletonSection />
                      )}
                  </nav>
                  <nav style={{ marginBottom: '32px' }} className={(isStatDataRequest ? "" : "spinner-container-div analytics-dashboard-loading") + " right-nav-section"}>
                    {isStatDataRequest ?
                      <div className="survey-total-card">
                        <span className="span-label">Total Completes</span>
                        <span id={"completes"} className="span-value">{researchSearchData["Total Completes"] ? researchSearchData["Total Completes"] === '-' ? 0 : researchSearchData["Total Completes"] : 0}</span>
                        {
                          (researchSearchData["Total Completes"] !==0 && researchSearchData["Total Completes"] && researchSearchData["Total Completes"].toString().length > 5) &&
                          <UncontrolledTooltip
                            className="ad-tool-tip"
                            placement="top"
                            target={"completes"}
                          >{researchSearchData["Total Completes"] ? researchSearchData["Total Completes"] === '-' ? 0 : researchSearchData["Total Completes"] : 0}
                          </UncontrolledTooltip>
                        }
                      </div>
                      : (
                        <SkeletonSection />
                      )}
                  </nav>
                </div>
                <nav className="last-section d-flex" style={{ padding: 0, margin: 0 }}>

                  <Button
                    className="icon-button"
                    style={{ marginRight: '16px' }}
                    onClick={() => setOpenExportModal(true)}
                    disabled={detailsData.length === 0 && !isDetailsRequest}
                  >
                    <img src="/Icon_assets/svg/export.svg" alt="export" height={24} width={24} ></img>
                  </Button>

                  <Button
                    className="icon-button"
                    style={{ marginRight: '16px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      loadApis();
                    }}
                  >
                    <img src="/Icon_assets/svg/refresh.svg" alt="refresh" height={24} width={24} ></img>
                  </Button>

                  <Button style={{
                    backgroundColor: (Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => hidden[k])) ? 'white' :
                      (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? 'white' : '#3257FB',
                    borderColor: (Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => hidden[k])) ? '#C4C4C4' :
                      (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? '#C4C4C4' : '#3257FB'
                  }} className="icon-button-right" onClick={() => toggleAllToChart('list')} >
                    <img style={{ cursor: "pointer" }} height={24} width={24}
                      src={(Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => hidden[k])) ? statusIcons.SURVEY_ICON :
                        (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? statusIcons.SURVEY_ICON : statusIcons.LIST_ICON_HIGHLIGHT}
                      alt="survey icon"
                    />
                  </Button>
                  <Button style={{
                    backgroundColor: (Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => !hidden[k])) ? 'white' :
                      (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? '#3257FB' : 'white',
                    borderColor: (Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => !hidden[k])) ? '#C4C4C4' :
                      (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? '#3257FB' : '#C4C4C4',
                  }} className="icon-button-left" onClick={() => toggleAllToChart('chart')}>

                    <img style={{ cursor: "pointer" }} height={24} width={24}
                      src={(Object.keys(hidden).length !== 0 && Object.keys(hidden).some(k => !hidden[k])) ? statusIcons.BAR_ICON :
                        (Object.keys(hidden).length !== 0 && Object.values(hidden).every(Boolean)) ? statusIcons.BAR_ICON_HIGHLIGHT : statusIcons.BAR_ICON}
                      alt="bar-icon" />
                  </Button>
                </nav>
              </div>

              <div className="rtr-survey-details">
                <div>
                  {!isDetailsRequest ?
                    (
                      detailsData && detailsData.length ?
                        (
                          detailsData.map((data, index) => {
                            myRefs.current = detailsData.map((element, i) => myRefs.current[i] ?? createRef());
                            disableChart = data.options.every((e) => e.count === 0);
                            return (

                              <>
                                <Card className={`data-card ${data.options.every((e) => e.count === 0) ? 'ad-disabled' : ''}`} id={"card-" + data?.qId} key={index + 1}>
                                  {!isDetailsRequest ?
                                    <CardBody>
                                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className="export-question-header">
                                        <div className="d-flex survey-sub-title" id={"sub-head-tooltip-" + index}>
                                          <div style={{ marginRight: '6px', paddingLeft: '12px' }} >{((selection.SINGLE_CHOICE.toLowerCase()) === data.subHeading.toLowerCase()) ? selection.SINGLE_SELECTION : selection.MULTIPLE_SELECTION}</div>
                                          {!(data?.nextQuestion) && <img className="br-icon" src={statusIcons.BRANCH_ICON} alt="bar icon" height={16} width={16} />}
                                        </div>
                                        {!data.options.every((e) => e.count === 0) ?
                                          <div className="d-flex survey-icon" style={{ padding: '20px' }}>
                                            <Button style={{
                                              backgroundColor: !hidden[index] ?
                                                data.options.every((e) => e.count === 0) ? 'white' : '#3257FB' : 'white'
                                            }} className="icon-button-right" onClick={() => toggleHide(index)} >
                                              <img style={{ cursor: "pointer" }}
                                                src={hidden[index] ?
                                                  data.options.every((e) => e.count === 0) ? statusIcons.LIST_ICON_HIGHLIGHT : statusIcons.SURVEY_ICON : statusIcons.LIST_ICON_HIGHLIGHT}
                                                alt="survey icon"
                                              />
                                            </Button>

                                            <Button style={{
                                              backgroundColor: hidden[index] ?
                                                data.options.every((e) => e.count === 0) ? 'white' : '#3257FB' : 'white'
                                            }} className="icon-button-left" onClick={() => toggleHide(index)} >
                                              <img style={{ cursor: "pointer" }}
                                                src={!hidden[index] ?
                                                  data.options.every((e) => e.count === 0) ? statusIcons.BAR_ICON_HIGHLIGHT : statusIcons.BAR_ICON : statusIcons.BAR_ICON_HIGHLIGHT}
                                                alt="survey icon"
                                              />
                                            </Button>
                                          </div> : <div className="d-flex survey-icon" style={{ padding: '20px' }}>
                                            <Button style={{
                                              backgroundColor: '#F2F3FD'
                                            }} className="icon-button-right" >
                                              <img style={{ cursor: "pointer" }}
                                                src={statusIcons.SURVEY_ICON}
                                                alt="survey icon"
                                              />
                                            </Button>

                                            <Button style={{
                                              backgroundColor: '#F2F3FD'
                                            }} className="icon-button-left" onClick={() => toggleHide(index)} >
                                              <img style={{ cursor: "pointer" }}
                                                src={statusIcons.BAR_ICON}
                                                alt="survey icon"
                                              />
                                            </Button>
                                          </div>}

                                        <UncontrolledTooltip
                                          style={{ whiteSpace: "pre-wrap" }}
                                          className="sub-heading-tool-tip"
                                          placement="top-start"
                                          target={"sub-head-tooltip-" + index}
                                        >{(data.subHeading.toLowerCase() === selection.SINGLE_CHOICE.toLowerCase()) ? selection.SINGLE_TOOL_TIP : selection.MULTIPLE_TOOL_TIP}
                                        </UncontrolledTooltip>
                                      </div>
                                      <h1 id={"tooltip-" + data.qId} className="survey-name"
                                        onMouseEnter={() => compareSize(myRefs.current[index])}
                                        onMouseLeave={() => compareSize(myRefs.current[index])}
                                        ref={myRefs.current[index]}
                                      >{data.qId}. {data.question}</h1>
                                      {
                                        hoverStatus &&
                                        <UncontrolledTooltip
                                          className="survey-tool-tip"
                                          placement="top"
                                          target={"tooltip-" + data.qId}
                                        >{data.question}
                                        </UncontrolledTooltip>
                                      }
                                      {!data.options.every((e) => e.count === 0) ? <Button
                                        className="icon-button"
                                        style={{ marginLeft: '27px', backgroundColor: selectionData?.[index]?.isSelectable || answer?.filter(s => s.includes(data?.qId))?.length > 0 ? '#3257FB' : 'white' }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectionEnabled(index)
                                        }}
                                      >
                                        <img src={selectionData?.[index]?.isSelectable || answer?.filter(s => s.includes(data?.qId))?.length > 0 ? "/Icon_assets/svg/rtr-filter-active.svg" : "/Icon_assets/svg/rtr-filter.svg"}
                                          height={24}
                                          width={24}
                                          alt="filter" ></img>
                                      </Button> : <Button
                                        className="icon-button"
                                        style={{ marginLeft: '27px', backgroundColor: '#F2F3FD' }}
                                      >
                                        <img src={"/Icon_assets/svg/rtr-filter.svg"} style={{ cursor: "pointer" }} height={24} width={24} alt="filter" ></img>
                                      </Button>
                                      }

                                      <div style={{ clear: "right" }}></div>
                                      <div style={{ padding: "0 32px 0 32px" }}>
                                        {!hidden[index] ?
                                          <DetailsRow key={index} selected={data.max}
                                            selectedOption={data.selectedOption}
                                            data={data}
                                            qId={`${index + 1}`}
                                            total={data.total}
                                            onhandleAnsClick={handleAnsClick}
                                            selectedAnswer={answer}
                                            qSelectionData={selectionData[index]}
                                          />
                                          :
                                          <RealTimeChart data={data.options} disableChart={disableChart} />

                                        }
                                      </div>
                                    </CardBody>
                                    : <Loader />
                                  }
                                </Card>
                              </>)
                          }))
                        :
                        (
                          <Card className="data-card" id={"card-no-data"}>
                            <CardBody>
                              <p className="no-wallet-data-text text-center" style={{ marginTop: "1rem" }} >No survey details available</p>
                            </CardBody>
                          </Card>
                        )
                    ) :
                    (
                      <Card className="data-card" id={"card-no-data"}>
                        <CardBody>
                          <SkeletonAnalyticsTable rows={4} />
                        </CardBody>
                      </Card>
                    )
                  }

                </div>
              </div>
            </div>
          </div>

        </div>

        {
          <ExportModal open={openExportModal}
            orgImage={orgLogo}
            closeModal={() => setOpenExportModal(false)}
            exportId={id}
            exportBrandId={brand_id}
            surveyName={isStatDataRequest ? researchSearchData["Ad"] !== undefined ?
              `${researchSearchData["Ad"]}`
              : `${localStorage.getItem('ad_name')}` : `Analytics ()`}
            exportData={detailsData}
            zip={zipCode}
            answer={answer}
            startTime={selectedStartTime}
            endTime={selectedEndTime}
            response={researchSearchData["Total Respondents"] ? researchSearchData["Total Respondents"] === '-' ? 0 : parseInt(researchSearchData["Total Respondents"]) : 0}
            completes={researchSearchData["Total Completes"] ? researchSearchData["Total Completes"] === '-' ? 0 : parseInt(researchSearchData["Total Completes"]) : 0} />
        }
      </div>
    </>
  )
}

export default RealtimeResearchDetails;