import { takeLatest, call, select, put } from "redux-saga/effects";
import { dashboardActionCreator, notificationActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendDashboardRequest(API_URL_PARAM, jwtToken, paramList) {
  let API_URL, param = '';
  var entries = Object.entries(paramList);
  if (entries.length) {
    for (let [index, [key, value]] of entries.entries()) {
      if (index == entries.length - 1) {
        param = param + `${key}=${value}`;
      } else {
        param = param + `${key}=${value}` + "&";
      }
    }
    API_URL = API_URL_PARAM + "/v1/dspreports/dsp-metric-data?" + param;
  } else {
    API_URL = API_URL_PARAM + "/v1/dspreports/dsp-metric-data";
  }

  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function getSpendCtrCpaRequest(API_URL_PARAM, jwtToken, paramList) {
  let API_URL, param = '';
  let entries = Object.entries(paramList);
  if (entries.length) {   
    for (let [index, [key, value]] of entries.entries()){
      if (index == entries.length-1) {
        param = param + (`${key}=${value}`);
      } else {
        param = param + (`${key}=${value}`) + '&';
      }      
    }
    API_URL = API_URL_PARAM + "/v1/dspreports/spend-ctr-cpa?" + param;
  } else {
    API_URL = API_URL_PARAM + "/v1/dspreports/spend-ctr-cpa";
  }
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function sendDashboardCampaignMetricDataRequest(API_URL_PARAM, jwtToken, data) {
  let API_URL, param = '';
  if (data.selectedCampaignLineFilterSet.length > 0 || data.dateRange.start_time) {   
    param = ( data.selectedCampaignLineFilterSet ? data.selectedCampaignLineFilterSet.join() : '' ) + (data.dateRange.start_time ? "&start_time="+data.dateRange.start_time+"&end_time="+data.dateRange.end_time : '');
    API_URL = API_URL_PARAM + "/v1/dspreports/dsp-campaign-metric-data?campaign_ids=" + data.expandedRows[0]+"&line_ids=" + param;
  } else {
    API_URL = API_URL_PARAM + "/v1/dspreports/dsp-campaign-metric-data?campaign_ids=" + data.expandedRows[0];
  }

  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function getSpendClicksConversionRequest(API_URL_PARAM, jwtToken, paramList) {
  let API_URL, param = '';
  let entries = Object.entries(paramList);
  if (entries.length) {   
    for (let [index, [key, value]] of entries.entries()){
      if (index == entries.length-1) {
        param = param + (`${key}=${value}`);
      } else {
        param = param + (`${key}=${value}`) + '&';
      }      
    }
    API_URL = API_URL_PARAM + "/v1/dspreports/spend-clicks-conversions?" + param;
  } else {
    API_URL = API_URL_PARAM + "/v1/dspreports/spend-clicks-conversions";
  }
  return axios({
    url: API_URL,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function getDSPCampaignsListRequest(API_URL_PARAM, jwtToken, params) {
  let API_URL= API_URL_PARAM + "/v1/dsp_campaigns";
  const param = {};
  param.limit = 300;

  if (params.advertiserList && params.advertiserList.length) {
    param.advertisers = params.advertiserList.toString();
  }

  if (params.organization) {
    param.organization = params.organization;
  }

  if (params?.limit) {
    param.limit = params.limit;
    param.offset = params.offset;
  }

  if (params?.name) {
    param.search = params.name
  }
  return axios({
    url: API_URL,
    params: param,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function getDSPCampaignsLineListRequest(API_URL_PARAM, jwtToken, paramsList) {
  let API_URL = API_URL_PARAM + "/v1/dsp_campaign_lines", param = {};
  param.limit = 300;

  if (paramsList.advertisers && paramsList.advertisers.length) {
    param.advertisers = paramsList.advertisers.toString();
  }

  if (paramsList.campaigns && paramsList.campaigns.length) {
    param.campaigns = paramsList.campaigns.toString();
  }

  if (paramsList.organization) {
    param.organization = paramsList.organization;
  }

  if (paramsList?.limit) {
    param.limit = paramsList.limit;
    param.offset = paramsList.offset;
  }

  if (paramsList?.name) {
    param.search = paramsList.name
  }

  return axios({
    url: API_URL,
    params: param,
    method: "GET",
    headers: {
      Authorization: jwtToken,
    },
    withCredentials: true,
  }).catch((err) => {
    console.log(err);
  });
}

function* sendDashboardRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendDashboardRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      var sortedItem = sortArrayData("CampaignName", false, item);
      yield put(dashboardActionCreator.dashboardSuccess(sortedItem));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(dashboardActionCreator.dashboardFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(dashboardActionCreator.dashboardFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(dashboardActionCreator.dashboardFailure("Server Error"));
  }
}

function* getSpendCtrCpaRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(getSpendCtrCpaRequest, API_URL, jwtToken, action.payload);
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === 'SUCCESS') {
        yield put(dashboardActionCreator.getSpendCtrCpaSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(
          dashboardActionCreator.getSpendCtrCpaFailure(errorMsg.join())
        );
        // yield put(
        //   notificationActionCreator.setNotification({
        //     message: errorMsg.join(),
        //   })
        // );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(dashboardActionCreator.getSpendCtrCpaFailure(errorMsg));
        // yield put(
        //   notificationActionCreator.setNotification({ message: errorMsg })
        // );
      }
    }
  } catch (err) {
    yield put(dashboardActionCreator.getSpendCtrCpaFailure("Server Error"));
  }
}

function* sendDashboardCampaignMetricDataRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      sendDashboardCampaignMetricDataRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(
        dashboardActionCreator.dashboardCampaignMetricDataSuccess(item)
      );
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(
          dashboardActionCreator.dashboardCampaignMetricDataFailure(
            errorMsg.join()
          )
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(
          dashboardActionCreator.dashboardCampaignMetricDataFailure(errorMsg)
        );
      }
    }
  } catch (error) {
    yield put(
      dashboardActionCreator.dashboardCampaignMetricDataFailure("Server Error")
    );
  }
}

function* getSpendClicksConversionRequestSaga(action) {
  try {
      const API_URL = yield select((state) => state.config.API_URL);
      const userData = yield select((state) => state.auth.userData);
      let jwtToken = userData && userData.token ? userData.token : null;
      const result = yield call(getSpendClicksConversionRequest, API_URL, jwtToken, action.payload);
      const { item, status } = result.data;
      if (status.status_type.toUpperCase() === 'SUCCESS') {
          yield put(dashboardActionCreator.getSpendClicksConversionSuccess(item));
      } else {
        const errorMsgList = status && status.status_message ? status.status_message : null;
        let errorMsg = "";
        if (typeof errorMsgList === "object") {
          errorMsg = Object.entries(errorMsgList).map((err) => {
            if (err instanceof Object)
              return `${err[0]}: ${JSON.stringify(err[1])}`;
            else return `${err[0]}: ${err[1]}`;
          });
          yield put(
            dashboardActionCreator.getSpendClicksConversionFailure(
              errorMsg.join()
            )
          );
          // yield put(
          //   notificationActionCreator.setNotification({
          //     message: errorMsg.join(),
          //   })
          // );
        } else {
          errorMsg =
            status && status.status_message ? status.status_message : null;
          yield put(
            dashboardActionCreator.getSpendClicksConversionFailure(errorMsg)
          );
          // yield put(
          //   notificationActionCreator.setNotification({ message: errorMsg })
          // );
        }
      }
  } catch (err) {
    yield put(
      dashboardActionCreator.getSpendClicksConversionFailure("Server Error")
    );
  }
}

function* getDSPCampaignsListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getDSPCampaignsListRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(dashboardActionCreator.getDSPCampaignsListSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(
          dashboardActionCreator.getDSPCampaignsListFailure(errorMsg.join())
        );
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(dashboardActionCreator.getDSPCampaignsListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(
      dashboardActionCreator.getDSPCampaignsListFailure("Server Error")
    );
  }
}

function* getDSPCampaignLineListRequestSaga(action) {
  try {
    const API_URL = yield select((state) => state.config.API_URL);
    const userData = yield select((state) => state.auth.userData);
    let jwtToken = userData && userData.token ? userData.token : null;
    const result = yield call(
      getDSPCampaignsLineListRequest,
      API_URL,
      jwtToken,
      action.payload
    );
    const { item, status } = result.data;
    if (status.status_type.toUpperCase() === "SUCCESS") {
      yield put(dashboardActionCreator.getDSPCampaignsLineListSuccess(item));
    } else {
      const errorMsgList = status && status.status_message ? status.status_message : null;
      let errorMsg = "";
      if (typeof errorMsgList === "object") {
        errorMsg = Object.entries(errorMsgList).map((err) => {
          if (err instanceof Object)
            return `${err[0]}: ${JSON.stringify(err[1])}`;
          else return `${err[0]}: ${err[1]}`;
        });
        yield put(dashboardActionCreator.getDSPCampaignsLineListFailure(errorMsg.join()));
      } else {
        errorMsg =
          status && status.status_message ? status.status_message : null;
        yield put(dashboardActionCreator.getDSPCampaignsLineListFailure(errorMsg));
      }
    }
  } catch (error) {
    yield put(dashboardActionCreator.getDSPCampaignsLineListFailure("Server Error"));
  }
}

export default function* dashboardSagas() {
  yield takeLatest(ACTIONS.DASHBOARD_REQUEST, sendDashboardRequestSaga);
  yield takeLatest(ACTIONS.GET_SPEND_CTR_CPA_REQUEST, getSpendCtrCpaRequestSaga);
  yield takeLatest(ACTIONS.GET_SPEND_CLICKS_CONVERSIONS_REQUEST, getSpendClicksConversionRequestSaga);
  yield takeLatest(ACTIONS.DSP_CAMPAIGNS_LIST_REQUEST, getDSPCampaignsListRequestSaga);
  yield takeLatest(ACTIONS.DSP_CAMPAIGN_LINE_LIST_REQUEST, getDSPCampaignLineListRequestSaga);  
  yield takeLatest(ACTIONS.DASHBOARD_CAMPAIGN_METRIC_DATA_REQUEST, sendDashboardCampaignMetricDataRequestSaga);
}

function sortArrayData (prop, asc, orders) {
  orders.sort(function(a, b) {
    if (asc) {
        return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
    } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
    }
});
  console.log(orders);
  return orders;
}