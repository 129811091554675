export const clickableScript = 
	`<script>
  let clickableElements = document.querySelectorAll("[data-interaction='click']");
  clickableElements && clickableElements.forEach(elem => {
    elem.addEventListener("click", function() {
      try {
				if(isPreview){
        let attributes = Object.values(this.attributes);
				const attr = attributes && attributes.find( attr => attr && attr.nodeName === 'data-url' );
        if (attr.nodeValue) {
          let win = window.open( clickTrack(attr.nodeValue), '_blank' );
          win.focus();
				}
			}
      } catch (e) {
        return false;
      }
    });
	});
	function clickTrack(rurl) {
		if(rurl){
			try{
				var _rurl = encodeURIComponent(rurl);
				let redirectURL = 'API_URL';
				return (redirectURL+'&event_type=ADCL&redirect_url='+_rurl);
			}
			catch(e){
				console.log(e);
			}
		}
	
	};
</script>
`
