import ACTIONS from "../constants/actionTypes";


export const sendResetPasswordRequest = (data) => {
  return {
    type: ACTIONS.RESET_PASSWORD_REQUEST,
    payload: data,
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: ACTIONS.RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetPasswordFailure = (errors) => {
  return {
    type: ACTIONS.RESET_PASSWORD_FAILURE,
    payload: errors,
  };
};

export const resetPasswordFailureClear = () => {
  return {
    type: ACTIONS.RESET_PASSWORD_FAILURE_CLEAR,
  };
};

export const sendSignInRequest = (data) => {
  return {
    type: ACTIONS.SIGNIN_REQUEST,
    payload: data,
  };
};

export const signInSuccess = (data) => {
  return {
    type: ACTIONS.SIGNIN_SUCCESS,
    payload: data,
  };
};

export const signInFailure = (errors) => {
  return {
    type: ACTIONS.SIGNIN_FAILURE,
    payload: errors,
  };
};

export const signInFailureClear = () => {
  return {
    type: ACTIONS.SIGNIN_FAILURE_CLEAR,
  };
};

export const sendSignUpRequest = (data) => {
  return {
    type: ACTIONS.SIGNUP_REQUEST,
    payload: data,
  };
};

export const signUpSuccess = (data) => {
  return {
    type: ACTIONS.SIGNUP_SUCCESS,
    payload: data,
  };
};

export const signUpFailure = (errors) => {
  return {
    type: ACTIONS.SIGNUP_FAILURE,
    payload: errors,
  };
};

export const signUpFailureClear = () => {
  return {
    type: ACTIONS.SIGNUP_FAILURE_CLEAR,
  };
};

export const logOutRequest = () => {
  return {
    type: ACTIONS.LOG_OUT_REQUEST,
  };
};

export const logOutSuccess = () => {
  return {
    type: ACTIONS.LOG_OUT_SUCCESS,
  };
};

export const forgotPasswordRequest = (data) => {
  return {
    type: ACTIONS.FORGOTPASSWORD_REQUEST,
    payload: data,
  };
};

export const forgotPasswordSuccess = (data) => {
  return {
    type: ACTIONS.FORGOTPASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgotPasswordFailure = (errors) => {
  return {
    type: ACTIONS.FORGOTPASSWORD_FAILURE,
    payload: errors,
  };
};

export const forgotPasswordFailureClear = () => {
  return {
    type: ACTIONS.FORGOTPASSWORD_FAILURE_CLEAR,
  };
};

export const forgotPasswordReset = () => {
  return {
    type: ACTIONS.FORGOTPASSWORD_RESET,
  };
};

export const updateUserInfo = (data) => {
  return {
    type: ACTIONS.UPDATE_USER_INFO,
    payload: data
  }
}

//SET/RESET RBAC
export const setRBAC = (data) => {
  return {
    type: ACTIONS.SET_RBAC,
    payload: data
  };
};

export const resetRBAC = () => {
  return {
    type: ACTIONS.RESET_RBAC,
  }
}