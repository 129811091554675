import { takeLatest, call, select, put } from "redux-saga/effects";
import { campaignsActionCreator } from "../actions";
import ACTIONS from "../constants/actionTypes";
import axios from "axios";

function sendCampaignsListRequest(API_URL_PARAM, jwtToken, data) {
    //const API_URL = API_URL_PARAM + "/v1/dsp_campaigns?source=1&limit=100";
    let API_URL = "";
    const param = {};
    if (data?.organization) {
      param.organization = data.organization;
    }

    if (data?.searchKey) {
      let encodeString = data.searchKey.replace(/&/g, "%26").replace(/#/g, "%23");
      param.search = encodeString;
    }
    API_URL = API_URL_PARAM + "/v1/dsp_campaigns?limit=100";

    return axios({
      url: API_URL,
      method: "GET",
      params: param,
      headers: {
        Authorization: jwtToken,
      },
      withCredentials: true,
    }).catch((err) => {
      console.log(err);
    });
  }
  
  function* sendCampaignsListRequestSaga(action) {
    try {
      const API_URL = yield select((state) => state.config.API_URL);
      const userData = yield select((state) => state.auth.userData);
      const data = action.payload ? action.payload : "";
      let jwtToken = userData && userData.token ? userData.token : null;
      const result = yield call(
        sendCampaignsListRequest,
        API_URL,
        jwtToken,
        data
      );
      const { item, status } = result.data;
      if (status.status_type.toUpperCase() === "SUCCESS") {
        yield put(campaignsActionCreator.getCampaignsListSuccess(item));
      } else {
        const errorMsgList =
          status && status.status_message ? status.status_message : null;
        let errorMsg = "";
        if (typeof errorMsgList === "object") {
          errorMsg = Object.entries(errorMsgList).map((err) => {
            if (err instanceof Object)
              return `${err[0]}: ${JSON.stringify(err[1])}`;
            else return `${err[0]}: ${err[1]}`;
          });
          yield put(campaignsActionCreator.getCampaignsListFailure(errorMsg.join()));
        } else {
          errorMsg =
            status && status.status_message ? status.status_message : null;
          yield put(campaignsActionCreator.getCampaignsListFailure(errorMsg));
        }
      }
    } catch (error) {
      yield put(campaignsActionCreator.getCampaignsListFailure("Server Error"));
    }
  }
  
  export default function*campaignSagas() {
    yield takeLatest(ACTIONS.GET_CAMPAIGNS_LIST_REQUEST, sendCampaignsListRequestSaga);
  }