import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  FormFeedback,
} from "reactstrap";
import { notificationActionCreator, reportBuilderActionCreator } from "../../../actions";
import ADspinner from "../../Loader/ADspinner";
import Validator from "../../../utils/validators";
import SkeletonTable from "../../Loader_Skeleton/SkeletonTable";
import PopUpModal from "../../PopUpModal";
import TemplateSaveAs from "../TemplateSaveAs";
import RBAC from "../../RBAC";
import { PERMISSIONS } from "../../../utils/permissionMatrix";
import "./index.scss";

export const STATES = [
  { id: 1, state: "default" }, //default textbox is empty
  { id: 2, state: "update" }, //user start updating selected template
  { id: 3, state: "selected" }, //user selected a template from dropdown
]

const ReportTemplate = ({ isFormValid, getReportTemplateData, report_template, currentTemplateState, handleFormCancel }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reportBuilder.templatesFetching);
  const templateList = useSelector((state) => state.reportBuilder.reportTemplateList);
  const selectedTemplate = useSelector((state) => state.reportBuilder.selectedTemplate);
  const isTemplateUpdating = useSelector((state) => state.reportBuilder.isTemplateUpdating);
  const isTemplateUpdated = useSelector((state) => state.reportBuilder.isTemplateUpdated);
  const isTemplateDeleting = useSelector((state) => state.reportBuilder.isTemplateDeleting);
  const isTemplateDeleted = useSelector((state) => state.reportBuilder.isTemplateDeleted);
  const isTemplateSaving = useSelector((state) => state.reportBuilder.isTemplateSaving);
  const isTemplateSaved = useSelector((state) => state.reportBuilder.isTemplateSaved);

  const ICONS = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    CHECKED_BLUE_ICON: "/Icon_assets/svg/checked_blue_Icon.svg",
  }

  //validation object
  const validatorsObj = new Validator();
  const [validators, setstate] = useState(validatorsObj);
  const [isValid, setIsValid] = useState(false);

  const updateValidations = (fieldName, value) => {
    return validators.isValueValid(fieldName, value);
  };

  const [dropdown, setDropdown] = useState(false);
  const toggledropdown = () => setDropdown(prevState => !prevState);

  const intialValue = (Object.keys(selectedTemplate).length === 0) ? "" : selectedTemplate.name;
  const [inputText, setInputText] = useState(intialValue);
  const [currentState, setCurrentState] = useState(currentTemplateState);
  const [modal, setModal] = useState(false);
  const [deleteTempModal, setDeleteTempModal] = useState(false);

  const handleInputText = (e) => {
    e.preventDefault();
    const checkIsValid = updateValidations(e.target.name, e.target.value);
    setIsValid(checkIsValid);
    setInputText(e.target.value);
    (selectedTemplate.hasOwnProperty('name')) ? setCurrentState(STATES[1]) : setCurrentState(STATES[0])
    debounceCall(e)
  }

  /**handle debounce functionality start**/
  const handleSearch = (e) => {
    dispatch(reportBuilderActionCreator.getReportTemplates({ search: e.target.value })) //search in the list
  }

  const debounce = (func, wait = 300) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debounceCall = useCallback(debounce(handleSearch), [])
  /**handle debounce functionality end**/

  const handleReportTemplateSelect = (e, item) => {
    e.preventDefault();
    dispatch(reportBuilderActionCreator.setReportTemplate(item))
    setInputText(item.name)
    validators.name.errorMsg = undefined
    setCurrentState(STATES[2])
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setInputText("")
    setIsValid(false)
    setCurrentState(STATES[0])
    handleFormCancel();
    dispatch(reportBuilderActionCreator.resetReportTemplate())
    dispatch(reportBuilderActionCreator.getReportTemplates()) //update the list
  }

  const handleSave = (e) => {
    e.preventDefault();
    const updated_report_template = getReportTemplateData(e); //get the report_template data
    if (updated_report_template) {
      updated_report_template.custom_params.name = inputText;
      dispatch(reportBuilderActionCreator.saveReportTemplateRequest({ ...updated_report_template, method: "POST", state: "save" }))
    } else {
      dispatch(notificationActionCreator.setNotification({ message: "Please fill out all required fields.", position: "fixed" }))
    }
  }

  const handleSaveAs = (e) => {
    e.preventDefault();
    getReportTemplateData(e); //get the report_template data
    setModal(true) //open save as popup
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    const updated_report_template = getReportTemplateData(e); //get the report_template data
    if (updated_report_template) {
      updated_report_template.custom_params.name = inputText;
      dispatch(reportBuilderActionCreator.updateReportTemplateRequest({ ...updated_report_template, id: selectedTemplate.id, method: "PUT" }))
    } else {
      dispatch(notificationActionCreator.setNotification({ message: "Please fill out all required fields.", position: "fixed" }))
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();
    if (selectedTemplate.id) {
      dispatch(reportBuilderActionCreator.deleteReportTemplateRequest([selectedTemplate.id]))
    } else {
      dispatch(notificationActionCreator.setNotification({ message: "Please select the template.", position: "fixed" }))
    }
  }

  const defaultState = () => {
    dispatch(reportBuilderActionCreator.deleteReportTemplatesFailurClear())// reset the flags
    dispatch(reportBuilderActionCreator.updateReportTemplatesFailurClear())// reset the flags
    dispatch(reportBuilderActionCreator.getReportTemplates()) //update the list
    dispatch(reportBuilderActionCreator.resetReportTemplate()) //selected template removed
    setCurrentState(STATES[0]) //set state to "default"
    setInputText("") //textbox clear
    setIsValid(false)//form default state
  }

  //onload
  useEffect(() => {
    if (Object.keys(selectedTemplate).length === 0)
      defaultState();
  }, []);

  //on update
  useEffect(() => {
    if (isTemplateSaved) {
      dispatch(reportBuilderActionCreator.saveReportTemplatesFailurClear())// reset the flags on save success
      if (modal) { //saveAs success
        setCurrentState(STATES[1]) //set state to "update"
      } else {
        setCurrentState(STATES[2]) //set state to "selected"
      }
    }

    if (isTemplateUpdated) {
      dispatch(reportBuilderActionCreator.updateReportTemplatesFailurClear())// reset the flags on update success
      setCurrentState(STATES[2]) //set state to "selected"
    }

    dispatch(reportBuilderActionCreator.getReportTemplates()) //update the list

    if (isTemplateDeleted) {
      defaultState();
      handleFormCancel();
      setDeleteTempModal(false);
    }
  }, [isTemplateUpdated, isTemplateDeleted, isTemplateSaved]);

  useEffect(() => {
    setCurrentState(currentTemplateState)
  }, [currentTemplateState])

  return (<>
    <Row className="template-row">
      <Col md={12} sm={12} lg={4}>
        <div className="option-selector">
          <Dropdown
            isOpen={dropdown}
            toggle={toggledropdown}
            className="dropdown-option"
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              className="dropdown_toggle"
            >
              <span
                className="dropdown_title"
                title={"Select template"}
              >
                <Input
                  value={inputText}
                  type="text"
                  name="name"
                  id="name"
                  className="value-input"
                  autoComplete="off"
                  onChange={(e) => handleInputText(e)}
                  placeholder={(currentState.id === STATES[1].id || currentState.id === STATES[2].id) ? selectedTemplate.name : "Select/Create a template"}
                  invalid={validators.name.errorMsg !== ""}
                />
              </span>
              <img
                src={
                  dropdown
                    ? ICONS.ARROW_UP
                    : ICONS.ARROW_DOWN
                }
                className="dropdown-img"
              ></img>
            </DropdownToggle>
            <DropdownMenu>
              <div className="templates-container">
                {isLoading ? (<DropdownItem style={{ padding: "5px 0 5px 10px" }}><SkeletonTable showLine={false} /></DropdownItem>) : (
                  (templateList && templateList.length > 0) ? templateList.map((item) => {
                    return (
                      <DropdownItem
                        key={item.id}
                        onClick={(e) => handleReportTemplateSelect(e, item)}
                        className={`d-flex justify-content-between align-items-center ${item.id === selectedTemplate?.id ? "dropdown-option-selected" : ""}`}
                      >
                        <span className="template-name" title={item.name}>{item.name}</span>
                        {item.id === selectedTemplate?.id ? (<img
                          src={ICONS.CHECKED_BLUE_ICON}
                          alt="dimension selected"
                          className="option-selected"
                        />) : ""}
                      </DropdownItem>
                    )
                  }) : (<DropdownItem>No result found</DropdownItem>)
                )}
              </div>

            </DropdownMenu>
          </Dropdown>
          <FormFeedback>{validators.name.errorMsg}</FormFeedback>
        </div>
      </Col>
      <Col md={12} sm={12} lg={6}>
        <div className="option-selector">
        <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE} >
          {currentState.id === STATES[0].id && (<>
            <Button
              className="in-btn-lg in-btn-info btn-template-action save"
              type="submit"
              disabled={isFormValid && isValid ? false : true}
              onClick={handleSave}
              style={{ minWidth: isTemplateSaving ? "150px" : "" }}
            >
              {isTemplateSaving ? <ADspinner size="small" /> : "Save Template"}
            </Button>
          </>)}
          </RBAC>

          <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_DELETE} >
          {(currentState.id === STATES[1].id || currentState.id === STATES[2].id) && (<>
            <Button
              className="in-btn-lg in-btn-info btn-template-action delete"
              type="submit"
              //disabled={isFormValid ? false : true}
              onClick={() => setDeleteTempModal(true)}
            >
              {isTemplateDeleting ? <ADspinner size="small" /> : "Delete"}
            </Button>
          </>)}
          </RBAC>

          <RBAC requiredPermissions={PERMISSIONS.MEDIA_REPORT_BUILDER_WRITE} >
            {currentState.id === STATES[1].id && (<>
              <Button
                className="in-btn-lg in-btn-info btn-template-action update"
                type="submit"
                disabled={isFormValid ? false : true}
                onClick={handleUpdate}
                style={{ minWidth: isTemplateUpdating ? "100px" : "" }}
              >
                {isTemplateUpdating ? <ADspinner size="small" /> : "Update"}
              </Button>
            </>)}
            {currentState.id === STATES[1].id && (<>
              <Button
                className="in-btn-lg in-btn-info btn-template-action saveAs"
                type="submit"
                disabled={isFormValid ? false : true}
                onClick={handleSaveAs}
              >
                {"Save As"}
              </Button>
            </>)}
          </RBAC>
          
          <Button
            className="in-btn-primary inverse btn-template-action cancel"
            onClick={handleCancel}
            disabled={(inputText ? false : true)}
          >
            Cancel
          </Button>
        </div>
      </Col>
    </Row>
    <PopUpModal
      className={'save-as-template'}
      title={"Save Template As"}
      openPopUp={modal}
      setOpenPopUp={setModal}
    >
      <TemplateSaveAs
        setOpenPopUp={setModal}
        report_template={report_template}
      />
    </PopUpModal>

    <PopUpModal
      className={'delete-template'}
      title={"Delete Template"}
      openPopUp={deleteTempModal}
      setOpenPopUp={setDeleteTempModal}
    >
      <>
        <div className="template-delete-container">
          <p>Are you sure you want to delete this template?</p>
        </div>
        <Col md={12} sm={12} lg={12} className="pl-0">
          <div className="delete-actions">
            <Button
              className="in-btn-lg in-btn-info btn-save btn-no"
              onClick={() => setDeleteTempModal(false)}
            >
              {'No'}
            </Button>
            <Button
              className="in-btn-link btn btn-link btn-clear btn-cancel"
              onClick={handleDelete}
              disabled={isTemplateDeleting}
            >
              {'Yes, Delete'}
            </Button>
          </div>
        </Col>
      </>
    </PopUpModal>
  </>)
}

export default ReportTemplate;