import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import createInitialState from './initialState';

export default function configureStore() {
	const initialState = createInitialState();
	const sagaMiddleware = createSagaMiddleware();
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));
	sagaMiddleware.run(rootSaga);
	return store;
}