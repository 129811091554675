import React, { useState, createRef, useRef } from 'react';
import "./index.scss";
import { Progress, UncontrolledTooltip, Table, Tooltip, CustomInput } from 'reactstrap';

const TABLE_COLUMNS = [
    {
        dataField: "id",
        text: "#",
        className: "col-id"
    },
    {
        dataField: "option",
        text: "Answers",
        className: "col-option"
    },
    {
        dataField: "percent",
        text: "Answers (%)",
        className: "col-percent"
    },
    {
        dataField: "index",
        text: "Index",
        className: "col-index"
    },
    {
        dataField: "count",
        text: "Count",
        className: "col-count"
    },
];

const DetailsRow = ({ data, selected, selectedOption, qId, total, onhandleAnsClick, selectedAnswer, qSelectionData }) => {
    const rowRefs = useRef([]);
    const [hoverStatus, setHover] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const compareSize = (e, textElementRef) => {
        if (textElementRef && textElementRef.current &&
            textElementRef.current.parentElement) {
            var el = textElementRef.current.parentElement;
            const compare = el.offsetWidth < el.scrollWidth;
            setHover(compare);
            e.stopPropagation()
        }
    };


    const getAnswerId = (ansId) => {
        var str = ansId;
        var matches = str.match(/(\d+)/);
        return `A${matches[0]}`
    }


    const handleRowClick = (e, itemId, itemCount) => {
        e.preventDefault();
        const selectedRowStateArr = [...selectedAnswer];

        //if itemId is not availble in array add it else remove it
        if ((itemCount === 0 && selectedRowStateArr.length > 0) || itemCount !== 0) {
            !selectedRowStateArr.includes(itemId) ?
                selectedRowStateArr.push(itemId) :
                selectedRowStateArr.splice(selectedRowStateArr.indexOf(itemId), 1);

            //send selected array to parent
            onhandleAnsClick(e, selectedRowStateArr);
        }

    }

    return (
        <>
            <Table className="table survey-details-tbl">
                <thead>
                    <tr>

                        {(qSelectionData?.isSelectable || selectedAnswer?.filter(s => s.includes(data?.qId))?.length > 0) && <th key={-1} >

                        </th>}

                        {TABLE_COLUMNS.map((d, i) => {
                            if (d.text === "Index")
                                return (<>
                                    <th key={i} className={d.className}>{d.text}
                                        <img src={"/Icon_assets/svg/info-icon.svg"} alt="Info icon" id="opentooltip" className="index-tip" />
                                        <Tooltip
                                            placement="top"
                                            className="custom-file-upload-input-tooltip"
                                            isOpen={tooltipOpen}
                                            target="opentooltip"
                                            toggle={toggle}
                                        >A value above 100% <br />indicates over-indexing, <br />while a value below 100% <br />indicates under-indexing.</Tooltip>
                                    </th>
                                </>)


                            return <th key={i} className={d.className + ' align-middle'}>{d.text}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        (data.options.map((item, a) => {

                            rowRefs.current = data.options.map((element, i) => rowRefs.current[i] ?? createRef());
                            return (
                                <tr key={`detailRow-${item.id}`} onClick={(e) => { /*handleRowClick(e, item.id, item.count)*/ }}
                                    className={`${(selected === item.value && selected !== 0) ? 'selected' : ''} ${selectedAnswer.includes(item.id) ? ' rowSelected' : ''}`}>
                                    {(qSelectionData?.isSelectable || selectedAnswer?.filter(s => s.includes(data?.qId))?.length > 0) && <td className={'align-middle '} key={`detailRow-${item.id}`} >
                                        <CustomInput
                                            type="checkbox"
                                            id={`survey-details-tbl-checkbox-detailRow-${item.id}`}
                                            checked={selectedAnswer.includes(item.id)}
                                            onChange={(e) => { handleRowClick(e, item.id, item.count) }}
                                        />
                                    </td>}
                                    <td className="col-height align-middle">
                                        <span
                                        >{item.id.split('_')[1] ? getAnswerId(item.id.split('_')[1]) : `A${a + 1}`}
                                        </span>
                                    </td>
                                    <td className="answers-column col-height align-middle">
                                        <span id={item.id}
                                            ref={rowRefs.current[a]}
                                            onMouseEnter={(e) => compareSize(e, rowRefs.current[a])}
                                            onMouseLeave={(e) => compareSize(e, rowRefs.current[a])}>
                                            {item.label}
                                        </span>
                                        {
                                            hoverStatus &&
                                            <UncontrolledTooltip
                                                className="survey-tool-tip"
                                                placement="top"
                                                target={item.id}
                                            >{item.label}
                                            </UncontrolledTooltip>

                                        }
                                    </td>

                                    <td className="percent-column col-height align-middle progress-column">
                                        <span
                                            style={{ width: '70px', float: "left", paddingRight: "15px" }}>
                                            {item.value}{'%'} </span>
                                        <span
                                            className={selected === item.value && item.label === selectedOption ? 'selected' : ''}>
                                            <Progress value={item.value === 0 ? item.value + 1 : item.value} />
                                        </span>

                                    </td>

                                    <td className="percent-column col-height align-middle">
                                        <span>
                                            {item["ans-index"]}{'%'}
                                        </span>
                                    </td>

                                    <td className="status-column col-height align-middle">
                                        <span>{item.count}
                                        </span>
                                    </td>
                                </tr>
                            )

                        }))
                    }
                </tbody>
            </Table>
            <div className="survey-total">
                <span className="" >{'Total : '}</span>
                <span className="" style={{ fontWeight: 'bold' }}>{total}</span>
            </div>
        </>);
}
export default DetailsRow;