import React from "react";
import { Button } from "reactstrap";
import Loader from '../../../Loader';
import "./index.scss";

const UniversalTag = ({isUniversalTagFetching, universalTagScriptData, setUniversalTagModal}) => {

  const copyPixelCode = () => {
		window.getSelection().selectAllChildren(document.getElementById('universalScriptSection'));
		document.execCommand('Copy');
		setUniversalTagModal(false);
	}

  return (
    <section className="universal-tag-body-content">
      {
        isUniversalTagFetching ? (
            <Loader />
        ) :
        (
          <>
            <nav className="universal-script-section" id="universalScriptSection">
              {
                (universalTagScriptData && Object.keys(universalTagScriptData).length) ? (
                  (`<!-- Invisibly Pixel Code -->\n` + universalTagScriptData.universal_tag)
                ):(
                  <p>Ad does not support Universal Tag.</p>
                )
              }
            </nav>
            <nav className="btn-wrapper">
              <Button className="in-btn-sm in-btn-primary" onClick={() => copyPixelCode()}>
                Copy Code
              </Button>
              <Button color="link" className="in-btn-link" onClick={() => setUniversalTagModal(false)}>
                Cancel
              </Button>
            </nav>
        </>
        )
      }
    </section>
  )
}

export default UniversalTag;