import React, { useState } from 'react';

const AnalyticsAction = ({ goToResearchDetails, data, actionIcons }) => {
  const [analyticsImage, setanAlyticsImage] = useState(actionIcons.ANALYTICS);
  return (
    <span
      style={{ cursor: data.ad_state !== 0 ? "pointer" : "default" }}
      className="align-span"
      onClick={(e) => {
        e.stopPropagation();
        data.ad_state !== 0 ?
          goToResearchDetails(e, data.id, data.brand_v2 ? data.brand_v2.id : "",
            data.name, data.brand_v2 && data.brand_v2.organization ?
            data.brand_v2.organization.name : "",
            data.brand_v2.organization ?
              data.brand_v2.organization.id : "", data.rtr_type, data) : e.preventDefault();
      }}
      onMouseEnter={() => { data.ad_state !== 0 ? setanAlyticsImage(actionIcons.ANALYTICS_ACTIVE) : setanAlyticsImage(actionIcons.ANALYTICS) }}
      onMouseLeave={() => { setanAlyticsImage(actionIcons.ANALYTICS) }}
    >
      <img src={analyticsImage} className="analytics-icon" alt="status icon"
      />
    </span>
  )
}

export default AnalyticsAction;