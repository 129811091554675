import { fork, all } from "redux-saga/effects";
import authSagas from "./authSagas";
import userSagas from "./userSagas";
import dashboardSagas from "./dashboardSagas";
import realtimeResearchSagas from "./realtimeResearchSagas";
import advertiserSagas from "./advertiserSagas";
import organizationSagas from "./organizationSagas";
import reportBuilderSagas from "./reportBuilderSagas";
import campaignSagas from "./campaignSagas";
import lineSagas from "./lineSagas";
import adSagas from "./adSagas";
import surveySagas from "./surveySaga";
import brandsSagas from "./brandsSagas";
import conversionSaga from "./conversionSaga";
import offerSaga from './offerSaga';
import sheduleReportSagas from "./scheduleReportSagas";
import filtersSaga from './filtersSaga'


export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(userSagas),
    fork(dashboardSagas),
    fork(realtimeResearchSagas),
    fork(advertiserSagas),
    fork(organizationSagas),
    fork(reportBuilderSagas),
    fork(sheduleReportSagas),
    fork(campaignSagas),
    fork(lineSagas),
    fork(adSagas),
    fork(surveySagas),
    fork(brandsSagas),
    fork(conversionSaga),
    fork(offerSaga),
    fork(filtersSaga)
  ]);
}
