import React, { useState, useEffect, useRef } from 'react';
import "./index.scss";
import { useParams, useLocation } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import ROUTES from "../../../utils/routeConstants";
import history from "../../../history";
import { notificationActionCreator, surveyActionCreator, realtimeResearchActionCreator, offerActionCreator, scheduleReportActionCreator } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
// import { fs } from 'fs'
import Stepper from "../../Stepper/Stepper";
import { clickableScript } from "../../../utils/previewMessageClickableScript";
import { getAspectRatio } from "./getAspectRatio";
import ADspinner from "../../Loader/ADspinner";
import LeaveCampaignModal from './LeaveCampaignModal';
import Validator from "../../../utils/validators";
import ToastUploadModal from './ToastUploadModal';
import UploadTemplate from './UploadTemplate';
import GeneralSetup from './GeneralSetup';
import Preview from './Preview';
import SurveyComplete from "./SurveyComplete";
import OfferSetup from './OfferSetup';
import RTRPreview from "./RTRPreview";
import Builder from "./Builder";
import PopUpModal from '../../PopUpModal';
import { RTRBuilderResponseToJSON, RTRBuilderPayloadToJSON, RTRBuilderTaxononyPayload, compare, RTRBuilderTaxononyResponse } from "../../../utils/parsers";

let validators = new Validator();
const fs = require('fs');

const RealTimeCampaign = () => {

  const VALUES = {
    STANDARD: "Standard",
    BRANCHING: "Branching"
  };

  const RTR_TYPE = {
    PROGRAMMATIC: "Programmatic",
    INVISIBLY: "Invisibly",
    BOTH: "Both"
  }

  const UNITS = {
    INVISIBLY: "invisibly",
    TEXT: "text"
  };

  let location = useLocation();
  let { preview } = useParams();
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [fileDataObject, setFileDataObject] = useState({ 'ad_size': {} });
  const [offerFileDataObject, setOfferFileDataObject] = useState({});
  const [headerImage, setHeaderImage] = useState(false);
  const [logoImage, setLogoImage] = useState(false);
  const [activationData, setActivationData] = useState({
    brand_id: "",
    message_id: "", ad_state: '', pixel: "", tags: "",
    tracking_tag: "", external_msg_id: "",
    name: ""
  });
  const [draftData, setDraftData] = useState();
  const [draftCalled, setDraftCalled] = useState(false);
  const [templateType, setTemplateType] = useState(VALUES.STANDARD);

  const [templateTooltipOpen, setTemplateTooltipOpen] = useState(false);
  const templateToggle = () => setTemplateTooltipOpen(!templateTooltipOpen);
  const [editCampaign, setEditCampaign] = useState(false);
  const [isActiveOrPaused, setIsActiveOrPaused] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [platformPreview, setPlatformPreview] = useState(1);
  const [headerImgContent, setHeaderImgContent] = useState();
  const [logoImgContent, setLogoImgContent] = useState();

  const closeCSVUpload = () => {
    setDisplayUploadInfo(false)
  }
  const closeModal = () => {
    setShowPopup(false)
  }

  const selectTemplateType = (e, type) => {
    removeSelection(e, 'file');
    setTemplateType(type);
  }

  const closeToastModal = () => {
    setDisplayToastInfo(false);
  }

  const activateExternalAd = () => {
    var params = {};
    params.brand_id = localStorage.getItem('brand_id');
    params.message_id = localStorage.getItem('msg_id');
    params.ad_state = 4;
    params.pixel = "";
    params.tags = null;
    params.tracking_tag = "";
    params.ad_id = activationData.external_msg_id;
    params.name = activationData.name;

    dispatch(surveyActionCreator.activateAdRequest(params));
  }

  const reloadIframe = () => {
    var iframe = document.getElementsByName('frame')[0];
    iframe.srcdoc = iframe.srcdoc;
  }

  const reloadPreviousScreen = () => {
    setActiveStep(1);
  }

  const refreshIframe = () => {
    setIsLoading(true);
    var params = {};
    if (selectedOrganization) {
      params.organization_id = selectedOrganization?.id;
    }
    params.brands = localStorage.getItem('brand_id');
    params.messages = localStorage.getItem('msg_id');
    if (params.brands && params.messages && activeStep == 1) {
      dispatch(surveyActionCreator.getPreviewRequest(params));
    }
  }

  const markSurveyComplete = () => {
    setSurveyComplete(true);
    setActiveStep(3);
  }

  const copyLink = () => {
    var copyText = document.getElementById("shareable-link");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const goToRTRDashboard = () => {
    // no changes made on page go to RTR
    if ((JSON.stringify(initialState) === JSON.stringify(inputValues)) ||
      (JSON.stringify(draftData) === JSON.stringify(inputValues))) {
      history.push(`${ROUTES.REALTIME_RESEARCH}`);
    } else {
      setShowPopup(true);
    }
  }

  const toggle = () => {
    setSearchValue("");
    let param = {};
    if (!dropdownOpen) {
      // dispatch(advertiserActionCreator.advertisersListRequest());
      if (selectedOrganization) {
        param.organization = selectedOrganization.id;
      }
      dispatch(realtimeResearchActionCreator.rtrListRequest(param));
    }
    setDropdownOpen(prevState => !prevState);
  }
  const [searchValue, setSearchValue] = useState('');
  const [advertiser, setAdvertiser] = useState('');
  const [displayUploadInfo, setDisplayUploadInfo] = useState(false);
  const [displayToastInfo, setDisplayToastInfo] = useState(false);
  const [disable, setDisable] = useState(true);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [frame, setFrame] = useState("");
  const [shadow, setShadow] = useState("2px 8px 12px rgb(19 36 139 / 4%)");
  const [previewHtml, setPreviewHtml] = useState("<div>No Previews Available. Create message and try again...</div>");
  const [isLoading, setIsLoading] = useState(false);
  const [initiator, setInitiator] = useState('btn-draft');
  const msgbodyStyle = { height: 'auto', background: "#9d5cff", overflow: "auto", marginTop: "0", minHeight: "400px" };
  const msgbodyStyle1 = { height: '100%', overflow: "auto", marginTop: "0", minHeight: "400px" };
  const [fileName, setFileName] = useState({});
  const [toastFileName, setToastFileName] = useState({});
  const [displayToastSection, setDisplayToastSection] = useState(true);
  const questionsRef = useRef();
  const toastsRef = useRef();
  const [questionModal, setQuestionModal] = useState(false)
  const [toastModal, setToastModal] = useState(false)


  const updateToastFile = (param) => {
    // call api to upload toast file. and remove the below code.
    let inputField = { ...inputValues };
    setToastFileName(param.file);
    // setDisplayToastInfo(false);
    inputField["selectedToastFiles"] = param.file;
    setInputValues(inputField);
    param.organization_id = selectedOrganization?.id || localStorage.getItem("organization_id");
    dispatch(surveyActionCreator.uploadToastRequest(param));
  }
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    let value = event.target.value.toLowerCase();
    let result = [];
    result = advertiserData && advertiserData.filter((data) => {
      return data.name.toLowerCase().search(value) != -1;
    });

    var d = [...result];
    setAdvertiserList(d);
  }

  const statusIcons = {
    ARROW_UP: "/Icon_assets/svg/arrow-up.svg",
    ARROW_DOWN: "/Icon_assets/svg/arrow-down.svg",
    GO_BACK_ICON: "/Icon_assets/svg/back_icon.svg",
    GENERAL_SETTING_ICON: "/Icon_assets/svg/general-settings-icon.svg",
  }
  const dispatch = useDispatch();

  const saveCampaign = (elemId) => {
    setInitiator(elemId);

    if (JSON.stringify(draftData) !== JSON.stringify(inputValues) && draftCalled) {
      // setGoToPreview(false);
      let platform = bothPlatformData?.ads?.length;
      let invisiblyInfo, programmaticInfo;
      if (platform == 2) {
        invisiblyInfo = bothPlatformData?.ads?.filter((ac) => ac.platform_value === 2)?.[0];
        programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.platform_value === 1)?.[0];
      } else if (bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 0)?.length > 0) { // invisibly type
        programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 0)?.[0];
      } else {
        programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 1)?.[0];
      }


      var param =
        inputValues.platform === RTR_TYPE.BOTH ?
          {
            organization_id: selectedOrganization?.id,
            "name": inputValues.campaignName,
            "brand_id": inputValues.brand_id,
            "pixel": inputValues.pixelCode,
            "survey_csv": inputValues.survey_csv,
            ads: [{
              // update this logic to call update pass respective external message id
              rtr_type: 2,
              'external_message_id': programmaticInfo?.id,
              'message_id': programmaticInfo?.message,
              "toast_csv": inputValues?.toast_csv,
              "ad_size": Number(inputValues?.ad_size),
              "brand_id": inputValues?.brand_id,
              platform_value: 1,
            }, {
              rtr_type: 2,
              'external_message_id': invisiblyInfo?.id,
              'message_id': invisiblyInfo?.message,
              "brand_offer": inputValues?.brand_offer,
              platform_value: 2,
              "brand_id": inputValues.brand_id,
            }]
          }
          :
          inputValues.platform === RTR_TYPE.PROGRAMMATIC ?
            {
              organization_id: selectedOrganization?.id,
              "name": inputValues.campaignName,
              "brand_id": inputValues.brand_id,
              "pixel": inputValues.pixelCode,
              "survey_csv": inputValues.survey_csv,
              ads: [{
                rtr_type: 1,
                'external_message_id': programmaticInfo?.id,
                'message_id': programmaticInfo?.message,
                "toast_csv": inputValues.toast_csv,
                "ad_size": Number(inputValues.ad_size)
              }]
            }
            :
            {
              organization_id: selectedOrganization?.id,
              "name": inputValues.campaignName,
              "brand_id": inputValues.brand_id,
              "pixel": inputValues.pixelCode,
              "survey_csv": inputValues.survey_csv,
              ads: [{
                rtr_type: 0,
                'external_message_id': programmaticInfo?.id,
                'message_id': programmaticInfo?.message,
                // "ad_size": 0,
                "brand_offer": inputValues.brand_offer
              }]
            }

      if (inputValues.platform === RTR_TYPE.BOTH) {
        var updateParam = param.ads.filter(d => d.platform_value === 1)?.[0];
        if (inputValues.headerImage) updateParam.header_image = inputValues.header_image
        if (inputValues.headerUrl) updateParam.header_image_dest_url = inputValues.headerUrl
        if (inputValues.logoImage) updateParam.logo_image = inputValues.logo_image
        if (inputValues.logoUrl) updateParam.logo_image_dest_url = inputValues.logoUrl
      }

      if (inputValues.headerImage && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["header_image"] = inputValues.header_image
      if (inputValues.headerUrl && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["header_image_dest_url"] = inputValues.headerUrl

      if (inputValues.logoImage && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["logo_image"] = inputValues.logo_image;
      if (inputValues.logoUrl && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["logo_image_dest_url"] = inputValues.logoUrl;

      if (inputValues.platform === RTR_TYPE.INVISIBLY) {
        inputValues.brand_offer.offer_name = inputValues.campaignName;
        inputValues.brand_offer.brand = inputValues.brand_id;
        inputValues.brand_offer.id = inputValues.brand_offer.id;
        if (inputValues?.ad_template_type) {
          param.ad_template_type = JSON.parse(inputValues.ad_template_type);
        }
      }
      localStorage.setItem("brand_id", param.brand_id);
      dispatch(surveyActionCreator.updateDraftedAdRequest(param));
    }
    else if (!draftCalled) {
      // prepare api request for submit ad
      var param =
        inputValues.platform === RTR_TYPE.BOTH ?
          {
            organization_id: selectedOrganization?.id,
            "name": inputValues.campaignName,
            "brand_id": inputValues.brand_id,
            "pixel": inputValues.pixelCode,
            "survey_csv": inputValues.survey_csv,
            ads: [{
              "toast_csv": inputValues.toast_csv,
              rtr_type: 2,
              "ad_size": Number(inputValues.ad_size),
              platform_value: 1,
            }, {
              rtr_type: 2,
              // "ad_size": 0,
              "brand_offer": inputValues.brand_offer,
              platform_value: 2,
            }]
          }
          :
          inputValues.platform === RTR_TYPE.PROGRAMMATIC ?
            {
              organization_id: selectedOrganization?.id,
              "name": inputValues.campaignName,
              "brand_id": inputValues.brand_id,
              "pixel": inputValues.pixelCode,
              "survey_csv": inputValues.survey_csv,
              ads: [{
                "toast_csv": inputValues.toast_csv,
                rtr_type: 1,
                "ad_size": Number(inputValues.ad_size)
              }]
            } :
            {
              organization_id: selectedOrganization?.id,
              "name": inputValues.campaignName,
              "brand_id": inputValues.brand_id,
              "pixel": inputValues.pixelCode,
              "survey_csv": inputValues.survey_csv,
              ads: [{
                rtr_type: 0,
                // "ad_size": 0,
                "brand_offer": inputValues.brand_offer
              }]
            }

      if (inputValues.platform === RTR_TYPE.BOTH) {
        var updateParam = param.ads.filter(d => d.platform_value === 1)?.[0]
        if (inputValues.headerImage) updateParam.header_image = inputValues.header_image
        if (inputValues.headerUrl) updateParam.header_image_dest_url = inputValues.headerUrl
        if (inputValues.logoImage) updateParam.logo_image = inputValues.logo_image
        if (inputValues.logoUrl) updateParam.logo_image_dest_url = inputValues.logoUrl
      }

      if (inputValues.headerImage && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["header_image"] = inputValues.header_image
      if (inputValues.headerUrl && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["header_image_dest_url"] = inputValues.headerUrl

      if (inputValues.logoImage && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["logo_image"] = inputValues.logo_image;
      if (inputValues.logoUrl && inputValues.platform === RTR_TYPE.PROGRAMMATIC)
        param.ads[0]["logo_image_dest_url"] = inputValues.logoUrl;

      if (inputValues.platform === RTR_TYPE.INVISIBLY) {
        inputValues.brand_offer.offer_name = inputValues.campaignName;
        if (inputValues?.ad_template_type) {
          param.ad_template_type = JSON.parse(inputValues.ad_template_type);
        }
      }
      dispatch(surveyActionCreator.submitAdRequest(param));
    } else if (elemId === 'btn-next-step' || goToPreview && elemId === 'btn-preview-step') {
      setActiveStep(1);
      // called to get updated id
      dispatch(surveyActionCreator.getAdAssetsRequest(
        {
          brand_id: localStorage.getItem("brand_id"),
          message_id: localStorage.getItem("msg_id")
        }));
      refreshIframe();

      if (inputValues.brand_offer) {
        setAdName(inputValues.campaignName);
        setMessageId(inputValues.brand_offer.message);
        setAdvertiserId(inputValues.brand_offer.ad);
        setBrandId(inputValues.brand_id);
        setOfferId(inputValues.brand_offer.id);
      }

      setActivationData({ ...activationData, platformState: bothPlatformData?.ads?.filter((ac) => ac.platform_value === 2)?.[0]?.rtr_type });

      // do not call api
      return;
    }
  }
  const offerBlobUrl = useSelector((state) => state.offer.blobUrl);
  const userData = useSelector((state) => state.auth.userData);
  const user_role = userData.organization_role;
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const isFetching = useSelector((state) => state.surveyData.isFetching);
  const campaign = useSelector((state) => state.surveyData.campaign);
  const bothPlatformData = useSelector((state) => state.realtimeResearch.bothAdsData);
  const activating = useSelector((state) => state.surveyData.activating);
  const adActivated = useSelector((state) => state.surveyData.activatedAdData);
  const updating = useSelector((state) => state.surveyData.updating);
  const updatedAd = useSelector((state) => state.surveyData.updatedAd);
  const adLoading = useSelector((state) => state.surveyData.adLoading);
  const adSet = useSelector((state) => state.surveyData.adData);
  const advertiserData = useSelector((state) => state.realtimeResearch.rtrList?.results);
  const mimeTypeList = useSelector((state) => state.surveyData.mimeTypeList);
  const isAdvertiserRequest = useSelector((state) => state.realtimeResearch.isLoading);
  const imageLoading = useSelector((state) => state.surveyData.imageLoading);
  const blobUrl = useSelector((state) => state.surveyData.blobUrl);
  const saveFileResult = useSelector((state) => state.surveyData.saveFileResult);
  const isHeader = useSelector((state) => state.surveyData.isHeaderImage);
  const isLogo = useSelector((state) => state.surveyData.isLogoImage);
  const csvLoading = useSelector((state) => state.surveyData.csvLoading);
  const csvUrl = useSelector((state) => state.surveyData.csvUrl);
  const previewMessage = useSelector((state) => state.surveyData.previewSurvey);
  const isPreviewFetching = useSelector((state) => state.surveyData.isLoading);
  const toastUrl = useSelector((state) => state.surveyData.toastUrl);
  const toastLoading = useSelector((state) => state.surveyData.toastLoading);
  const asset = useSelector((state) => state.surveyData.asset);
  const invisiblyAsset = useSelector((state) => state.surveyData.invisiblyAsset);
  const rtrBuilderSaved = useSelector((state) => state.realtimeResearch.surveyData);

  useEffect(() => {
    if (rtrBuilderSaved && Object.keys(rtrBuilderSaved).length > 0) {
      goToPreviewScreen();
    }
  }, [rtrBuilderSaved])

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      // to check 
      setActivationData({
        ad_state: location.state.detail.ad_state,
        message: location.state.detail.msg_id,
        name: location.state.detail.ad_name,
        external_msg_id: location.state.detail.external_msg_id,
        isPDP: location.state.detail.isPDP,
        isOffer: location.state.detail.isOffer,
        platformState: location.state.detail.platformState,
      });
      setAdName(location.state.detail.ad_name);
      setMessageId(localStorage.getItem("msg_id"));
      setAdvertiserId(localStorage.getItem("ad_id"));
      setBrandId(localStorage.getItem("brand_id"));
      setOfferId(localStorage.getItem("id"));
    }

    if (location?.state?.detail?.platformState === 2) {
      dispatch(realtimeResearchActionCreator.getBothCampaignsRequest({ adset_id: location.state.detail.ad_set_id }))
    }

    if (location && location.state && location.state.detail &&
      location.state.detail.isEdit) {
      setIsActiveOrPaused(location.state.detail.isActiveOrPaused);
      setEditCampaign(location.state.detail.isEdit);
      dispatch(realtimeResearchActionCreator.rtrListRequest());
      // dispatch(surveyActionCreator.getCampaignByIdRequest({ id: location.state.detail.external_msg_id }));
      dispatch(surveyActionCreator.getAdAssetsRequest(
        {
          brand_id: location.state.detail.brand_id,
          message_id: location.state.detail.msg_id
        }));
    }
  }, [location]);

  const dataURLtoFile = (fileName) => {
    var mime = "image/png",
      bstr = "", n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  // populate response for get campagin by id
  useEffect(() => {
    let result = [];
    var brandId = "";
    const inputField = { ...inputValues };
    if (editCampaign) {
      if (localStorage.getItem("brand_id"))
        brandId = localStorage.getItem("brand_id");

      if (asset && asset.frames && asset.frames.frame_1) {
        // assign image and urls
        if (asset.frames.frame_1["banner_group.image_component_1"]) {
          var header_id = asset.frames.frame_1["banner_group.image_component_1"].id;
          var header_uri = asset.frames.frame_1["banner_group.image_component_1"].preview_url;
          var arr = header_uri.split("_"), fileName = arr[arr.length - 1];
          var fileData = dataURLtoFile(decodeURI(fileName));
          inputField["header_image"] = header_id;
          inputField.headerImage = decodeURI(fileName);
        }

        if (asset.frames.frame_1["banner_group.Ad_click_image_url"]) {
          var img_url = asset.frames.frame_1["banner_group.Ad_click_image_url"];
          inputField["headerUrl"] = img_url;
        }

        if (asset.frames.frame_1["logo_details.image_component_1"]) {
          var logo_id = asset.frames.frame_1["logo_details.image_component_1"].id;
          var logo_uri = asset.frames.frame_1["logo_details.image_component_1"].preview_url;
          var arr = logo_uri.split("_"), fileName = arr[arr.length - 1];
          var fileData = dataURLtoFile(decodeURI(fileName));
          inputField["logo_image"] = logo_id;
          inputField.logoImage = decodeURI(fileName);
        }

        if (asset.frames.frame_1["logo_details.logo_link"]) {
          var logo_url = asset.frames.frame_1["logo_details.logo_link"];
          inputField["logoUrl"] = logo_url;
        }

        if (asset.frames) {
          let data;
          if (Object.keys(asset.frames.frame_1).length) {
            data = RTRBuilderResponseToJSON(asset.frames.frame_1);
          } else {
            const frameData = {
              "group_1.Q1": undefined,
              "group_1.Q1_ANS2": undefined,
              "group_1.Q1_ANS3": undefined,
              "group_26.button_component_1": "Shop now",
              "group_26.text_component_1": "Thanks for your feedback!",
              "group_26.text_component_2": "You can learn more about our products by shopping on our site.",
              "group_26.toast_button_link": "https://www.invisibly.com"
            }
            data = RTRBuilderResponseToJSON(frameData);
          }

          if (asset?.taxonomy_mapping) {
            const newDataObjet = RTRBuilderTaxononyResponse(data.questions, asset.taxonomy_mapping);
            setQuestionJson(newDataObjet);
          } else {
            setQuestionJson(data.questions);
          }
          setToastJson(data.toasts)
          questionsRef.current = JSON.parse(JSON.stringify(data.questions))
          toastsRef.current = JSON.parse(JSON.stringify(data.toasts))
        }

      }

      // populates data on edit from bothPlatformData rather than campaign and update the
      // logic
      if (Object.keys(bothPlatformData).length > 0) {
        let platform = bothPlatformData?.ads?.length;

        let invisiblyInfo, programmaticInfo, isBrandOfferPresent;
        if (platform == 2) {
          invisiblyInfo = bothPlatformData?.ads?.filter((ac) => ac.platform_value === 2)?.[0];
          programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.platform_value === 1)?.[0];
          isBrandOfferPresent = invisiblyInfo?.brand_offer;
        } else if (bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 0)?.length > 0) { // invisibly type
          programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 0)?.[0];
          isBrandOfferPresent = programmaticInfo?.brand_offer;
        } else {
          programmaticInfo = bothPlatformData?.ads?.filter((ac) => ac.rtr_type === 1)?.[0];
          isBrandOfferPresent = programmaticInfo?.brand_offer;
        }

        setDraftCalled(true);
        inputField.selectedFiles = { name: programmaticInfo.survey_file_name !== null ? programmaticInfo.survey_file_name : undefined };
        inputField.selectedToastFiles = { name: programmaticInfo.toast_file_name !== null ? programmaticInfo.toast_file_name : undefined };
        inputField.campaignName = inputValues.campaignName !== "" ? (programmaticInfo.name === inputValues.campaignName ? programmaticInfo.name : inputValues.campaignName) : programmaticInfo.name;
        inputField.pixelCode = campaign.pixel || ""; // to check where to get pixel from 
        inputField.brand_id = bothPlatformData?.brand_v2;
        inputField.platform = platform === 2 ? RTR_TYPE.BOTH : programmaticInfo?.rtr_type === 1 ? RTR_TYPE.PROGRAMMATIC : RTR_TYPE.INVISIBLY;
        inputField.ad_template_type = asset?.template_type?.id
        inputField.ad_size = programmaticInfo?.ad_size ? programmaticInfo?.ad_size : inputValues.ad_size;
        if (isBrandOfferPresent?.length) {
          inputField.brand_offer = JSON.parse(JSON.stringify(isBrandOfferPresent?.[0])) || {};
          var obj = inputField.brand_offer;
          for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === "") {
              delete obj[propName];
            }
          }
          inputField.brand_offer = obj;
          var name = "";
          var x = inputField.brand_offer[`media_1_url`];
          if (x) {
            name = x.split("/")[5].replace(x.split("/")[5].split("_")[0], "").replace("_", "");
          }
          inputField.brand_offer[`media_1_name`] = decodeURI(name);
        }
        // TO do check if this works
        setInputValues(inputField);
        if (inputField.platform.toLowerCase() === "Invisibly".toLowerCase()) { // if invisiblyInfo is not null then it is of type platform
          setDisplayToastSection(false);
        }
        setDraftData(JSON.parse(JSON.stringify(inputField)));
      }
    }
  }, [bothPlatformData, asset]);

  useEffect(() => {
    //for RTRBuilder prepair submit API data (get data for invisibly as default programatic already have it)
    if (bothPlatformData?.ads?.length > 1) {
      //get API call for invisibly ads
      let invisiblyInfo = bothPlatformData?.ads.filter((ac) => ac.platform_value === 2)?.[0]
      dispatch(surveyActionCreator.getAdAssetsRequest({
        brand_id: invisiblyInfo?.brand_v2?.id,
        message_id: invisiblyInfo.message,
        invisibly: true
      }));
    }
  }, [bothPlatformData])



  useEffect(() => {
    let inputField = { ...inputValues };

    if (!(Object.keys(adActivated).length === 0 && adActivated.constructor === Object)) {
      setTimeout(() => {
        history.push(ROUTES.REALTIME_RESEARCH);
        dispatch(surveyActionCreator.activateAdRequestClear());
      }, 2000);
    }
    if (csvUrl !== "" && inputField["selectedFiles"] !== "") {
      inputField["survey_csv"] = csvUrl;
      setDisplayUploadInfo(false);
      inputField["selectedFiles"] = fileName;
      setInputValues(inputField);
      dispatch(surveyActionCreator.uploadCSVRequestClear());
    }

    if (toastUrl !== "" && inputField["selectedToastFiles"] !== "") {
      inputField["toast_csv"] = toastUrl;
      setDisplayToastInfo(false);
      inputField["selectedToastFiles"] = toastFileName;
      setInputValues(inputField);
      dispatch(surveyActionCreator.uploadToastRequestClear());
    }
    //getting called to remove
    // setInputValues(inputField);
  }, [csvUrl, adActivated, toastUrl])

  useEffect(() => {
    // this effect is called when we update the survey data in edit flow.
    if (!(Object.keys(updatedAd).length === 0 && updatedAd.constructor === Object)) {
      setGoToPreview(true);
      const draft = { ...inputValues };

      // let invisiblyInfo = bothPlatformData?.ads.filter((ac) => ac.platform_value === 2)?.[0];
      // let programmaticInfo = bothPlatformData?.ads.filter((ac) => ac.platform_value === 1)?.[0];

      setDraftData(JSON.parse(JSON.stringify(draft)));
      setActivationData({
        message: updatedAd?.[0]?.message,
        ad_state: updatedAd?.[0]?.ad_state,
        name: updatedAd?.[0]?.name,
        external_msg_id: updatedAd?.[0]?.id,
        isPDP: updatedAd?.[0]?.rtr_type.toString() === "0",
        isOffer: updatedAd?.[0]?.brand_offer.length > 0,
        platformState: updatedAd[0].rtr_type
      });
      localStorage.setItem("campaign_id", updatedAd?.[0]?.campaign?.id)
      dispatch(realtimeResearchActionCreator.getBothCampaignsRequest({ adset_id: updatedAd[0]?.ad_set?.id }))

      if (initiator === 'btn-next-step' || initiator === 'btn-preview-step') {
        setActiveStep(1);
        // called to get updated id
        dispatch(surveyActionCreator.getAdAssetsRequest(
          {
            brand_id: localStorage.getItem("brand_id"),
            message_id: localStorage.getItem("msg_id")
          }));
        if ((userData.organization_role === 0)) {
          let questionParamList = {}
          questionParamList.type = 0;
          dispatch(surveyActionCreator.getTaxonomyQuestionListRequest(questionParamList));
          let answerParamList = {};
          answerParamList.type = 1;
          answerParamList.pagination = -1;
          dispatch(surveyActionCreator.getTaxonomyAnswerListRequest(answerParamList));
        }
        refreshIframe();
      }
    }
  }, [updatedAd]);

  useEffect(() => {
    if (+preview === 1) {
      // go to preview
      setActiveStep(2);
      setDisplayStepper(false);
    } else {
      setDisplayStepper(true);
    }
  }, [preview]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (previewMessage) {
      const frames = previewMessage && previewMessage.previews &&
        previewMessage.previews["frame_1"] ? previewMessage.previews["frame_1"] : "";

      let keys = Object.keys(frames),
        sizes, width, height;

      let sizeKey = [];
      keys.map((value, index) => {
        sizeKey = value === "size" ? keys.splice(index, 1) : "";
        return sizeKey;
      });


      const previewHtml =
        frames[keys[0]] ? frames[keys[0]] + clickableScript : '<div style=" height:150px; width: 150px;font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Previews Available. Create message and try again...</div>';

      if (sizeKey.length > 0) {
        sizes = frames && frames.size ? frames.size : "";
        height = sizes && sizes.height ? sizes.height : "";
        width = sizes && sizes.width ? sizes.width : "";
      }


      setFrame(frames);
      setHeight((+height + 16) + "px");
      setWidth((+width + 16) + "px");
      setPreviewHtml(previewHtml);
    } else {
      const previewHtml = '<div  style=" font-size: 16px; color: #0E0E0F; font-family:BasisGrotesque; margin: auto;padding-top:40px;">No Preview Available. Create message and try again...</div>';
      setPreviewHtml(previewHtml);
      setHeight(150 + "px");
      setWidth(550 + "px");
    }


  }, [displayUploadInfo, previewMessage]);

  useEffect(() => {
    refreshIframe();
    let questionParamList = {}
    questionParamList.type = 0;
    dispatch(surveyActionCreator.getTaxonomyQuestionListRequest(questionParamList));
    let answerParamList = {};
    answerParamList.type = 1;
    answerParamList.pagination = -1;
    dispatch(surveyActionCreator.getTaxonomyAnswerListRequest(answerParamList));
    return function cleanup() {
      //clear adactivated, adset,updateddraftstate.
      dispatch(surveyActionCreator.submitAdRequestClear());
      dispatch(surveyActionCreator.updateDraftedAdRequestClear());
      dispatch(surveyActionCreator.activateAdRequestClear());
      dispatch(offerActionCreator.uploadMediaRequestClear());
      validators = new Validator();
      dispatch(realtimeResearchActionCreator.getBothCampaignsClear())
      dispatch(surveyActionCreator.getInvisiblyAdAssetsClear())
    }
  }, []);


  useEffect(() => {
    dispatch(surveyActionCreator.requestMimeTypeList());
  }, []);

  useEffect(() => {
    let result = [];
    var brandId = "";
    const inputField = { ...inputValues };
    if (advertiserData && advertiserData.length) {
      if (localStorage.getItem("brand_id"))
        brandId = localStorage.getItem("brand_id");
      result = advertiserData && advertiserData.filter((data) => {
        return data.id.search(brandId) != -1;
      });
      inputField.advertiser = result[0]?.name || "";
      setInputValues(inputField);

      setAdvertiserList(advertiserData);
    }
  }, [advertiserData]);

  useEffect(() => {
    if (blobUrl) {
      ///set header image name after successful upload.
      const inputField = { ...inputValues };
      if (isHeader) {
        inputField["headerImage"] = fileDataObject.file_name;
      }
      if (isLogo) {
        inputField["logoImage"] = fileDataObject.file_name;
      }

      setInputValues(inputField);
      let dataObj = { ...fileDataObject };
      dataObj.url = blobUrl["blob_url"];
      setFileDataObject(dataObj);

      let param = {};
      param.brand_id = inputValues.brand_id;
      param.file = dataObj;
      param.organization_id = selectedOrganization?.id || localStorage.getItem('organizationId');
      dispatch(surveyActionCreator.uploadImageRequestClear());
      dispatch(surveyActionCreator.saveFileRequest(param));

    }
  }, [blobUrl]);

  useEffect(() => {
    var inputFields = { ...inputValues }
    if (!(Object.keys(saveFileResult).length === 0 && saveFileResult.constructor === Object)) {
      if (headerImage) {
        inputFields["header_image"] = saveFileResult.id;
        setHeaderImage(false);
      } else if (logoImage) {
        inputFields["logo_image"] = saveFileResult.id;
        setLogoImage(false);
      }
      setInputValues(inputFields);
      dispatch(surveyActionCreator.saveFileRequestClear());

    }

  }, [saveFileResult])

  useEffect(() => {
    if ((Object.keys(adSet).length !== 0)) {
      setDraftCalled(true);
      const draft = { ...inputValues };
      // extract brand offer from response
      let brandOfferData = adSet.filter((ac) => ac.brand_offer?.length);
      let brandOfferSet = brandOfferData?.[0]?.brand_offer || [];
      if (brandOfferSet?.length) {
        draft.brand_offer.id = brandOfferSet?.[0]?.id;
        draft.brand_offer.message = brandOfferSet?.[0]?.message;
        draft.brand_offer.ad = brandOfferSet?.[0]?.ad;
      }
      setDraftData(JSON.parse(JSON.stringify(draft)));
      let Programmaticdata = adSet.filter((ac) => ac.platform_value === 1);
      localStorage.setItem("ad_id", Programmaticdata?.[0]?.id);
      localStorage.setItem("id", brandOfferSet?.[0]?.id);
      localStorage.setItem("campaign_id", Programmaticdata?.[0]?.campaign?.id)
      setActivationData({
        message: Programmaticdata?.[0]?.message,
        ad_state: Programmaticdata?.[0]?.ad_state,
        name: Programmaticdata?.[0]?.name,
        external_msg_id: Programmaticdata?.[0]?.id,
        isPDP: Programmaticdata?.[0]?.rtr_type.toString() === "0",
        isOffer: brandOfferSet?.length > 0,
        platformState: adSet[0]?.rtr_type
      });
      dispatch(realtimeResearchActionCreator.getBothCampaignsRequest({ adset_id: Programmaticdata[0].ad_set?.id }))
      if (brandOfferSet?.length > 0) {
        setAdName(brandOfferSet?.[0]?.name);
        setMessageId(brandOfferSet?.[0]?.message);
        setAdvertiserId(brandOfferSet?.[0]?.ad);
        setBrandId(brandOfferSet?.[0]?.brand_id);
        setOfferId(brandOfferSet?.[0]?.id);
      }
      setGoToPreview(true);
      // when "next step " button is clicked, post response go to preview.
      if (initiator === 'btn-next-step' || initiator === 'btn-preview-step') {
        // called to get updated id
        dispatch(surveyActionCreator.getAdAssetsRequest(
          {
            brand_id: localStorage.getItem("brand_id"),
            message_id: localStorage.getItem("msg_id")
          }));
        setActiveStep(1);
        // removed refreshIframe call from here since it is unnecessary. This is done to resolve IMA-2982
      }

      if (adSet?.length > 1) { //for both platforms
        //proramatic
        dispatch(surveyActionCreator.getAdAssetsRequest({
          brand_id: Programmaticdata?.[0]?.brand_v2?.id,
          message_id: Programmaticdata?.[0]?.message,
        }))

        //invisibly
        let invisiblydata = adSet.filter((ac) => ac.platform_value === 2);
        dispatch(surveyActionCreator.getAdAssetsRequest({
          brand_id: invisiblydata?.[0]?.brand_v2?.id,
          message_id: invisiblydata?.[0]?.message,
          invisibly: true
        }));
      } else {
        //programtic/invisibly
        dispatch(surveyActionCreator.getAdAssetsRequest({
          brand_id: adSet?.[0]?.brand_v2?.id,
          message_id: adSet?.[0]?.message,
        }))
      }
    }

  }, [adSet]);

  const [questionJson, setQuestionJson] = useState({});
  const [toastJson, setToastJson] = useState({});
  const [fromCSV, setFromCSV] = useState(false)
  const [isRTRBuilderEdited, setIsRTRBuilderEdited] = useState({})
  const [activeStep, setActiveStep] = useState(0);
  const [backFromPreview, setBackFromPreview] = useState(false);
  const [displayStepper, setDisplayStepper] = useState(false);
  const [goToPreview, setGoToPreview] = useState(false);
  const [advertiserId, setAdvertiserId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [messageId, setMessageId] = useState("");
  const [offerId, setOfferId] = useState("");
  const [adName, setAdName] = useState("");
  const [calledFrom, setCalledFrom] = useState("");
  const getSteps = () => {
    return [
      activeStep !== 0 ?
        {
          title: "General Setup",
          icon: "/Icon_assets/svg/tick-icon.svg",
          onClick: (e) => {
            e.preventDefault();
            // setActiveStep(0);
          }
        } :
        {
          title: "General Setup",
          onClick: (e) => {
            e.preventDefault();
            // setActiveStep(0);
          }
        },
      activeStep !== 1 && activeStep !== 0 ?
        {
          title: "Survey Details",
          icon: "/Icon_assets/svg/tick-icon.svg",
          onClick: (e) => {
            e.preventDefault();
            // setActiveStep(0);
            // goToSurveyBuilder('btn-survey-builder');
          }
        } :
        {
          title: "Survey Details",
          onClick: (e) => {
            /// TO DO - this update later
            e.preventDefault()
            // goToSurveyBuilder('btn-survey-builder');
          }
        },
      {
        title: "Preview",
        onClick: (e) => {
          if (!disable) {
            e.preventDefault()
            // saveCampaign('btn-preview-step');
          }
        }
      }];
  }

  const steps = getSteps();
  const [advertiserList, setAdvertiserList] = useState([]);

  const initialState = {
    btnCopy: '',
    campaignName: '',
    title: '',
    subtitle: '',
    pixelCode: "",
    headerImage: '',
    logoImage: '',
    logoUrl: '',
    headerUrl: '',
    logoUrl_invalid: false,
    headerUrl_invalid: false,
    url: '',
    url_invalid: false,
    selectedFiles: {},
    advertiser: '',
    brand_id: '',
    selectedToastFiles: {},
    platform: 'Programmatic',
    ad_size: 0,
    ad_template_type: 5
  };

  const goToSurveyBuilder = () => {
    setActiveStep(1)
  }

  const goToPreviewScreen = (caller) => {
    if (calledFrom === "next step" || caller === "next step") {
      setActiveStep(2);
      refreshIframe();
    }
    //reset rtr builder data
    dispatch(realtimeResearchActionCreator.setRTRSurveyDataClear())
  }

  const validateFile = (type, file) => {
    const validTypes = type;
    var fileName, fileExtension;
    fileName = file.name;
    fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
    return (validTypes.includes(file.type) || (validTypes.includes(fileExtension)))
  }

  const setToastFileData = (jsonData) => {
    setToastJson(jsonData)
    toastsRef.current = JSON.parse(JSON.stringify(jsonData))
    setIsRTRBuilderEdited({ ...setIsRTRBuilderEdited, toasts: false })
  }

  const csvJSON = (csv) => {
    if (!csv) {
      return undefined
    }

    var lines = csv.split(/\r\n|\n|\r/);
    lines = lines.filter(n => n);
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    //return result; //JavaScript object
    return (result); //JSON
  }


  const handleFiles = (files) => {
    var f;
    for (let i = 0; i < files.length; i++) {
      if (validateFile('.csv', files[i])) {

        let reader = new FileReader();
        reader.readAsText(files[i]);

        reader.onload = function (e) {
          const fileJson = csvJSON(reader.result);
          setQuestionJson(fileJson);
          questionsRef.current = JSON.parse(JSON.stringify(fileJson))
          setIsRTRBuilderEdited({ ...setIsRTRBuilderEdited, questions: false })
          setFromCSV(true)
          f = new File([reader.result], files[i].name, { type: "text/csv", lastModified: files[i].lastModified })
          let param = {};
          param.organization_id = selectedOrganization?.id || localStorage.getItem('organizationId');
          param.brand_id = inputValues.brand_id;
          param.file = f;

          dispatch(surveyActionCreator.uploadCSVRequest(param));
        };

        reader.onerror = function () {
          console.log(reader.error);
        };
        // const inputFields = { ...inputValues };
        // inputFields["selectedFiles"] = files[i];
        // setInputValues(inputFields);
        setFileName(files[i]);

      } else {
        dispatch(notificationActionCreator.setNotification({
          // color: "error",
          message: "Unsupported content type of file, use csv file."
        }));
      }
    }

  }

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
  }

  const dragLeave = (e) => {
    e.preventDefault();
  }

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }
  const clearText = (e) => {
    const inputFields = { ...inputValues };
    inputFields[e.target.name] = '';
    setInputValues(inputFields);
  }

  const removeSelection = (e, name) => {
    const inputField = { ...inputValues };
    if (name === 'logo') {
      inputField['logoImage'] = '';
      inputField['logoUrl'] = '';
    } else if (name === 'header') {
      inputField['headerImage'] = '';
      inputField['headerUrl'] = '';
      setFileDataObject({ 'ad_size': {} });
    }
    else if (name === 'file') {
      inputField['selectedFiles'] = {};
    } else if (name === 'toastFile') {
      inputField['selectedToastFiles'] = {};
    }

    setInputValues(inputField);
  }

  const removeOfferSelection = (e) => {
    let inputField = { ...inputValues };
    e.preventDefault();
    setFileDataObject({});
    var key = e.target.name.replace("_name", "_size");
    var keyUrl = e.target.name.replace("_name", "_url");
    inputField.brand_offer[keyUrl] = "";
    delete inputField.brand_offer[key];
    delete inputField.brand_offer[e.target.name];
    setInputValues(inputField);
  }

  const isValidImageFormat = (file) => {
    const validFileExtensions = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
    if (validFileExtensions.includes(file.type.toLowerCase())) {
      return true;
    }
    return false;

  }

  // convert to specific ratio
  function getAspectRatio(res_w, res_h) {
    res_w = parseInt(res_w);
    res_h = parseInt(res_h);

    let aspect_full = (res_w / res_h);
    let vFinal = parseFloat(aspect_full).toFixed(2);

    switch (+vFinal) {
      case 0.78:
        return "7:9";
      case 0.92:
        return "11:12";

      default:
        let gcd = 1;
        for (let i = 1; i <= res_w && i <= res_h; ++i) {
          if (res_w % i == 0 && res_h % i == 0) gcd = i;
        }
        return res_w / gcd + ":" + res_h / gcd;
    };
  }

  const readOfferImageFile = async (file, isMobile, name, inputFields) => {
    let fileData = { ad_size: {} };
    let reader = new FileReader();
    reader.onload = await function (e) {
      let img = new Image();
      img.onload = async function () {
        fileData.ad_size.size = this.width + '*' + this.height;
        fileData.ad_size.height = this.height;
        fileData.ad_size.width = this.width;
        fileData.ad_size.aspect_ratio = getAspectRatio(this.width, this.height);
        fileData.ad_size.is_fluid = 0;
        // let expectedRatio = ["7:9", "11:12"]
        // if (expectedRatio.includes(fileData.ad_size.aspect_ratio)) {
        // aspect ratio matches
        setImageFileObjectUploadData(file, isMobile, name);
        setInputValues(inputFields);
        // } else {
        //   dispatch(notificationActionCreator.setNotification({
        //     message: 'Aspect ratio of image should be 11:12 or 7:9'
        //   }));
        // }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    return fileData;
  };

  const readImageFile = async (file) => {
    let fileData = { ...fileDataObject }
    let reader = new FileReader();
    let getAspectRatiofn = getAspectRatio;
    reader.onload = await function (e) {
      let img = new Image();
      img.onload = async function () {
        fileData.ad_size.size = this.width + '*' + this.height;
        fileData.ad_size.height = this.height;
        fileData.ad_size.width = this.width;
        fileData.ad_size.aspect_ratio = getAspectRatiofn(this.width, this.height);
        fileData.ad_size.is_fluid = 0;
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    return fileData;
  };

  const setFileObjectUploadData = (file, isMobile, name) => {
    let param = {};
    let fileObj = { ...fileDataObject };
    fileObj['file_size'] = file.size;
    fileObj['file_name'] = file.name;
    fileObj['url'] = file.url;
    isMobile ? fileObj['device_type'] = 1 : fileObj['device_type'] = 0;
    fileObj['mime_type'] = (mimeTypeList.filter(function (Object) { return Object.value == file.type }))[0].id;
    setFileDataObject(fileObj);
    if (name === "headerImage") {
      setHeaderImage(true);
      setLogoImage(false);
      param.isHeaderImage = true;
      param.isLogoImage = false;
    }
    else if (name === "logoImage") {
      setLogoImage(true);
      setHeaderImage(false);
      param.isLogoImage = true;
      param.isHeaderImage = false;
    }

    param.brand_id = inputValues.brand_id;
    param.file = file;
    param.organization_id = selectedOrganization?.id || localStorage.getItem('organizationId');

    dispatch(surveyActionCreator.uploadImageRequest(param));

  }

  const setImageFileObjectUploadData = (file, isMobile, name) => {
    let param = {};
    let fileObj = {};//{ ...fileDataObject };
    fileObj['file_size'] = file.size;
    fileObj['file_name'] = file.name;
    fileObj['url'] = file.url;
    isMobile ? fileObj['device_type'] = 1 : fileObj['device_type'] = 0;
    fileObj['mime_type'] = (mimeTypeList.filter(function (Object) { return Object.value == file.type }))[0].id;
    param.file = file;
    setOfferFileDataObject(fileObj);
    dispatch(offerActionCreator.uploadMediaRequest(param));
  }

  const handleImageUpload = (e) => {
    let { target } = e;
    if (target.value.length > 0) {
      const fileList = Array.from(e.target.files);
      if (fileList.length == 1) {
        fileList.map((file, index) => {
          let type = "image";
          if (file.type.indexOf("image") > -1 && !isValidImageFormat(file)) {
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid image format, ${file.type} not supported !!`
            }));
          }
          else if (file.type.indexOf(type) != -1 || type.includes(file.type.split("/")[0])) {
            let isMobile = (/iPhone|iPad|iPod|Android/i).test(navigator.userAgent);
            if (file.type.indexOf("image") > -1) {
              if (file.size <= 31457280) {
                readImageFile(file);
                setFileObjectUploadData(file, isMobile, target.name);
              }
              else {

                dispatch(notificationActionCreator.setNotification({
                  message: 'Please select image of size  less than 30mb!'
                }));
              }

            }
            else {
              dispatch(notificationActionCreator.setNotification({
                message: 'Please select Image'
              }));
            }
          } else {
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid image format, ${file.type} not supported !!`
            }));
          }
        })
      }
    }
    else {
      target.reset();
    }
  }

  const isValidVideoFormat = (file) => {
    const validFileExtensions = ["video/mp4", "video/mp3", "video/quicktime"];
    if (validFileExtensions.includes(file.type.toLowerCase())) {
      return true;
    }
    return false;

  }

  const handleOfferImageUpload = (e) => {
    let inputFields = { ...inputValues };
    var key = e.target.name.replace("_url", "_type");
    var sizeKey = e.target.name.replace("_url", "_size");

    let { target } = e;
    setMediaUrl(e.target.name);
    if (target.value.length > 0) {
      const fileList = Array.from(e.target.files);
      if (fileList.length == 1) {
        fileList.map((file, index) => {
          let type = ["image/gif", "image/jpeg", "image/jpg", "image/png", "video/mp4", "video/mp3", "video/quicktime"];
          if (file.type.indexOf("image") > -1 && !isValidImageFormat(file)) {
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid format,  ${file.type} not supported !!`
            }));
            setMediaUrl("");
          } else if (type.includes(file.type)) {
            let isMobile = (/iPhone|iPad|iPod|Android/i).test(navigator.userAgent);
            if (file.type.indexOf("image") > -1) {
              if (file.size <= 1048576) {
                inputFields.brand_offer[sizeKey] = file.size;
                readOfferImageFile(file, isMobile, e.target.name, inputFields);
              } else {
                dispatch(notificationActionCreator.setNotification({
                  message: 'Image size should not be more than 1MB.'
                }));
              }
            }
            else {
              window.scrollTo(0, 0);
              setMediaUrl("");
              dispatch(notificationActionCreator.setNotification({
                message: `Please select valid format,${file.type} not supported !!`
              }));
            }
          }

          else {
            window.scrollTo(0, 0);
            setMediaUrl("");
            dispatch(notificationActionCreator.setNotification({
              message: `Please select valid format, ${file.type} not supported !!`
            }));
          }
        })
      }
    }
    else {
      target.reset();
    }
  }
  const handleFileUpload = (e) => {
    const inputField = { ...inputValues };
    var f;
    const fileList = Array.from(e.target.files);
    if (fileList.length === 1) {
      if (e.target.files.length !== 0 &&
        (validateFile('.csv', e.target.files[0]))) {
        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);

        reader.onload = function () {
          const fileJson = csvJSON(reader.result);
          setQuestionJson(fileJson);
          questionsRef.current = JSON.parse(JSON.stringify(fileJson))
          setIsRTRBuilderEdited({ ...setIsRTRBuilderEdited, questions: false })
          setFromCSV(true)
          f = new File([reader.result], e.target.files[0].name, { type: "text/csv", lastModified: e.target.files[0].lastModified })
          let param = {};
          param.organization_id = selectedOrganization?.id || localStorage.getItem('organizationId');
          param.brand_id = inputValues.brand_id;
          param.file = f;
          //let csv = fs.readFileSync("username.csv");
          // check logic to parse csv content e
          dispatch(surveyActionCreator.uploadCSVRequest(param));
        };

        reader.onerror = function () {
          dispatch(notificationActionCreator.setNotification({
            message: reader.error
          }));
        };

        setFileName(e.target.files[0]);
        // let param = {};
        // param.organization_id = localStorage.getItem('organizationId');
        // param.brand_id = inputValues.brand_id;
        // param.file = e.target.files[0];
        // dispatch(surveyActionCreator.uploadCSVRequest(param));
      } else {
        dispatch(notificationActionCreator.setNotification({
          message: "Unsupported content type of file, use csv file."
        }));
      }
    } else {
      dispatch(notificationActionCreator.setNotification({
        message: "Please select a single file."
      }));
    }
  }

  const updateValidations = (fieldName, value) => {
    var error = validators.isValueValid(fieldName, value);
    if (validators[fieldName].errorMsg !== "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return error;
  };

  const handleOfferInputChange = (e) => {
    const inputFields = { ...inputValues };
    if (inputFields.campaignName) {
      inputFields.brand_offer.offer_name = inputFields.campaignName;
    }
    inputFields.brand_offer[e.target.name] = e.target.value;
    // inputFields.brand_offer.type = 2;

    // if (e.target.name === "subtitle" && e.target.value === "") {
    // delete inputFields.brand_offer.subtitle;
    // }

    if (!mandatoryFields.includes(e.target.name) && e.target.value === "") {
      delete inputFields.brand_offer[e.target.name];
    }

    if (e.target.name === "description") {
      setCharacterEntered(e.target.value.length);
    }

    setInputValues(inputFields);
    if (validationEnabledFields.includes(e.target.name)) {
      updateValidations(e.target.name, e.target.value);
    }
  }

  const handleInputChange = (e) => {
    const inputFields = { ...inputValues };
    inputFields[e.target.name] = e.target.value;
    // if (e.target.name === "ad_size" && e.target.value !== "0" && toastJson.length === 6) {
    //   // remove last item from toast if toast 6 is present if ad_size !==0
    //   // if branching for question/answer is at toast6 set it to last entry of toast.
    //   let updatedData = JSON.parse(JSON.stringify(toastJson));
    //   let updatedQuestions = JSON.parse(JSON.stringify(questionJson));
    //   updatedData.splice(-1);
    //   setToastJson(updatedData);

    //   updatedQuestions.filter(f => {
    //     if (f.next_question !== "" && f.next_question === 'Toast 6') {
    //       f.next_question = updatedData[updatedData.length - 1].id
    //     } else {
    //       f.answer.filter((a, index) => {
    //         if (f.answer[index][`answer${index + 1}_branch`] === 'Toast 6') {
    //           f.answer[index][`answer${index + 1}_branch`] = updatedData[updatedData.length - 1].id
    //         }
    //       })
    //     }
    //   })
    //   setQuestionJson(updatedQuestions)
    // }

    if ((e.target.name === "platform" && e.target.value.toLowerCase() === RTR_TYPE.INVISIBLY.toLowerCase())
      || inputFields.platform.toLowerCase() === RTR_TYPE.INVISIBLY.toLowerCase()) {
      setDisplayToastSection(false);
      if (inputValues.brand_offer === undefined ||
        (inputValues.brand_offer
          && Object.keys(inputValues.brand_offer).length === 0
          && Object.getPrototypeOf(inputValues.brand_offer) === Object.prototype))
        inputFields.brand_offer = {
          unit: UNITS.TEXT,
          title: '',
          description: "",
          monetary: "",
          type: 2,
          media_1_type: "image",
          media_1_url: "",
          button_text: ""
        }
    } else if ((e.target.name === "platform" && e.target.value.toLowerCase() === RTR_TYPE.BOTH.toLowerCase())
      || inputFields.platform.toLowerCase() === RTR_TYPE.BOTH.toLowerCase()) {
      if (inputValues.brand_offer === undefined ||
        (inputValues.brand_offer
          && Object.keys(inputValues.brand_offer).length === 0
          && Object.getPrototypeOf(inputValues.brand_offer) === Object.prototype))
        inputFields.brand_offer = {
          unit: UNITS.TEXT,
          title: '',
          description: "",
          monetary: "",
          type: 2,
          media_1_type: "image",
          media_1_url: "",
          button_text: ""
        }
    } else {
      delete inputFields.brand_offer;
      setDisplayToastSection(true);
    }

    setInputValues(inputFields);
    if (e.target.name === 'url' ||
      e.target.name === 'logoUrl' ||
      e.target.name === 'headerUrl') {
      updateValidations(e.target.name, e.target.value);
    }
  }
  const [inputValues, setInputValues] = useState(initialState);

  const [descriptionLimit, setDescriptionLimit] = useState(250);
  const [characterEntered, setCharacterEntered] = useState(0);
  const [isError, setIsError] = useState(false);

  const validationEnabledFields = [
    'url', 'monetary', 'filters', 'keywords', 'display_order', 'title', 'subtitle', 'offer_name', 'button_text', 'description'
  ];
  const mandatoryFields = ['title', 'monetary', 'description', 'media_1_url', 'media_1_type', 'button_text']

  useEffect(() => {
    var disable = true;
    if (inputValues.platform.toLowerCase() === RTR_TYPE.INVISIBLY.toLowerCase()) {
      for (var i = 0; i < Object.keys(inputValues).length; i++) {
        if (inputValues.campaignName !== "" &&
          // (inputValues.selectedFiles &&
          //   inputValues.selectedFiles.name !== undefined) &&
          inputValues.advertiser !== ""
          && validators.url.errorMsg === ""
          && validators.headerUrl.errorMsg === ""
          && validators.logoUrl.errorMsg === ""
          && inputValues.brand_offer
          && Object.keys(inputValues.brand_offer).length !== 0
          && Object.getPrototypeOf(inputValues.brand_offer) === Object.prototype
          && Object.values(inputValues.brand_offer).every(o => o !== "")) {
          disable = false;
        }
      }
    } else if (inputValues.platform.toLowerCase() === RTR_TYPE.PROGRAMMATIC.toLowerCase()) {
      for (var i = 0; i < Object.keys(inputValues).length; i++) {
        if (inputValues.campaignName !== "" &&
          // (inputValues.selectedFiles &&
          //   inputValues.selectedFiles.name !== undefined) &&
          inputValues.advertiser !== ""
          && validators.url.errorMsg === ""
          && validators.headerUrl.errorMsg === ""
          && validators.logoUrl.errorMsg === ""
          // && (inputValues.selectedToastFiles &&
          //   inputValues.selectedToastFiles.name !== undefined)
        ) {
          disable = false;
        }
      }
    } else {
      // platform selected is both
      for (var i = 0; i < Object.keys(inputValues).length; i++) {
        if (inputValues.campaignName !== "" &&
          // (inputValues.selectedFiles &&
          //   inputValues.selectedFiles.name !== undefined) &&
          inputValues.advertiser !== ""
          && validators.url.errorMsg === ""
          && validators.headerUrl.errorMsg === ""
          && validators.logoUrl.errorMsg === ""
          && inputValues.brand_offer
          && Object.keys(inputValues.brand_offer).length !== 0
          && Object.getPrototypeOf(inputValues.brand_offer) === Object.prototype
          && Object.values(inputValues.brand_offer).every(o => o !== "")
          // && (inputValues.selectedToastFiles &&
          //   inputValues.selectedToastFiles.name !== undefined)
        ) {
          disable = false;
        }
      }
    }
    setDisable(disable);
    setHeaderImgContent(inputValues?.header_image);
    setLogoImgContent(inputValues?.logo_image);
  }, [inputValues]);

  useEffect(() => {
    var inputFields = { ...inputValues };
    var key = mediaUrl.replace("_url", "_name")
    if (offerBlobUrl !== "") {
      inputFields.brand_offer[key] = offerFileDataObject.file_name;
      inputFields.brand_offer[mediaUrl] = offerBlobUrl;
    }
    setInputValues(inputFields);

  }, [offerBlobUrl]);

  useEffect(() => {
    return () => {
      //handle on refresh
      if (bothPlatformData?.ads?.length > 1) {
        handlePlatformReset()
      }
    }
  }, [])


  const handlePlatformChange = (selectedPlatform) => {
    const newSelectedAd = bothPlatformData?.ads?.find(ad => ad.platform_value === selectedPlatform);
    const programmaticAd = bothPlatformData?.ads?.find(ad => ad.platform_value === 1);
    setPlatformPreview(selectedPlatform)
    setActivationData({ ...activationData, isPDP: (selectedPlatform === 2), isOffer: (selectedPlatform === 2) })
    setMessageId(newSelectedAd?.message)
    setBrandId(newSelectedAd?.brand_v2?.id)
    setAdvertiserId(newSelectedAd?.id)
    setOfferId(newSelectedAd?.brand_offer.length > 0 ? newSelectedAd?.brand_offer?.[0]?.id : false)

    localStorage.setItem("brand_id", newSelectedAd?.brand_v2?.id);
    localStorage.setItem("msg_id", newSelectedAd.message);

    if (selectedPlatform === 1) {
      setSurveyComplete(false);
      // setActiveStep(1);
      refreshIframe()
    }

    //sharable link have unique URL for both platforms
    localStorage.setItem("shareable_brand_id", programmaticAd?.brand_v2?.id);
    localStorage.setItem("shareable_msg_id", programmaticAd.message);
  }

  const handleBackClick = (data) => {
    dispatch(surveyActionCreator.getAdAssetsRequest(
      {
        brand_id: localStorage.getItem("brand_id"),
        message_id: localStorage.getItem("msg_id")
      }));
    setActiveStep(1);
    setBackFromPreview(true);
    if (bothPlatformData?.ads?.length > 1) {
      handlePlatformChange(1)
    }

    if (data) { //back from RTRBuilder
      setActiveStep(0);
      setBackFromPreview(false);
      const isChange = !(JSON.stringify(questionsRef.current) === JSON.stringify(data.questions) && JSON.stringify(toastsRef.current) === JSON.stringify(data.toasts))
      if (isChange) {
        setQuestionJson(data.questions)
        setToastJson(data.toasts)
        //setIsRTRBuilderEdited({questions: JSON.parse(JSON.stringify(data.questions)), toasts: JSON.parse(JSON.stringify(data.toasts)) })
        setIsRTRBuilderEdited({ questions: !(JSON.stringify(questionsRef.current) === JSON.stringify(data.questions)), toasts: !(JSON.stringify(toastsRef.current) === JSON.stringify(data.toasts)) })
        setFromCSV(false)
        questionsRef.current = JSON.parse(JSON.stringify(data.questions))
        toastsRef.current = JSON.parse(JSON.stringify(data.toasts))
      } else {
        //setting no chnage in RTRBuilder
        setIsRTRBuilderEdited({ questions: !(JSON.stringify(questionsRef.current) === JSON.stringify(data.questions)), toasts: !(JSON.stringify(toastsRef.current) === JSON.stringify(data.toasts)) })
      }
    }
  }

  const handlePlatformReset = () => {
    localStorage.setItem("brand_id", localStorage.getItem("shareable_brand_id") ? localStorage.getItem("shareable_brand_id") : localStorage.getItem("brand_id"));
    localStorage.setItem("msg_id", localStorage.getItem("shareable_msg_id") ? localStorage.getItem("shareable_msg_id") : localStorage.getItem("msg_id"));
  }

  const handleSubmitRTRBuilder = (e, allQuestions, allToasts, callerVal) => {
    e.preventDefault()
    setCalledFrom(callerVal);
    setQuestionJson(allQuestions)
    setToastJson(allToasts)
    if (bothPlatformData?.ads?.length > 1) { //both platforms
      //if its a both platform-> 2 API calls required for progrmatic & invisibly
      const programaticInfo = bothPlatformData?.ads?.filter((ac) => ac.platform_value === 1)?.[0];
      //PROGRAMMATIC
      const programaticData = RTRBuilderPayloadToJSON(allQuestions, allToasts);
      let programaticPayload = {
        brand_v2: asset?.brand_v2,
        id: asset?.id,
        name: asset?.name,
        ad_template: asset?.ad_template,
        state: 0,
        frames: {
          frame_1: programaticData
        }
      }
      if (headerImgContent !== undefined) {
        programaticPayload.frames.frame_1["banner_group.image_component_1"] = headerImgContent;
      }
      if (logoImgContent !== undefined) {
        programaticPayload.frames.frame_1["logo_details.image_component_1"] = logoImgContent;
      }
      dispatch(realtimeResearchActionCreator.setRTRSurveyDataRequest(programaticPayload))

      //INVISIBLY
      let data = RTRBuilderPayloadToJSON(allQuestions);
      let taxonomyData = RTRBuilderTaxononyPayload(allQuestions);
      let lastRecord = allQuestions[allQuestions.length - 1]

      if (lastRecord?.next_question !== "" || lastRecord?.question_type === "MS") {//question Level branching
        data[`group_${lastRecord.ID}.selection_component_1`] = "Toast 1"
      } else { //answer Level branching
        if (lastRecord?.answer.length) {
          lastRecord?.answer?.forEach((ans, index) => {
            data[`group_${lastRecord.ID}.Q${lastRecord.ID}_ANS${index + 1}_NEXT_Q`] = "Toast 1"
          })
        }
      }

      let payload = {
        brand_v2: invisiblyAsset?.brand_v2,
        id: invisiblyAsset?.id,
        name: invisiblyAsset?.name,
        ad_template: invisiblyAsset?.ad_template,
        state: 0,
        taxonomy_mapping: taxonomyData,
        frames: {
          frame_1: data
        }
      }
      dispatch(realtimeResearchActionCreator.setRTRSurveyDataRequest(payload))
    } else {
      if (inputValues.platform === RTR_TYPE.PROGRAMMATIC) {
        //PROGRAMMATIC
        const data = RTRBuilderPayloadToJSON(allQuestions, allToasts);
        let payload = {
          brand_v2: asset?.brand_v2,
          id: asset?.id,
          name: asset?.name,
          ad_template: asset?.ad_template,
          state: 0,
          frames: {
            frame_1: data
          }
        }
        if (headerImgContent !== undefined) {
          payload.frames.frame_1["banner_group.image_component_1"] = headerImgContent;
        }
        if (logoImgContent !== undefined) {
          payload.frames.frame_1["logo_details.image_component_1"] = logoImgContent;
        }
        dispatch(realtimeResearchActionCreator.setRTRSurveyDataRequest(payload))

      } else {
        //INVISIBLY
        let data = RTRBuilderPayloadToJSON(allQuestions);
        let taxonomyData = RTRBuilderTaxononyPayload(allQuestions);
        let lastRecord = allQuestions[allQuestions.length - 1]

        if (lastRecord?.next_question !== "" || lastRecord?.question_type === "MS") {//question Level branching
          data[`group_${lastRecord.ID}.selection_component_1`] = "Toast 1"
        } else { //answer Level branching
          if (lastRecord?.answer.length) {
            lastRecord?.answer?.forEach((ans, index) => {
              data[`group_${lastRecord.ID}.Q${lastRecord.ID}_ANS${index + 1}_NEXT_Q`] = "Toast 1"
            })
          }
        }

        let payload = {
          brand_v2: asset?.brand_v2,
          id: asset?.id,
          name: asset?.name,
          ad_template: asset?.ad_template,
          state: 0,
          taxonomy_mapping: taxonomyData,
          frames: {
            frame_1: data
          }
        }

        dispatch(realtimeResearchActionCreator.setRTRSurveyDataRequest(payload))
      }
    }
  }

  const handleRemoveCSV = (e, csvType) => {
    e.preventDefault()
    if (csvType === "questions") {
      if (Object.hasOwn(isRTRBuilderEdited, "questions")) { //user back from survey page
        if (isRTRBuilderEdited?.questions) { //user changed the survey
          setQuestionModal(true)
        } else {
          //user backfrom survey but not chnage the RTRBuilder
          removeSelection(e, 'file')
        }
      } else {
        //user not yet visited survey page
        removeSelection(e, 'file')
      }
    } else if (csvType === "toasts") {
      if (Object.hasOwn(isRTRBuilderEdited, "toasts")) { //user back from survey page
        if (isRTRBuilderEdited?.toasts) { //user changed the survey
          setToastModal(true)
        } else {
          //user backfrom survey but not chnage the RTRBuilder
          removeSelection(e, 'toastFile')
        }
      } else {
        //user not yet visited survey page
        removeSelection(e, 'toastFile')
      }
    }
  }

  const handleConfirmDeleteQuestionCSV = (e) => {
    e.preventDefault()
    removeSelection(e, 'file')
    questionsRef.current = {}
    setQuestionJson({})
    setQuestionModal(false)
  }

  const handleConfirmDeleteToastCSV = (e) => {
    e.preventDefault()
    removeSelection(e, 'toastFile')
    toastsRef.current = {}
    setToastJson({})
    setToastModal(false)
  }

  return (<>
    <div style={{ flexDirection: "column" }}>
      {!displayUploadInfo && !displayToastInfo &&
        displayStepper && <section className="general-section">
          <div className="center-stepper" id="stepper">
            <Stepper
              barStyle={"solid"}
              completeColor={"#3257FB"}
              completeTitleColor={"#FFFFFF"}
              size={24}
              titleFontSize={16}
              circleFontSize={12}
              circleFontColor={"#0E0E0F"}
              activeColor={"#fff"}
              activeTitleColor={"#fff"}
              defaultOpacity={'0.5'}
              defaultTitleOpacity={'0.5'}
              defaultTitleColor={"#fff"}
              defaultBarColor={"#fff"}
              steps={steps}
              activeStep={activeStep} />
          </div>

        </section>}

      {activeStep === 0 ?
        <div className='right-container'>
          <div id="campaign-section">
            {
              displayToastInfo ?
                <ToastUploadModal
                  display={displayToastInfo}
                  closeToastModal={closeToastModal}
                  validateFile={validateFile}
                  inputValues={inputValues}
                  updateToastFile={updateToastFile}
                  toastLoading={toastLoading}
                  csvJSON={csvJSON}
                  setToastJson={setToastFileData}>
                </ToastUploadModal>
                :
                displayUploadInfo ?
                  <UploadTemplate
                    type={templateType}
                    csvLoading={csvLoading}
                    dragLeave={dragLeave}
                    dragOver={dragOver}
                    dragEnter={dragEnter}
                    handleFileUpload={handleFileUpload}
                    fileDrop={fileDrop}
                    closeUpload={closeCSVUpload} />
                  :
                  <div className="dashboard real-time">

                    <section className="title-section dashboard-header-section">
                      <>
                        <nav className="left-nav-section title-section-with-back pb-3 campaign-header">
                          <Button className="btn-transparent" onClick={goToRTRDashboard}>
                            <img src={"/Icon_assets/svg/back_icon.svg"} alt="go to back" className="go-back" />
                          </Button>
                          <h2 id={"survey-header"}
                            className="mb-0 pl-4 secondary-title">
                            General Setup</h2>
                        </nav>

                        <nav className="right-nav-section" style={{ maxHeight: "fit-content", background: "transparent", boxShadow: 'none' }}>
                          <div className="text-right form-group">
                            <Button className={(disable ? 'btn-draft-inactive' : 'btn-draft') + " in-btn-sm in-btn-info btn-export"}
                              id="btn-draft"
                              style={{ minWidth: '144px', minHeight: '48px' }}
                              disabled={disable || isError}
                              onClick={(e) => saveCampaign(e.target.id)}>
                              {`${initiator}` === "btn-draft" && adLoading ?
                                <img
                                  src={'/Icon_assets/svg/blue_loading_icon.svg'}
                                  alt="Loading..."
                                  className={`ad-spinner ad-spinner-lg`}
                                />
                                : (editCampaign && isActiveOrPaused) ? `Update ` : `Save as Draft`}</Button>

                            <Button className={(disable ? 'btn-next' : 'btn-next-active') + " in-btn-sm in-btn-info btn-export ml-3"}
                              style={{ minWidth: '144px', minHeight: '48px' }}
                              id="btn-next-step"
                              disabled={disable || isError}
                              onClick={(e) => saveCampaign(e.target.id)}>{`${initiator}` === "btn-next-step" && adLoading ? <ADspinner size="large" /> : `Next Step`}</Button>
                          </div>
                        </nav>

                      </>
                    </section>
                    <GeneralSetup
                      displayToastSection={displayToastSection}
                      isFetching={isFetching}
                      isEdit={editCampaign}
                      isActiveOrPaused={isActiveOrPaused}
                      inputValues={inputValues}
                      handleInputChange={handleInputChange}
                      dropdownOpen={dropdownOpen}
                      toggle={toggle}
                      isAdvertiserRequest={isAdvertiserRequest}
                      searchValue={searchValue}
                      handleSearch={handleSearch}
                      advertiserList={advertiserList}
                      setInputValues={setInputValues}
                      clearText={clearText}
                      removeSelection={removeSelection}
                      templateToggle={templateToggle}
                      templateTooltipOpen={templateTooltipOpen}
                      selectTemplateType={selectTemplateType}
                      imageLoading={imageLoading}
                      headerImage={headerImage}
                      handleImageUpload={handleImageUpload}
                      templateType={templateType}
                      setDisplayUploadInfo={setDisplayUploadInfo}
                      setDisplayToastInfo={setDisplayToastInfo}
                      validators={validators}
                      disableCampaign={editCampaign || draftCalled}
                      handleRemoveCSV={handleRemoveCSV}
                      activeSurveyData={asset}
                    />

                    {
                      inputValues.platform.toLowerCase() !== RTR_TYPE.PROGRAMMATIC.toLowerCase() &&
                      <OfferSetup
                        handleInputChange={handleOfferInputChange}
                        validators={validators}
                        inputValues={inputValues}
                        handleOfferImageUpload={handleOfferImageUpload}
                        removeOfferSelection={removeOfferSelection}
                        mediaUrl={mediaUrl}
                        characterEntered={characterEntered}
                        descriptionLimit={descriptionLimit}
                      />
                    }
                  </div>
            }
            {!displayUploadInfo && !displayToastInfo &&
              <div className="setup-div">
                <img src={statusIcons.GENERAL_SETTING_ICON} alt="general-setting" />
                <div className="setup-text">General Setup.</div>
              </div>}
          </div>
        </div>
        :
        activeStep === 1 ?
          <Builder
            questionJson={questionJson}
            toastJson={toastJson}
            fromCSV={fromCSV}
            handleSubmitRTRBuilder={handleSubmitRTRBuilder}
            handleBackClick={handleBackClick}
            adState={activationData.ad_state}
            adName={inputValues.campaignName}
            adSize={Number(inputValues?.ad_size)} // if not 0  then hide redirect to survey toggle btton 
            rtrType={inputValues.platform}
            goToPreviewScreen={goToPreviewScreen}
            backFromPreview={backFromPreview}
          />
          :
          activeStep === 2 ?
            (!activationData.isPDP || !activationData.isOffer) ?
              //progrmatic
              //progrmatic
              <Preview
                draftCalled={draftCalled}
                surveyName={inputValues.campaignName}
                markSurveyComplete={markSurveyComplete}
                copyLink={copyLink}
                activationData={activationData}
                preview={preview}
                activateExternalAd={activateExternalAd}
                adActivated={adActivated}
                setActiveStep={setActiveStep}
                isPreviewFetching={isPreviewFetching}
                shadow={shadow}
                height={height}
                width={width}
                previewHtml={previewHtml}
                reloadIframe={reloadIframe}
                msgbodyStyle={activationData.isPDP ? msgbodyStyle : msgbodyStyle1}
                activating={activating}
                role={user_role}
                isPDP={activationData.isPDP}
                handlePlatformChange={handlePlatformChange}
                selectedPlatform={platformPreview}
                handleBackClick={handleBackClick}
              /> :
              //invisibly
              <RTRPreview
                activationData={activationData}
                isOfferValid={true}
                goToRTR={goToRTRDashboard}
                preview={preview}
                ad_name={adName}
                msg_id={messageId}
                brand_id={brandId}
                ad_id={advertiserId}
                hideHeader={true}
                offerId={offerId}
                setActiveStep={setActiveStep}
                handlePlatformChange={handlePlatformChange}
                selectedPlatform={platformPreview}
                handleBackClick={handleBackClick}
              />
            :
            //SurveyComplete for invisibly 
            <SurveyComplete
              activationData={activationData}
              role={user_role}
              activating={activating}
              activateExternalAd={activateExternalAd}
              adActivated={adActivated}
              preview={preview}
              setActiveStep={setActiveStep}
              reloadIframe={reloadIframe}
              reloadPreviousScreen={reloadPreviousScreen}
              handlePlatformChange={handlePlatformChange}
              selectedPlatform={platformPreview}
            />
      }

      {
        showPopup &&
        <LeaveCampaignModal display={showPopup} closePopup={closeModal} />
      }
    </div>
    <PopUpModal
      className={'csv-delete-confirm-modal delete-question'}
      title={"Replace Survey Questions?"}
      openPopUp={questionModal}
      setOpenPopUp={setQuestionModal}
    >
      <Row>
        <Col lg="12" md="12" sm="12" className='content'>
          <p>
            You already have questions and toast data from last uploaded csv. <br />
            Uploading new csv will replace all previous questions and toasts with data from the new csv.
          </p>
        </Col>
        <Col lg="12" md="12" sm="12" className='actions'>
          <Button
            className='in-btn-lg in-btn-info btn-replace btn-no btn btn-secondary'
            onClick={handleConfirmDeleteQuestionCSV}
          >
            Replace
          </Button>
          <Button
            className='in-btn-link btn btn-link btn-cancel btn btn-secondary'
            onClick={() => setQuestionModal(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </PopUpModal>

    <PopUpModal
      className={'csv-delete-confirm-modal delete-toast'}
      title={"Replace Survey Questions?"}
      openPopUp={toastModal}
      setOpenPopUp={setToastModal}
    >
      <Row>
        <Col lg="12" md="12" sm="12" className='content'>
          <p>
            You already have questions and toast data from last uploaded csv. <br />
            Uploading new csv will replace all previous questions and toasts with data from the new csv.
          </p>
        </Col>
        <Col lg="12" md="12" sm="12" className='actions'>
          <Button
            className='in-btn-lg in-btn-info btn-replace btn-no btn btn-secondary'
            onClick={handleConfirmDeleteToastCSV}
          >
            Replace
          </Button>
          <Button
            className='in-btn-link btn btn-link btn-cancel btn btn-secondary'
            onClick={() => setToastModal(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </PopUpModal>
  </>)
}

export default RealTimeCampaign;